import React from "react";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import {
  PanelSetProps,
  SummaryTitles,
} from "@components/product/summary/common";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { PhoneSizeComparisonGraphic } from "@components/svgs/phone-size-comparison-graphic";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useExplanation } from "@lib/hooks/use-explanation";
import { useAttribute } from "@lib/hooks/use-attributes";
import { ClassificationName } from "@lib/model/attribute";

const Size = (props: PanelSetProps) => {
  const { selectedProduct } = props;
  const { explanation } = useExplanation(
    "phoneSizeExplanation",
    selectedProduct
  );

  const attributeConfiguration = useAttribute("screenSize");
  const screenSize = props.selectedProduct.attributes["screenSize"]?.value;

  const shareMeetNeeds = props.isShare
    ? ", which may not meet my needs."
    : ", which may not meet your needs.";

  if (typeof explanation.content !== "string") {
    return null;
  }

  const sizeWord = explanation.content.split(" ")[1].toLowerCase();
  const classificationText = `${sizeWord} ${explanation.content.split(" ")[2]}`;

  const sizeWordMap = {
    largest: ClassificationName.Large,
    large: ClassificationName.Large,
    medium: ClassificationName.Medium,
    small: ClassificationName.Small,
    smallest: ClassificationName.Small,
  };

  const first = (
    <>
      At {screenSize} inches diagonal the{" "}
      <strong>{getProductLabels(selectedProduct).shortLabel}</strong> is{" "}
      {!explanation.content.includes("est") ? "a" : "the"} {classificationText}
      {explanation.isUnmet ? shareMeetNeeds : "."}
    </>
  );

  console.log(explanation);

  return (
    <DonePanel
      title={"Size"}
      icon={getAspectIcon("smartphones", "screenSize")}
      color={getMeetsNeedsColor(!explanation.isUnmet)}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <SummaryTitles first={first} />
      <PhoneSizeComparisonGraphic
        className="h-48 mx-auto mt-2"
        highlightSizes={[
          {
            size: sizeWordMap[sizeWord],
          },
        ]}
      />
    </DonePanel>
  );
};

export default Size;
