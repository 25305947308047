import { AttributeConfiguration } from "@lib/../../data/packages/model/src/productAttribute.js";

export interface AttributeConfigurationMap {
  [key: string]: AttributeConfiguration;
}

export interface Attribute {
  slug: string;
  value: string;
}

/**
 * @TODO It should be possible to deprecate this, but a few places still follow
 * this lookup.
 */
export enum ClassificationName {
  Poor = "poor",
  Good = "good",
  Great = "great",
  Outstanding = "outstanding",
  Low = "low",
  Small = "small",
  Medium = "medium",
  High = "high",
  Large = "large",
  Max = "max",
  VeryUnder = "veryUnder",
  Under = "under",
  SlightlyUnder = "slightlyUnder",
  Fits = "fits",
  SlightlyOver = "slightlyOver",
  Over = "over",
  VeryOver = "veryOver",
  Excellent = "excellent",
  LowEnd = "lowend",
}
