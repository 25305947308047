/**
 * Check if LLM blurb is valid or not
 */
export const isValidBlurb = (blurb: string, promptType: string): boolean => {
  if (!blurb) {
    return false;
  }

  const minLengthbyPromptType = {
    productDetail: 300,
    comparison: 330,
    prosAndCons: 0,
    isGood: 150,
    worthBuying: 300,
    comparePictureQualityTV: 240,
    compareMoviesTV: 240,
    compareSportsTV: 240,
    compareGamingTV: 240,
    compareNewsTV: 240,
    compareAnimationTV: 240,
    compareBrightRoomTV: 240,
    compareGeneralUseLaptop: 240,
    compareEngineeringLaptop: 240,
    compareGamingLaptop: 240,
    comparePerformanceLaptop: 240,
    compareScreenLaptop: 240,
    explainPictureQualityTV: 240,
    comparePhotosPhone: 240,
    compareVideosPhone: 240,
    compareCameraPhone: 240,
    compareMusicHeadphones: 240,
    compareCallsHeadphones: 240,
    compareGamingHeadphones: 240,
    compareTvHeadphones: 240,
    comparePodcastsHeadphones: 240,
    compareExerciseHeadphones: 240,
    compareNoiseReductionHeadphones: 240,
    compareTravelHeadphones: 240,
    explainMoviesTV: 240,
    explainSportsTV: 240,
    explainGamingTV: 240,
    explainNewsTV: 240,
    explainAnimationTV: 240,
    explainBrightRoomTV: 240,
    explainGeneralUseLaptop: 240,
    explainEngineeringLaptop: 240,
    explainGamingLaptop: 240,
    explainPerformanceLaptop: 240,
    explainPhotosPhone: 240,
    explainVideosPhone: 240,
    explainCameraPhone: 240,
    explainMusicHeadphones: 240,
    explainCallsHeadphones: 240,
    explainGamingHeadphones: 240,
    explainTvHeadphones: 240,
    explainPodcastsHeadphones: 240,
    explainExerciseHeadphones: 240,
    explainNoiseReductionHeadphones: 240,
    explainTravelHeadphones: 240,
  };

  const invalidWords = [
    "file",
    "\n-",
    "\n1",
    "as a sales manager",
    "tools",
    "since I",
    "i do not",
  ];
  return (
    blurb.length > minLengthbyPromptType[promptType] &&
    !invalidWords.some((word) => blurb.toLocaleLowerCase().includes(word))
  );
};

export const formatBlurbForDisplay = (
  blurb: string,
  promptType: string
): string => {
  let formattedBlurb: string;

  if (promptType === "prosAndCons") {
    formattedBlurb = blurb;
  } else {
    formattedBlurb = blurb.replaceAll(/\[[^\]]+\]/g, "");
  }

  return formattedBlurb;
};

export const getSystemPrompt = (category: string) => {
  let systemPrompt: string;
  switch (category) {
    case "monitors": {
      systemPrompt = `You are a sales manager at a Monitor store. Your job is to help your customer decide which monitor is better for them. You aren't able to ask questions. You may only give the customer advice to help them make their decision.

      In your advice, make tradeoffs explicit. For example, you might say things like "If you're willing to pay more for a better gaming experience, the LG X could be a great choice, but it won't perform as well in productivity tasks"
      
      Emphasize tradeoffs relating to price and use cases (casual gaming, competitive gaming, productivity and media consumption), especially when those tradeoffs are significant.
      
      Speak plainly so that a typical consumer can understand. Don't refer directly to scores. Don't include words like "category", "score", "rating", or "candidate". Don't reference specific numbers. Avoid using words like "could", "may", "might", "potentially".
      
      Your response must be less than 50 words.  It must be a single paragraph. No bullet points and no numbered lists. Do not start with a general overview or introductory sentence, just start with substance.`;
      break;
    }
    case "laptops": {
      systemPrompt = `You are a sales manager at a laptop store. Your job is to help your customer decide which laptop is better for them. You aren't able to ask questions. You may only give the customer advice to help them make their decision.

      In your advice, make tradeoffs explicit. For example, you might say things like "If you're willing to pay more for better performance doing intensive engineering tasks, the Asus could be a great choice, but it's not very portable."
      
      Emphasize tradeoffs relating to price, performance, battery life, portability, and suitability for different use cases (general use, 3d/machine learning/gaming, and engineering).
      
      Speak plainly so that a typical consumer can understand. Don't refer directly to scores. Don't include words like "category", "score", "rating", or "candidate". Don't reference specific numbers. Avoid using words like "could", "might", "potentially", "possibly".
      
      Your response must be less than 50 words.  It must be a single paragraph. No bullet points and no numbered lists. Do not start with a general overview or introductory sentence, just start with substance.`;
      break;
    }
    case "tvs": {
      systemPrompt = `You are a sales manager at a TV store. Your job is to help your customer decide which TV is better for them. You aren't able to ask questions. You may only give the customer advice to help them make their decision.

      In your advice, make tradeoffs explicit. For example, you might say things like "If you're willing to pay more for a better streaming experience, the Hisense X could be a great choice, but it won't perform as well in a sunny room."
      
      Emphasize tradeoffs relating to price, viewing content (movies, sports, gaming, TV, News), and bright room usage, especially when those tradeoffs are significant.
      
      Speak plainly so that a typical consumer can understand. Don't refer directly to scores. Don't include words like "category", "score", "rating", or "candidate". Don't reference specific numbers. Avoid using words like "could", "may", "might", "potentially".
      
      Your response must be less than 50 words.  It must be a single paragraph. No bullet points and no numbered lists. Do not start with a general overview or introductory sentence, just start with substance.`;
      break;
    }
    case "smartphones": {
      systemPrompt = `You are a sales manager at a phone store. Your job is to help your customer decide which phone is better for them. You aren't able to ask questions. You may only give the customer advice to help them make their decision.

      In your advice, make tradeoffs explicit. For example, you might say things like "If you're willing to pay more for a better camera , Phone X is a great choice, but it costs more than Phone Y."
      
      Emphasize tradeoffs relating to price, camera, battery life, and screen size, especially when those tradeoffs are significant.  If the phones have different operating systems, mention it but don't elaborate.
      
      Speak plainly and directly so that a typical consumer can understand. Don't refer directly to scores. Don't include words like "category", "score", "rating", or "candidate". Don't reference specific numbers. Avoid using words like "could", "might", "potentially".
      
      Use the attached spreadsheet, but do not explicitly cite it. Don't use the word "score" .
      
      Your response must be less than 50 words.  It must be a single paragraph. No bullet points and no numbered lists. Do not start with a general overview or introductory sentence, just start with substance. Do not conclude with a general statement.`;
      break;
    }
    case "headphones": {
      systemPrompt = `You are a sales manager at a store that sells earbuds and headphones. Your job is to help your customer decide which earbuds or headphones is better for them. You aren't able to ask questions. You may only give the customer advice to help them make their decision.

      In your advice, make tradeoffs explicit. For example, you might say things like "If you want the most immersive experience listening to music, the Sennheisers would be the better choice, but their high latency makes them unsuitable for TV and gaming."
      
      Emphasize tradeoffs relating to price, suitability for different use cases and different kinds of audio content. If one product has a major feature that other lacks (such as a microphone or virtual surround), mention that.
      
      Speak plainly so that a typical consumer can understand. Don't refer directly to scores. Don't include words like "category", "score", "rating", or "candidate". Don't reference specific numbers. Avoid using words like "could", "might", "potentially". 
      
      Your response must be less than 50 words.  It must be a single paragraph. No bullet points and no numbered lists. Do not start with a general overview or introductory sentence, just start with substance.`;
      break;
    }
  }
  return systemPrompt;
};

export const generalComparePrompts = ["comparison"];
export const specificUseCaseComparePrompts = [
  "comparePhotosPhone",
  "compareVideosPhone",
  "compareCameraPhone",
  "comparePictureQualityTV",
  "compareMoviesTV",
  "compareSportsTV",
  "compareGamingTV",
  "compareNewsTV",
  "compareAnimationTV",
  "compareBrightRoomTV",
  "compareGeneralUseLaptop",
  "compareEngineeringLaptop",
  "compareGamingLaptop",
  "compareMusicHeadphones",
  "compareCallsHeadphones",
  "compareGamingHeadphones",
  "compareTvHeadphones",
  "comparePodcastsHeadphones",
  "compareExerciseHeadphones",
  "compareNoiseReductionHeadphones",
  "compareTravelHeadphones",
];
export const specificComparePrompts = [
  ...specificUseCaseComparePrompts,
  "comparePerformanceLaptop",
  "compareScreenLaptop",
];
export const allComparePrompts = [
  ...generalComparePrompts,
  ...specificComparePrompts,
];
export const generalProductPrompts = [
  "productDetail",
  "worthBuying",
  "isGood",
  "prosAndCons",
];
export const specificUseCaseProductPrompts = [
  "explainPhotosPhone",
  "explainVideosPhone",
  "explainCameraPhone",
  "explainPictureQualityTV",
  "explainMoviesTV",
  "explainSportsTV",
  "explainGamingTV",
  "explainNewsTV",
  "explainAnimationTV",
  "explainBrightRoomTV",
  "explainGeneralUseLaptop",
  "explainEngineeringLaptop",
  "explainGamingLaptop",
  "explainMusicHeadphones",
  "explainCallsHeadphones",
  "explainGamingHeadphones",
  "explainTvHeadphones",
  "explainPodcastsHeadphones",
  "explainExerciseHeadphones",
  "explainNoiseReductionHeadphones",
  "explainTravelHeadphones",
];
export const specificProductPrompts = [
  ...specificUseCaseProductPrompts,
  "explainPerformanceLaptop",
  "explainScreenLaptop",
];
export const allProductPrompts = [
  ...generalProductPrompts,
  ...specificProductPrompts,
];

export const priceClassificationThresholds = {
  tvs: {
    budget: 50000,
    lowermid: 75000,
    mid: 100000,
    uppermid: 150000,
    premium: 200000,
  },
  laptops: {
    budget: 50000,
    lowermid: 100000,
    mid: 150000,
    uppermid: 200000,
    premium: 250000,
  },
  headphones: {
    budget: 5000,
    lowermid: 10000,
    mid: 20000,
    premium: 50000,
  },
  smartphones: {
    budget: 40000,
    mid: 60000,
    uppermid: 80000,
    premium: 1000000,
  },
  monitors: {
    budget: 25000,
    mid: 40000,
    uppermid: 80000,
    premium: 1000000,
  },
};

export const priceCompareThresholds = {
  tvs: {
    similar: 0.05,
    slight: 0.1,
    somewhat: 0.2,
    significant: 0.5,
  },
  laptops: {
    similar: 0.1,
    slight: 0.2,
    somewhat: 0.4,
    significant: 0.8,
  },
  headphones: {
    similar: 0.1,
    slight: 0.2,
    somewhat: 0.5,
    significant: 1,
  },
  smartphones: {
    similar: 0.05,
    slight: 0.15,
    somewhat: 0.3,
    significant: 0.6,
  },
  monitors: {
    similar: 0.05,
    slight: 0.1,
    somewhat: 0.2,
    significant: 0.5,
  },
};

export const openaiColumnNames = {
  tvs: [
    { name: "FullName", excludeForPromptTypes: [] },
    {
      name: "Price Comment",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Price Comparison",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    { name: "Manufacturer", excludeForPromptTypes: [] },
    { name: "Year Released", excludeForPromptTypes: [] },
    { name: "Release Date", excludeForPromptTypes: [] },
    { name: "Screen Size", excludeForPromptTypes: [] },
    {
      name: "Overall Picture Quality Score",
      sheetName: "Screen Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Gaming Score",
      sheetName: "Gaming Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Sports Content Score",
      sheetName: "Sports Content Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Dark Room Score",
      sheetName: "Dark Room Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Bright Room Score",
      sheetName: "Bright Room Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "QC/QA Score",
      sheetName: "QC/QA Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Movie/Series Score",
      sheetName: "Movie/Series Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "News, Talk, & Reality TV Score",
      sheetName: "News, Talk, & Reality TV (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Cartoons & Animation Score",
      sheetName: "Cartoons & Animation (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Contrast",
      sheetName: "Contrast (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "SDR Brightness Score",
      sheetName: "SDR Brightness Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Local Dimming",
      sheetName: "Local Dimming (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "HDR Brightness Score",
      sheetName: "HDR Brightness Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Gray Uniformity",
      sheetName: "Gray Uniformity (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Black Uniformity",
      sheetName: "Black Uniformity (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Viewing Angle",
      sheetName: "Viewing Angle (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Reflections Score",
      sheetName: "Reflections Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Upscaling",
      sheetName: "Upscaling (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Motion Processing",
      sheetName: "Motion Processing (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Colors Out of the Box Score",
      sheetName: "Colors Out fo the Box Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Color Gamut Score",
      sheetName: "Color Gamut Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Color Volume Score",
      sheetName: "Color Volume Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Response Time Score",
      sheetName: "Response Time Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Input Lag Score",
      sheetName: "Input Lag Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Sound Quality Score",
      sheetName: "Sound Quality Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    { name: "Screen Type", excludeForPromptTypes: [] },
    { name: "HDMI Ports", excludeForPromptTypes: [] },
    { name: "Great speakers", excludeForPromptTypes: [] },
    { name: "Screen Sub-type", excludeForPromptTypes: [] },
    { name: "HDR Supported formats", excludeForPromptTypes: [] },
    { name: "Chroma Subsampling", excludeForPromptTypes: [] },
    {
      name: "Chroma Score",
      sheetName: "Chroma Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Use as Monitor Score",
      sheetName: "Monitor Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    { name: "HDR10+ Support", excludeForPromptTypes: [] },
    { name: "Dolby Vision Support", excludeForPromptTypes: [] },
    { name: "HLG Support", excludeForPromptTypes: [] },
    { name: "HGiG", excludeForPromptTypes: [] },
    { name: "Dolby Atmos", excludeForPromptTypes: [] },
    { name: "DTS:X", excludeForPromptTypes: [] },
    { name: "Speaker Setup", excludeForPromptTypes: ["prosAndCons", "isGood"] },
    { name: "Speaker Power", excludeForPromptTypes: ["prosAndCons", "isGood"] },
    { name: "Accessibility", excludeForPromptTypes: [] },
    { name: "Other Description", excludeForPromptTypes: [] },
  ],
  laptops: [
    { name: "FullName", excludeForPromptTypes: [] },
    {
      name: "Price Comment",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Price Comparison",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Storage in GB",
      sheetName: "Storage",
      excludeForPromptTypes: [],
    },
    { name: "RAM in GB", sheetName: "RAM", excludeForPromptTypes: [] },
    {
      name: "Main Processor",
      sheetName: "Processor",
      excludeForPromptTypes: [],
    },
    { name: "GPU", excludeForPromptTypes: [] },
    {
      name: "Battery Capacity",
      excludeForPromptTypes: [...allProductPrompts, ...allComparePrompts],
    },
    {
      name: "Screen Size in inches",
      sheetName: "Screen Size",
      excludeForPromptTypes: [],
    },
    {
      name: "Display Panel Type",
      sheetName: "Display Technology",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Resolution", excludeForPromptTypes: [] },
    { name: "Touchscreen", excludeForPromptTypes: [] },
    {
      name: "Screen Aspect Ratio",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    {
      name: "Max Refresh Rate",
      sheetName: "Refresh Rate",
      excludeForPromptTypes: [],
    },
    {
      name: "Screen pixel density (PPI)",
      sheetName: "PPI",
      excludeForPromptTypes: [],
    },
    {
      name: "Brightness (nits)",
      sheetName: "Brightness",
      excludeForPromptTypes: [],
    },
    {
      name: "DCI-P3 colorspace coverage (percent)",
      sheetName: "DCI-P3",
      excludeForPromptTypes: [],
    },
    {
      name: "sRGB colorspace coverage (percent)",
      sheetName: "sRGB",
      excludeForPromptTypes: [],
    },
    {
      name: "Adobe RGB colorspace coverage (percent)",
      sheetName: "Adobe RGB",
      excludeForPromptTypes: [],
    },
    {
      name: "Contrast Ratio",
      sheetName: "Contrast",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Manufacturer", excludeForPromptTypes: [] },
    {
      name: "Installed Operating System",
      sheetName: "Operating System",
      excludeForPromptTypes: [],
    },
    {
      name: "Total Physical Width (inches)",
      sheetName: "Width",
      excludeForPromptTypes: [],
    },
    {
      name: "Total Physical Depth (inches)",
      sheetName: "Depth",
      excludeForPromptTypes: [],
    },
    {
      name: "Total Physical Thickness (inches)",
      sheetName: "Thickness",
      excludeForPromptTypes: [],
    },
    {
      name: "Weight (pounds)",
      sheetName: "Weight",
      excludeForPromptTypes: [],
    },
    { name: "USB-C", excludeForPromptTypes: [] },
    { name: "USB-C (Non-Thunderbolt)", excludeForPromptTypes: [] },
    { name: "Thunderbolt 3 Ports", excludeForPromptTypes: [] },
    { name: "Thunderbolt 4 Ports", excludeForPromptTypes: [] },
    { name: "HDMI Ports", excludeForPromptTypes: [] },
    { name: "DisplayPorts", excludeForPromptTypes: [] },
    {
      name: "Total Display Outputs",
      sheetName: "Display Outputs",
      excludeForPromptTypes: [],
    },
    { name: "Thunderbolt", excludeForPromptTypes: [] },
    { name: "USB4", excludeForPromptTypes: [] },
    { name: "Ethernet", excludeForPromptTypes: [] },
    { name: "USB-C Charging", excludeForPromptTypes: [] },
    {
      name: "Available RAM Slots",
      sheetName: "RAM Slots",
      excludeForPromptTypes: [],
    },
    {
      name: "Available Storage Slots",
      sheetName: "Storage Slots",
      excludeForPromptTypes: [],
    },
    {
      name: "Keyboard has Number Pad",
      sheetName: "Number Pad",
      excludeForPromptTypes: [],
    },
    { name: "Backlit Keyboard", excludeForPromptTypes: [] },
    {
      name: "Keyboard Lighting Color",
      excludeForPromptTypes: [...allProductPrompts, ...allComparePrompts],
    },
    { name: "Fingerprint Reader", excludeForPromptTypes: [] },
    { name: "Facial Recognition", excludeForPromptTypes: [] },
    {
      name: "Hardware Ray Tracing",
      sheetName: "Ray Tracing",
      excludeForPromptTypes: [],
    },
    { name: "DLSS", excludeForPromptTypes: [] },
    { name: "Premium Business Laptop", excludeForPromptTypes: [] },
    {
      name: "Gaming Laptop Appearance",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Release Date", excludeForPromptTypes: [] },
    { name: "Manufacturer Link", excludeForPromptTypes: [] },
    {
      name: "Battery Life (hours of typical usage)",
      sheetName: "Battery Life",
      excludeForPromptTypes: [],
    },
    {
      name: "Portability Score",
      sheetName: "Mobility Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Keyboard Quality Score",
      sheetName: "Keyboard Quality Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Webcam Score",
      sheetName: "Webcam Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Speaker Quality Score",
      sheetName: "Speaker Quality Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Build Quality Score",
      sheetName: "Build Quality Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Heat Management Score",
      sheetName: "Heat Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    { name: "Fan Noise Score", excludeForPromptTypes: [] },
    {
      name: "General Use Performance Score (considering CPU and RAM)",
      sheetName: "General Use Performance Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Gaming, 3D and Machine Learning Performance Score (considering GPU, CPU, RAM)",
      sheetName: "Gaming Performance Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Engineering Performance Score (considering CPU, RAM, GPU)",
      sheetName: "Engineering Performance Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "General Use Score",
      sheetName: "General Use Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Gaming, 3D rendering, machine learning Score",
      sheetName: "Gaming Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Engineering Use Score",
      sheetName: "Engineering Use Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Screen Colors Score",
      sheetName: "Screen Colors Score",
      excludeForPromptTypes: [],
    },
    {
      name: "General Use Screen Score",
      sheetName: "General Use Screen Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Fast Motion Screen Score",
      sheetName: "Gaming Screen Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Engineering Screen Score",
      sheetName: "Engineering Screen Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Suitable for Intensive Gaming",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Other Description", excludeForPromptTypes: [] },
  ],
  headphones: [
    { name: "FullName", excludeForPromptTypes: [] },
    { name: "Manufacturer", excludeForPromptTypes: [] },
    { name: "Release Date", excludeForPromptTypes: [] },
    {
      name: "Price Comment",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Price Comparison",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    { name: "Form Factor", excludeForPromptTypes: [] },
    {
      name: "Specific Form Factor",
      sheetName: "FF Subgroup",
      excludeForPromptTypes: [],
    },
    { name: "Enclosure Type", excludeForPromptTypes: [] },
    { name: "Wireless", excludeForPromptTypes: [] },
    { name: "Wired", excludeForPromptTypes: [] },
    {
      name: "Overall Sound Quality",
      sheetName: "Sound Quality (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Soundstage / Imaging Score",
      sheetName: "Soundstage / Imaging (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Comfort Score",
      sheetName: "Comfort (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Mic Quality Score",
      sheetName: "Mic Quality (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Build Quality Score",
      sheetName: "Build Quality (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Built-in Controls Score",
      sheetName: "Controls (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Music Score",
      sheetName: "Music Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Calls and Meetings Score",
      sheetName: "Calls Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Gaming Score",
      sheetName: "Gaming Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "TV and Video Score",
      sheetName: "TV and Video Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Book/Podcast Score",
      sheetName: "Book/Podcast Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Use during Exercise Score",
      sheetName: "Exercise Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Air Travel Score",
      sheetName: "Travel Score (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Portability Score",
      sheetName: "Portability Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Noise Isolation Quality",
      sheetName: "Noise Isolation Quality (Rescaled)",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
      ],
    },
    {
      name: "Cable length (meters)",
      sheetName: "Cable length (m)",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Detachable cable", excludeForPromptTypes: [] },
    { name: "Driver Type", excludeForPromptTypes: [] },
    { name: "Lossless Codec", excludeForPromptTypes: [] },
    { name: "AAC Codec", excludeForPromptTypes: [] },
    { name: "Low Latency", excludeForPromptTypes: [] },
    { name: "Sweatproof Certified", excludeForPromptTypes: [] },
    { name: "Dustproof Certified", excludeForPromptTypes: [] },
    { name: "Active Noise Cancelling", excludeForPromptTypes: [] },
    {
      name: "Weight (grams)",
      sheetName: "Weight",
      excludeForPromptTypes: [],
    },
    { name: "Earbud Size", excludeForPromptTypes: [] },
    { name: "Foldable", excludeForPromptTypes: [] },
    { name: "Headphone Carry Size", excludeForPromptTypes: [] },
    { name: "Earbud Carry Size", excludeForPromptTypes: [] },
    { name: "App Equalizer", excludeForPromptTypes: [] },
    { name: "App Controls Customization", excludeForPromptTypes: [] },
    { name: "App Audio Controls", excludeForPromptTypes: [] },
    { name: "Optimized for Android", excludeForPromptTypes: [] },
    { name: "Optimized for Apple", excludeForPromptTypes: [] },
    { name: "Ear / Head Detection", excludeForPromptTypes: [] },
    {
      name: "Battery life (hours of typical use)",
      excludeForPromptTypes: [],
    },
    {
      name: "Battery life case",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "Wireless charging", excludeForPromptTypes: [] },
    { name: "Charging time", excludeForPromptTypes: [] },
    { name: "Virtual Surround", excludeForPromptTypes: [] },
    { name: "Mic Style", excludeForPromptTypes: [] },
    { name: "Durability", excludeForPromptTypes: [] },
    { name: "Included secure fit accessories", excludeForPromptTypes: [] },
    { name: "Other Description", excludeForPromptTypes: [] },
  ],
  smartphones: [
    { name: "FullName", excludeForPromptTypes: [] },
    {
      name: "Price Comment",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Price Comparison",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Storage in GB",
      sheetName: "Storage",
      excludeForPromptTypes: [],
    },
    { name: "Year Released", excludeForPromptTypes: [] },
    { name: "Month Released", excludeForPromptTypes: [] },
    { name: "Day Released", excludeForPromptTypes: [] },
    { name: "Manufacturer", excludeForPromptTypes: [] },
    { name: "Operating System", excludeForPromptTypes: [] },
    {
      name: "Phone body back material",
      sheetName: "Back material",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    {
      name: "Battery Capacity (in mAh)",
      sheetName: "Battery Capacity",
      excludeForPromptTypes: [...allProductPrompts, ...allComparePrompts],
    },
    {
      name: "Screen Size (inches)",
      sheetName: "Screen Size",
      excludeForPromptTypes: [],
    },
    {
      name: "Curved screen edge",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    {
      name: "Vertical Resolution",
      sheetName: "YRes",
      excludeForPromptTypes: [],
    },
    {
      name: "Horizontal Resolution",
      sheetName: "XRes",
      excludeForPromptTypes: [],
    },
    { name: "Aspect Ratio", excludeForPromptTypes: [] },
    { name: "Display Type", excludeForPromptTypes: [] },
    { name: "Always On Display", excludeForPromptTypes: [] },
    {
      name: "Peak brightness (nits)",
      sheetName: "Peak",
      excludeForPromptTypes: [],
    },
    {
      name: "High brightness mode (nits)",
      sheetName: "HBM",
      excludeForPromptTypes: [],
    },
    {
      name: "Typical brightness (nits)",
      sheetName: "Typ",
      excludeForPromptTypes: [],
    },
    { name: "Refresh Rate", excludeForPromptTypes: [] },
    { name: "Variable refresh rate", excludeForPromptTypes: [] },
    { name: "Pixels per Inch", excludeForPromptTypes: [] },
    { name: "Height", excludeForPromptTypes: [] },
    { name: "Width", excludeForPromptTypes: [] },
    { name: "Area", excludeForPromptTypes: [] },
    { name: "Weight (oz)", sheetName: "Weight", excludeForPromptTypes: [] },
    { name: "Processor", excludeForPromptTypes: [] },
    { name: "RAM in GB", sheetName: "RAM", excludeForPromptTypes: [] },
    {
      name: "Battery Life (hours)",
      sheetName: "Battery Life",
      excludeForPromptTypes: [],
    },
    {
      name: "Total number of Cameras",
      sheetName: "Cameras",
      excludeForPromptTypes: [],
    },
    { name: "RAW Output", excludeForPromptTypes: [] },
    { name: "HDR Video", excludeForPromptTypes: [] },
    {
      name: "Photos score",
      sheetName: "Final adjusted photos score (Rescaled)",
      excludeForPromptTypes: [
        "explainPhotosPhone",
        "comparePhotosPhone",
        "explainVideosPhone",
        "compareVideosPhone",
        "explainCameraPhone",
        "compareCameraPhone",
      ],
    },
    {
      name: "Videos Score",
      sheetName: "Final adjusted videos score (Rescaled)",
      excludeForPromptTypes: [
        "explainPhotosPhone",
        "comparePhotosPhone",
        "explainVideosPhone",
        "compareVideosPhone",
        "explainCameraPhone",
        "compareCameraPhone",
      ],
    },
    {
      name: "Total Camera Score",
      sheetName: "Total Camera Score (Rescaled)",
      excludeForPromptTypes: [
        "explainPhotosPhone",
        "comparePhotosPhone",
        "explainVideosPhone",
        "compareVideosPhone",
        "explainCameraPhone",
        "compareCameraPhone",
      ],
    },
    {
      name: "Photos Software experience score",
      sheetName: "Photos Software experience (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Photos Dynamic range score",
      sheetName: "Photos Dynamic range (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Photos Low light performance score",
      sheetName: "Photos Low light performance (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Camera Versatility score",
      sheetName: "Camera Versatility (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Photos Selfie quality score",
      sheetName: "Photos Selfie quality (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Video Resolution/framerate score",
      sheetName: "Video Resolution/framerate (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Video Software experience score",
      sheetName: "Video Software experience (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Video Stabilization score",
      sheetName: "Video Stabilization (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Video Dynamic range score",
      sheetName: "Video Dynamic range (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Video Low light performance score",
      sheetName: "Video Low light performance (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Mic quality score",
      sheetName: "Mic quality (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Main Camera Megapixels",
      sheetName: "Main Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Telephoto Camera Megapixels",
      sheetName: "Telephoto Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Telephoto Optical Zoom",
      excludeForPromptTypes: [],
    },
    {
      name: "Periscope Camera Megapixels",
      sheetName: "Periscope Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Super Telephoto Optical Zoom",
      excludeForPromptTypes: [],
    },
    {
      name: "Ultrawide Camera Megapixels",
      sheetName: "Ultrawide Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Ultrawide Camera Field of View",
      sheetName: "Ultrawide FOV",
      excludeForPromptTypes: [],
    },
    {
      name: "Macro Camera Megapixels",
      sheetName: "Macro Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Selfie Camera Megapixels",
      sheetName: "Selfie Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "Ultrawide Selfie Camera Megapixels",
      sheetName: "Ultrawide Selfie Megapixels",
      excludeForPromptTypes: [],
    },
    {
      name: "8K Max video recording fps",
      sheetName: "8K Max",
      excludeForPromptTypes: [],
    },
    {
      name: "4K Max video recording fps",
      sheetName: "4K Max",
      excludeForPromptTypes: [],
    },
    {
      name: "1080p video recording max fps",
      sheetName: "1080p Max",
      excludeForPromptTypes: [],
    },
    { name: "5G", excludeForPromptTypes: [] },
    { name: "MiscButtons", excludeForPromptTypes: [] },
    {
      name: "3.5mm Headphone Jack",
      sheetName: "Headphone Jack",
      excludeForPromptTypes: [],
    },
    { name: "Front-facing Stereo Speakers", excludeForPromptTypes: [] },
    { name: "NFC", excludeForPromptTypes: [] },
    { name: "WiFi Version", excludeForPromptTypes: [] },
    { name: "Bluetooth Version", excludeForPromptTypes: [] },
    { name: "A2DP", excludeForPromptTypes: [] },
    { name: "Wireless charging", excludeForPromptTypes: [] },
    { name: "Wireless charging Speed (Watts)", excludeForPromptTypes: [] },
    { name: "Reverse wireless charging", excludeForPromptTypes: [] },
    {
      name: "Reverse wireless charging Speed (Watts)",
      excludeForPromptTypes: ["prosAndCons", "isGood"],
    },
    { name: "SD Card", excludeForPromptTypes: [] },
    { name: "Stylus", excludeForPromptTypes: [] },
    { name: "Dual SIM", excludeForPromptTypes: [] },
    {
      name: "Stock Operating System",
      sheetName: "Stock",
      excludeForPromptTypes: [],
    },
    { name: "Screen Cutout", excludeForPromptTypes: ["prosAndCons", "isGood"] },
    { name: "Face Unlock", excludeForPromptTypes: [] },
    { name: "Fingerprint Sensor", excludeForPromptTypes: [] },
    { name: "Desktop Mode", excludeForPromptTypes: [] },
    { name: "IP Dust", excludeForPromptTypes: [] },
    { name: "IP Water", excludeForPromptTypes: [] },
    { name: "Quality", excludeForPromptTypes: [] },
    { name: "Unlockable Bootloader", excludeForPromptTypes: [] },
    { name: "Custom ROM Support", excludeForPromptTypes: [] },
    {
      name: "Warranty (months)",
      sheetName: "Warranty",
      excludeForPromptTypes: [],
    },
    { name: "Main Port", sheetName: "Port", excludeForPromptTypes: [] },
    {
      name: "Screen Quality",
      sheetName: "Screen (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Performance score",
      sheetName: "GB6.2 (Rescaled)",
      excludeForPromptTypes: [],
    },
    { name: "Other Description", excludeForPromptTypes: [] },
  ],
  monitors: [
    { name: "FullName", excludeForPromptTypes: [] },
    {
      name: "Price Comment",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Price Comparison",
      excludeForPromptTypes: [
        ...specificComparePrompts,
        ...specificProductPrompts,
        "prosAndCons",
      ],
    },
    {
      name: "Screen Resolution",
      excludeForPromptTypes: [],
    },
    {
      name: "Screen Size (in inches)",
      sheetName: "Screen Size",
      excludeForPromptTypes: [],
    },
    {
      name: "Panel Family",
      excludeForPromptTypes: [],
    },
    {
      name: "Panel Sub-Type",
      excludeForPromptTypes: [],
    },
    {
      name: "Refresh Rate (in Hz)",
      sheetName: "Refresh Rate",
      excludeForPromptTypes: [],
    },
    {
      name: "Aspect Ratio",
      excludeForPromptTypes: [],
    },
    {
      name: "Adjustable Stand",
      excludeForPromptTypes: [],
    },
    {
      name: "Special Features",
      excludeForPromptTypes: [],
    },
    {
      name: "Static Contrast",
      excludeForPromptTypes: [],
    },
    {
      name: "SDR Peak Brightness (in nits)",
      sheetName: "SDR Peak Brightness",
      excludeForPromptTypes: [],
    },
    {
      name: "HDR Peak Brightness (in nits)",
      sheetName: "HDR Peak Brightness",
      excludeForPromptTypes: [],
    },
    {
      name: "Suitable for HDR Gaming and Media Consumption",
      excludeForPromptTypes: [],
    },
    {
      name: "sRGB Color Gamut",
      excludeForPromptTypes: [],
    },
    {
      name: "Suitable for Digital Photo Editing",
      excludeForPromptTypes: [],
    },
    {
      name: "P3 Color Gamut",
      excludeForPromptTypes: [],
    },
    {
      name: "Suitable for HDR Video Editing",
      excludeForPromptTypes: [],
    },
    {
      name: "AdobeRGB Color Gamut",
      excludeForPromptTypes: [],
    },
    {
      name: "Suitable for Print Photo Editing",
      excludeForPromptTypes: [],
    },
    {
      name: "Color Depth",
      excludeForPromptTypes: [],
    },
    {
      name: "Contrast",
      sheetName: "Contrast Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Brightness",
      sheetName: "Brightness Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Color Volume",
      sheetName: "Color Volume Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Total Response Time (in ms)",
      sheetName: "Total Response Time",
      excludeForPromptTypes: [],
    },
    {
      name: "VRR Range (in Hz)",
      sheetName: "VRR Range",
      excludeForPromptTypes: [],
    },
    {
      name: "Strobing",
      excludeForPromptTypes: [],
    },
    {
      name: "Ghosting",
      sheetName: "Response Time Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Pixels Per Inch",
      excludeForPromptTypes: [],
    },
    {
      name: "Coating",
      excludeForPromptTypes: [],
    },
    {
      name: "Text Quality",
      sheetName: "Text Quality Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Image Clarity",
      sheetName: "Resolution Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "HDMI 1.4 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "HDMI 2.0 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "HDMI 2.1 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "Micro HDMI Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "DisplayPort 1.2 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "DisplayPort 1.4 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "DisplayPort 2.0 Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "Thunderbolt Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "USB Ports",
      excludeForPromptTypes: [],
    },
    {
      name: "USB Power Delivery (in Watts)",
      sheetName: "Power Delivery",
      excludeForPromptTypes: [],
    },
    {
      name: "Casual Gaming Score",
      sheetName: "Casual Gaming Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Competitive Gaming Score",
      sheetName: "Competitive Gaming Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Productivity Score",
      sheetName: "Productivity Score (Rescaled)",
      excludeForPromptTypes: [],
    },
    {
      name: "Media Consumption Score",
      sheetName: "Media Consumption Score (Rescaled)",
      excludeForPromptTypes: [],
    },
  ],
};
