import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { deriveCategoryVersion } from "@lib/utilities/shared-utilities";

export const CategoryApiClient = {
  withContext: (args: {
    productCategoryConfig: ProductCategoryConfig;
    apiDomain: string;
    table: string;
  }) => {
    const headers = {
      "x-category-name": args.productCategoryConfig.name,
      "x-version": deriveCategoryVersion(
        args.productCategoryConfig,
        args.table
      ),
    };

    return {
      fetch: async (path) => {
        const url = `${args.apiDomain}/${path}`;

        // At the moment, our API (via API gateway) returns S3 xml messages with
        // a 200 status code when there are errors or when it can't find something.
        // We should fix this problem, and then we can do something better than
        // a try/catch here.
        try {
          const response = await fetch(url, { headers });
          return await response.json();
        } catch (e) {
          // Do nothing
          console.error(e);
        }
      },
    };
  },
};
