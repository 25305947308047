import {
  getIconColorClass,
  getRecBgClass,
} from "@components/recommendation/done-panel";
import { useWindowDimensions } from "@lib/utilities/client-utilities";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

const LineScoreMeter = (props: {
  percent: number;
  color: string;
  maxPercent?: number;
  className?: string;
  fill?: string;
  opacityClass?: string;
  index?: number;
  width?: string;
  maxPhrase?: string;
  ticks?: string[];
}) => {
  const {
    percent,
    fill = "fill-none",
    opacityClass = "opacity-90",
    color,
    width = "flex-shrink:0 max-w-[600px]",
    maxPhrase,
    maxPercent,
    ticks,
  } = props;

  const dotRef = useRef(null);
  const drawPathRef = useRef(null);
  const arrowRef = useRef(null);

  const barRef = useRef(null);
  const [barWidth, setBarWidth] = useState(null);

  const { windowDimensions } = useWindowDimensions();

  useEffect(() => {
    if (barRef.current) {
      const w = barRef.current.offsetWidth - 8;
      setBarWidth(w);
    }
  }, [barRef.current, windowDimensions]);

  const { arcStrokeColor, circleFillColor, circleStrokeColor } =
    getIconColorClass(color);

  const adjustedPercent = Math.min(percent, 0.95);

  useEffect(() => {
    if (dotRef.current && drawPathRef.current) {
      const totalL = drawPathRef.current.getTotalLength();
      const endL = adjustedPercent * totalL;

      const maxL = maxPercent * getWidths(barWidth).delta;

      const p = drawPathRef.current.getPointAtLength(endL);

      dotRef.current.setAttribute("cx", `${p.x}`);
      dotRef.current.setAttribute("cy", `${p.y}`);

      drawPathRef.current.setAttribute("stroke-dashoffset", totalL - endL);
      drawPathRef.current.setAttribute("stroke-dasharray", totalL);

      arrowRef.current?.setAttribute("transform", `translate(${maxL},0)`);

      drawPathRef.current?.classList.remove("hidden");
      dotRef.current?.classList.remove("hidden");
      arrowRef.current?.classList.remove("hidden");
    }
  }, [adjustedPercent, dotRef, drawPathRef, maxPercent, arrowRef, barWidth]);

  const getWidths = (barWidth: number) => {
    const scale = 0.377;
    return {
      viewBoxWidth: barWidth * scale,
      startWidth: 3,
      endWidth: barWidth * scale - 8,
      delta: barWidth * scale - 11,
    };
  };

  const arcStartCoords = [getWidths(barWidth).startWidth, 20];
  const d = `M ${arcStartCoords.join(" ")} A0 0 0 1 1 ${
    getWidths(barWidth).endWidth
  } 20`;

  // Since there are usually three of these on a screen, we need unique ids.
  const clipPathId = `confidence-number-clip-${uuidv4()}`;

  return (
    <div ref={barRef} className={`${width} ${props.className || ""}`}>
      <svg viewBox={`0 0 ${getWidths(barWidth).viewBoxWidth} 40`}>
        {/* The background semicircle. */}
        <path
          d={d}
          className={`stroke-none fill-none opacity-0 origin-center`}
        />

        {/* The white stroke path. */}
        <path
          d={d}
          className={`stroke-[3.5px] fill-none stroke-gray-300 ${fill} opacity-100 origin-center`}
          fill="none"
        />

        {/* Line ticks with labels */}
        {ticks &&
          ticks.map((tick, index) => {
            const tickX =
              3 +
              ((getWidths(barWidth).endWidth - getWidths(barWidth).startWidth) /
                (ticks.length - 1)) *
                index;
            return (
              <g key={index}>
                {/* Line tick */}
                <line
                  x1={tickX}
                  y1="21.75"
                  x2={tickX}
                  y2="24.75"
                  stroke="black"
                  strokeWidth="0.4"
                />
                {/* Tick label */}
                <text
                  x={tickX}
                  y="30"
                  textAnchor="middle"
                  fontSize="5"
                  fill="black"
                  className="font-semibold"
                >
                  {tick}
                </text>
              </g>
            );
          })}

        {/* The blue stroke path. */}
        <path
          ref={drawPathRef}
          d={d}
          strokeDashoffset={0}
          className={`${arcStrokeColor} stroke-[3.5px] draw-path hidden`}
          fill="none"
        />

        {/* The dot at the end. */}
        <circle
          ref={dotRef}
          r="3"
          cx={getWidths(barWidth).startWidth}
          cy="0"
          className={`${circleFillColor} stroke-[1px] ${circleStrokeColor} confidence-cap hidden`}
        />

        {/* The dots at the line ends*/}
        <circle
          r="1.7"
          cx={getWidths(barWidth).startWidth}
          cy="20"
          className={`${circleFillColor}`}
        />

        <circle
          r="1.7"
          cx={getWidths(barWidth).endWidth}
          cy="20"
          className={`fill-gray-300 opacity-100`}
        />

        {/* The arrow down marker */}
        {maxPercent && (
          <g ref={arrowRef} transform={`translate(0, 0)`} className="hidden">
            {maxPhrase && (
              <text
                fontSize={5}
                x={-10}
                y={7}
                fill="black"
                className={`font-semibold`}
              >
                {maxPhrase}
              </text>
            )}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              x={-10}
              y={-3.5}
              strokeWidth="0.75"
              strokeDasharray={9}
              stroke="currentColor"
              className="w-3 h-3"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.25 18.75 12 21m0 0-2.25-2.25M12 21V3"
              />
            </svg>
          </g>
        )}

        {/* Here, we display a scroll of five numbers before the target, and
         then the target, and then we apply animation styles to create a "scroll
         past" effect. */}
        <defs>
          <clipPath id={clipPathId}>
            <rect y="16" x="27" width="40" height="20" />
          </clipPath>
        </defs>
        <g clipPath={`url(#${clipPathId})`}></g>
      </svg>
    </div>
  );
};

export default LineScoreMeter;
