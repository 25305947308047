import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import PurchaseOption from "@lib/model/purchase-option";

const SeeOnAmazon = ({
  amazonPurchaseOption,
}: {
  amazonPurchaseOption: PurchaseOption;
}) => {
  if (!amazonPurchaseOption) {
    return null;
  }

  return (
    <a
      href={amazonPurchaseOption.link}
      target="_blank"
      rel="noreferrer"
      className="font-normal"
    >
      <div className="bg-white border border-keyline-1 p-4 rounded-lg flex gap-2">
        <StarIcon className="h-5 w-5 fill-accent-gold mt-1" />
        <h3 className="font-sans text-lg my-0">
          See reviews and images on Amazon
        </h3>
        <ArrowSmallRightIcon className="h-5 w-4 ml-auto mt-1" />
      </div>
    </a>
  );
};

export default SeeOnAmazon;
