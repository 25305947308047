import React, { useContext, useEffect, useState } from "react";
import { Product } from "@lib/model/product";
import Brightness from "@components/product/summary/brightness";
import {
  ChartPropsStandard,
  PanelLayout,
  PanelSetProps,
  useLimitedChartProducts,
} from "@components/product/summary/common";
import TvScreenMeter from "@components/product/summary/tv-screen-meter";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import useMultiResponse from "@lib/hooks/use-multi-response";
import MultiOrdChart from "@components/product/summary/multi-ord-chart";
import ComparePanel from "@components/product/summary/compare-panel";
import {
  TvContentPhraseMap,
  getContentWatched,
} from "@components/product/summary/tv-fns";
import { StandardCard } from "../done-page-fixtures";
import BudgetMeter from "@components/product/summary/budget-meter";
import { getRandomChartProducts } from "@components/compare/compare-fns";
import { ClassifiedAttributes } from "@server/storage/config/attribute";
import FiltersSummary from "../filters-summary";

const TvAttributeDisplay = (
  props: PanelSetProps & { feedbackPanel: JSX.Element }
) => {
  const { selectedProduct } = props;

  const { activeResponseSet } = useContext(RecUIContext);

  const multiResponse = useMultiResponse(
    "b400abdd-4fe1-4fc9-9a2f-abca66592ec7",
    activeResponseSet
  );

  const contentWatched = Object.keys(TvContentPhraseMap).filter((attribute) => {
    return getContentWatched(attribute, multiResponse);
  });

  return (
    // The repeated conditionals here are because we can't use a fragment due to
    // the way PanelLayout uses the children array.
    <PanelLayout>
      {props.selectedProduct && (
        <TvScreenMeter {...props} attributeName="screenScore" />
      )}
      {props.selectedProduct &&
        contentWatched.map((content) => (
          <TvScreenMeter key={content} {...props} attributeName={content} />
        ))}
      {props.selectedProduct && <Brightness {...props} />}
      {props.selectedProduct && (
        <BudgetMeter
          product={selectedProduct}
          budgetQuestionId="9969344a-dab9-4db4-9aa9-365dbfc03597"
        />
      )}
      {props.selectedProduct && (
        <FiltersSummary meetsNeeds={props.meetsNeeds} />
      )}
      {props.feedbackPanel}
    </PanelLayout>
  );
};

export const TvComparisonPanels = (props: PanelSetProps) => {
  const {
    selectedProduct,
    consideredProducts,
    recommendedProducts,
    productCategoryConfig,
    pageType,
  } = props;

  const plottedProducts = useLimitedChartProducts(
    consideredProducts,
    selectedProduct
  );

  const { activeResponseSet } = useContext(RecUIContext);

  let bestAttributeOptions = [
    "screenScore",
    "movieScore",
    "sportsScore",
    "gamingScore",
    "animationScore",
    "newsScore",
  ];

  const useCaseAnswers = useMultiResponse(
    "b400abdd-4fe1-4fc9-9a2f-abca66592ec7",
    activeResponseSet
  ).map(
    (response) =>
      ClassifiedAttributes.answerToAttributeHack.tvs[response.answerId]
  );

  bestAttributeOptions = bestAttributeOptions.sort((attA, attB) => {
    const indexA = useCaseAnswers.indexOf(attA);
    const indexB = useCaseAnswers.indexOf(attB);

    if (indexA === -1 && indexB === -1) {
      // If both elements are not in "multiResponse", keep their original order
      return 0;
    } else if (indexA === -1) {
      // If only "b" is in "multiResponse", move "a" after "b"
      return 1;
    } else if (indexB === -1) {
      // If only "a" is in "multiResponse", move "a" before "b"
      return -1;
    } else {
      // If both elements are in "multiResponse", sort based on their index in "multiResponse"
      return indexA - indexB;
    }
  });

  return (
    <>
      <StandardCard className="bg-panel-blue-3">
        <MultiOrdChart
          id="tvs-multichart"
          defaultAttribute={bestAttributeOptions[0]}
          attributeOptions={bestAttributeOptions}
          defaultProduct={selectedProduct}
          plottedProducts={plottedProducts}
          rankedProducts={recommendedProducts}
          pageType={pageType}
        />
      </StandardCard>
      <ComparePanel
        compareProducts={recommendedProducts}
        categoryName={productCategoryConfig.name}
      />
    </>
  );
};

/**
 * VS/landing-page chart.
 */
export const TvChartsStandard = (props: ChartPropsStandard) => {
  const {
    selectedProductIndex,
    compareProducts,
    allProducts,
    getRank,
    pageType,
  } = props;

  const selectedProduct = selectedProductIndex
    ? compareProducts[props.selectedProductIndex]
    : compareProducts[0];

  const [plottedProducts, setPlottedProducts] = useState<Product[]>([]);

  // Since there aren't enough small TVs to plot a good set of products, we
  // group them below a certain threshold.
  const smallGroupMaxSize = 42;

  // Derive plotted products once per mount. It may involve random sampling, so we
  // don't want it to change.
  useEffect(() => {
    if (plottedProducts.length) {
      return;
    }
    // Plot products that match any of the compare-products' screensize.
    const plottableProducts = allProducts.filter((product) => {
      const sizes = compareProducts.map((product) => {
        const sizeFloat = parseFloat(product.attributes["screenSize"].value);
        return sizeFloat > smallGroupMaxSize
          ? product.attributes["screenSize"].value
          : "_small";
      });

      const screenSizeGroup =
        parseFloat(product.attributes["screenSize"].value) > smallGroupMaxSize
          ? product.attributes["screenSize"].value
          : "_small";

      return sizes.includes(screenSizeGroup);
    });

    setPlottedProducts(
      plottableProducts.length > 20
        ? getRandomChartProducts(plottableProducts, 20, compareProducts)
        : plottableProducts
    );
  }, [props.compareProducts]);

  return (
    // TV Content Chart with tabs
    <>
      {plottedProducts.length && (
        <MultiOrdChart
          id="picture-score-plot"
          defaultAttribute="screenScore"
          attributeOptions={[
            "screenScore",
            "movieScore",
            "sportsScore",
            "gamingScore",
            "animationScore",
            "newsScore",
          ]}
          defaultProduct={selectedProduct}
          plottedProducts={plottedProducts}
          styledContainer={false}
          rankResolver={getRank}
          productCardClasses="bg-gray-100"
          pageType={pageType}
        />
      )}
    </>
  );
};

export default TvAttributeDisplay;
