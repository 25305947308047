import {
  getImageUrl,
  AssetType,
  toFixedWithoutRounding,
} from "@lib/utilities/shared-utilities";
import { Product } from "@lib/model/product";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import React, { useMemo, useState } from "react";
import { Filter } from "@lib/hooks/use-filters";
import { ModelResponseRecommendation } from "@lib/model/recommender-model";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { AttributeConfiguration } from "../../../../data/packages/model/src/productAttribute";
import { useWindowDimensions } from "@lib/utilities/client-utilities";
import ProductFeatureModal from "../product-feature-modal";
import ImageWithFallback from "@components/common/image-with-fallback";
import { FeatureUnits } from "@lib/model/enum";
import { getRecIconColorClass } from "@components/recommendation/done-panel";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import ArcScoreMeter from "./arc-score-meter";
import { getUnitsSuffix } from "@lib/utilities/rec-ui";
import { TextButton } from "@components/common/button";

export const SummaryTitles = (props: {
  first: React.ReactNode;
  llm?: string;
  second?: string;
  better?: React.ReactNode;
  disposition?: React.ReactNode;
  showSecondOnly?: boolean;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {props.first && (
        <div className="flex gap-1">
          <span className="text-base font-normal">
            <span className="inline mr-1">{props.first}</span>
            {!props.showSecondOnly && (props.llm || props.second) && (
              <TextButton
                onClick={() => {
                  setOpen(!open);
                }}
                className="text-base shrink-0 inline"
              >
                Learn more
              </TextButton>
            )}
          </span>
        </div>
      )}
      {!props.showSecondOnly && (props.second || props.llm) && (
        <div
          className={`${
            open ? "block" : "hidden"
          } my-2 text-base font-normal leading-tight`}
        >
          {props.llm || props.second}
        </div>
      )}
      {props.better && <div className="leading-none mt-2">{props.better}</div>}
      {props.showSecondOnly && props.second && (
        <div className={`my-2 text-sm text-gray-500 font-normal leading-tight`}>
          {props.second}
        </div>
      )}
    </>
  );
};

export const ScoreDisplay = (props: {
  attribute: AttributeConfiguration;
  product: Product;
  scoreClass?: string;
  unitClass?: string;
}) => {
  const { attribute, product, scoreClass, unitClass } = props;

  const value = product.attributes[attribute.slug].value;

  const score = parseFloat(value);

  const scoreDisplay =
    isNaN(score) ||
    [
      "resolution",
      "contrast",
      "refreshRate",
      "sdrBrightness",
      "hdrBrightness",
      "vrr",
      "ppi",
    ].includes(attribute.slug)
      ? value
      : toFixedWithoutRounding(
          parseFloat(product.attributes[attribute.slug].value),
          1
        );

  return (
    <div className="">
      <span className={`text-3xl ${scoreClass} font-semibold font-serif`}>
        {scoreDisplay}
      </span>
      {!isNaN(score) && (
        <span className={`ml-1 text-2xl ${unitClass} font-serif`}>
          {getUnitsSuffix(
            FeatureUnits[attribute.unit],
            product.attributes[attribute.slug].value,
            true
          )}
        </span>
      )}
    </div>
  );
};

/**
 * This is for the main cards, we will need a few more variants, not sure if we should make components for those as well
 */
export const ArcScoreDisplay = (props: {
  attribute?: AttributeConfiguration;
  product: Product;
  color: string;
  score: number;
  icon?: React.FC<{ className: string }>;
  max?: number;
  type?: "default" | "scoreInside" | "scoreBelow";
  index?: number;
  className?: string;
  showScore?: boolean;
  showAdjective?: boolean;
  showUnits?: boolean;
}) => {
  const {
    attribute,
    product,
    score,
    color,
    icon,
    max = 10,
    type = "default",
    index = 0,
    className,
    showScore = true,
    showAdjective = false,
    showUnits = false,
  } = props;

  const Icon = icon;
  const pct = score / max;

  if (type === "default" && attribute && icon) {
    return (
      <div
        className={`flex flex-row items-center gap-4 mb-2 ${
          showScore && "min-w-[224px]"
        }`}
      >
        <div className="flex flex-col items-center gap-0">
          <div className="h-0 w-0 relative right-[14px] top-[28px]">
            <Icon className={`${getRecIconColorClass(color)} w-7 h-7`} />
          </div>
          <ArcScoreMeter percent={pct} color={color} />
          <div className="font-bold text-sm">{getQualitativeWord(score)}</div>
        </div>
        {showScore && <ScoreDisplay attribute={attribute} product={product} />}
      </div>
    );
  } else if (type === "scoreInside") {
    return (
      <div className="flex flex-row items-center gap-4 mb-2">
        <div className="flex flex-col items-center gap-0">
          <div
            className={`font-serif font-semibold text-sm w-0 h-0 relative top-[10px] right-[10.5px]`}
          >
            {toFixedWithoutRounding(score, 1)}
          </div>
          <ArcScoreMeter percent={pct} color={color} width="w-[40px]" />
        </div>
      </div>
    );
  } else if (type === "scoreBelow") {
    return (
      <div className={`flex flex-col items-center gap-4 mb-2`}>
        <div className="flex flex-col items-center gap-0">
          <div className="h-0 w-0 relative right-[14px] top-[28px]">
            <Icon className={`${getRecIconColorClass(color)} w-7 h-7`} />
          </div>
          <ArcScoreMeter percent={pct} color={color} />
          {showAdjective ? (
            <div className="font-bold text-sm">{getQualitativeWord(score)}</div>
          ) : (
            <ScoreDisplay
              attribute={attribute}
              product={product}
              scoreClass={showUnits ? "text-base" : "text-xl"}
              unitClass={showUnits ? "text-sm -ml-[1px]" : "hidden"}
            />
          )}
        </div>
      </div>
    );
  }
};

// @TODO - the children mapping pattern here shouldn't be needed. Instead, let's
// add a component that wraps the done panel in this markup and use that where
// needed.
export const PanelLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="md:columns-2 md:gap-3">
      {React.Children.toArray(children).map((child, i) => child)}
    </div>
  );
};

export const ChevronDown = () => (
  <ImageWithFallback
    src={getImageUrl("short-chevron.svg", AssetType.Icon)}
    alt="Chevron down"
    className="mx-auto mt-2 mb-3"
  />
);

/**
 * A question mark icon that spawns a small modal, used for panel explanations.
 */
export const PanelExplainer = ({
  title,
  text,
  jsxText,
  className = "",
}: {
  title: string;
  text?: string;
  jsxText?: React.ReactNode;
  className?: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ProductFeatureModal
        open={modalOpen}
        name={title}
        jsxText={jsxText}
        text={text}
        onClose={() => setModalOpen(false)}
      />
      <QuestionMarkCircleIcon
        className={`fill-blue-600 w-5 h-5 cursor-pointer shrink-0 ${className}`}
        onClick={() => setModalOpen(true)}
      />
    </>
  );
};

export const PriceCompTitle = ({
  attribute,
}: {
  attribute: AttributeConfiguration;
}) => {
  return (
    <div className="flex items-center justify-center gap-1">
      <h3 className="text-lg font-sans font-normal">
        <strong>Price</strong> vs{" "}
        <strong>{attribute.chartName || attribute.displayName}</strong>
      </h3>
      <PanelExplainer
        title={attribute.shortName}
        text={attribute.explanation}
      />
    </div>
  );
};

export const getExplanation = (
  recommendation: ModelResponseRecommendation,
  explanationType: string
) => {
  return recommendation.explanations.find(
    (explanation) => explanation.explanationType === explanationType
  );
};

export interface PanelSetProps {
  selectedProduct: Product;
  recommendedProducts: Product[];

  /**
   * Considered products are ranked by how well they match.
   */
  consideredProducts: Product[];
  productCategoryConfig: ProductCategoryConfig;
  filters?: Filter[];
  recommendations: { [productId: string]: ModelResponseRecommendation };
  meetsNeeds?: boolean;
  isShare?: boolean;
  pageType?: string;
}

export const useLimitedChartProducts = (
  consideredProducts: Product[],
  selectedProduct: Product,
  nSmall = 15,
  nLarge = 20
) => {
  const { breakpoint } = useWindowDimensions();
  const n = breakpoint === "xs" ? nSmall : nLarge;

  return useMemo(() => {
    const plottedProducts = consideredProducts.slice(0, n);

    // Ensure that other products selected get into the list.
    if (
      selectedProduct &&
      !plottedProducts.find((p) => p.id === selectedProduct.id)
    ) {
      plottedProducts.push(selectedProduct);
    }

    return plottedProducts;
  }, [consideredProducts, selectedProduct]);
};

export interface ChartPropsStandard {
  productCategory: ProductCategoryConfig;

  attributes: AttributeConfigurationMap;

  /**
   * All of the products that you want to show on the chart.
   */
  allProducts: Product[];

  /**
   * A smaller set of products that are being highlighted for comparison.
   */
  compareProducts: Product[];

  /**
   * A function for determining the html content of the dots, e.g. 1-2-3, or A,B etc.
   */
  getRank: (product: Product) => string;

  /**
   * Indicates the index of the selected product, out of the compareProducts
   * attribute.
   */
  selectedProductIndex?: number;

  /**
   * Indicates the page on which the chart is located
   */
  pageType?: string;
}
