import { ProductCategoryContext } from "contexts/product-category-context";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import {
  debounce,
  matchAllWordsFuzzy,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { useContext, useState, useCallback, useRef, useEffect } from "react";
import { Product } from "@lib/model/product";
import Autocomplete from "react-autocomplete";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import {
  scrollToRef,
  useWindowDimensions,
} from "@lib/utilities/client-utilities";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

/**
 * A text/autocomplete element for selecting which products to compare.
 *
 * We rely on react-autocomplete package to handle details like a11y and key-nav.
 */
const ProductSelectInput = ({
  position,
  searchEvent,
  selectEvent,
  placeholder,
  testId,
  defaultValue,
  onSelect,
  className = "",
  onSearch,
}: {
  position?: number;
  selectEvent: Events;
  searchEvent: Events;
  placeholder: string;
  testId?: string;
  defaultValue?: string;
  onSelect?: (args: {
    position: number;
    value: string;
    product?: Product;
  }) => void;
  onSearch?: (ars: { position: number; value: string }) => void;
  className?: string;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { products } = useGlobalAllProducts();
  const ref = useRef(null);
  const { aboveBreakpoint } = useWindowDimensions("sm");
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const [menuOpen, setMenuOpen] = useState(false);

  // This allows other components to set the local state when URL changes.
  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue);
    } else {
      setInputValue("");
    }
  }, [defaultValue]);

  const trackChange = useCallback(
    debounce((productSearchText) => {
      eventTracker.track(searchEvent, {
        productCategory: productCategoryConfig.name,
        productSearchText,
      });
    }),
    []
  );

  const handleKeyDown = (e) => {
    // Note - this doesn't (and shouldn't) fire when someone uses the arrow keys
    // + enter to select an option.
    if (onSearch && menuOpen && e.key === "Enter") {
      onSearch({ position, value: inputValue });
    }
  };

  // If users can "search" wih this input, track use of the enter key to submit
  // the search. For instance, they may submit "iphone" which doesn't resolve to
  // a specific product.
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    // The weird selector here is because react-autocomplete makes an inline-styled
    // div that we can't put classes on.
    <div
      className={`w-full sm:w-auto relative [&>*:first-child]:w-full ${className}`}
      ref={ref}
    >
      {products && (
        <>
          <Autocomplete
            menuStyle={{
              borderRadius: "8px",
              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
              background: "white",
              padding: "0",
              position: "absolute",
              left: "0",
              top: "46px",
              width: "100%",
              overflow: "auto",
              maxHeight: "400px",
              zIndex: "20",
              cursor: "pointer",
            }}
            inputProps={{
              className: "border border-gray-500 rounded-lg w-full p-2",
              placeholder,
              "data-testid": testId,
              onFocus: (e) => {
                if (!aboveBreakpoint) {
                  scrollToRef(ref, true);
                }
              },
            }}
            items={products
              .sort((productA, productB) => {
                const shortNameA = getProductLabels(productA).shortLabel;
                const shortNameB = getProductLabels(productB).shortLabel;

                if (shortNameA < shortNameB) {
                  return -1;
                } else if (shortNameA > shortNameB) {
                  return 1;
                } else {
                  return 0;
                }
              })
              .filter(
                (product) =>
                  product.isCanonical &&
                  matchAllWordsFuzzy(
                    inputValue.toLocaleLowerCase(),
                    getProductLabels(product).shortLabel
                  )
              )}
            getItemValue={(product: Product) => product.id}
            onChange={(e) => {
              setInputValue(e.target.value);
              trackChange(e.target.value);
            }}
            value={inputValue}
            onSelect={(id) => {
              const product = products.find((product) => product.id === id);

              if (product) {
                setInputValue(getProductLabels(product).shortLabel);

                if (onSelect) {
                  onSelect({ position, value: id, product });
                  eventTracker.track(selectEvent, {
                    productCategory: productCategoryConfig.name,
                    product: getProductLabels(product).shortLabel,
                    productId: product.id,
                  });
                }
              }
            }}
            onMenuVisibilityChange={(open) => setMenuOpen(open)}
            renderItem={(product: Product, isHighlighted) => (
              <div
                className={`text-left w-full py-1 px-2 product-select--item ${
                  isHighlighted
                    ? "bg-blue-600 text-white product-select--highlighted"
                    : ""
                }`}
                key={product.id}
              >
                {getProductLabels(product).shortLabel}
              </div>
            )}
          />
          {onSearch && (
            <MagnifyingGlassIcon
              className="h-10 w-10 p-2.5 absolute right-0 bottom-1/2 translate-y-[50%] cursor-pointer"
              onClick={() => onSearch({ position, value: inputValue })}
            />
          )}
        </>
      )}
      {!products && <ProductSelectInputSkeleton />}
    </div>
  );
};

export const ProductSelectInputSkeleton = () => (
  <div className="w-full bg-white h-[42px] rounded-xl border border-keyline-1"></div>
);

export default ProductSelectInput;
