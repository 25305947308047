import * as d3 from "d3";
import { useD3 } from "@lib/hooks/use-d3";
import { forwardRef, useCallback, useEffect, useState } from "react";
import {
  AssetType,
  getImageUrl,
  getProductAttributeAsFloat,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { Product } from "@lib/model/product";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import ProductCardStatsModal from "@components/product/product-card/product-card-stats-modal";
import { MiniProductCard } from "@components/product/product-card/mini-product-card";
import { animated, useInView, useSpring, useSpringRef } from "react-spring";
import { useGesture } from "@use-gesture/react";
import React from "react";
import usePreviousValue from "@lib/hooks/use-previous-value";
import { AttributeConfiguration } from "../../../data/packages/model/src/productAttribute";

type PlotAxis = AttributeConfiguration | "bestPrice";

const ScatterPlot = forwardRef(function ScatterPlot(props: {
  subtitle?: string;
  id: string;
  productCategoryName: string;
  defaultProduct: Product;
  rankedProducts?: Product[];
  plottedProducts: Product[];
  xAxisProp: PlotAxis;
  yAxisProp: PlotAxis;
  className?: string;
  rankResolver?: (product: Product) => string;
  autoSelectDelay?: number;
  productCardClasses?: string;
  pageType?: string;
}) {
  const {
    plottedProducts,
    rankedProducts,
    id,
    productCategoryName,
    rankResolver,
    pageType,
    xAxisProp,
    yAxisProp,
    defaultProduct,
  } = props;
  const [chartProduct, setChartProduct] = useState<Product>(null);
  const [chartAnimatedOnce, setChartAnimatedOnce] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);
  const api = useSpringRef();

  const animateTooltip = useCallback(
    (value: number, onStart: () => unknown, onMiddle: () => unknown) => {
      setChartAnimatedOnce(true);
      api.start({
        to: async (next, cancel) => {
          await next({ xy: [0, 150] });
          onMiddle();
          await next({ xy: [0, !value ? 150 : 0] });
        },
        config: { duration: 250 },
        onStart,
      });
    },
    [api]
  );

  /**
   * Set the highlighted style for a dot.
   */
  const setDotHighlight = useCallback(
    (product, state) => {
      const productDot = d3.select(`#${id}--point-${product.id}`);
      const labelDot = d3.select(`#${id}--label-${product.id}`);
      if (!productDot.node()) return;

      if (state) {
        productDot.node().classList.add("swarm-dot-border");
        if (labelDot.node()) {
          labelDot.node().classList.add("swarm-dot-highlight");
          labelDot.node().classList.remove("text-slate-700");
          labelDot.node().classList.remove("bg-white");
          labelDot.node().classList.remove("border");
        }
      } else {
        productDot.node().classList.remove("swarm-dot-border");
        if (labelDot.node()) {
          labelDot.node().classList.remove("swarm-dot-highlight");
          labelDot.node().classList.add("text-slate-700");
          labelDot.node().classList.add("bg-white");
          labelDot.node().classList.add("border");
        }
      }
    },
    [id]
  );

  /**
   * Place the tooltip and set its content.
   */
  const setActiveDot = useCallback(
    (product) => {
      // Unhighlight previous dot. We can't rely on mouseout event do this because
      // the initial highlighted dot won't immediately get mouse-outed.
      const prevDot = d3.select(`#${id}--chart-root .swarm-dot-border`).node();
      if (prevDot) {
        const prevProduct = plottedProducts[prevDot.getAttribute("data-index")];
        setDotHighlight(prevProduct, false);
      }

      if (!product?.id) {
        return;
      }

      const productDot = d3.select(`#${id}--point-${product.id}`);
      if (!productDot.node()) {
        return;
      }
      setDotHighlight(product, true);
    },
    [setDotHighlight, plottedProducts, id]
  );

  const selectChartProduct = useCallback(
    (product: Product) => {
      animateTooltip(
        1,
        () => {
          setActiveDot(product);
        },
        () => {
          setChartProduct(product);
        }
      );
    },
    [animateTooltip, setActiveDot, setChartProduct]
  );

  // Simplify arguments for the D3 scatterplot function.
  const getAxisDisplayData = (axisProp: PlotAxis) => {
    // Price
    if (axisProp === "bestPrice") {
      return {
        slug: "bestPrice",
        label: "Price",
        getTickValue: (tickValue) => `${tickValue / 100}`.padStart(6, "\xa0"),
      };
    }

    // Normal attribute scores.
    return {
      label: axisProp.chartName || axisProp.displayName,
      slug: axisProp.slug,
      getTickValue: (tickValue) => d3.format(".2")(tickValue),
    };
  };

  const yAxis = getAxisDisplayData(yAxisProp);
  const xAxis = getAxisDisplayData(xAxisProp);

  const chartRef = useD3(
    (ref) =>
      ScatterPlotChart({
        data: plottedProducts,
        chartDiv: ref,
        id,
        chartProduct,
        rankedProducts,
        xAxis,
        yAxis,
        productCategoryName,
        selectChartProduct,
        setActiveDot,
        rankResolver,
        pageType,
      }),
    [
      chartProduct,
      xAxisProp,
      yAxisProp,
      rankResolver,
      plottedProducts,
      defaultProduct,
      selectChartProduct,
      setActiveDot,
    ]
  );

  const springs = useSpring({
    ref: api,
    from: { xy: [0, 150] },
  });
  const [miniProductCardRef, miniProductCardInView] = useInView({
    amount: 0.5,
    once: false,
  });

  const selectInitialChartProduct = useCallback(() => {
    if (miniProductCardInView && !chartAnimatedOnce) {
      selectChartProduct(defaultProduct);
    }
  }, [miniProductCardInView, chartAnimatedOnce, selectChartProduct]);

  // This allows the chart to re-select the tooltip correctly without remounting.
  const prevChartProduct = usePreviousValue<Product>(defaultProduct);
  useEffect(() => {
    if (defaultProduct.id !== prevChartProduct?.id) {
      setChartAnimatedOnce(false);
    }
  }, [defaultProduct]);

  // Deselect any product that has a NAN score, and select another eligible product
  // instead.
  useEffect(() => {
    const notNanPlottedProducts = plottedProducts.filter(
      (product) =>
        !axisPropIsNan(product, xAxisProp) && !axisPropIsNan(product, yAxisProp)
    );

    if (
      (chartProduct && axisPropIsNan(chartProduct, xAxisProp)) ||
      axisPropIsNan(chartProduct, yAxisProp)
    ) {
      selectChartProduct(notNanPlottedProducts[0]);
    }
  }, [xAxisProp, yAxisProp, plottedProducts, chartProduct]);

  useGesture(
    {
      onScroll: ({ xy: [, y] }) => {
        selectInitialChartProduct();
      },
    },
    {
      target: window,
    }
  );

  useEffect(() => {
    const delay = props.autoSelectDelay || 500;
    if (delay > 0) {
      const timeout = setTimeout(() => {
        selectInitialChartProduct();
      }, delay);
      return () => clearTimeout(timeout);
    }
  }, [props.autoSelectDelay, selectInitialChartProduct]);

  return (
    <div>
      <div className={`${props.className || ""} mt-6 mb-3`}>
        <div className="relative pl-3 pb-5">
          <div className="absolute" id={`${id}--scale`} />

          <div ref={chartRef} id={`${id}--chart-root`}></div>
        </div>
      </div>
      <div className="overflow-clip h-24" ref={miniProductCardRef}>
        <animated.div
          style={{
            transform: springs.xy.to((x, y) => `translate(${x}%, ${y}%)`),
          }}
        >
          {chartProduct && (
            <>
              <MiniProductCard
                productCategoryName={props.productCategoryName}
                product={chartProduct}
                // Display attributes for both axes, but not the price.
                displayAttributes={[
                  {
                    ...yAxis,
                    value: getProductPropValue(chartProduct, yAxis.slug),
                  },
                  {
                    ...xAxis,
                    value: getProductPropValue(chartProduct, xAxis.slug),
                  },
                ].filter((data) => data.slug !== "bestPrice")}
                onTitleClick={() => setStatsModalOpen(true)}
                className={props.productCardClasses || undefined}
              />
              <ProductCardStatsModal
                open={statsModalOpen}
                closeHandler={() => setStatsModalOpen(false)}
                product={chartProduct}
                productCategoryConfigName={props.productCategoryName}
              />
            </>
          )}
        </animated.div>
      </div>
    </div>
  );
});

/**
 * Determines whether an axis prop is NaN.
 */
export const axisPropIsNan = (product: Product, axisProp: PlotAxis) => {
  if (axisProp === "bestPrice") {
    return isNaN(product.bestPrice);
  }

  return isNaN(getProductAttributeAsFloat(product, axisProp.slug));
};

export const getProductPropValue = (product: Product, prop: string) => {
  if (prop === "bestPrice") {
    return product.bestPrice;
  }

  return getProductAttributeAsFloat(product, prop);
};

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/mirrored-beeswarm
const ScatterPlotChart = (args: {
  data: Product[];
  chartDiv;
  id;
  chartProduct: Product;
  rankedProducts: Product[];
  xAxis: { slug: string; label: string; getTickValue: (number) => string };
  yAxis: { slug: string; label: string; getTickValue: (number) => string };
  productCategoryName: string;
  selectChartProduct: (product: Product) => unknown;
  setActiveDot: (product: Product) => unknown;
  rankResolver: (product: Product) => string;
  pageType: string;
}) => {
  const {
    data,
    chartDiv,
    id,
    chartProduct,
    rankedProducts,
    xAxis,
    yAxis,
    productCategoryName,
    selectChartProduct,
    setActiveDot,
    rankResolver,
    pageType,
  } = args;

  // Clear the dots each time. We can optimize this if we need to later.
  chartDiv.selectAll(".chart-dot").remove();

  // Don't change this without changing the dot tailwind class.
  const radius = 24;
  const dotHeight = radius * 2;
  const chartHeight = 364;

  const scalePaddingTop = 0;
  const scalePadding = 20;

  const xRange = [
    0 + dotHeight,
    chartDiv.node().getBoundingClientRect().width - dotHeight,
  ];
  const yRange = [0 + dotHeight + scalePadding, chartHeight - scalePaddingTop];

  // Compute values.
  const yValues = d3.map(data, (data: Product) =>
    getProductPropValue(data, yAxis.slug)
  );

  const xValues = d3.map(data, (data: Product) =>
    getProductPropValue(data, xAxis.slug)
  );

  // Compute which data points are considered defined.
  const I = d3.range(yValues.length).filter((i) => !isNaN(yValues[i]));

  const xDomain = d3.extent(xValues);
  const yDomain = d3.extent(yValues);

  const yScale = d3.scaleLinear(yDomain, yRange).nice();
  const xScale = d3.scaleLinear(xDomain, xRange).nice();

  /**
   * Gets the rank for a product dot, if possible.
   */
  const getRank = rankResolver
    ? rankResolver
    : (product) => {
        if (!rankedProducts) {
          return;
        }
        const index = rankedProducts.findIndex(
          (item) => item.id === product.id
        );
        if (index !== -1) {
          return index + 1;
        }
      };

  const beeswarm = beeswarmForce()
    .y((d) => yScale(getProductPropValue(d, yAxis.slug)))
    .x((d) => xScale(getProductPropValue(d, xAxis.slug)))
    .rec((d) => getRank(d))
    .r((d) => (getRank(d) ? 1 : 0.8) * radius);

  const forcedData = beeswarm(data);

  chartDiv.attr("style", `height: ${chartHeight}px`);

  const chartRect = chartDiv.node().getBoundingClientRect();
  const scaleEl = d3.select(`#${id}--scale`);

  // Clear scale container before rendering.
  scaleEl.html("");

  // Y axis line
  scaleEl
    .append("div")
    .attr("class", `border-gray-500 absolute border-solid border-l w-0`)
    .style("left", `${scalePadding}px`)
    .style("top", `${scalePaddingTop}px`)
    .style("height", `${chartRect.height - scalePadding - scalePaddingTop}px`);

  // Y axis label
  scaleEl
    .append("div")
    .attr(
      "class",
      "text-xs text-gray-900 absolute -rotate-90 origin-left top-0 whitespace-nowrap text-center pb-1"
    )
    .style("font-weight", "700")
    .style("left", `-10px`)
    .style("top", `${chartRect.height - scalePadding}px`)
    .style("width", `${chartRect.height}px`)
    .text(yAxis.label);

  // X axis line
  scaleEl
    .append("div")
    .attr("class", `border-gray-500 absolute border-solid border-b h-0`)
    .style("left", `${scalePadding}px`)
    .style("top", `${chartRect.height - scalePadding}px`)
    .style("width", `${chartRect.width - scalePadding}px`);

  // X axis label
  scaleEl
    .append("div")
    .attr(
      "class",
      "text-xs text-gray-900 absolute -translate-x-[50%] pt-1 whitespace-nowrap"
    )
    .style("font-weight", "700")
    .style("left", `${(chartRect.width + scalePadding) / 2}px`)
    .style("top", `${chartHeight}px`)
    .text(xAxis.label);

  // Y axis ticks
  yScale.ticks(5).forEach((tick) => {
    const tickString = tick.toFixed(2);
    if (tickString.slice(-1) === "0") {
      scaleEl
        .append("div")
        .attr("class", "text-xs text-gray-900 absolute ")
        .style("font-weight", "600")
        .style("right", `${4 - scalePadding}px`)
        .style("top", `${chartRect.height - yScale(tick)}px`)
        .text(yAxis.getTickValue(tick));
    }
  });

  // X axis ticks
  xScale.ticks(5).forEach((tick) => {
    const tickString = tick.toFixed(2);
    if (tickString.slice(-1) === "0") {
      scaleEl
        .append("div")
        .attr("class", "text-xs text-gray-900 absolute")
        .style("font-weight", "600")
        .style("left", `${xScale(tick) - 15}px`)
        .style("top", `${chartRect.height - scalePadding + 4}px`)
        .text(xAxis.getTickValue(tick));
    }
  });

  if (chartDiv) {
    // Render data points.
    const dots = chartDiv
      .selectAll()
      .data(I)
      .join("div")
      .attr("id", (i) => `${id}--point-${data[i].id}`)
      .attr(
        "class",
        (i) =>
          `cursor-pointer chart-dot absolute w-8 h-8 rounded-full bg-white border-0 border-gray-500 flex items-center justify-center`
      )
      .attr("data-index", (i) => i)
      .style("filter", "drop-shadow(0px 4px 8px rgba(0,0,0,0.15))")
      .style("left", (i) => {
        const left = forcedData[i].x;
        return `${left}px`;
      })
      .style("top", (i) => {
        const top = chartHeight - forcedData[i].y;
        return `${top}px`;
      })
      .on("click", function (event, i) {
        selectChartProduct && selectChartProduct(data[i]);
        EventTracker.track(Events.DoneGraphInteract, {
          productName: getProductLabels(data[i]).shortLabel,
          productId: data[i].id,
          page_type: pageType,
          productCategory: productCategoryName,
          attributeName: yAxis.slug,
        });
      });

    // Append images.
    dots
      .append("img")
      .attr("class", "max-h-[22px] max-w-[22px]")
      .attr("src", (i) =>
        getImageUrl(
          data[i].image,
          AssetType.ProductImage,
          {
            width: 24,
          },
          productCategoryName
        )
      );

    // Append rank markers.
    dots
      .append("div")
      .html((i) => getRank(data[i]))
      .attr("id", (i) => `${id}--label-${data[i].id}`)
      .attr(
        "class",
        (i) =>
          `${
            getRank(data[i]) ? "" : "hidden"
          } cursor-pointer chart-dot absolute w-5 h-5 rounded-full text-slate-700 text-xs pt-[1px] text-center bg-white border border-gray-500 font-bold`
      )
      .attr("data-index", (i) => i)
      .style("left", "-5px")
      .style("top", "-5px");
  }

  chartProduct && setActiveDot && setActiveDot(chartProduct);
  return chartDiv.node();
};

/**
 * Factory for "beeswarm" force simulation
 */
function beeswarmForce() {
  let x = (d): any => d[0];
  let y = (d): any => d[1];
  let r = (d): any => d[2];
  let rec = (d): any => d[3];
  let ticks = 300;

  function beeswarm(data) {
    const entries = data.map((d) => {
      return {
        x0: typeof x === "function" ? x(d) : x,
        y0: typeof y === "function" ? y(d) : y,
        r: typeof r === "function" ? r(d) : r,
        rec: typeof rec === "function" ? rec(d) : rec,
        data: d,
      };
    });

    const simulation = d3
      .forceSimulation(entries)
      .force(
        "x",
        d3.forceX((d) => d.x0).strength((d) => (d.rec ? 0.7 : 0.4))
      )
      .force(
        "y",
        d3.forceY((d) => d.y0).strength((d) => (d.rec ? 0.9 : 0.5))
      )
      .force(
        "collide",
        d3.forceCollide((d) => d.r)
      );
    for (let i = 0; i < ticks; i++) {
      simulation.tick();
    }

    return entries;
  }

  beeswarm.x = (f): any => (f ? ((x = f), beeswarm) : x);
  beeswarm.y = (f): any => (f ? ((y = f), beeswarm) : y);
  beeswarm.r = (f): any => (f ? ((r = f), beeswarm) : r);
  beeswarm.rec = (f): any => (f ? ((rec = f), beeswarm) : rec);
  beeswarm.ticks = (n) => (n ? ((ticks = n), beeswarm) : ticks);

  return beeswarm;
}

export default ScatterPlot;
