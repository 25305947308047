const UsageIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className={`${props.className || "fill-blue-600"}`}
  >
    <path d="M21 3H3c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h18c.6 0 1-.4 1-1V4c0-.6-.4-1-1-1zM8.4 17H6.1v-4.7h2.4V17zm4.8 0h-2.4V7h2.4v10zm4.7 0h-2.4V9.5h2.4V17z" />
  </svg>
);

export { UsageIcon };
