/**
 * A custom icon that shows an ear, as an outline.
 */
const EarIconOutline = ({ className = "" }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.74023 14.8434L9.51573 14.5081C10.1808 14.2205 10.711 13.69 10.9983 13.0248L11.1173 12.7491C11.3496 12.2112 11.3515 11.6017 11.1227 11.0624L11.0767 10.954C10.8961 10.5282 10.5618 10.186 10.1405 9.99537L10.0431 9.95133C9.28865 9.61002 8.88622 8.77798 9.08706 7.97461V7.97461C9.17948 7.60495 9.39247 7.27664 9.69237 7.04159L10.2352 6.61611C10.7619 6.20328 11.3919 5.9431 12.0565 5.86399L12.087 5.86036C12.7464 5.78186 13.4146 5.89015 14.0155 6.1729L14.1025 6.21385C14.5351 6.41744 14.9154 6.71744 15.2141 7.09081V7.09081C15.5579 7.52057 15.7823 8.03336 15.8648 8.57751L15.9836 9.36193"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6 8.67725L6.66186 6.77801C6.96571 5.90608 7.49352 5.12939 8.19234 4.52586V4.52586C8.90386 3.91138 9.76692 3.49861 10.6919 3.33043L10.9611 3.28149C11.9774 3.0967 13.0237 3.16595 14.0068 3.48309L14.2676 3.56722C15.1063 3.83775 15.8686 4.30379 16.4917 4.9269V4.9269C17.154 5.58922 17.6383 6.40817 17.8994 7.30769L17.9697 7.54993C18.2413 8.48527 18.2991 9.4697 18.139 10.4304L18.1062 10.6272C17.9975 11.2794 17.7704 11.9062 17.436 12.4766V12.4766C17.2193 12.8463 16.9595 13.1889 16.662 13.4974L16.4919 13.6738C16.0254 14.1576 15.6302 14.7121 15.3239 15.3103V15.3103C14.9533 16.0338 14.7129 16.8236 14.6197 17.6311L14.5506 18.2299C14.4953 18.7088 14.3475 19.1722 14.1152 19.5946L13.9534 19.8888C13.5922 20.5455 12.9865 21.0369 12.2702 21.2573V21.2573C11.7562 21.4155 11.206 21.4265 10.6864 21.288V21.288C9.80203 21.0521 9.08344 20.4081 8.75253 19.5547L8.34921 18.5146"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default EarIconOutline;
