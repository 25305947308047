const DropletIcon = ({ className = "" }: { className?: string }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512.053 512.053"
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M261.36,437.387C202.48,437.28,154.8,389.6,154.693,330.72c0-5.867-4.8-10.667-10.667-10.667
                         c-5.867,0-10.667,4.8-10.667,10.667c0.107,70.613,57.387,127.893,128,128c5.867,0,10.667-4.8,10.667-10.667
                         C272.027,442.187,267.227,437.387,261.36,437.387z"
            />
            <path
              d="M263.387,3.04c-4.16-4.053-10.773-4.053-14.827,0C241.733,9.76,80.027,169.333,80.027,332.213
                         c0,99.2,78.933,179.84,176,179.84s176-80.64,176-179.84C432.027,168.373,270.32,9.76,263.387,3.04z M256.027,490.72
                         c-85.333,0-154.667-71.147-154.667-158.507c0-134.613,122.88-272.747,154.667-306.24
                         c31.787,33.387,154.667,170.88,154.667,306.24C410.693,419.573,341.253,490.72,256.027,490.72z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DropletIcon;
