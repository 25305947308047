import { ResponseSetWithResponses } from "@lib/model/response-set";
import { useBudgetQuestion, useQuestions } from "./use-questions";
import { useContext } from "react";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { QuestionWithAnswers } from "@lib/model/question";
import Answer from "@lib/model/answer";

export interface MultiResponse {
  questionid: string;
  answerId: string;
  name: string;
}
/**
 *
 * Use this hook to get the responses to a multi-response question
 *
 * @param questionId string The GUID of a multi-response question
 * @param responseSet ResponseSetWithResponses The response set to search for the question responses
 * @returns MultiResponse[] An array of the responses to the multi-response question
 *
 * @TODO retire this in favor of useResponseAnswers() since it uses known types
 *       and does the same thing.
 */
const useMultiResponse = (
  questionId: string,
  responseSet: ResponseSetWithResponses
): MultiResponse[] => {
  const { questions } = useQuestions();
  if (!questionId || !questions || !responseSet) {
    return [];
  }

  const chosenResponses = [];

  const multiResponse = responseSet.responses.find(
    (response) => response.questionId === questionId
  );

  const multiResponseQuestion = questions.find(
    (question) => question.id === questionId
  );

  if (multiResponse && multiResponseQuestion && multiResponse.answerIds) {
    multiResponse.answerIds.forEach((answerId) => {
      chosenResponses.push({
        questionId,
        answerId: answerId,
        name: multiResponseQuestion.answers.find(
          (answer) => answer.id === answerId
        )?.mainText,
      });
    });
  }

  return chosenResponses;
};

/**
 * Gets the anwer object that the user chose in their response to a particular
 * question.
 */
export const useResponseAnswers = (
  questionId: string
): {
  answers: Answer[];
  isSkip: boolean;
  answer: Answer | null;
  responded: boolean;
} => {
  const context = useContext(RecUIContext);
  const activeResponseSet = context?.activeResponseSet;

  const { questions } = useQuestions();

  const response = activeResponseSet?.responses?.find(
    (response) => response.questionId === questionId
  );

  const emptyDefaults = {
    answers: [],
    isSkip: response === undefined,
    answer: null,
    responded: false,
  };

  if (!questions) {
    return emptyDefaults;
  }

  const question = questions.find((question) => question.id === questionId);

  if (!response || !question) {
    return emptyDefaults;
  }

  const answers = response.answerIds
    ? question.answers.filter((answer) =>
        response.answerIds.find((answerId) => answerId === answer.id)
      )
    : [];

  // Check that
  const isSkip = answers.length === 0 || answers[0]?.isSkipSemantic;

  // Add a single answer for convenience, if the question is single response.
  return { answers, isSkip, answer: answers[0], responded: true };
};

/**
 * Gets the numeric value in cents of the user's stated budget
 */
export const useBudget = () => {
  const budgetQuestion = useBudgetQuestion();
  const { answer, isSkip } = useResponseAnswers(budgetQuestion?.id);

  const { questionMaxBudget, maxBudgetAnswerId } =
    getMaxBudgetForQuestion(budgetQuestion);

  return {
    userMaxBudget: answer ? answer.metadata["range"][1] : null,
    didUserChooseMaxBudget: answer?.id === maxBudgetAnswerId,
    questionMaxBudget,
    maxBudgetAnswerId,
    isSkip,
  };
};

const getMaxBudgetForQuestion = (budgetQuestion: QuestionWithAnswers) => {
  let questionMaxBudget = 0;
  let maxBudgetAnswerId = null;

  if (!budgetQuestion) {
    return { questionMaxBudget, maxBudgetAnswerId };
  }

  // iterate through the answers and find the answer with the highest budget
  budgetQuestion.answers.forEach((answer) => {
    const answerMaxBudget = answer.metadata["range"][1];
    if (answerMaxBudget > questionMaxBudget) {
      questionMaxBudget = answerMaxBudget;
      maxBudgetAnswerId = answer.id;
    }
  });

  return { questionMaxBudget, maxBudgetAnswerId };
};

export default useMultiResponse;
