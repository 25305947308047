import {
  AssetType,
  centsToDollars,
  getImageUrl,
  getProductImage,
  getProductLabels,
  getProductLandingPageUrl,
} from "@lib/utilities/shared-utilities";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { Product } from "@lib/model/product";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import React, { useEffect, useRef, useState } from "react";
import useElementOnScreen from "@lib/hooks/use-element-on-screen";
import { usePurchaseDataMultiple } from "@lib/hooks/use-purchase-data";
import ProductSelectInput from "@components/product/product-select-input";
import Events from "@lib/constants/events";
import VariantSelectInput, {
  VariantSelectDropdown,
} from "@components/product/variant-select-input";

import Link from "next/link";
import ImageWithFallback from "@components/common/image-with-fallback";
import { useRouter } from "next/router";
import eventTracker from "@lib/tracking/event-tracker";
import { Disposition } from "@components/product/purchase-data";
import { ExpectedReleaseCard } from "./compare-components";
import {
  VariantPriceSummary,
  VariantSelectTable,
} from "@components/product/VariantDropdown";
import {
  ArrowRightIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/20/solid";
import { StandardCard } from "@components/recommendation/done-page-fixtures";

export default function ProductCompareHero({
  products,
  attributes,
  category,
  onSelectProduct,
  setSelectedProducts,
  getComparison,
  setComparison,
  ctaRef,
}: {
  products: Product[];
  attributes: AttributeConfigurationMap;
  category: ProductCategoryConfig;
  onSelectProduct?: (product: Product, index: number) => void;
  setSelectedProducts?: any;
  getComparison;
  setComparison;
  ctaRef?: React.RefObject<HTMLDivElement>;
}) {
  const setComparingProduct = (newProduct, index) => {
    if (!newProduct) {
      return;
    }
    setInteracted((prev) => ({
      ...prev,
      [index]: true,
    }));
    if (onSelectProduct) {
      onSelectProduct(newProduct, index);
    }
  };

  const [firstLoad, setFirstLoad] = useState(true);

  const router = useRouter();

  const { productVariants, products: allProducts } = useGlobalAllProducts();

  useEffect(() => {
    if (allProducts) {
      const urlProductSlugs = router.asPath
        .split("#")[0]
        .replace("?internal=true", "")
        .split("/")
        .pop()
        .split("--vs--");
      if (urlProductSlugs.length === 2) {
        const urlProducts = urlProductSlugs.map((urlProductSlug) =>
          allProducts.find((product) => product.slug === urlProductSlug)
        );
        if (products[0] === urlProducts[1] || products[1] === urlProducts[0]) {
          setSelectedProducts(urlProducts.reverse());
        } else {
          setSelectedProducts(urlProducts);
        }
        if (!firstLoad) {
          getComparison(urlProducts, category, setComparison);
        }

        setFirstLoad(false);
      }
    }
  }, [
    router.asPath,
    allProducts,
    setSelectedProducts,
    getComparison,
    category,
    setComparison,
  ]);

  const getRightBorderClass = (index, numberOfSelectors) => {
    const borderClass =
      index !== numberOfSelectors - 1
        ? "sm:border-r-[1px] sm:-mr-[1px] sm:border-gray-400 sm:pr-4"
        : "sm:pl-4";

    return borderClass;
  };

  const getLeftBorderClass = (index) => {
    const borderClass =
      index !== 0 ? "sm:border-l-[1px] sm:border-gray-300 sm:pl-4" : "sm:pr-4";

    return borderClass;
  };

  const [interacted, setInteracted] = useState({ 0: false, 1: false });

  const displayPrices = products.map((product) =>
    centsToDollars(product.bestPrice)
  );

  const purchaseConditions = products.map(
    (product) => product.bestPurchaseOption?.condition
  );

  const displayPurchaseCondition = !(
    purchaseConditions[0] === "new" && purchaseConditions[1] === "new"
  );

  const underlines = {
    0: "border-accent-purple",
    1: "border-accent-orange",
  };

  return (
    <>
      {/* We use grid so that two-line product links don't make the dropdowns be at different heights. */}
      <div
        className={`bg-white z-20 grid grid-cols-2 gap-2 sm:gap-0 py-3 max-w-screen-xl`}
      >
        {/* Product links row */}
        {products.map((product, index) => (
          <div
            className={`flex flex-col md:flex-row gap-3 ${getRightBorderClass(
              index,
              products.length
            )} ${getLeftBorderClass(index)}`}
            key={product.id + index}
          >
            <div
              ref={index === 0 ? ctaRef : null}
              className={`relative flex justify-center md:justify-start`}
            >
              <a
                className={`relative flex justify-center p-2 h-16 w-16 sm:p-4 sm:h-32 sm:w-32 rounded-full border-2 ${underlines[index]}`}
                href={getProductLandingPageUrl(product, category)}
              >
                <ImageWithFallback
                  src={getImageUrl(
                    product.image,
                    AssetType.ProductImage,
                    { width: 150 },
                    category.name
                  )}
                  width={150}
                  alt={product.model}
                  className={`object-contain`}
                />
              </a>
            </div>
            <div className="flex flex-col w-full">
              <div
                className={`flex flex-row justify-center w-full sm:max-w-[500px] min-h-[38px] md:min-h-[42px] mx-auto mb-1`}
              >
                <VariantSelectInput
                  position={index}
                  setSelectedProduct={setComparingProduct}
                  selectEvent={Events.CompareProductChange}
                  searchEvent={Events.CompareProductSearch}
                  placeholder={`${getProductLabels(product).shortLabel}`}
                  product={product}
                  hasVariants={category.hasVariants}
                  numberOfSelectors={products.length}
                />
              </div>
              {/* <div className="flex justify-start font-semibold text-base w-full px-2 sm:px-3 my-2">
                {getProductLabels(product)?.shortLabel}
              </div> */}
              <div className={`flex flex-row w-full sm:max-w-[500px] mx-auto`}>
                {category.hasVariants && (
                  <VariantSelectDropdown
                    position={index}
                    product={product}
                    setSelectedProduct={setComparingProduct}
                    attributes={attributes}
                    numberOfSelectors={products.length}
                  />
                )}
              </div>
              {/* <div className="sm:max-w-[300px] mx-auto flex flex-row items-center mb-3">
                <span className="font-bold mr-2">{displayPrices?.[index]}</span>
                {displayPurchaseCondition && (
                  <Disposition
                    purchaseOption={product?.bestPurchaseOption}
                    className=""
                  />
                )}
              </div> */}
              <div
                className={`flex flex-row w-full sm:max-w-[500px] mx-auto my-2`}
              >
                {
                  <VariantSelectTable
                    product={product}
                    showSeeAllOptionsCta={false}
                    showSelector={false}
                    className="w-full"
                    index={index}
                  />
                }
              </div>
              {new Date(product.attributes["releaseDate"]?.value) >
                new Date() && (
                <div className="flex w-full my-2">
                  <ExpectedReleaseCard product={product} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <FixedTopContainer
        observableRef={ctaRef}
        // Similar to TOC container, but gap needed to be tweaked a bit.
        containerClass="container max-w-[1296px] px-2 sm:px-8 xl:grid xl:grid-cols-toc-side gap-x-4 gap-y-2"
      >
        <SmallProductVsHeader products={products} category={category} />
        <div className="flex justify-center md:col-start-2">
          <StandardCard
            className="!border-blue-500 inline-flex my-0 rounded-md"
            innerClassName="!p-0 !px-2"
          >
            <a
              href="#shopping"
              className="text-xs sm:text-sm hover:cursor-pointer font-semibold inline-flex items-center relative bottom-[1px] text-blue-500"
            >
              <span className="">See retailers</span>
            </a>
          </StandardCard>
        </div>
      </FixedTopContainer>
    </>
  );
}

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

/**
 * A reusable fixed top container that shows up when a provided element ref
 * is no longer visisble.
 */
const FixedTopContainer = ({
  children,
  observableRef,
  className = "",
  containerClass = "container",
}: {
  children: React.ReactNode;
  observableRef: React.RefObject<HTMLElement>;
  className?: string;
  containerClass?: string;
}) => {
  const { refVisible } = useElementOnScreen(observableRef, [], 0);
  const scrollDirection = useScrollDirection();
  const opacityClass =
    refVisible || scrollDirection === "down"
      ? "opacity-0 -z-10"
      : "opacity-100 z-20";
  const verticalOffest = "top-[41px] md:top-[57px]";

  return (
    <div
      className={`fixed bg-white ${opacityClass} ${verticalOffest} left-0 w-full transition-opacity ${className} border-b border-b-gray-200 p-1 pt-2 sm:pb-2 sm:pt-3`}
    >
      <div className={containerClass}>{children}</div>
    </div>
  );
};

/**
 * A small product vs product header.
 */
const SmallProductVsHeader = ({
  products,
  category,
}: {
  products: Product[];
  category: ProductCategoryConfig;
}) => {
  return (
    <div className="flex justify-center gap-x-8 col-start-2">
      <SmallProduct
        product={products[0]}
        borderColorClass="border-accent-purple"
        category={category}
      />
      <span className="absolute top-12 mx-2 text-2xl my-auto md:text-4xl text-gray-300 font-extrabold">
        VS
      </span>
      <SmallProduct
        product={products[1]}
        borderColorClass="border-accent-orange"
        category={category}
      />
    </div>
  );
};

/**
 * One product/title in the small header.
 */
const SmallProduct = ({
  product,
  borderColorClass,
  category,
}: {
  product: Product;
  borderColorClass: string;
  category: ProductCategoryConfig;
}) => {
  const { productVariants } = useGlobalAllProducts();
  const variantLabel = productVariants
    ?.find((variant) => variant.id === product.id)
    ?.variantLabel?.replace(";", ",");

  return (
    <div className="flex flex-col gap-3 items-center w-[40%] sm:w-[300px]">
      <a
        className={`h-16 w-16 p-3 rounded-full border-2 relative flex ${borderColorClass}`}
        href={getProductLandingPageUrl(product, category)}
      >
        <ImageWithFallback
          src={getProductImage(product, { width: 100 })}
          alt={product.model}
          className={`object-contain`}
        />
        <div
          className={`border-2 ${borderColorClass} rounded-md bg-white px-0.5 text-xs absolute -bottom-2 left-14`}
        >
          {centsToDollars(product.bestPrice)}
        </div>
      </a>

      <a
        className="font-bold text-center text-xs sm:text-sm hover:underline"
        href={getProductLandingPageUrl(product, category)}
      >
        {getProductLabels(product).shortLabel}
        {variantLabel && (
          <div className="text-gray-500 font-semibold text-center text-xs sm:text-sm">
            {variantLabel.replace(";", ",")}
          </div>
        )}
      </a>
    </div>
  );
};
