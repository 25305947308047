import HeatMeter from "./heat-meter";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import {
  getProductLabels,
  roundDecimal,
} from "@lib/utilities/shared-utilities";
import { Battery100Icon } from "@heroicons/react/24/solid";
import {
  ArcScoreDisplay,
  PanelSetProps,
  SummaryTitles,
  getExplanation,
} from "./common";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import React from "react";
import { getAspectIcon } from "@components/compare/compare-fns";
import {
  DonePageScaleChoiceClassification,
  ScaleChoiceClassification,
} from "@lib/attribute/classification";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { useAttribute } from "@lib/hooks/use-attributes";
import { useResponseAnswers } from "@lib/hooks/use-multi-response";

const getContent = (
  productName: string,
  batteryRating: number,
  batteryLife: number,
  batteryPreference: string,
  isShare?: boolean
) => {
  /**
   * Eventually use templates so this can turn into just configuration.
   */
  const classification = getQualitativeWord(batteryRating);

  const getSecond = () => {
    if (classification) {
      let second =
        DonePageScaleChoiceClassification.BatteryScale[
          parseInt(batteryPreference)
        ];

      if (!second) return null;

      if (isShare) {
        second = capitalizeFirstLetter(
          second.toLowerCase().replaceAll("you", "I")
        );
      }
      return second;
    } else {
      return null;
    }
  };

  const title = `${capitalizeFirstLetter(classification)} battery`;
  const secondPrefix = (
    <>
      At {roundDecimal(batteryLife)} hours of screen time, the{" "}
      <strong>{productName}</strong>
    </>
  );

  const article = classification.toLowerCase() === "best" ? "the" : "";

  return {
    title,
    first: (
      <>
        {secondPrefix} has {article} {classification.toLowerCase()} battery
        life.
      </>
    ),
    second: getSecond(),
  };
};

/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const PhoneBatteryMeter = (props: PanelSetProps) => {
  const { selectedProduct } = props;
  const { explanation } = useExplanation(
    "batteryRatingExplanation",
    selectedProduct
  );

  const attributeConfiguration = useAttribute("batteryLife");
  const { answer } = useResponseAnswers("fc9c7fe5-5544-49dc-bcf9-4bca2eaa90d2");

  const batteryLife = parseFloat(
    props.selectedProduct.attributes["batteryLife"].value
  );

  const batteryRating = parseFloat(
    props.selectedProduct.attributes["batteryRating"].value
  );

  const { title, first, second } = getContent(
    getProductLabels(props.selectedProduct).shortLabel,
    batteryRating,
    batteryLife,
    answer?.mainText,
    props.isShare
  );

  const color = getMeetsNeedsColor(!explanation.isUnmet);

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <ArcScoreDisplay
        product={selectedProduct}
        attribute={attributeConfiguration}
        icon={getAspectIcon("smartphones", "batteryLife")}
        score={batteryRating}
        color={color}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default PhoneBatteryMeter;
