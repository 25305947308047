import SelectSingle from "@components/form-element/select-single";
import ScatterPlot, {
  axisPropIsNan,
} from "@components/recommendation/scatter-plot";
import { useAttributes } from "@lib/hooks/use-attributes";
import { Product } from "@lib/model/product";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext, useEffect, useState } from "react";
import { PanelExplainer } from "./common";
import { useWindowDimensions } from "@lib/utilities/client-utilities";
import { getRandomChartProducts } from "@components/compare/compare-fns";
import { AttributeConfigurationMap } from "@lib/model/attribute";

const MultiOrdChart = ({
  id,
  defaultAttribute,
  defaultProduct,
  plottedProducts,
  attributeOptions,
  rankedProducts,
  styledContainer = true,
  productCardClasses = "",
  rankResolver,
  pageType,
}: {
  id: string;
  defaultAttribute: string;
  defaultProduct: Product;
  plottedProducts: Product[];
  attributeOptions: string[];
  rankedProducts?: Product[];
  styledContainer?: boolean;
  productCardClasses?: string;
  rankResolver?: (product: Product) => string;
  pageType: string;
}) => {
  const { attributes } = useAttributes();
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const [yAttribute, setYAttribute] = useState(defaultAttribute);
  const [xAttribute, setXAttribute] = useState("bestPrice");

  if (!attributes) {
    return null;
  }

  // Don't show attribute options where less than five of the shown products have
  // an actual score.
  const notNanProductsForAttribute = {};
  attributeOptions.forEach((attributeName) => {
    notNanProductsForAttribute[attributeName] = 0;
  });
  attributeOptions.forEach((attributeName) => {
    plottedProducts.forEach((product) => {
      if (
        product &&
        attributes[attributeName] &&
        !axisPropIsNan(product, attributes[attributeName])
      ) {
        notNanProductsForAttribute[attributeName]++;
      }
    });
  });

  const options = attributeOptions
    .filter(
      (attributeName) =>
        attributes[attributeName] &&
        notNanProductsForAttribute[attributeName] > 5
    )
    .map((attributeName) => ({
      value: attributeName,
      label:
        attributes[attributeName]?.chartName ||
        attributes[attributeName]?.displayName,
    }));

  /**
   * Gets a list of options, making sure that the selected option from the
   * other axis is not present.
   */
  const getAxisOptions = (
    options: { value: string; label: string }[],
    axis: "y" | "x"
  ) =>
    options.filter((option) => {
      if (axis === "y") {
        return option.value !== xAttribute;
      }
      if (axis === "x") {
        return option.value !== yAttribute;
      }
    });

  const priceOption = { value: "bestPrice", label: "Price ($)" };

  return (
    <div>
      <div className="grid grid-cols-12 gap-2 mx-auto w-3/4 sm:w-[65%] md:w-full mb-12 md:flex md:justify-center">
        <SelectSingle
          options={getAxisOptions([...options, priceOption], "y")}
          onChange={(e: any) => setYAttribute(e.target.value)}
          defaultValue={yAttribute}
          style="blue"
          className="text-base col-span-11 md:w-[30%]"
        />
        <AttributeExplainer
          attributes={attributes}
          attribute={yAttribute}
          className="self-center"
        />
        <div className="text-base self-center justify-self-center font-semibold">
          VS
        </div>
        <SelectSingle
          options={getAxisOptions([...options, priceOption], "x")}
          onChange={(e: any) => setXAttribute(e.target.value)}
          defaultValue={xAttribute}
          style="blue"
          className="text-base col-span-10 md:w-[30%]"
        />
        <AttributeExplainer
          attributes={attributes}
          attribute={xAttribute}
          className="self-center"
        />
      </div>

      <ScatterPlot
        id={id}
        productCategoryName={productCategoryConfig.name}
        defaultProduct={defaultProduct}
        rankedProducts={rankedProducts}
        plottedProducts={plottedProducts}
        yAxisProp={
          yAttribute === "bestPrice" ? "bestPrice" : attributes[yAttribute]
        }
        xAxisProp={
          xAttribute === "bestPrice" ? "bestPrice" : attributes[xAttribute]
        }
        productCardClasses={`max-w-screen-sm mx-auto ${productCardClasses} ${
          !styledContainer ? "bg-gray-100" : "bg-white"
        }`}
        rankResolver={rankResolver}
        pageType={pageType}
      />
    </div>
  );
};

/**
 * A wrapper for the modal explainer UI that won't display for price.
 */
const AttributeExplainer = ({
  attributes,
  attribute,
  className = "",
}: {
  attributes: AttributeConfigurationMap;
  attribute: string;
  className?: string;
}) => {
  if (attribute === "bestPrice") {
    return null;
  }
  return (
    <PanelExplainer
      title={
        attributes[attribute].shortName || attributes[attribute].displayName
      }
      text={attributes[attribute].explanation}
      className={className}
    />
  );
};

/**
 * A wrapper for multi-ord chart that does random sampling. Used on VS pages, where
 * there isn't a response set to help us rank the products.
 */
export const SampledMultiOrdChart = ({
  selectedProduct,
  plottableProductsPool,
  rankedProducts,
  id,
  attributeOptions,
  defaultAttribute,
  rankResolver,
  pageType,
}: {
  selectedProduct: Product;
  plottableProductsPool: Product[];
  rankedProducts: Product[];
  id: string;
  attributeOptions: string[];
  defaultAttribute: string;
  rankResolver?: (product: Product) => string;
  pageType: string;
}) => {
  const { breakpoint } = useWindowDimensions();
  const n = breakpoint === "xs" ? 15 : 20;

  const [plottedProducts, setPlottedProducts] = useState<Product[]>([]);

  // We want to use the same random set once it mounts.
  useEffect(() => {
    if (rankedProducts && plottableProductsPool && !plottedProducts.length) {
      const plottableProducts = getRandomChartProducts(
        plottableProductsPool,
        n,
        rankedProducts
      );
      setPlottedProducts(plottableProducts);
    }
  }, [rankedProducts, plottableProductsPool, n]);

  return (
    <MultiOrdChart
      id={id}
      defaultAttribute={defaultAttribute}
      attributeOptions={attributeOptions}
      defaultProduct={selectedProduct}
      plottedProducts={plottedProducts}
      rankedProducts={rankedProducts}
      productCardClasses="bg-gray-100"
      styledContainer={false}
      rankResolver={rankResolver}
      pageType={pageType}
    />
  );
};

export default MultiOrdChart;
