import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

/**
 * A collapsible summary section. It may be controlled externally, or not.
 */
const CollapsibleSection = ({
  heading,
  children,
  expandedDefault,
  controlledExpanded,
  onClick,
  isLast,
}: {
  heading: string | React.ReactNode;
  children: React.ReactNode;

  /**
   * If this component is not being controlled externally, optionally provide a
   * default expanded state.
   */
  expandedDefault?: boolean;

  /**
   * Provide whether the section is expanded if this component is being controlled
   * externally.
   */
  controlledExpanded?: boolean;

  /**
   * Provide an onClick handler if this component is being controlled externally.
   */
  onClick?: () => void;

  isLast?: boolean;
}) => {
  const [expanded, setExpanded] = useState(expandedDefault);

  // Regardless of whether it is controlled externally, derive the actual expanded
  // state.
  const isExpanded =
    (controlledExpanded === true || controlledExpanded === false) && onClick
      ? controlledExpanded
      : expanded;

  const borderTypeClass = isLast && !isExpanded ? "border-y" : "border-t";

  return (
    <div>
      <button
        onClick={onClick ? onClick : () => setExpanded(!expanded)}
        className={`${borderTypeClass} ${
          !isExpanded && "bg-gray-100 hover:bg-gray-200"
        } border-gray-500 p-2 font-semibold w-full text-left flex items-center gap-2 justify-between`}
      >
        {heading}
        <ChevronDownIcon
          className={`h-5 w-5 ${
            isExpanded && "rotate-180"
          } transition-all shrink-0`}
        />
      </button>
      {isExpanded && <div>{children}</div>}
    </div>
  );
};

export default CollapsibleSection;
