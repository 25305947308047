const ContentIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className={`${props.className || "fill-blue-600"}`}
  >
    <path d="M2 4c0-.5.4-1 1-1h18c.5 0 1 .4 1 1v16c0 .5-.4 1-1 1H3c-.5 0-1-.4-1-1V4zm2 1v2h2V5H4zm14 0v2h2V5h-2zM4 9v2h2V9H4zm14 0v2h2V9h-2zM4 13v2h2v-2H4zm14 0v2h2v-2h-2zM4 17v2h2v-2H4zm14 0v2h2v-2h-2z" />
  </svg>
);

export { ContentIcon };
