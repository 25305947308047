import DonePanel from "@components/recommendation/done-panel";
import { SummaryTitles } from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import { useAttributes } from "@lib/hooks/use-attributes";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import { CompareCardScoreDisplay } from "@components/compare/compare-components";

const attributeToPromptTypeMap = {
  cameraScore: "compareCameraPhone",
  pictureScore: "comparePhotosPhone",
  videoScore: "compareVideosPhone",
};
/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const PhoneCameraComparison = (props: {
  productA: Product;
  productB: Product;
  keySpecs?: boolean;
  compareResults: any;
  attributeName: string;
  title: string;
  poles: string[];
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const { productA, productB, compareResults, comparisons } = props;

  const { attributes } = useAttributes();
  const attribute = attributes[props.attributeName];

  if (!productA || !productB) {
    return null;
  }

  const { pctA, pctB, winner, first } = compareResults[props.attributeName];

  const llmComparison =
    comparisons &&
    comparisons[attributeToPromptTypeMap[props.attributeName]]?.result;

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={props.title}
      className={"bg-white"}
      modal={{
        title: attribute.displayName,
        text: attribute.explanation,
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={attribute}
        categoryName={"smartphones"}
      />
      <SummaryTitles
        {...{
          first,
          llm: llmComparison,
        }}
      />
    </DonePanel>
  );
};

export default PhoneCameraComparison;
