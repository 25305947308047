import Modal from "@components/modal/modal";
import {
  getPriceLastUpdated,
  getTrackingPayload,
} from "@components/product/purchase-data";
import { useAllPurchaseData } from "@lib/hooks/use-purchase-data";
import { Product } from "@lib/model/product";
import PurchaseOption from "@lib/model/purchase-option";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext, useState } from "react";
import { PrimaryButton } from "./button";
import Events from "@lib/constants/events";
import eventTracker from "@lib/tracking/event-tracker";
import {
  getProductLandingPageUrl,
  getVsPageUrl,
} from "@lib/utilities/shared-utilities";
import BlankTextPlaceholder from "./blank-text-placeholder";

const LatestReportDisplay = ({
  products,
  page,
  className,
}: {
  products: Product[];
  page: string;
  className?: string;
}) => {
  const [reported, setReported] = useState(false);
  const { allPurchaseData } = useAllPurchaseData();

  const productIds = products?.map((product) => product.id);

  const purchaseData = allPurchaseData?.filter((purchaseData) =>
    productIds?.includes(purchaseData.productId)
  );

  const productIsReported = () => {
    return setReported(true);
  };

  return (
    <div className={`text-xs flex my-2 ${className}`}>
      {!purchaseData ? (
        <BlankTextPlaceholder textForSizing="Last updated -- hours ago | Report incorrect information" />
      ) : (
        <>
          <div className="flex-shrink-0">
            Last updated {getPriceLastUpdated(purchaseData)} ago |
          </div>
          {!reported ? (
            <ReportProductModal
              products={products}
              purchaseData={purchaseData}
              reportProduct={productIsReported}
              page={page}
            />
          ) : (
            <div className="ml-1">
              Thank you - incorrect information reported
            </div>
          )}
        </>
      )}
    </div>
  );
};

const ReportProductModal = ({
  purchaseData,
  products,
  reportProduct,
  page,
}: {
  purchaseData?: PurchaseOption[];
  products?: Product[];
  reportProduct?: () => void;
  page?: string;
}) => {
  const [open, setOpen] = useState(false);
  const { productCategoryConfig: productCategory } = useContext(
    ProductCategoryContext
  );

  const trackingPayLoads = products
    ?.map((product, index) =>
      getTrackingPayload(
        purchaseData?.filter((data) => data.productId === product.id),
        product,
        index
      )
    )
    .reduce((acc, payloads) => {
      return { ...acc, ...payloads };
    }, {});

  const getPageUrl = (page: string) => {
    if (page === "vs") {
      return `https://www.perfectrec.com${getVsPageUrl(
        products,
        productCategory
      )}`;
    } else if (page === "product-detail" && products?.length === 1) {
      return `https://www.perfectrec.com${getProductLandingPageUrl(
        products[0],
        productCategory
      )}`;
    }
  };

  return (
    <>
      <div
        className="cursor-pointer text-blue-600 ml-1"
        onClick={() => {
          setOpen(true);
        }}
      >
        Report incorrect information
      </div>
      <Modal
        modalOpen={open}
        onClose={() => setOpen(false)}
        omitBottomButtons={true}
        title="What's wrong with the information on this page?"
      >
        <textarea
          id="issueDescription"
          placeholder="Describe the issue here"
          className="w-full min-h-[100px]"
        />
        <PrimaryButton
          className="mt-4 w-full"
          variant="solid"
          onClick={async () => {
            setOpen(false);
            const issueDescription = (
              document.getElementById("issueDescription") as HTMLTextAreaElement
            )?.value;
            eventTracker.track(Events.ReportedIncorrectInfo, {
              ...trackingPayLoads,
              issueDescription,
            });
            reportProduct();
            try {
              await fetch("/api/reportErrors", {
                body: JSON.stringify({
                  payload: {
                    ...trackingPayLoads,
                    issueDescription,
                    Url: getPageUrl(page),
                  },
                }),
                method: "POST",
              });
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Submit
        </PrimaryButton>
      </Modal>
    </>
  );
};

export default LatestReportDisplay;
