import { AssetType, getImageUrl } from "@lib/utilities/shared-utilities";
import ShareModal from "@components/tray/share-modal";
import { useContext, Fragment, useState, useEffect } from "react";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import {
  ExclamationTriangleIcon,
  QueueListIcon,
  ShareIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import {
  scrollToRef,
  useWindowDimensions,
} from "@lib/utilities/client-utilities";
import useShare from "@lib/hooks/use-share";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import { Product } from "@lib/model/product";
import { PrimaryButton } from "@components/common/button";
import Dropdown, { DropdownItem } from "@components/form-element/dropdown";
import { RecommendationNote } from "@lib/model/recommender-model";
import { Menu, Transition } from "@headlessui/react";
import { ProductCategoryContext } from "contexts/product-category-context";
import { IFaq } from "@lib/model/contentful";
import CollapsibleSection from "@components/common/collapsible-section";
import { renderRichText } from "@components/common/contentful-rich-text";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import useElementOnScreen from "@lib/hooks/use-element-on-screen";
import { Disclosure } from "@headlessui/react";
import ImageWithFallback from "@components/common/image-with-fallback";
import { Faqs } from "@components/common/faq";

/**
 * Display card for rec notes.
 */
export const OurAdvice = ({
  recNotes,
  isShare,
}: {
  recNotes: RecommendationNote[];
  isShare: boolean;
}) => {
  return (
    <StandardCard className="mt-2 max-w-[640px] mx-auto">
      <h3 className="text-base text-left font-serif font-bold pb-2">
        {isShare ? "PerfectRec" : "Our"}&nbsp;advice
      </h3>
      {/* add a line for each advice */}
      {recNotes.map(({ recNoteText, recNoteType }, index) => (
        <div className="flex flex-row gap-1" key={index}>
          <span className="inline-block align-top">
            {recNoteType === "Alert" && (
              <ExclamationTriangleIcon className="w-5 fill-red-500" />
            )}
            {recNoteType === "Information" && (
              <InformationCircleIcon className="w-5 fill-gray-400" />
            )}
          </span>
          <div
            className="text-sm text-left font-normal"
            dangerouslySetInnerHTML={{ __html: recNoteText }}
          />
        </div>
      ))}
    </StandardCard>
  );
};

/**
 * A heading in the done page linked from the scroll-nav, with a custom chevron.
 */
export const ArrowHeading = ({
  innerRef,
  children,
}: {
  innerRef?: React.Ref<HTMLDivElement>;
  children: React.ReactNode;
}) => {
  return (
    <div className={`text-center my-6 md:mt-8 md:text-left`} ref={innerRef}>
      <h2 className="text-2xl text-center font-normal px-3">{children}</h2>
      <ImageWithFallback
        src={getImageUrl("short-chevron.svg", AssetType.Icon)}
        alt="Chevron"
        className="mx-auto my-4"
      />
    </div>
  );
};

/**
 * The button to create a share and show the share modal.
 */
export const ShareCta = () => {
  const { shareData, createShare } = useShare();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ShareModal
        modalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <PrimaryButton
        className="w-full"
        onClick={() => {
          EventTracker.track(Events.ModalOpen, {
            explanationType: `Done Component - Share Start`,
          });
          // Open modal right away, then wait there for the link to finish
          if (!shareData) createShare();
          setIsModalOpen(true);
        }}
        data-testid="share-button"
        variant="solid"
      >
        <ShareIcon className="h-4 w-4" />
        Share
      </PrimaryButton>
    </>
  );
};

/**
 * A no-results segment for when there are masked products.
 */
export const NoMaskedResults = () => {
  return (
    <div className="font-semibold text-center">
      No other products match the selection.
    </div>
  );
};

/**
 * The heading for the longer list of not-recommended phones. May contain a
 * dropdown to filter the list.
 */
export const OtherProductsHeading = (props: {
  items?: DropdownItem[];
  default: string | number;
  onChange: (value) => void;
  hasMask: boolean;
  className?: string;
}) => {
  const { productCategoryConfig } = useContext(RecUIContext);
  return props.hasMask ? (
    <h2 className={`text-center my-6 ${props.className || ""}`}>
      Other
      <span className="inline-flex items-baseline font-sans mx-2">
        <Dropdown {...props} items={props.items} />
      </span>
      {productCategoryConfig.pluralNoun} considered
    </h2>
  ) : (
    <h2 className="text-center text-2xl my-6">
      Other {productCategoryConfig.pluralNoun} considered
    </h2>
  );
};

/**
 * Scroll the horizontal rec-viewer to a particular product/recommendation.
 */
export const scrollToRec = (
  product: Product,
  recommendationScrollerDivId: string
) => {
  document.getElementById(recommendationScrollerDivId).scrollTo({
    top: 0,
    left: document.getElementById(`recommendation-${product.id}`).offsetLeft,
    behavior: "smooth",
  });
};

/**
 * Scroll to the top of the window.
 */
export const scrollPanelToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * A menu that shows a table of contents and the currently selected product.
 */
export const StickyMenu = ({
  children,
  items,
  observableRef,
  ready,
}: {
  children: React.ReactNode;
  items: {
    text: string;
    ref: React.RefObject<HTMLElement>;
    disabled?: boolean;
  }[];
  observableRef: React.RefObject<HTMLElement>;
  ready: boolean;
}) => {
  const { refVisible } = useElementOnScreen(observableRef, [ready]);
  const { breakpoint } = useWindowDimensions();

  useEffect(() => {
    if (!refVisible && breakpoint === "xs") {
      EventTracker.track(Events.DonePageNavMenuShown, {});
    }
  }, [refVisible, breakpoint]);

  const opacityClass = refVisible ? "opacity-0 -z-10" : "opacity-100 z-20";

  return (
    <div
      className={`fixed w-full top-0 flex gap-2 p-2 sm:hidden ${opacityClass} transition-opacity`}
    >
      <Menu as="div" className="text-left relative">
        <Menu.Button
          className="rounded-lg bg-white p-2 flex items-center gap-1 shadow-lg h-full mb-2"
          onClick={() => {
            EventTracker.track(Events.DonePageNavSectionsToggle, {});
          }}
        >
          <QueueListIcon className="w-6 h-6" />
          <span className="font-semibold text-sm">Sections</span>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            as="ul"
            className="absolute left-0 z-20 py-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col gap-3"
          >
            {items
              // Filter out empty items, in case some categories don't have certain
              // sections.
              .filter((item) => !item.disabled)
              .map((item) => (
                <Menu.Item
                  as="li"
                  key={item.text}
                  className="px-3 whitespace-nowrap text-sm"
                  onClick={() => {
                    scrollToRef(item.ref, true);
                    EventTracker.track(Events.DonePageNavSectionsItemClick, {
                      item_text: item.text,
                    });
                  }}
                >
                  <span>{item.text}</span>
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </Menu>
      {children}
    </div>
  );
};

/**
 * A wrapper for cards on the done page. Typically, you would pass a color as
 * the classname. Otherwise, it will be white.
 */
export const StandardCard = ({
  children,
  className = "bg-white",
  innerClassName = "",
  headerClassName = "bg-blue-50",
  title,
  id,
}: {
  children: React.ReactNode;
  className?: string;
  innerClassName?: string;
  headerClassName?: string;
  title?: string;
  id?: string;
}) => {
  return (
    <div id={id} className={`border border-gray-500 rounded-lg ${className}`}>
      {title && (
        <h3
          className={`font-sans text-base text-center p-3 border-b border-gray-500 rounded-t-lg ${headerClassName}`}
        >
          {title}
        </h3>
      )}
      <div className={`p-3 ${innerClassName}`}>{children}</div>
    </div>
  );
};

/**
 * A single section within a standard done page card.
 */
export const StandardCardSection = ({
  title,
  description,
  disclosureButtonText,
  disclosureText,
  children,
  className = "",
  last,
}: {
  title?: string;
  description?: React.ReactNode;
  disclosureButtonText?: React.ReactNode;
  disclosureText?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  last?: boolean;
}) => {
  return (
    <div
      className={`lg:grid lg:grid-cols-2 ${
        !last && "border-b border-keyline-1 pb-3 mb-3"
      } ${className}`}
    >
      <div className="mb-2 lg:mb-0">
        {title && <h3 className="font-sans small-caps text-2xl">{title}</h3>}
        {description && (
          <div className="text-gray-500 text-sm">{description}</div>
        )}
        {disclosureButtonText && disclosureText && (
          <Disclosure>
            <Disclosure.Button className="btn-text">
              {disclosureButtonText}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500 text-sm">
              {disclosureText}
            </Disclosure.Panel>
          </Disclosure>
        )}
      </div>
      {children}
    </div>
  );
};

/**
 * A single section within a standard done page card.
 */
export const StandardCardSectionHorizontal = ({
  title,
  description,
  disclosureButtonText,
  disclosureText,
  children,
  className = "",
  last,
}: {
  title?: string;
  description?: React.ReactNode;
  disclosureButtonText?: React.ReactNode;
  disclosureText?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  last?: boolean;
}) => {
  return (
    <div
      className={`${
        !last && "border-b border-keyline-1 pb-3 mb-3"
      } ${className}`}
    >
      <div className="mb-2">
        {title && <h3 className="font-sans small-caps text-2xl">{title}</h3>}
        {description && (
          <div className="text-gray-500 text-sm">{description}</div>
        )}
        {disclosureButtonText && disclosureText && (
          <Disclosure>
            <Disclosure.Button className="btn-text">
              {disclosureButtonText}
            </Disclosure.Button>
            <Disclosure.Panel className="text-gray-500 text-sm">
              {disclosureText}
            </Disclosure.Panel>
          </Disclosure>
        )}
      </div>
      {children}
    </div>
  );
};
