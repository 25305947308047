import HeatMeter from "./heat-meter";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useExplanation } from "@lib/hooks/use-explanation";
import { useAttribute } from "@lib/hooks/use-attributes";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { getQualitativeWord } from "@lib/utilities/product-classifier";

/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const PhonePhotoScore = (props: PanelSetProps) => {
  const { selectedProduct } = props;
  const { explanation } = useExplanation(
    "cameraQualityExplanation",
    selectedProduct
  );

  const score = parseFloat(
    props.selectedProduct.attributes["pictureScore"]?.value
  );
  const pct = score / 10;

  const attributeConfiguration = useAttribute("pictureScore");

  const color = getMeetsNeedsColor(!explanation.isUnmet);
  return (
    <DonePanel
      title="Photo Quality"
      color={color}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <ArcScoreDisplay
        product={selectedProduct}
        attribute={attributeConfiguration}
        icon={getAspectIcon("smartphones", "pictureScore")}
        score={score}
        color={color}
      />
      <SummaryTitles
        {...{
          first: (
            <>
              The {getProductLabels(selectedProduct).shortLabel} captures{" "}
              <strong>
                {getQualitativeWord(score)
                  .toLowerCase()
                  .replace("best", "the best")
                  .replace("fair", "only fair")}
              </strong>{" "}
              photos.
            </>
          ),
          second:
            "PerfectRec's photo quality score captures the overall quality of photos you can expect, taking into account software features and usability and various shooting scenarios.",
          showSecondOnly: true,
        }}
      />
    </DonePanel>
  );
};

export default PhonePhotoScore;
