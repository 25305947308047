import { CheckIcon } from "@heroicons/react/24/outline";
import DonePanel from "./done-panel";
import useFilters from "@lib/hooks/use-filters";
import { useContext } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { SingleRecExplanationIconColors } from "@lib/constants/rec-explanation-icons";

const FiltersSummary = ({ meetsNeeds }: { meetsNeeds: boolean }) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { activeResponseSet } = useContext(RecUIContext);
  const filters = useFilters(productCategoryConfig, activeResponseSet);

  if (!filters.length || !meetsNeeds) {
    return null;
  }

  return (
    <DonePanel
      title="Meets your requirements"
      icon={CheckIcon}
      color={SingleRecExplanationIconColors.Green}
    >
      <ul className="mt-4 flex gap-3 flex-wrap">
        {filters.map((filter) => (
          <li
            key={filter.name}
            className="rounded-full flex items-center gap-1 border border-rec-light-green px-3 py-1 bg-white"
          >
            <CheckIcon className="h-5 w-5 shrink-0" /> {filter.name}
          </li>
        ))}
      </ul>
    </DonePanel>
  );
};

export default FiltersSummary;
