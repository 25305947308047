const BrightRoomIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className={`${props.className || "fill-blue-600"}`}
  >
    <path d="M12 18c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.5 4.9l1.4-1.4L7 5.6 5.6 7.1 3.5 4.9zM17 18.4l1.4-1.4 2.1 2.1-1.4 1.4-2.1-2.1zm2.1-14.9 1.4 1.4L18.4 7 17 5.6l2.1-2.1zM5.6 17 7 18.4l-2.1 2.1-1.4-1.4L5.6 17zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
  </svg>
);

export { BrightRoomIcon };
