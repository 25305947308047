import { Product } from "@lib/model/product";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext, useMemo } from "react";
import { useGlobalAllProducts } from "./global/use-global-all-products";

const getSizeCategoryForLaptops = (size: number) => {
  if (size <= 14.7) {
    return "small";
  } else if (size <= 16.7) {
    return "standard";
  } else {
    return "large";
  }
};

export const useBetterProduct = (
  inputProducts: Product[],
  attributeName: string
) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { products: allProducts } = useGlobalAllProducts();

  const betterProduct = useMemo(() => {
    let relevantProducts: Product[];

    switch (productCategoryConfig.name) {
      case "laptops": {
        relevantProducts = allProducts?.filter((product) =>
          inputProducts.some(
            (inputProduct) =>
              getSizeCategoryForLaptops(
                inputProduct.attributes["screenSize"].value
              ) ===
                getSizeCategoryForLaptops(
                  product.attributes["screenSize"].value
                ) &&
              inputProduct.attributes["operatingSystem"].value ===
                product.attributes["operatingSystem"].value
          )
        );
        break;
      }
      case "tvs": {
        relevantProducts = allProducts?.filter((product) => {
          const sizes = inputProducts.map((inputProduct) =>
            parseFloat(inputProduct.attributes["screenSize"].value)
          );
          const maxSize = Math.max(...sizes);
          const minSize = Math.min(...sizes);

          const productSize = parseFloat(
            product.attributes["screenSize"].value
          );

          return productSize >= minSize && productSize < maxSize + 6;
        });
        break;
      }
      case "smartphones": {
        relevantProducts = allProducts?.filter((product) =>
          inputProducts.some(
            (inputProduct) =>
              inputProduct.attributes["operatingSystem"].value ===
              product.attributes["operatingSystem"].value
          )
        );
        break;
      }
      case "headphones": {
        relevantProducts = allProducts?.filter((product) =>
          inputProducts.some(
            (inputProduct) =>
              inputProduct.attributes["formFactor"].value ===
                product.attributes["formFactor"].value &&
              inputProduct.attributes["wireless"].value ===
                product.attributes["wireless"].value
          )
        );
        break;
      }
    }

    const currentWinner = inputProducts.reduce((maxProduct, currentProduct) => {
      const currentValue = parseFloat(
        currentProduct.attributes[attributeName].value
      );
      const maxValue = parseFloat(maxProduct.attributes[attributeName].value);

      return currentValue > maxValue ? currentProduct : maxProduct;
    }, inputProducts[0]);

    const filteredProducts = relevantProducts
      ?.filter((product) => {
        let result = true;
        inputProducts.forEach((inputProduct) => {
          if (
            parseFloat(product.attributes[attributeName].value) -
              parseFloat(inputProduct.attributes[attributeName].value) <
            0.2
          ) {
            result = false;
          }
        });
        return result;
      })
      .filter((product) => {
        let result = false;
        inputProducts.forEach((inputProduct) => {
          if (product.bestPrice > inputProduct.bestPrice * 0.5) {
            result = true;
          }
        });
        return result;
      })
      .filter((product) => product.bestPrice < currentWinner.bestPrice * 1.2)
      .sort((a: Product, b: Product) => {
        const valueA = parseFloat(a.attributes[attributeName].value);
        const valueB = parseFloat(b.attributes[attributeName].value);

        return valueB - valueA;
      });

    return filteredProducts?.length > 0 && filteredProducts[0];
  }, [
    inputProducts,
    attributeName,
    productCategoryConfig,
    allProducts,
    getSizeCategoryForLaptops,
  ]);
  return { betterProduct };
};
