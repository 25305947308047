import { useContext } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import { SparklesIcon, SunIcon } from "@heroicons/react/24/solid";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";
import { AttributeConfigurationMap } from "@lib/model/attribute";

export const useAttributes = () => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const data =
    productCategoryConfig.attributeConfiguration as AttributeConfigurationMap;

  return { attributes: data };
};

export const useAttribute = (attributeName) => {
  const { attributes } = useAttributes();
  return attributes[attributeName];
};

export const useAttributeIcons = () => {
  return {
    tvs: {
      screenScore: SparklesIcon,
      gamingScore: SparklesIcon,
      movieScore: SparklesIcon,
      sportsScore: SparklesIcon,
      animationScore: SparklesIcon,
      newsScore: SparklesIcon,
      brightnessScore: SunIcon,
      soundScore: SpeakerWaveIcon,
    },
  };
};
