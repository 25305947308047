import React from "react";

/**
 * An indicator to show which product is A/B on the charts.
 */
export const ABMarker = (props: { letter: string }) => {
  return (
    <div className="rounded-full h-6 w-6 border border-slate-900 flex items-center justify-center text-xs font-semibold bg-white absolute bottom-0 right-1/2 translate-x-[50%]">
      {props.letter}
    </div>
  );
};
