import SquareAdUnit from "@components/ads/square-ad-unit";
import {
  ChartPropsStandard,
  PanelLayout,
} from "@components/product/summary/common";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import VsPageCtaCard from "../vs-page-cta-card";
import ProductCostComparison from "./product-cost-comparison";
import ProductScoreComparison from "./product-score-comparison";
import { SampledMultiOrdChart } from "@components/product/summary/multi-ord-chart";
import ProductSuitabilityComparison from "./product-suitability-comparison";

const MonitorsComparisonDisplay = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
  keySpecs?: boolean;
  compareResults: any;
  displayDiffs: boolean;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const contentAttributes = [
    "casualGamingScore",
    "competitiveGamingScore",
    "productivityScore",
    "mediaConsumptionScore",
  ];

  const suitabilityAttributes = [
    "hdrConsumption",
    "digitalPhoto",
    "hdrVideo",
    "printPhoto",
  ];
  return (
    <PanelLayout>
      {contentAttributes.map((attribute) => (
        <ProductScoreComparison
          key={attribute}
          productA={props?.products?.[0]}
          productB={props?.products?.[1]}
          usageAttribute={attribute}
          keySpecs={props.keySpecs}
          compareResults={props.compareResults}
        />
      ))}
      <ProductCostComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        maxBudgetCents={400000}
        compareResults={props.compareResults}
      />
      {suitabilityAttributes.map((attribute) => (
        <ProductSuitabilityComparison
          key={attribute}
          productA={props?.products?.[0]}
          productB={props?.products?.[1]}
          compareResults={props.compareResults}
          attributeName={attribute}
          keySpecs={props.keySpecs}
        />
      ))}
      {/* {props.keySpecs && props.displayDiffs && <SquareAdUnit />}
      {!props.keySpecs && !props.displayDiffs && <SquareAdUnit />} */}
    </PanelLayout>
  );
};

export const MonitorChartsStandard = (props: ChartPropsStandard) => {
  const {
    selectedProductIndex,
    compareProducts,
    allProducts,
    getRank,
    pageType,
  } = props;

  const selectedProduct = selectedProductIndex
    ? compareProducts[props.selectedProductIndex]
    : compareProducts[0];

  const bestDefaultAttribute = "casualGamingScore";
  const bestAttributeOptions = [
    "casualGamingScore",
    "competitiveGamingScore",
    "productivityScore",
    "mediaConsumptionScore",
    "refreshRateScore",
    "textQualityScore",
    "resolutionScore",
    "contrastScore",
    "brightnessScore",
    "colorVolumeScore",
    "responseTimeScore",
  ];

  return (
    <SampledMultiOrdChart
      id="monitors-multiord-plot"
      defaultAttribute={bestDefaultAttribute}
      attributeOptions={bestAttributeOptions}
      selectedProduct={selectedProduct}
      rankResolver={getRank}
      plottableProductsPool={allProducts}
      rankedProducts={compareProducts}
      pageType={pageType}
    />
  );
};

export default MonitorsComparisonDisplay;
