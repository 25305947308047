import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { QuestionWithAnswers } from "@lib/model/question";
import { productCategorySession } from "@lib/utilities/client-utilities";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import Response from "@lib/model/response";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import {
  deriveUpdatedResponseSet,
  getNextWeightedQuestionIndex,
} from "@lib/utilities/rec-ui";
import { ProductCategoryContext } from "./product-category-context";
import { AttributeConfiguration } from "../../data/packages/model/src/productAttribute";
import { useRouter } from "next/router";
import {
  ModelResponse,
  ModelResponseRecommendation,
  RecommendationExplanation,
  RecommendationNote,
} from "@lib/model/recommender-model";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { Product } from "@lib/model/product";

/**
 * This type is for our history object. Its job is to remember the contents of
 * the response set, the recommendations, and the last input based on what
 * question is being shown.
 */
interface ResponseHistory {
  /**
   * The key is either a question ID or "final".
   */
  [key: string]: {
    /**
     * A snapshot of what the response set was when the user last viewed this
     * question, or the final recs if they reached the end.
     */
    responseSet: ResponseSetWithResponses;

    /**
     * The set of recommended product IDs that showed the last time the user viewed this
     * question/done-page.
     */
    topProductIds: string[];

    explanations: RecommendationExplanation[][];

    /**
     * The array index of the question.
     */
    index: number;

    modelInput: {
      query: [];
      filters: [];
    };
  };
}

export interface QuestionOverrides {
  [key: string]: {
    allowedAnswers: string[];
  };
}

/**
 * The model returns this structure, which captures product groupings related to
 * user preferences and filters.
 */
export interface RankedProductIds {
  /**
   * "losers" means products that satisfied hard filters, but did not make the top
   * recommendations.
   */
  losers: ModelResponseRecommendation[];

  /**
   * Filtered products are those that did not meet the hard filters.
   */
  filtered: ModelResponseRecommendation[];
}

/**
 * Represents different states for recommender UI.
 */
type Screen = "splash" | "previewDone" | "done" | "questions" | "share";

type RecUIContextValues = {
  animatingResponse: boolean;
  currentQuestionIndex: number;
  currentQuestion: QuestionWithAnswers;
  questions: QuestionWithAnswers[];
  back: boolean;
  questionOverrides: QuestionOverrides;
  productCategoryConfig: ProductCategoryConfig;
  topProductIds: string[];
  topProducts: Product[];
  losers: ModelResponseRecommendation[];
  filtered: ModelResponseRecommendation[];
  modelInput: {
    query: string[];
    filters: string[];
  };
  setCurrentQuestionIndex: (index: number) => void;
  nextQuestion: (
    responseSet: ResponseSetWithResponses,
    question?: QuestionWithAnswers
  ) => { questionId: string; questionIndex: number };
  setBack: (back: boolean) => void;
  setFirstQuestion: () => void;
  setAnimatingResponse: (animating: boolean) => void;
  resetRecommendations: () => void;
  pastFirstQuestion: () => boolean;
  setQuestionAllowedAnswers: (
    questionText: string,
    allowedAnswers: string[]
  ) => void;
  updateRecDisplay: (mlRecData: ModelResponse) => string[];
  responseSets: ResponseSetWithResponses[];
  storeResponseSet: (newResponseSet: ResponseSetWithResponses) => void;
  activeResponseSet: ResponseSetWithResponses;
  resetResponseSet: () => void;
  buildUpdatedResponseSet: (newResponse: Response) => ResponseSetWithResponses;
  storeActiveResponseSetId: (responseSetId: string) => void;
  handleLoadingDone: () => void;
  storeRecState: (newRecState: {
    topProductIds?: string[];
    recNotes?: RecommendationNote[];
    losers?: ModelResponseRecommendation[];
    filtered?: ModelResponseRecommendation[];
    modelInput?: {
      query: string[];
      filters: string[];
    };
  }) => void;
  writeResponseHistory: (
    questionId: string,
    responseSet: ResponseSetWithResponses,
    questionIndex: number,
    modelResponse: ModelResponse
  ) => void;
  resetResponseHistory: () => void;
  hasDisambiguation: boolean;
  setHasDisambiguation: React.Dispatch<React.SetStateAction<boolean>>;
  rankedProductIds: RankedProductIds;
  saveRankedProductIds: (modelResponse: ModelResponse) => void;
  storeScreen: (screen: Screen) => void;
  restoreFromHistory: (key: string) => boolean;
  getLastAnsweredQuestionId: () => string;
  getResponseByQuestionId: (questionId: string) => Response;
  isInternalUser: boolean;
  recNotes: any;
  screen: Screen;
  getFirstQuestionId: () => string;
  skipSplash: boolean;
  explanations: RecommendationExplanation[][];
};

export const RecUIContext = createContext<RecUIContextValues>(null);

const RecUIContextProvider = (props: {
  children: React.ReactNode;
  attributeConfiguration: { [key: string]: AttributeConfiguration };
  questions: QuestionWithAnswers[];
  initialTopProductIds?: string[];
  initialResponseSet?: ResponseSetWithResponses;
  initialActiveResponseSetId?: string;
  initialRankedProductIds?: RankedProductIds;
  initialRecNotes?: RecommendationNote[];
  isInternalUser?: boolean;
  initialExplanations?: RecommendationExplanation[][];
}) => {
  const { children, questions, isInternalUser } = props;
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { rawProducts: products } = useGlobalAllProducts();

  const getCategorySession = productCategorySession.getter(
    productCategoryConfig
  );
  const setCategorySession = productCategorySession.setter(
    productCategoryConfig
  );

  const [recState, setRecState] = useState({
    topProductIds: props.initialTopProductIds || [],
    // We can eliminate initialExplanations and recState.explanations once we
    // stop relying on the model for explanations.
    explanations: props.initialExplanations || [],
    recNotes: props.initialRecNotes || [],
    losers: [] as ModelResponseRecommendation[],
    filtered: [] as ModelResponseRecommendation[],
    modelInput: {
      query: [],
      filters: [],
    },
  });

  const topProducts = useMemo<Product[]>(() => {
    if (products && recState.topProductIds) {
      return recState.topProductIds.map((productId) =>
        products.find((product) => product.id === productId)
      );
    }
  }, [products, recState.topProductIds]);

  const [responseHistory, setResponseHistory] = useState<ResponseHistory>({});

  // Question overrides - this state is for things that the model has provided
  // that override how a question is displayed.
  const [questionOverrides, setQuestionOverrides] = useState({});
  const [animatingResponse, setAnimatingResponse] = useState<boolean>(false);

  // "back" is a flag used to govern product card animations.
  const [back, setBack] = useState(false);

  // Keep track of what type of UI is being displayed.
  const [screen, setScreen] = useState<Screen>();
  const [skipSplash, setSkipSplash] = useState<boolean>(false);

  const [responseSets, setResponseSets] = useState<ResponseSetWithResponses[]>(
    props.initialResponseSet ? [props.initialResponseSet] : []
  );
  const [activeResponseSetId, setActiveResponseSetId] = useState<string>(
    props.initialActiveResponseSetId || null
  );

  const [hasDisambiguation, setHasDisambiguation] = useState<boolean>(false);

  const [rankedProductIds, setRankedProductIds] = useState<RankedProductIds>(
    props.initialRankedProductIds || {
      losers: [],
      filtered: [],
    }
  );

  const getActiveResponseSet = () =>
    responseSets.find((responseSet) => responseSet.id === activeResponseSetId);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const currentQuestion = questions ? questions[currentQuestionIndex] : null;

  const router = useRouter();

  /**
   * On first load, attempt to hydrate rec UI state from browser session
   * storage.
   */
  useEffect(() => {
    // @TODO - Make some kind of manifest for these variables since it's a
    // predictable pattern.
    const storedResponseSets: ResponseSetWithResponses[] =
      getCategorySession("responseSets");
    const storedActiveResponseSetId: string = getCategorySession(
      "activeResponseSetId"
    );

    if (storedResponseSets) {
      setResponseSets(storedResponseSets);
    }
    if (storedActiveResponseSetId) {
      setActiveResponseSetId(storedActiveResponseSetId);
    }

    const storedOverrides = getCategorySession("questionOverrides");
    if (storedOverrides) {
      setQuestionOverrides(storedOverrides);
    }

    const storedTopProductIds: string[] = getCategorySession("topProductIds");
    const storedExplanations: RecommendationExplanation[][] =
      getCategorySession("explanations");

    const storedModelInput: {
      query: [];
      filters: [];
    } = getCategorySession("modelInput");

    if (storedTopProductIds) {
      // Remove the re-render directive so that it doesn't flash the "did not
      // change" effect.
      // @TODO remove this after making sure it still works.
      const resetTopProductIds = storedTopProductIds.map((item) => {
        return item;
      });

      setRecState((prevState) => {
        return {
          ...prevState,
          topProductIds: resetTopProductIds,
          explanations: storedExplanations,
          modelInput: storedModelInput,
        };
      });
    }

    const responseHistory = getCategorySession("responseHistory");
    if (responseHistory) {
      setResponseHistory(responseHistory);
    }

    const storedRankedProductIds = getCategorySession("rankedProductIds");
    if (storedRankedProductIds) {
      setRankedProductIds(storedRankedProductIds);
    }

    // Determine the starting screen.
    const storedSkipSplash = router.query.skipSplash
      ? true
      : getCategorySession("skipSplash");
    storeSkipSplash(storedSkipSplash);

    const screen =
      getCategorySession("screen") ||
      (storedSkipSplash ? "questions" : "splash");
    setScreen(screen);

    // Makes sure there is actually history if we have a get variable. This can
    // happen if someone copies a link and sends it to another person. If url
    // state is found but no session state, we rewrite the url without the get
    // variables so the user gets a clean start.
    const { isDone, questionId } = getUrlState(
      window.location.pathname + window.location.search
    );
    let replace = false;
    if (isDone) {
      replace = typeof responseHistory.final === "undefined";
    }
    if (questionId) {
      replace =
        !responseHistory ||
        (responseHistory && typeof responseHistory[questionId] === "undefined");
    }

    if (replace) {
      router.replace(window.location.pathname, "", { shallow: true });
    }
  }, [router.pathname]);

  // Restore current question from URL.
  useEffect(() => {
    if (questions) {
      const { questionId } = getUrlState(
        window.location.pathname + window.location.search
      );

      if (questionId) {
        const i = questions.findIndex((question) => question.id === questionId);
        if (i >= 0) {
          setCurrentQuestionIndex(i);
        }
      }
    }
  }, [questions]);

  /**
   * Write response set and recommendation history for a question.
   */
  const writeResponseHistory = (
    questionId: string,
    responseSet: ResponseSetWithResponses,
    questionIndex: number,
    ModelResponse: ModelResponse
  ) => {
    setResponseHistory((prevResponseHistory) => {
      const prevQuestionHistory = prevResponseHistory[questionId] || {};
      const history = {
        ...prevResponseHistory,
        [questionId]: {
          ...prevQuestionHistory,
          responseSet,
          topProductIds: ModelResponse.recommendations.map(
            (recommendation) => recommendation.productId
          ),
          explanations: ModelResponse.recommendations.map(
            (recommendation) => recommendation.explanations
          ),
          index: questionIndex,
          modelInput: ModelResponse.model_input,
        },
      };
      setCategorySession("responseHistory", history);

      return history;
    });
  };

  /**
   * Indicates that the user is done with the current response set.
   *
   * The next question answered will start a new response set.
   */
  const resetResponseSet = () => {
    setActiveResponseSetId(null);
    setCategorySession("activeResponseSetId", null);
  };

  /**
   * Resets the response set history.
   */

  const resetResponseHistory = () => {
    setResponseHistory({});
    setCategorySession("responseHistory", {});
  };

  /**
   * Incorporate a response into a new or existing response set.
   */
  const buildUpdatedResponseSet = (newResponse: Response) => {
    return deriveUpdatedResponseSet(newResponse, responseSets);
  };

  /**
   * Stores a response set in state and session storage.
   *
   * It will create a new response set if necessary.
   */
  const storeResponseSet = (responseSet: ResponseSetWithResponses) => {
    setResponseSets((prevResponseSets) => {
      const responseSetId = responseSet.id;

      const targetResponseSetIndex = prevResponseSets.findIndex(
        (responseSet) => responseSet.id === responseSetId
      );
      const needsNewResponseSet = targetResponseSetIndex === -1;

      // Update existing response set, or push a new one.
      const newResponseSets = [...prevResponseSets];
      if (needsNewResponseSet) {
        newResponseSets.push(responseSet);
      } else {
        newResponseSets[targetResponseSetIndex] = responseSet;
      }
      setCategorySession("responseSets", newResponseSets);
      return newResponseSets;
    });
  };

  /* Session/state setters */

  // @TODO type this argument.
  const storeRecState = (newRecState) => {
    setRecState((prevState) => {
      return { ...prevState, ...newRecState };
    });
    (newRecState.topProductIds || newRecState.topProductIds == null) &&
      setCategorySession("topProductIds", newRecState.topProductIds);
    (newRecState.explanations || newRecState.explanations == null) &&
      setCategorySession("explanations", newRecState.explanations);
    (newRecState.recNotes || newRecState.recNotes == null) &&
      setCategorySession("recNotes", newRecState.recNotes);
    (newRecState.losers || newRecState.losers == null) &&
      setCategorySession("losers", newRecState.losers);
    (newRecState.filtered || newRecState.filtered == null) &&
      setCategorySession("filtered", newRecState.filtered);
    (newRecState.modelInput || newRecState.filtered == null) &&
      setCategorySession("modelInput", newRecState.modelInput);
  };

  const storeScreen = (screen) => {
    setScreen(screen);
    setCategorySession("screen", screen);
  };

  const storeSkipSplash = (value) => {
    setSkipSplash(value);
    setCategorySession("skipSplash", value);
  };

  const storeActiveResponseSetId = (responseSetId) => {
    setActiveResponseSetId(responseSetId);
    setCategorySession("activeResponseSetId", responseSetId);
  };

  const storeCurrentQuestion = (newIndex: number) => {
    setCurrentQuestionIndex(newIndex);
    setCategorySession("currentQuestionIndex", newIndex);
    setCategorySession("currentQuestionId", questions[newIndex]?.id);
  };

  /**
   * Advance to the next question.
   */
  const nextQuestion = (
    responseSet: ResponseSetWithResponses,
    nextQuestion?: QuestionWithAnswers
  ) => {
    let newCurrentQuestionIndex;
    // If we are being supplied the next question specifically (e.g. from the
    // model), use that.
    if (nextQuestion) {
      newCurrentQuestionIndex = questions.findIndex(
        (question) => question.id === nextQuestion.id
      );
    } else {
      // Otherwise, use the lowest index question that comes after the last
      // answered, weighted question.
      newCurrentQuestionIndex = getNextWeightedQuestionIndex(
        questions,
        productCategoryConfig,
        responseSet
      );
    }

    storeCurrentQuestion(newCurrentQuestionIndex);
    const questionId = questions[newCurrentQuestionIndex]?.id;

    return { questionId, questionIndex: newCurrentQuestionIndex };
  };

  /**
   * Get data from the URL needed for the question iterator.
   */
  const getUrlState = (path: string) => {
    const { hostname, protocol } = window.location;
    const url = new URL(`${protocol}${hostname}${path}`);
    const pathname = url.pathname;
    const urlCategorySlug = pathname.split("/")[2];
    const questionId = url.searchParams.get("q");
    const isPreviewDone = url.searchParams.get("previewDone");
    const isDone = url.searchParams.get("done");

    return { isDone, isPreviewDone, questionId, urlCategorySlug };
  };

  /**
   * We support browser back/forward by looking at certain variables that we
   * push to the URL when people progress through the recommender.
   */
  useEffect(() => {
    router.beforePopState(({ as, options }) => {
      const { isDone, isPreviewDone, questionId, urlCategorySlug } =
        getUrlState(as);

      // We only want to manipulate state if we are going back and forth in a
      // single recommender.
      if (urlCategorySlug === productCategoryConfig.recommenderSlug) {
        const key = isDone || isPreviewDone ? "final" : questionId;
        restoreFromHistory(key);

        const storedSkipSplash = getCategorySession("skipSplash");
        const showSplash = !storedSkipSplash && key === null;

        if (isDone) {
          storeScreen("done");
        } else if (isPreviewDone) {
          storeScreen("previewDone");
        } else if (showSplash) {
          storeScreen("splash");
        } else {
          storeScreen("questions");
        }
      }

      return true;
    });
  }, [responseHistory]);

  /**
   * Given a key (either a question ID or "final"), restore question iterator state.
   *
   * Returns true if history was found, false if not.
   */
  const restoreFromHistory = (key: string) => {
    const history = responseHistory[key];

    if (history?.responseSet) {
      storeCurrentQuestion(history.index);
      storeResponseSet({ ...history.responseSet });
      storeRecState({
        topProductIds: [...history.topProductIds],
        explanations: [...history.explanations],
        modelInput: history.modelInput,
      });
      storeActiveResponseSetId(history.responseSet.id);

      return true;
    } else if (key !== "final") {
      setFirstQuestion();
      resetResponseSet();

      // Reset list of products shown.
      resetRecommendations();
    }

    return false;
  };

  /**
   * Gets the question ID of the latest response in the current response set.
   */
  const getLastAnsweredQuestionId = () => {
    const responseSet = getActiveResponseSet();

    if (responseSet.responses) {
      const lastResponse = responseSet.responses.slice(-1)[0];

      return lastResponse.questionId;
    }
  };

  /**
   * Limits allowed answers for a question.
   *
   * The model may request that we only allow a subset of the answers for a question.
   */
  const setQuestionAllowedAnswers = (
    questionText: string,
    allowedAnswers: string[]
  ) => {
    setQuestionOverrides((prevOverrides) => {
      if (!prevOverrides[questionText]) {
        prevOverrides[questionText] = {};
      }
      prevOverrides[questionText]["allowedAnswers"] = allowedAnswers;
      return prevOverrides;
    });
    setCategorySession("questionOverrides", questionOverrides);
  };

  /**
   * Set pointer back to the first question.
   */
  const setFirstQuestion = () => {
    setCurrentQuestionIndex(0);
  };

  /**
   * Gets the ID of the first quesion.
   */
  const getFirstQuestionId = () => {
    return questions[0].id;
  };

  /**
   * Resets consideration set
   */
  const resetRecommendations = () => {
    storeRecState({ topProductIds: null });
  };

  /**
   * Determines whether the person has progressed past the first question.
   */
  const pastFirstQuestion = () => {
    const activeResponseSet = getActiveResponseSet();

    return activeResponseSet?.responses
      ? activeResponseSet.responses.length > 0
      : false;
  };

  /**
   * Handle updating the consideration set in the UI, given a new set of
   * recommendations.
   */
  const updateRecDisplay = (mlRecData: ModelResponse) => {
    const recommendationsPayload = mlRecData.recommendations;

    const newTopProductIds = recommendationsPayload.map((recommendation, i) => {
      const product = products.find(
        (product) => product.id === recommendation.productId
      );

      if (!product) {
        throw new Error(
          `Unable to find matching productId for ${recommendation.productId}`
        );
      }
      return product.id;
    });

    // This helps us force a re-render on product cards, even when recs
    // don't change. We need to show an animation for cards that haven't
    // changed after someone responds.
    handleLoadingDone();
    storeRecState({
      topProductIds: newTopProductIds,
      explanations: recommendationsPayload.map(
        (recommendation) => recommendation.explanations
      ),
      recNotes: mlRecData.recNotes,
      losers: mlRecData.losers,
      filtered: mlRecData.filtered,
      modelInput: mlRecData.model_input,
    });

    return newTopProductIds;
  };

  /**
   * Handles the timing for ending the recs-loading animation state.
   *
   * To prevent "flashing" from very fast queries, we have a minimum time set.
   */
  const handleLoadingDone = () => {
    setAnimatingResponse(false);
  };

  /**
   * Take the product filter/rank metadata from the model response and save it
   * to state.
   */
  const saveRankedProductIds = (mlRecData: ModelResponse) => {
    // The goal here is to build a list of ranked product ids. This results in
    // a correctly ordered list of all products, and also provides metadata about
    // whether the product is filtered out.
    const rankedProductIds = {
      losers: mlRecData.losers || [],
      filtered: mlRecData.filtered || [],
    };

    setRankedProductIds(rankedProductIds);
    setCategorySession("rankedProductIds", rankedProductIds);
  };

  const getResponseByQuestionId = (questionId: string) => {
    const responseSet = getActiveResponseSet();

    return responseSet.responses.find(
      (response) => response.questionId === questionId
    );
  };

  return (
    <RecUIContext.Provider
      value={{
        animatingResponse,
        currentQuestionIndex,
        currentQuestion,
        questions,
        back,
        questionOverrides,
        productCategoryConfig,
        topProductIds: recState.topProductIds,
        topProducts,
        responseSets,
        activeResponseSet: getActiveResponseSet(),
        screen,
        hasDisambiguation,
        rankedProductIds,
        resetRecommendations,
        setAnimatingResponse,
        setCurrentQuestionIndex,
        nextQuestion,
        setBack,
        setFirstQuestion,
        updateRecDisplay,
        pastFirstQuestion,
        setQuestionAllowedAnswers,
        storeResponseSet,
        resetResponseSet,
        buildUpdatedResponseSet,
        storeActiveResponseSetId,
        handleLoadingDone,
        storeRecState,
        writeResponseHistory,
        resetResponseHistory,
        setHasDisambiguation,
        saveRankedProductIds,
        storeScreen,
        restoreFromHistory,
        getLastAnsweredQuestionId,
        getResponseByQuestionId,
        isInternalUser,
        recNotes: recState.recNotes,
        losers: recState.losers,
        filtered: recState.filtered,
        modelInput: recState.modelInput,
        getFirstQuestionId,
        skipSplash,
        explanations: recState.explanations,
      }}
    >
      {children}
    </RecUIContext.Provider>
  );
};

export default RecUIContextProvider;
