import HeatMeter, { MeterHeading } from "../../../product/summary/heat-meter";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import {
  getProductLabels,
  joinWithAnd,
  roundDecimal,
} from "@lib/utilities/shared-utilities";
import {
  ArcScoreDisplay,
  PanelExplainer,
  ScoreDisplay,
  SummaryTitles,
} from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import { laptopContentPhraseMap } from "@components/product/summary/laptop-usage-meter";
import { laptopPerformanceAttributes } from "@components/compare/compare-fns";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import Modal from "@components/modal/modal";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import {
  getQualitativeCompareWeight,
  getQualitativeWord,
} from "@lib/utilities/product-classifier";
import { CompareCardScoreDisplay } from "@components/compare/compare-components";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";

export const getLaptopPerformanceAttributePhrase = (
  product: Product,
  attribute: string
) => {
  if (product.slug.includes("apple")) {
    return laptopContentPhraseMap[
      attribute.replace("Benchmark", "").replace("Screen", "")
    ].titlePhrase
      .toLowerCase()
      .replace("ai", "AI")
      .replace("gaming and AI", "AI");
  } else {
    return laptopContentPhraseMap[
      attribute.replace("Benchmark", "").replace("Screen", "")
    ].titlePhrase
      .toLowerCase()
      .replace("ai", "AI");
  }
};
/**
 * Laptop Performance Comparison
 */
const LaptopPerformanceComparison = (props: {
  productA: Product;
  productB: Product;
  icon?: React.FC<{ className?: string }>;
  keySpecs?: boolean;
  compareResults: any;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const { productA, productB, icon, comparisons } = props;

  const { attributes: allAttributes } = useAttributes();

  if (!productA || !productB) {
    return null;
  }
  const productAName = getProductLabels(productA).shortLabel;
  const productBName = getProductLabels(productB).shortLabel;

  const scoresA = laptopPerformanceAttributes.map((attribute) =>
    parseFloat(productA.attributes[attribute].value)
  );
  const scoresB = laptopPerformanceAttributes.map((attribute) =>
    parseFloat(productB.attributes[attribute].value)
  );

  const { winner } = props.compareResults["laptopPerformance"];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  const winners = scoresA.map((score, index) => {
    if (
      score - scoresB[index] > 0.2 ||
      getQualitativeCompareWeight(score) >
        getQualitativeCompareWeight(scoresB[index])
    ) {
      return productA;
    } else if (
      scoresB[index] - score > 0.2 ||
      getQualitativeCompareWeight(scoresB[index]) >
        getQualitativeCompareWeight(score)
    ) {
      return productB;
    } else {
      return "tie";
    }
  });

  const productAWins = [];
  const productBWins = [];

  laptopPerformanceAttributes.forEach((attribute, index) => {
    if (winners[index] === productA) {
      productAWins.push(
        getLaptopPerformanceAttributePhrase(productA, attribute)
      );
    }
  });
  laptopPerformanceAttributes.forEach((attribute, index) => {
    if (winners[index] === productB) {
      productBWins.push(
        getLaptopPerformanceAttributePhrase(productB, attribute)
      );
    }
  });

  const title = "Performance";

  let first;

  if (productAWins.length === 0 && productBWins.length === 0) {
    first = (
      <>
        The {productAName} and the {productBName} have <strong>similar</strong>{" "}
        performance.
      </>
    );
  } else if (productAWins.length > 0 && productBWins.length > 0) {
    first = (
      <>
        The {productAName} performs better than the {productBName} for{" "}
        {joinWithAnd(productAWins)}, while the {productBName} performs better
        than the {productAName} for {joinWithAnd(productBWins)}.
      </>
    );
  } else if (productAWins.length > 0) {
    first = (
      <>
        The {productAName} performs <strong>better</strong> than the{" "}
        {productBName} for {joinWithAnd(productAWins)}.
      </>
    );
  } else if (productBWins.length > 0) {
    first = (
      <>
        The {productBName} performs <strong>better</strong> than the{" "}
        {productAName} for {joinWithAnd(productBWins)}.
      </>
    );
  }

  const second =
    "Better performance provides a smoother experience and allows you to get work done faster. PerfectRec’s performance scores take into account the laptop’s hardware as well as your intended use.";

  const llmComparison = comparisons?.comparePerformanceLaptop?.result;

  const dims = ["cpuScore", "gpuScore", "ram"];
  const dimName = ["CPU", "GPU", "RAM"];

  return (
    <DonePanel
      title={title}
      className={"!bg-white"}
      modal={{
        title: "Performance",
        text: "PerfectRec’s performance scores are determined differently for different use cases. The overall performance score is determined by taking the average of the performance scores for the use cases you selected. If you skipped the use case question, the overall performance is based on the average of the specific use case performance scores.",
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={allAttributes["overallPerformanceScore"]}
        categoryName={productA.metadata.categoryName}
      />
      <div className="flex flex-col w-full mt-2 mb-6 text-gray-700">
        {dims.map((dim, index) => {
          return (
            <div
              key={index}
              className={`flex justify-between items-end border-b-[1px] ${
                index === 0 && "border-t-[1px]"
              } border-gray-300 py-3`}
            >
              <div className="">
                <ScoreDisplay
                  attribute={allAttributes[dim]}
                  product={productA}
                  scoreClass="!text-base"
                  unitClass="!text-sm"
                />
              </div>
              <div className="tracking-wider font-bold text-xs">
                {dimName[index]}
              </div>
              <div className="">
                <ScoreDisplay
                  attribute={allAttributes[dim]}
                  product={productB}
                  scoreClass="!text-base"
                  unitClass="!text-sm"
                />
              </div>
            </div>
          );
        })}
      </div>
      <SummaryTitles {...{ first, second, llm: llmComparison }} />
    </DonePanel>
  );
};

export default LaptopPerformanceComparison;
