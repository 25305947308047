import Modal from "@components/modal/modal";
import { Dialog } from "@headlessui/react";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import ProductCompareTable from "@components/compare/product-compare-table";
import { useAttributes } from "@lib/hooks/use-attributes";
import { useState } from "react";
import { useEffect } from "react";
import { Product } from "@lib/model/product";
import ProductCompareTableSelectable from "@components/compare/product-compare-table-selectable";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { ProductImage } from "./product-card-composed";
import { PrimaryButton } from "@components/common/button";

const ProductCardStatsModal = (props: {
  open: boolean;
  closeHandler: () => void;
  productCategoryConfigName: string;

  // The product whose stats to show. Always required.
  product: Product;

  // Optional - supply this if you want to enable the compare feature.
  topProducts?: Product[];

  // Optional - supply this if you want to track additional properties.
  trackingContext?: Record<string, unknown>;
}) => {
  const { attributes } = useAttributes();
  const [showCompare, setShowCompare] = useState(false);

  // When it first opens, don't show compare view, even if it was left open.
  useEffect(() => {
    if (props.open) {
      setShowCompare(false);
    }
  }, [props.open]);

  return (
    <Modal
      onClose={() => {
        props.closeHandler();
      }}
      modalOpen={props.open}
      variant={showCompare ? "xlarge" : "large"}
      onSubmit={() => {
        eventTracker.track(Events.CompareModalOpen, {
          productId: props.product.id,
          productName: getProductLabels(props.product).seoLabel,
          element: "view-specs-compare-button",
          ...(props.trackingContext || {}),
        });
        setShowCompare(!showCompare);
      }}
      omitBottomButtons={true}
      showCloseButton={false}
      title={showCompare ? "Comparing recommendations" : undefined}
    >
      {!showCompare || !props.topProducts ? (
        <>
          <div className="mx-auto">
            <ProductImage
              product={props.product}
              categoryName={props.productCategoryConfigName}
              sizeClassName="max-h-48 mx-auto"
              widthToFetch={300}
            />
          </div>
          <Dialog.Title
            as="h5"
            className="text-center sticky top-20 md:top-14 p-2 bg-white z-10 mb-4"
          >
            {getProductLabels(props.product).shortLabel}
          </Dialog.Title>
          {props.topProducts && (
            <div className="bg-white sticky top-[124px] md:top-[104px] pb-1 z-10 mb-4">
              <PrimaryButton
                onClick={() => {
                  eventTracker.track(Events.CompareModalOpen, {
                    productId: props.product.id,
                    productName: getProductLabels(props.product).seoLabel,
                    element: "view-specs-compare-button",
                    ...(props.trackingContext || {}),
                  });
                  setShowCompare(true);
                }}
                variant="outline"
                className="mx-auto"
              >
                Compare recommendations
                <ArrowSmallRightIcon className="h-3" />
              </PrimaryButton>
            </div>
          )}
          <ProductCompareTable
            products={[props.product]}
            attributes={attributes}
            category={props.productCategoryConfigName}
          />
        </>
      ) : (
        <ProductCompareTableSelectable
          products={props.topProducts}
          categoryName={props.productCategoryConfigName}
          attributes={attributes}
        />
      )}
    </Modal>
  );
};

export default ProductCardStatsModal;
