import {
  IFaq,
  IMediaHighlight,
  IProductFields,
  IScenario,
} from "@lib/model/contentful";
import { Product } from "@lib/model/product";
import {
  AssetType,
  centsToDollars,
  getImageUrl,
  getProductLabels,
  getProductLandingPageUrl,
  getCategoryFeatureState,
  getCategoryProductsUrl,
  getUnderConstruction,
  getProductLandingPageUrlBySlug,
  getCanonicalSlug,
  getProductLandingPagePathBySlug,
  getProductLandingPagePath,
  parseOpenAIJSON,
} from "@lib/utilities/shared-utilities";
import Breadcrumbs from "@components/common/breadcrumbs";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import ProductCompareTable from "@components/compare/product-compare-table";
import PurchaseData from "@components/product/purchase-data";
import PurchaseOption from "@lib/model/purchase-option";
import ProductCategoryContextProvider, {
  ProductCategoryContext,
} from "contexts/product-category-context";
import { PageLayout } from "@components/layouts/page-layout";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { PhoneChartsStandard } from "@components/recommendation/classified-attribute-display/phone-attribute-display";
import { TvChartsStandard } from "@components/recommendation/classified-attribute-display/tv-attribute-display";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import Meta from "./meta";
import ScenariosList from "./scenarios-list";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import {
  StandardCard,
  StandardCardSection,
} from "@components/recommendation/done-page-fixtures";
import {
  LandingPageFeedbackForm,
  LlmFeedbackForm,
} from "@components/form/feedback-forms";
import ReviewCard from "@components/recommendation/review-card";
import SeeOnAmazon from "@components/recommendation/see-on-amazon";
import { usePurchaseData } from "@lib/hooks/use-purchase-data";
import { HeadphonesChartsStandard } from "@components/recommendation/classified-attribute-display/headphones-attribute-display";
import { LaptopChartsStandard } from "@components/recommendation/classified-attribute-display/laptop-attribute-display";
import ImageWithFallback from "@components/common/image-with-fallback";
import SimilarProducts from "@components/compare/similar-products";
import { StickyBottomCta } from "./vs-page";
import ProsAndCons, { getProsAndCons } from "@components/common/pros-and-cons";
import React, { useContext, useMemo, useRef, useState } from "react";
import { FaqsCard } from "@components/common/faq";
import router from "next/router";
import { VariantSelectTable } from "@components/product/VariantDropdown";
import ProductLandingTitle from "@components/product/product-landing-title";
import Trust from "@components/common/trust";
import {
  StarRatingsSection,
  getStarRatings,
} from "@components/product/summary/star-ratings";
import FortySixtySection from "@components/frame/forty-sixty-container";
import KeyStatsDisplay from "@components/product/summary/key-stats";
import CollapsibleSection from "@components/common/collapsible-section";
import { PromptType, StoredLlmOutput, prosAndConsSchema } from "@lib/model/llm";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import Modal from "@components/modal/modal";
import Ajv from "ajv";
import TableOfContents from "@components/common/table-of-contents";
import { ProductHeading } from "@components/common/section-heading";
import BlankTextPlaceholder from "@components/common/blank-text-placeholder";
import {
  formatBlurbForDisplay,
  isValidBlurb,
} from "@lib/utilities/shared-llm-utilities";
import HorizontalAdUnit from "@components/ads/horizontal-ad-unit";
import { MonitorChartsStandard } from "@components/compare/compare-attribute-display/monitors-comparison-display";

export const getOutputs = async (newProduct, productCategory, setOutputs) => {
  try {
    const params = new URLSearchParams();
    if (productCategory.name === "tvs") {
      params.append(
        "path",
        getProductLandingPagePathBySlug(
          getCanonicalSlug(newProduct),
          productCategory
        )
      );
    } else {
      params.append(
        "path",
        getProductLandingPagePath(newProduct, productCategory)
      );
    }
    params.append("cacheOnly", "true");

    const generalPromptTypes = [
      "productDetail",
      "isGood",
      "worthBuying",
      "prosAndCons",
    ];

    const categoryPromptTypes = {
      tvs: [
        "explainPictureQualityTV",
        "explainMoviesTV",
        "explainSportsTV",
        "explainGamingTV",
        "explainNewsTV",
        "explainAnimationTV",
        "explainBrightRoomTV",
      ],
      laptops: [
        "explainGeneralUseLaptop",
        "explainEngineeringLaptop",
        "explainGamingLaptop",
        "explainPerformanceLaptop",
        "explainScreenLaptop",
      ],
      smartphones: [
        "explainPhotosPhone",
        "explainVideosPhone",
        "explainCameraPhone",
      ],
      headphones: [
        "explainMusicHeadphones",
        "explainCallsHeadphones",
        "explainGamingHeadphones",
        "explainTvHeadphones",
        "explainPodcastsHeadphones",
        "explainExerciseHeadphones",
        "explainNoiseReductionHeadphones",
        "explainTravelHeadphones",
      ],
      tablets: [],
      monitors: [],
    };

    const promptTypesToFetch = [
      ...generalPromptTypes,
      ...categoryPromptTypes[productCategory.name],
    ];

    const results = await Promise.all(
      promptTypesToFetch.map(async (promptType) => {
        params.set("promptType", promptType);

        const url = `${window?.location?.origin}/api/getLlmOutput?${params}`;

        const result = await fetch(url);

        if (!result.ok) {
          throw new Error("Network response was not ok");
        }

        const json = await result.json();

        return isValidBlurb(json.assistantMessage, promptType)
          ? {
              [promptType]: {
                metadata: json.metadata,
                result: formatBlurbForDisplay(
                  json.assistantMessage,
                  promptType
                ),
              },
            }
          : {
              [promptType]: { metadata: null, result: null },
            };
      })
    );

    const combinedOutput = results.reduce(
      (acc, result) => ({ ...acc, ...result }),
      {}
    );

    setOutputs(combinedOutput);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const ProductLandingPage = ({
  initialProduct,
  purchaseData,
  productEntry,
  productCategory,
  configMap,
  scenarios,
  similarProducts,
  faqs,
  mediaHighlights,
  storedOutputs,
}: {
  initialProduct: Product;
  productEntry: IProductFields;
  productCategory: ProductCategoryConfig;
  purchaseData: PurchaseOption[];
  configMap: ProductCategoryConfigMap;
  scenarios: IScenario[];
  similarProducts: Product[];
  faqs?: IFaq[];
  mediaHighlights: IMediaHighlight[];
  storedOutputs: Record<PromptType, StoredLlmOutput>;
}) => {
  const [product, setProduct] = useState<Product>(initialProduct);

  const [outputs, setOutputs] = useState<any>(storedOutputs);

  const [firstLoad, setFirstLoad] = useState(true);

  const onSelectProduct = (product: Product, position?: number) => {
    router.push(
      getProductLandingPagePath(product, productCategory),
      undefined,
      {
        shallow: true,
      }
    );
    if (!firstLoad) {
      getOutputs(product, productCategory, setOutputs);
    }
    setFirstLoad(false);
    setProduct(product);
  };

  const { seoLabel, shortLabel, metaDescription } = getProductLabels(product);
  const attributes = productCategory.attributeConfiguration;

  const shouldShowLikeDislike = () =>
    productEntry?.whatWeLike && productEntry?.whatWeDislike;

  const hasCharts = getCategoryFeatureState(
    productCategory,
    "landingPageCharts"
  );

  const ctaRef = useRef();

  const ajv = new Ajv();
  const validateProAndCons = ajv.compile(prosAndConsSchema);

  const prosAndConsObject: any =
    outputs.prosAndCons?.result && parseOpenAIJSON(outputs.prosAndCons.result);

  const prosAndCons: any =
    (validateProAndCons(prosAndConsObject) && prosAndConsObject) ||
    getProsAndCons(product, productCategory);

  const starRatings = getStarRatings(product, attributes, productCategory.name);

  const numberOfOffers = purchaseData ? purchaseData.length : 0;
  const lowPrice = product.bestPrice / 100 || 0;
  const highPrice = purchaseData
    ? Math.max(...purchaseData.map((data) => data.price), 0) / 100
    : 0;

  const productJson = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: `${getProductLabels(product).shortLabel}`,
    brand: {
      "@type": "Brand",
      name: product.manufacturer,
    },
    description: outputs?.productDetail?.result,
    review: {
      "@type": "Review",
      name: `${getProductLabels(product).shortLabel} review and stats`,
      author: {
        "@type": "Person",
        name: "Joe Golden",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: starRatings.length > 0 ? starRatings[0].stars : 1,
      },
      positiveNotes: productCategory.displayProsAndCons && {
        "@type": "ItemList",
        itemListElement: prosAndCons.pros.slice(0, 5).map((pro, index) => ({
          "@type": "ListItem",
          position: index + 1,
          name: capitalizeFirstLetter(pro),
        })),
      },
      negativeNotes: productCategory.displayProsAndCons && {
        "@type": "ItemList",
        itemListElement: prosAndCons.cons.slice(0, 4).map((con, index) => ({
          "@type": "ListItem",
          position: index + 1,
          name: capitalizeFirstLetter(con),
        })),
      },
    },
    offers: purchaseData && {
      "@type": "AggregateOffer",
      offerCount: numberOfOffers,
      lowPrice: lowPrice,
      highPrice: highPrice,
      priceCurrency: "USD",
    },
  };

  /**
   * The author and the body section is rendered twice, and visibility varies.
   */
  const DescriptionSection = (bodySectionProps: { className?: string }) => {
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

    const { productVariants, products: allProducts } = useGlobalAllProducts();

    if (!outputs?.productDetail?.result) {
      return null;
    }

    const getCopy = (categoryName: string) => {
      const canonicalProduct =
        productCategory.name === "tvs"
          ? allProducts.find(
              (allProduct) => allProduct.slug === getCanonicalSlug(product)
            )
          : product;

      const canonicalVariant = productVariants.find(
        (variant) => variant.id === canonicalProduct.id
      );

      if (categoryName === "tvs") {
        return (
          <>
            This description is based on the{" "}
            <strong>
              {canonicalVariant.variantLabel} {canonicalVariant.label}
            </strong>
            .{" "}
            {outputs?.productDetail?.metadata?.prices.length === 1 && (
              <>
                At the time of writing the {canonicalVariant.variantLabel}{" "}
                {canonicalVariant.label} cost{" "}
                {centsToDollars(
                  outputs?.productDetail?.metadata?.prices[0]?.price
                )}
                .
              </>
            )}
          </>
        );
      } else if (categoryName === "laptops") {
        return (
          <>
            This description is based on the {canonicalVariant.label} configured
            with{" "}
            {canonicalVariant.variantLabel
              .replaceAll("GB", "GB storage")
              .replaceAll("GB storage |", "GB RAM,")
              .replace(";", ",")}
            .{" "}
            {outputs?.productDetail?.metadata?.prices.length === 1 && (
              <>
                At the time of writing the {canonicalVariant.label} cost{" "}
                {centsToDollars(
                  outputs.productDetail?.metadata?.prices[0]?.price
                )}
                .
              </>
            )}
          </>
        );
      } else {
        return (
          outputs?.productDetail?.metadata?.prices.length === 1 && (
            <>
              At the time of writing the {canonicalVariant.label} cost{" "}
              {centsToDollars(
                outputs?.productDetail?.metadata?.prices[0]?.price
              )}
              .
            </>
          )
        );
      }
    };

    return (
      <>
        <CollapsibleSection heading="Description" expandedDefault isLast>
          <div className={bodySectionProps.className}>
            <div className="my-2">
              <p className="text-sm sm:text-base">
                {outputs.productDetail.result}{" "}
                <span
                  onClick={() => {
                    setFeedbackModalOpen(true);
                  }}
                  className="inline-flex"
                >
                  <span className="text-gray-400 hover:cursor-pointer hover:underline">
                    Give&nbsp;Feedback
                  </span>
                  <ArrowRightIcon className="h-5 w-5 pl-1 text-gray-400 hover:cursor-pointer hover:underline relative top-1" />
                </span>
              </p>
              {productVariants && allProducts ? (
                <p className="text-sm text-gray-400 inline-flex">
                  <span>{getCopy(productCategory.name)} </span>
                </p>
              ) : (
                <BlankTextPlaceholder textForSizing="this description is based on the product variant with some specs. At the time of writing, the variant with some specs cost some dollars." />
              )}
            </div>
          </div>
        </CollapsibleSection>
        <Modal
          onClose={() => setFeedbackModalOpen(false)}
          modalOpen={feedbackModalOpen}
          omitBottomButtons
          title="Share your feedback"
        >
          <LlmFeedbackForm hiddenFields={{}} />
        </Modal>
      </>
    );
  };

  const BrandSection = (bodySectionProps: { className?: string }) => {
    const { getProductBrands } = useContext(ProductCategoryContext);

    const brand = useMemo(() => {
      const brands = getProductBrands([product]);

      if (brands.length === 0) {
        return null;
      }

      return brands[0];
    }, [getProductBrands, product]);

    if (!brand || !brand.description) {
      return null;
    }

    return (
      <CollapsibleSection heading={`About ${brand.label}`} isLast>
        <div className={bodySectionProps.className}>
          {brand && (
            <>
              <div className="text-sm sm:text-base max-w-screen-lg mx-auto">
                {brand.description}
              </div>
            </>
          )}
        </div>
      </CollapsibleSection>
    );
  };

  usePageScrollTracking({ page: "Product landing" });

  const breadcrumbs = [
    {
      name: capitalizeFirstLetter(productCategory.pluralNoun),
      href: getCategoryProductsUrl(productCategory),
    },
    {
      name: getProductLabels(product).shortLabel,
    },
  ];

  const breadcrumbsJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 0,
        name: "Home",
        item: "https://www.perfectrec.com",
      },
      ...breadcrumbs.slice(0, -1).map(({ name, href }, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name,
        item: `https://www.perfectrec.com${href}`,
      })),
      {
        "@type": "ListItem",
        position: breadcrumbs.length,
        name: breadcrumbs[breadcrumbs.length - 1].name,
      },
    ],
  };

  const Shopping = () => {
    const { hasPrice, amazonPurchaseOption } = usePurchaseData(product);

    if (!product?.review && !amazonPurchaseOption && !hasPrice) {
      return null;
    }

    return (
      <div>
        <StandardCard
          title={getProductLabels(product).shortLabel}
          className=""
          headerClassName="bg-blue-50"
        >
          {hasPrice && (
            <StandardCardSection
              title="Start shopping"
              description={
                <>
                  We <strong>don&apos;t</strong> receive any commission or
                  payment when you buy through&nbsp;these&nbsp;links.
                </>
              }
              disclosureButtonText="Learn more"
              disclosureText={
                <>
                  Other recommendation sites make money when you buy through
                  their links. This gives them an incentive to recommend more
                  expensive products that might not be worth it for you. It also
                  creates an incentive to encourage you to buy now, when you
                  might be better off waiting for sales or
                  new&nbsp;product&nbsp;releases.
                  <br />
                  <br />
                  PerfectRec does not take any commissions so that we can stay
                  100% independent and give you truly&nbsp;unbiased&nbsp;advice.
                </>
              }
            >
              <PurchaseData product={product} rowClassName="bg-white" />
            </StandardCardSection>
          )}
          {(product?.review || amazonPurchaseOption) && (
            <StandardCardSection
              title="See more"
              description="Dig into reviews and images"
              last={true}
            >
              <div className="flex flex-col gap-2">
                <ReviewCard review={product?.review} />
                <SeeOnAmazon amazonPurchaseOption={amazonPurchaseOption} />
              </div>
            </StandardCardSection>
          )}
        </StandardCard>
      </div>
    );
  };

  const canonicalSlug = product?.isCanonical
    ? product?.slug
    : product?.variants.find((variant) => variant.isCanonical)?.slug;

  const canonicalUrl = getProductLandingPageUrlBySlug(
    canonicalSlug,
    productCategory
  );

  return (
    <PageLayout configMap={configMap}>
      <Meta
        title={`${seoLabel}`}
        description={metaDescription}
        url={getProductLandingPageUrl(product, productCategory)}
        image={getImageUrl(
          product?.image,
          AssetType.ProductImage,
          { width: 600 },
          productCategory.name
        )}
        canonicalUrl={canonicalUrl}
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbsJson),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(productJson),
          }}
        />
      </Meta>
      <ProductCategoryContextProvider
        productCategoryConfig={productCategory}
        faqs={faqs}
      >
        <div className="container-toc">
          <div className="hidden xl:block min-w-[200px] relative pt-[116px]">
            <TableOfContents
              selector=".content-section h2"
              title="Table of Contents"
            />
          </div>
          <div className="content-section">
            <Breadcrumbs pages={breadcrumbs} removeLastHref />
            <h1
              ref={ctaRef}
              className="md:hidden text-center m-0 mt-4 mb-2 text-3xl leading-tight"
            >
              {getProductLabels(product).shortLabel}
            </h1>
            <div
              className="my-6 md:my-12"
              data-testid="product-landing-page-data"
            >
              <FortySixtySection className="mb-4">
                <div>
                  <div className="sticky top-[65px]">
                    <div className="bg-white rounded-xl p-5">
                      <ImageWithFallback
                        width={600}
                        height={600}
                        src={getImageUrl(
                          product?.image,
                          AssetType.ProductImage,
                          { width: 600 },
                          productCategory.name
                        )}
                        alt={seoLabel}
                        className="object-contain max-h-[600px] mx-auto"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <ProductLandingTitle
                    product={product}
                    ctaRef={ctaRef}
                    numberOfStarRatings={starRatings.length}
                    setProduct={setProduct}
                    className=""
                    testId="product-landing-title"
                  />
                  <KeyStatsDisplay
                    product={product}
                    className="justify-start"
                  />

                  <div className="flex flex-col gap-4 my-4 md:my-1">
                    <DescriptionSection className="mt-3" />
                    <BrandSection className="mt-3" />
                  </div>
                  <VariantSelectTable
                    product={product}
                    setProduct={onSelectProduct}
                    className="mt-4"
                  />
                </div>
              </FortySixtySection>
              {/* <HorizontalAdUnit adSlot="5639232039" ezoicId="" /> */}
              {((productCategory.displayProsAndCons &&
                prosAndCons.pros.length !== 0) ||
                starRatings?.length > 0) && (
                <div className="mb-2">
                  <div id="star-ratings" />
                  <ProductHeading id="evaluation">Evaluation</ProductHeading>
                  {productCategory.displayProsAndCons &&
                    prosAndCons.pros.length !== 0 && (
                      <div className="mb-1">
                        <ProsAndCons
                          product={product}
                          productCategory={productCategory}
                          prosAndCons={prosAndCons}
                        />
                      </div>
                    )}
                  <StarRatingsSection product={product} outputs={outputs} />
                </div>
              )}
              {/* <HorizontalAdUnit adSlot="7078653821" ezoicId="" /> */}
              <div className="mt-4 mb-8 flex flex-col">
                <ProductHeading id="full-specs">Full Specs</ProductHeading>
                <div>
                  <ProductCompareTable
                    products={[product]}
                    attributes={attributes}
                    category={productCategory.name}
                    context="full"
                    className=""
                  />
                </div>
              </div>

              {product.bestPurchaseOption &&
                !getUnderConstruction(productCategory, "prices") && (
                  <ProductHeading id="shopping">Shopping</ProductHeading>
                )}
              <Shopping />
              {hasCharts && (
                <>
                  <ProductHeading id="comparison-charts">
                    Comparison charts
                  </ProductHeading>
                  <Charts
                    {...{
                      productCategory,
                      attributes,
                      product,
                    }}
                  />
                </>
              )}
              {/* <HorizontalAdUnit adSlot="5765572155" ezoicId="" /> */}
              {/* Feedback form */}
              <StandardCard className="bg-panel-blue-2 my-8">
                <StandardCardSection
                  title="Give feedback"
                  description="We're constantly perfecting our model"
                  last={true}
                >
                  <LandingPageFeedbackForm
                    products={[product]}
                    categoryName={productCategory.name}
                    pageType="product-landing"
                  />
                </StandardCardSection>
              </StandardCard>

              {scenarios.length > 0 && (
                <>
                  <ProductHeading
                    id="guides"
                    tocName={`${capitalizeFirstLetter(
                      productCategory.noun
                    )} guides`}
                  >
                    {capitalizeFirstLetter(productCategory.noun)} guides you may
                    be interested in
                  </ProductHeading>
                  <ScenariosList
                    category={productCategory}
                    scenarios={scenarios}
                  />
                </>
              )}
              {/* <HorizontalAdUnit adSlot="7618528119" ezoicId="" /> */}
              {similarProducts.length > 0 &&
                productCategory.features?.vsPages && (
                  <div className="my-8 flex flex-col">
                    <ProductHeading
                      id="compare"
                      tocName="Compare to alternatives"
                    >
                      {`Compare ${
                        getProductLabels(product).shortLabel || "this product"
                      } to similarly priced alternatives`}
                    </ProductHeading>

                    <SimilarProducts
                      comparisons={similarProducts.map((similarProduct) => [
                        product,
                        similarProduct,
                      ])}
                      category={productCategory}
                    />
                  </div>
                )}
              {/* <HorizontalAdUnit adSlot="1699987026" ezoicId="" /> */}
              {productCategory.features?.faqs && (
                <>
                  <ProductHeading id="faq">FAQs</ProductHeading>
                  <FaqsCard
                    className="mb-8"
                    product={product}
                    llmFaqs={outputs}
                  />
                </>
              )}
              {/* <HorizontalAdUnit adSlot="9386905359" ezoicId="" /> */}
              <ProductHeading id="trust">Why trust us</ProductHeading>
              <Trust
                mediaHighlights={mediaHighlights}
                category={productCategory}
              />
            </div>
          </div>
        </div>
      </ProductCategoryContextProvider>
    </PageLayout>
  );
};

/**
 * The charts for this product.
 */
const Charts = ({
  product,
  productCategory,
  attributes,
}: {
  product: Product;
  productCategory: ProductCategoryConfig;
  attributes: AttributeConfigurationMap;
}) => {
  const { products: allProducts } = useGlobalAllProducts();

  const getRank = (chartProduct: Product) => {
    if (chartProduct.id === product?.id) {
      return "A";
    }
  };

  const pageType = "product-detail";

  const chartProps = {
    productCategory,
    attributes,
    getRank,
    allProducts,
    compareProducts: [product],
    pageType,
  };

  return (
    <div className="flex flex-col gap-2 my-4">
      {allProducts && (
        <>
          {productCategory.name === "smartphones" && (
            <PhoneChartsStandard {...chartProps} />
          )}
          {productCategory.name === "tvs" && (
            <TvChartsStandard {...chartProps} />
          )}
          {productCategory.name === "headphones" && (
            <HeadphonesChartsStandard {...chartProps} />
          )}
          {productCategory.name === "laptops" && (
            <LaptopChartsStandard {...chartProps} />
          )}
          {productCategory.name === "monitors" && (
            <MonitorChartsStandard {...chartProps} />
          )}
        </>
      )}
    </div>
  );
};

export default ProductLandingPage;
