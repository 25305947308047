import {
  AnswerInputProps,
  trackConfirmationShown,
} from "@components/tray/answer-input";
import { useContext } from "react";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { getAnswers } from "@lib/utilities/rec-ui";
import { ScaleChoiceClassification } from "@lib/attribute/classification";
import { PrimaryButton } from "@components/common/button";
import useAnswerConfirmation from "@lib/hooks/use-answer-confirmation";

const ScaleChoiceAnswerInput = (props: AnswerInputProps) => {
  const { questionOverrides, productCategoryConfig, activeResponseSet } =
    useContext(RecUIContext);
  const currentQuestion = props.currentQuestion;
  const answers = getAnswers(
    questionOverrides,
    currentQuestion,
    productCategoryConfig,
    activeResponseSet
  );
  const { getConfirmation } = useAnswerConfirmation();
  const classification = ScaleChoiceClassification[currentQuestion.scaleType];
  return (
    <>
      <div className="grid grid-flow-col auto-cols-fr gap-2">
        {answers.map((answer, index) => {
          const isSelected = props.confirmAnswer?.id === answer.id;
          return (
            <PrimaryButton
              onClick={() => {
                const confirmation = getConfirmation(answer);
                if (confirmation) {
                  props.setConfirmAnswer(confirmation);
                  trackConfirmationShown(currentQuestion, answer, confirmation);
                  return;
                }
                props.animateQuestionTray(
                  () => {},
                  () => {
                    props.setConfirmAnswer(null);
                    props.submitNewResponse(answer);
                  }
                );
              }}
              key={answer.id}
              variant={isSelected ? "solid" : "outline"}
              data-testid={`answer-${answer.id}`}
            >
              {index + 1}
            </PrimaryButton>
          );
        })}
      </div>
      <div className="grid grid-flow-col auto-cols-fr mb-4 mt-2 text-gray-400">
        {classification && (
          <>
            <div className="text-sm">{classification[1]}</div>
            <div className="text-sm text-right">{classification[4]}</div>
          </>
        )}
      </div>
    </>
  );
};

export default ScaleChoiceAnswerInput;
