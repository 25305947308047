import { getBudgetExplanation } from "@lib/utilities/product-classifier";
import { Product } from "@lib/model/product";
import { useBudget } from "./use-multi-response";

export const useBudgetExplanation = ({
  product,
  maxBudgetOverride,
}: {
  product: Product;
  // optional max budget to use instead of the calculated one by user answer
  maxBudgetOverride?: number;
}) => {
  const { userMaxBudget, didUserChooseMaxBudget, isSkip } = useBudget();

  // If the user chose the maximum budget, we don't want to show the budget explanation
  // as it can be misleading to show such large "savings"
  if (didUserChooseMaxBudget || isSkip) {
    return {
      phrase: null,
      interval: null,
      isUnmet: false,
      didUserChooseMaxBudget: true,
    };
  }

  return {
    ...getBudgetExplanation(
      maxBudgetOverride || userMaxBudget,
      product.bestPrice
    ),
    didUserChooseMaxBudget,
  };
};
