import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import ProductCompareTable from "@components/compare/product-compare-table";
import React, { useEffect, useState } from "react";
import { Product } from "@lib/model/product";
import {
  getProductOptions,
  getProductOptionsWithVariants,
} from "@lib/utilities/rec-ui";
import { AttributeConfigurationMap } from "@lib/model/attribute";

const ProductCompareTableSelectable = ({
  products,
  categoryName,
  attributes,
  onSelectProduct,
  className = "",
  isModal = true,
  displayImage = true,
  vs = false,
  context = "compare",
  headerRows,
}: {
  products: Product[];
  categoryName: string;
  attributes: AttributeConfigurationMap;
  isModal?: boolean;
  displayImage?: boolean;
  onSelectProduct?: (product: Product, i: number) => void;
  className?: string;
  vs?: boolean;
  context?: "compare" | "full";
  headerRows?: React.ReactNode;
}) => {
  const { products: allProducts } = useGlobalAllProducts();

  const [comparingProducts, setComparingProducts] =
    useState<Product[]>(products);

  // If the three recs change, reset state. Otherwise, we keep any changes that
  // the user has made.
  useEffect(() => {
    setComparingProducts(products);
  }, [products]);

  const setComparingProduct = (newProduct, index) => {
    setComparingProducts((prevComparingProducts) => {
      const newComparingProducts = [...prevComparingProducts];
      // If the user selects a product that is already being compared in another
      // position, switch the products being shown in those two positions.
      const switchToIndex = prevComparingProducts.findIndex((product) => {
        product.id === newProduct.id;
      });
      if (switchToIndex !== -1) {
        newComparingProducts[switchToIndex] = prevComparingProducts[index];
      }

      if (newProduct) {
        newComparingProducts[index] = newProduct;
      }

      return newComparingProducts;
    });

    if (onSelectProduct) {
      onSelectProduct(newProduct, index);
    }
  };

  let optionGroups = [];
  if (attributes && allProducts) {
    optionGroups =
      categoryName === "laptops"
        ? getProductOptionsWithVariants(
            allProducts,
            "laptops",
            attributes,
            undefined,
            window
          )
        : getProductOptions(allProducts, categoryName);
  }

  return (
    <>
      <ProductCompareTable
        products={comparingProducts}
        category={categoryName}
        attributes={attributes}
        setComparingProduct={setComparingProduct}
        optionGroups={optionGroups}
        isModal={isModal}
        displayImage={displayImage}
        className={className}
        context={context}
        vs={vs}
      />
    </>
  );
};

export default ProductCompareTableSelectable;
