import { PrimaryButton } from "@components/common/button";
import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { useAttributes } from "@lib/hooks/use-attributes";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { Product } from "@lib/model/product";
import { isInternalUser as checkIsInternalUser } from "@lib/utilities/global-utilities";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import {
  allComparePrompts,
  openaiColumnNames,
  priceClassificationThresholds,
  priceCompareThresholds,
} from "@lib/utilities/shared-llm-utilities";
import {
  centsToDollars,
  getProductLabels,
} from "@lib/utilities/shared-utilities";

const transformProduct = (
  product: Product,
  attributes: AttributeConfigurationMap
) => {
  const transformedProduct = {};

  transformedProduct["FullName"] = getProductLabels(product).shortLabel;
  const columnsToAvoid = ["FullName", "Price Comment", "Price Comparison"];

  openaiColumnNames[product.metadata.categoryName].forEach((column) => {
    const attribute = column.sheetName
      ? Object.values(attributes).find(
          (attribute) => attribute.name === column.sheetName
        )
      : Object.values(attributes).find(
          (attribute) => attribute.name === column.name
        );
    const attributeValue = product.attributes[attribute?.slug]?.value;

    if (!columnsToAvoid.includes(column.name)) {
      if (column.sheetName) {
        if (column.sheetName.includes("Rescaled")) {
          // transformedProduct[column.name] = getQualitativeWord(
          //   parseFloat(attributeValue)
          // );
          transformedProduct[column.name] = attributeValue;
        } else {
          transformedProduct[column.name] = attributeValue;
        }
      } else {
        if (column.name.includes("Rescaled")) {
          // transformedProduct[column.name] = getQualitativeWord(
          //   parseFloat(attributeValue)
          // );
          transformedProduct[column.name] = attributeValue;
        } else {
          transformedProduct[column.name] = attributeValue;
        }
      }
    }
  });

  return transformedProduct;
};

const getCsv = (products: Product[], attributes: AttributeConfigurationMap) => {
  const transformedProducts = products.map((product) =>
    transformProduct(product, attributes)
  );

  //Get the priceComment for all transformed products
  transformedProducts.forEach((product, index) => {
    product["priceComment"] = getPriceComment(
      products[index],
      products[index].metadata.categoryName
    );
  });
  // const priceComparisons = getPriceComparison(
  //   products,
  //   products[0].metadata.categoryName,
  //   "comparison"
  // );
  // transformedProducts[0]["priceComparison"] = priceComparisons[0];
  // transformedProducts[1]["priceComparison"] = priceComparisons[1];

  transformedProducts.map((transformProduct, index) => {
    transformProduct["price"] = centsToDollars(products[index].bestPrice);
  });

  const csvHeader = Object.keys(transformedProducts[0])
    .filter((key) => transformedProducts[0][key] && transformedProducts[1][key])
    .map((key) => `"${key.replace(/"/g, '""')}"`)
    .join(",");
  const csvRows = transformedProducts.map((product) =>
    Object.values(product)
      .filter((value) => value)
      .map((value) => `"${String(value).replace(/"/g, '""')}"`)
      .join(",")
  );
  const csv = [csvHeader, ...csvRows].join("\n");

  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${products[0].slug}--vs--${products[1].slug}.csv`;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const getJson = (products, attributes) => {
  const transformedProducts = products.map((product) =>
    transformProduct(product, attributes)
  );

  transformedProducts.forEach((product, index) => {
    product["priceComment"] = getPriceComment(
      products[index],
      products[index].metadata.categoryName
    );
  });

  const json = JSON.stringify(transformedProducts, null, 2);

  const blob = new Blob([json], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `products.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

const VsTray = ({
  isInternalUser,
  inputProducts,
}: {
  isInternalUser: boolean;
  inputProducts: Product[];
}) => {
  const { rawProducts: allRawProducts } = useGlobalAllProducts();
  const { attributes } = useAttributes();

  if (!allRawProducts) {
    return null;
  }

  // const products = inputProducts.map((product) =>
  //   allRawProducts.find((rawProduct) => rawProduct.id === product.id)
  // );

  const products = allRawProducts;

  return (
    <>
      {(isInternalUser || checkIsInternalUser()) && (
        <div
          className={`fixed bottom-0 left-[50%] -translate-x-[50%] lg:top-0 lg:bottom-auto z-50 p-0 bg-transparent`}
        >
          <div
            className={`flex gap-2 justify-center items-center lg:rounded-b-xl lg:rounded-t-none rounded-t-xl p-2 z-50 text-sm bg-red-200`}
          >
            <Popover>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={`text-black min-w-[150px] w-full group inline-flex items-center focus:outline-none rounded-md ${
                      open ? "pb-3" : "bg-white"
                    } px-2 py-1 text-sm justify-between`}
                    data-testid="popover-button"
                  >
                    Tools
                    <ChevronDownIcon
                      className={`${
                        open
                          ? "text-blue-500 sm:rotate-180 sm:transform"
                          : "text-blue-500/70 rotate-180 transform sm:rotate-0"
                      }
                          ml-2 h-5 w-5 transition duration-150 ease-in-out `}
                    />
                  </Popover.Button>
                  <Popover.Panel className="relative grid grid-cols-1 gap-3 text-sm">
                    <PrimaryButton
                      className="py-3 bg-red-300 w-auto"
                      onClick={() => {
                        getCsv(products, attributes);
                      }}
                    >
                      Download LLM&nbsp;State
                    </PrimaryButton>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
        </div>
      )}
    </>
  );
};

function getPriceComment(product: Product, category: string) {
  const price = product.bestPrice;
  const classification = priceClassificationThresholds[category];
  if (price <= classification.budget) {
    return "budget price";
  } else if (classification.lowermid && price <= classification.lowermid) {
    return "lower midrange price";
  } else if (classification.mid && price <= classification.mid) {
    return "midrange price";
  } else if (classification.uppermid && price <= classification.uppermid) {
    return "upper midrange price";
  } else if (classification.premium && price <= classification.premium) {
    return "premium price";
  } else {
    return "high end price";
  }
}

function getPriceComparison(
  products: Product[],
  category: string,
  promptType: string
) {
  if (!allComparePrompts.includes(promptType)) {
    return ["", ""];
  }
  const product1 = products[0];
  const product2 = products[1];

  const priceRatio = product1.bestPrice / product2.bestPrice;
  const thresholds = priceCompareThresholds[category];
  if (
    priceRatio >= 1 - thresholds.similar &&
    priceRatio <= 1 + thresholds.similar
  ) {
    return ["similarly priced", "similarly priced"];
  } else if (
    priceRatio >= 1 - thresholds.slight &&
    priceRatio <= 1 + thresholds.slight
  ) {
    if (priceRatio >= 1) {
      return ["often slightly more expensive", "often slightly less expensive"];
    }
    return ["often slightly less expensive", "often slightly more expensive"];
  } else if (
    priceRatio >= 1 - thresholds.somewhat &&
    priceRatio <= 1 + thresholds.somewhat
  ) {
    if (priceRatio >= 1) {
      return ["often somewhat more expensive", "often somewhat less expensive"];
    }
    return ["often somewhat less expensive", "often somewhat more expensive"];
  } else if (
    priceRatio >= 1 - thresholds.significant &&
    priceRatio <= 1 + thresholds.significant
  ) {
    if (priceRatio >= 1) {
      return [
        "often significantly more expensive",
        "often significantly less expensive",
      ];
    }
    return [
      "often significantly less expensive",
      "often significantly more expensive",
    ];
  } else {
    if (priceRatio >= 1) {
      return ["typically much more expensive", "typically much less expensive"];
    }
    return ["typically much less expensive", "typically much more expensive"];
  }
}

export default VsTray;
