import { useState } from "react";

const useModal = (initialMode = false) => {
  const [modalOpen, setModalOpen] = useState(initialMode);
  const toggle = () => setModalOpen(!modalOpen);

  // @TODO change this to be an object so that callers don't have to deal with
  // an empty second item.
  return [modalOpen, setModalOpen, toggle] as const;
};

export default useModal;
