import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import { useExplanation } from "@lib/hooks/use-explanation";
import { laptopContentPhraseMap } from "./laptop-usage-meter";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useAttributes } from "@lib/hooks/use-attributes";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import { useResponseAnswers } from "@lib/hooks/use-multi-response";

interface Props extends PanelSetProps {
  usagesChosen: string[];
}

const performanceAttributeMap = {
  generalUseScore: "generalUseBenchmarkScore",
  gamingScore: "gamingBenchmarkScore",
  creativeUseScore: "creativeUseBenchmarkScore",
  softwareScore: "softwareBenchmarkScore",
  contentScore: "contentBenchmarkScore",
};

const LaptopPerformanceMeter = (props: Props) => {
  const { selectedProduct, usagesChosen } = props;

  const { attributes } = useAttributes();

  const { explanation } = useExplanation(
    "performanceExplanation",
    selectedProduct
  );

  const { answers } = useResponseAnswers(
    "78c0a42b-31e3-49a2-b2ab-904603fc37f9"
  );

  const answer = parseFloat(answers[0]?.mainText);

  const performanceScores: Record<string, number> = {};

  if (usagesChosen.length > 0) {
    usagesChosen.map((attribute) => {
      performanceScores[attribute] = parseFloat(
        selectedProduct.attributes[performanceAttributeMap[attribute]].value
      );
    });
  } else {
    Object.keys(laptopContentPhraseMap).map((attribute) => {
      performanceScores[attribute] = parseFloat(
        selectedProduct.attributes[performanceAttributeMap[attribute]].value
      );
    });
  }

  const mean =
    Object.values(performanceScores).reduce((acc, val) => acc + val, 0) /
    Object.values(performanceScores).length;

  const numberOfScores = Object.keys(performanceScores).length;

  const title =
    numberOfScores !== 1
      ? `Performance`
      : `${
          laptopContentPhraseMap[Object.keys(performanceScores)[0]].titlePhrase
        } Performance`;
  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(mean)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      {title.toLowerCase().replace("ai", "AI")}.
    </>
  );
  const second =
    "Better performance provides a smoother experience and allows you to get work done faster. PerfectRec’s performance scores take into account the laptop’s hardware as well as your intended use.";

  const color = getMeetsNeedsColor(!explanation?.isUnmet);

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: "Performance",
        text: "PerfectRec’s performance scores are determined differently for different use cases. The overall performance score is determined by taking the average of the performance scores for the use cases you selected. If you skipped the use case question, the overall performance is based on the average of the specific use case performance scores.",
      }}
    >
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 mb-2">
        <ArcScoreDisplay
          product={props.selectedProduct}
          attribute={attributes["overallBenchmarkScore"]}
          icon={getAspectIcon("laptops", "overallPerformanceScore")}
          score={mean}
          color={color}
        />
        {numberOfScores > 1 && (
          <div className="flex flex-col justify-center ml-6 lg:ml-0">
            {Object.keys(performanceScores).map((attribute, index) => (
              <div key={index} className="flex flex-row gap-8 items-center">
                <div className="w-4">
                  <ArcScoreDisplay
                    product={props.selectedProduct}
                    attribute={attributes[performanceAttributeMap[attribute]]}
                    score={performanceScores[attribute]}
                    color={getMeetsNeedsColor(
                      !(
                        (performanceScores[attribute] < 7 && answer === 3) ||
                        (performanceScores[attribute] < 8 && answer === 4)
                      )
                    )}
                    type="scoreInside"
                    index={index}
                  />
                </div>
                <div className="text-sm">
                  {capitalizeFirstLetter(
                    attributes[performanceAttributeMap[attribute]].displayName
                      .toLowerCase()
                      .replace("ai", "AI")
                      .replace("performance score", "")
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopPerformanceMeter;
