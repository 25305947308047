import DonePanel from "@components/recommendation/done-panel";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { ScoreDisplay, SummaryTitles } from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import { CompareCardScoreDisplay } from "@components/compare/compare-components";
import { useAttributes, useAttribute } from "@lib/hooks/use-attributes";

/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const LaptopPortabilityComparison = (props: {
  productA: Product;
  productB: Product;
  keySpecs?: boolean;
  compareResults: any;
}) => {
  const { productA, productB } = props;

  const { attributes: allAttributes } = useAttributes();

  if (!productA || !productB) {
    return null;
  }

  const second = `The most portable laptops are small, thin, and light.`;

  const { winner, pctA, pctB, first } = props.compareResults["mobilityScore"];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  const dims = ["screenSize", "weight", "thickness"];
  const dimName = ["SIZE", "WEIGHT", "THICKNESS"];

  const attributeConfiguration = allAttributes["mobilityScore"];

  return (
    <DonePanel
      title="Portability"
      className={"!bg-white"}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={attributeConfiguration}
        categoryName={productA.metadata.categoryName}
        showAdjective={true}
      />
      <div className="flex flex-col w-full mt-2 mb-6 text-gray-700">
        {dims.map((dim, index) => {
          return (
            <div
              key={index}
              className={`flex justify-between items-end border-b-[1px] ${
                index === 0 && "border-t-[1px]"
              } border-gray-300 py-3`}
            >
              <ScoreDisplay
                attribute={allAttributes[dim]}
                product={productA}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
              <div className="tracking-wider font-bold text-xs">
                {dimName[index]}
              </div>
              <ScoreDisplay
                attribute={allAttributes[dim]}
                product={productB}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
            </div>
          );
        })}
      </div>
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopPortabilityComparison;
