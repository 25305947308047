import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext } from "react";

export const staticCategoryStats = {
  smartphones: {
    products: 68,
    comparisons: 2278,
    retailers: 13,
    links: 715,
  },
  tvs: {
    products: 385,
    comparisons: 5567,
    retailers: 29,
    links: 850,
  },
  laptops: {
    products: 380,
    comparisons: 27261,
    retailers: 33,
    links: 717,
  },
  headphones: {
    products: 108,
    comparisons: 6670,
    retailers: 28,
    links: 236,
  },
};

export const useCategoryStatsCta = (ctaType: string) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  let ctaText;
  let href;
  let linkText;

  const categoryStats = staticCategoryStats[productCategoryConfig.name];

  if (!ctaType || !categoryStats) {
    return { ctaText, href, linkText, validType: false };
  }

  switch (ctaType) {
    case "exhaustiveProducts": {
      ctaText = `We exhaustively researched ${categoryStats.products} current ${productCategoryConfig.pluralNoun}.`;
      href = `/electronics/products/${productCategoryConfig.slug}`;
      linkText = "Take a look";
      break;
    }
    case "comparisons": {
      ctaText = `We have ${categoryStats.comparisons} more ${productCategoryConfig.pluralNoun} comparisons to explore.`;
      href = `/electronics/compare/${productCategoryConfig.slug}`;
      linkText = "Browse";
      break;
    }
    case "detailedStats": {
      ctaText = `Detailed stats and scores for ${categoryStats.products} current ${productCategoryConfig.pluralNoun}.`;
      href = `/electronics/products/${productCategoryConfig.slug}`;
      linkText = "Browse";
      break;
    }
    case "shoppingLinks": {
      ctaText = `Browse ${categoryStats.links} no-commission continuously updated shopping links.`;
      href = `/electronics/products/${productCategoryConfig.slug}`;
      linkText = "Browse";
      break;
    }
    case "retailers": {
      ctaText = `Continuously updated prices across ${categoryStats.retailers} reputable retailers - no commissions.`;
      href = `/electronics/products/${productCategoryConfig.slug}`;
      linkText = "Browse";
      break;
    }
  }

  return {
    ctaText,
    href,
    linkText,
    validType: true,
  };
};
