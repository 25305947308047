import { ChevronDown } from "@components/product/summary/common";
import { useCategoryStatsCta } from "@lib/hooks/use-category-stats-cta";
import eventTracker, { Events } from "@lib/tracking/event-tracker";

/**
 * A large heading commonly used on the homepage.
 */
export const SectionHeading = ({
  className = "",
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h2 className={`text-2xl md:text-4xl leading-snug ${className}`}>
      {children}
    </h2>
  );
};

/**
 * A heading used on product pages like VS and product landing pages, that works
 * with a table of contents.
 */
export const ProductHeading = ({
  children,
  className = "",
  id,
  tocName,
  scrollMarginClass = "xl:scroll-mt-[64px]",
  ctaType,
}: {
  children: React.ReactNode;
  className?: string;
  id?: string;
  tocName?: string;
  scrollMarginClass?: string;
  ctaType?: string;
}) => {
  const { ctaText, href, linkText, validType } = useCategoryStatsCta(ctaType);

  return (
    <>
      <h2
        id={id}
        className={`text-center text-2xl mt-8 ${scrollMarginClass} ${className}`}
        data-tocname={tocName}
      >
        {children}
      </h2>
      {validType && ctaText && href && linkText && (
        <span className="flex flex-row w-full justify-center text-xs flex-wrap">
          {ctaText}
          <a
            href={href}
            className="text-blue-600 ml-1 hover:underline cursor-pointer flex-shrink-0"
            onClick={() => {
              eventTracker.track(Events.InvitationToExploreCta, {
                ctaText: ctaText,
                target: href,
              });
            }}
          >
            {linkText}
          </a>
        </span>
      )}
      <ChevronDown />
    </>
  );
};
