import PurchaseOption from "@lib/model/purchase-option";
import {
  centsToDollars,
  getCategoryVariantsPhrase,
  getProductLabels,
  getProductLandingPageUrl,
  timeSince,
} from "@lib/utilities/shared-utilities";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import { Product } from "@lib/model/product";
import React, { TextareaHTMLAttributes, useContext, useMemo } from "react";
import {
  useAllPurchaseData,
  usePurchaseData,
} from "@lib/hooks/use-purchase-data";
import {
  ArrowSmallRightIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import VariantDropdown from "@components/product/VariantDropdown";
import { ProductVariant } from "../../../data/packages/model/src/product";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { pickBestPurchaseVariantId } from "@lib/utilities/pick-best-purchase-option";
import useModal from "@lib/hooks/use-modal";
import ProductFeatureModal from "./product-feature-modal";
import Modal from "@components/modal/modal";
import { PrimaryButton, TextButton } from "@components/common/button";
import { ProductCategoryContext } from "contexts/product-category-context";

type PurchaseDataProps = {
  product: Product;
  position?: number;
  rowClassName?: string;
  className?: string;
  showLearnMoreModal?: boolean;
};

const variantModalContent = {
  laptops:
    "Most laptops are available in different configurations, including different CPU and GPU options and different amount of RAM and storage.\n\nWe present a curated list of the most popular variants. The linked e-commerce sites may have additional configurations available, or may allow full customization where you can pick laptop components individually.",
  smartphones:
    "The amount of storage needed will depend on your individual needs. The base storage configuration will likely be enough for you, as new phones come with a decent amount of storage. If you tend to save a lot of photos and videos on your phone, consider paying for more storage, especially if you don't use cloud photo services such as Google Photos and Apple Photos. ",
};

/**
 * Gets display data about a purchase option, such as color and its phrase.
 */
const getPurchaseOptionDisplayData = (purchaseOption: PurchaseOption) => {
  let colorClasses = "bg-yellow-600/[0.15] text-yellow-600";
  let phrase = "Refurbished";

  if (!purchaseOption?.inStock) {
    colorClasses = "bg-gray-200 text-gray-800";
    phrase = "Out of stock";
  } else if (purchaseOption?.condition === "new") {
    colorClasses = "text-blue-600 bg-blue-600/[0.15]";
    phrase = "New";
  }

  return { colorClasses, phrase };
};

/**
 * Gets the latest created date amonst the full set of purchase options for this
 * product.
 */
export const getPriceLastUpdated = (purchaseOptions: PurchaseOption[]) => {
  const getCreatedTime = (purchaseOption) => {
    return new Date(purchaseOption.metadata.createdAt).getTime();
  };

  const latestTime = purchaseOptions.reduce(
    (prevLatestTime, purchaseOption) => {
      const currentCreatedTime = getCreatedTime(purchaseOption);
      if (!prevLatestTime) {
        return currentCreatedTime;
      }

      return currentCreatedTime > prevLatestTime
        ? currentCreatedTime
        : prevLatestTime;
    },
    0
  );
  const currentTime = new Date().getTime();

  if (currentTime - latestTime > 86400000) {
    return timeSince(currentTime - 86400000);
  }

  return timeSince(latestTime);
};

/**
 * Gets an object map of storename to price.
 */
export const getTrackingPayload = (
  purchaseData: PurchaseOption[],
  product: Product,
  index?: number
) => {
  const payload = {} as any;

  if (index || index === 0) {
    purchaseData.forEach((purchaseOption) => {
      payload[index + 1 + " " + purchaseOption.storeName + " " + "price"] =
        purchaseOption.price;
    });

    payload[index + 1 + " " + "product"] = getProductLabels(product).shortLabel;
    payload[index + 1 + " " + "category"] = product.metadata.categoryName;
  } else {
    purchaseData.forEach((purchaseOption) => {
      payload[purchaseOption.storeName + " " + "price"] = purchaseOption.price;
    });

    payload.product = getProductLabels(product).shortLabel;
    payload.category = product.metadata.categoryName;
  }

  return payload;
};

/**
 * The new/refurbished/out-of-stock badge.
 */
export const Disposition = (props: {
  purchaseOption: PurchaseOption;
  className?: string;
}) => {
  const { colorClasses, phrase } = getPurchaseOptionDisplayData(
    props.purchaseOption
  );

  return (
    <span
      className={`rounded-md self-center text-[10px] p-1 font-semibold ${props.className} ${colorClasses}`}
    >
      {phrase}
    </span>
  );
};

const getCarrierPrice = (fullPrice: number, store: string) => {
  switch (store) {
    case "Verizon":
      return `${centsToDollars(fullPrice / 36)}/mo x 36`;
    case "T-Mobile":
      return `${centsToDollars(fullPrice / 36)}/mo x 36`;
    case "AT&T":
      return `${centsToDollars(fullPrice / 24)}/mo x 24`;
  }
};

const isCarrier = (store: string) => {
  return store === "Verizon" || store === "AT&T" || store === "T-Mobile";
};

const Price = (props: { purchaseOption: PurchaseOption }) => {
  return (
    <>
      <span data-testid={`full-price-span`}>
        {centsToDollars(props.purchaseOption.price)}
      </span>
      {props.purchaseOption.metadata.categoryName === "smartphones" &&
        isCarrier(props.purchaseOption.storeName) && (
          <p className="text-xs leading-none">
            {getCarrierPrice(
              props.purchaseOption.price,
              props.purchaseOption.storeName
            )}
          </p>
        )}
    </>
  );
};

/**
 * Gets a set of purchase items for a product, as follows:
 *
 * - Products have the same value for arg attribute as a reference product ID
 *   (i.e. the product/variant ID derived from the user's selection).
 * - The set contains one least expensive purchase option per store, per condition.
 *
 * This derivation is useful for categories like phones that use price groups,
 * and we want to use one of the price group dimensions (e.g. storage) as a
 * selector.
 */
export const bestPurchaseOptionsByAttribute = (
  activeProductId: string,
  attribute: string,
  purchaseData: PurchaseOption[],
  products: Product[]
) => {
  const activeProduct = products?.find(
    (indexProduct) => indexProduct.id === activeProductId
  );

  // Build a table of Store > Condition > Best price, for products that match on
  // the specified attribute.
  const priceTable = {};
  purchaseData?.forEach((purchaseDatum) => {
    const purchaseDataProduct = products?.find(
      (indexProduct) => indexProduct.id === purchaseDatum.productId
    );

    if (
      purchaseDataProduct.attributes[attribute].value !==
      activeProduct.attributes[attribute].value
    ) {
      return;
    }

    const { storeName, condition, price } = purchaseDatum;

    if (!priceTable[storeName]) {
      priceTable[storeName] = {};
    }

    if (
      !priceTable[storeName][condition]?.price ||
      price < priceTable[storeName][condition].price
    ) {
      priceTable[storeName][condition] = {
        price,
        purchaseDatum,
      };
    }
  });

  // Derive a flat list of purchase options from the table.
  const purchaseDataFiltered = [] as PurchaseOption[];
  Object.entries(priceTable).forEach(([storeName, storeConditions]) => {
    Object.entries(storeConditions).forEach(
      ([condition, { purchaseDatum }]) => {
        purchaseDataFiltered.push(purchaseDatum);
      }
    );
  });

  return purchaseDataFiltered;
};

/**
 * Given a product, get a list of selectable variants to provide to the buy
 * dropdown.
 */
const getSelectableVariants = (
  product: Product,
  purchaseData: PurchaseOption[]
) => {
  const productAttributes = {};
  Object.keys(product?.attributes).forEach(
    (dim) => (productAttributes[dim] = product.attributes[dim].value)
  );
  const productAsVariant: ProductVariant = {
    variantId: product.id,
    minPrice: centsToDollars(product.bestPrice),
    attributes: productAttributes,
  };

  return [
    productAsVariant,
    ...product.variants.filter(
      (variant) =>
        variant.attributes?.color === productAsVariant.attributes?.color &&
        purchaseData.find((data) => data.productId === variant.variantId)
    ),
  ];
};

/**
 * Gets a table of lowest price per selectable variant.
 */
const getSelectableVariantPriceTable = (
  selectableVariants: ProductVariant[],
  attribute,
  priceGroupPurchaseData,
  allProducts
) => {
  const prices = {} as { [variantId: string]: number };
  for (const variant of selectableVariants) {
    // Get the purchase options for a particular product/variant
    const purchaseData = bestPurchaseOptionsByAttribute(
      variant.variantId,
      attribute,
      priceGroupPurchaseData,
      allProducts
    ).filter((datum) => datum.inStock);

    for (const purchaseDatum of purchaseData) {
      if (
        !prices[variant.variantId] ||
        purchaseDatum.price < prices[variant.variantId]
      )
        prices[variant.variantId] = purchaseDatum.price;
    }
  }
  return prices;
};

const PurchaseData = (props: PurchaseDataProps) => {
  const { product, position } = props;
  const { allPurchaseData } = useAllPurchaseData();
  const { rawProducts } = useGlobalAllProducts();
  const [reportedProducts, setReportedProducts] = useState({});

  const { productCategoryConfig } = useContext(ProductCategoryContext);

  // To determine all selectable variants in the case of laptops, we need more
  // than just one product's purchase data.
  const [selectableVariants, selectableVariantPurchaseData] = useMemo(() => {
    if (allPurchaseData && product) {
      const variants = getSelectableVariants(product, allPurchaseData);
      const variantPurchaseData = allPurchaseData.filter((data) =>
        variants.find((variant) => variant.variantId === data.productId)
      );
      return [variants, variantPurchaseData];
    }

    return [null, null];
  }, [product, allPurchaseData]);

  const [activeVariant, setActiveVariant] = useState<string>(
    pickBestPurchaseVariantId({
      variants: selectableVariants,
      variantPurchaseData: selectableVariantPurchaseData,
      product,
    })
  );

  const selectedProduct = rawProducts
    ? rawProducts.find((product) => activeVariant === product.id)
    : product;
  const { purchaseData } = usePurchaseData(selectedProduct);

  const displayPurchaseData = useMemo(() => {
    if (activeVariant && product && rawProducts && purchaseData) {
      return product.metadata.categoryName === "smartphones"
        ? bestPurchaseOptionsByAttribute(
            activeVariant,
            "storage",
            purchaseData,
            rawProducts
          )
        : purchaseData.filter((datum) => datum.productId === activeVariant);
    }
  }, [activeVariant, rawProducts, purchaseData, product]);

  /**
   *
   * @param productId
   */
  const reportProduct = (productId: string) => {
    setReportedProducts((prevReportedProducts) => {
      return {
        ...prevReportedProducts,
        [productId]: true,
      };
    });
  };

  /**
   * Determine whether a product has been reported.
   */
  const productIsReported = (productId: string) => {
    return reportedProducts[productId];
  };

  const trackRetailerVisit = (purchase: PurchaseOption) => {
    const retailer = purchase.storeName;
    const retailerPosition =
      purchaseData.findIndex(
        (purchaseOption) => purchaseOption.link === purchase.link
      ) + 1;
    EventTracker.track(Events.RetailerVisit, {
      rec_rank: position ? position + 1 : null,
      productName: `${product.manufacturer} ${product.model}`,
      retailer: retailer,
      retailer_position: retailerPosition,
    });
  };

  const openFeatureModal = (e) => {
    e.stopPropagation();
    setModalOpen(true);
    trackModalOpen();
  };

  const trackModalOpen = () => {
    EventTracker.track(Events.ModalOpen, {
      explanationType: `Done Component - Variant Explanation`,
    });
  };

  const [modalOpen, setModalOpen, toggleModal] = useModal();

  const shouldShowVariantsDropdown =
    product?.variants.length > 0 &&
    purchaseData &&
    activeVariant &&
    selectableVariants &&
    rawProducts;

  return (
    <div>
      {purchaseData && (
        <div data-testid="purchase-data-container">
          {props.showLearnMoreModal && (
            <>
              <div className="text-sm text-gray-500 mb-2">
                We don&apos;t receive any commission or payment when you buy
                through these links.
              </div>
              <LearnMoreModal />
            </>
          )}
          <div className={`flex flex-col gap-2 ${props.className || ""}`}>
            {shouldShowVariantsDropdown &&
              product.metadata.categoryName === "smartphones" && (
                <div className="border p-3 rounded-lg w-full flex gap-6 items-center">
                  <div className="basis-1/2">
                    <div className="flex items-center mb-2">
                      <p className="text-xs font-bold text-black m-0">
                        STORAGE:
                      </p>
                      <QuestionMarkCircleIcon
                        className="ml-2 fill-gray-400 w-5 h-5 cursor-pointer"
                        onClick={(e) => {
                          openFeatureModal(e);
                        }}
                      />
                    </div>
                    <p className="text-xs leading-none">
                      How much space do you need?
                    </p>
                  </div>
                  <VariantDropdown
                    product={product}
                    activeVariantId={activeVariant}
                    setActiveVariantId={setActiveVariant}
                    purchaseData={purchaseData}
                    category={product.metadata.categoryName}
                    variants={selectableVariants}
                    bestPrices={getSelectableVariantPriceTable(
                      selectableVariants,
                      "storage",
                      purchaseData,
                      rawProducts
                    )}
                  />
                </div>
              )}

            {shouldShowVariantsDropdown &&
              ["laptops", "tvs"].includes(product.metadata.categoryName) && (
                <div className="border p-3 rounded-lg w-full items-center">
                  <div className="flex items-center mb-2">
                    <p className="text-xs font-bold text-black m-0">
                      {getCategoryVariantsPhrase(productCategoryConfig)}:
                    </p>
                    <QuestionMarkCircleIcon
                      className="ml-2 fill-gray-400 w-5 h-5 cursor-pointer"
                      onClick={(e) => {
                        openFeatureModal(e);
                      }}
                    />
                  </div>
                  <VariantDropdown
                    product={product}
                    activeVariantId={activeVariant}
                    setActiveVariantId={setActiveVariant}
                    purchaseData={allPurchaseData}
                    category={product.metadata.categoryName}
                    variants={selectableVariants}
                  />
                </div>
              )}
            <ProductFeatureModal
              open={modalOpen}
              onClose={toggleModal}
              name={`${getCategoryVariantsPhrase(
                productCategoryConfig
              )} Explanation`}
              text={variantModalContent[product.metadata.categoryName]}
            />
            <>
              {displayPurchaseData?.map((purchase, index) => (
                <a
                  href={purchase.link}
                  onClick={(e) => {
                    e.stopPropagation();
                    trackRetailerVisit(purchase);
                  }}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-between"
                  key={index}
                  data-testid={`purchase-link-${index}`}
                >
                  <div
                    className={`border-blue-600 hover:bg-blue-50 border p-2 rounded-lg w-full flex gap-2 items-center ${
                      props.rowClassName || ""
                    }`}
                  >
                    <div className="text-blue-600 text-base leading-tight">
                      <Price purchaseOption={purchase} />
                    </div>
                    <div className="">
                      <Disposition purchaseOption={purchase} />
                    </div>
                    <div className="flex-grow text-right text-blue-600">
                      Shop {purchase.storeName}
                    </div>
                    <ArrowSmallRightIcon className="h-4 w-4" />
                  </div>
                </a>
              ))}
            </>
          </div>
          <div className="text-xs flex my-2 justify-center">
            <div className="flex-shrink-0">
              Pricing last updated {getPriceLastUpdated(purchaseData)} ago |
            </div>
            {!productIsReported(product.id) ? (
              <ReportProductModal
                product={product}
                purchaseData={purchaseData}
                reportProduct={reportProduct}
              />
            ) : (
              <div className="ml-1">Thank you - incorrect pricing reported</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const LearnMoreModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TextButton className="mb-1 text-xs" onClick={() => setOpen(true)}>
        Learn more
      </TextButton>
      <Modal modalOpen={open} onClose={() => setOpen(false)}>
        Other recommendation sites make money when you buy through their links.
        This gives them an incentive to recommend more expensive products that
        might not be worth it for you. It also creates an incentive to encourage
        you to buy now, when you might be better off waiting for sales or new
        product releases. PerfectRec does not take any commissions so that we
        can stay 100% independent and give you truly unbiased advice.
      </Modal>
    </>
  );
};

const ReportProductModal = ({
  purchaseData,
  product,
  reportProduct,
}: {
  purchaseData?: PurchaseOption[];
  product?: Product;
  reportProduct?: (productId: string) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { productCategoryConfig: productCategory } = useContext(
    ProductCategoryContext
  );

  return (
    <>
      <TextButton
        className="ml-1"
        onClick={() => {
          setOpen(true);
        }}
      >
        Report incorrect pricing
      </TextButton>
      <Modal
        modalOpen={open}
        onClose={() => setOpen(false)}
        omitBottomButtons={true}
        title="What's wrong with the price?"
      >
        <textarea
          id="priceIssueDescription"
          placeholder="Describe the issue here"
          className="w-full min-h-[100px]"
        />
        <PrimaryButton
          className="mt-4 w-full"
          variant="solid"
          onClick={async () => {
            setOpen(false);
            const priceIssueDescription = (
              document.getElementById(
                "priceIssueDescription"
              ) as HTMLTextAreaElement
            )?.value;
            EventTracker.track(Events.ReportedIncorrectPricing, {
              ...getTrackingPayload(purchaseData, product),
              priceIssueDescription,
            });
            reportProduct(product.id);
            try {
              await fetch("/api/reportPrice", {
                body: JSON.stringify({
                  payload: {
                    ...getTrackingPayload(purchaseData, product),
                    priceIssueDescription,
                    productUrl: `https://www.perfectrec.com${getProductLandingPageUrl(
                      product,
                      productCategory
                    )}`,
                  },
                }),
                method: "POST",
              });
            } catch (error) {
              console.error(error);
            }
          }}
        >
          Submit
        </PrimaryButton>
      </Modal>
    </>
  );
};

export default PurchaseData;
