import DonePanel from "@components/recommendation/done-panel";
import { ScoreDisplay, SummaryTitles } from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import { CompareCardScoreDisplay } from "@components/compare/compare-components";

const attributeToPromptTypeMap = {
  movieScore: "compareMoviesTV",
  sportsScore: "compareSportsTV",
  gamingScore: "compareGamingTV",
  newsScore: "compareNewsTV",
  animationScore: "compareAnimationTV",
  brightRoomScore: "compareBrightRoomTV",
  screenScore: "comparePictureQualityTV",
};

const dims = {
  screenScore: ["contrast", "colorVolumeScore", "panelType", "panelSubtype"],
  gamingScore: [
    "responseTimeScore",
    "inputLagScore",
    "motionProcessing",
    "gamingLocalDimming",
    "gamingHdrBrightnessScore",
  ],
  movieScore: [
    "contrast",
    "blackUniformity",
    "upscaling",
    "hdr10Support",
    "hdr10PlusSupport",
    "dolbyVisionSupport",
  ],
  sportsScore: [
    "motionProcessing",
    "refreshRate",
    "inputLagScore",
    "upscaling",
    "sdrBrightnessScore",
    "hlgSupport",
  ],
  newsScore: ["sdrBrightnessScore", "upscaling"],
  brightRoomScore: [
    "viewingAngle",
    "sdrBrightnessScore",
    "hdrBrightnessScore",
    "reflectionsScore",
  ],
  animationScore: [
    "colorGamutScore",
    "colorVolumeScore",
    "sdrBrightnessScore",
    "colorsOutOfTheBoxScore",
    "grayUniformity",
  ],
};

function TvScreenComparison(props: {
  productA: Product;
  productB: Product;
  attributeName: string;
  keySpecs?: boolean;
  compareResults: any;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) {
  const { productA, productB, attributeName, compareResults, comparisons } =
    props;

  const { attributes } = useAttributes();

  const attributeConfiguration = useAttribute(attributeName);

  if (!productA || !productB) {
    return null;
  }

  const { pctA, pctB, winner, first } = compareResults[attributeName];

  const llmComparison =
    comparisons && comparisons[attributeToPromptTypeMap[attributeName]]?.result;

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={attributeConfiguration.displayName.replace("Score", "")}
      className={"!bg-white"}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={attributeConfiguration}
        categoryName={productA.metadata.categoryName}
      />
      <div className="flex flex-col w-full mt-2 mb-6 text-gray-700">
        {dims[attributeName]?.map((dim, index) => {
          return (
            <div
              key={index}
              className={`flex justify-between items-end border-b-[1px] ${
                index === 0 && "border-t-[1px]"
              } border-gray-300 py-3`}
            >
              <ScoreDisplay
                attribute={attributes[dim]}
                product={productA}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
              <div className="tracking-wider font-bold text-xs">
                {attributes[dim].displayName.toUpperCase()}
              </div>
              <ScoreDisplay
                attribute={attributes[dim]}
                product={productB}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
            </div>
          );
        })}
      </div>
      <SummaryTitles
        {...{
          first,
          llm: llmComparison,
        }}
      />
    </DonePanel>
  );
}
export default TvScreenComparison;
