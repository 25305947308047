import {
  AdjustmentsVerticalIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  Battery100Icon,
  BoltIcon,
  CameraIcon,
  ChatBubbleBottomCenterIcon,
  ClockIcon,
  Cog8ToothIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentTextIcon,
  ForwardIcon,
  MoonIcon,
  PaintBrushIcon,
  PhotoIcon,
  SpeakerXMarkIcon,
  SunIcon,
  SwatchIcon,
  TvIcon,
} from "@heroicons/react/24/solid";
import { ContentIcon } from "@components/svgs/content-icon";
import EarIcon from "@components/svgs/ear-icon";
import { getExplanations } from "@lib/utilities/product-classifier";
import { PictureQualityIcon } from "@components/svgs/picture-quality-icon";
import { BrightRoomIcon } from "@components/svgs/bright-room-icon";
import { UsageIcon } from "@components/svgs/usage-icon";
import { ScreenSizeIcon } from "@components/svgs/screen-size-icon";

export enum RecExplanationStyle {
  List = "List",
  Chips = "Chips",
  pdpChips = "pdpChips",
}

const iconMap = {
  cog8Tooth: Cog8ToothIcon,
  content: ContentIcon,
  usage: UsageIcon,
  paintBrush: PaintBrushIcon,
  speakerXMark: SpeakerXMarkIcon,
  ear: EarIcon,
  pictureQuality: PictureQualityIcon,
  brightRoom: BrightRoomIcon,
  cameraQuality: CameraIcon,
  batteryRating: Battery100Icon,
  size: ArrowsPointingInIcon,
  laptopSize: ScreenSizeIcon,
  performance: BoltIcon,
  portability: ArrowsPointingOutIcon,
  cube: CubeIcon,
  cubeTransparent: CubeTransparentIcon,
  document: DocumentTextIcon,
  photo: PhotoIcon,
  brightness: SunIcon,
  contrast: MoonIcon,
  colorVolume: SwatchIcon,
  colorGamut: AdjustmentsVerticalIcon,
  refreshRate: ForwardIcon,
  responseTime: ClockIcon,
  textQuality: ChatBubbleBottomCenterIcon,
  resolution: TvIcon,
};

const SingleRecNewExplanations = (props: {
  explanations: ReturnType<typeof getExplanations>;
  variant?: RecExplanationStyle;
  className?: string;
}) => {
  if (!props.explanations) {
    return null;
  }
  const explanations = props.explanations;
  const variant = props.variant || RecExplanationStyle.List;
  const wrapperClasses = {
    [RecExplanationStyle.List]: "flex flex-col text-left gap-1",
    [RecExplanationStyle.Chips]: "flex gap-2 justify-start flex-wrap",
    [RecExplanationStyle.pdpChips]: "flex gap-2 flex-wrap",
  };

  const itemClasses = {
    [RecExplanationStyle.List]: "flex",
    [RecExplanationStyle.Chips]:
      "flex rounded-xl border border-gray-300 p-1 px-2",
    [RecExplanationStyle.pdpChips]:
      "flex rounded-xl border border-gray-300 p-1 px-2",
  };

  return (
    <div className={`${wrapperClasses[variant]} ${props.className || ""}`}>
      {explanations.map((explanation, index) => {
        // Checks the values of the enumeration to work around TvSize being different from PhoneSize.
        if (explanation?.display === false) {
          return null;
        }
        const IconComponent = iconMap[explanation.icon];
        return (
          <div key={index} className={itemClasses[variant]}>
            <IconComponent
              className={`h-4 flex shrink-0 mr-1 ${
                explanation.isUnmet
                  ? `fill-yellow-500 ${
                      explanation.icon === "ear" ? "stroke-yellow-500" : ""
                    }`
                  : `fill-rec-dark-green ${
                      explanation.icon === "ear" ? "stroke-rec-dark-green" : ""
                    }`
              }`}
            />
            <div className="font-bold leading-3">
              <span className="font-normal font-variant-normal text-xs">
                {explanation.content}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SingleRecNewExplanations;
