import Header from "@components/frame/header";
import { Footer } from "@components/page/footer";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import ProductCategoryContextProvider, {
  ProductCategoryContext,
} from "contexts/product-category-context";
import {
  getCategoryImage,
  getCategoryScenariosUrl,
  getScenarioUrl,
} from "@lib/utilities/shared-utilities";
import usePager from "@lib/hooks/use-pager";
import React, { useContext } from "react";
import { ResponsivePagers } from "@components/common/pager";
import { capitalizeWordsExceptCommon } from "@lib/utilities/client-utilities";
import Meta from "./meta";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import { IScenario } from "@lib/model/contentful";
import { ArticleCard } from "@components/common/article-list";
import { getScenarioTitle } from "./scenario-page";

const ScenariosPage = ({
  productCategory,
  configMap,
  scenarios,
  total,
}: {
  productCategory: ProductCategoryConfig;
  configMap: ProductCategoryConfigMap;
  scenarios: IScenario[];
  total: number;
}) => {
  usePageScrollTracking({ page: "Scenarios listing" });
  const basePath = getCategoryScenariosUrl(productCategory);
  const { currentPage } = usePager(basePath);

  const descriptions = {
    smartphones: "Best phone guides",
    laptops: "Best laptop guides",
    tvs: "Find the best TV",
    headphones: "Your guide to the best earbuds and best headphones",
  };

  return (
    <>
      <Meta
        title={`PerfectRec - Best Guides for ${capitalizeWordsExceptCommon(
          productCategory.pluralNoun
        )}`}
        description={descriptions[productCategory.name]}
        url={getCategoryScenariosUrl(productCategory, currentPage)}
        image={getCategoryImage(productCategory)}
      />
      <Header configMap={configMap} />
      <ProductCategoryContextProvider productCategoryConfig={productCategory}>
        <PageContent
          currentPage={currentPage}
          scenarios={scenarios}
          total={total}
        />
      </ProductCategoryContextProvider>
      <Footer configMap={configMap} />
    </>
  );
};

const PageContent = ({
  currentPage,
  scenarios,
  total,
}: {
  currentPage: number;
  scenarios: IScenario[];
  total: number;
}) => {
  const { productCategoryConfig: category } = useContext(
    ProductCategoryContext
  );

  return (
    <div className="container my-12 max-w-screen-xl">
      <h1 className="mb-12">
        Best Guides for {capitalizeWordsExceptCommon(category.pluralNoun)}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {scenarios.map((scenario) => (
          <ArticleCard
            key={scenario.sys.id}
            title={getScenarioTitle(scenario.fields, category)}
            url={getScenarioUrl(category, scenario.fields.slug)}
            author={scenario.fields.author?.fields?.name}
          />
        ))}
      </div>
      <ResponsivePagers
        currentPage={currentPage}
        getLink={(page) => {
          return getCategoryScenariosUrl(category, page);
        }}
        totalPages={Math.ceil(total / 12)}
      />
    </div>
  );
};

export default ScenariosPage;
