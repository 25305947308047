import React from "react";
import * as d3 from "d3";

export const useD3 = (renderChartFn, dependencies = []) => {
  const ref = React.useRef();

  React.useEffect(() => {
    renderChartFn(d3.select(ref.current));
    let throttled = false;

    const resizeListener = () => {
      if (!throttled) {
        throttled = true;

        renderChartFn(d3.select(ref.current));
        setTimeout(function () {
          throttled = false;
        }, 150);
      }
    };

    addEventListener("resize", resizeListener);

    return () => removeEventListener("resize", resizeListener);
  }, dependencies);
  return ref;
};
