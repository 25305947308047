const WeightIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className={`${props.className || "fill-blue-600"}`}
  >
    <path d="M6 2c0 .5.5 1 1 1h10c.5 0 1-.5 1-1h2c0 1.7-1.3 3-3 3h-4v2.1c3.9.5 7 3.9 7 7.9v6c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-6c0-4.1 3.1-7.4 7-7.9V5H7C5.3 5 4 3.7 4 2h2zm6 9c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4c0-.7-.2-1.4-.6-2l-2.7 2.7-.1.1c-.4.3-1 .3-1.3-.1-.4-.4-.4-1 0-1.4l2.7-2.7c-.6-.4-1.3-.6-2-.6z" />
  </svg>
);

export { WeightIcon };
