import { LinkIcon } from "@heroicons/react/24/solid";
import { NotificationContext } from "contexts/notification-context-provider";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "next-share";
import { useContext } from "react";
import { PrimaryButton } from "./button";

export interface SocialMediaButtonsProps {
  url: string;
  body: string;
  emailSubject: string;
}

const SocialMediaButtons = (props: SocialMediaButtonsProps) => {
  const { setNotification } = useContext(NotificationContext);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(props.url);
    setNotification({ text: "Link Copied!", show: true });
    setTimeout(
      () => setNotification((prev) => ({ ...prev, show: false })),
      1000
    );
  };

  return (
    <div className="children:m-2">
      <button
        className="bg-blue-600 hover:bg-blue-700 text-white rounded-3xl"
        onClick={onCopyToClipboard}
      >
        <LinkIcon className="h-5 m-1.5 flex-shrink-0 cursor-pointer" />
      </button>
      <EmailShareButton
        url={props.url}
        subject={props.emailSubject}
        body={props.body}
        onClick={(_, link) => {
          window.location.href = link;
        }}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
      <FacebookShareButton url={props.url} quote={props.body}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <FacebookMessengerShareButton
        url={props.url}
        appId={process.env.NEXT_PUBLIC_FB_APP_ID}
      >
        <FacebookMessengerIcon size={32} round />
      </FacebookMessengerShareButton>
      <TwitterShareButton url={props.url} title={props.body}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <WhatsappShareButton url={props.url} title={props.body}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <RedditShareButton url={props.url} title={props.body}>
        <RedditIcon size={32} round />
      </RedditShareButton>
    </div>
  );
};

export default SocialMediaButtons;
