import { useContext, useState } from "react";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import Answer from "@lib/model/answer";
import {
  AnswerConfirmation,
  AnswerConfirmationMap,
} from "@lib/model/answer-confirmation";

const confirmationStrings = {
  SmallAndroid:
    "There are very limited, high quality small Android choices.  PerfectRec will try to find a good one for your needs, but may need to recommend larger phones to you.  Please confirm your selection.",
  IPhone400Dollar:
    "Only older, used iPhones are available under $400.  PerfectRec will try to find a good one for your needs, but may need to recommend more expensive iPhones.  Please confirm your selection.",
  IPhone600Dollar:
    "Only older or used iPhones are available under $600.  PerfectRec will try to find a good one for your needs, but may need to recommend more expensive iPhones.  Please confirm your selection.",
  BigBatterySmallPhone:
    "Small phones cannot fit large batteries with long battery life.  PerfectRec will do its best to find the right balance of small size and battery life for you.  Please confirm your selection.",
  GamingMac:
    "Many games do not run on Mac. Consider switching to a PC if gaming is a priority. Please confirm your selection.",
  GamingSmallLaptop:
    "There are limited options for high-end 13-14” gaming laptops.  Consider selecting a larger size. Please confirm your selection.",
  SmallTVQuality:
    "The best quality displays are only available in larger sizes. PerfectRec will do its best to find the best quality picture for you. Please confirm your selection.",
  QualityTV70InchUnder500:
    "Displays that are 70-79” and under $500 tend to have relatively poor screen quality. PerfectRec will try to find a good one for your needs, but may need to recommend lower quality displays to you. Please confirm your selection.",
  QualityTV80InchCheap:
    "There are very limited displays at the absolute top end that are above 80” and under $1500. PerfectRec will try to find a good balance of your needs. Please confirm your selection.",
  LowLatency50DollarEarbuds:
    "At the sub-$50 price point, few earbuds suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  LowLatency100DollarEarbuds:
    "At the sub-$100 price point, few earbuds suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  LowLatency50DollarHeadphones:
    "At the sub-$50 price point, few headphones suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  LowLatency100DollarHeadphones:
    "At the sub-$100 price point, few headphones suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  LowLatency50DollarEarbudsOrHeadphones:
    "At the sub-$50 price point, few products suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  LowLatency100DollarEarbudsOrHeadphones:
    "At the sub-$100 price point, few products suitable for gaming and TV are available. These use cases require low latency – otherwise the sound will be out of sync with the visuals – and that feature is typically found in higher-priced products.",
  WiredExerciseEarbudsHeadphones:
    "We usually don’t recommend using wired earbuds or headphones while exercising. Wires can get snagged on equipment and get in your way.",
  LaptopEthernet:
    "Most modern laptops lack a native Ethernet port. You may have to make other sacrifices to get this.\n\nYou could use Ethernet over USB with an adapter (under $20) instead.",
  LaptopGamingAnd3DTouchscreen:
    "Most high performance laptops do not have a touchscreen. You may have to make other sacrifices to get this.",
  Laptop13Inch14InchNumberPad:
    "Most modern laptops with a screen size below 15” do not have a number pad.",
  SmallIphone:
    "Unfortunately, Apple does not offer the latest iPhones in a Small (under 6”) format. The most recent small iPhone produced was the iPhone 13 Mini. Please confirm your selection.",
  TVNextGenReady:
    "Applying this filter may eliminate some of the most cost-effective choices. Are you sure you need NextGen?",
  TVOledDisplayAndBrightRoom:
    "Are you sure you need an OLED screen? LCD TVs are generally better at handling glare and ambient light in your bright room.",
  TVHighRefreshAndBelow750BudgetOrNoVideoGameSelected:
    "Are you sure you need 120 Hz+? This feature is mainly useful for video games, and may not provide much benefit for other uses. TVs with this feature generally cost more.",
  PhoneWirelessChargingAnd400Budget:
    "Usually, wireless charging is reserved for more expensive phones. You may be recommended phones outside your budget.",
  PhoneDualSIMAnd400Budget:
    "Dual SIM is usually reserved for more expensive phones. You may be recommended phones outside your budget.",
  PhoneSDCard:
    "Relatively few modern phones have an SD card slot. Most people find that the combination of large built-in storage and cloud storage makes them unnecessary. Requiring this may lead to a very limited selection of phones.",
  PhoneHighPerformanceAnd400Budget:
    "Phones with exceptional performance features, like faster processors and more RAM, often come at a higher cost. This filter may result in a more limited selection of devices.",
  EarDetectionBelow100Budget:
    "The options available that include this feature, at the selected price point, are limited. You may be recommended options outside of your budget or not optimal for the rest of your needs.",
  HeadphoneEarbudEqLowCost:
    "The options available under $50 that include this feature are limited. You may have to make other sacrifices or pay more to get this.",
  LosslessCodecsBothConnectivity:
    "Codecs are only applicable to wireless headphones, so we will only show wireless headphones while this option is selected",
  LosslessCodecs100Dollar:
    "The options available under $100 that include this feature are very limited. You may be recommended options outside of your budget or not optimal for the rest of your needs.",
};

// @TODO segment this by category.
const answerConfirmationMap = {
  // Small Phone Answer
  "f458b76f-dd0f-49a0-9137-6535590659ae": [
    {
      id: "f458b76f-dd0f-49a0-9137-6535590659ae",
      prerequisite: ["3d0a2d14-a332-44ab-a689-dd693fe2146d"], // Android Answer
      trayContent: confirmationStrings.SmallAndroid,
      trayVariant: "caution",
    },
    {
      id: "f458b76f-dd0f-49a0-9137-6535590659ae",
      prerequisite: ["dcf960aa-1d36-45e1-8cae-492ccdd0cfa2"], // iPhone Answer
      trayContent: confirmationStrings.SmallIphone,
      trayVariant: "caution",
    },
  ],
  // Up to $400 Phone Answer
  "18f9db7e-c64a-4f7e-a9d0-9bd35c8280c3": [
    {
      id: "18f9db7e-c64a-4f7e-a9d0-9bd35c8280c3",
      prerequisite: ["dcf960aa-1d36-45e1-8cae-492ccdd0cfa2"], // iPhone Answer
      trayContent: confirmationStrings.IPhone400Dollar,
      trayVariant: "warn",
    },
  ],
  // Up to $600 Phone Answer
  "42fdc734-0d63-4114-a613-4027d2c4339e": [
    {
      id: "42fdc734-0d63-4114-a613-4027d2c4339e",
      prerequisite: ["dcf960aa-1d36-45e1-8cae-492ccdd0cfa2"], // iPhone Answer
      trayContent: confirmationStrings.IPhone600Dollar,
      trayVariant: "caution",
    },
  ],
  // 4 Battery Life Phone Answer
  "13bf6728-f5e8-4cb9-9f86-ae30c05b7a52": [
    {
      id: "13bf6728-f5e8-4cb9-9f86-ae30c05b7a52",
      prerequisite: ["f458b76f-dd0f-49a0-9137-6535590659ae"], // small phone answer
      trayContent: confirmationStrings.BigBatterySmallPhone,
      trayVariant: "caution",
    },
  ],
  // gaming laptop answer
  "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5": [
    {
      id: "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5",
      prerequisite: ["52d7b6f4-dc3e-4cd5-bcc1-acd716dfb80b"], // mac laptop answer
      trayContent: confirmationStrings.GamingMac,
      trayVariant: "caution",
      textConfig: {
        header: "Important Note",
        leftButton: "Cancel",
        rightButton: "Okay, got it",
      },
    },
  ],
  // 13" laptop answer
  "caedeceb-6a7a-47cc-87d5-b710c93fcf11": [
    {
      id: "caedeceb-6a7a-47cc-87d5-b710c93fcf11",
      prerequisite: [
        "5cef2409-0462-43b6-afff-5fc56ca1efdb", // $2000 laptop answer
        "7739bedb-9b60-4946-9821-78a4b2510411", // pc laptop answer
        "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5", // gaming laptop answer
      ],
      trayContent: confirmationStrings.GamingSmallLaptop,
      trayVariant: "caution",
    },
    {
      id: "caedeceb-6a7a-47cc-87d5-b710c93fcf11",
      prerequisite: [
        "0b615026-d0ac-40e9-bae4-a345b4d0cd25", // $2500 laptop answer
        "7739bedb-9b60-4946-9821-78a4b2510411", // pc laptop answer
        "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5", // gaming laptop answer
      ],
      trayContent: confirmationStrings.GamingSmallLaptop,
      trayVariant: "caution",
    },
    {
      id: "caedeceb-6a7a-47cc-87d5-b710c93fcf11",
      prerequisite: [
        "397f8cdd-93c9-4c22-9e1d-763d629e5839", // $4000 laptop answer
        "7739bedb-9b60-4946-9821-78a4b2510411", // pc laptop answer
        "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5", // gaming laptop answer
      ],
      trayContent: confirmationStrings.GamingSmallLaptop,
      trayVariant: "caution",
    },
  ],
  // TV Picture Quality 4 Answer
  "ebf4253f-f1f5-4acd-b4f6-6318751585fd": [
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: ["14aa6a05-7383-4480-b2be-61aa1159fc3d"], // TV Up to 39" Answer
      trayContent: confirmationStrings.SmallTVQuality,
      trayVariant: "caution",
    },
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: [
        "ef14e476-8a4a-4e0f-ae1e-727b1b51409c", // TV 70 - 79" Answer
        "fac879d8-c9a1-49ca-853d-04b358234cdb", // $500 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV70InchUnder500,
      trayVariant: "caution",
    },
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: [
        "3ce622c5-122d-49a1-992e-9e94337d16b4", // TV 80 - 89" Answer
        "fac879d8-c9a1-49ca-853d-04b358234cdb", // $500 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV80InchCheap,
      trayVariant: "caution",
    },
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: [
        "3ce622c5-122d-49a1-992e-9e94337d16b4", // TV 80 - 89" Answer
        "9545ff15-6793-4795-891c-0014e68b86d9", // $750 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV80InchCheap,
      trayVariant: "caution",
    },
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: [
        "3ce622c5-122d-49a1-992e-9e94337d16b4", // TV 80 - 89" Answer
        "f29583fc-17d3-4fbc-b204-911d3034334d", // $1000 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV80InchCheap,
      trayVariant: "caution",
    },
    {
      id: "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      prerequisite: [
        "3ce622c5-122d-49a1-992e-9e94337d16b4", // TV 80 - 89" Answer
        "d0d121da-18d2-4e3b-9e7f-51be8a1b51e6", // $1500 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV80InchCheap,
      trayVariant: "caution",
    },
  ],
  // TV Picture Quality 3 Answer
  "045ac578-f622-4500-9bad-d1716de01528": [
    {
      id: "045ac578-f622-4500-9bad-d1716de01528",
      prerequisite: [
        "ef14e476-8a4a-4e0f-ae1e-727b1b51409c", // TV 70 - 79" Answer
        "fac879d8-c9a1-49ca-853d-04b358234cdb", // $500 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV70InchUnder500,
      trayVariant: "caution",
    },
  ],
  // TV Picture Quality 2 Answer
  "e917ad93-a9d4-4872-8a4d-2297cecd9a07": [
    {
      id: "e917ad93-a9d4-4872-8a4d-2297cecd9a07",
      prerequisite: [
        "ef14e476-8a4a-4e0f-ae1e-727b1b51409c", // TV 70 - 79" Answer
        "fac879d8-c9a1-49ca-853d-04b358234cdb", // $500 TV Budget answer
      ],
      trayContent: confirmationStrings.QualityTV70InchUnder500,
      trayVariant: "caution",
    },
  ],
  // TV answer
  "5d0db25c-17f7-417a-98b3-b162318ae6c6": [
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "0e0d6c2a-c7c5-47c6-bb9e-501702618dfe", // Earbuds Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarEarbuds,
      trayVariant: "caution",
    },
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "0e0d6c2a-c7c5-47c6-bb9e-501702618dfe", // Earbuds Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarEarbuds,
      trayVariant: "caution",
    },
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "65c1f30b-268d-4e0f-a1cc-4e69aefb2056", // Headphones Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarHeadphones,
      trayVariant: "caution",
    },
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "65c1f30b-268d-4e0f-a1cc-4e69aefb2056", // Headphones Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarHeadphones,
      trayVariant: "caution",
    },
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "978e9a6f-566a-4f31-b76a-af2d740c8c16", // Show Me Both Form Factors Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarEarbudsOrHeadphones,
      trayVariant: "caution",
    },
    {
      id: "5d0db25c-17f7-417a-98b3-b162318ae6c6",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "978e9a6f-566a-4f31-b76a-af2d740c8c16", // Show Me Both Form Factors Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarEarbudsOrHeadphones,
      trayVariant: "caution",
    },
  ],
  // Gaming Answer
  "118fe58c-d91a-42c6-b62f-62054806d850": [
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "0e0d6c2a-c7c5-47c6-bb9e-501702618dfe", // Earbuds Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarEarbuds,
      trayVariant: "caution",
    },
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "0e0d6c2a-c7c5-47c6-bb9e-501702618dfe", // Earbuds Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarEarbuds,
      trayVariant: "caution",
    },
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "65c1f30b-268d-4e0f-a1cc-4e69aefb2056", // Headphones Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarHeadphones,
      trayVariant: "caution",
    },
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "65c1f30b-268d-4e0f-a1cc-4e69aefb2056", // Headphones Form Factors Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarHeadphones,
      trayVariant: "caution",
    },
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "1a4e6a0f-547e-4794-b437-d557197c9c99", // $100 Budget Answer
        "978e9a6f-566a-4f31-b76a-af2d740c8c16", // Show Me Both Form Factors Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency100DollarEarbudsOrHeadphones,
      trayVariant: "caution",
    },
    {
      id: "118fe58c-d91a-42c6-b62f-62054806d850",
      prerequisite: [
        "15f6d1dc-cfc3-4df3-b036-d251abd07394", // $50 Budget Answer
        "978e9a6f-566a-4f31-b76a-af2d740c8c16", // Show Me Both Form Factors Answer
        "c16562ba-c20e-43ff-b132-a6b33b75f5d7", // Wireless Answer
      ],
      trayContent: confirmationStrings.LowLatency50DollarEarbudsOrHeadphones,
      trayVariant: "caution",
    },
  ],
  // Laptop Ethernet
  "4331c787-20cf-4868-8e0a-2cde1e050197": [
    {
      id: "4331c787-20cf-4868-8e0a-2cde1e050197",
      prerequisite: [],
      trayContent: confirmationStrings.LaptopEthernet,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Laptop Gaming and 3D + Touchscreen
  "32488df7-07c9-4980-b23f-13de7eacf2bf": [
    {
      id: "32488df7-07c9-4980-b23f-13de7eacf2bf",
      prerequisite: ["651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5"],
      trayContent: confirmationStrings.LaptopGamingAnd3DTouchscreen,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Laptop 13-14" + Number Pad
  "d54764f6-81c4-494f-843f-d346f6b94b1e": [
    {
      id: "d54764f6-81c4-494f-843f-d346f6b94b1e",
      prerequisite: ["caedeceb-6a7a-47cc-87d5-b710c93fcf11"],
      trayContent: confirmationStrings.Laptop13Inch14InchNumberPad,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // TV NextGen Ready
  "0c7d0448-c1fd-4e4f-8a49-d287bbf2b748": [
    {
      id: "0c7d0448-c1fd-4e4f-8a49-d287bbf2b748",
      prerequisite: [],
      trayContent: confirmationStrings.TVNextGenReady,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // TV OLED Display and Bright Room
  "f417959d-be94-4237-93c3-35868f702d75": [
    {
      id: "f417959d-be94-4237-93c3-35868f702d75",
      prerequisite: ["4b278763-67ef-4215-b369-74f8ea68091c"], //bright room yes answer
      trayContent: confirmationStrings.TVOledDisplayAndBrightRoom,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // TV 120Hz+ And Less Than 750 Budget Or Video Game Not Selected
  "98bfe6e7-88f4-4cfa-ab24-744ffee20340": [
    {
      id: "98bfe6e7-88f4-4cfa-ab24-744ffee20340",
      prerequisite: ["fac879d8-c9a1-49ca-853d-04b358234cdb"], //TV $500 budget
      trayContent:
        confirmationStrings.TVHighRefreshAndBelow750BudgetOrNoVideoGameSelected,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
    {
      id: "98bfe6e7-88f4-4cfa-ab24-744ffee20340",
      prerequisite: ["9545ff15-6793-4795-891c-0014e68b86d9"], //TV $750 budget
      trayContent:
        confirmationStrings.TVHighRefreshAndBelow750BudgetOrNoVideoGameSelected,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Phone Wireless Charging and 400 Budget
  "758162cb-b2a1-4935-bafd-322f5c002fee": [
    {
      id: "758162cb-b2a1-4935-bafd-322f5c002fee",
      prerequisite: ["18f9db7e-c64a-4f7e-a9d0-9bd35c8280c3"], //Phone upto 400 budget
      trayContent: confirmationStrings.PhoneWirelessChargingAnd400Budget,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Phone Dual SIM and 400 Budget
  "7d340039-5b50-402a-8600-761e9e8c6600": [
    {
      id: "7d340039-5b50-402a-8600-761e9e8c6600",
      prerequisite: ["18f9db7e-c64a-4f7e-a9d0-9bd35c8280c3"], //Phone upto 400 budget
      trayContent: confirmationStrings.PhoneDualSIMAnd400Budget,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Phone High Performance and 400 Budget
  "78dced2e-71fc-499b-a9c7-659ddb63ac89": [
    {
      id: "78dced2e-71fc-499b-a9c7-659ddb63ac89",
      prerequisite: ["18f9db7e-c64a-4f7e-a9d0-9bd35c8280c3"], //Phone upto 400 budget
      trayContent: confirmationStrings.PhoneHighPerformanceAnd400Budget,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Phone SD Card
  "5047c8de-bafa-4915-9355-9a12cd32d62f": [
    {
      id: "5047c8de-bafa-4915-9355-9a12cd32d62f",
      prerequisite: [],
      trayContent: confirmationStrings.PhoneSDCard,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Headphone Ear detection under 100 Budget
  "743aabe8-8f2d-4088-b8aa-380dec33e350": [
    {
      id: "743aabe8-8f2d-4088-b8aa-380dec33e350",
      prerequisite: ["15f6d1dc-cfc3-4df3-b036-d251abd07394"], // wireless product budget <= 50
      trayContent: confirmationStrings.EarDetectionBelow100Budget,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
    {
      id: "743aabe8-8f2d-4088-b8aa-380dec33e350",
      prerequisite: ["1a4e6a0f-547e-4794-b437-d557197c9c99"], // wireless product budget <= 100
      trayContent: confirmationStrings.EarDetectionBelow100Budget,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Wired, exercise
  "e4b172ef-3081-427b-bb2d-69652b359a5f": [
    {
      id: "e4b172ef-3081-427b-bb2d-69652b359a5f",
      prerequisite: ["61b31fe3-c3a5-4126-ae45-9ad91aa14ae2"],
      trayContent: confirmationStrings.WiredExerciseEarbudsHeadphones,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  // Headphones/earbuds EQ included <= 50
  "f3a8c032-a62b-4d93-8278-bd09420f941f": [
    {
      id: "f3a8c032-a62b-4d93-8278-bd09420f941f",
      prerequisite: ["15f6d1dc-cfc3-4df3-b036-d251abd07394"],
      trayContent: confirmationStrings.HeadphoneEarbudEqLowCost,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
  "1aa051f1-8989-4a31-875f-8351de2cfb45": [
    // Lossless codecs, both wireless and wired.
    {
      id: "1aa051f1-8989-4a31-875f-8351de2cfb45",
      prerequisite: ["453e1bf2-e12b-499b-825d-e540ac5d92e4"],
      trayContent: confirmationStrings.LosslessCodecsBothConnectivity,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
    // Lossless Codecs, $100 (at 50 we don't show the option)
    {
      id: "1aa051f1-8989-4a31-875f-8351de2cfb45",
      prerequisite: ["1a4e6a0f-547e-4794-b437-d557197c9c99"],
      trayContent: confirmationStrings.LosslessCodecs100Dollar,
      trayVariant: "caution",
      textConfig: {
        header: "Are you sure you need this?",
        leftButton: "No, I don't need it",
        rightButton: "Yes, I need it",
      },
    },
  ],
} as AnswerConfirmationMap;

const useAnswerConfirmation = () => {
  const { activeResponseSet } = useContext(RecUIContext);
  const [confirmActive, setConfirmActive] = useState(false);
  const [confirmingAnswer, setConfirmingAnswer] = useState<Answer>();

  return {
    getConfirmation: (answer: Answer): AnswerConfirmation => {
      if (!activeResponseSet || !answer) {
        return null;
      }
      let confirmAnswer = null;
      const answerConfirmation = answerConfirmationMap[answer.id];
      if (answerConfirmation) {
        answerConfirmation.forEach((confirmation) => {
          const prerequisitesMet = confirmation.prerequisite.every((prereq) => {
            return activeResponseSet.responses.some((response) => {
              return response.answerIds?.includes(prereq);
            });
          });
          if (prerequisitesMet) {
            confirmAnswer = confirmation;
          }
        });
      }
      return confirmAnswer;
    },
    confirmActive,
    setConfirmActive,
    confirmingAnswer,
    setConfirmingAnswer,
  };
};

export default useAnswerConfirmation;
