import { IProduct, IScenarioFields } from "@lib/model/contentful";
import { PageLayout } from "@components/layouts/page-layout";
import { Product } from "@lib/model/product";
import { marked } from "marked";
import AuthorCard from "@components/common/author-card";
import React, { useState } from "react";
import {
  getRecommenderUrl,
  getProductLabels,
  getScenarioUrl,
  getProductLandingPageUrl,
  getImageUrl,
  AssetType,
  parseOpenAIJSON,
} from "@lib/utilities/shared-utilities";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import { ModelResponseRecommendation } from "@lib/model/recommender-model";
import SingleRecExplanations, {
  RecExplanationStyle,
} from "@components/recommendation/single-rec-explanations";
import PurchaseData from "@components/product/purchase-data";
import ProductCategoryContextProvider from "contexts/product-category-context";
import ProductCompareTable from "@components/compare/product-compare-table";
import Link from "next/link";
import CollapsibleSection from "@components/common/collapsible-section";
import Meta from "./meta";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import { LinkButton } from "@components/common/button";
import { ProductImage } from "@components/product/product-card/product-card-composed";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import { v4 as uuidv4 } from "uuid";
import { getExplanations } from "@lib/utilities/product-classifier";
import SingleRecNewExplanations from "@components/recommendation/single-rec-explanations-new";
import ProsAndCons, { getProsAndCons } from "@components/common/pros-and-cons";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import {
  StarRatingsSection,
  StarSummary,
} from "@components/product/summary/star-ratings";
import {
  VariantPriceSummary,
  VariantSelectTable,
} from "@components/product/VariantDropdown";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import HorizontalAdUnit from "@components/ads/horizontal-ad-unit";

export const getScenarioTitle = (
  scenario: IScenarioFields,
  category: ProductCategoryConfig
) => {
  // Replace singular noun with plural.
  if (
    scenario?.title.toLowerCase().includes(category.pluralNoun.toLowerCase())
  ) {
    return `${scenario?.numberOfRecs === null ? 10 : scenario?.numberOfRecs} ${
      scenario?.title
    }`;
  } else {
    return `${
      scenario?.numberOfRecs === null ? 10 : scenario?.numberOfRecs
    } ${scenario?.title.replace(
      new RegExp(category.noun, "i"),
      capitalizeFirstLetter(category.pluralNoun)
    )}`;
  }
};

const getScenarioSubTitle = (
  scenario: IScenarioFields,
  category: ProductCategoryConfig,
  n: number
) => {
  const title = getScenarioTitle(scenario, category).replace(
    new RegExp(/^best/i),
    `The ${n} best`
  );

  return title;
};

const getMetaDescription = (
  scenario: IScenarioFields,
  category: ProductCategoryConfig,
  n: number
) =>
  `Here are ${getScenarioSubTitle(
    scenario,
    category,
    n
  ).toLowerCase()}, according to our ${category.noun} experts`;

/**
 * The main scenario page component.
 */
const ScenarioPage = (props: {
  scenario: IScenarioFields;
  products: Product[];
  productCategory: ProductCategoryConfig;
  recommendations: ModelResponseRecommendation[];
  productEntries: IProduct[];
  configMap: ProductCategoryConfigMap;
  storedOutputs: Record<string, Record<string, StoredLlmOutput>>;
}) => {
  const {
    scenario,
    products,
    productEntries,
    productCategory,
    recommendations,
    configMap,
    storedOutputs,
  } = props;

  const title = getScenarioTitle(scenario, productCategory);

  const responseSet: ResponseSetWithResponses = {
    id: uuidv4(),
    responses: scenario.responses.map((response) => ({
      answerIds: response.answerIds,
      questionId: response.questionId,
      answers: response.answerIds.map((answerId) =>
        productCategory.questions
          .find((question) => question.id === response.questionId)
          ?.answers.find((answer) => answer.id === answerId)
      ),
      question: productCategory.questions.find(
        (question) => question.id === response.questionId
      ),
    })),
  };

  const prosAndCons = {};

  products.map((product) => {
    const productProsAndCons =
      (storedOutputs[product.id].prosAndCons?.result &&
        parseOpenAIJSON(storedOutputs[product.id].prosAndCons.result)) ||
      getProsAndCons(product, productCategory);

    prosAndCons[product.id] = productProsAndCons;
  });

  usePageScrollTracking({ page: "Scenario" });

  const breadcrumbs = [
    {
      name: `${capitalizeFirstLetter(productCategory.noun)} Guides`,
      href: `/electronics/scenarios/${productCategory.slug}`,
    },
  ];

  const breadcrumbsJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 0,
        name: "Home",
        item: "https://www.perfectrec.com",
      },
      ...breadcrumbs.map(({ name, href }, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name,
        item: `https://www.perfectrec.com${href}`,
      })),
      {
        "@type": "ListItem",
        position: breadcrumbs.length + 1,
        name: title,
      },
    ],
  };

  return (
    <PageLayout configMap={configMap}>
      <Meta
        title={`${title} - PerfectRec`}
        description={getMetaDescription(
          scenario,
          productCategory,
          recommendations.length
        )}
        url={getScenarioUrl(productCategory, scenario.slug)}
        image={getImageUrl(
          products[0].image,
          AssetType.ProductImage,
          { width: 600 },
          productCategory.name
        )}
        canonicalUrl={getScenarioUrl(productCategory, scenario.slug)}
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbsJson),
          }}
        />
      </Meta>
      <ProductCategoryContextProvider productCategoryConfig={productCategory}>
        <div className="container max-w-5xl">
          <h1 className="mt-12 mb-8">{title}</h1>
          <div className="flex justify-center">
            <ProductImage
              product={products[0]}
              categoryName={productCategory.name}
              sizeClassName="max-h-[400px]"
              widthToFetch={450}
            />
          </div>
          {scenario.author && (
            <AuthorCard
              author={scenario.author.fields}
              title="editor"
              className="my-4"
            />
          )}
          {scenario.introduction && (
            <MarkdownBlock value={scenario.introduction} className="my-4" />
          )}

          {/* Criteria block */}
          <div className="border rounded-xl border-gray-500 bg-panel-blue-1 p-4 mb-2">
            {scenario.criteriaHeadline && (
              <h2 className="text-lg">{scenario.criteriaHeadline}</h2>
            )}
            {scenario.criteria && (
              <MarkdownBlock
                className="scenario-criteria mb-5 mt-3"
                value={scenario.criteria}
              />
            )}
            <div className="text-xs font-semibold text-center mb-2 mt-5">
              To consider others factors, try the PerfectRec decision engine to
              get your personalized {productCategory.noun} recommendation
            </div>
            <LinkButton
              className="w-full"
              href={getRecommenderUrl(productCategory)}
            >
              Get your personalized recommendations
            </LinkButton>
          </div>
          {/* 
          <HorizontalAdUnit
            adSlot="6205412084"
            ezoicId="ezoic-pub-ad-placeholder-111"
          /> */}

          <h2 className="text-center mt-8 mb-2 text-2xl">
            {getScenarioSubTitle(
              scenario,
              productCategory,
              recommendations.length
            )}
          </h2>

          {/* Top recommenations */}
          <div data-testid="scenario-product-container">
            {recommendations.map((modelRec, i) => {
              const product = products.find(
                (product) => product.id === modelRec.productId
              );
              if (product) {
                return (
                  <ScenarioRecCard
                    {...{
                      productCategory,
                      modelRec,
                      product,
                      productEntry: productEntries?.find(
                        (entry) => entry.fields.slug === product.slug
                      ),
                      responseSet,
                      storedOutput: storedOutputs[product.id],
                      prosAndCons: prosAndCons[product.id],
                    }}
                    key={modelRec.productId}
                    position={i + 1}
                  />
                );
              }
            })}
          </div>
          <div></div>
        </div>
      </ProductCategoryContextProvider>
    </PageLayout>
  );
};

/**
 * A single card for a product being displayed a scenario.
 */
const ScenarioRecCard = (props: {
  product: Product;
  productEntry: IProduct;
  modelRec: ModelResponseRecommendation;
  productCategory: ProductCategoryConfig;
  position: number;
  responseSet: ResponseSetWithResponses;
  storedOutput: Record<PromptType, StoredLlmOutput>;
  prosAndCons: { pros: string[]; cons: string[] };
}) => {
  const {
    product,
    productEntry,
    modelRec,
    productCategory,
    position,
    responseSet,
    storedOutput,
    prosAndCons,
  } = props;

  const [expanded, setExpanded] = useState(false);

  const newExplanations =
    responseSet && productCategory?.explanationConfiguration
      ? getExplanations(
          product,
          responseSet,
          productCategory.explanationConfiguration,
          8
        )
      : [];

  const displayableExplanations = newExplanations.filter((exp) => exp.display);

  const adSlots = {
    2: { adSlot: "7150047840", ezoicId: "ezoic-pub-ad-placeholder-112" },
    4: { adSlot: "4892330418", ezoicId: "ezoic-pub-ad-placeholder-113" },
    6: { adSlot: "3579248749", ezoicId: "ezoic-pub-ad-placeholder-114" },
    8: { adSlot: "2266167073", ezoicId: "ezoic-pub-ad-placeholder-115" },
  };

  return (
    <>
      <div className="my-7 relative">
        <Position>{position}</Position>
        <div className="p-4 md:p-8 border border-gray-500 rounded-xl">
          <div className="grid grid-cols-1 md:grid-cols-10 gap-2">
            <div className="md:col-span-4">
              <ProductImage
                product={product}
                categoryName={productCategory.name}
                sizeClassName="max-h-60"
                widthToFetch={350}
              />
            </div>
            <div className="md:col-span-6">
              <ProductTitle
                product={product}
                className=""
                category={productCategory}
              />

              <div className="text-left font-sans font-normal text-xs mt-2">
                <StarSummary product={product} showViewAllRatings={false} />
              </div>

              <SingleRecNewExplanations
                explanations={displayableExplanations}
                variant={RecExplanationStyle.Chips}
                className="my-4"
              />

              <div className="mb-3">
                <VariantPriceSummary product={product} />
              </div>
            </div>
          </div>
          {storedOutput?.productDetail?.result && (
            <div className="my-2">
              <div className="font-semibold">Expert Summary</div>
              <div className={`my-2 ${!expanded && "line-clamp-3"}`}>
                {storedOutput.productDetail.result}
              </div>
              <button
                className="cursor-pointer text-sm font-semibold flex items-center cursor:pointer text-blue-500"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {expanded ? "Show less" : `Show more`}
                <ChevronDownIcon
                  className={`h-4 w-4 ${
                    expanded && "rotate-180"
                  } transition-all shrink-0`}
                />
              </button>
            </div>
          )}
          {productCategory.displayProsAndCons &&
            prosAndCons.pros.length !== 0 && (
              <ProsAndCons
                product={product}
                productCategory={productCategory}
                prosAndCons={prosAndCons}
                className="mt-12"
              />
            )}
          <CollapsibleSection heading={"View all ratings"}>
            <div className="my-4">
              <StarRatingsSection
                product={product}
                outputs={props.storedOutput}
              />
            </div>
          </CollapsibleSection>
          <CollapsibleSection heading="Specs">
            <ProductCompareTable
              products={[product]}
              attributes={productCategory.attributeConfiguration}
              category={product.metadata.categoryName}
            />
          </CollapsibleSection>
        </div>
      </div>
      {/* {[2, 4, 6, 8].includes(position) && (
        <HorizontalAdUnit
          adSlot={adSlots[position].adSlot}
          ezoicId={adSlots[position].ezoicId}
        />
      )} */}
    </>
  );
};

/**
 * The position indicator for a scenario product card.
 */
const Position = (props: { children: React.ReactNode }) => (
  <div className="rounded-full w-6 h-6 my-2 border border-gray-500 flex justify-center items-center text-xs font-bold absolute top-0 left-2">
    {props.children}
  </div>
);

/**
 * The product title for a scenario product card.
 */
const ProductTitle = (props: {
  product: Product;
  category: ProductCategoryConfig;
  className?: string;
}) => {
  const productLabels = getProductLabels(props.product);
  return (
    <div className={props.className || ""}>
      <div className="text-gray-500">{productLabels.secondaryLabel}</div>
      <Link href={getProductLandingPageUrl(props.product, props.category)}>
        <h3 className="font-sans text-2xl font-bold cursor-pointer">
          {productLabels.primaryLabel}
        </h3>
      </Link>
    </div>
  );
};

/**
 * Renders a block of markdown content, usually from Contentful.
 */
const MarkdownBlock = (props: { value: string; className?: string }) => (
  <div
    className={props.className || undefined}
    dangerouslySetInnerHTML={{
      __html: marked(props.value),
    }}
  />
);

export default ScenarioPage;
