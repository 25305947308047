import React, { useContext } from "react";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import useModal from "@lib/hooks/use-modal";
import CompareModal from "@components/compare/compare-modal";
import { PrimaryButton } from "@components/common/button";
import { QuestionWithAnswers } from "@lib/model/question";
import { ProductCategoryContext } from "contexts/product-category-context";
import { TableCellsIcon } from "@heroicons/react/24/solid";
import { Product } from "@lib/model/product";

export const CompareButton = (props: {
  products: Product[];
  currentQuestion?: QuestionWithAnswers;
  btnText?: string;
}) => {
  const [compareModalOpen, setCompareModalOpen, toggleCompareModal] =
    useModal();
  const { currentQuestion, products } = props;
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const trackCompareModalOpen = () => {
    EventTracker.track(Events.CompareModalOpen, {
      question_text: currentQuestion?.mainText
        ? currentQuestion.mainText
        : "Done view",
      question_id: currentQuestion?.id ? currentQuestion.id : "Done view",
      rec_rank1: `${products[0].manufacturer} ${products[0].model}`,
      rec_rank2:
        products[1] && `${products[1].manufacturer} ${products[1].model}`,
      rec_rank3:
        products[2] && `${products[2].manufacturer} ${products[2].model}`,
    });
  };

  return (
    <>
      <PrimaryButton
        onClick={() => {
          toggleCompareModal();
          trackCompareModalOpen();
          // The more standard router.push method doesn't work because we need
          // shallow routing, and sometimes compare buttons show up on dynamic
          // routes where shallow routing does not work.
          window.history.pushState(null, "", `${window.location.href}#compare`);

          // This behaves strangely when it is put in a useEffect. It should go
          // there though eventually.
          window.onpopstate = (event) => {
            if (event.currentTarget.location.hash === "#compare") {
              setCompareModalOpen(true);
            } else {
              setCompareModalOpen(false);
            }
          };
        }}
        variant="solid"
        className={"w-full"}
      >
        <TableCellsIcon className="h-5" />
        <span>{props.btnText || "Compare"}</span>
      </PrimaryButton>
      <CompareModal
        open={compareModalOpen}
        compareProducts={products}
        categoryName={productCategoryConfig.name}
      />
    </>
  );
};

export default CompareButton;
