import Header from "@components/frame/header";
import { Footer } from "@components/page/footer";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import ProductCategoryContextProvider, {
  ProductCategoryContext,
} from "contexts/product-category-context";
import {
  getCategoryImage,
  getCategoryProductsUrl,
  getProductLabels,
  getProductLandingPageUrl,
  getRecommenderLandingUrl,
  matchAllWordsFuzzy,
  productLabelCompare,
  productReleaseDateCompare,
} from "@lib/utilities/shared-utilities";
import usePager from "@lib/hooks/use-pager";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { ProductCardVertical } from "@components/product/product-card/product-card-composed";
import { ResponsivePagers } from "@components/common/pager";
import {
  capitalizeFirstLetter,
  useWindowDimensions,
} from "@lib/utilities/client-utilities";
import { Product } from "@lib/model/product";
import { AttributeValue } from "@components/compare/compare-components";
import Meta from "./meta";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import { Events } from "@lib/tracking/event-tracker";
import ProductSelectInput from "@components/product/product-select-input";
import { useRouter } from "next/router";
import Dropdown from "@components/form-element/dropdown";
import { Checkbox } from "@components/form-element/checkbox";
import {
  LinkButton,
  PrimaryButton,
  TextButton,
} from "@components/common/button";

const DEFAULT_SORT_PREF = "releaseDateDesc";

/**
 * The main category product listing component.
 */
const CategoryProductsPage = ({
  productCategory,
  products,
  configMap,
}: {
  productCategory: ProductCategoryConfig;
  products: Product[];
  configMap: ProductCategoryConfigMap;
}) => {
  const basePath = getCategoryProductsUrl(productCategory);
  const { currentPage } = usePager(basePath);

  usePageScrollTracking({ page: "Products listing" });

  return (
    <>
      <Meta
        title={`PerfectRec - ${capitalizeFirstLetter(
          productCategory.pluralNoun
        )}`}
        description={`Browse our wide selection of ${
          productCategory.pluralNoun
        } to find the best ${productCategory.noun.toLowerCase()} for your needs. `}
        url={getCategoryProductsUrl(productCategory, currentPage)}
        image={getCategoryImage(productCategory)}
      />
      <Header configMap={configMap} />
      <ProductCategoryContextProvider productCategoryConfig={productCategory}>
        <Content currentPage={currentPage} products={products} />
      </ProductCategoryContextProvider>
      <Footer configMap={configMap} />
    </>
  );
};

type HeadPhoneType =
  | "wirelessEarbuds"
  | "wiredEarbuds"
  | "wirelessHeadphones"
  | "wiredHeadphones";

type SortOptions =
  | "nameAsc"
  | "nameDesc"
  | "priceAsc"
  | "priceDesc"
  | "releaseDateAsc"
  | "releaseDateDesc";

/**
 * The listing page content, inside of the context.
 */
const Content = ({
  currentPage,
  products: ssrProducts,
}: {
  currentPage: number;
  products: Product[];
}) => {
  const { products: allProducts } = useGlobalAllProducts();
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const router = useRouter();

  const canonicalProducts = allProducts?.filter(
    (product) => product.isCanonical
  );

  const perPage = 20;
  const start = (currentPage - 1) * perPage;
  const end = start + perPage;

  const urlSearchParams = router.query as {
    sort: SortOptions;
    s: string;
    brands: string | string[];
    headphoneTypes: HeadPhoneType[] | HeadPhoneType;
  };

  const { sort, s, brands, headphoneTypes } = urlSearchParams;
  const brandsN = typeof brands === "string" ? 1 : brands?.length || 0;
  const headphoneTypesN =
    typeof headphoneTypes === "string" ? 1 : headphoneTypes?.length || 0;
  const hasSearchParams = sort || s || brands || headphoneTypes;
  const filtersN = headphoneTypesN + brandsN;

  /**
   * Navigate to a new URL update for a single key/value query pair.
   */
  const navigateForQuery = (param: string, value: string | string[]) => {
    const url = new URL(window.location.href);
    url.searchParams.delete("page");

    if (Array.isArray(value)) {
      url.searchParams.delete(param);
      for (const singleValue of value) {
        if (singleValue) {
          url.searchParams.append(param, singleValue);
        }
      }
    } else {
      url.searchParams.set(param, value);
    }

    router.push(url.toString());
  };

  const navigateClearParams = (params: string[]) => {
    const url = new URL(window.location.href);
    url.searchParams.delete("page");
    for (const param of params) {
      url.searchParams.delete(param);
    }
    router.push(url.toString());
  };

  const handleSearchInput = ({ value }) => {
    navigateForQuery("s", value);
  };

  const handleBrandInput = (brands) => {
    navigateForQuery("brands", brands);
  };

  const handleSortInput = (sort) => {
    navigateForQuery("sort", sort);
  };

  const handleHeadphoneTypeInput = (type) => {
    navigateForQuery("headphoneTypes", type);
  };

  const [bottomFiltersOpen, setBottomFiltersOpen] = useState(false);

  const productsInQuery = useMemo(() => {
    if (canonicalProducts) {
      return getProductsInQuery(canonicalProducts, urlSearchParams);
    }
    return [];
  }, [canonicalProducts, urlSearchParams]);

  // The first page shows the products provided by the server, for SEO purposes.
  const shouldShowSsrProducts =
    !hasSearchParams && (!currentPage || currentPage === 1);

  // On pages past the first, and for any state where there is a query, we
  // use the client-side list.
  const displayProducts = shouldShowSsrProducts
    ? ssrProducts
    : productsInQuery.slice(start, end);

  let summaryStart, summaryEnd, summaryCount;
  if (shouldShowSsrProducts) {
    summaryStart = 1;
    summaryEnd = perPage;
    summaryCount = canonicalProducts?.length;
  } else {
    summaryCount = productsInQuery?.length;
    summaryStart = start + 1;
    summaryEnd =
      end > productsInQuery?.length ? productsInQuery?.length : end + 1;
  }

  return (
    <>
      <div className="container my-12 max-w-screen-xl">
        <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-x-4">
          <h1 className="mb-6 sm:col-span-3 sm:col-start-2">
            {capitalizeFirstLetter(productCategoryConfig.pluralNoun)}
          </h1>
          <div className="hidden sm:block col-span-1">
            <h4 className="small-caps text-xl mb-3 font-semibold">Filter</h4>
            <div className="border-y border-gray-500 p-3">
              <div className="flex flex-col gap-6 max-h-[768px] overflow-auto">
                {productCategoryConfig.name === "headphones" && (
                  <MultiSelectFilterContainer title="Type">
                    <HeadphoneTypeFilter
                      defaultValues={headphoneTypes}
                      onChange={handleHeadphoneTypeInput}
                      noBorder
                    />
                  </MultiSelectFilterContainer>
                )}
                <MultiSelectFilterContainer title="Brand">
                  <BrandFilter
                    defaultValues={brands}
                    onChange={handleBrandInput}
                    noBorder
                  />
                </MultiSelectFilterContainer>
              </div>
            </div>
          </div>
          <div className="grow sm:col-span-2 md:col-span-3">
            <div className="flex flex-col md:flex-row gap-4">
              <ProductSelectInput
                onSelect={({ product }) =>
                  router.push(
                    getProductLandingPageUrl(product, productCategoryConfig)
                  )
                }
                onSearch={handleSearchInput}
                selectEvent={Events.CategoryIndexProductSelect}
                searchEvent={Events.CategoryIndexProductSearch}
                placeholder={`Search ${productCategoryConfig.pluralNoun}`}
                className="grow"
                defaultValue={s}
              />
              <div className="flex flex-col w-full sm:w-auto gap-4">
                <SortPreferenceInput
                  onChange={handleSortInput}
                  defaultValue={sort || DEFAULT_SORT_PREF}
                />
                <PrimaryButton
                  onClick={() => setBottomFiltersOpen(true)}
                  className="block sm:hidden"
                >
                  {productCategoryConfig.name === "headphones"
                    ? "Filters"
                    : "Brand filters"}
                  {filtersN > 0 && ` (${filtersN})`}
                </PrimaryButton>
              </div>
            </div>

            <div className="my-4 text-sm font-semibold h-5">
              {summaryCount > 0 && (
                <>
                  {summaryStart} to {summaryEnd} of {summaryCount} results
                </>
              )}
            </div>
            <ProductsList products={displayProducts} />
            {displayProducts?.length === 0 && (
              <div className="text-center w-full p-8 text-xl font-semibold">
                No results found.
              </div>
            )}

            <ListPager
              {...{ allProducts: productsInQuery, currentPage }}
              searchParams={{ sort, s, brands }}
            />
          </div>
        </div>
      </div>
      <BottomTray open={bottomFiltersOpen}>
        <div className="flex justify-end">
          <TextButton onClick={() => setBottomFiltersOpen(false)}>
            Close
          </TextButton>
        </div>
        <h4 className="text-center text-lg mb-3 font-semibold">Filter</h4>
        <div className="border-t border-gray-500 mt-4 py-2 max-h-[275px] overflow-auto flex flex-col gap-6">
          {/* Fits about four rows of brand checkboxes. */}
          {productCategoryConfig.name === "headphones" && (
            <MultiSelectFilterContainer title="Type">
              <div className="grid grid-cols-2 gap-2">
                <HeadphoneTypeFilter
                  defaultValues={headphoneTypes}
                  onChange={handleHeadphoneTypeInput}
                />
              </div>
            </MultiSelectFilterContainer>
          )}

          <MultiSelectFilterContainer title="Brand">
            <div className="grid grid-cols-2 gap-2">
              <BrandFilter defaultValues={brands} onChange={handleBrandInput} />
            </div>
          </MultiSelectFilterContainer>
        </div>
        <div className="flex justify-between border-t border-gray-500 pt-3">
          <PrimaryButton
            onClick={() =>
              navigateClearParams(["brands", "s", "headphoneTypes"])
            }
          >
            Clear filters
          </PrimaryButton>
          {summaryCount > 0 && (
            <PrimaryButton
              onClick={() => setBottomFiltersOpen(false)}
              variant="solid"
            >
              {summaryCount > 1
                ? `See all ${summaryCount} Results`
                : "See 1 Result"}
            </PrimaryButton>
          )}
        </div>
      </BottomTray>
    </>
  );
};

/**
 * Filters all products based on defined search parameters.
 */
const getProductsInQuery = (
  products: Product[],
  searchParams: {
    sort: SortOptions;
    s: string;
    brands: string | string[];
    headphoneTypes: HeadPhoneType[] | HeadPhoneType;
  }
) => {
  if (!products) {
    return [];
  }

  let needsFilter = false;
  const requires = {
    s: !!searchParams.s,
    brands: !!searchParams.brands,
    headphoneTypes: !!searchParams.headphoneTypes,
  };

  Object.entries(requires).forEach(([param, required]) => {
    if (required) {
      needsFilter = true;
    }
  });

  const filteredProducts = needsFilter
    ? products.filter((product) => {
        let passes = true;
        const productLabel = getProductLabels(product).shortLabel;

        if (requires.s) {
          passes = matchAllWordsFuzzy(searchParams.s, productLabel);
        }
        if (requires.brands && passes) {
          let brands = searchParams.brands;
          if (!Array.isArray(searchParams.brands)) {
            brands = [searchParams.brands];
          }
          passes = brands.includes(product.manufacturer);
        }
        if (requires.headphoneTypes && passes) {
          switch (searchParams.headphoneTypes) {
            case "wirelessEarbuds":
              passes =
                product.attributes["formFactor"]?.value === "Earbuds" &&
                product.attributes["wireless"]?.value === "Yes";
              break;
            case "wiredEarbuds":
              passes =
                product.attributes["formFactor"]?.value === "Earbuds" &&
                product.attributes["wired"]?.value === "Yes";
              break;
            case "wiredHeadphones":
              passes =
                product.attributes["formFactor"]?.value === "Headphones" &&
                product.attributes["wired"]?.value === "Yes";
              break;
            case "wirelessHeadphones":
              passes =
                product.attributes["formFactor"]?.value === "Headphones" &&
                product.attributes["wireless"]?.value === "Yes";
              break;
          }
        }

        return passes;
      })
    : products;

  const sortPref = searchParams.sort || DEFAULT_SORT_PREF;
  const sortedProducts = filteredProducts.sort((a: Product, b: Product) => {
    const priceA = a.bestPrice;
    const priceB = b.bestPrice;
    switch (sortPref) {
      case "nameAsc":
        return productLabelCompare(a, b);
      case "nameDesc":
        return productLabelCompare(b, a);
      case "priceAsc":
        return priceA - priceB;
      case "priceDesc":
        return priceB - priceA;
      case "releaseDateAsc":
        return productReleaseDateCompare(a, b);
      case "releaseDateDesc":
        return productReleaseDateCompare(b, a);
    }
  });

  return sortedProducts;
};

/**
 * An input for setting sort order.
 */
const SortPreferenceInput = ({
  onChange,
  defaultValue,
}: {
  onChange: (value: string) => void;
  defaultValue: string;
}) => {
  const items = [
    { value: "releaseDateDesc", label: "Release date: Newest to oldest" },
    { value: "releaseDateAsc", label: "Release date: Oldest to newest" },
    { value: "nameAsc", label: "Name: A to Z" },
    { value: "nameDesc", label: "Name: Z to A" },
    { value: "priceAsc", label: "Price: Low to High" },
    { value: "priceDesc", label: "Price: High to Low" },
  ];
  return (
    <Dropdown
      items={items}
      default={defaultValue}
      onChange={onChange}
      buttonClassName="w-full sm:min-w-[320px]"
      getDisplayLabel={(selectedItem) => (
        <>
          <strong>Sort by: </strong>
          {selectedItem}
        </>
      )}
    />
  );
};

/**
 * An input for selecting a set of brands to filter by.
 */
const BrandFilter = ({
  onChange,
  defaultValues,
  noBorder = false,
}: {
  onChange: (value: string[]) => void;
  defaultValues: string | string[];
  noBorder?;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  return (
    <MultiSelectFilterOptions
      onChange={onChange}
      defaultValues={defaultValues}
      noBorder={noBorder}
      options={productCategoryConfig.brands}
    />
  );
};

/**
 * An input for selecting a set of headphone types to filter by.
 */
const HeadphoneTypeFilter = ({
  onChange,
  defaultValues,
  noBorder = false,
}: {
  onChange: (value: string[]) => void;
  defaultValues: string | string[];
  noBorder?;
}) => {
  return (
    <MultiSelectFilterOptions
      onChange={onChange}
      defaultValues={defaultValues}
      noBorder={noBorder}
      options={[
        { value: "wirelessEarbuds", label: "Wireless Earbuds" },
        { value: "wiredEarbuds", label: "Wired Earbuds" },
        { value: "wirelessHeadphones", label: "Wireless Headphones" },
        { value: "wiredHeadphones", label: "Wired Headphones" },
      ]}
    />
  );
};

/**
 * Renders the checkboxes for a filter group.
 */
const MultiSelectFilterOptions = ({
  onChange,
  defaultValues,
  noBorder = false,
  options,
}: {
  onChange: (value: string[]) => void;
  defaultValues: string | string[];
  noBorder?;
  options: { value?: string; label; description?: string }[];
}) => {
  const handleDefaults = (defaults) =>
    Array.isArray(defaults) ? defaults : defaults ? [defaults] : [];

  // The local state makes UI changes immediate.
  const [selected, setSelected] = useState(handleDefaults(defaultValues));
  useEffect(() => {
    setSelected(handleDefaults(defaultValues));
  }, [defaultValues]);

  // Value is optional, so we may default to label.
  const getSlug = (stat) => stat.value || stat.label;

  return (
    <>
      {options.map(({ value, label, description }) => (
        <Checkbox
          label={label}
          key={label}
          // Value is optional.
          value={value || label}
          noBorder={noBorder}
          explanation={description || undefined}
          onChange={() => {
            let newSelected;
            const urlSlug = getSlug({ label, value });

            if (!selected.includes(urlSlug)) {
              newSelected = [...selected, urlSlug];
            } else {
              newSelected = selected.filter(
                (selectedSlug) => selectedSlug !== urlSlug
              );
            }

            setSelected(newSelected);
            onChange(newSelected);
          }}
          checked={selected.includes(getSlug({ label, value }))}
        />
      ))}
    </>
  );
};

/**
 * Renders the wrapper (with title) for a multiselect filter group.
 */
const MultiSelectFilterContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <div className="text-sm font-semibold mb-2">{title}</div>
      {children}
    </div>
  );
};

const RecommenderCtaBlock = ({
  category,
}: {
  category: ProductCategoryConfig;
}) => {
  return (
    <div className="border border-gray-500 rounded-xl p-4 bg-panel-blue-1 sm:col-span-2 md:col-span-3 lg:col-span-4">
      <div className="font-semibold mb-4 text-center">
        Try the PerfectRec decision engine to get your personalized phone
        recommendation
      </div>
      <LinkButton href={getRecommenderLandingUrl(category)}>
        Get your personalized recommendation
      </LinkButton>
    </div>
  );
};

/**
 * A wrapper around the pager.
 */
const ListPager = ({
  allProducts,
  currentPage,
  searchParams,
}: {
  allProducts: Product[];
  currentPage: number;
  searchParams?: { [key: string]: string | string[] };
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  return (
    <div className="h-12">
      {allProducts && (
        <ResponsivePagers
          currentPage={currentPage}
          getLink={(page) => {
            return getCategoryProductsUrl(
              productCategoryConfig,
              page,
              searchParams
            );
          }}
          totalPages={Math.ceil(allProducts.length / 20)}
        />
      )}
    </div>
  );
};

/**
 * A block of formatted product stats for the product card.
 */
const ProductStats = ({ product }: { product: Product }) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const displayAttributes = {
    tvs: [
      "screenSize",
      "panelFamily",
      "operatingSystem",
      "dimensionsWithoutStand",
      "dimensionsWithStand",
    ],
    smartphones: [
      "screenSize",
      "resolution",
      "batteryLife",
      "ram",
      "cameraScore",
    ],
    laptops: ["screenSize", "resolution", "ram", "storage", "processor"],
    headphones: [
      "soundQualityRescaled",
      "batteryLife",
      "activeNoiseCancelling",
    ],
    tablets: [
      "size",
      "resolution",
      "ppi",
      "frontCameraResolution",
      "yearReleased",
    ],
    monitors: [
      "screenSize",
      "resolution",
      "panelFamily",
      "screenSubtype",
      "refreshRate",
    ],
  };

  const categoryAttributes = displayAttributes[productCategoryConfig.name];

  if (!categoryAttributes) {
    return null;
  }

  return (
    <>
      <ul className="text-xs text-left flex flex-col gap-1">
        {categoryAttributes.map((attribute) => (
          <li key={attribute}>
            <span className="text-gray-500 font-normal">
              {
                productCategoryConfig.attributeConfiguration[attribute]
                  ?.displayName
              }
              :{" "}
            </span>
            <span className="font-semibold">
              <AttributeValue
                attribute={
                  productCategoryConfig.attributeConfiguration[attribute]
                }
                product={product}
              />
            </span>
          </li>
        ))}
      </ul>
      {productCategoryConfig.name === "laptops" && (
        <div className="mt-auto">
          <div
            className={`small-caps bg-panel-blue-1 pt-[1px] px-1 pb-0.5 rounded-[4px] text-xs text-blue-600 inline-block`}
          >
            Other configurations available
          </div>
        </div>
      )}
    </>
  );
};

/**
 * The list of products in this category.
 */
const ProductsList = ({ products }: { products: Product[] }) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { breakpoint } = useWindowDimensions();
  const [ctaDelta, setCtaDelta] = useState(3);

  const ctaDeltaMap = {
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
  };
  useEffect(
    () => setCtaDelta(ctaDeltaMap[breakpoint]),
    [breakpoint, setCtaDelta]
  );

  return (
    products && (
      <div
        className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        data-testid="product-list-container"
      >
        {products.map((product, i) => {
          return (
            <React.Fragment
              key={i === ctaDelta ? "recommender-cta" : product.id}
            >
              <ProductCardVertical
                product={product}
                productCategory={productCategoryConfig}
                stats={<ProductStats product={product} />}
              />
              {i === ctaDelta &&
                !productCategoryConfig.underConstruction?.recommender && (
                  <RecommenderCtaBlock
                    category={productCategoryConfig}
                    // className={`${i === ctaDelta ? "" : "hidden"}`}
                  />
                )}
            </React.Fragment>
          );
        })}
      </div>
    )
  );
};

/**
 * A wrapper for the bottom filters, similar to the question tray in the recommender.
 */
const BottomTray = ({
  children,
  open,
}: {
  children: React.ReactNode;
  open: boolean;
}) => {
  const positionClass = open ? "translate-y-0" : "translate-y-[100%]";

  return (
    <div
      className={`bg-white sm:hidden w-full rounded-t-xl border-blue-600 border-t md:border-none shadow-blue-600 shadow md:shadow-none z-10 transition-all fixed bottom-0 ${positionClass} py-3 px-6`}
    >
      {children}
    </div>
  );
};

export default CategoryProductsPage;
