import { Checkbox } from "@components/form-element/checkbox";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { usePopper } from "react-popper";

/**
 * A component that displays a dropdown with a checkbox that indicates whether
 * a selection has been made.
 *
 * @TODO - Deal with the typescript ignores here. Headless UI Menu doesn't seem
 * to like refs.
 */
export const CheckboxMenu = ({
  label,
  disabled = false,
  testId,
  value,
  checked = false,
  children,
}: {
  label: string;
  disabled;
  testId: string;
  value: string;
  checked;
  children: React.ReactNode;
}) => {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();

  // This has to be memoized, see https://popper.js.org/react-popper/v2/faq/
  const customModifier = useMemo(
    () => ({
      name: "sameWidth",
      enabled: true,
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
      },
      phase: "beforeWrite",
      requires: ["computeStyles"],
    }),
    []
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "flip",
      },
      // @ts-ignore
      customModifier,
    ],
  });

  return (
    <Menu>
      {({ open }) => (
        <>
          <Menu.Button
            className={`w-full ${checked && "text-blue-600"}`}
            // @ts-ignore
            ref={setReferenceElement}
          >
            <Checkbox
              {...{ label, testId, value, checked }}
              disabled={disabled || open}
              noTab
            >
              <ChevronDownIcon
                className={`h-5 w-5 my-0.5 ${
                  open && "rotate-180"
                } transition-all shrink-0`}
              />
            </Checkbox>
          </Menu.Button>

          <Menu.Items
            className="rounded-lg shadow-md bg-white flex flex-col p-2 z-10"
            as={"div"}
            // @ts-ignore
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {children}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export const CheckboxMenuItem = ({
  text,
  checked = false,
  onClick,
}: {
  text: string;
  checked;
  onClick: () => void;
}) => {
  return (
    <Menu.Item as="button" onClick={onClick}>
      {({ active }) => (
        <div
          className={`${
            active ? "bg-gray-100" : "bg-white"
          } text-left px-3 py-2 hover:bg-gray-100 flex items-center text-sm`}
        >
          <div className="w-5 mr-2">
            {checked && <CheckIcon className="h-5 w-5" />}
          </div>
          {text}
        </div>
      )}
    </Menu.Item>
  );
};
