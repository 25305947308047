import { useMemo } from "react";
import { useGlobalAllProducts } from "./global/use-global-all-products";

const useProductsMaxPrice = (roundInterval = 10000) => {
  const { products } = useGlobalAllProducts();

  const maxPrice = useMemo(() => {
    let max = 0;
    for (const product of products) {
      if (product.bestPrice > max) {
        max = product.bestPrice;
      }
    }

    return max;
  }, [products]);

  return {
    maxPrice,
    maxPriceRounded: Math.ceil(maxPrice / roundInterval) * roundInterval,
  };
};

export default useProductsMaxPrice;
