import AnswerModal from "@components/tray/answer-modal";
import React from "react";

export const Checkbox = (props: {
  value: string;
  onChange?: () => void;
  label: string;
  checked?: boolean;
  explanation?: string;
  inlineExplanation?: string;
  disabled?: boolean;
  testId?: string;
  children?: React.ReactNode;
  noTab?;
  noBorder?;
}) => {
  const borderClass = props.noBorder
    ? ""
    : props.checked
    ? "border border-blue-600 hover:bg-blue-50"
    : "border border-gray-300 hover:bg-gray-50";

  return (
    <div
      className={`justify-between items-center flex cursor-pointer text-sm mb-0 rounded-lg px-2 py-2.5  relative ${borderClass} ${
        props.disabled ? "opacity-50" : ""
      }`}
      onClick={
        props.onChange ? () => !props.disabled && props.onChange() : null
      }
    >
      <div className="flex items-center gap-2">
        <input
          className={`h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 transition duration-200 align-top bg-no-repeat bg-center bg-contain cursor-pointer`}
          type="checkbox"
          value={props.value}
          id={props.value}
          checked={props.checked}
          data-testid={props.testId}
          tabIndex={props.noTab ? -1 : undefined}
          readOnly
        />
        {!props.inlineExplanation ? (
          <span className="leading-none">{props.label}</span>
        ) : (
          <span>
            <strong>{props.label}: </strong>
            {props.inlineExplanation}
          </span>
        )}
      </div>
      {props.children}
      {props.explanation && (
        <div>
          <AnswerModal
            shortName={props.label}
            explanation={props.explanation}
            value={props.value}
          />
        </div>
      )}
    </div>
  );
};

export const CheckboxGrid = (props: {
  children: React.ReactNode;
  className?: string;
  stacked?: boolean;
}) => {
  return (
    <div
      className={`grid gap-2 ${
        props.stacked ? "grid-cols-1" : "grid-cols-2"
      } grid-rows-2 w-full ${props.className || ""}`}
    >
      {props.children}
    </div>
  );
};
