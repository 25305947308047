import CollapsibleSection from "@components/common/collapsible-section";
import { Checkbox } from "@components/form-element/checkbox";
import {
  CheckboxMenu,
  CheckboxMenuItem,
} from "@components/form-element/checkbox-menu";
import Modal from "@components/modal/modal";
import {
  AnswerInputProps,
  getAnswerExplanation,
} from "@components/tray/answer-input";
import AnswerModal from "@components/tray/answer-modal";
import useAnswerConfirmation from "@lib/hooks/use-answer-confirmation";
import Answer from "@lib/model/answer";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { addNonBreakSpace } from "@lib/utilities/client-utilities";
import { getAnswers } from "@lib/utilities/rec-ui";
import { ProductCategoryContext } from "contexts/product-category-context";
import { QuestionIteratorContext } from "contexts/question-iterator-context-provider";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { useContext, useState } from "react";

interface GroupConfig {
  label: string;
  defaultChecked?: boolean;
  subtext?: string;
  subGroups?: AnswerSubGroup[];
  explanation?: React.ReactNode;
}

export interface AnswerSubGroup {
  slug: string;
  mainText: string;
  group: string;
  weight: number;
}

const groups = {
  smartphones: {
    screen: {
      label: "Display Features",
    },
    ports: {
      label: "Ports",
    },
    other: {
      label: "OS & Other",
    },
    brand: {
      label: "Brand",
      subtext: "Get recommendations from specific brands.",
    },
  },
  tvs: {
    display: {
      label: "Display Features",
    },
    broadcast: {
      label: "Broadcast",
    },
    brand: {
      label: "Brand",
      subtext: "Get recommendations from specific brands.",
    },
  },
  laptops: {
    memoryAndStorage: {
      label: "Memory / Storage",
      explanation: (
        <>
          <p>
            Storage determines how much data can be stored on the computer at
            one time. In general, more storage is better. For most users, 256 GB
            of storage can suffice, but at least 512 GB is ideal, especially for
            those who download games and store lots of photos and videos
          </p>
          <p>
            RAM determines how many browser tabs or programs a user can have
            open at one time. In general, more RAM is better. 8 GB RAM suffices
            for general tasks, but 16 GB RAM or more is ideal for all use cases.
          </p>
        </>
      ),
      subGroups: [
        {
          mainText: "Select RAM",
          group: "memoryAndStorage",
          slug: "selectRam",
          weight: 0,
        },
        {
          mainText: "Select Storage",
          group: "memoryAndStorage",
          slug: "selectStorage",
          weight: 1,
        },
      ],
    },
    screen: {
      label: "Screen",
    },
    portsAndBattery: {
      label: "Ports and Battery",
    },
    soundKeyboard: {
      label: "Sound & Keyboard",
    },
    brand: {
      label: "Brand",
      subtext: "Get recommendations from specific brands.",
    },
  },
  headphones: {
    audio: {
      label: "Audio",
    },
    physical: {
      label: "Physical",
    },
    convenience: {
      label: "Convenience",
    },
    brand: {
      label: "Brand",
      subtext: "Get recommendations from specific brands.",
    },
    soundSignature: {
      label: "Sound signature",
      explanation:
        "Most people should leave this blank if you’re not sure about your preferences or prefer a neutral sound signature. If you have a particular preference for the “flavor” of sound you enjoy more, you can use this control to tweak your recommendations to your taste.",
    },
  },
} as {
  [category: string]: {
    [group: string]: GroupConfig;
  };
};

const GroupedFilters = ({
  currentQuestion,
  setStagedResponse,
  stagedResponse,
  submitResponse,
  animatingResponse,
}: AnswerInputProps) => {
  const {
    questionOverrides,
    productCategoryConfig: category,
    activeResponseSet,
  } = useContext(RecUIContext);

  const { getBrand } = useContext(ProductCategoryContext);

  const answers = getAnswers(
    questionOverrides,
    currentQuestion,
    category,
    activeResponseSet
  );
  const {
    getConfirmation,
    confirmActive,
    setConfirmActive,
    confirmingAnswer,
    setConfirmingAnswer,
  } = useAnswerConfirmation();

  const { toggleAnswerInResponse, clearSubGroupInResponse } = useContext(
    QuestionIteratorContext
  );

  /**
   * Toggles an answer and submits the response.
   */
  const toggleAnswerAndSubmit = (answer: Answer) => {
    const newStagedResponse = toggleAnswerInResponse(answer, stagedResponse);

    submitResponse(newStagedResponse);
    setStagedResponse(newStagedResponse);
  };

  /**
   * Clears an answer subgroup and submits the response
   */
  const clearSubGroupAnswersAndSubmit = (subGroup: string) => {
    const newStagedResponse = clearSubGroupInResponse(subGroup, stagedResponse);

    submitResponse(newStagedResponse);
    setStagedResponse(newStagedResponse);
  };

  const getSelectedSubGroupAnswer = (subGroup: string) => {
    const subGroupAnswerIds = currentQuestion.answers
      .filter((answer) => answer.subGroup === subGroup)
      .map((answer) => answer.id);

    const selectedAnswerId = stagedResponse.answerIds.find((answerId) =>
      subGroupAnswerIds.includes(answerId)
    );

    return currentQuestion.answers.find(
      (answer) => answer.id === selectedAnswerId
    );
  };

  /**
   * Determines whether an answer is checked.
   */
  const answerIsChecked = (answer: Answer) => {
    const userChecked = stagedResponse?.answerIds
      ? !!stagedResponse?.answerIds.find(
          (stagedAnswerId) => stagedAnswerId === answer.id
        )
      : false;

    return userChecked;
  };

  /**
   * Change handler for single checkbox. Either it sets the confirmation modal,
   * or directly toggles.
   */
  const handleCheckboxChange = (answer: Answer) => {
    const confirmation = getConfirmation(answer);
    if (confirmation && !answerIsChecked(answer)) {
      // @TODO this can probably just be one state (either there is or is not
      // an answer).
      setConfirmActive(true);
      setConfirmingAnswer(answer);

      eventTracker.track(Events.AnswerConfirmationShown, {
        question_id: currentQuestion.id,
        question_text: currentQuestion.mainText,
        answer_id: answer.id,
        answer_text: answer.mainText,
        confirmation_string: confirmation.trayContent,
        confirmation_variant: confirmation.trayVariant,
      });
    } else {
      toggleAnswerAndSubmit(answer);
    }
  };

  const confirmation = getConfirmation(confirmingAnswer);

  // First group is expanded by default.
  const [expandedGroup, setExpandedGroup] = useState(null);

  /**
   * Click handler for filters section group.
   */
  const handleSectionClick = (groupSlug: string, group: GroupConfig) => {
    setExpandedGroup((prevGroupSlug) => {
      if (prevGroupSlug === groupSlug) {
        return null;
      } else {
        eventTracker.track(Events.FilterCategoryOpen, {
          filter_category_name: group.label,
        });
      }

      return groupSlug;
    });
  };

  /**
   * Determine how many filters have been applied in a group.
   */
  const appliedFiltersInGroup = (groupSlug: string) => {
    if (!stagedResponse?.answerIds?.length || !answers) {
      return 0;
    }

    const appliedInGroup = stagedResponse.answerIds.filter((stagedAnswerId) => {
      const stagedAnswer = answers.find(
        (answer) => answer.id === stagedAnswerId
      );

      return stagedAnswer.group === groupSlug;
    });

    const checkedSubgroups = groups[category.name][groupSlug].subGroups
      ? groups[category.name][groupSlug].subGroups.filter((subGroup) =>
          subGroupHasCheckedChildren(subGroup)
        )
      : [];

    return [...appliedInGroup, ...checkedSubgroups].length;
  };

  /**
   * Determine whether a subgroup has any of its member filters checked.
   */
  const subGroupHasCheckedChildren = (subGroup: AnswerSubGroup) =>
    subGroups[subGroup.slug]?.some((answer) => answerIsChecked(answer));

  /**
   * Gets the formatted string used in the number-of-applied-filters section
   * badge.
   */
  const getFilterBadgeContent = (groupSlug: string) => {
    const appliedInGroup = appliedFiltersInGroup(groupSlug);
    return appliedInGroup > 0 ? `${appliedInGroup} applied` : null;
  };

  /**
   * Get all basic answer checkboxes and filter subgroups that need to display in
   * a certain group.
   */
  const getGroupFiltersBySlug = (
    groupSlug: string
  ): (AnswerSubGroup | Answer)[] => {
    return [
      ...answers,
      ...(groups?.[category.name]?.[groupSlug]?.subGroups || []),
    ]
      .filter(
        (answerOrSubGroup) =>
          answerOrSubGroup.group && answerOrSubGroup.group === groupSlug
      )
      .sort((a, b) => a.weight - b.weight);
  };

  const nonEmptyGroups = Object.entries(groups[category.name]).filter(
    ([groupSlug]) => getGroupFiltersBySlug(groupSlug)?.length > 0
  );

  // Aggregate answers that have children.
  const subGroups = {};
  answers.forEach((answer) => {
    if (answer.subGroup) {
      if (!subGroups[answer.subGroup]) {
        subGroups[answer.subGroup] = [];
      }
      subGroups[answer.subGroup].push(answer);
    }
  });

  /**
   * Gets the explanation for an answer, given that it could be a brand.
   */
  const getCheckboxAnswerExplanation = (answer: Answer) => {
    if (answer.group !== "brand") {
      return getAnswerExplanation(
        category.name,
        answer,
        currentQuestion.id,
        activeResponseSet
      );
    }

    const brand = getBrand(answer.mainText);
    return brand ? brand.description : answer.explanation;
  };

  return (
    <>
      <div>
        {nonEmptyGroups.map(([groupSlug, group], i) => (
          <CollapsibleSection
            key={groupSlug}
            heading={
              <SectionHeading
                title={group.label}
                expanded={expandedGroup === groupSlug}
                subtext={group.subtext}
                badgeContent={getFilterBadgeContent(groupSlug)}
                explanation={group.explanation}
                trackingId={groupSlug}
              />
            }
            onClick={() => handleSectionClick(groupSlug, group)}
            controlledExpanded={expandedGroup === groupSlug}
            isLast={i === Object.values(groups[category.name]).length - 1}
          >
            <div className="grid grid-cols-2 gap-2 py-2 max-h-[184px] sm:max-h-[300px] overflow-y-auto">
              <>
                {getGroupFiltersBySlug(groupSlug).map((answerOrSubgroup) => {
                  const subGroup = answerOrSubgroup as AnswerSubGroup;
                  const answer = answerOrSubgroup as Answer;

                  if (subGroups[subGroup.slug]) {
                    const empty = !subGroupHasCheckedChildren(subGroup);

                    return (
                      <CheckboxMenu
                        label={
                          empty
                            ? addNonBreakSpace(subGroup.mainText, 2, 7)
                            : getSelectedSubGroupAnswer(subGroup.slug)?.mainText
                        }
                        value={subGroup.slug}
                        checked={!empty}
                        key={subGroup.slug}
                        testId={`answer-subgroup-${subGroup.slug}`}
                        disabled={animatingResponse}
                      >
                        <CheckboxMenuItem
                          text={"Any"}
                          checked={empty}
                          onClick={() =>
                            clearSubGroupAnswersAndSubmit(subGroup.slug)
                          }
                        />
                        {subGroups[subGroup.slug].map((answer) => (
                          <CheckboxMenuItem
                            key={answer.id}
                            text={answer.mainText}
                            checked={answerIsChecked(answer)}
                            onClick={() => handleCheckboxChange(answer)}
                          />
                        ))}
                      </CheckboxMenu>
                    );
                  } else {
                    return (
                      <Checkbox
                        label={addNonBreakSpace(answer.mainText, 2, 7)}
                        value={answer.id}
                        explanation={getCheckboxAnswerExplanation(answer)}
                        checked={answerIsChecked(answer)}
                        key={answer.id}
                        testId={`answer-${answer.id}`}
                        disabled={animatingResponse}
                        onChange={() => handleCheckboxChange(answer)}
                      />
                    );
                  }
                })}
              </>
            </div>
          </CollapsibleSection>
        ))}
      </div>
      {confirmation && (
        <Modal
          onClose={() => {
            setConfirmActive(false);
          }}
          onSubmit={() => {
            setConfirmActive(false);
            toggleAnswerAndSubmit(confirmingAnswer);
          }}
          closeButtonText={confirmation.textConfig?.leftButton ?? "Cancel"}
          linkButtonText={
            confirmation.textConfig?.rightButton ?? "Okay, got it"
          }
          isConfirmation={true}
          title={confirmation.textConfig?.header ?? "Important Note"}
          modalOpen={confirmActive}
        >
          <div className="whitespace-pre-wrap">{confirmation.trayContent}</div>
        </Modal>
      )}
    </>
  );
};

/**
 * A component to nest inside of CollapsibleSection components, containing a status
 * badge and explainer, possibly.
 */
const SectionHeading = ({
  title,
  expanded,
  subtext,
  badgeContent,
  explanation,
  trackingId,
}: {
  title: string;
  expanded: boolean;
  subtext?: string;
  badgeContent?: string;
  explanation?: React.ReactNode;
  trackingId;
}) => {
  return (
    <div className="flex justify-between items-center w-full gap-1">
      <div>
        <div className="flex">
          {title}{" "}
          {explanation && (
            <AnswerModal
              shortName={title}
              explanationJsx={explanation}
              value={trackingId}
            />
          )}
        </div>

        {subtext && expanded && (
          <div className="text-gray-500 font-normal text-sm sm:text-base">
            {subtext}
          </div>
        )}
      </div>
      {badgeContent && (
        <div className="bg-blue-600 text-white px-2 rounded-lg text-xs flex items-center max-h-6 shrink-0">
          {badgeContent}
        </div>
      )}
    </div>
  );
};

export default GroupedFilters;
