const RankIcon = (props: {
  handleClick?: () => void;
  isSelected?: boolean;
  className?: string;
  rank: number;
}) => {
  return (
    <div
      className={`cursor-pointer select-none ${props.className || ""}`}
      onClick={props.handleClick}
    >
      <div
        className={`flex justify-center items-center leading-zero border rounded-[50%] w-7 h-7 ${
          props.isSelected
            ? "border-blue-600 bg-blue-600 text-white"
            : "border-gray-500 bg-white text-gray-900"
        }`}
      >
        <div className="text-xs leading-zero font-sans font-bold">
          {props.rank}
        </div>
      </div>
    </div>
  );
};

export default RankIcon;
