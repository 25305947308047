export const enum AttributeType {
  Rating = "rating",
  Fact = "fact",
}

export const enum InputType {
  MultipleChoice = "MultipleChoice",
  SingleChoice = "SingleChoice",
  SingleChoiceExtended = "SingleChoiceExtended",
  ScaleChoice = "ScaleChoice",
  GroupedFilters = "GroupedFilters",
}

export const enum ScaleType {
  OftenScale = "OftenScale",
  MattersScale = "MattersScale",
  PriceToPerformanceScale = "PriceToPerformanceScale",
  TvPictureScale = "TvPictureScale",
}

export const enum QuestionType {
  Static = "STATIC",
  Model = "MODEL",
  Feedback = "FEEDBACK",
}

export enum FeatureUnits {
  Inch = "Inch",
  Feet = "Feet",
  Score10 = "Score10",
  Score100 = "Score100",
  Count = "Count",
  Hz = "Hz",
  None = "None",
  GB = "GB",
  Lbs = "Lbs",
  Hours = "Hours",
  Watts = "Watts",
  Wh = "Wh",
  mAh = "mAh",
  Times = "Times",
  Grams = "Grams",
  Oz = "Oz",
  Months = "Months",
  Years = "Years",
  Nits = "Nits",
  MP = "MP",
  Degrees = "Degrees",
  PPI = "PPI",
  Percentage = "Percentage",
  Ms = "Ms",
  Bit = "Bit",
  ContrastRatio = "ContrastRatio",
  Celsius = "Celsius",
}

export const enum Manufacturer {
  Apple = "Apple",
  NonApple = "NonApple",
  Any = "Any",
}

export const enum RecUiForwardOp {
  None = "None",
  Skip = "Skip",
  Next = "Next",
}

export const enum RecAnimateConfState {
  None = "None",
  Stay = "Stay",
  Change = "Change",
  Enter = "Enter",
}

export const enum RecAnimateCardState {
  Stay = "Stay",
  MoveUp = "MoveUp",
  MoveDown = "MoveDown",
  Enter = "Enter",
}

export const enum RecAnimateDelays {
  /**
   * The time it takes for the confidence meter reveal animation.
   */
  ConfidenceReveal = 200,

  /**
   * The time it takes to animate the confidence meter and the number change.
   */
  ConfidenceChange = 600,

  /**
   * The time between the end of one card's confidence animation, and the beginning
   * of the next.
   */
  ConfidencePause = 200,

  /**
   * The length of time it takes for cards to change positions.
   */
  CardAnimate = 200,

  /**
   * An delay between when the confidence meter is revealed, and when the
   * change animations start.
   */
  CardAnimatePause = 0,
}
