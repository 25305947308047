import { AttributeConfiguration } from "../../../data/packages/model/src/productAttribute";
import {
  AdjustmentsVerticalIcon,
  ArrowsRightLeftIcon,
  Battery100Icon,
  CameraIcon,
  Cog6ToothIcon,
  CpuChipIcon,
  DevicePhoneMobileIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  SpeakerWaveIcon,
  TvIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import {
  AssetType,
  centsToDollars,
  getImageUrl,
  getProductLabels,
  getProductLandingPageUrl,
  getRecommenderUrl,
  getUnderConstruction,
} from "@lib/utilities/shared-utilities";
import { Product } from "@lib/model/product";
import { FeatureUnits } from "@lib/model/enum";
import { getUnitsSuffix } from "@lib/utilities/rec-ui";
import {
  getAspectIcon,
  getAspectLabel,
  getBestProductIds,
  getCompareSummaryRows,
  getDisplayValue,
} from "./compare-fns";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";
import React, { useContext, useMemo, useState } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import ReactDOMServer from "react-dom/server";
import {
  ArrowRightCircleIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/outline";

import useModal from "@lib/hooks/use-modal";
import ProductFeatureModal from "@components/product/product-feature-modal";
import Modal from "@components/modal/modal";
import PurchaseData from "@components/product/purchase-data";
import ImageWithFallback from "@components/common/image-with-fallback";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { GenericPathContext } from "@pages/[...path]";
import { ModalContext } from "@components/modal/modal-wrapper";
import { usePurchaseData } from "@lib/hooks/use-purchase-data";
import { useBetterProduct } from "@lib/hooks/use-better-product";
import eventTracker from "@lib/tracking/event-tracker";
import { ArcScoreDisplay } from "@components/product/summary/common";
import { getMeetsNeedsColor } from "@components/recommendation/done-panel";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { TextButton } from "@components/common/button";

/**
 * Renders the units for a product attribute value.
 */
export const UnitSuffix = ({
  units,
  product,
  attribute,
  inWords = false,
}: {
  units: FeatureUnits;
  product: Product;
  attribute: string;
  inWords?: boolean;
}) => {
  const suffix = getUnitsSuffix(
    units,
    product.attributes[attribute]?.value,
    inWords
  );

  switch (units) {
    case FeatureUnits.Score10:
      return <span className="text-xs font-normal">/10</span>;
    case FeatureUnits.Score100:
      return <span className="text-xs font-normal">/100</span>;
    default:
      return <>{suffix}</>;
  }
};

/**
 * Displays the icon for an attribute group.
 *
 * @TODO - this returns JSX. Make it a real component with props object as the arg
 *         and a proper name.
 */
const getGroupIcon = (group: string) => {
  const attributes = { className: "inline h-4 w-4 shrink-0 mr-1 mb-1" };
  switch (group) {
    case "SCREEN":
      return <DevicePhoneMobileIcon {...attributes} />;
    case "BATTERY":
      return <Battery100Icon {...attributes} />;
    case "CAMERA":
      return <CameraIcon {...attributes} />;
    case "INTERNAL":
      return <CpuChipIcon {...attributes} />;
    case "CUSTOM":
      return <WrenchScrewdriverIcon {...attributes} />;
    case "TV FEATURES":
      return <Cog6ToothIcon {...attributes} />;
    case "DISPLAY QUALITY SCORES":
      return <AdjustmentsVerticalIcon {...attributes} />;
    case "DISPLAY":
      return <PresentationChartBarIcon {...attributes} />;
    case "SOUND":
      return <SpeakerWaveIcon {...attributes} />;
    case "PHYSICAL":
      return <TvIcon {...attributes} />;
  }
};

/**
 * Component to display the formatted value of an attribute
 */
export const AttributeValue = (props: {
  attribute: AttributeConfiguration;
  product: Product;
}) => {
  const { attribute, product } = props;
  if (!attribute || !product) {
    return null;
  }
  const { slug, unit } = attribute;

  const value = getDisplayValue(attribute, product);

  if (!value && attribute.emptyVal) return <>{attribute.emptyVal}</>;
  else if (value === "N/A" || value === "Unknown") return <>{value}</>;
  else if (value || value === 0) {
    return (
      <>
        {value}
        <UnitSuffix
          units={FeatureUnits[unit]}
          product={product}
          attribute={slug}
        />
      </>
    );
  }

  return null;
};

/**
 * The name of an attribute and its explainer icon/modal.
 */
export const ProductAttributeRow = ({
  configuration,
  products,
  iconClassname = "text-gray-400",
}: {
  configuration: AttributeConfiguration;
  products: Product[];
  iconClassname?: string;
}) => {
  const [modalOpen, setModalOpen, toggleModal] = useModal();
  const genericPathContext = useContext(GenericPathContext);
  const isProductSeoPage = ["product-landing-page", "vs-page"].includes(
    genericPathContext?.pageType
  );

  const modalContext = useContext(ModalContext);

  const trackModalOpen = () => {
    EventTracker.track(Events.ModalOpen, {
      explanationType: `${configuration.displayName} Explanation`,
    });
  };

  const openFeatureModal = (e) => {
    e.stopPropagation();
    setModalOpen(true);
    trackModalOpen();
  };

  const { productCategoryConfig, getProductBrands } = useContext(
    ProductCategoryContext
  );

  const displayName =
    productCategoryConfig.name === "headphones" &&
    products.length === 1 &&
    configuration.slug === "weight"
      ? products[0].attributes["formFactor"].value === "Headphones"
        ? "Weight"
        : "Weight of single earbud"
      : configuration.displayName;

  const brands = useMemo(() => {
    if (configuration.slug === "brand") {
      const brands = getProductBrands(products);

      if (brands.length === 0) {
        return [];
      }

      return brands;
    }
    return [];
  }, [getProductBrands, configuration, products]);

  // Build manufacturer description out of each product's brand data.
  const manufacturerDescription =
    brands.length > 0 ? (
      <>
        {brands.map((brand) => (
          <React.Fragment key={brand.label.toLowerCase()}>
            <strong>{brand.label}</strong>
            <p>{brand.description}</p>
          </React.Fragment>
        ))}
      </>
    ) : null;

  // Don't show brand explainer on product landing pages, unless it is wrapped in
  // a modal. For instance, we do want the brand explainer to show on view-stats
  // modals spawned from dots on the chart.
  const showExplainer =
    (manufacturerDescription && (!isProductSeoPage || modalContext?.isModal)) ||
    configuration.explanation;

  const showBrand =
    configuration?.name?.toLowerCase().includes("rescaled") ||
    configuration?.name?.toLowerCase().includes("overall");

  return (
    <div className={`flex items-center gap-2 lg:pr-2`}>
      {showExplainer && (
        <>
          <QuestionMarkCircleIcon
            className={`cursor-pointer h-5 inline flex-shrink-0 ${iconClassname}`}
            tabIndex={0}
            onClick={(e) => {
              openFeatureModal(e);
            }}
          />
          <ProductFeatureModal
            open={modalOpen}
            onClose={toggleModal}
            name={configuration.displayName}
            jsxText={manufacturerDescription || undefined}
            text={
              manufacturerDescription ? undefined : configuration.explanation
            }
            image={configuration.imageName || configuration.image}
            imageAlt={configuration.imageAlt}
          />
        </>
      )}
      <span
        className={`${!showExplainer && "lg:pl-7"} ${
          showExplainer && !showBrand && "lg:pl-0"
        }`}
      >
        {displayName}
      </span>
      {showBrand && (
        <div
          onClick={(e) => {
            openFeatureModal(e);
          }}
          className={`${!showExplainer && "pl-0"} ${
            genericPathContext?.pageType === "vs-page" && "hidden"
          }  lg:block shrink-0`}
        >
          <ImageWithFallback
            className={`h-5 w-5 ${showExplainer && "cursor-pointer"}`}
            alt="PerfectRec logo"
            width={5}
            src={getImageUrl("perfectrec-icon.png", AssetType.Image)}
          />
        </div>
      )}
    </div>
  );
};

/**
 * The title of a group of attributes.
 */
export const AttributeGroup = ({
  groupTitle,
  className = "",
  innerClassName = "",
}: {
  groupTitle: string;
  className?: string;
  innerClassName?: string;
}) => {
  return (
    <tr className={`${className}`}>
      <th
        colSpan={4}
        scope="colgroup"
        className={`py-3 text-left ${innerClassName}`}
      >
        <h3 className="font-sans text-base">
          {getGroupIcon(groupTitle)}
          {groupTitle}
        </h3>
      </th>
    </tr>
  );
};

const getPadding = (index) => {
  const padding = index === 0 ? "px-3" : "px-5";
  return padding;
};

/**
 * The set of rows needed to display price on compare or single stat tables.
 */
export const PriceRow = ({
  products,
  className,
  innerClassName,
}: {
  products: Product[];
  className?: string;
  innerClassName?: string;
}) => {
  const displayPrices = products.map((product) =>
    centsToDollars(product.bestPrice)
  );

  if (displayPrices?.length !== products.length) {
    return null;
  }

  return (
    <>
      <tr className={`${products.length > 1 ? "lg:hidden" : "hidden"}`}>
        <th
          colSpan={4}
          scope="colgroup"
          className={`pt-2 text-left font-bold ${innerClassName}`}
        >
          Price
        </th>
      </tr>
      <tr data-testid="compare-price-row" className="border-b">
        <th
          className={` ${
            products.length > 1
              ? `hidden lg:text-left lg:table-cell ${innerClassName}`
              : `text-left table-cell ${innerClassName}`
          } lg:pl-10`}
        >
          Price
        </th>
        {displayPrices.map((displayPrice, i) => (
          <td
            key={i}
            className={` pb-2 ${
              products.length == 1 && "text-left"
            } lg:py-3 ${innerClassName} ${getPadding(i)}`}
          >
            <div>{displayPrice}</div>
            <PricingModal product={products[i]} />
          </td>
        ))}
      </tr>
    </>
  );
};

/**
 * A button that makes a modal appear with pricing information in it.
 */
const PricingModal = ({ product }: { product: Product }) => {
  const [open, setOpen] = useState(false);

  const { purchaseData } = usePurchaseData(product);

  if (!purchaseData?.length) {
    return null;
  }

  return (
    <>
      <TextButton onClick={() => setOpen(true)}>
        Shop
        <ArrowSmallRightIcon className="h-5" />
      </TextButton>

      <Modal
        onClose={() => setOpen(false)}
        modalOpen={open}
        title={getProductLabels(product).shortLabel}
        omitBottomButtons
      >
        <PurchaseData showLearnMoreModal product={product} />
      </Modal>
    </>
  );
};

/**
 * A list of table cells that hold the values for a product row.
 */
export const DisplayValues = ({
  products,
  configuration,
  highlightBest,
  className = "",
}: {
  products: Product[];
  configuration: AttributeConfiguration;
  className?: string;
  highlightBest?: boolean;
}) => {
  // This varies because at small breakpoints, the row label is a separate row,
  // rather than a column. When the label is a column to the left, we try to
  // leave at least 20% for it. We want a fixed ratio for the product columns
  // so that the layout doesn't shift when someone changes the product selector.
  const lgWidth = (index) => {
    if (products.length === 2) {
      if (index === 0) {
        return "lg:w-[340px]";
      } else {
        return "lg:w-[39%]";
      }
    } else {
      return "lg:w-[26.6%]";
    }
  };
  const width = products.length === 2 ? "w-1/2" : "w-1/3";

  const bestProductIds = getBestProductIds(products, configuration);

  return (
    <>
      {products.map((product, index) => (
        <td
          key={index}
          className={`pb-2 pr-2 lg:py-2 ${width} ${lgWidth(
            index
          )} ${className} ${getPadding(index)}`}
        >
          <span
            className={
              highlightBest && bestProductIds.includes(product.id)
                ? "text-panel-green-line font-semibold"
                : undefined
            }
          >
            <AttributeValue attribute={configuration} product={product} />
          </span>
        </td>
      ))}
    </>
  );
};

/**
 * A smaller compare header used on VS pages and the done page.
 */
export const CompareHeaderSmall = ({
  products,
  category,
}: {
  products: Product[];
  category: string;
}) => {
  const lgWidth = (index) => {
    if (products.length === 2) {
      if (index === 0) {
        return "lg:w-[340px]";
      } else {
        return "lg:w-[39%]";
      }
    } else {
      return "lg:w-[26.6%]";
    }
  };

  const width = products.length === 2 ? "w-1/2" : "w-1/3";
  return (
    <>
      <tr>
        <td className="hidden lg:table-cell" />
        {products.map((product, i) => (
          <td
            key={product.id + i}
            className={`${width} ${lgWidth(i)} pr-2 ${getPadding(i)}`}
          >
            <ImageWithFallback
              src={getImageUrl(
                product.image,
                AssetType.ProductImage,
                { height: 48 },
                category
              )}
              height={48}
              alt={getProductLabels(product).shortLabel}
              className={`object-contain max-h-12`}
            />
          </td>
        ))}
      </tr>
      <tr>
        <td className="hidden lg:table-cell" />
        {products.map((product, i) => (
          <td
            key={product.id + i}
            className={`${width} ${lgWidth(
              i
            )} align-top pb-3 font-bold text-sm ${i < 2 && "pr-2"} ${getPadding(
              i
            )} lg:px-0`}
          >
            {getProductLabels(product).shortLabel}
          </td>
        ))}
      </tr>
    </>
  );
};

/**
 * A commonly used "spec comparisons" heading.
 */
export const ComparisonHeading = () => {
  return (
    <h2
      className="text-lg font-bold text-center font-sans mt-0 mb-4"
      id="spec-comparison"
    >
      Spec Comparison
    </h2>
  );
};

/**
 * A small badge used to display a "winner" product or a tie.
 *
 * Note - the pseudo-element nonsense here is for the benefit of search engine
 * SERP blocks.
 */
export const VsDisposition = ({
  winner,
  products,
  serp = false,
}: {
  serp?;
  products?: Product[];
  winner: Product | "tie";
}) => {
  const { productVariants } = useGlobalAllProducts();
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const winnerVariant =
    winner !== "tie" &&
    productCategoryConfig.hasVariants &&
    productCategoryConfig.displayVariantsInTables &&
    productVariants
      ?.find((variant) => variant.id === winner.id)
      .variantLabel.replace(";", ",");

  return (
    <>
      {winner === "tie" ? (
        <span>
          {!serp && (
            <ArrowsRightLeftIcon className="w-[16px] h-[16px] inline" />
          )}{" "}
          Roughly equal
        </span>
      ) : (
        <span>
          <strong
            className="before:content-[var(--image-before)] before:mr-2 before:absolute before:left-0 relative pl-5"
            style={
              {
                "--image-before": `url(${getImageUrl(
                  winner.image,
                  AssetType.ProductImage,
                  {
                    width: 16,
                  },
                  winner.metadata.categoryName
                )})`,
              } as React.CSSProperties
            }
          >
            <span className="pr-1">{getProductLabels(winner).shortLabel}</span>
            {winnerVariant && (
              <span className="text-gray-500">({winnerVariant})</span>
            )}
          </strong>{" "}
          {!serp && "wins"}
        </span>
      )}
    </>
  );
};

/**
 * A block that shows which product is better for a set of properties.
 *
 * Note - the pseudo-element nonsense here is for the benefit of search engine
 * SERP blocks.
 */
export const ComparisonSummary = ({
  attributes: { exact, fuzzy = [] },
  products,
}: {
  attributes: { exact: string[]; fuzzy?: string[] };
  products: Product[];
}) => {
  const { attributes } = useAttributes();
  const { productCategoryConfig: category } = useContext(
    ProductCategoryContext
  );

  const summaryRows = getCompareSummaryRows(
    products,
    category,
    attributes,
    exact,
    fuzzy
  );

  return (
    <div className="border border-gray-500 rounded-lg overflow-hidden my-2 sm:my-4">
      <table className="w-full border-collapse table-fixed">
        <colgroup>
          <col span={1} className="w-1/2" />
          <col span={1} className="w-1/2" />
        </colgroup>
        <thead>
          <tr>
            <th className="p-3 font-bold bg-blue-50" colSpan={2}>
              Comparison Summary
            </th>
          </tr>
        </thead>

        <tbody>
          {summaryRows.map(({ winner, attribute }) => (
            <ComparisonSummaryRow
              products={products}
              key={attribute.slug}
              {...{ winner, attribute }}
              category={category.name}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

/**
 * One row in the comparison summary.
 */
const ComparisonSummaryRow = ({
  attribute,
  winner,
  category,
  products,
}: {
  attribute: AttributeConfiguration;
  winner: Product | "tie";
  category: string;
  products: Product[];
}) => {
  const Icon = getAspectIcon(category, attribute.slug);

  // The point of this is to not confuse Google SERP finder with stray image
  // markup.
  const iconString = Icon ? ReactDOMServer.renderToString(<Icon />) : null;

  return (
    <tr className=" border-t border-gray-500">
      <td className="p-2 small-caps font-bold flex gap-1">
        <span
          className="before:content-[var(--image-before)] pl-5 before:absolute before:left-0 relative before:w-4 before:h-4 before:pt-1"
          style={
            iconString
              ? ({
                  "--image-before": `url(data:image/svg+xml,${encodeURIComponent(
                    iconString
                  )})`,
                } as React.CSSProperties)
              : {}
          }
        >
          {getAspectLabel(category, attribute.slug)}
        </span>
      </td>
      <td className="p-2 border-l border-gray-500 text-xs">
        <VsDisposition winner={winner} products={products} serp />
      </td>
    </tr>
  );
};

export const CompareCtaRow = ({
  heading,
  n,
}: {
  heading: string;
  n: number;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const href = getUnderConstruction(productCategoryConfig, "landingPages")
    ? undefined
    : getRecommenderUrl(productCategoryConfig);

  const name =
    productCategoryConfig.name === "headphones"
      ? productCategoryConfig.name
      : productCategoryConfig.noun;

  return (
    <>
      <tr className="border-b lg:border-t bg-blue-600 text-white">
        <th colSpan={1} className="font-bold lg:hidden lg:pr-10 py-3 px-3">
          {heading}
        </th>
        <th colSpan={2} className="hidden lg:table-cell text-left lg:pr-2 px-3">
          {heading}
        </th>
        <td colSpan={2} className="px-3 lg:pr-24 lg:pl-0">
          <a
            href={href}
            onClick={() =>
              EventTracker.track(Events.VsPageGetRecsClick, {
                category: productCategoryConfig.name,
                identifier: `row ${n} cta`,
              })
            }
            className="border flex gap-2 items-center justify-center max-w-[250px] border-blue-500 py-1.5 px-3 sm:px-4 rounded-lg text-md my-2 text-blue-700 font-semibold m-auto lg:mx-0 bg-white"
          >
            Find your new&nbsp;{name}
            <ArrowRightCircleIcon className="h-4 shrink-0" />
          </a>
        </td>
      </tr>
    </>
  );
};

export const BetterProductCta = ({
  products,
  attributeName,
}: {
  products: Product[];
  attributeName: string;
}) => {
  const { betterProduct } = useBetterProduct(products, attributeName);

  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const attributeConfiguration = useAttribute(attributeName);

  if (!betterProduct) {
    return null;
  }

  const maxScore = Math.max(
    ...products.map((product) =>
      parseFloat(product?.attributes?.[attributeName]?.value)
    ),
    0
  );

  const even = maxScore >= 7.0 ? " even" : "";

  const betterVerb = [
    "buildQualityRescaled",
    "buildQuality",
    "batteryRating",
    "screenScore",
    "cameraScore",
  ].includes(attributeName)
    ? `has${even} better`
    : ["pictureScore", "videoScore"].includes(attributeName)
    ? `takes${even} better`
    : `is${even} better for`;

  const display = ["batteryRating"].includes(attributeName)
    ? "Battery Life"
    : attributeConfiguration.displayName;

  return (
    <>
      <span className="text-sm mt-2 font-normal my-2">
        The{" "}
        <a
          className="cursor-pointer hover:underline text-blue-600"
          href={getProductLandingPageUrl(betterProduct, productCategoryConfig)}
          onClick={() => {
            eventTracker.track(Events.EvenBetterProductCtaClick, {
              products: products.map((product) => product.slug),
              attribute: attributeName,
              better_product: betterProduct.slug,
            });
          }}
        >
          {getProductLabels(betterProduct).shortLabel} (
          {centsToDollars(betterProduct.bestPrice)}){" "}
        </a>
      </span>
      <span className="text-sm mt-2 font-normal my-2">
        {betterVerb}{" "}
        {display
          .toLowerCase()
          .replace(" score", "")
          .replace("tv", "TV")
          .replace("ai", "AI")}
        .{" "}
      </span>
    </>
  );
};

export const CompareCardScoreDisplay = ({
  productA,
  productB,
  attribute,
  categoryName,
  attributeMax,
  meetsNeedsA,
  meetsNeedsB,
  showUnits,
  showAdjective,
}: {
  productA: Product;
  productB: Product;
  attribute: AttributeConfiguration;
  categoryName: string;
  attributeMax?: number;
  meetsNeedsA?: boolean;
  meetsNeedsB?: boolean;
  showUnits?: boolean;
  showAdjective?: boolean;
}) => {
  const scoreA = attribute.slug.includes("battery")
    ? parseFloat(productA.attributes["batteryRating"].value)
    : parseFloat(productA.attributes[attribute.slug].value);
  const scoreB = attribute.slug.includes("battery")
    ? parseFloat(productB.attributes["batteryRating"].value)
    : parseFloat(productB.attributes[attribute.slug].value);

  const max = attribute.slug.includes("battery") ? 10 : attributeMax || 10;

  return (
    <div className="flex flex-row justify-between mt-2">
      <div className="flex flex-row justify-center items-center gap-2 lg:gap-6">
        <ArcScoreDisplay
          product={productA}
          attribute={attribute}
          score={scoreA}
          icon={getAspectIcon(categoryName, attribute.slug)}
          color={
            meetsNeedsA !== null && meetsNeedsA !== undefined
              ? getMeetsNeedsColor(meetsNeedsA)
              : getMeetsNeedsColor(scoreA >= 7)
          }
          type="scoreBelow"
          max={max}
          showUnits={showUnits}
          showAdjective={showAdjective}
        />
        <div className="flex flex-col justify-center items-center ">
          <div className="relative flex justify-center w-12 h-12">
            <ImageWithFallback
              src={getImageUrl(
                productA.image,
                AssetType.ProductImage,
                { width: 150 },
                categoryName
              )}
              alt={productA.model}
              className={`object-contain`}
            />
          </div>
          <div className="h-[2px w-[50px] border-b-[2px] mt-2 border-accent-purple" />
        </div>
      </div>
      <div className="flex flex-row justify-center items-center gap-2 lg:gap-6">
        <div className="flex flex-col justify-center items-center">
          <div className="relative flex justify-center w-12 h-12">
            <ImageWithFallback
              src={getImageUrl(
                productB.image,
                AssetType.ProductImage,
                { width: 150 },
                categoryName
              )}
              alt={productB.model}
              className={`object-contain`}
            />
          </div>
          <div className="h-[2px] w-[50px] border-b-[2px] mt-2 border-accent-orange" />
        </div>
        <ArcScoreDisplay
          product={productB}
          attribute={attribute}
          score={scoreB}
          icon={getAspectIcon(categoryName, attribute.slug)}
          color={
            meetsNeedsB !== null && meetsNeedsB !== undefined
              ? getMeetsNeedsColor(meetsNeedsB)
              : getMeetsNeedsColor(scoreB >= 7)
          }
          type="scoreBelow"
          max={max}
          showUnits={showUnits}
          showAdjective={showAdjective}
        />
      </div>
    </div>
  );
};

export const ExpectedReleaseCard = ({ product }: { product: Product }) => {
  return (
    <StandardCard className="border-none bg-blue-100">
      <div className="text-sm sm:text-base font-bold my-1">
        Expected {product.attributes["releaseDate"].value}
      </div>
      <div className="text-xs sm:text-sm">
        Since the <strong>{getProductLabels(product).shortLabel}</strong>{" "}
        isn&apos;t released yet, some data represents our best estimate because
        the details aren&apos;t available
      </div>
    </StandardCard>
  );
};
