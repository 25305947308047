const ScreenSizeIcon = (props: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 24 24"
    className={`${props.className || "fill-blue-600"}`}
  >
    <path d="M22.5 18.2h-1.4V4.7c0-.5-.4-1-1-1H3.9c-.5 0-1 .4-1 1v13.5H1.5c-.2 0-.3.2-.3.3v1.3c0 .2.2.3.3.3h21c.2 0 .3-.2.3-.3v-1.3c.1-.1-.1-.3-.3-.3zM15.3 7.3V5.9H19v3.8h-1.4V8.2l-1.9 1.9-1-1 1.9-1.9h-1.3zm.5 5.4 1.9 1.9v-1.4H19V17h-3.8v-1.4h1.4l-1.9-1.9 1.1-1zM5 7.3V5.9h3.8v1.4H7.3l1.9 1.9-1 1-1.9-2v1.4H5V7.3zm0 8.3v-2.4h1.4v1.4l1.9-1.9 1 1-1.9 1.9h1.4V17H5v-1.4z" />
  </svg>
);

export { ScreenSizeIcon };
