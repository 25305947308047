import ImageWithFallback from "@components/common/image-with-fallback";
import { SummaryTitles } from "@components/product/summary/common";
import DonePanel from "@components/recommendation/done-panel";
import { useAttribute } from "@lib/hooks/use-attributes";
import { Product } from "@lib/model/product";
import {
  getProductLabels,
  getImageUrl,
  AssetType,
} from "@lib/utilities/shared-utilities";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext } from "react";

const ProductSuitabilityComparison = (props: {
  productA: Product;
  productB: Product;
  keySpecs?: boolean;
  compareResults: any;
  attributeName: string;
}) => {
  const { productA, productB, attributeName } = props;

  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const attribute = useAttribute(attributeName);

  const displayName = attribute.displayName
    .toLowerCase()
    .replace("hdr", "HDR")
    .replace("sdr", "SDR");

  const attributeA = productA.attributes[attributeName].value;
  const attributeB = productB.attributes[attributeName].value;

  const productAName = getProductLabels(productA).shortLabel;
  const productBName = getProductLabels(productB).shortLabel;

  const { winner } = props.compareResults[attributeName];

  let first;

  if (attributeA === "Yes" && attributeB === "Yes") {
    first = `Both the ${productAName} and ${productBName} are ${displayName}.`;
  } else if (attributeA === "Yes" && attributeB === "No") {
    first = `The ${productAName} is ${displayName} while the ${productBName} is not ${displayName}.`;
  } else if (attributeA === "No" && attributeB === "Yes") {
    first = `The ${productAName} is not ${displayName} while the ${productBName} is ${displayName}.`;
  } else {
    first = `Both the ${productAName} and ${productBName} are not ${displayName}.`;
  }

  if (!winner) {
    return null;
  }

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={attribute.displayName.replace("Suitable for ", "")}
      className={"!bg-white"}
      modal={{
        title: attribute.displayName.replace("Suitable for ", ""),
        text: attribute.explanation,
      }}
    >
      <div className="flex flex-row justify-between mt-2">
        <div className="flex flex-row justify-center items-center gap-6">
          <div className="font-serif text-2xl font-bold">{attributeA}</div>
          <div className="flex flex-col justify-center items-center ">
            <div className="relative flex justify-center w-12 h-12">
              <ImageWithFallback
                src={getImageUrl(
                  productA.image,
                  AssetType.ProductImage,
                  { width: 150 },
                  productCategoryConfig.name
                )}
                alt={productA.model}
                className={`object-contain`}
              />
            </div>
            <div className="h-[2px w-[50px] border-b-[2px] mt-2 border-accent-purple" />
          </div>
        </div>
        <div className="flex flex-row justify-center items-center gap-6">
          <div className="flex flex-col justify-center items-center">
            <div className="relative flex justify-center w-12 h-12">
              <ImageWithFallback
                src={getImageUrl(
                  productB.image,
                  AssetType.ProductImage,
                  { width: 150 },
                  productCategoryConfig.name
                )}
                alt={productB.model}
                className={`object-contain`}
              />
            </div>
            <div className="h-[2px] w-[50px] border-b-[2px] mt-2 border-accent-orange" />
          </div>
          <div className="font-serif text-2xl font-bold">{attributeB}</div>
        </div>
      </div>

      <div className="mt-4">
        <SummaryTitles {...{ first }} />
      </div>
    </DonePanel>
  );
};

export default ProductSuitabilityComparison;
