import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import HeatMeter, { Legend } from "./heat-meter";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";
import { getAspectIcon } from "@components/compare/compare-fns";

const LaptopBuildQuality = (props: PanelSetProps) => {
  const { selectedProduct } = props;

  const { attributes } = useAttributes();

  const score = parseFloat(selectedProduct.attributes["buildQuality"].value);

  const title = "Build Quality";
  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(score)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      build quality.
    </>
  );
  const second =
    "PerfectRec’s Build Quality Score incorporates case materials, display and keyboard flex, hinge quality, and overall reliability.";

  const color = getMeetsNeedsColor(score >= 7.0);
  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: "Build Quality",
        text: "PerfectRec’s Build Quality Score incorporates case materials, display and keyboard flex, hinge quality, chassis rigidity, and overall reliability. Our assessment of build quality includes many details that are difficult to quantify. Our laptop experts use their best judgment to produce the final score.",
      }}
    >
      <ArcScoreDisplay
        product={props.selectedProduct}
        attribute={attributes["buildQuality"]}
        icon={getAspectIcon("laptops", "buildQuality")}
        score={score}
        color={color}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopBuildQuality;
