import { Product } from "@lib/model/product";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import {
  ExplanationAttribute,
  ExplanationConfiguration,
} from "../../../data/packages/model/src/productExplanation";
import { centsToDollars } from "@lib/utilities/shared-utilities";
import { capitalizeFirstLetter } from "./client-utilities";

export function getLetterGrade(score: number) {
  if (String(score) === "N/A") {
    return "N/A";
  } else if (score >= 9.5) {
    return "A+";
  } else if (score >= 9.0) {
    return "A";
  } else if (score >= 8.0) {
    return "A-";
  } else if (score >= 7.0) {
    return "B+";
  } else if (score >= 6.0) {
    return "B";
  } else {
    return "C";
  }
}

export function getQualitativeWord(score: number) {
  if (String(score) === "N/A") {
    return "N/A";
  } else if (score >= 9.5) {
    return "Best in Class";
  } else if (score >= 9.0) {
    return "Excellent";
  } else if (score >= 8.0) {
    return "Very Good";
  } else if (score >= 7.0) {
    return "Good";
  } else if (score >= 6.0) {
    return "Fair";
  } else {
    return "Poor";
  }
}

export function getQualitativeCompareWeight(score: number) {
  if (String(score) === "N/A") {
    return -1;
  } else if (score >= 9.5) {
    return 5;
  } else if (score >= 9.0) {
    return 4;
  } else if (score >= 8.0) {
    return 3;
  } else if (score >= 7.0) {
    return 2;
  } else if (score >= 6.0) {
    return 1;
  } else {
    return 0;
  }
}

export const thicknessThresholds = [0.57, 0.69, 0.84, 0.99, 1000.0];
export const thicknessNames = [
  "very thin",
  "thin",
  "med. thick",
  "thick",
  "very thick",
];

export const weightThresholds = [3.25, 4.09, 4.9, 5.83, 1000.0];
export const weightNames = [
  "very light",
  "light",
  "med. heavy",
  "heavy",
  "very heavy",
];

function getLetterGradeContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  return `${
    explanationConfiguration.displayName
  }: ${explanationConfiguration.attributes
    .map((attribute, index) => {
      if (!attributeMayDisplay(attribute, responseSet)) {
        return "";
      }
      return `${
        attribute.displayName === "" ? "" : attribute.displayName + ":\u00a0"
      }${getLetterGrade(product.attributes[attribute.slug].value)}`;
    })
    .filter((content) => content != "")
    .join(", ")}`;
}

function attributeMayDisplay(
  attribute: ExplanationConfiguration["attributes"][0],
  responseSet: ResponseSetWithResponses
) {
  if (attribute?.showWhenAnswered) {
    const response = responseSet.responses.find((response) =>
      response.answerIds?.some((answerId) =>
        attribute.showWhenAnswered?.includes(answerId)
      )
    );
    if (!response) {
      return false;
    }
  }

  return true;
}

function getQualitativeWordContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  return `${
    explanationConfiguration.displayName
  }: ${explanationConfiguration.attributes
    .map((attribute, index) => {
      return `${
        attribute.displayName === "" ? "" : attribute.displayName + ": "
      }${getQualitativeWord(product.attributes[attribute.slug].value)}`;
    })
    .join(", ")}`;
}

export function getAndroidAndAppleOptimized(product: Product) {
  return {
    androidOptimized: product.attributes["androidOptimized"].value == "Yes",
    appleOptimized: product.attributes["appleOptimized"].value == "Yes",
  };
}

function getEarbudsSignatureContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const soundProfile = product.attributes["soundProfile"].value;
  return `Signature: ${soundProfile}`;
}

function getAnyScoreBelowThreshold(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  return explanationConfiguration.attributes.some((attribute) => {
    if (attribute?.showWhenAnswered) {
      const response = responseSet.responses.find((response) =>
        response.answerIds?.some((answerId) =>
          attribute.showWhenAnswered?.includes(answerId)
        )
      );
      if (!response) {
        return false;
      }
    }
    return (
      product.attributes[attribute.slug].value === "N/A" ||
      product.attributes[attribute.slug].value <
        explanationConfiguration?.threshold
    );
  });
}

function getEarbudsSignatureUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const isSkip = !responseSet.responses.find(
    (response) =>
      response.questionId === "a8e05cf5-91b7-41cf-a7c3-6486e35682e1" &&
      response.answerIds?.length < 1
  );
  if (isSkip) {
    return false;
  }
  const answers = [
    {
      id: "a0109394-a87c-46b0-81c2-f269203dab60",
      mainText: "Bass",
      weight: 0,
    },
    {
      id: "5d58a8b5-83be-4297-adb0-70a95b01cd4f",
      mainText: "Midrange",
      weight: 1,
    },
    {
      id: "12d4d5ab-83ec-48a3-b61e-f8f8ade18c5c",
      mainText: "Treble",
      weight: 2,
    },
  ] as const;
  const soundProfile = product.attributes["soundProfile"].value;
  const soundProfileList = soundProfile
    .split(",")
    .map((soundProfile) => soundProfile.trim()) as (
    | "Bass"
    | "Midrange"
    | "Treble"
    | "Neutral"
  )[];
  const soundProfileIncludes = answers.filter((answer) =>
    soundProfileList.includes(answer.mainText)
  );
  const soundProfileExcludes = answers.filter(
    (answer) => !soundProfileList.includes(answer.mainText)
  );
  const soundProfileResponsesAll = soundProfileIncludes.every(
    (soundProfile) => {
      return responseSet.responses.find((response) =>
        response.answerIds?.includes(soundProfile.id)
      );
    }
  );

  const soundProfileResponsesNone = soundProfileExcludes.every(
    (soundProfile) => {
      return !responseSet.responses.find((response) =>
        response.answerIds?.includes(soundProfile.id)
      );
    }
  );
  return !soundProfileResponsesAll || !soundProfileResponsesNone;
}

function getLikertQualitativeUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const response = responseSet.responses.find((response) => {
    return response.questionId === explanationConfiguration.questionId;
  });

  if (
    !response ||
    !response.answers ||
    !response.answers[0] ||
    !explanationConfiguration.attributes[0]
  ) {
    return false;
  }

  const likertScore = parseFloat(response.answers[0].mainText);
  const attributeScore =
    product.attributes[explanationConfiguration.attributes[0]["slug"]].value;

  return (
    (likertScore === 4 && attributeScore < 8) ||
    (likertScore === 3 && attributeScore < 7)
  );
}

function getPhoneSizeContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const phoneSize =
    product.attributes[explanationConfiguration.attributes[0]["slug"]].value;
  const phoneOs = product.attributes["operatingSystem"].value;

  const prefix = "Size: ";

  if (phoneOs === "iOS") {
    if (phoneSize < 5.0) {
      return prefix + "Smallest iPhone";
    } else if (phoneSize >= 5.0 && phoneSize < 6.0) {
      return prefix + "Small iPhone";
    } else if (phoneSize >= 6.0 && phoneSize < 6.5) {
      return prefix + "Medium iPhone";
    } else {
      return prefix + "Largest iPhone";
    }
  } else if (phoneOs === "Android") {
    if (phoneSize < 6.0) {
      return prefix + "Smallest Android";
    } else if (phoneSize >= 6.0 && phoneSize < 6.5) {
      return prefix + "Medium Android";
    } else if (phoneSize >= 6.5 && phoneSize < 6.75) {
      return prefix + "Large Android";
    } else {
      return prefix + "Largest Android";
    }
  }
  return prefix + "N/A";
}

function getPhoneSizeUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const response = responseSet.responses.find((response) => {
    return response.questionId === explanationConfiguration.questionId;
  });

  if (
    !response ||
    !response.answers ||
    !response.answers[0] ||
    !explanationConfiguration.attributes[0]
  ) {
    return false;
  }

  const answer = response.answers[0].mainText;
  const screenSize =
    product.attributes[explanationConfiguration.attributes[0]["slug"]].value;

  return (
    (answer.includes("Small") && screenSize > 6.15) ||
    (answer.includes("Medium") && (screenSize > 6.5 || screenSize < 5.85)) ||
    (answer.includes("Large") && screenSize <= 6.4)
  );
}

function getLaptopSizeContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const laptopSize =
    product.attributes[explanationConfiguration.attributes[0]["slug"]].value;

  return "Size: " + laptopSize + '"';
}

function getLaptopSizeUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const laptopSize =
    product?.attributes[explanationConfiguration.attributes[0]["slug"]]?.value;

  const responses = responseSet?.responses?.find((response) => {
    return response.questionId === explanationConfiguration.questionId;
  })?.answers;

  if (!responses) {
    return false;
  }

  const responseRange = responses[0]?.metadata?.modelBin;

  if (!responseRange || !responseRange[0] || !responseRange[1]) {
    return false;
  }

  return laptopSize < responseRange[0] || laptopSize > responseRange[1];
}

function getLaptopPerformanceMean(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const answerIds = responseSet?.responses?.find((response) => {
    return response.questionId === explanationConfiguration.questionId;
  })?.answerIds;

  const performanceScores =
    answerIds?.length > 0
      ? answerIds?.map((answerId) => {
          const performanceSlug = explanationConfiguration?.attributes?.find(
            (attribute) => attribute.relatedAnswerId === answerId
          )?.slug;
          if (performanceSlug) {
            return product.attributes[performanceSlug]?.value;
          }
        })
      : explanationConfiguration?.attributes?.map(
          (attribute) => product.attributes[attribute?.slug]?.value
        );

  const mean =
    performanceScores.reduce((acc, val) => acc + parseFloat(val), 0) /
    performanceScores.length;

  return mean;
}

function getLaptopPerformanceContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const mean = getLaptopPerformanceMean(
    product,
    explanationConfiguration,
    responseSet
  );

  return "Overall Performance: " + getQualitativeWord(mean);
}

function getLaptopPerformanceUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const mean = getLaptopPerformanceMean(
    product,
    explanationConfiguration,
    responseSet
  );

  const response = responseSet.responses.find((response) => {
    return (
      response.questionId === explanationConfiguration.showWhenQuestionSkipped
    );
  });

  if (
    !response ||
    !response.answers ||
    !response.answers[0] ||
    !explanationConfiguration.attributes[0]
  ) {
    return false;
  }

  const likertScore = parseFloat(response.answers[0].mainText);

  return (likertScore === 4 && mean < 8) || (likertScore === 3 && mean < 7);
}

function getLaptopPortabilityContent(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const thickness = parseFloat(product?.attributes["thickness"]?.value);
  const weight = parseFloat(product?.attributes["weight"]?.value);

  let thicknessClass = "";
  for (const [index, threshold] of thicknessThresholds.entries()) {
    if (thickness <= threshold) {
      thicknessClass = thicknessNames[index];
      break;
    }
  }

  let weightClass = "";
  for (const [index, threshold] of weightThresholds.entries()) {
    if (weight <= threshold) {
      weightClass = weightNames[index];
      break;
    }
  }

  return (
    "Portability: " +
    capitalizeFirstLetter(
      thicknessClass.toLowerCase() + ", " + weightClass.toLowerCase()
    )
  );
}

function getLaptopPortabilityUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const thickness = parseFloat(product?.attributes["thickness"]?.value);
  const weight = parseFloat(product?.attributes["weight"]?.value);

  const response = responseSet?.responses?.find((response) => {
    return (
      response.questionId === explanationConfiguration.showWhenQuestionSkipped
    );
  });

  if (
    !response ||
    !response.answers ||
    !response.answers[0] ||
    !explanationConfiguration.attributes[0]
  ) {
    return false;
  }

  const likertScore = parseFloat(response.answers[0].mainText);

  return (
    (likertScore === 4 && (thickness > 0.69 || weight > 4.09)) ||
    (likertScore === 3 && (thickness > 0.84 || weight > 4.9))
  );
}

function getPossiblyUnmetRequirements(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  if (
    explanationConfiguration.displayType === "letterGrade" ||
    explanationConfiguration.displayType === "qualitative"
  ) {
    return getAnyScoreBelowThreshold(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "earbudsOS") {
    const osExplanations = getHeadphonesOsExplanations(
      product,
      explanationConfiguration,
      responseSet
    );

    const hasUnmet = osExplanations.find((explanation) => explanation.isUnmet);

    return !!hasUnmet;
  } else if (explanationConfiguration.displayType === "earbudsSignature") {
    return getEarbudsSignatureUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "likertQualitative") {
    return getLikertQualitativeUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "phoneSize") {
    return getPhoneSizeUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "laptopSize") {
    return getLaptopSizeUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "laptopPerformance") {
    return getLaptopPerformanceUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  } else if (explanationConfiguration.displayType === "laptopPortability") {
    return getLaptopPortabilityUnmetRequirements(
      product,
      explanationConfiguration,
      responseSet
    );
  }
  return false;
}

/**
 * Gets explanations for headphones OS. May contain both met and unmet.
 */
function getHeadphonesOsExplanations(
  product: Product,
  explanationConfiguration: ExplanationConfiguration,
  responseSet: ResponseSetWithResponses
) {
  const { androidOptimized, appleOptimized } =
    getAndroidAndAppleOptimized(product);

  let is;

  if (androidOptimized && appleOptimized) {
    is = "both";
  } else if (!androidOptimized && !appleOptimized) {
    is = "neither";
  } else if (androidOptimized) {
    is = "android";
  } else if (appleOptimized) {
    is = "apple";
  }

  let needs;

  const needsMap = {
    "a57a0141-2d14-4f69-b1c9-4cc129187cfc": "apple",
    "64823170-0f75-421f-88cc-acedd00cd714": "android",
    "77e832ef-537e-4919-baa1-4bd124a08ab4": "both",
  };

  Object.entries(needsMap).forEach(([answerId, needsKey]) => {
    if (!needs && !!answeredQuestionWithAnswer(responseSet, [answerId])) {
      needs = needsKey;
    }
  });

  if (!is || !needs) {
    return [];
  }

  // Is optimized for > User needs > explanations;
  const explanationsTable = {
    android: {
      android: [{ content: "Optimized for Android", isUnmet: false }],
      apple: [{ content: "Not optimized for Apple", isUnmet: true }],
      both: [
        { content: "Not optimized for Apple", isUnmet: true },
        { content: "Optimized for Android", isUnmet: false },
      ],
    },
    apple: {
      android: [{ content: "Not optimized for Android", isUnmet: true }],
      apple: [{ content: "Optimized for Apple", isUnmet: false }],
      both: [
        { content: "Not optimized for Android", isUnmet: true },
        { content: "Optimized for Apple", isUnmet: false },
      ],
    },
    both: {
      android: [{ content: "Optimized for Android", isUnmet: false }],
      apple: [{ content: "Optimized for Apple", isUnmet: false }],
      both: [{ content: "Optimized for Apple & Android", isUnmet: false }],
    },
    neither: {
      android: [{ content: "Not optimized for Android", isUnmet: true }],
      apple: [{ content: "Not optimized for Apple", isUnmet: true }],
      both: [{ content: "Not optimized for Apple or Android", isUnmet: true }],
    },
  };

  const explanations = explanationsTable[is][needs];
  return explanations;
}

function answeredQuestion(
  responseSet: ResponseSetWithResponses,
  questionId: string
) {
  return responseSet.responses.find(
    (response) =>
      response.questionId === questionId && response.answerIds?.length > 0
  );
}

function answeredQuestionWithAnswer(
  responseSet: ResponseSetWithResponses,
  answerIds: string[]
) {
  return responseSet.responses.find((response) =>
    response.answerIds?.some((answerId) => answerIds.includes(answerId))
  );
}

function skippedQuestion(
  responseSet: ResponseSetWithResponses,
  questionId: string
) {
  return responseSet.responses.find(
    (response) =>
      response.questionId === questionId && response.answers?.length === 0
  );
}

// @TODO Only supports letter grade thresholds.
const conditionalThresholdResolvers = {
  tvs: {
    // Picture quality pref = 4.
    pictureQuality4: (responseSet) => {
      return !!answeredQuestionWithAnswer(responseSet, [
        "ebf4253f-f1f5-4acd-b4f6-6318751585fd",
      ]);
    },
  },
  laptops: {
    performance4: (responseSet) => {
      // Performance pref = 4.
      return !!answeredQuestionWithAnswer(responseSet, [
        "aae7f955-e1d5-4501-9355-d0553422a81e",
      ]);
    },
  },
} as {
  [category: string]: {
    [resolver: string]: (
      responseSet: ResponseSetWithResponses,
      product: Product,
      explanation: ExplanationConfiguration,
      attribute: ExplanationAttribute
    ) => boolean;
  };
};

/**
 * Gets all explanations for a product.
 */
export function getExplanations(
  product: Product,
  responseSet: ResponseSetWithResponses,
  explanationConfigurations: {
    [explanationName: string]: ExplanationConfiguration;
  },
  displayMax = 3
): {
  icon: string;
  isUnmet: boolean;
  display: boolean;
  content: string | JSX.Element;
  name: string;
}[] {
  const explanations = [];
  const explanationConfigNames = Object.keys(explanationConfigurations);
  let displayCount = 0;
  const categoryName = product.metadata.categoryName;

  for (const explanationConfigName of explanationConfigNames) {
    const explanationConfig = explanationConfigurations[explanationConfigName];

    const isUnmet = getPossiblyUnmetRequirements(
      product,
      explanationConfig,
      responseSet
    );

    // Determine whether to display.
    let display = false;
    if (
      explanationConfig.showWhenAnswered &&
      explanationConfig.showWhenQuestionNotSkipped
    ) {
      display =
        !!answeredQuestionWithAnswer(
          responseSet,
          explanationConfig.showWhenAnswered
        ) &&
        !!answeredQuestion(
          responseSet,
          explanationConfig.showWhenQuestionNotSkipped
        );
    } else if (
      explanationConfig.showWhenAnswered &&
      explanationConfig.showWhenQuestionSkipped
    ) {
      display =
        !!answeredQuestionWithAnswer(
          responseSet,
          explanationConfig.showWhenAnswered
        ) ||
        !!skippedQuestion(
          responseSet,
          explanationConfig.showWhenQuestionSkipped
        );
    } else if (explanationConfig.showWhenAnswered) {
      display = !!answeredQuestionWithAnswer(
        responseSet,
        explanationConfig.showWhenAnswered
      );
    } else if (
      explanationConfig.showWhenQuestionNotSkipped &&
      explanationConfig.showWhenQuestionSkipped
    ) {
      display =
        !!answeredQuestion(
          responseSet,
          explanationConfig.showWhenQuestionNotSkipped
        ) ||
        !!skippedQuestion(
          responseSet,
          explanationConfig.showWhenQuestionSkipped
        );
    } else if (explanationConfig.showWhenQuestionNotSkipped) {
      display = !!answeredQuestion(
        responseSet,
        explanationConfig.showWhenQuestionNotSkipped
      );
    } else if (explanationConfig.showWhenQuestionSkipped) {
      display = !!skippedQuestion(
        responseSet,
        explanationConfig.showWhenQuestionSkipped
      );
    }

    //this should override every other config
    if (explanationConfig.showAlways) {
      display = true;
    }

    // Limit to the display max. Explanation types such as usage, which may have
    // multiple explanation lines (pass and fail), only count once. If display max
    // is empty, display everything. For instance, we may want to show all
    // explanations on the done page.

    display = displayMax < 0 || displayCount < displayMax ? display : false;

    if (display) {
      displayCount++;
    }

    // Build explanation and add to list.
    if (explanationConfig.displayType === "letterGrade") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getLetterGradeContent(product, explanationConfig, responseSet),
      });
    } else if (explanationConfig.displayType === "letterGradeSplit") {
      // Split lettergrade results in possibly two explanations if some of
      // the attributes pass the threshold and some don't.
      const graded = { pass: [], fail: [] };

      for (const attribute of explanationConfig.attributes) {
        // If it shouldn't be shown at all due to conditional, continue.
        if (
          !attributeMayDisplay(attribute, responseSet) &&
          !(
            explanationConfig.showWhenQuestionSkipped &&
            !!skippedQuestion(
              responseSet,
              explanationConfig.showWhenQuestionSkipped
            ) &&
            attribute.default
          )
        ) {
          continue;
        }

        let threshold = explanationConfig.threshold;

        // Evaluate conditional thresholds. For instance, TV content rating pass/fail
        // may depend on picture quality preference.
        if (explanationConfig.conditionalThresholds) {
          Object.keys(explanationConfig.conditionalThresholds).forEach(
            (thresholdFn) => {
              const fn =
                conditionalThresholdResolvers?.[categoryName]?.[thresholdFn];
              if (
                fn &&
                fn(responseSet, product, explanationConfig, attribute)
              ) {
                threshold =
                  explanationConfig.conditionalThresholds[thresholdFn];
              }
            }
          );
        }

        const pass =
          parseFloat(product.attributes?.[attribute.slug]?.value) >=
            threshold ||
          !!skippedQuestion(
            responseSet,
            explanationConfig.showWhenQuestionSkipped
          );

        const gradeContent = `${
          attribute.displayName === "" ? "" : attribute.displayName + ":\u00a0"
        }${getLetterGrade(product.attributes[attribute.slug]?.value)}`;

        if (pass) {
          graded.pass.push(gradeContent);
        } else {
          graded.fail.push(gradeContent);
        }
      }

      const commonExplanationProps = {
        name: explanationConfigName,
        icon: explanationConfig.icon,
        display,
      };

      if (graded.pass.length > 0) {
        explanations.push({
          ...commonExplanationProps,
          isUnmet: false,
          content: graded.pass.join(", "),
        });
      }
      if (graded.fail.length > 0) {
        explanations.push({
          ...commonExplanationProps,
          isUnmet: true,
          content: graded.fail.join(", "),
        });
      }
    } else if (explanationConfig.displayType === "qualitative") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getQualitativeWordContent(
          product,
          explanationConfig,
          responseSet
        ),
      });
    } else if (explanationConfig.displayType === "phoneSize") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getPhoneSizeContent(product, explanationConfig, responseSet),
      });
    } else if (explanationConfig.displayType === "likertQualitative") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getQualitativeWordContent(
          product,
          explanationConfig,
          responseSet
        ),
      });
    } else if (explanationConfig.displayType === "earbudsOS") {
      const osExplanations = getHeadphonesOsExplanations(
        product,
        explanationConfig,
        responseSet
      );

      for (const osExplanation of osExplanations) {
        explanations.push({
          name: explanationConfigName,
          ...osExplanation,
          display,
          icon: explanationConfig.icon,
        });
      }
    } else if (explanationConfig.displayType === "earbudsSignature") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getEarbudsSignatureContent(
          product,
          explanationConfig,
          responseSet
        ),
      });
    } else if (explanationConfig.displayType === "earbudsConnectivity") {
      explanations.push({
        name: explanationConfigName,
        isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getHeadphonesConnectivityValue(product),
      });
    } else if (explanationConfig.displayType === "laptopSize") {
      explanations.splice(1, 0, {
        name: explanationConfigName,
        isUnmet: isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getLaptopSizeContent(product, explanationConfig, responseSet),
      });
    } else if (explanationConfig.displayType === "laptopPerformance") {
      explanations.splice(1, 0, {
        name: explanationConfigName,
        isUnmet: isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getLaptopPerformanceContent(
          product,
          explanationConfig,
          responseSet
        ),
      });
    } else if (explanationConfig.displayType === "laptopPortability") {
      explanations.push({
        name: explanationConfigName,
        isUnmet: isUnmet,
        display,
        icon: explanationConfig.icon,
        content: getLaptopPortabilityContent(
          product,
          explanationConfig,
          responseSet
        ),
      });
    }
  }

  return explanations;
}

/**
 * Gets phrase and interval for a product's price relative to a provided budget.
 */
export const getBudgetExplanation = (
  budgetMax: number,
  productPrice: number
) => {
  const thresholdPcts = [-1, -0.2, -0.0001, 0.0001, 0.1, 0.2];

  if (!budgetMax || !productPrice) {
    return { phrase: null, interval: null, isUnmet: false };
  }

  let phrase;
  let interval = thresholdPcts.length - 1;

  const pDiffAbs = Math.abs(budgetMax - productPrice);
  const pDiffString = centsToDollars(pDiffAbs);

  // Determine the interval where the product belongs, relative to budget.
  for (const [i, tPct] of thresholdPcts.entries()) {
    const isFirst = i === 0;
    if (isFirst) {
      continue;
    }

    const minPct = thresholdPcts[i - 1];
    const maxPct = tPct;
    const minSpan = minPct * budgetMax;
    const maxSpan = maxPct * budgetMax;
    const minPt = budgetMax + minSpan;
    const maxPt = budgetMax + maxSpan;

    if (productPrice > minPt && productPrice <= maxPt) {
      interval = i - 1;
    }
  }

  if (interval < 2) {
    // Budget phrase is less granular than the full set of intervals.
    phrase = `${pDiffString} under budget`;
  } else if (interval === 2) {
    phrase = `At your budget`;
  } else if (interval === 3) {
    phrase = `Slightly over budget`;
  } else if (interval > 3) {
    phrase = `${pDiffString} over budget`;
  }

  return { phrase, interval, isUnmet: interval === 4 || interval === 5 };
};

/**
 * Get wired/wireless (connectivity) phrase for headphones.
 */
export const getHeadphonesConnectivityValue = (product: Product) => {
  const phrase = [];
  if (product.attributes?.["wired"]?.value === "Yes") {
    phrase.push("Wired");
  }
  if (product.attributes?.["wireless"]?.value === "Yes") {
    phrase.push("Wireless");
  }

  return phrase.join(" + ");
};
