import React from "react";
import { PanelLayout } from "@components/product/summary/common";
import ProductCostComparison from "./product-cost-comparison";
import TvScreenComparison from "./tvs/tv-screen-comparison";
import { TvContentPhraseMap } from "@components/product/summary/tv-fns";
import { Product } from "@lib/model/product";
import { ComparisonSummary } from "../compare-components";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import VsPageCtaCard from "../vs-page-cta-card";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import SquareAdUnit from "@components/ads/square-ad-unit";

const TvComparisonDisplay = (props: {
  products: Product[];
  categoryConfig: ProductCategoryConfig;
  keySpecs?: boolean;
  compareResults: any;
  displayDiffs: boolean;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const contentAttributes = Object.keys(TvContentPhraseMap);
  contentAttributes.unshift("screenScore");
  contentAttributes.push("brightRoomScore");
  return (
    <PanelLayout>
      {contentAttributes.map((content) => (
        <TvScreenComparison
          key={content}
          productA={props?.products?.[0]}
          productB={props?.products?.[1]}
          attributeName={content}
          keySpecs={props.keySpecs}
          compareResults={props.compareResults}
          comparisons={props.comparisons}
        />
      ))}
      <ProductCostComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        maxBudgetCents={675000}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      {props.keySpecs && props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {!props.keySpecs && !props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {/* {props.keySpecs && props.displayDiffs && <SquareAdUnit />}
      {!props.keySpecs && !props.displayDiffs && <SquareAdUnit />} */}
    </PanelLayout>
  );
};

export const TvComparisonSummary = (props: {
  products: Product[];
  categoryConfig: ProductCategoryConfig;
}) => {
  return (
    <ComparisonSummary
      attributes={{
        exact: ["screenScore", "gamingScore", "soundScore"],
        fuzzy: [
          "movieScore",
          "sportsScore",
          "brightRoomScore",
          "animationScore",
          "newsScore",
        ],
      }}
      products={props.products}
    />
  );
};

export default TvComparisonDisplay;
