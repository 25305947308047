import useSWRImmutable from "swr/immutable";
import { useContext, useEffect, useMemo } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import PurchaseOption, {
  acceptableConditionsForRecommendation,
} from "@lib/model/purchase-option";
import { centsToDollars } from "@lib/utilities/shared-utilities";
import {
  pickBestPurchaseOption,
  sortPurchaseData,
} from "@lib/utilities/pick-best-purchase-option";
import { Product } from "@lib/model/product";

/**
 * Gets all prices for all products. Filtering out below excellent condition.
 */
export const useAllPurchaseData = () => {
  const { productCategoryConfig, apiDomain } = useContext(
    ProductCategoryContext
  );

  const headers = {
    "x-category-name": productCategoryConfig.name,
    "x-version": productCategoryConfig.dataVersions["purchaseData"],
  };
  const path = `${apiDomain}/purchaseData/all`;
  const cacheKey = `${productCategoryConfig.name}/purchaseData/all`;

  // @TODO figure out shape of data coming from purchase data endpoint.
  const { data, isLoading } = useSWRImmutable<unknown[]>(cacheKey, async () => {
    const res = await fetch(path, {
      headers,
    });

    if (res.status === 200) {
      return res.json();
    }

    throw new Error(
      `${res.status} - ${res.statusText} - Using fetch path: ${path}`
    );
  });

  const allPurchaseDataTransformed = useMemo(() => {
    if (data) {
      const transformedPurchaseData = transformPurchaseData(data).filter(
        (purchaseOption) =>
          acceptableConditionsForRecommendation.includes(
            purchaseOption.condition
          )
      );

      return sortPurchaseData(transformedPurchaseData);
    }
  }, [data]);

  return { allPurchaseData: allPurchaseDataTransformed, isLoading };
};

/**
 * Get prices for multiple products.
 */
export const usePurchaseDataMultiple = (products: Product[]) => {
  const { allPurchaseData } = useAllPurchaseData();

  const purchaseData = useMemo(() => {
    let purchaseData = null as ReturnType<typeof buildProductPurchaseData>[];

    if (allPurchaseData?.length > 0 && products?.length > 0) {
      purchaseData = products
        .filter((product) => product)
        .map((product) => {
          return buildProductPurchaseData(product, allPurchaseData);
        });
    }

    return purchaseData;
  }, [allPurchaseData, products]);

  return { purchaseData };
};

/**
 * Get prices for a single product.
 */
export const usePurchaseData = (product: Product) => {
  let displayPriceNumeric,
    displayPrice,
    hasPrice,
    purchaseData,
    amazonPurchaseOption;

  const products = useMemo(() => [product], [product]);

  const { purchaseData: purchaseDataList } = usePurchaseDataMultiple(products);

  if (!purchaseDataList?.length) {
    return {
      purchaseData,
      displayPriceNumeric,
      displayPrice,
      hasPrice,
      amazonPurchaseOption,
    } as ReturnType<typeof buildProductPurchaseData>;
  }

  return purchaseDataList?.[0];
};

/**
 * Helper function to map API properties to frontend data types.
 */
export const transformPurchaseData = (apiPurchaseData) => {
  return Object.keys(apiPurchaseData).map((id) => {
    const apiPurchaseDataRecord = apiPurchaseData[id];
    // @TODO use a single model for products.
    return {
      productId: apiPurchaseDataRecord.source.productId,
      variantId: apiPurchaseDataRecord.source.variantId,
      price: apiPurchaseDataRecord.price,
      condition: apiPurchaseDataRecord.condition,
      link: apiPurchaseDataRecord.source.link,
      linkId: apiPurchaseDataRecord.source.id,
      storeName: apiPurchaseDataRecord.source.storeName,
      inStock: apiPurchaseDataRecord.inStock,
      metadata: apiPurchaseDataRecord.metadata,
    };
  });
};

/**
 * Helper function to derive pricing information per product.
 */
export const buildProductPurchaseData = (
  product: Product,
  allPurchaseData: PurchaseOption[]
) => {
  // Get the purchase data for the input product
  const productPurchaseOptions = allPurchaseData.filter(
    (pd) => pd.productId === product.id
  );

  // Get the variant IDs for the input product, which are also in its price group
  const variantIds = product.variants
    ? product.variants
        .filter((variant) =>
          product.priceGroup ? variant.priceGroup === product.priceGroup : false
        )
        .map((variant) => variant.variantId)
    : [];

  // Get the purchase data for the variant IDs found
  const variantsPurchaseOptions = allPurchaseData.filter((pd) =>
    variantIds.includes(pd.productId)
  );
  const purchaseData = [...productPurchaseOptions, ...variantsPurchaseOptions];
  const displayPriceNumeric = pickBestPurchaseOption(purchaseData)?.price;
  const displayPrice = purchaseData
    ? centsToDollars(displayPriceNumeric)
    : null;
  const hasPrice = displayPriceNumeric && !Number.isNaN(displayPriceNumeric);

  const amazonPurchaseOption = purchaseData.find(
    (purchaseDatum) => purchaseDatum.storeName === "Amazon"
  );

  const retailers = [];
  purchaseData.forEach((purchaseOption) => {
    retailers.push(purchaseOption.storeName);
  });

  return {
    displayPriceNumeric,
    displayPrice,
    purchaseData,
    hasPrice,
    retailers: [...new Set(retailers)],
    amazonPurchaseOption,
  };
};
