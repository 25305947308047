import { getProductLabels } from "@lib/utilities/shared-utilities";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { useAttribute } from "@lib/hooks/use-attributes";

const Brightness = (props: PanelSetProps) => {
  const { selectedProduct } = props;
  const brightnessAttribute = useAttribute("brightRoomScore");

  const { explanation, config } = useExplanation(
    "brightRoomExplanation",
    selectedProduct
  );

  if (!brightnessAttribute || !explanation.display) {
    return null;
  }

  const score = parseFloat(
    selectedProduct.attributes["brightRoomScore"]?.value
  );

  const qualitativeWord = getQualitativeWord(score)
    .toLowerCase()
    .replace("best", "the best")
    .replace("fair", "only fair");

  const title = `Sunny rooms`;

  const article = qualitativeWord.toLowerCase() === "best" ? "the" : "";

  const first = (
    <>
      The <strong>{getProductLabels(selectedProduct).shortLabel}</strong> has{" "}
      {article} {qualitativeWord.toLowerCase()} performance in bright rooms and
      against glare.
    </>
  );

  const color = getMeetsNeedsColor(score > config.threshold);

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: brightnessAttribute.displayName,
        text: brightnessAttribute.explanation,
      }}
    >
      <ArcScoreDisplay
        product={props.selectedProduct}
        attribute={brightnessAttribute}
        icon={getAspectIcon("tvs", "brightRoomScore")}
        score={score}
        color={color}
      />
      <SummaryTitles first={first} />
    </DonePanel>
  );
};

export default Brightness;
