import React from "react";
import { PanelLayout } from "@components/product/summary/common";

import PhoneCameraComparison from "@components/compare/compare-attribute-display/phone/phone-camera-comparison";
import ProductBatteryComparison from "./product-battery-comparison";
import PhoneSizeComparison from "./phone/phone-size-comparison";
import ProductCostComparison from "./product-cost-comparison";
import { ComparisonSummary } from "../compare-components";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import VsPageCtaCard from "../vs-page-cta-card";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import SquareAdUnit from "@components/ads/square-ad-unit";

const PhoneComparisonDisplay = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
  keySpecs?: boolean;
  compareResults: any;
  displayDiffs: boolean;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  return (
    <PanelLayout>
      <PhoneCameraComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
        attributeName="cameraScore"
        title="Overall Camera Quality"
        poles={["Worse Camera", "Better Camera"]}
        comparisons={props.comparisons}
      />
      <PhoneCameraComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
        attributeName="pictureScore"
        title="Photo Quality"
        poles={["Worse Photos", "Better Photos"]}
        comparisons={props.comparisons}
      />
      <PhoneCameraComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
        attributeName="videoScore"
        title="Video Quality"
        poles={["Worse Video", "Better Video"]}
        comparisons={props.comparisons}
      />
      <ProductBatteryComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        attributeName="batteryLife"
        ticks={["0", "3", "6", "9", "12"]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <PhoneSizeComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductCostComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        maxBudgetCents={140000}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      {props.keySpecs && props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {!props.keySpecs && !props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {/* {props.keySpecs && props.displayDiffs && <SquareAdUnit />}
      {!props.keySpecs && !props.displayDiffs && <SquareAdUnit />} */}
    </PanelLayout>
  );
};

export const PhoneComparisonSummary = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
}) => {
  return (
    <ComparisonSummary
      attributes={{
        exact: ["cameraScore", "batteryLife"],
        fuzzy: ["screenScore", "performance"],
      }}
      products={props.products}
    />
  );
};

export default PhoneComparisonDisplay;
