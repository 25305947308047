import { AttributeConfigurationMap } from "@lib/model/attribute";
import { Product } from "@lib/model/product";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import { PageLayout } from "@components/layouts/page-layout";
import {
  getProductLabels,
  getRecommenderUrl,
  getVsPageUrl,
  getCategoryFeatureState,
  getCompareIndexUrl,
  getUnderConstruction,
  getImageUrl,
  AssetType,
  getProductLandingPageUrl,
  getVsPageUrlBySlugs,
  nonCanonicalVsPagesForSitemap,
  centsToDollars,
  getCanonicalCompareSlugs,
  getCanonicalSlug,
  displayDate,
  getCanonicalCompare,
  getCanonicalCompareIds,
} from "@lib/utilities/shared-utilities";
import ProductCategoryContextProvider, {
  ProductCategoryContext,
} from "contexts/product-category-context";
import { LinkButton } from "@components/common/button";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { PhoneChartsStandard } from "@components/recommendation/classified-attribute-display/phone-attribute-display";
import { TvChartsStandard } from "@components/recommendation/classified-attribute-display/tv-attribute-display";
import { useContext, useMemo, useRef, useState } from "react";
import Breadcrumbs from "@components/common/breadcrumbs";
import PhoneComparisonDisplay, {
  PhoneComparisonSummary,
} from "@components/compare/compare-attribute-display/phone-comparison-display";
import LaptopComparisonDisplay, {
  LaptopComparisonSummary,
} from "@components/compare/compare-attribute-display/laptop-comparison-display";
import useElementOnScreen from "@lib/hooks/use-element-on-screen";
import ProductCompareHero from "@components/compare/product-compare-hero";
import {
  StandardCard,
  StandardCardSection,
  StandardCardSectionHorizontal,
} from "@components/recommendation/done-page-fixtures";
import ProductCompareTable from "@components/compare/product-compare-table";
import TvComparisonDisplay, {
  TvComparisonSummary,
} from "@components/compare/compare-attribute-display/tv-comparison-display";
import { CompareHeaderSmall } from "@components/compare/compare-components";
import Meta from "./meta";
import { IFaq, IScenario, ITextBlock } from "@lib/model/contentful";
import ScenariosList from "./scenarios-list";
import PurchaseData, {
  getPriceLastUpdated,
} from "@components/product/purchase-data";
import ReviewCard from "@components/recommendation/review-card";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import {
  useAllPurchaseData,
  usePurchaseData,
} from "@lib/hooks/use-purchase-data";
import HeadphonesComparisonDisplay, {
  HeadphonesComparisonSummary,
} from "@components/compare/compare-attribute-display/headphones-comparison-display";
import { HeadphonesChartsStandard } from "@components/recommendation/classified-attribute-display/headphones-attribute-display";
import { LaptopChartsStandard } from "@components/recommendation/classified-attribute-display/laptop-attribute-display";
import {
  LandingPageFeedbackForm,
  LandingPageFeedbackMidWayForm,
  LlmFeedbackForm,
} from "@components/form/feedback-forms";
import SeeOnAmazon from "@components/recommendation/see-on-amazon";
import { renderRichText } from "@components/common/contentful-rich-text";
import LatestReportDisplay from "@components/common/latest-report-display";
import SimilarProducts from "@components/compare/similar-products";
import { useRouter } from "next/router";
import { getAllCompareResults } from "@components/compare/compare-fns";
import { FaqsCard } from "@components/common/faq";
import Modal from "@components/modal/modal";
import Trust from "@components/common/trust";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import TableOfContents from "@components/common/table-of-contents";
import { ProductHeading } from "@components/common/section-heading";
import BlankTextPlaceholder from "@components/common/blank-text-placeholder";
import HorizontalAdUnit from "@components/ads/horizontal-ad-unit";
import ImageWithFallback from "@components/common/image-with-fallback";
import Link from "next/link";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import FakeQuestion from "@components/compare/fake-question";
import PurchaseOption from "@lib/model/purchase-option";
import { getStarRatings } from "@components/product/summary/star-ratings";
import VsTray from "@components/compare/vs-tray";
import {
  formatBlurbForDisplay,
  isValidBlurb,
} from "@lib/utilities/shared-llm-utilities";
import { VsPriceAlertsFormBlock } from "@components/form/price-alert-form";
import AdvantagesSection from "@components/compare/advantages";
import MonitorsComparisonDisplay, {
  MonitorChartsStandard,
} from "@components/compare/compare-attribute-display/monitors-comparison-display";

/**
 * Helper to get reasonable labels for the two vs products.
 */
const getSEOLabels = (products: Product[]) => {
  const seoLabel0 = getProductLabels(products[0]).seoLabel;
  const seoLabel1 = getProductLabels(products[1]).seoLabel;
  return [seoLabel0, seoLabel1];
};

export const getComparison = async (
  selectedProductsNew,
  productCategory,
  setComparison
) => {
  try {
    const params = new URLSearchParams();
    if (productCategory.name === "tvs") {
      params.append(
        "path",
        getVsPageUrlBySlugs(
          getCanonicalCompareSlugs(selectedProductsNew),
          productCategory,
          true
        )
      );
    } else {
      params.append(
        "path",
        getVsPageUrl(selectedProductsNew, productCategory, true)
      );
    }
    params.append("cacheOnly", "true");

    const specificComparePrompts = {
      tvs: [
        "comparePictureQualityTV",
        "compareMoviesTV",
        "compareSportsTV",
        "compareGamingTV",
        "compareNewsTV",
        "compareAnimationTV",
        "compareBrightRoomTV",
      ],
      laptops: [
        "compareGeneralUseLaptop",
        "compareEngineeringLaptop",
        "compareGamingLaptop",
        "comparePerformanceLaptop",
        "compareScreenLaptop",
      ],
      smartphones: [
        "comparePhotosPhone",
        "compareVideosPhone",
        "compareCameraPhone",
      ],
      headphones: [
        "compareMusicHeadphones",
        "compareCallsHeadphones",
        "compareGamingHeadphones",
        "compareTvHeadphones",
        "comparePodcastsHeadphones",
        "compareExerciseHeadphones",
        "compareNoiseReductionHeadphones",
        "compareTravelHeadphones",
      ],
      monitors: [],
    };

    const promptTypesToFetch = [
      "comparison",
      ...specificComparePrompts[productCategory.name],
    ];

    const results = await Promise.all(
      promptTypesToFetch.map(async (promptType) => {
        params.set("promptType", promptType);

        const url = `${window?.location?.origin}/api/getLlmOutput?${params}`;

        const result = await fetch(url);

        if (!result.ok) {
          throw new Error("Encountered an error fetching comparisons");
        }

        const json = await result.json();

        return isValidBlurb(json.assistantMessage, promptType)
          ? {
              [promptType]: {
                metadata: json.metadata,
                result: formatBlurbForDisplay(
                  json.assistantMessage,
                  promptType
                ),
                updated_at: json.updated_at,
                created_at: json.created_at,
              },
            }
          : {
              [promptType]: {
                metadata: null,
                result: null,
                updated_at: null,
                created_at: null,
              },
            };
      })
    );

    const combinedOutput = results.reduce(
      (acc, result) => ({ ...acc, ...result }),
      {}
    );

    setComparison(combinedOutput);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

/**
 * The main VS page component.
 */
const VsPage = (props: {
  productCategory: ProductCategoryConfig;
  products: Product[];
  purchaseData: PurchaseOption[][];
  configMap: ProductCategoryConfigMap;
  scenarios: IScenario[];
  textBlock: ITextBlock;
  similarProducts: Product[][];
  storedComparisons: Record<PromptType, StoredLlmOutput>;
  faqs: IFaq[];
  mediaHighlights;
  isInternalUser?: boolean;
  retailerCount: number;
}) => {
  const {
    products: defaultProducts,
    productCategory,
    purchaseData,
    isInternalUser,
  } = props;
  const attributes = productCategory.attributeConfiguration;
  const hasCharts = getCategoryFeatureState(
    productCategory,
    "landingPageCharts"
  );

  const [selectedProducts, setSelectedProducts] =
    useState<Product[]>(defaultProducts);

  let product0 = defaultProducts[0];
  let product1 = defaultProducts[1];

  // Make sure both selected products are loaded.
  if (selectedProducts.filter((product) => product).length === 2) {
    product0 = selectedProducts[0];
    product1 = selectedProducts[1];
  }

  const displayProducts = [product0, product1];
  const [seoLabel0, seoLabel1] = getSEOLabels(displayProducts);
  const shortLabel0 = getProductLabels(product0).shortLabel;
  const shortLabel1 = getProductLabels(product1).shortLabel;
  const seoTitle = `${seoLabel0} vs ${seoLabel1}`;
  const [comparisons, setComparisons] = useState(props.storedComparisons);

  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const canonicalSlugs = getCanonicalCompareSlugs(displayProducts);

  const specialComparison =
    nonCanonicalVsPagesForSitemap[productCategory.name].length > 0 &&
    nonCanonicalVsPagesForSitemap[productCategory.name].find(
      (productSlugs) =>
        (productSlugs[0] === product0.slug &&
          productSlugs[1] === product1.slug) ||
        (productSlugs[0] === product1.slug && productSlugs[1] === product0.slug)
    );

  const canonicalUrl =
    specialComparison || canonicalSlugs.includes(undefined)
      ? getVsPageUrl(displayProducts, productCategory)
      : canonicalSlugs[0] !== canonicalSlugs[1]
      ? getVsPageUrlBySlugs(canonicalSlugs, productCategory)
      : undefined;

  const router = useRouter();

  const onSelectProduct = (product: Product, i) => {
    setSelectedProducts((prevSelectedProducts) => {
      EventTracker.track(Events.CompareProductChange, {
        product_id: product.id,
        product_name: product.slug,
        old_product_id: prevSelectedProducts[i]?.id,
        old_product_name: prevSelectedProducts[i]?.slug,
        compare_product_id: prevSelectedProducts[1 - i]?.id,
        compare_product_name: prevSelectedProducts[1 - i]?.slug,
      });
      const selectedProductsNew = [...prevSelectedProducts];
      selectedProductsNew[i] = product;
      router.push(
        getVsPageUrl(
          [selectedProductsNew[0], selectedProductsNew[1]],
          productCategory
        ),
        undefined,
        { shallow: true }
      );

      getComparison(selectedProductsNew, productCategory, setComparisons);

      return selectedProductsNew;
    });

    setSelectedProductIndex(i);
  };

  usePageScrollTracking({ page: "VS Page" });

  const ctaRef = useRef();
  const breadcrumbs = [
    {
      name: `Compare ${productCategory.pluralNoun}`,
      href: getCompareIndexUrl(productCategory),
    },
    {
      name: `${shortLabel0} vs ${shortLabel1}`,
    },
  ];

  const { compareResults, winnerCount, tieCount } = getAllCompareResults(
    displayProducts[0],
    displayProducts[1],
    productCategory,
    attributes
  );

  const displayDifferences = winnerCount > 0;
  const displaySimilarities = tieCount > 0;

  const starRatings = displayProducts.map((product) =>
    getStarRatings(product, attributes, productCategory.name)
  );

  const offerDetails = purchaseData.map((data, index) => {
    return {
      numberOfOffers: data ? data.length : 0,
      lowPrice: data ? displayProducts[index].bestPrice / 100 || 0 : 0,
      highPrice: data
        ? Math.max(...data.map((datum) => datum.price), 0) / 100
        : 0,
    };
  });

  const productsJson = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: [
      {
        "@type": "Product",
        url: `https://www.perfectrec.com${getProductLandingPageUrl(
          displayProducts[0],
          productCategory
        )}`,
        name: `${getProductLabels(displayProducts[0]).shortLabel}`,
        brand: {
          "@type": "Brand",
          name: displayProducts[0].manufacturer,
        },
        review: {
          "@type": "Review",
          name: `${
            getProductLabels(displayProducts[0]).shortLabel
          } review and stats`,
          author: {
            "@type": "Person",
            name: "Joe Golden",
          },
          reviewRating: {
            "@type": "Rating",
            ratingValue:
              starRatings[0].length > 0 ? starRatings[0][0].stars : 1,
          },
        },
        offers: offerDetails[0]?.numberOfOffers > 0 && {
          "@type": "AggregateOffer",
          offerCount: offerDetails[0].numberOfOffers,
          lowPrice: offerDetails[0].lowPrice,
          highPrice: offerDetails[0].highPrice,
          priceCurrency: "USD",
        },
      },
      {
        "@type": "Product",
        url: `https://www.perfectrec.com${getProductLandingPageUrl(
          displayProducts[1],
          productCategory
        )}`,
        name: `${getProductLabels(displayProducts[1]).shortLabel}`,
        brand: {
          "@type": "Brand",
          name: displayProducts[1].manufacturer,
        },
        review: {
          "@type": "Review",
          name: `${
            getProductLabels(displayProducts[1]).shortLabel
          } review and stats`,
          author: {
            "@type": "Person",
            name: "Joe Golden",
          },
          reviewRating: {
            "@type": "Rating",
            ratingValue:
              starRatings[1].length > 0 ? starRatings[1][0].stars : 1,
          },
        },
        offers: offerDetails[1]?.numberOfOffers > 0 && {
          "@type": "AggregateOffer",
          offerCount: offerDetails[1].numberOfOffers,
          lowPrice: offerDetails[1].lowPrice,
          highPrice: offerDetails[1].highPrice,
          priceCurrency: "USD",
        },
      },
    ],
  };

  const breadcrumbsJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 0,
        name: "Home",
        item: "https://www.perfectrec.com",
      },
      ...breadcrumbs.slice(0, -1).map(({ name, href }, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name,
        item: `https://www.perfectrec.com${href}`,
      })),
      {
        "@type": "ListItem",
        position: breadcrumbs.length,
        name: breadcrumbs[breadcrumbs.length - 1].name,
      },
    ],
  };

  const navigateForQuery = (param: string, value: string | string[]) => {
    const url = new URL(window.location.href);
    url.searchParams.delete("page");
    if (Array.isArray(value)) {
      url.searchParams.delete(param);
      for (const singleValue of value) {
        if (singleValue) {
          url.searchParams.append(param, singleValue);
        }
      }
    } else {
      url.searchParams.set(param, value);
    }

    router.push(url.toString());
  };

  const handleSearchInput = ({ value }) => {
    navigateForQuery("s", value);
  };

  return (
    <PageLayout configMap={props.configMap}>
      <Meta
        title={seoTitle}
        description={`Compare features, price, and technical specs between the ${seoLabel0} vs the ${seoLabel1} including charts and ratings.`}
        url={getVsPageUrl(displayProducts, productCategory)}
        image={getImageUrl(
          defaultProducts[0].image,
          AssetType.ProductImage,
          { width: 600 },
          productCategory.name
        )}
        canonicalUrl={canonicalUrl}
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(breadcrumbsJson),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(productsJson),
          }}
        />
      </Meta>

      <div className="container-toc">
        <ProductCategoryContextProvider
          productCategoryConfig={productCategory}
          faqs={props.faqs}
        >
          <VsTray
            isInternalUser={isInternalUser}
            inputProducts={displayProducts}
          />
          <div className="hidden xl:block">
            <TableOfContents
              selector=".content-section h2"
              className="top-[225px]"
              observeRootMargin="-200px 0% 0% 0%"
              title="Table of Contents"
            />
          </div>
          <div className="content-section">
            <Breadcrumbs
              className="my-8 hidden sm:flex"
              pages={breadcrumbs}
              removeLastHref
            />
            <div>
              <h1
                className="font-serif font-normal text-2xl sm:text-4xl mt-6 mb-1 text-center max-w-screen-md mx-auto"
                data-testid="vs-page-header"
              >
                <div className="">
                  <a
                    href={`${getProductLandingPageUrl(
                      displayProducts[0],
                      productCategory
                    )}`}
                    className="hover:underline"
                  >
                    <strong>{shortLabel0}</strong>
                  </a>{" "}
                  vs{" "}
                  <a
                    href={`${getProductLandingPageUrl(
                      displayProducts[1],
                      productCategory
                    )}`}
                    className="hover:underline"
                  >
                    <strong>{shortLabel1}</strong>
                  </a>
                </div>
              </h1>
              <div className="text-center text-lg text-gray-500 mb-2">
                A comparison of specs, key information, reviews, and best
                pricing from top retailers
              </div>
              <div className="mb-3">
                <LatestReportDisplay
                  products={displayProducts}
                  page="vs"
                  className="justify-center"
                />
              </div>

              <ProductCompareHero
                products={displayProducts}
                attributes={attributes}
                category={productCategory}
                onSelectProduct={onSelectProduct}
                setSelectedProducts={setSelectedProducts}
                getComparison={getComparison}
                setComparison={setComparisons}
                ctaRef={ctaRef}
              />
            </div>
            {props.textBlock && (
              <div className="my-12 max-w-screen-md mx-auto">
                {renderRichText(props.textBlock.fields.text, false)}
              </div>
            )}
            {comparisons?.comparison && (
              <WhatWeThink
                products={displayProducts}
                comparison={comparisons.comparison}
              />
            )}
            <div className="mt-12">
              <AdvantagesSection
                productA={displayProducts[0]}
                productB={displayProducts[1]}
                category={productCategory.name}
              />
            </div>
            {/* <HorizontalAdUnit
              adSlot="9978921008"
              ezoicId="ezoic-pub-ad-placeholder-106"
            /> */}
            {displayDifferences && (
              <div>
                <Heading id="key-differences">Key differences</Heading>
                {productCategory.name === "smartphones" && (
                  <PhoneComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    keySpecs={true}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "laptops" && (
                  <LaptopComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    keySpecs={true}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "headphones" && (
                  <HeadphonesComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    keySpecs={true}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "tvs" && (
                  <TvComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    keySpecs={true}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "monitors" && (
                  <MonitorsComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    keySpecs={true}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
              </div>
            )}

            {displaySimilarities && (
              <div>
                <Heading id="key-similarities">Key similarities</Heading>
                {productCategory.name === "smartphones" && (
                  <PhoneComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "laptops" && (
                  <LaptopComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "headphones" && (
                  <HeadphonesComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "tvs" && (
                  <TvComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
                {productCategory.name === "monitors" && (
                  <MonitorsComparisonDisplay
                    products={displayProducts}
                    categoryConfig={productCategory}
                    compareResults={compareResults}
                    displayDiffs={displayDifferences}
                    comparisons={comparisons}
                  />
                )}
              </div>
            )}

            {/* <HorizontalAdUnit
              adSlot="5200873052"
              ezoicId="ezoic-pub-ad-placeholder-108"
            /> */}

            <StandardCard className="bg-panel-blue-2 my-8">
              <StandardCardSection
                title="Give feedback"
                description="We’re constantly working to improve."
                last={true}
              >
                <LandingPageFeedbackMidWayForm
                  products={displayProducts}
                  categoryName={productCategory.name}
                  pageType="vs"
                />
              </StandardCardSection>
            </StandardCard>

            {hasCharts && (
              <>
                <Heading
                  className="font-normal"
                  id="compare-to-other"
                  tocName="Compare to others"
                >
                  How the <strong>{shortLabel0}</strong> and the{" "}
                  <strong>{shortLabel1}</strong> compare to other{" "}
                  {productCategory.pluralNoun}
                </Heading>
                <Charts
                  {...{ productCategory, attributes, selectedProductIndex }}
                  compareProducts={displayProducts}
                />
              </>
            )}

            {/* Key specs */}
            <Heading id="spec-comparison">Spec Comparison</Heading>
            <ProductCompareTable
              products={displayProducts}
              attributes={attributes}
              category={productCategory.name}
              displayImage={false}
              isModal={false}
              vs={true}
              context="full"
              headerRows={
                <CompareHeaderSmall
                  products={displayProducts}
                  category={productCategory.name}
                />
              }
            />

            {/* <HorizontalAdUnit
              adSlot="2352236480"
              ezoicId="ezoic-pub-ad-placeholder-109"
            /> */}

            {/* Shopping and reviews */}
            {!getUnderConstruction(productCategory, "prices") && (
              <Shopping selectedProducts={displayProducts} />
            )}

            {getCategoryFeatureState(productCategory, "priceAlerts") && (
              <VsPriceAlertsFormBlock
                products={displayProducts}
                categoryName={productCategory.name}
              />
            )}
            <Brands products={displayProducts} />

            <HorizontalAdUnit
              adSlot="6175823627"
              ezoicId="ezoic-pub-ad-placeholder-110"
            />

            {/* Feedback form */}
            <StandardCard className="bg-panel-blue-2 my-8">
              <StandardCardSection
                title="Give feedback"
                description="We're constantly perfecting our model"
                last={true}
              >
                <LandingPageFeedbackForm
                  products={displayProducts}
                  categoryName={productCategory.name}
                  pageType="vs"
                />
              </StandardCardSection>
            </StandardCard>

            {/* Scenarios */}
            {props.scenarios.length > 0 && (
              <>
                <Heading
                  id="guides"
                  tocName={`${capitalizeFirstLetter(
                    productCategory.noun
                  )} guides`}
                >
                  {capitalizeFirstLetter(productCategory.noun)} guides you might
                  be interested in
                </Heading>
                <ScenariosList
                  category={props.productCategory}
                  scenarios={props.scenarios}
                />
              </>
            )}

            {/* <HorizontalAdUnit adSlot="2267527885" ezoicId="" /> */}

            {/* Similar price products */}
            {props.similarProducts.length > 0 && (
              <div className="mb-2">
                <Heading id="more-comparisons" tocName="More comparisons">
                  More comparisons for you
                </Heading>
                <SimilarProducts
                  comparisons={aggregateComparisons(
                    props.similarProducts,
                    displayProducts
                  )}
                  category={productCategory}
                  onCardClick={(products) => setSelectedProducts(products)}
                />
              </div>
            )}

            {/* <HorizontalAdUnit adSlot="8931609780" ezoicId="" /> */}

            <Heading id="faqs">FAQs</Heading>
            <FaqsCard className="mb-8" />

            <Heading id="trust">Why trust us</Heading>
            <Trust
              category={productCategory}
              mediaHighlights={props.mediaHighlights}
            />

            <Breadcrumbs
              className="my-8 sm:hidden"
              pages={breadcrumbs}
              removeLastHref
            />
          </div>
        </ProductCategoryContextProvider>
      </div>
    </PageLayout>
  );
};

const WhatWeThink = ({
  products,
  comparison,
}: {
  products: Product[];
  comparison: StoredLlmOutput;
}) => {
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const { productVariants, products: allProducts } = useGlobalAllProducts();

  if (!comparison?.result) {
    return null;
  }

  const getCopy = (categoryName: string) => {
    const canonicalProductIds =
      products[0].metadata.categoryName === "tvs"
        ? getCanonicalCompareIds(products)
        : products.map((product) => product.id);

    const canonicalVariants = canonicalProductIds.map((id) =>
      productVariants.find((variant) => variant.id === id)
    );

    const priceDrop = comparison?.metadata?.prices.map(
      (price) =>
        price.price - products.find((p) => p.id === price.productId)?.bestPrice
    );

    const hasLargePriceDrop = priceDrop?.map(
      (price, i) => price / comparison?.metadata?.prices[i].price > 0.1
    );

    if (categoryName === "tvs") {
      return (
        <>
          This comparison is based on the{" "}
          <strong>
            {canonicalVariants[0].variantLabel} {canonicalVariants[0].label}
          </strong>{" "}
          and the{" "}
          <strong>
            {canonicalVariants[1].variantLabel} {canonicalVariants[1].label}
          </strong>
          .{" "}
          {comparison?.metadata?.prices.length == 2 && (
            <>
              At the time of writing the {canonicalVariants[0].variantLabel}{" "}
              {canonicalVariants[0].label} cost{" "}
              {centsToDollars(comparison?.metadata?.prices[0]?.price)} and the{" "}
              {canonicalVariants[1].variantLabel} {canonicalVariants[1].label}{" "}
              cost {centsToDollars(comparison?.metadata?.prices[1]?.price)}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[0] && (
            <>
              {" "}
              The {canonicalVariants[0].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[0])}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[1] && (
            <>
              {" "}
              The {canonicalVariants[1].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[1])}.
            </>
          )}
        </>
      );
    } else if (categoryName === "laptops") {
      return (
        <>
          {/* This comparison is based on the {canonicalVariants[0].label}{" "}
          configured with{" "}
          {canonicalVariants[0].variantLabel
            .replaceAll("GB", "GB storage")
            .replaceAll("GB storage |", "GB RAM,")
            .replace(";", ",")}{" "}
          and the {canonicalVariants[1].label} configured with{" "}
          {canonicalVariants[1].variantLabel
            .replaceAll("GB", "GB storage")
            .replaceAll("GB storage |", "GB RAM,")
            .replace(";", ",")}
          .{" "} */}
          {comparison?.metadata?.prices.length == 2 && (
            <>
              At the time of writing the {canonicalVariants[0].label} cost{" "}
              {centsToDollars(comparison?.metadata?.prices[0]?.price)} and the{" "}
              {canonicalVariants[1].label} cost{" "}
              {centsToDollars(comparison?.metadata?.prices[1]?.price)}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[0] && (
            <>
              {" "}
              The {canonicalVariants[0].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[0])}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[1] && (
            <>
              {" "}
              The {canonicalVariants[1].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[1])}.
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {comparison?.metadata?.prices.length == 2 && (
            <>
              At the time of writing the {canonicalVariants[0].label} cost{" "}
              {centsToDollars(comparison?.metadata?.prices[0]?.price)} and the{" "}
              {canonicalVariants[1].label} cost{" "}
              {centsToDollars(comparison?.metadata?.prices[1]?.price)}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[0] && (
            <>
              {" "}
              The {canonicalVariants[0].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[0])}.
            </>
          )}
          {hasLargePriceDrop && hasLargePriceDrop[1] && (
            <>
              {" "}
              The {canonicalVariants[1].label} has dropped in price by{" "}
              {centsToDollars(priceDrop[1])}.
            </>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Heading id="what-we-think">What we think</Heading>

      <div className="max-w-screen-md m-auto">
        <Byline
          products={products}
          author={`The PerfectRec ${productCategoryConfig.noun} team`}
          comparisonUpdated={comparison?.updated_at}
        />
        <p className="text-base !mb-1">
          {comparison.result}{" "}
          <span
            onClick={() => {
              setFeedbackModalOpen(true);
            }}
            className="inline-flex"
          >
            <span className="text-gray-400 hover:cursor-pointer hover:underline">
              Give&nbsp;Feedback
            </span>
            <ArrowRightIcon className="h-5 w-5 pl-1 text-gray-400 hover:cursor-pointer hover:underline relative top-1" />
          </span>
        </p>
        {productVariants && allProducts ? (
          <p className="text-sm text-gray-400 inline-flex mb-1">
            <span>{getCopy(products[0].metadata.categoryName)} </span>
          </p>
        ) : (
          <BlankTextPlaceholder
            textForSizing={`this description is based on the product variant with some specs and product variant with some specs. At the time of writing, the variant with some specs cost some dollars and the variant with some specs cost some dollars.`}
          />
        )}
      </div>
      <Modal
        onClose={() => setFeedbackModalOpen(false)}
        modalOpen={feedbackModalOpen}
        omitBottomButtons
        title="Share your feedback"
      >
        <LlmFeedbackForm hiddenFields={{}} />
      </Modal>
    </>
  );
};

/**
 * A section that contains brand explainers.
 */
const Brands = ({ products }: { products: Product[] }) => {
  const { getProductBrands } = useContext(ProductCategoryContext);

  const brands = useMemo(() => {
    const brands = getProductBrands(products);

    if (brands.length === 0) {
      return [];
    }

    return brands;
  }, [getProductBrands, products]);

  return (
    <div className="flex flex-col gap-8 max-w-screen-md mx-auto my-8">
      {brands
        .filter((brand) => brand.description)
        .map((brand, i) => (
          <div key={i}>
            <h3 className="text-center text-2xl mb-4">About {brand.label}</h3>
            <div>{brand.description}</div>
          </div>
        ))}
    </div>
  );
};

const aggregateComparisons = (
  productSets: Product[][],
  compProducts: Product[]
) => {
  const comparisons = [];

  compProducts.forEach((compProduct, i) => {
    productSets[i].forEach((setProduct) =>
      comparisons.push([compProduct, setProduct])
    );
  });

  return comparisons;
};

/**
 * The shopping/reviews section.
 */
const Shopping = ({ selectedProducts }: { selectedProducts: Product[] }) => {
  const { hasPrice: hasPriceA, amazonPurchaseOption: amazonPurchaseOptionA } =
    usePurchaseData(selectedProducts[0]);
  const { hasPrice: hasPriceB, amazonPurchaseOption: amazonPurchaseOptionB } =
    usePurchaseData(selectedProducts[1]);
  const hasPriceMap = [hasPriceA, hasPriceB];

  const productsWithSection = selectedProducts.filter(
    (product, i) => hasPriceMap[i] || product.review
  );

  if (productsWithSection?.length === 0) {
    return null;
  }

  const amazonOptions = [amazonPurchaseOptionA, amazonPurchaseOptionB];

  return (
    <>
      <Heading id="shopping">Shopping</Heading>
      <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-4">
        {productsWithSection.map((product, i) => (
          <StandardCard
            title={getProductLabels(product).shortLabel}
            key={product.id + i}
          >
            {hasPriceMap[i] && (
              <StandardCardSectionHorizontal
                title="Start shopping"
                last={!product.review && !amazonOptions[i]}
              >
                <PurchaseData product={product} />
              </StandardCardSectionHorizontal>
            )}
            {(product.review || amazonOptions[i]) && (
              <StandardCardSectionHorizontal
                title="See more"
                description="Dig into reviews and images"
                last={true}
              >
                <div className="flex flex-col gap-2" id={`shopping${i + 1}`}>
                  <ReviewCard review={product.review} />
                  <SeeOnAmazon amazonPurchaseOption={amazonOptions[i]} />
                </div>
              </StandardCardSectionHorizontal>
            )}
          </StandardCard>
        ))}
      </div>
    </>
  );
};

export const Heading = ({
  children,
  className = "",
  id,
  tocName,
  ctaType,
}: {
  children: React.ReactNode;
  className?: string;
  id?: string;
  tocName?: string;
  ctaType?: string;
}) => {
  return (
    <ProductHeading
      {...{ id, tocName }}
      className={className}
      scrollMarginClass=" xl:scroll-mt-[200px]"
      ctaType={ctaType}
    >
      {children}
    </ProductHeading>
  );
};

/**
 * A CTA that floats at the bottom and is visible once you scroll past the top CTA.
 */
export const StickyBottomCta = ({
  observeRef,
  productCategory,
}: {
  observeRef: React.RefObject<HTMLTableCellElement>;
  productCategory: ProductCategoryConfig;
}) => {
  const { refVisible } = useElementOnScreen(observeRef, [], 0);

  return (
    <div
      className={`${
        refVisible ? "hidden" : "sticky"
      } bottom-0 py-1 w-full bg-white z-10`}
    >
      <LinkButton
        href={getRecommenderUrl(productCategory)}
        className="w-full"
        onClick={() =>
          EventTracker.track(Events.VsPageGetRecsClick, {
            category: productCategory.name,
          })
        }
      >
        See what&apos;s better for you
      </LinkButton>
    </div>
  );
};

const Byline = ({
  comparisonUpdated,
  author,
  products,
}: {
  comparisonUpdated: string;
  author: string;
  products: Product[];
}) => {
  const { allPurchaseData } = useAllPurchaseData();

  const purchaseData = useMemo(() => {
    const productIds = products?.map((product) => product.id);

    return allPurchaseData?.filter((purchaseData) =>
      productIds?.includes(purchaseData.productId)
    );
  }, [products, allPurchaseData]);

  return (
    <div className="flex gap-2 my-2">
      <div className="h-12 w-12 rounded-full border-gray-500 border p-1 flex items-center justify-center">
        <ImageWithFallback
          src={getImageUrl("perfectrec-icon.png", AssetType.Image)}
          className="h-7 w-7"
          alt="PerfectRec Logo"
        />
      </div>
      <div className="text-sm text-gray-500">
        <div className="font-bold flex gap-4">
          {author}{" "}
          <Link
            href="#trust"
            className="text-sm text-blue-600 flex gap-4 items-center"
          >
            Learn more
            <ChevronDownIcon className="h-4 w-4" />
          </Link>
        </div>
        <div>
          Updated {displayDate(comparisonUpdated).toDay()}
          <span className="mx-2">·</span>{" "}
          {purchaseData && (
            <span>Prices updated {getPriceLastUpdated(purchaseData)} ago</span>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Renders the correct charts for the current category and product set.
 */
const Charts = (props: {
  productCategory: ProductCategoryConfig;
  attributes: AttributeConfigurationMap;
  compareProducts: Product[];
  selectedProductIndex: number;
}) => {
  const { products: allProducts } = useGlobalAllProducts();

  const getRank = (product: Product) => {
    if (product.id === props.compareProducts[0].id) {
      return "A";
    }
    if (product.id === props.compareProducts[1].id) {
      return "B";
    }
  };

  const pageType = "vs";

  const chartProps = { ...props, getRank, allProducts, pageType };

  return (
    <div className="flex flex-col gap-12 my-4">
      {allProducts && (
        <>
          {props.productCategory.name === "smartphones" && (
            <PhoneChartsStandard {...chartProps} />
          )}
          {props.productCategory.name === "tvs" && (
            <TvChartsStandard {...chartProps} />
          )}
          {props.productCategory.name === "headphones" && (
            <HeadphonesChartsStandard {...chartProps} />
          )}
          {props.productCategory.name === "laptops" && (
            <LaptopChartsStandard {...chartProps} />
          )}
          {props.productCategory.name === "monitors" && (
            <MonitorChartsStandard {...chartProps} />
          )}
        </>
      )}
    </div>
  );
};

export default VsPage;
