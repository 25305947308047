import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { IMediaHighlight } from "@lib/model/contentful";
import { MediaHighlight } from "@components/page/featured-in";
import { useState } from "react";
import Modal from "@components/modal/modal";
import {
  addCommas,
  lowerCaseCategoryWord,
} from "@lib/utilities/shared-utilities";
import {
  capitalizeFirstLetter,
  capitalizeWordsExceptCommon,
} from "@lib/utilities/client-utilities";
import { PrimaryButton, TextButton } from "./button";

const experts = [
  {
    name: "Joe Golden, Ph.D",
    title: "CEO and [Product] Editor",
    description:
      "Joe is an entrepreneur and lifelong electronics enthusiast with a Ph.D in Economics from the University of Michigan.",
  },
  {
    name: "Jason Lew",
    title: "Staff Expert & Software Engineer",
    description:
      "Jason is a staff expert and software engineer that has been making laptop recommendations for 7 years and moderates one of the largest laptop subreddits.",
  },
  {
    name: "Chandradeep Chowdhury",
    title: "Staff Expert & Software Engineer",
    description:
      "Chandradeep is a staff expert and software engineer and expert in televisions and monitors. He’s been making monitor recommendations for ten years.",
  },
  {
    name: "Seth Peoples",
    title: "[Product] Expert",
    categories: ["smartphones"],
    description:
      "Seth is a US-based product expert focusing on smartphones and he is a moderator of one of the largest smartphone subreddits. His tailored recommendations have helped him earn over 87k comment karma, putting him in the top 1% of Redditors.",
  },
  {
    name: "Efthimis Kotanidis",
    title: "[Product] Expert",
    categories: ["smartphones"],
    description:
      "Efthimis is a Greece-based product expert focusing on smartphones. He has been recommending phones for 4 years and his recommendations on Reddit have earned him over 35k karma.",
  },
  {
    name: "Craig Russell",
    title: "[Product] Expert",
    categories: ["laptops"],
    description:
      "Craig is a UK-based laptops expert. Craig works in IT, where he recommends and supports laptops and PCs for clients and has been recommending laptops on Reddit for five years.",
  },
  {
    name: "Jaime Roldán",
    title: "[Product] Expert",
    categories: ["tvs"],
    description:
      "Jaime is a Colombia-based TV expert. He is an electronics engineer with 8 years of experience in the telecom sector and has been making TV recommendations for 12 years.",
  },
  {
    name: "Luis Torres",
    title: "[Product] Expert",
    categories: ["headphones"],
    description:
      "Luis is a Mexico-based headphones expert. He’s been making headphone recommendations for five years and has close to 100 pairs in his personal collection.",
  },
];

/**
 * The value of stats per product category.
 */
const stats = {
  smartphones: {
    productsEvaluated: 68,
    statsCompiled: 115 * 68,
    propRatings: 8,
    gaTotalUsers: 8580,
    minuteDifferenceToRead: 34,
    consumerHoursSavedContent: (
      <>
        The average American reads at 200 words per minute. We compared the time
        it would take to read and complete our phone questionnaire (1 minute 30
        seconds) with the time it would take to read the NYT Wirecutter&apos;s
        guides to iPhones and Androids (36 minutes).
      </>
    ),
  },
  tvs: {
    productsEvaluated: 385,
    statsCompiled: 33110,
    propRatings: 21,
    gaTotalUsers: 4086,
    minuteDifferenceToRead: 9,
    consumerHoursSavedContent: (
      <>
        The average American reads at 200 words per minute. We compared the time
        it would take to read and complete our TV questionnaire (2 minutes) to
        the time it would take to read the NYT Wirecutter&apos;s TV Buying Guide
        and TV Guide (11 minutes)
      </>
    ),
  },
  laptops: {
    productsEvaluated: 380,
    statsCompiled: 128 * 380,
    propRatings: 13,
    gaTotalUsers: 2979,
    minuteDifferenceToRead: 13,
    consumerHoursSavedContent: (
      <>
        The average American reads at 200 words per minute. We compared the time
        it would take to read and complete our laptops questionnaire (2 minutes)
        to the time it would take to read the NYT Wirecutter&apos;s Best Laptops
        Guide (15 minutes).
      </>
    ),
  },
  headphones: {
    productsEvaluated: 108,
    statsCompiled: 105 * 108,
    propRatings: 15,
    gaTotalUsers: 2500,
    minuteDifferenceToRead: 9,
    consumerHoursSavedContent: (
      <>
        The average American reads at 200 words per minute. We compared the time
        it would take to read and complete our headphones questionnaire (2
        minutes) to the time it would take to read the NYT Wirecutter&apos;s
        Best Headphones Guide (11 minutes).
      </>
    ),
  },
  monitors: {
    productsEvaluated: 210,
    statsCompiled: 50 * 210,
    propRatings: 15,
    gaTotalUsers: 3100,
    minuteDifferenceToRead: 9,
    consumerHoursSavedContent: (
      <>
        The average American reads at 200 words per minute. We compared the time
        it would take to read our monitors product and comparison pages (2
        minutes) to the time it would take to read the NYT Wirecutter&apos;s
        Best Monitors Guide (11 minutes).
      </>
    ),
  },
};

/**
 * We are projecting a certain rate of recommendations since a date.
 */
const getRecommendationsMade = (
  gaTotalUsers: number,
  dateMeasured = "2023-12-22"
) => {
  const today = new Date();
  const targetDate = new Date(dateMeasured);

  // Calculate the difference in milliseconds
  const differenceMs = today.getTime() - targetDate.getTime();
  const daysSinceStatMeasure = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
  return 5 * gaTotalUsers + 300 * daysSinceStatMeasure;
};

/**
 * The type of stats we support.
 */
const statBlocks = [
  {
    getTitle: ({ category }) =>
      `${capitalizeFirstLetter(category.pluralNoun)} evaluated`,
    getNumber: (stat) => stat.productsEvaluated,
  },
  {
    getTitle: ({ category }) =>
      `${capitalizeFirstLetter(category.pluralNoun)} stats compiled`,
    getNumber: (stat) => stat.statsCompiled,
  },
  {
    getTitle: ({ category }) =>
      `Proprietary ${capitalizeFirstLetter(
        category.pluralNoun
      )} ratings developed`,
    getNumber: (stat) => stat.propRatings,
  },
  {
    title: "Recommendations made",
    getNumber: (stat) => getRecommendationsMade(stat.gaTotalUsers),
  },
  {
    title: "Consumer hours saved",
    getModalContent: (stat) => stat.consumerHoursSavedContent,
    getNumber: (stat) =>
      Math.ceil(
        (getRecommendationsMade(stat.gaTotalUsers) *
          stat.minuteDifferenceToRead) /
          60
      ),
  },
];

const Trust = ({
  category,
  mediaHighlights,
}: {
  category: ProductCategoryConfig;
  mediaHighlights: IMediaHighlight[];
}) => {
  return (
    <div className="rounded-md py-6 px-4 border border-gray-500">
      <div className="sm:pb-6 sm:border-b border-gray-500 max-w-screen-md mb-10 mx-auto text-xl font-semibold text-center">
        This information was produced and vetted by the PerfectRec{" "}
        {lowerCaseCategoryWord(category, "label")} team. We are a product
        research and recommendation organization that meticulously reviews and
        evaluates the latest {lowerCaseCategoryWord(category, "noun")}{" "}
        information and makes it digestible for you.
      </div>

      <div className="flex flex-col gap-8 sm:gap-4 sm:flex-row">
        {stats[category.name] && (
          <div className="min-w-[268px] flex flex-col items-center gap-4 justify-start">
            <TrustHeading>By the numbers</TrustHeading>
            <div className="flex flex-col gap-6">
              {statBlocks.map((statBlock, i) => (
                <Stat
                  label={
                    statBlock.getTitle
                      ? statBlock.getTitle({
                          category,
                        })
                      : statBlock.title
                  }
                  number={statBlock.getNumber(stats[category.name])}
                  key={`${statBlock.title}--${i}`}
                  modalContent={
                    statBlock.getModalContent
                      ? statBlock.getModalContent(stats[category.name])
                      : undefined
                  }
                />
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-4 justify-center">
          <TrustHeading>About the {category.noun} team</TrustHeading>
          <div className="lg:columns-2 gap-4">
            {experts
              .filter(
                ({ categories }) =>
                  !categories || categories.includes(category.name)
              )
              .map((expert) => (
                <ExpertCard
                  {...expert}
                  key={expert.name}
                  className="mb-2 sm:mb-4 break-inside-avoid"
                  category={category}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <TrustHeading className="my-6 lg:mt-10 lg:mb-6">
          Featured in
        </TrustHeading>
        <div className="flex flex-wrap justify-center gap-y-4 gap-x-12">
          {mediaHighlights
            .filter((highlight) => highlight.fields.displayOnTrustCard)
            .map((highlight) => (
              <MediaHighlight
                highlight={highlight}
                className="h-[50px] max-w-[124px]"
                key={highlight.sys.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const Stat = ({
  number,
  label,
  modalContent,
}: {
  number: number;
  label: string;
  modalContent?: React.ReactNode;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="text-center" key={label}>
      <div className="text-3xl font-bold">{addCommas(number)}</div>
      <div className="text-sm text-gray-500">{label}</div>
      {modalContent && (
        <>
          <TextButton
            className="mx-auto my-2"
            onClick={() => setModalOpen(true)}
          >
            Learn more
          </TextButton>
          <Modal modalOpen={modalOpen} onClose={() => setModalOpen(false)}>
            {modalContent}
          </Modal>
        </>
      )}
    </div>
  );
};

const TrustHeading = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?;
}) => {
  return (
    <h3
      className={`font-sans mx-auto rounded-full small-caps text-base inline-block px-2 py-0.5 font-bold bg-gray-100 ${className}`}
    >
      {children}
    </h3>
  );
};

const ExpertCard = ({
  title,
  name,
  description,
  className = "",
  category,
}: {
  title: string;
  name: string;
  description: React.ReactNode;
  className: string;
  category: ProductCategoryConfig;
}) => {
  return (
    <div className={`border border-gray-300 py-4 px-2 rounded-md ${className}`}>
      <div className="mb-2">
        <h3 className="font-sans text-base font-bold mb-1">{name}</h3>
        <div className="textgray-500 text-xs">
          {capitalizeWordsExceptCommon(
            title.replace("[Product]", category.pluralNoun)
          )}
        </div>
      </div>
      <div>{description}</div>
    </div>
  );
};

export default Trust;
