// @TODO - This file should move out of the "server folder"
export const ClassifiedAttributes = {
  /**
   * This helper can go away when we have uids for answers,
   * we can build a lookup from answer guid -> attributes.
   */
  questionToAttributeHack: {
    smartphones: {
      "cf106da8-0388-47ed-b2de-3fa94d9af922": "cameraScore",
      "fc9c7fe5-5544-49dc-bcf9-4bca2eaa90d2": "batteryLife",
      "339252bb-c599-4995-b3a9-6f8e09317dd8": "screenSize",
      "ebaf4b02-d071-4e80-bd67-4441c993153a": "budget",
    },
    tvs: {
      "c241646a-0165-477a-b540-66eb93fea187": "brightRoomScore",
      "f0950652-5699-432c-b741-f3fd40680504": "screenSize",
      "b6d3f6fc-3781-4aa3-b4a5-86967915a786": "screenScore",
      "9969344a-dab9-4db4-9aa9-365dbfc03597": "price",
    },
    laptops: {
      "6e7510b3-47e0-4cbb-925d-97eab4b6ce9d": "batteryLifeHours",
      "6580cdb5-4914-4358-9b75-5e5e8609f798": [
        "mobilityScore",
        "thickness",
        "weight",
      ],
      "01585282-7858-449b-9eea-ad7f9a67a70b": "screenSize",
    },
    headphones: {},
  },

  answerToAttributeHack: {
    smartphones: {},
    tvs: {
      "e957a026-e312-4dc1-ab19-e360ef3227c7": "movieScore",
      "fd1355bf-66ba-44e1-9997-4a277f6efb1a": "sportsScore",
      "7efbcb67-dac0-4761-95b7-6dd0bf70e660": "gamingScore",
      "43eccfb2-e90a-4623-be19-ccfc7ee208be": "newsScore",
      "31a3215f-6199-4001-9e74-270d2b6a2e9d": "animationScore",
    },
    laptops: {
      "4ec9cec0-5084-414f-aecb-5c4bb5cb2a58": "generalUseScore",
      "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5": "gamingScore",
      "3dc9b48b-ee75-4702-9560-e8f65111125b": "creativeUseScore",
    },
    headphones: {
      "77120855-0e90-4836-a19d-d90fed8efbb2": "musicScoreRescaled",
      "fe4108c8-eef6-4633-959b-2e7ab4ae681e": "callScoreRescaled",
      "118fe58c-d91a-42c6-b62f-62054806d850": "gamingScoreRescaled",
      "5d0db25c-17f7-417a-98b3-b162318ae6c6": "tvScoreRescaled",
      "5d3e5408-d317-4003-ad64-f94f74e3330f": "podcastScoreRescaled",
      "e4b172ef-3081-427b-bb2d-69652b359a5f": "exerciseScoreRescaled",
      "759288d6-dc1c-4382-ab38-178ae0432ab6": "travelScoreRescaled",
    },
  },
};
