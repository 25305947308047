import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import React from "react";
import { TvContentPhraseMap } from "./tv-fns";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { useResponseAnswers } from "@lib/hooks/use-multi-response";
import { useAttribute } from "@lib/hooks/use-attributes";
import { ScaleChoiceClassification } from "@lib/attribute/classification";

interface TvScreenMeterProps extends PanelSetProps {
  attributeName: string;
}

const TvScreenMeter = (props: TvScreenMeterProps) => {
  const { attributeName, selectedProduct } = props;

  const explanationType =
    attributeName === "screenScore"
      ? "pictureQualityExplanation"
      : "contentExplanation";

  const pictureQualityQuestionId = "b6d3f6fc-3781-4aa3-b4a5-86967915a786";

  const { explanation } = useExplanation(explanationType, selectedProduct);
  const attributeConfiguration = useAttribute(attributeName);
  const { answer: pictureQualityAnswer } = useResponseAnswers(
    pictureQualityQuestionId
  );

  const pictureQualityAnswerValue = parseFloat(pictureQualityAnswer?.mainText);

  if (!props.selectedProduct.attributes[attributeName]) {
    return null;
  }

  const score = parseFloat(
    props.selectedProduct.attributes[attributeName]?.value
  );

  const title =
    attributeName === "screenScore"
      ? `Picture Quality`
      : `${TvContentPhraseMap[attributeName].subjectPlural}`;

  const first =
    attributeName === "screenScore" ? (
      <>
        The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
        <strong>
          {getQualitativeWord(score)
            .toLowerCase()
            .replace("best", "the best")
            .replace("fair", "only fair")}
        </strong>{" "}
        picture quality.
      </>
    ) : (
      <>
        The {getProductLabels(props.selectedProduct).shortLabel} is{" "}
        <strong>
          {getQualitativeWord(score)
            .toLowerCase()
            .replace("best", "the best")
            .replace("fair", "only fair")}
        </strong>{" "}
        for {TvContentPhraseMap[attributeName]?.verb?.toLowerCase()}
      </>
    );

  const screenScorePhrase =
    ScaleChoiceClassification.TvPictureScale[pictureQualityAnswerValue];

  const second =
    attributeName === "screenScore"
      ? screenScorePhrase
        ? props.isShare
          ? `
          I said ${screenScorePhrase}
        `
          : `
          You said ${screenScorePhrase
            ?.replace("I'm", "you are")
            ?.replace("I", "you")}
        `
        : ""
      : props.isShare
      ? `I said I ${TvContentPhraseMap[attributeName]?.verb}`
      : `You said you ${TvContentPhraseMap[attributeName]?.verb}`;

  const color =
    attributeName === "screenScore"
      ? getMeetsNeedsColor(!explanation.isUnmet)
      : getMeetsNeedsColor(
          !(
            (score < 6 && pictureQualityAnswerValue === 3) ||
            (score < 7 && pictureQualityAnswerValue === 4)
          )
        );

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title:
          attributeConfiguration.displayName || attributeConfiguration.name,
        text: attributeConfiguration.explanation,
      }}
    >
      <ArcScoreDisplay
        product={props.selectedProduct}
        attribute={attributeConfiguration}
        icon={getAspectIcon("tvs", attributeConfiguration.slug)}
        score={score}
        color={color}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default TvScreenMeter;
