import { getPage } from "@server/contentful";
import {
  getVerticalRouteVars,
  getProductPageData,
  getScenarioPageData,
  getVerticalIndexPageVars,
  getCompareIndexData,
  getVsPageData,
  getVsRouteVars,
  getCategoryProductsFirstPage,
  getScenariosIndexPageData,
} from "@server/page-data";
import ProductLandingPage from "@components/page/product-landing-page";
import LandingPage from "@components/page/landing-page";
import ScenarioPage from "@components/page/scenario-page";
import VsPage from "@components/page/vs-page";
import CompareIndexPage from "@components/page/compare-index-page";
import CategoryProductsPage from "@components/page/category-products-page";
import { TrackingPageProps } from "@lib/page-props-tracking-interface";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import errorTracker from "@lib/tracking/error-tracker";
import { getServerCacheProductCategoryConfig } from "@lib/fetching/product-category-config";
import { createContext } from "react";
import ScenariosPage from "@components/page/scenarios";
import { isInternalUser } from "@lib/utilities/global-utilities";

export const GenericPathContext = createContext<{ pageType: string }>(null);

/**
 * A lot of distinct pages route through this path handler because we tend to
 * "silo" content by vertical (e.g. electronics).
 */
const Page = (
  props: {
    // Generic data needed for rendering.
    data: any;
    configMap: ProductCategoryConfigMap;
    isInternalUser: boolean;
  } & TrackingPageProps
) => {
  const { component } = props;

  return (
    <GenericPathContext.Provider value={{ pageType: component }}>
      {component === "product-landing-page" && (
        <ProductLandingPage {...props.data} configMap={props.configMap} />
      )}
      {component === "scenario-page" && (
        <ScenarioPage {...props.data} configMap={props.configMap} />
      )}
      {component === "landing-page" && (
        <LandingPage {...props.data} configMap={props.configMap} />
      )}
      {component === "vs-page" && (
        <VsPage
          {...props.data}
          isInternalUser={props.isInternalUser}
          configMap={props.configMap}
        />
      )}
      {component === "compare-index-page" && (
        <CompareIndexPage {...props.data} configMap={props.configMap} />
      )}
      {component === "category-products-page" && (
        <CategoryProductsPage {...props.data} configMap={props.configMap} />
      )}
      {component === "category-scenarios-page" && (
        <ScenariosPage {...props.data} configMap={props.configMap} />
      )}
    </GenericPathContext.Provider>
  );
};

export default Page;

/**
 * We use server-side-props here mainly because we need to render paged links
 * with query params on the server.
 */
export const getServerSideProps = errorTracker.wrapFunction(
  async ({ params, query, req }) => {
    const { path } = params;
    const notFound = {
      notFound: true,
    };

    // This is a manifest of routes that this Next page must handle. The keys determine
    // which component to use to render the page.
    const routes = {
      "vs-page": {
        getRouteVars: getVsRouteVars,
        getRouteData: getVsPageData,
      },
      "product-landing-page": {
        getRouteVars: getVerticalRouteVars,
        getRouteData: getProductPageData,
      },
      "scenario-page": {
        getRouteVars: getVerticalRouteVars,
        getRouteData: getScenarioPageData,
      },
      "landing-page": {
        getRouteVars: (path: string[]) => path.join("/"),
        getRouteData: async (path: string) => {
          const page = await getPage(path);
          return page ? { page: page.fields } : null;
        },
      },
      "compare-index-page": {
        getRouteVars: (path: string[], query) =>
          getVerticalIndexPageVars(path, "compare", query),
        getRouteData: getCompareIndexData,
      },
      "category-products-page": {
        getRouteVars: (path: string[], query) =>
          getVerticalIndexPageVars(path, "products", query),
        getRouteData: getCategoryProductsFirstPage,
      },
      "category-scenarios-page": {
        getRouteVars: (path: string[], query) =>
          getVerticalIndexPageVars(path, "scenarios", query),
        getRouteData: getScenariosIndexPageData,
      },
    };

    const configMap = await getServerCacheProductCategoryConfig();

    for (const component of Object.keys(routes)) {
      const routeDef = routes[component];
      const routeVars = await routeDef.getRouteVars(path, query);

      // If this route type was able to derive its variables from the route, try
      // to get data.
      if (routeVars) {
        const data = await routeDef.getRouteData(routeVars, query);

        if (data) {
          return {
            props: {
              component,
              data: JSON.parse(JSON.stringify(data)),
              configMap,
              isInternalUser: isInternalUser(req.cookies),
            },
          };
        }
      }
    }

    return notFound;
  }
);
