import { Product } from "../model/product.js";
import { ProductVariant } from "../../../data/packages/model/src/product.js";
import PurchaseOption from "../model/purchase-option.js";

/**
 * Sorts a PurchaseData array in the following order: in/out of stock, new/refurbished/used, and price.
 *
 * @param purchaseData A PurchaseData array.
 * @returns A sorted PurchaseData array.
 */
export const sortPurchaseData = (purchaseData: PurchaseOption[]) => {
  return purchaseData.sort((a, b) => {
    const inStockCompare = (a, b) => {
      if (a == b) {
        return 0;
      } else if (a) {
        return -1;
      } else if (b) {
        return 1;
      }
    };
    const conditionCompare = (a, b) => {
      const conditionMap = new Map([
        ["new", 1],
        ["refurbished", 0],
        ["used-excellent", -1],
        ["used-good", -2],
        ["used-mixed", -3],
        ["used", -3],
        ["used-fair", -4],
      ]);
      if (a === b) {
        return 0;
      } else if (conditionMap.get(a) > conditionMap.get(b)) {
        return -1;
      } else if (conditionMap.get(a) < conditionMap.get(b)) {
        return 1;
      }
    };
    const priceCompare = (a, b) => {
      return a - b;
    };
    return (
      inStockCompare(a.inStock, b.inStock) ||
      conditionCompare(a.condition, b.condition) ||
      priceCompare(a.price, b.price)
    );
  });
};

/**
 * Returns the best purchase option. See sortPurchaseData function for how the best purchase option is chosen.
 * @param purchaseData A PurchaseData array.
 * @returns A PurchaseData object.
 */
export const pickBestPurchaseOption = (purchaseData: PurchaseOption[]) => {
  return sortPurchaseData(purchaseData)[0];
};

/**
 * Return the best purchase variant based on the purchase data.
 *
 * Leverages the pickBestPurchaseOption function to determine the best variant,
 * with a fallback to the product id if no variants are available.
 * @param { variants, variantPurchaseData, productId } - The variants, corresponding purchase data, and productId.
 * @returns { string } - The best purchase variant id.
 */
export const pickBestPurchaseVariantId = ({
  variants,
  variantPurchaseData,
  product,
}: {
  variants: ProductVariant[];
  variantPurchaseData: PurchaseOption[];
  product: Product;
}): string => {
  const bestPurchaseLinkId = product.bestPurchaseLinkId;
  const noVariantData = !variants?.length || !variantPurchaseData?.length;

  if (noVariantData || !bestPurchaseLinkId) return product.id;

  const bestPurchaseData = variantPurchaseData.find((purchaseOption) => {
    return purchaseOption.linkId === bestPurchaseLinkId;
  });

  const bestVariantId =
    bestPurchaseData?.variantId || bestPurchaseData?.productId;

  return bestVariantId || product.id;
};
