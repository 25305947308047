import Modal from "@components/modal/modal";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import React, { useState } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

export interface AnswerModalProps {
  shortName: string;
  explanation?: string;
  value: string;
  explanationJsx?: React.ReactNode;
}

const AnswerModal = (props: AnswerModalProps) => {
  const [open, setOpen] = useState(false);

  const trackAnswerModalStateOpen = () => {
    EventTracker.track(Events.ModalOpen, {
      explanationType: "Answer Explanation",
      answerId: props.value,
      answerLabel: props.shortName,
    });
  };

  const openHandler = (e) => {
    e.stopPropagation();
    setOpen(true);
    trackAnswerModalStateOpen();
  };

  return (
    <>
      <QuestionMarkCircleIcon
        tabIndex={0}
        className="h-6 w-6 fill-gray-400 stroke-white flex-shrink-0 cursor-pointer"
        onClick={(e) => {
          openHandler(e);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            openHandler(e);
          }
        }}
      />
      <Modal
        onClose={() => setOpen(false)}
        modalOpen={open}
        title={props.shortName}
      >
        {props.explanation && (
          <div
            className="whitespace-pre-line content-wrapper"
            dangerouslySetInnerHTML={{ __html: props.explanation }}
          ></div>
        )}
        {props.explanationJsx && <>{props.explanationJsx}</>}
      </Modal>
    </>
  );
};

export default AnswerModal;
