import HeatMeter, { getTicksForRange } from "./heat-meter";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import React from "react";
import {
  ArcScoreDisplay,
  PanelSetProps,
  SummaryTitles,
} from "@components/product/summary/common";
import { Battery100Icon } from "@heroicons/react/24/solid";
import { useAttributes } from "@lib/hooks/use-attributes";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { laptopContentPhraseMap } from "./laptop-usage-meter";
import { getAspectIcon } from "@components/compare/compare-fns";

const LaptopBatteryMeter = (props: PanelSetProps) => {
  const { attributes } = useAttributes();

  const { explanation } = useExplanation(
    "batteryExplanation",
    props.selectedProduct
  );

  const max = 10;

  const rating = parseFloat(
    props.selectedProduct.attributes["batteryRating"].value
  );

  const score = parseFloat(
    props.selectedProduct.attributes["batteryLifeHours"].value
  );

  const pct = score / max;

  const title = `Battery`;

  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(rating)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      battery life.
    </>
  );

  const second = `Battery life estimate is based on a mix of common use patterns. More portable and higher performing laptops tend to have less battery life.`;

  const color = getMeetsNeedsColor(!explanation.isUnmet);

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: attributes["batteryLifeHours"].name,
        text: attributes["batteryLifeHours"].explanation,
      }}
    >
      <ArcScoreDisplay
        product={props.selectedProduct}
        attribute={attributes["batteryLifeHours"]}
        icon={({ className }) => <Battery100Icon className={className} />}
        score={rating}
        color={color}
        max={10}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopBatteryMeter;
