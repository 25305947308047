import { AttributeConfiguration } from "../../../data/packages/model/src/productAttribute";
import { Product } from "@lib/model/product";
import {
  AttributeConfigurationMap,
  ClassificationName,
} from "@lib/model/attribute";
import {
  SparklesIcon,
  SunIcon,
  Battery100Icon,
  SpeakerWaveIcon,
  CameraIcon,
  PaintBrushIcon,
  CpuChipIcon,
  WrenchIcon,
  WrenchScrewdriverIcon,
  ChartBarIcon,
  TvIcon,
  FilmIcon,
  NewspaperIcon,
  PhotoIcon,
  MusicalNoteIcon,
  PhoneIcon,
  PaperAirplaneIcon,
  CodeBracketIcon,
  CommandLineIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  CheckIcon,
  Cog8ToothIcon,
  MicrophoneIcon,
  SpeakerXMarkIcon,
  CubeIcon,
  CloudIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import {
  addYearsToMonthYearNumeric,
  centsToDollars,
  displayDate,
  getRandomProducts,
  roundDecimal,
} from "@lib/utilities/shared-utilities";
import * as d3 from "d3";
import { TvContentPhraseMap } from "@components/product/summary/tv-fns";
import { laptopContentPhraseMap } from "@components/product/summary/laptop-usage-meter";
import {
  getHeadphonesConnectivityValue,
  getQualitativeCompareWeight,
  getQualitativeWord,
} from "@lib/utilities/product-classifier";
import { getFirst } from "./get-first";

/**
 * Gets attributes for the compare page, and sorts them.
 */
export const getAttributes = (configuration: AttributeConfigurationMap) => {
  return Object.keys(configuration).filter((att) => {
    return configuration[att]?.display;
  });
};

const booleanMap = {
  Yes: 1,
  No: 0,
};

const rankResolvers = {
  resolution: (value) => {
    const parts = value
      .split(" x ")
      .map((part) => parseInt(part.trim()))
      .filter((number) => !Number.isNaN(number));

    if (parts.length !== 2) {
      return NaN;
    }

    return parts[0] * parts[1];
  },
  resolutionHorizontal: (value) => {
    const order = ["480p", "720p", "1080p", "1440p", "2160p", "4k"];
    return order.findIndex((resolution) => value.toLowerCase() === resolution);
  },
  diskStorage: (value) => storageToBytes(value),
  boolean: (value) => {
    return booleanMap[value] !== undefined ? booleanMap[value] : 0;
  },
  fpsAtRes: (value) => {
    const regex = /(\d+)fps\s*@\s*(\w+)/i;
    const match = value.match(regex);

    if (match) {
      const fps = parseInt(match[1]);
      const resolution = match[2];

      const resMod = {
        "1080p": 1,
        "4k": 2,
      };

      return resMod[resolution] ? fps * resMod[resolution] : 0;
    } else {
      return 0;
    }
  },
  notNone: (value) => (value === "None" ? 0 : 1),
  extractFirstNumber: (value) => {
    const regex = /[-+]?\b\d+(\.\d+)?\b/;
    const match = value.match(regex);
    return match ? parseFloat(match[0]) : null;
  },
  tvDts: (value) => {
    const map = {
      Yes: 2,
      No: 0,
      Unknown: 0,
      "Yes, Bypass only": 1,
      "Up to DTS Digital Surround, Bypass only": 1,
    };

    return map[value] || 0;
  },
} as { [resolver: string]: (value: string) => number };

const releaseDate = (product: Product) => {
  return displayDate(product.attributes["releaseDate"]?.value).toDay();
};

/**
 * A list of resolvers for derived attributes.
 */
const derivedAttributes = {
  tvs: {
    screenSubtype: (product) => {
      return (
        product.attributes["panelType"]?.value +
        " " +
        product.attributes["panelSubtype"]?.value
      );
    },
    hdrSupportedFormats: (product) => {
      const formats = {
        hdr10Support: "HDR10",
        hdr10PlusSupport: "HDR10+",
        dolbyVisionSupport: "Dolby Vision",
        hlgSupport: "HLG",
      };

      const supported = Object.keys({
        hdr10Support: "HDR10",
        hdr10PlusSupport: "HDR10+",
        dolbyVisionSupport: "Dolby Vision",
        hlgSupport: "HLG",
      })
        // Only include those that do have support.
        .filter((slug) => product.attributes[slug]?.value === "Yes")
        // Find the display name and join with commas.
        .map((slug) => formats[slug])
        .join(", ");
      return supported || "None";
    },
    releaseDate,
  },
  laptops: {
    releaseDate,
  },
  tablets: {
    resolution: (product) => {
      return `${product.attributes["xRes"].value} x ${product.attributes["yRes"].value}`;
    },
    showAds: (product) => {
      const removeAdsPrice = product.attributes?.["removeAdsPrice"].value;
      const removeAdsDollars = parseFloat(removeAdsPrice);

      if (Number.isNaN(removeAdsDollars)) {
        return "";
      }
      return removeAdsDollars === 0
        ? "No"
        : `Remove for ${centsToDollars(removeAdsDollars * 100)}`;
    },
    releaseDate,
  },
  headphones: {
    connectivity: getHeadphonesConnectivityValue,
    cableLengthFeet: (product) => {
      if (!product.attributes?.["cableLength"]?.value) {
        return "N/A";
      }

      const result = roundDecimal(
        parseFloat(product.attributes?.["cableLength"]?.value) * 3.28
      );

      if (isNaN(result)) {
        return "N/A";
      }

      return result;
    },
    releaseDate,
  },
  smartphones: {
    osUpdatesUntil: (product) => formatUpdatesUntil(product, "osUpdateYears"),
    osSecurityUpdatesUntil: (product: Product) =>
      formatUpdatesUntil(product, "osSecurityYears"),
    refreshRateRange: (product) => {
      const min = product.attributes["refreshRateMin"]?.value;
      const max = product.attributes["refreshRateMax"]?.value;

      if (!min || !max) {
        return "N/A";
      }

      return `${min} hz to ${max} hz`;
    },
    reverseWirelessCharging: (product) => {
      const has = product.attributes["hasReverseWirelessCharging"]?.value;
      const speed = product.attributes["reverseWirelessChargingSpeed"]?.value;

      if (has === "No") {
        return has;
      }

      return `${has}  (${speed} watts)`;
    },
    biometricUnlock: (product) => {
      const display = [];
      const faceUnlockVal = product.attributes["faceUnlock"]?.value;
      const fingerprintSensorVal =
        product.attributes["fingerprintSensor"]?.value;

      if (faceUnlockVal && faceUnlockVal !== "No") {
        display.push("Face unlock");
      }

      if (fingerprintSensorVal && fingerprintSensorVal !== "No") {
        display.push(`Fingerprint sensor: ${fingerprintSensorVal}`);
      }

      return display.join(", ");
    },
    releaseDate,
  },
  monitors: {},
} as {
  [category: string]: {
    [attribute: string]: (
      product: Product,
      attributeConfig: AttributeConfiguration
    ) => string;
  };
};

/**
 * Given a product and a field that represents how many years a type of update is
 * supported, returns a formatted date that represents how long updates are supported
 * for. It is based on the year and month released of the product.
 *
 * Only supports phones at the moment.
 */
const formatUpdatesUntil = (product: Product, updateYearsField: string) => {
  const osUpdateYears = parseFloat(
    product.attributes?.[updateYearsField]?.value
  );
  const yearReleased = parseFloat(product.attributes?.["yearReleased"]?.value);
  const monthReleased = parseFloat(
    product.attributes?.["monthReleased"]?.value
  );

  if (
    Number.isNaN(osUpdateYears) ||
    Number.isNaN(yearReleased) ||
    Number.isNaN(monthReleased)
  ) {
    return "";
  }

  // [Year + month released] + [OS_Years]
  const date = addYearsToMonthYearNumeric(
    monthReleased,
    yearReleased,
    osUpdateYears
  );

  return date;
};

/**
 * Gets the display value for one product's attribute.
 */
export const getDisplayValue = (
  configuration: AttributeConfiguration,
  product: Product,
  decimalPlaces = 1
) => {
  // Format regular attribute values.
  if (!configuration.derived) {
    let value = product.attributes?.[configuration.slug]?.value;
    if (!value) return "";
    else if (value === "N/A" || value === "Unknown") return value;

    if (configuration.unit === "Score100") {
      value = value / 10;
    }

    const place = configuration.type === "rating" ? decimalPlaces : 1;
    return configuration.round || configuration.type === "rating"
      ? roundDecimal(value, place)
      : value;

    // Handle attributes that are concatenations of values from sibling products
    // e.g. phone color possibilities.
  } else if (product.supportedVariants.includes(configuration.slug)) {
    const values = product.variants.map(
      (variant) => variant.attributes[configuration.slug]
    );
    values.push(product.attributes[configuration.slug].value);
    return [...new Set(values)].join(", ");

    // Handle derived attributes with a resolver.
  } else if (
    derivedAttributes?.[product.metadata.categoryName]?.[configuration.slug]
  ) {
    const value = derivedAttributes[product.metadata.categoryName][
      configuration.slug
    ](product, configuration);

    return value;
  }
};

/**
 * For a given set of products, gets the IDs of those having the "best" value
 * in a given attribute.
 *
 * We support three types of rankings:
 * - Boolean, either "Yes" or "No"
 * - Numeric ratings e.g. attribute.type === "rating"
 * - Attributes with a rankDirection property
 *
 * The rankDirection property is either empty (assumes ASC), "ASC" or "DESC".
 * ASC means higher values (including 1 vs 0 in boolean fields) are best.
 */
export const getBestProductIds = (
  products: Product[],
  attribute: AttributeConfiguration
) => {
  if (products.length <= 1) {
    return [];
  }

  // We only try to assess ratings and attributes that are either themselves
  // rankable, or refer to a a rating as their ranking attribute. We also don't try
  // to rank if there is only one product.
  if (
    products.length <= 1 ||
    (attribute.type !== "rating" &&
      !attribute.rankingAttribute &&
      !attribute.rankDirection &&
      !attribute.rankResolver)
  ) {
    return [];
  }

  const rankingAtribute = attribute.rankingAttribute || attribute.slug;

  // Find the best value.
  const values = products.map((product) => {
    const value = product.attributes?.[rankingAtribute]?.value;

    if (attribute.rankResolver && rankResolvers?.[attribute?.rankResolver]) {
      return rankResolvers[attribute.rankResolver](value);
    } else {
      // Handle yes/no as 0/1.
      return roundDecimal(value, 1);
    }
  });

  let bestFn = Math.max;
  if (attribute.rankDirection === "DESC") {
    bestFn = Math.min;
  }

  const bestValue = bestFn(...values);

  // Check which products have the best value.
  const bestProducts = [];
  products.forEach((product, i) => {
    if (values[i] === bestValue) {
      bestProducts.push(product);
    }
  });

  // Check if they all have the same best value.
  if (bestProducts.length === products.length) {
    return [];
  }

  return bestProducts.map((product) => product.id);
};

/**
 * Most of these are attribute names, but not all. They correspond to product aspects
 * that we represent on the VS pages and/or done page, like icons or bits of text.
 */
const aspects = {
  tvs: {
    screenScore: {
      icon: TvIcon,
      label: "Better Picture",
    },
    movieScore: {
      icon: FilmIcon,
      label: "Better for Movies",
    },
    sportsScore: {
      icon: SparklesIcon,
      label: "Better for Sports",
    },
    animationScore: {
      icon: SparklesIcon,
      label: "Better for Animation",
    },
    newsScore: {
      icon: NewspaperIcon,
      label: "Better for News",
    },
    gamingScore: {
      icon: CubeIcon,
      label: "Better for Gaming",
    },
    brightRoomScore: {
      icon: SunIcon,
      label: "Better in a Sunny Room",
    },
    soundScore: {
      icon: SpeakerWaveIcon,
      label: "Better Sound",
    },
  },
  // We don't have key-specs blocks for these categories yet, so label is
  // blank.
  smartphones: {
    batteryLife: {
      icon: Battery100Icon,
      label: "Better Battery",
    },
    cameraScore: {
      icon: CameraIcon,
      label: "Better Camera",
    },
    pictureScore: {
      icon: PhotoIcon,
      label: "Better photos",
    },
    videoScore: {
      icon: FilmIcon,
      label: "Better videos",
    },
    screenSize: {
      icon: ArrowsPointingInIcon,
      label: "",
    },
    screenScore: {
      icon: SparklesIcon,
      label: "Better Screen",
    },
    performance: {
      icon: CpuChipIcon,
      label: "Better performance",
    },
  },
  laptops: {
    screenSize: {
      icon: ArrowsPointingInIcon,
      label: "",
    },
    batteryLifeHours: {
      icon: Battery100Icon,
      label: "Better Battery",
    },
    mobilityScore: {
      icon: ArrowsPointingOutIcon,
      label: "More portable",
    },
    gamingScore: {
      icon: CubeIcon,
      label: "Better for Gaming",
      mayDisplay: (products: Product[]) => {
        return (
          products[0].manufacturer !== "Apple" ||
          products[1].manufacturer !== "Apple"
        );
      },
    },
    overallScreenScore: {
      icon: TvIcon,
      label: "Better screen",
    },
    creativeUseScore: {
      label: "Better for creative use",
      icon: PaintBrushIcon,
    },
    generalUseScore: {
      label: "Better for general use",
      icon: WrenchIcon,
    },
    softwareScore: {
      label: "Better for software development",
      icon: CommandLineIcon,
    },
    contentScore: {
      label: "Better for content creation",
      icon: PhotoIcon,
    },
    keyboardQuality: {
      label: "Better keyboard",
      icon: CheckIcon,
    },
    buildQuality: {
      label: "Better build quality",
      icon: WrenchScrewdriverIcon,
    },
    overallPerformanceScore: {
      label: "Better overall performance",
      icon: ChartBarIcon,
    },
  },
  headphones: {
    compatibility: {
      icon: Cog8ToothIcon,
      label: "",
    },
    activeNoiseCancelling: {
      icon: SpeakerXMarkIcon,
      label: "",
    },
    noiseIsolationQualityRescaled: {
      icon: SpeakerXMarkIcon,
      label: "Better noise reduction",
    },
    musicScoreRescaled: {
      icon: MusicalNoteIcon,
      label: "Better for music",
    },
    callScoreRescaled: {
      icon: PhoneIcon,
      label: "Better for calls",
    },
    gamingScoreRescaled: {
      icon: CubeIcon,
      label: "Better for gaming",
    },
    tvScoreRescaled: {
      icon: TvIcon,
      label: "Better for TV",
    },
    podcastScoreRescaled: {
      icon: MicrophoneIcon,
      label: "",
    },
    soundProfile: {
      icon: PaintBrushIcon,
      label: "",
    },
    soundQualityRescaled: {
      icon: SpeakerWaveIcon,
      label: "Better Sound",
    },
    travelScoreRescaled: {
      icon: PaperAirplaneIcon,
      label: "Better for travel",
    },
    exerciseScoreRescaled: {
      icon: CheckIcon,
      label: "Better for exercise",
    },
    buildQualityRescaled: {
      icon: WrenchScrewdriverIcon,
      label: "Better build quality",
    },
    batteryLife: {
      icon: Battery100Icon,
      label: "Better battery life",
    },
    comfortScoreRescaled: {
      icon: CloudIcon,
    },
  },
  monitors: {
    casualGamingScore: {
      icon: CubeIcon,
      label: "Better for casual gaming",
    },
    competitiveGamingScore: {
      icon: CubeTransparentIcon,
      label: "Better for competitive gaming",
    },
    productivityScore: {
      icon: DocumentTextIcon,
      label: "Better for productivity",
    },
    mediaConsumptionScore: {
      icon: PhotoIcon,
      label: "Better for media consumption",
    },
  },
};

/**
 * Gets the icon for a product aspect.
 *
 */
export const getAspectIcon = (category, attributeName) => {
  return getAspect(category, attributeName)?.icon;
};

/**
 * Gets the label for a product aspect.
 */
export const getAspectLabel = (category, attributeName) => {
  return getAspect(category, attributeName)?.label;
};

/**
 * Gets aspect information.
 */
const getAspect = (category, aspectName) => {
  if (!aspects?.[category]?.[aspectName]) {
    console.warn(`No aspect found for attribute ${aspectName}`);
  }

  return aspects[category][aspectName];
};

/**
 * Compare two products along an attribute dimension, while allowing a "fuzz"
 * threshold, where if the products are not sufficiently different, we consider
 * it a tie.
 */
export const compareProductsFuzzy = (
  a: Product,
  b: Product,
  attribute: AttributeConfiguration,
  useCase?: boolean,
  max?: number
): { winner: Product | "tie"; pctA: number; pctB: number; first?: string } => {
  const { slug, unit } = attribute;

  if (max === undefined) {
    if (unit === "Score10") {
      max = 10;
    } else if (unit === "Score100") {
      max = 100;
    } else if (attribute.max) {
      max = attribute.max;
    }
  }

  if (!a.attributes[slug] || !b.attributes[slug]) {
    console.warn(
      `One or more producs does not have attribute "${attribute.slug}"`
    );
    return { winner: "tie", pctA: 0, pctB: 0, first: undefined };
  }

  const valueA = parseFloat(a.attributes[slug]?.value);
  const valueB = parseFloat(b.attributes[slug]?.value);

  const pctA = valueA / max;
  const pctB = valueB / max;

  if (Math.abs(pctA - pctB) <= 0.05) {
    return {
      winner: "tie",
      pctA,
      pctB,
      first: getFirst(attribute, a, b, pctA, pctB, true, useCase),
    };
  }

  const compFn = attribute.rankDirection === "DESC" ? Math.min : Math.max;
  const winner = compFn(pctA, pctB) === pctA ? a : b;

  if (winner === a) {
    return {
      winner,
      pctA,
      pctB,
      first: getFirst(attribute, a, b, pctA, pctB, false, useCase),
    };
  } else if (winner === b) {
    return {
      winner,
      pctA,
      pctB,
      first: getFirst(attribute, b, a, pctB, pctA, false, useCase),
    };
  }
};

/**
 * Converts a string like "1TB" or "2GB" to a number of bytes for comparison
 */
const storageToBytes = (input: string) => {
  const regex = /^(\d+)([TGMK]B)$/;

  const match = input.toUpperCase().match(regex);

  if (match) {
    const number = parseInt(match[1]);
    const unit = match[2];

    if (!number || !unit) {
      return 0;
    }
    const coefficients = {
      bytes: 1,
      KB: 1024,
      MB: 1024 * 1024,
      GB: 1024 * 1024 * 1024,
      TB: 1024 * 1024 * 1024 * 1024,
    };
    const bytes = number * coefficients[unit];
    return bytes / coefficients.bytes;
  }

  return 0;
};

/**
 * Gets the rows that should display for a compare summary table.
 */
export const getCompareSummaryRows = (
  products: Product[],
  category: ProductCategoryConfig,
  attributeConfig: AttributeConfigurationMap,
  exact: string[],
  fuzzy: string[]
) => {
  // We only want six total rows, and each fuzzy row must not be a tie.
  return [...exact, ...fuzzy]
    .map((slug) => {
      const attribute = attributeConfig[slug];
      if (!attribute) {
        console.warn(
          `Invalid attribute "${slug}" supplied to compare summary table.`
        );
        return null;
      }

      const aspect = getAspect(category.name, slug);
      if (!aspect) {
        return null;
      }

      // Some aspects have display rules.
      if (aspect.mayDisplay && !aspect.mayDisplay(products)) {
        return null;
      }

      const { winner } = compareProductsFuzzy(
        products[0],
        products[1],
        attribute
      );

      return { winner, attribute };
    })
    .filter((row) => row)
    .filter(({ winner }, i) => (i < exact.length ? true : winner !== "tie"))
    .slice(0, 6);
};

/**
 * Gets a random subset of plottable products, ensuring that the two
 * rankable products are at the beginning. The Scatter Plot chart needs it
 * to be that way.
 */
export const getRandomChartProducts = (
  pool: Product[],
  n: number,
  rankedProducts: Product[]
) => {
  const rankedIds = rankedProducts.map((product) => product.id);

  return getRandomProducts(pool, n, rankedProducts).sort(
    (a: Product, b: Product) => {
      if (rankedIds.includes(a.id) && !rankedIds.includes(b.id)) {
        return -1;
      }
      if (rankedIds.includes(b.id) && !rankedIds.includes(a.id)) {
        return 1;
      }

      return 0;
    }
  );
};

/**
 * Comparison fns
 */

const pctBetween = (lowerExtreme, upperExtreme, givenValue) =>
  (givenValue - lowerExtreme) / (upperExtreme - lowerExtreme);

/**
 * Gets min/max cost range for a graph, based on two prices. Rounds to a sensible
 * interval
 */
const getCostRange = (
  priceA: number,
  priceB: number,
  maxCategoryPrice: number,
  roundInterval = 5000
) => {
  const minPrice = Math.min(priceA, priceB);
  const maxPrice = Math.max(priceA, priceB);

  // Min extreme: 50% of the lower priced item
  const priceExtremeMin = minPrice / 2;

  // Max extreme: Whichever is lowest: the most expensive product in the category,
  // or 150% of the most expensive product being compared.
  const priceExtremeMax = Math.min(maxCategoryPrice, maxPrice * 1.5);

  return {
    priceMin: roundToNearest(priceExtremeMin, roundInterval),
    priceMax: roundToNearest(priceExtremeMax, roundInterval),
  };
};

const roundToNearest = (num, nearest) => Math.round(num / nearest) * nearest;

const getProductPriceResult = (
  productA: Product,
  productB: Product,
  maxBudgetCents: number
) => {
  const priceA = productA.bestPrice;
  const priceB = productB.bestPrice;

  // If max budget not provided, derive a range based on the products.
  const { priceMin, priceMax } = getCostRange(priceA, priceB, maxBudgetCents);

  if (!priceA || !priceB) {
    return { winner: null, pctA: null, pctB: null, ticks: null };
  }

  const tickInterval = d3.nice(priceMin, priceMax, 5);
  const scaleMin = tickInterval[0];
  const scaleMax = tickInterval[1];
  const ticks = d3.ticks(tickInterval[0], tickInterval[1], 5);

  const pctA = pctBetween(scaleMin, scaleMax, priceA);
  const pctB = pctBetween(scaleMin, scaleMax, priceB);

  return Math.abs(pctA - pctB) <= 0.05
    ? { winner: "tie", pctA: pctA, pctB: pctB, ticks: ticks }
    : pctA < pctB
    ? { winner: productA, pctA: pctA, pctB: pctB, ticks: ticks }
    : { winner: productB, pctA: pctA, pctB: pctB, ticks: ticks };
};

//Use this for laptop portability
const getProductScoreResult = (
  productA: Product,
  productB: Product,
  attribute: AttributeConfiguration,
  useCase?: boolean
) => {
  const pctA = parseFloat(productA.attributes[attribute.slug]?.value) / 10;
  const pctB = parseFloat(productB.attributes[attribute.slug]?.value) / 10;

  return Math.abs(pctA - pctB) <= 0.05
    ? {
        winner: "tie",
        pctA: pctA,
        pctB: pctB,
        first: getFirst(
          attribute,
          productA,
          productB,
          pctA,
          pctB,
          true,
          useCase
        ),
      }
    : pctA > pctB
    ? {
        winner: productA,
        pctA: pctA,
        pctB: pctB,
        first: getFirst(
          attribute,
          productA,
          productB,
          pctA,
          pctB,
          false,
          useCase
        ),
      }
    : {
        winner: productB,
        pctA: pctA,
        pctB: pctB,
        first: getFirst(
          attribute,
          productB,
          productA,
          pctB,
          pctA,
          false,
          useCase
        ),
      };
};

export const ticksByForm = {
  Headphones: ["0", "20", "40", "60", "80"],
  Earbuds: ["0", "3", "6", "9", "12"],
};

const getProductBatteryResult = (
  productA: Product,
  productB: Product,
  attributeName: string,
  ticks: string[],
  attributes: AttributeConfigurationMap
) => {
  const values = [productA, productB].map((product, i) =>
    product.attributes[attributeName].value === "N/A"
      ? [false, product, i]
      : [roundDecimal(product.attributes[attributeName].value), product, i]
  );

  const valuesWithBattery = values.filter(
    ([batteryValue, product]) => batteryValue
  );

  if (valuesWithBattery.length === 0) {
    return null;
  }

  const maxBatteryLife = parseFloat(ticks[ticks.length - 1]);

  const { pctA, pctB, winner, first } =
    valuesWithBattery.length > 1
      ? compareProductsFuzzy(
          productA,
          productB,
          attributes[attributeName],
          false,
          maxBatteryLife
        )
      : {
          pctA: (valuesWithBattery[0][0] as number) / maxBatteryLife,
          pctB: null,
          winner: null,
          first: null,
        };
  return { winner: winner, pctA: pctA, pctB: pctB, first: first };
};

//Use for tv screen, phone camera,
const getFuzzyResult = (
  productA: Product,
  productB: Product,
  attributeName: string,
  attributes: AttributeConfigurationMap,
  useCase?: boolean
) => {
  const { pctA, pctB, winner, first } = compareProductsFuzzy(
    productA,
    productB,
    attributes[attributeName],
    useCase
  );

  return { winner: winner, pctA: pctA, pctB: pctB, first: first };
};

function getClassificationSize(size: number) {
  if (size < 6) {
    return ClassificationName.Small;
  }
  if (size < 6.5) {
    return ClassificationName.Medium;
  }
  return ClassificationName.Large;
}

//The winner here is for sake of consistency, this is a preference so no actual winners
const getPhoneSizeResult = (productA: Product, productB: Product) => {
  const classSizeA = getClassificationSize(
    productA.attributes["screenSize"].value
  );
  const classSizeB = getClassificationSize(
    productB.attributes["screenSize"].value
  );

  //who the winner is does not matter here
  if (classSizeA === classSizeB) {
    return { winner: "tie" };
  } else {
    return { winner: productA };
  }
};

export const laptopPerformanceAttributes = [
  "generalUseBenchmarkScore",
  "gamingBenchmarkScore",
  "creativeUseBenchmarkScore",
  "contentBenchmarkScore",
  "softwareBenchmarkScore",
];

//Winner only for checking difference
const getLaptopPerformanceResult = (productA: Product, productB: Product) => {
  const scoresA = laptopPerformanceAttributes.map((attribute) =>
    parseFloat(productA.attributes[attribute].value)
  );
  const scoresB = laptopPerformanceAttributes.map((attribute) =>
    parseFloat(productB.attributes[attribute].value)
  );
  let winner: Product | "tie" = "tie";
  for (const [index, score] of scoresA.entries()) {
    if (Math.abs(score - scoresB[index]) > 0.2) {
      winner = productA;
    }
  }
  return { winner: winner };
};

export const laptopDisplayAttributes = [
  "generalUseScreenScore",
  "gamingScreenScore",
  "creativeUseScreenScore",
  "softwareScore",
  "contentScore",
];

//Winner only for checking difference
const getLaptopDisplayResult = (productA: Product, productB: Product) => {
  const scoresA = laptopDisplayAttributes.map((attribute) =>
    parseFloat(productA.attributes[attribute]?.value)
  );
  const scoresB = laptopDisplayAttributes.map((attribute) =>
    parseFloat(productB.attributes[attribute]?.value)
  );
  let winner: Product | "tie" = "tie";
  for (const [index, score] of scoresA.entries()) {
    if (Math.abs(score - scoresB[index]) > 0.05) {
      winner = productA;
    }
  }
  return { winner: winner };
};

const getProductSuitabilityResult = (
  productA: Product,
  productB: Product,
  attributeName: string
) => {
  let winner: Product | "tie";

  const attributeA = productA.attributes[attributeName].value;
  const attributeB = productB.attributes[attributeName].value;

  if (attributeA === "Yes" && attributeB === "Yes") {
    winner = "tie";
  } else if (attributeA === "Yes" && attributeB === "No") {
    winner = productA;
  } else if (attributeA === "No" && attributeB === "Yes") {
    winner = productB;
  } else {
    winner = "tie";
  }

  return { winner: winner };
};

/**
 * Get a list of winners and ties for the comparison displays on VS Page for all categories
 * @param productA
 * @param productB
 * @param category
 * @param attributes
 * @returns
 */
export const getAllCompareResults = (
  productA: Product,
  productB: Product,
  category: ProductCategoryConfig,
  attributes: AttributeConfigurationMap
) => {
  const compareResults = {};

  switch (category.name) {
    case "tvs": {
      const contentAttributes = Object.keys(TvContentPhraseMap);
      contentAttributes.unshift("screenScore");
      contentAttributes.push("brightRoomScore");

      contentAttributes.map((content) => {
        compareResults[content] = getFuzzyResult(
          productA,
          productB,
          content,
          attributes,
          content === "screenScore" ? false : true
        );
      });
      compareResults["productPrice"] = getProductPriceResult(
        productA,
        productB,
        675000
      );
      break;
    }
    case "laptops": {
      const contentAttributes = Object.keys(laptopContentPhraseMap);
      contentAttributes.map((content) => {
        compareResults[content] = getProductScoreResult(
          productA,
          productB,
          attributes[content],
          true
        );
      });
      compareResults["batteryLifeHours"] = getProductBatteryResult(
        productA,
        productB,
        "batteryLifeHours",
        ["0", "5", "10", "15", "20"],
        attributes
      );
      compareResults["mobilityScore"] = getProductScoreResult(
        productA,
        productB,
        attributes["mobilityScore"],
        false
      );
      compareResults["productPrice"] = getProductPriceResult(
        productA,
        productB,
        400000
      );

      compareResults["laptopPerformance"] = getLaptopPerformanceResult(
        productA,
        productB
      );

      compareResults["laptopDisplay"] = getLaptopDisplayResult(
        productA,
        productB
      );

      compareResults["buildQuality"] = getProductScoreResult(
        productA,
        productB,
        attributes["buildQuality"],
        false
      );

      break;
    }
    case "smartphones": {
      compareResults["cameraScore"] = getFuzzyResult(
        productA,
        productB,
        "cameraScore",
        attributes,
        false
      );
      compareResults["pictureScore"] = getFuzzyResult(
        productA,
        productB,
        "pictureScore",
        attributes,
        false
      );
      compareResults["videoScore"] = getFuzzyResult(
        productA,
        productB,
        "videoScore",
        attributes,
        false
      );
      compareResults["batteryLife"] = getProductBatteryResult(
        productA,
        productB,
        "batteryLife",
        ["0", "3", "6", "9", "12"],
        attributes
      );
      compareResults["screenSize"] = getPhoneSizeResult(productA, productB);
      compareResults["productPrice"] = getProductPriceResult(
        productA,
        productB,
        140000
      );
      break;
    }
    case "headphones": {
      const contentAttributes = [
        "musicScoreRescaled",
        "gamingScoreRescaled",
        "callScoreRescaled",
        "podcastScoreRescaled",
        "tvScoreRescaled",
        "noiseIsolationQualityRescaled",
      ];
      const formFactor = [productA, productB]
        .map((product) => product.attributes?.["formFactor"]?.value)
        .includes("Headphones")
        ? "Headphones"
        : "Earbuds";

      contentAttributes.map((content) => {
        compareResults[content] = getProductScoreResult(
          productA,
          productB,
          attributes[content],
          true
        );
      });

      compareResults["batteryLife"] = getProductBatteryResult(
        productA,
        productB,
        "batteryLife",
        ticksByForm[formFactor],
        attributes
      );

      compareResults["productPrice"] = getProductPriceResult(
        productA,
        productB,
        40000
      );
      compareResults["buildQualityRescaled"] = getProductScoreResult(
        productA,
        productB,
        attributes["buildQualityRescaled"],
        false
      );
      break;
    }
    case "monitors": {
      const contentAttributes = [
        "casualGamingScore",
        "competitiveGamingScore",
        "productivityScore",
        "mediaConsumptionScore",
      ];

      contentAttributes.map((content) => {
        compareResults[content] = getProductScoreResult(
          productA,
          productB,
          attributes[content],
          true
        );
      });

      compareResults["productPrice"] = getProductPriceResult(
        productA,
        productB,
        400000
      );

      const suitabilityAttributes = [
        "hdrConsumption",
        "digitalPhoto",
        "hdrVideo",
        "printPhoto",
      ];

      suitabilityAttributes.map((attr) => {
        compareResults[attr] = getProductSuitabilityResult(
          productA,
          productB,
          attributes[attr].slug
        );
      });
      break;
    }
  }

  const winnerCount = Object.keys(compareResults).filter(
    (key) => compareResults[key]?.winner !== "tie"
  ).length;

  const tieCount = Object.keys(compareResults).filter(
    (key) => compareResults[key]?.winner === "tie"
  ).length;

  return { compareResults, winnerCount, tieCount };
};
