import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import React from "react";
import { useExplanation } from "@lib/hooks/use-explanation";
import {
  MultiResponse,
  useResponseAnswers,
} from "@lib/hooks/use-multi-response";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getAspectIcon } from "@components/compare/compare-fns";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { useAttribute } from "@lib/hooks/use-attributes";

interface Props extends PanelSetProps {
  scoreType: string;
}

export const laptopContentPhraseMap = {
  generalUseScore: {
    titlePhrase: "General Use",
    textPhrase:
      "PerfectRec’s General Use Score takes into account the many different features of the laptop that make it more or less suitable for general productivity, web browsing, streaming, video calls, and running a wide variety of apps and programs.",
  },
  gamingScore: {
    titlePhrase: "Gaming and AI",
    textPhrase:
      "PerfectRec’s Gaming and AI Score strongly emphasizes graphics processing capability, while also taking into account other factors that impact suitability for gaming and AI work.",
  },
  creativeUseScore: {
    titlePhrase: "Engineering and Design",
    textPhrase:
      "PerfectRec’s Engineering and Design Score takes into account the many different features of the laptop that make it more or less suitable for  graphic design and 3D rendering work.",
  },
  contentScore: {
    titlePhrase: "Content Creation",
    textPhrase:
      "PerfectRec’s Content Creation Score takes into account the many different features of the laptop that make it more or less suitable for photo editing, video editing and other content creation tasks.",
  },
  softwareScore: {
    titlePhrase: "Software Development",
    textPhrase:
      "PerfectRec’s Software Development Score takes into account the many different features of the laptop that make it more or less suitable for software developers.",
  },
};

export const getUsage = (
  attributeName: string,
  multiResponse: MultiResponse[]
) => {
  const contentAnswerIdMap = {
    generalUseScore: "4ec9cec0-5084-414f-aecb-5c4bb5cb2a58",
    gamingScore: "651b39c0-ed9a-4f33-9a1b-0455b1a0bbe5",
    creativeUseScore: "3dc9b48b-ee75-4702-9560-e8f65111125b",
  };
  return multiResponse.find((filter) => {
    return filter.answerId === contentAnswerIdMap[attributeName];
  });
};

const LaptopUsageMeter = (props: Props) => {
  const attribute = props.selectedProduct?.attributes[props.scoreType];
  const attrConfig = useAttribute(props.scoreType);

  const score = parseFloat(
    props.selectedProduct?.attributes[props.scoreType]?.value
  );

  const performanceQuestionId = "78c0a42b-31e3-49a2-b2ab-904603fc37f9";

  const { answers } = useResponseAnswers(performanceQuestionId);

  const answer = parseFloat(answers[0]?.mainText);

  const title = `${laptopContentPhraseMap[attribute?.slug]?.titlePhrase}`;

  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} is{" "}
      <strong>
        {getQualitativeWord(score)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      for{" "}
      {laptopContentPhraseMap[attribute?.slug]?.titlePhrase
        .toLowerCase()
        .replace("ai", "AI")}
      .
    </>
  );

  const second = laptopContentPhraseMap[attribute?.slug]?.textPhrase;

  const color = getMeetsNeedsColor(
    !((score < 7 && answer === 3) || (score < 8 && answer === 4))
  );

  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: attrConfig?.displayName,
        text: attrConfig?.explanation,
      }}
    >
      <ArcScoreDisplay
        product={props.selectedProduct}
        attribute={attrConfig}
        icon={getAspectIcon("laptops", attribute?.slug)}
        score={score}
        color={color}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopUsageMeter;
