import { getExplanations } from "@lib/utilities/product-classifier";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { useContext } from "react";

export const useExplanation = (explanationName, product) => {
  const { activeResponseSet, productCategoryConfig: category } =
    useContext(RecUIContext);

  const config = category.explanationConfiguration[explanationName];

  if (!activeResponseSet) {
    return {
      explanation: null,
      config,
    };
  }

  const explanations = getExplanations(
    product,
    activeResponseSet,
    category.explanationConfiguration
  );

  const explanation = explanations.find(
    (explanation) => explanation.name === explanationName
  );

  return {
    explanation,
    config,
  };
};
