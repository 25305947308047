import { SingleRecExplanationIconColors } from "@lib/constants/rec-explanation-icons";
import React, { useContext } from "react";
import { PanelExplainer } from "@components/product/summary/common";
import { StandardCard } from "./done-page-fixtures";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import { Product } from "@lib/model/product";
import { VsDisposition } from "@components/compare/compare-components";
import { ProductCategoryContext } from "contexts/product-category-context";

export const getRecBgClass = (color?: string) => {
  switch (color) {
    case SingleRecExplanationIconColors.White:
      return "!bg-panel-green";
    case SingleRecExplanationIconColors.Blue:
      return "!bg-panel-green";
    case SingleRecExplanationIconColors.Green:
      return "!bg-panel-green";
    case SingleRecExplanationIconColors.LightGreen:
      return "!bg-panel-green";
    case SingleRecExplanationIconColors.Orange:
      return "!bg-panel-yellow";
    case SingleRecExplanationIconColors.Yellow:
      return "!bg-panel-yellow";
    case SingleRecExplanationIconColors.Red:
      return "!bg-panel-red";
    default:
      return "!bg-panel-blue-2";
  }
};

export const getRecBorderColorClass = (color?: string) => {
  switch (color) {
    case SingleRecExplanationIconColors.White:
      return "!border-green-500";
    case SingleRecExplanationIconColors.Blue:
      return "!border-green-500";
    case SingleRecExplanationIconColors.Green:
      return "!border-rec-green";
    case SingleRecExplanationIconColors.LightGreen:
      return "!border-rec-green";
    case SingleRecExplanationIconColors.Orange:
      return "!border-yellow-500";
    case SingleRecExplanationIconColors.Yellow:
      return "!border-yellow-500";
    case SingleRecExplanationIconColors.Red:
      return "!border-red-500";
    default:
      return "!border-gray-500";
  }
};

export const getRecIconColorClass = (color?: string) => {
  switch (color) {
    case SingleRecExplanationIconColors.White:
      return "!text-green-500";
    case SingleRecExplanationIconColors.Blue:
      return "!text-green-500";
    case SingleRecExplanationIconColors.Green:
      return "!text-rec-green";
    case SingleRecExplanationIconColors.LightGreen:
      return "!text-rec-green";
    case SingleRecExplanationIconColors.Orange:
      return "!text-yellow-500";
    case SingleRecExplanationIconColors.Yellow:
      return "!text-yellow-500";
    case SingleRecExplanationIconColors.Red:
      return "textr-red-500";
    default:
      return "!text-gray-500";
  }
};

export const getIconColorClass = (color?: string) => {
  switch (color) {
    case SingleRecExplanationIconColors.White:
      return {
        arcStrokeColor: "stroke-green-500",
        circleFillColor: "fill-green-500",
        circleStrokeColor: "stroke-panel-green",
      };
    case SingleRecExplanationIconColors.Blue:
      return {
        arcStrokeColor: "stroke-green-500",
        circleFillColor: "fill-green-500",
        circleStrokeColor: "stroke-panel-green",
      };
    case SingleRecExplanationIconColors.Green:
      return {
        arcStrokeColor: "stroke-rec-green",
        circleFillColor: "fill-rec-green",
        circleStrokeColor: "stroke-panel-green",
      };
    case SingleRecExplanationIconColors.LightGreen:
      return {
        arcStrokeColor: "stroke-rec-green",
        circleFillColor: "fill-rec-green",
        circleStrokeColor: "stroke-panel-green",
      };
    case SingleRecExplanationIconColors.Orange:
      return {
        arcStrokeColor: "stroke-yellow-500",
        circleFillColor: "fill-yellow-500",
        circleStrokeColor: "stroke-panel-yellow",
      };
    case SingleRecExplanationIconColors.Yellow:
      return {
        arcStrokeColor: "stroke-yellow-500",
        circleFillColor: "fill-yellow-500",
        circleStrokeColor: "stroke-panel-yellow",
      };
    case SingleRecExplanationIconColors.Red:
      return {
        arcStrokeColor: "stroke-red-500",
        circleFillColor: "fill-red-500",
        circleStrokeColor: "stroke-panel-red",
      };
    default:
      return {
        arcStrokeColor: "stroke-gray-500",
        circleFillColor: "fill-gray-500",
        circleStrokeColor: "stroke-white",
      };
  }
};

/**
 * Gets the binary meets/does-not-meet needs color.
 */
export const getMeetsNeedsColor = (meetsNeeds) =>
  meetsNeeds
    ? SingleRecExplanationIconColors.Green
    : SingleRecExplanationIconColors.Yellow;

const DonePanel = ({
  title,
  icon,
  children,
  className = "",
  color,
  modal,
  winner,
}: {
  title: string;
  children: React.ReactNode;
  color?: string;
  icon?: React.FC<{ className: string }>;
  className?: string;
  modal?: {
    title: string;
    jsxText?: React.ReactNode;
    text?: string;
  };
  winner?: Product | "tie";
}) => {
  const Icon = icon;

  const colorClass = color ? getRecBgClass(color) : "";

  const borderColorClass = color
    ? getRecBorderColorClass(color)
    : "border-gray-500";

  return (
    <div className="break-inside-avoid mb-2 md:mb-3">
      <StandardCard
        className={`relative overflow-hidden !px-1 !pb-2 ${borderColorClass} ${
          winner ? "!pt-6" : ""
        } ${colorClass} ${className}`}
      >
        {winner && (
          <div className="absolute right-8 ml-[-1px] top-[-1px] bg-white">
            <div className="text-2xs leading-4 font-semibold border border-gray-500 rounded-b-lg p-1">
              <VsDisposition winner={winner} />
            </div>
          </div>
        )}
        {modal && (
          <div className="grid justify-items-end right-0 h-0 ">
            <PanelExplainer
              {...modal}
              className="ml-auto text-right h-5 -mr-3 -mt-2"
            />
          </div>
        )}
        <div className={`flex gap-2 mb-1 items-center`}>
          <h3 className="text-xl my-2">{capitalizeFirstLetter(title)}</h3>
        </div>
        {children}
      </StandardCard>
    </div>
  );
};

export default DonePanel;
