import Modal from "@components/modal/modal";
import { useRouter } from "next/router";
import { useAttributes } from "@lib/hooks/use-attributes";
import { Product } from "@lib/model/product";
import ProductCompareTableSelectable from "./product-compare-table-selectable";

export interface CompareModalProps {
  open: boolean;
  compareProducts: Product[];
  categoryName: string;
}

const CompareModal = (props: CompareModalProps) => {
  const router = useRouter();
  const { attributes } = useAttributes();

  return (
    <Modal
      onClose={() => router.back()}
      variant="xlarge"
      modalOpen={props.open}
      closeButtonText={"Close"}
      omitBottomButtons={true}
      title="Compare recommendations"
    >
      {attributes && (
        <ProductCompareTableSelectable
          products={props.compareProducts}
          categoryName={props.categoryName}
          attributes={attributes}
          className="w-full"
        />
      )}
    </Modal>
  );
};

export default CompareModal;
