import { Product } from "@lib/model/product";
import {
  AssetType,
  centsToDollars,
  getImageUrl,
  getProductLandingPageUrl,
} from "@lib/utilities/shared-utilities";
import dynamic from "next/dynamic";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import Link from "next/link";
import RankIcon from "@components/recommendation/rank-icon";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { useWindowDimensions } from "@lib/utilities/client-utilities";
import { useContext } from "react";
import { getAttributeShortname } from "@lib/utilities/rec-ui";
import { AttributeValue } from "@components/compare/compare-components";
import {
  RankedRecommendation,
  RecommendationExplanation,
} from "@lib/model/recommender-model";
import SingleRecExplanations from "@components/recommendation/single-rec-explanations";
import { getExplanations } from "@lib/utilities/product-classifier";
import SingleRecNewExplanations from "@components/recommendation/single-rec-explanations-new";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { PlayIcon } from "@heroicons/react/24/solid";
import { useBudgetExplanation } from "@lib/hooks/use-budget-explanation";
const ImageWithFallback = dynamic(
  () => import("@components/common/image-with-fallback"),
  {
    ssr: false,
  }
);

/**
 * The right half of the product card.
 */
const Right = (props: { children: React.ReactNode }) => {
  return (
    <div
      className={`flex flex-grow flex-col justify-between items-start bg-light-gray-background p-1.5 pl-3 border-l border-keyline-1`}
    >
      {props.children}
    </div>
  );
};

/**
 * The left half of the product card.
 */
const Left = (props: { children: React.ReactNode; className?: string }) => {
  return (
    <div
      className={`items-center flex flex-col gap-2 justify-center basis-[30%] shrink-0 p-1.5 ${props.className}`}
    >
      {props.children}
    </div>
  );
};

/**
 * The outer shell of the product card.
 */
export const Outer = (props: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  selected?: boolean;
}) => {
  const borderColor = props.selected ? "border-blue-600" : "border-keyline-1";
  return (
    <div
      className={`rounded-lg flex overflow-hidden border ${borderColor} ${
        props.className || ""
      }`}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

/**
 * Primary/secondary titles for a product.
 */
export const SplitTitles = ({
  product,
  children,
}: {
  product: Product;
  children?: React.ReactNode;
}) => {
  const { primaryLabel, secondaryLabel } = getProductLabels(product);
  return (
    <div className="text-left w-full flex flex-col gap-[2px]">
      <ProductTitleSecondary text={secondaryLabel}>
        {children}
      </ProductTitleSecondary>
      <ProductTitlePrimary text={primaryLabel} />
    </div>
  );
};

/**
 * Markup and content for the primary product label.
 */
export const ProductTitlePrimary = ({
  text,
  className = "",
}: {
  text: string;
  className?: string;
}) => {
  return (
    <h4 className={`leading-none text-base md:text-[1.125rem] ${className}`}>
      {text}
    </h4>
  );
};

/**
 * Markup and content for the secondary product label.
 */
export const ProductTitleSecondary = ({
  text,
  children,
}: {
  text: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex gap-2 justify-between">
      <h4 className="font-semibold text-xs md:text-sm my-0 text-gray-500">
        {text}
      </h4>
      {children}
    </div>
  );
};

/**
 * A bottom section that contains the price and a CTA.
 */
const Bottom = (props: {
  price: string;
  budgetPhrase?: string;
  budgetColor?: ReturnType<typeof getBudgetColorClass>;
  children?: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between items-center w-full gap-2">
      <div className="font-bold text-sm leading-none mt-auto mb-0">
        <span className={`inline-block`}>{props.price}</span>
        {props.budgetPhrase && (
          <>
            <span className={`ml-2 text-xs font-normal ${props.budgetColor}`}>
              {props.budgetColor === "text-rec-dark-green" ? (
                <PlayIcon className="inline-block h-2 w-2 rotate-90" />
              ) : props.budgetColor === "text-black" ? null : (
                <PlayIcon className="inline-block h-2 w-2 -rotate-90" />
              )}
            </span>
            <span className="ml-0.5 text-xs font-normal text-gray-500">
              {props.budgetPhrase}
            </span>
          </>
        )}
      </div>
      {props.children}
    </div>
  );
};

/**
 * A wrapper that puts a rank icon around whatever is inside.
 */
export const RankedProductOuter = ({
  rank,
  selected,
  selectHandler,
  children,
  className = "",
}: {
  rank: number;
  selected?: boolean;
  selectHandler: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={`relative ml-2 mt-2 ${className}`}>
      <RankIcon
        rank={rank}
        isSelected={selected}
        handleClick={selectHandler}
        className="absolute -top-1.5 -left-1.5 md:my-auto md:mr-2 z-10"
      />
      {children}
    </div>
  );
};

/**
 * A product card variant that can be expanded on narrow screens.
 */
export const ProductCardExpandable = ({
  product,
  productCategory,
  cta,
  explanations,
  onExpand,
  expanded,
  selected,
  confidence,
  delay,
}: {
  product: Product;
  productCategory: ProductCategoryConfig;
  cta: React.ReactElement;
  explanations?: React.ReactElement;
  onExpand: () => void;
  expanded: boolean;
  selected: boolean;
  confidence?: number;
  delay?: number;
  className?: string;
  recommendation: RankedRecommendation;
}) => {
  const { breakpoint } = useWindowDimensions();
  const minSm =
    breakpoint === "lg" || breakpoint === "md" || breakpoint === "sm";

  const { phrase: budgetPhrase, interval: budgetInterval } =
    useBudgetExplanation({ product });

  const imgMaxHeight = productCategory.keySpecs ? "max-h-16" : "max-h-[141px]";

  return (
    <Outer
      onClick={onExpand}
      className={`cursor-pointer h-full ${
        expanded ? "min-h-[155px]" : "sm:min-h-[155px]"
      }`}
      selected={selected}
    >
      <Left>
        <ProductImageCard
          product={product}
          productCategoryConfigName={productCategory.name}
          confidence={confidence}
          delay={delay}
          showConfidence={
            productCategory?.showConfidenceScores?.done && confidence > 0
          }
          imageClassName={`mx-0 ${imgMaxHeight}`}
        />
        {(expanded || minSm) && productCategory.keySpecs && (
          <KeySpecs product={product} category={productCategory} />
        )}
      </Left>

      <Right>
        <SplitTitles {...{ product }}>
          {expanded || minSm ? cta : null}
        </SplitTitles>
        {(expanded || minSm) && (
          <>
            {explanations && <div className="mb-2">{explanations}</div>}

            <Bottom
              price={centsToDollars(product.bestPrice)}
              budgetPhrase={budgetPhrase}
              budgetColor={getBudgetColorClass(budgetInterval)}
            ></Bottom>
          </>
        )}
      </Right>
    </Outer>
  );
};

export const ProductCardVertical = ({
  product,
  productCategory,
  stats,
}: {
  product: Product;
  productCategory: ProductCategoryConfig;
  stats?: React.ReactElement;
}) => {
  return (
    <Link href={getProductLandingPageUrl(product, productCategory)}>
      <Outer className="flex-row sm:flex-col h-full relative">
        <div className="border-r sm:border-b sm:border-r-0 border-gray-300 p-3 flex items-center justify-center w-[35%] sm:w-auto sm:h-[150px]">
          <ProductImage
            product={product}
            widthToFetch={180}
            categoryName={productCategory.name}
            sizeClassName="max-w-[90%] max-h-[90%]"
          />
        </div>
        {/* The calc here is to make sure this fills the whole rest of the vertical container. */}
        <div className="p-3 h-[calc(100%-150px)] flex flex-col gap-2">
          <SplitTitles {...{ product, productCategory }} />
          <div className="text-lg text-left font-normal">
            {centsToDollars(product.bestPrice)}
          </div>
          {stats}
        </div>
      </Outer>
    </Link>
  );
};

/**
 * A flexible product card suitable for most display purposes.
 */
export const ProductCardBasic = ({
  product,
  productCategory,
  cta,
  stats,
  titleFormat = "split",
  explanations,
}: {
  product: Product;
  productCategory: ProductCategoryConfig;
  cta: React.ReactElement;
  stats?: React.ReactElement;
  titleFormat?: "split" | "singleLinked";
  explanations?: RecommendationExplanation[];
}) => {
  const recUIContext = useContext(RecUIContext);
  const activeResponseSet = recUIContext?.activeResponseSet;

  const { phrase: budgetPhrase, interval: budgetInterval } =
    useBudgetExplanation({ product });

  const imageMaxHeight = productCategory.keySpecs
    ? "max-h-20"
    : "max-h-[158px]";

  const imageContainerHeight = "h-[180px]";

  const displayMax = productCategory.name === "laptops" ? 4 : 3;

  const newExplanations =
    activeResponseSet && productCategory?.explanationConfiguration
      ? getExplanations(
          product,
          activeResponseSet,
          productCategory.explanationConfiguration,
          displayMax
        )
      : [];
  const displayableExplanations = newExplanations
    .filter((exp) => exp.display)
    .filter((_, index) => index < displayMax);
  const metRequirementsExplanations = displayableExplanations.filter(
    (exp) => !exp.isUnmet
  );
  const unmetRequirementsExplanations = displayableExplanations.filter(
    (exp) => exp.isUnmet
  );

  return (
    <Outer className={""}>
      <Left className={`${imageContainerHeight}`}>
        <ProductImageCard
          product={product}
          productCategoryConfigName={productCategory.name}
          imageClassName={`mx-0 ${imageMaxHeight}`}
        />
        {productCategory.keySpecs && (
          <KeySpecs product={product} category={productCategory} />
        )}
      </Left>

      <Right>
        {titleFormat === "split" && (
          <SplitTitles {...{ product, productCategory }} />
        )}
        {titleFormat === "singleLinked" && (
          <Link
            prefetch={false}
            href={getProductLandingPageUrl(product, productCategory)}
          >
            <ProductTitlePrimary
              text={getProductLabels(product).shortLabel}
              className="text-left"
            />
          </Link>
        )}
        {stats && <div className="mb-2">{stats}</div>}
        {explanations && newExplanations.length === 0 && (
          <SingleRecExplanations explanations={explanations} />
        )}
        {metRequirementsExplanations.length > 0 && (
          <SingleRecNewExplanations
            explanations={metRequirementsExplanations}
          />
        )}
        {unmetRequirementsExplanations.length > 0 && (
          <div className="border-l-gray-400 border-l-2 pl-2 space-y-1 mt-1">
            <div className="text-xs italic">May not meet your needs:</div>
            <SingleRecNewExplanations
              explanations={unmetRequirementsExplanations}
            />
          </div>
        )}

        <Bottom
          price={centsToDollars(product.bestPrice)}
          budgetColor={getBudgetColorClass(budgetInterval)}
          budgetPhrase={budgetPhrase}
        >
          {cta}
        </Bottom>
      </Right>
    </Outer>
  );
};

/**
 * A very tiny product card.
 */
export const ProductCardTiny = ({
  product,
  rank,
  className = "",
  onClick,
}: {
  product: Product;
  rank: number;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`rounded-lg flex gap-1 items-center p-1.5 bg-white ${className}`}
      onClick={onClick}
    >
      <div className="rounded-full h-6 w-6 bg-blue-600 text-white text-xs flex items-center justify-center font-semibold">
        {rank}
      </div>
      <ProductImageCard
        product={product}
        productCategoryConfigName={product.metadata.categoryName}
        imageClassName="max-h-[32px]"
      />
      <SplitTitles product={product} />
    </div>
  );
};

/**
 * Displays the CPU, GPU, RAM, and storage for the laptop product card.
 */
export const KeySpecs = ({
  product,
  category,
}: {
  product: Product;
  category: ProductCategoryConfig;
}) => {
  const attributes = category.attributeConfiguration;

  return (
    <div className="relative w-full text-xs leading-3 cursor-pointer bottom-0">
      {category.keySpecs.map((attribute) => (
        <div
          className="font-normal text-gray-500 children:text-black children:font-semibold key-specs-row"
          key={attribute}
        >
          {getAttributeShortname(attributes[attribute])}:{" "}
          <span>
            <AttributeValue
              product={product}
              attribute={attributes[attribute]}
            />
          </span>
        </div>
      ))}
    </div>
  );
};

/**
 * Color classes by budget interval.
 *
 * Note - this must remain in a .tsx file or tailwind gets confused.
 */
export const getBudgetColorClass = (interval: number) => {
  const colors = {
    0: "text-rec-dark-green",
    1: "text-rec-dark-green",
    2: "text-black",
    3: "text-yellow-500",
    4: "text-rec-red",
    5: "text-rec-red",
  } as const;
  return colors[interval as keyof typeof colors];
};

/**
 * The product image with optional confidence meter.
 *
 * @TODO use ProductImage instead of this.
 */
export const ProductImageCard = (props: {
  product: Product;
  position?: number;
  productCategoryConfigName: string;
  delay?: number;
  className?: string;
  imageClassName?: string;
  showConfidence?: boolean;
  confidence?: number;
  children?: React.ReactNode;
}) => {
  const productCategoryName = props.productCategoryConfigName;
  const product = props.product;

  return (
    <div className={`relative flex justify-center ${props.className}`}>
      <ImageWithFallback
        src={getImageUrl(
          product.image,
          AssetType.ProductImage,
          { width: 200 },
          productCategoryName
        )}
        alt={product.model}
        // The exact heights are important here: the inner dimension of the
        // fixed height card.
        className={props.imageClassName || "my-0 max-h-[129px] mx-0"}
      />
    </div>
  );
};

export const ProductImage = ({
  product,
  categoryName,
  widthToFetch = 125,
  sizeClassName = "max-h-16",
}: {
  product: Product;
  categoryName: string;
  widthToFetch?: number;
  sizeClassName?: string;
}) => {
  return (
    <ImageWithFallback
      src={getImageUrl(
        product.image,
        AssetType.ProductImage,
        { width: widthToFetch },
        categoryName
      )}
      width={widthToFetch}
      alt={product.model}
      className={`object-contain ${sizeClassName}`}
    />
  );
};
