enum SingleRecExplanationIcons {
  // Phones
  Camera = "Camera",
  PhoneSize = "SIZE",
  // TVs
  TvSize = "Size",
  BrightRoom = "BrightRoom",
  PictureQuality = "PictureQuality",
  // Laptops
  Usage = "Usage",
  Weight = "Weight",
  ScreenSize = "ScreenSize",
  Performance = "Performance",
  Portability = "Portability",
  // Earbuds
  NoiseCanceling = "NoiseCanceling",
  Small = "Small",
  Signature = "Signature",
  OS = "OS",
  // Shared
  Budget = "Budget",
  Battery = "Battery",
  Content = "Content",
}

enum SingleRecExplanationIconColors {
  Blue = "Blue",
  Green = "Green",
  LightGreen = "LightGreen",
  Orange = "Orange",
  Red = "Red",
  Yellow = "Yellow",
  White = "White",
}

export { SingleRecExplanationIcons, SingleRecExplanationIconColors };
