import { CategoryMetadata } from "@lib/../../data/packages/model/src/category.js";

export type Condition =
  | "new"
  | "refurbished"
  | "used-excellent"
  | "used-fair"
  | "used-good"
  | "used-mixed"
  | "used";

export const acceptableConditionsForRecommendation = [
  "new",
  "refurbished",
  "used-excellent",
] as Condition[];

export default interface PurchaseOption {
  productId: string;
  price: number;
  condition: Condition;
  link: string;
  linkId: string;
  storeName: string;
  inStock: boolean;
  metadata: CategoryMetadata;
  variantId?: string;
}
