import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext } from "react";

export const useQuestions = () => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const data = productCategoryConfig.questions;

  return {
    questions: data,
  };
};

export const useQuestion = (questionId) => {
  const { questions } = useQuestions();

  return questions && questionId
    ? questions.find((question) => question.id === questionId)
    : null;
};

export const useBudgetQuestion = () => {
  const { questions } = useQuestions();

  if (questions) {
    return questions.find((question) => question.isBudget);
  }
};
