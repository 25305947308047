import ImageWithFallback from "@components/common/image-with-fallback";
import { SectionHeading } from "@components/common/section-heading";
import SectionContainer from "@components/frame/section-container";
import { IMediaHighlight } from "@lib/model/contentful";
import { getUrlForCmsAsset } from "@lib/utilities/client-utilities";
import { AssetType, getImageUrl } from "@lib/utilities/shared-utilities";
import Link from "next/link";

const FeaturedIn = ({
  mediaHighlights,
}: {
  mediaHighlights: IMediaHighlight[];
}) => {
  if (!mediaHighlights) {
    return null;
  }
  return (
    <SectionContainer>
      <SectionHeading className="text-center my-8">Featured In</SectionHeading>
      <div className="flex gap-5 justify-center">
        <div className="border-r-2 border-slate-900 shrink-0 p-4">
          <Link href="https://www.youtube.com/watch?v=-eqjP-aFBPA&t=178s">
            <ImageWithFallback
              alt="Linus Tech Tips"
              src={getImageUrl("linus-tech-tips-logo.png", AssetType.Icon, {
                width: 250,
              })}
              className="object-contain h-24"
            />
          </Link>
        </div>
        <div className="flex flex-col justify-center">
          <div className="italic text-xl font-serif">
            &quot;Kind of a cool website&quot;
          </div>
          <span className="text-sm text-gray-500">- Linus Sebastian</span>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row flex-wrap justify-center gap-x-12 gap-y-8 sm:gap-y-20 my-12 sm:my-20">
        {/* The first item gets special treatment. */}
        {mediaHighlights.slice(1).map((highlight) => (
          <MediaHighlight highlight={highlight} key={highlight.sys.id} />
        ))}
      </div>
    </SectionContainer>
  );
};

export const MediaHighlight = ({
  highlight,
  className = "h-12 max-w-[250px]",
}: {
  highlight: IMediaHighlight;
  className?;
}) => (
  <Link href={highlight.fields.url} key={highlight.sys.id} className="shrink-0">
    <ImageWithFallback
      src={getUrlForCmsAsset(highlight.fields.image)}
      alt={highlight.fields.image.fields.description}
      className={`mx-auto object-contain ${className}`}
    />
  </Link>
);

export { FeaturedIn };
