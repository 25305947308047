export enum SectionSpacing {
  None = "",
  Large = "spacing-lg",
  ExtraLarge = "spacing-xl",
}

export interface SectionContainerProps {
  className?: string;
  children: React.ReactNode;
  sectionSpacing?: SectionSpacing;
}

const SectionContainer = ({
  className,
  children,
  sectionSpacing = SectionSpacing.Large,
}: SectionContainerProps) => {
  return (
    <div
      className={`container ${className ? className : ""} ${sectionSpacing}`}
    >
      {children}
    </div>
  );
};

export default SectionContainer;
