import Modal from "@components/modal/modal";
import { useContext, useEffect, useState } from "react";
import SocialMediaButtons from "@components/common/social-media-buttons";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import useShare from "@lib/hooks/use-share";
import { PrimaryButton } from "@components/common/button";

export interface ShareModalProps {
  onClose: any;
  modalOpen: boolean;
}

const ShareModal = ({ onClose, modalOpen }: ShareModalProps) => {
  const { topProducts, productCategoryConfig } = useContext(RecUIContext);
  const { shareURL, isLoading, error } = useShare();

  const shareData = {
    title: `My ${productCategoryConfig.noun} recs`,
    text: `I got these ${productCategoryConfig.noun} recs: ${topProducts
      .map((product) => getProductLabels(product).shortLabel)
      .join(", ")}. Can you let me know what you think?`,
    url: shareURL,
  };

  return (
    <>
      <Modal
        onClose={onClose}
        modalOpen={modalOpen}
        title={`Get a 2nd opinion on your perfect ${productCategoryConfig.noun} recs`}
        dataTestId="share-x-button"
      >
        <div className="mb-3">{`${shareData.text} `}</div>

        {isLoading && !shareURL && <p>Generating share link...</p>}

        {!isLoading && error && (
          <p className="text-red-600">Error generating share link</p>
        )}

        {!isLoading && shareURL && (
          <div>
            <ShareLink shareURL={shareURL} />
            <SocialShareButtons shareData={shareData} />
          </div>
        )}
      </Modal>
    </>
  );
};

const ShareLink = ({ shareURL }: { shareURL: string }) => {
  return (
    <a href={shareURL} className="text-blue-600" data-testid="share-url">
      {shareURL}
    </a>
  );
};

const SocialShareButtons = ({
  shareData,
}: {
  shareData: { url: string; text: string; title: string };
}) => {
  const handleNativeShare = () => {
    navigator
      .share(shareData)
      .then(() => EventTracker.track(Events.ShareStarted))
      .catch(() => EventTracker.track(Events.ShareFailed));
  };

  const [isNativeShare, setNativeShare] = useState(false);

  useEffect(() => {
    let touchScreen = false;
    if ("maxTouchPoints" in navigator) {
      touchScreen = navigator.maxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        touchScreen = !!mQ.matches;
      }
    }
    if (touchScreen && navigator.share) {
      setNativeShare(true);
    }
  }, []);

  return isNativeShare ? (
    <PrimaryButton className="w-full mt-4" onClick={handleNativeShare}>
      Share
    </PrimaryButton>
  ) : (
    <div className="text-center mt-2">
      <SocialMediaButtons
        url={shareData.url}
        body={shareData.text}
        emailSubject={shareData.title}
      />
    </div>
  );
};

export default ShareModal;
