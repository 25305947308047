import React, { Fragment, useContext, useState } from "react";
import SelectSingle from "@components/form-element/select-single";
import {
  AssetType,
  getImageUrl,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { OptionGroup } from "@lib/model/form";
import { Product } from "@lib/model/product";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { ABMarker } from "./ab-marker";
import { getAttributes } from "./compare-fns";
import {
  AttributeGroup,
  DisplayValues,
  PriceRow,
  ProductAttributeRow,
} from "./compare-components";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Events from "@lib/constants/events";
import VariantSelectInput, {
  VariantSelectDropdown,
} from "@components/product/variant-select-input";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { ProductImage } from "@components/product/product-card/product-card-composed";
import { ProductCategoryContext } from "contexts/product-category-context";
import { PanelLayout } from "@components/product/summary/common";
import { TextButton } from "@components/common/button";

export interface ProductCompareTableProps {
  products: Product[];
  attributes: AttributeConfigurationMap;
  category: string;
  optionGroups?: OptionGroup[];

  // An optional callback for when someone uses the product selector.
  setComparingProduct?: (product: Product, index: number) => void;
  displayImage?: boolean;
  isModal?: boolean;
  className?: string;

  // This flag puts "vs" text between the first two imgages.
  vs?: boolean;

  // This governs what fields show up.
  context?: "compare" | "full";

  // Governs whether we highlight the best product.
  highlightBest?: boolean;

  // An optional set of rows to put at the top of the table. Often contains custom images
  // or product titles.
  headerRows?: React.ReactNode;
}

interface ProductCompareSubTableProps {
  products: Product[];
  attributes: AttributeConfigurationMap;
  attributeGroup: string[];
  // Governs whether we highlight the best product.
  highlightBest?: boolean;
  className?: string;
  index: number;
}

const ProductCompareSubTable = ({
  products,
  attributes,
  attributeGroup,
  highlightBest,
  className,
  index,
}: ProductCompareSubTableProps) => {
  const [expanded, setExpanded] = useState(false);

  const subTableLength = attributeGroup.length;

  return (
    <StandardCard className="overflow-hidden " innerClassName="!p-0 !px-0">
      <table className={`min-w-full ${className || ""}`}>
        <tbody className="bg-white overflow-hidden">
          {index === 0 && (
            <>
              <AttributeGroup
                groupTitle={"GENERAL"}
                className="border-b border-gray-500 tracking-wider bg-blue-50"
                innerClassName="px-3"
              />
              <PriceRow
                products={products}
                innerClassName={`px-3 ${products.length === 1 && "py-2"}`}
              />
            </>
          )}
          {attributeGroup.map((att, index) => {
            return (
              <Fragment key={index}>
                {attributes[att].groupTitle ? (
                  <AttributeGroup
                    groupTitle={attributes[att].groupTitle}
                    className={`border-b border-gray-500 tracking-wider bg-blue-50 ${
                      subTableLength <= 6 ||
                      (subTableLength > 6 && index < 5) ||
                      (subTableLength > 6 && index >= 5 && expanded)
                        ? ""
                        : "hidden"
                    }`}
                    innerClassName="px-3"
                  />
                ) : null}
                {/* On small screens, the row below acts as the heading. */}
                <tr
                  className={`text-left font-bold border-t ${
                    products.length > 1 ? "lg:hidden" : "hidden"
                  } lg:pr-10 px-3 ${
                    subTableLength <= 6 ||
                    (subTableLength > 6 && index < 5) ||
                    (subTableLength > 6 && index >= 5 && expanded)
                      ? ""
                      : "hidden"
                  }`}
                >
                  <th colSpan={3} className={`pt-3 px-3`}>
                    <ProductAttributeRow
                      configuration={attributes[att]}
                      products={products}
                    />
                  </th>
                </tr>
                {/* This row contains the wide-screen heading, and the attribute values for a product. */}
                <tr
                  className={`${
                    subTableLength <= 6 && index === attributeGroup.length - 1
                      ? ""
                      : "border-b"
                  } ${index > 0 && "lg:border-t"} px-3 ${
                    subTableLength <= 6 ||
                    (subTableLength > 6 && index < 5) ||
                    (subTableLength > 6 && index >= 5 && expanded)
                      ? ""
                      : "hidden"
                  }`}
                >
                  <th
                    className={`${
                      products.length > 1 ? "hidden" : ""
                    } lg:table-cell text-left lg:pr-2 px-3`}
                  >
                    <ProductAttributeRow
                      configuration={attributes[att]}
                      products={products}
                    />
                  </th>
                  <DisplayValues
                    products={products}
                    configuration={attributes[att]}
                    highlightBest={highlightBest}
                    className={`px-3 ${products.length === 1 && "py-2"}`}
                  />
                </tr>
              </Fragment>
            );
          })}
          {subTableLength > 6 && (
            <tr className="text-center items-center">
              <td
                colSpan={4}
                className="text-blue-500 font-semibold text-sm p-2"
              >
                <div className="flex justify-center">
                  <TextButton
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                  >
                    {expanded
                      ? "Show less"
                      : `Show ${subTableLength - 5} more rows`}
                    <ChevronDownIcon
                      className={`h-5 w-5 ${
                        expanded && "rotate-180"
                      } transition-all shrink-0`}
                    />
                  </TextButton>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </StandardCard>
  );
};

/**
 * The main compare table component.
 */
const ProductCompareTable = ({
  products,
  attributes,
  category,
  setComparingProduct,
  displayImage,
  isModal,
  className = "",
  vs = false,
  highlightBest = true,
  headerRows,
}: ProductCompareTableProps) => {
  const compareAttributes = getAttributes(attributes).filter((att) => {
    for (const product of products)
      if (product.attributes[att]?.value !== "N/A") return true;
    return false;
  });

  const dropdownOffset = !isModal
    ? "top-[41px] lg:top-[57px]"
    : "top-18 lg:top-20";

  const variantDropdownOffset = !isModal
    ? "top-[51px] lg:top-[67px]"
    : "top-20 lg:top-24";

  const { productVariants } = useGlobalAllProducts();

  console.log(productVariants);

  const compareAttributeGroups: string[][] = [];
  let currentGroup = [];
  for (const att of compareAttributes) {
    if (attributes[att].groupTitle) {
      compareAttributeGroups.push(currentGroup);
      currentGroup = [];
    }
    currentGroup.push(att);
  }
  if (currentGroup.length > 0) {
    compareAttributeGroups.push(currentGroup);
  }

  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const getRightBorderClass = (index, numberOfSelectors) => {
    const borderClass =
      index !== numberOfSelectors - 1
        ? "border-r-[1px] -mr-[1px] border-gray-400 pr-2"
        : "pl-2";

    return borderClass;
  };

  const getLeftBorderClass = (index) => {
    const borderClass =
      index !== 0 ? "border-l-[1px] border-gray-400 pl-2" : "pr-2";

    return borderClass;
  };

  const lgWidth = (index) => {
    if (products.length === 2) {
      if (index === 0) {
        return "lg:w-[340px]";
      } else {
        return "lg:w-[39%]";
      }
    } else {
      return "lg:w-[26.6%]";
    }
  };
  const width = products.length === 2 ? "w-1/2" : "w-1/3";

  return (
    <>
      <table
        className={` min-w-full mt-4 mb-2 ${className || ""} ${
          !headerRows && !displayImage && "hidden"
        }`}
      >
        <thead>
          {/* Render custom header rows if present. */}
          <>
            {displayImage && (
              <>
                <tr className="lg:h-[150px]">
                  <td className={"hidden lg:table-cell"} />
                  {products.map((product, index) => (
                    <td
                      key={index}
                      className={`${width} ${lgWidth(index)} relative px-4`}
                    >
                      <ProductImage
                        product={product}
                        categoryName={category}
                        sizeClassName="max-h-48 mx-auto"
                        widthToFetch={300}
                      />
                      {vs && (
                        <ABMarker letter={String.fromCharCode(index + 65)} />
                      )}
                      {/* "VS" floats in between the first and second columns. */}
                      {vs && index === 0 && (
                        <span className="text-2xl lg:text-4xl text-gray-500 font-bold absolute right-0 top-1/2 translate-x-[50%]">
                          VS
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              </>
            )}
          </>
          {/* Render custom header rows if present. */}
          {headerRows}
        </thead>
        <tbody></tbody>
      </table>
      <table
        className={`sticky ${dropdownOffset} bg-white z-20 min-w-full pb-4 ${
          className || ""
        }`}
      >
        <thead>
          {/* Select-product dropdowns */}
          {setComparingProduct && (
            <>
              <tr
                className={`sticky ${dropdownOffset} bg-white z-20 items-center`}
              >
                <td className={"hidden lg:table-cell "} />
                {products.map((product, index) => (
                  <td
                    key={index}
                    className={`${width} ${lgWidth(
                      index
                    )} align-left ${getRightBorderClass(
                      index,
                      products.length
                    )} ${getLeftBorderClass(index)}`}
                  >
                    <VariantSelectInput
                      position={index}
                      setSelectedProduct={setComparingProduct}
                      selectEvent={Events.CompareProductChange}
                      searchEvent={Events.CompareProductSearch}
                      placeholder={`${getProductLabels(product).shortLabel}`}
                      product={product}
                      hasVariants={productCategoryConfig.hasVariants}
                      numberOfSelectors={products.length}
                    />
                  </td>
                ))}
              </tr>
              {/* Select-product dropdowns */}
              <tr
                className={`sticky ${variantDropdownOffset} bg-white z-10 items-center`}
              >
                <td className={"hidden lg:table-cell "} />
                {products.map((product, index) => (
                  <td
                    key={index}
                    className={`${width} ${lgWidth(
                      index
                    )} align-left ${getRightBorderClass(
                      index,
                      products.length
                    )} ${getLeftBorderClass(index)}`}
                  >
                    {productCategoryConfig.hasVariants && productVariants && (
                      <VariantSelectDropdown
                        position={index}
                        product={product}
                        setSelectedProduct={setComparingProduct}
                        attributes={attributes}
                        numberOfSelectors={products.length}
                      />
                    )}
                  </td>
                ))}
              </tr>
            </>
          )}
        </thead>
      </table>

      {products.length === 1 && (
        <PanelLayout>
          {compareAttributeGroups.map((attributeGroup, index) => (
            <div className="break-inside-avoid mb-2 md:mb-3" key={index}>
              <ProductCompareSubTable
                products={products}
                attributes={attributes}
                attributeGroup={attributeGroup}
                index={index}
                className={className}
                highlightBest={highlightBest}
              />
            </div>
          ))}
        </PanelLayout>
      )}
      {products.length > 1 && (
        <>
          {compareAttributeGroups.map((attributeGroup, index) => (
            <div className="break-inside-avoid mb-2 md:mb-3" key={index}>
              <ProductCompareSubTable
                products={products}
                attributes={attributes}
                attributeGroup={attributeGroup}
                index={index}
                className={className}
                highlightBest={highlightBest}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ProductCompareTable;
