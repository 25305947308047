import Header from "@components/frame/header";
import { Footer } from "@components/page/footer";
import {
  ProductCategoryConfig,
  ProductCategoryConfigMap,
} from "@lib/model/product-category-config";
import ProductCategoryContextProvider, {
  ProductCategoryContext,
} from "contexts/product-category-context";
import {
  getCanonicalCompareSlugs,
  getCategoryImage,
  getCompareIndexUrl,
  getProductLabels,
  getVsPageUrl,
  getVsPageUrlBySlugs,
} from "@lib/utilities/shared-utilities";
import { ProductCombinationDisplayData } from "@lib/model/product";
import { Events } from "@lib/tracking/event-tracker";
import { ResponsivePagers } from "@components/common/pager";
import Meta from "./meta";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";
import ProductSelectInput, {
  ProductSelectInputSkeleton,
} from "@components/product/product-select-input";
import { useRouter } from "next/router";
import ProductCombinations from "@components/product/product-combinations";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { useContext, useState } from "react";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";

const CompareIndexPage = ({
  productCategory,
  combinations,
  popularCombinations,
  totalCount,
  totalPages,
  currentPage,
  configMap,
  numberOfProducts,
}: {
  productCategory: ProductCategoryConfig;
  combinations: ProductCombinationDisplayData[];
  popularCombinations: ProductCombinationDisplayData[];
  totalCount: number;
  totalPages: number;
  popular: [slug1: string, slug2: string][];
  currentPage: number;
  configMap: ProductCategoryConfigMap;
  numberOfProducts: number;
}) => {
  usePageScrollTracking({ page: "Compare listing" });
  const router = useRouter();

  const { pid0, pid1, s0, s1, page } = router.query as {
    pid0: string;
    pid1: string;
    s0: string;
    s1: string;
    page: string;
  };

  const isFiltered = pid0 || pid1 || s0 || s1;

  /**
   * getLink callback for the Pager component.
   */
  const getPagerLink = (page) => {
    const url = getCompareIndexUrl(productCategory, page, {
      pid0,
      pid1,
      s0,
      s1,
    });

    return url;
  };

  // We show a loading effect once the user has selected two products, while the
  // navigation is happening.
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Meta
        title={`Compare ${productCategory.pluralNoun}`}
        description={`Side-by-side comparison of ${productCategory.noun} specs, features and performance.`}
        url={getCompareIndexUrl(productCategory, currentPage)}
        image={getCategoryImage(productCategory)}
      />
      <Header configMap={configMap} />
      <ProductCategoryContextProvider productCategoryConfig={productCategory}>
        <div className="container my-12 max-w-screen-lg">
          <h1>Compare {productCategory.pluralNoun}</h1>
          <p className="text-base mt-8">
            {productCategory.compareHeaderCopy.replace(
              "[number of skus]",
              `${numberOfProducts}`
            )}
          </p>
          <div className="border border-gray-500 rounded-xl p-4 bg-panel-blue-1 my-10">
            <p className="text-sm text-center font-semibold">
              {productCategory.comparePageCopy}
            </p>
            <div className="flex flex-col sm:flex-row gap-3 mt-3 w-full items-center">
              <CompareProductSelectInput
                position={0}
                onRedirectToVsPage={() => setLoading(true)}
              />
              <span className="font-bold text-lg">VS</span>
              <CompareProductSelectInput
                position={1}
                onRedirectToVsPage={() => setLoading(true)}
              />
            </div>
          </div>

          {loading && <div className="mx-auto dot-flashing my-36"></div>}

          {!loading && (
            <>
              {!isFiltered && !page && popularCombinations && (
                <>
                  <h3 className="text-xl my-4">
                    Popular {capitalizeFirstLetter(productCategory.name)}
                  </h3>
                  <ProductCombinations
                    className="mb-10"
                    combinations={popularCombinations}
                  />
                </>
              )}

              <div className="mt-4">
                {combinations.length > 0 && (
                  <>
                    <ResultsTitle
                      isFiltered={isFiltered}
                      productCategory={productCategory}
                    />
                    <ProductCombinations
                      className="mb-10"
                      combinations={combinations}
                      testId="product-comparisons-list"
                    />
                    <ResponsivePagers
                      getLink={getPagerLink}
                      {...{ totalCount, totalPages, currentPage }}
                    />
                  </>
                )}
                {combinations.length === 0 && (
                  <div className="text-center w-full p-8 text-xl font-semibold">
                    No results found.
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ProductCategoryContextProvider>
      <Footer configMap={configMap} />
    </>
  );
};

/**
 * Inner content for the result summary title
 */
const ResultsTitle = ({ isFiltered, productCategory }) => {
  const defaultInputs = useUrlInputs();

  if (!isFiltered) {
    return (
      <h3 className="text-xl my-4">
        All {capitalizeFirstLetter(productCategory.noun)} Comparisons
      </h3>
    );
  }

  if (!defaultInputs) {
    return null;
  }

  return (
    <h4 className="text-base font-semibold my-4">
      Results for {defaultInputs.map((input) => `"${input}"`).join(" vs ")}
    </h4>
  );
};

/**
 * Gets the query inputs from the URL
 */
const useUrlInputs = () => {
  const { products } = useGlobalAllProducts();
  const router = useRouter();

  const { pid0, pid1, s0, s1 } = router.query as {
    pid0: string;
    pid1: string;
    s0: string;
    s1: string;
  };

  if (!router.isReady || !products) {
    return null;
  }

  return [
    { pid: pid0, s: s0 },
    { pid: pid1, s: s1 },
  ]
    .map(({ pid, s }) => {
      const defaultProduct =
        pid && products ? products.find((product) => product.id === pid) : null;

      if (defaultProduct) {
        return getProductLabels(defaultProduct).shortLabel;
      }

      return s || "";
    })
    .filter((inputString) => inputString);
};

/**
 * A special type of ProductSelectInput that navigates to a query URL on select
 * and search events.
 */
const CompareProductSelectInput = ({
  position,
  onRedirectToVsPage,
}: {
  position: number;
  onRedirectToVsPage?: () => void;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const router = useRouter();
  const urlInputs = useUrlInputs();
  const { products } = useGlobalAllProducts();

  const { query } = router;
  const { pid0, pid1, s0, s1 } = query as {
    pid0: string;
    pid1: string;
    s0: string;
    s1: string;
  };

  /**
   * Use the router to navigate to a url corresponding to a query.
   */
  const navigateForQuery = (
    position: number,
    variable: string,
    value: string
  ) => {
    // e.g. pid0, s1, etc.
    const newParam = `${variable}${position}`;
    const newQuery = { ...query };
    newQuery[newParam] = value;

    const params = Object.keys(newQuery)
      // Remove other filters for this position.
      .filter(
        (key) =>
          ["pid0", "pid1", "s0", "s1"].includes(key) &&
          (key.slice(-1) !== position.toString() || key === newParam)
      );

    router.push(
      `${window.location.pathname}?${params
        .map((key) => `${key}=${newQuery[key]}`)
        .join("&")}`
    );
  };

  /**
   * Handler for when someone inputs a search string (not a product select).
   */
  const handleSearch = ({ position, value }) =>
    navigateForQuery(position, "s", value);

  /**
   * Handler for when user selects a particular product
   */
  const handleSelect = ({ position, value }) => {
    const otherSelectedProductId = position === 0 ? pid1 : pid0;

    // We redirect right to the VS page if both sides have a direct selection.
    if (!otherSelectedProductId) {
      navigateForQuery(position, "pid", value);
    } else {
      const product1 = products.find((product) => product.id === value);
      const product2 = products.find(
        (product) => product.id === otherSelectedProductId
      );

      if (onRedirectToVsPage) {
        onRedirectToVsPage();
      }

      router.push(
        getVsPageUrlBySlugs(
          getCanonicalCompareSlugs([product1, product2]),
          productCategoryConfig
        )
      );
    }
  };

  if (!urlInputs) {
    return <ProductSelectInputSkeleton />;
  }

  return (
    <ProductSelectInput
      position={position}
      searchEvent={Events.CompareIndexProductSearch}
      selectEvent={Events.CompareIndexProductSelect}
      placeholder={`Select the first item to compare`}
      testId={`compare-list-autocomplete-0`}
      onSelect={handleSelect}
      onSearch={handleSearch}
      className="grow"
      defaultValue={urlInputs?.[position]}
    />
  );
};

export default CompareIndexPage;
