import FiftyFiftySection from "@components/frame/fifty-fifty-container";
import { laptopContentPhraseMap } from "@components/product/summary/laptop-usage-meter";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useAttributes } from "@lib/hooks/use-attributes";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { Product } from "@lib/model/product";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import {
  getQualitativeCompareWeight,
  getQualitativeWord,
} from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";

const getAdvantages = (
  productA: Product,
  productB: Product,
  category: string,
  attributes: AttributeConfigurationMap
) => {
  const advantages = {
    [productA.slug]: [],
    [productB.slug]: [],
  };

  switch (category) {
    case "laptops": {
      [
        "generalUseScore",
        "creativeUseScore",
        "gamingScore",
        "softwareScore",
        "contentScore",
      ].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} for ${laptopContentPhraseMap[dim].titlePhrase
              .toLowerCase()
              .replace("ai", "AI")}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} for ${laptopContentPhraseMap[dim].titlePhrase
              .toLowerCase()
              .replace("ai", "AI")}`
          );
        }
      });

      //screenscore
      const screenA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["overallScreenScore"].value)
      );
      const screenB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["overallScreenScore"].value)
      );

      if (screenA > screenB && screenA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["overallScreenScore"].value)
            ).toLowerCase()
          )} overall display quality`
        );
      } else if (screenB > screenA && screenB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["overallScreenScore"].value)
            ).toLowerCase()
          )} overall display quality`
        );
      }

      //build quality
      const buildA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["buildQuality"].value)
      );
      const buildB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["buildQuality"].value)
      );

      if (buildA > buildB && buildA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["buildQuality"].value)
            ).toLowerCase()
          )} overall build quality`
        );
      } else if (buildB > buildA && buildB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["buildQuality"].value)
            ).toLowerCase()
          )} overall build quality`
        );
      }

      //portability
      const portA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["mobilityScore"].value)
      );
      const portB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["mobilityScore"].value)
      );

      if (portA > portB && portA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["mobilityScore"].value)
            ).toLowerCase()
          )} portability`
        );
      } else if (portB > portA && portB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["mobilityScore"].value)
            ).toLowerCase()
          )} portability`
        );
      }

      //battery life
      const batteryA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["batteryRating"].value)
      );
      const batteryB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["batteryRating"].value)
      );

      if (batteryA > batteryB && batteryA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["batteryRating"].value)
            ).toLowerCase()
          )} battery life`
        );
      } else if (batteryB > batteryA && batteryB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["batteryRating"].value)
            ).toLowerCase()
          )} battery life`
        );
      }

      //speakers
      const soundA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["speakerQualityScore"].value)
      );
      const soundB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["speakerQualityScore"].value)
      );

      if (soundA > soundB && soundA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["speakerQualityScore"].value)
            ).toLowerCase()
          )} speakers`
        );
      } else if (soundB > soundA && soundB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["speakerQualityScore"].value)
            ).toLowerCase()
          )} speakers`
        );
      }

      //keyboard
      const kbA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["keyboardQuality"].value)
      );
      const kbB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["keyboardQuality"].value)
      );

      if (kbA > kbB && kbA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["keyboardQuality"].value)
            ).toLowerCase()
          )} keyboard`
        );
      } else if (kbB > kbA && kbB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["keyboardQuality"].value)
            ).toLowerCase()
          )} keyboard`
        );
      }

      break;
    }
    case "tvs": {
      [
        "brightRoomScore",
        "darkRoomScore",
        "gamingScore",
        "movieScore",
        "sportsScore",
        "newsScore",
        "animationScore",
        "monitorScore",
        "upscaling",
      ].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        }
      });

      ["motionProcessing", "viewingAngle", "reflectionsScore"].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        }
      });

      break;
    }
    case "headphones": {
      [
        "musicScoreRescaled",
        "callScoreRescaled",
        "gamingScoreRescaled",
        "tvScoreRescaled",
        "exerciseScoreRescaled",
        "travelScoreRescaled",
        "podcastScoreRescaled",
      ].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("tv", "TV")}`
          );
        }
      });
      break;
    }
    case "smartphones": {
      const cameraA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["pictureScore"].value)
      );
      const cameraB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["pictureScore"].value)
      );

      if (cameraA > cameraB && cameraA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["pictureScore"].value)
            ).toLowerCase()
          )} for taking photos`
        );
      } else if (cameraB > cameraA && cameraB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["pictureScore"].value)
            ).toLowerCase()
          )} for taking photos`
        );
      }

      const videosA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["pictureScore"].value)
      );
      const videosB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["pictureScore"].value)
      );

      if (videosA > videosB && videosA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["videoScore"].value)
            ).toLowerCase()
          )} for recording videos`
        );
      } else if (videosB > videosA && videosB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["videoScore"].value)
            ).toLowerCase()
          )} for recording videos`
        );
      }

      const batteryA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["batteryRating"].value)
      );
      const batteryB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["batteryRating"].value)
      );

      if (batteryA > batteryB && batteryA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["batteryRating"].value)
            ).toLowerCase()
          )} battery life`
        );
      } else if (batteryB > batteryA && batteryB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["batteryRating"].value)
            ).toLowerCase()
          )} battery life`
        );
      }

      const screenA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["screenScore"].value)
      );
      const screenB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["screenScore"].value)
      );

      if (screenA > screenB && screenA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["screenScore"].value)
            ).toLowerCase()
          )} display quality`
        );
      } else if (screenB > screenA && screenB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["screenScore"].value)
            ).toLowerCase()
          )} display quality`
        );
      }

      const performanceA = getQualitativeCompareWeight(
        parseFloat(productA.attributes["performance"].value)
      );
      const performanceB = getQualitativeCompareWeight(
        parseFloat(productB.attributes["performance"].value)
      );

      if (performanceA > performanceB && performanceA > 1) {
        advantages[productA.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productA.attributes["performance"].value)
            ).toLowerCase()
          )} performance`
        );
      } else if (screenB > screenA && screenB > 1) {
        advantages[productB.slug].push(
          `${capitalizeFirstLetter(
            getQualitativeWord(
              parseFloat(productB.attributes["performance"].value)
            ).toLowerCase()
          )} performance`
        );
      }

      break;
    }
    case "monitors": {
      [
        "casualGamingScore",
        "competitiveGamingScore",
        "productivityScore",
        "mediaConsumptionScore",
      ].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} for ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()}`
          );
        }
      });

      [
        "refreshRateScore",
        "textQualityScore",
        "resolutionScore",
        "contrastScore",
        "brightnessScore",
        "colorVolumeScore",
        "responseTimeScore",
      ].map((dim) => {
        const weightA = getQualitativeCompareWeight(
          parseFloat(productA.attributes[dim].value)
        );
        const weightB = getQualitativeCompareWeight(
          parseFloat(productB.attributes[dim].value)
        );

        if (weightA > weightB && weightA > 1) {
          advantages[productA.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productA.attributes[dim].value)
              ).toLowerCase()
            )} ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("persistence blur", "refresh rate")
              .replace("ghosting", "response time")}`
          );
        } else if (weightB > weightA && weightB > 1) {
          advantages[productB.slug].push(
            `${capitalizeFirstLetter(
              getQualitativeWord(
                parseFloat(productB.attributes[dim].value)
              ).toLowerCase()
            )} ${attributes[dim].displayName
              ?.replace("Score", "")
              .toLowerCase()
              .replace("persistence blur", "refresh rate")
              .replace("ghosting", "response time")}`
          );
        }
      });
      break;
    }
  }

  return advantages;
};

const AdvantagesSection = ({
  productA,
  productB,
  category,
}: {
  productA: Product;
  productB: Product;
  category: string;
}) => {
  const { attributes } = useAttributes();
  const advantages = getAdvantages(productA, productB, category, attributes);

  return (
    <FiftyFiftySection className="!gap-x-6">
      <StandardCard
        className="mb-4 mt-2 font-sans font-normal rounded-lg overflow-hidden border-gray-500"
        innerClassName="!p-0"
        headerClassName="border-blue-500"
      >
        <div className="py-3 pl-3 font-sans font-semibold text-base border-b border-gray-500 bg-blue-50">
          Advantages of the {getProductLabels(productA).shortLabel}
        </div>
        <div className="px-4 sm:pr-2 py-3">
          {advantages[productA.slug]?.length > 0 ? (
            <ul>
              {advantages[productA.slug].map((advantage, index) => (
                <li key={index} className="py-1">
                  <span className="inline-flex items-start">
                    <PlusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-green-500 stroke-[4px]" />
                    <span>{capitalizeFirstLetter(advantage)}</span>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li className="py-1">
                <span className="inline-flex items-start">
                  <MinusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-red-500 stroke-[4px]" />
                  <span>
                    The {getProductLabels(productA).shortLabel} has no clear
                    advantages over the {getProductLabels(productB).shortLabel}.
                  </span>
                </span>
              </li>
            </ul>
          )}
        </div>
      </StandardCard>
      <StandardCard
        className="mb-4 mt-2 font-sans font-normal rounded-lg overflow-hidden border-gray-500"
        innerClassName="!p-0"
      >
        <div className="py-3 pl-3 font-sans font-semibold text-base border-b border-gray-500 bg-blue-50">
          Advantages of the {getProductLabels(productB).shortLabel}
        </div>
        <div className="px-4 mt-0 py-3 sm:pr-2">
          {advantages[productB.slug]?.length > 0 ? (
            <ul>
              {advantages[productB.slug].map((advantage, index) => (
                <li key={index} className="py-1">
                  <span className="inline-flex items-start">
                    <PlusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-green-500 stroke-[4px]" />
                    <span>{capitalizeFirstLetter(advantage)}</span>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li className="py-1">
                <span className="inline-flex items-start">
                  <MinusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-red-500 stroke-[4px]" />
                  <span>
                    The {getProductLabels(productB).shortLabel} has no clear
                    advantages over the {getProductLabels(productA).shortLabel}.
                  </span>
                </span>
              </li>
            </ul>
          )}
        </div>
      </StandardCard>
    </FiftyFiftySection>
  );
};

export default AdvantagesSection;
