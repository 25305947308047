import HeatMeter from "./heat-meter";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import {
  ArcScoreDisplay,
  PanelSetProps,
  ScoreDisplay,
  SummaryTitles,
} from "./common";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { useAttribute } from "@lib/hooks/use-attributes";

/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const PhoneCameraScore = (props: PanelSetProps) => {
  const { selectedProduct } = props;
  const { explanation } = useExplanation(
    "cameraQualityExplanation",
    selectedProduct
  );

  const score = parseFloat(
    props.selectedProduct.attributes["cameraScore"]?.value
  );

  const attributeConfiguration = useAttribute("cameraScore");
  const color = getMeetsNeedsColor(!explanation.isUnmet);

  return (
    <DonePanel
      title="Overall Camera Quality"
      color={color}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <ArcScoreDisplay
        product={selectedProduct}
        attribute={attributeConfiguration}
        icon={getAspectIcon("smartphones", "cameraScore")}
        score={score}
        color={color}
      />
      <SummaryTitles
        {...{
          first: (
            <>
              The {getProductLabels(selectedProduct).shortLabel} has{" "}
              <strong>
                {getQualitativeWord(score)
                  .toLowerCase()
                  .replace("best", "the best")
                  .replace("fair", "only fair")}
              </strong>{" "}
              overall camera quality.
            </>
          ),
          second:
            "PerfectRec's overall camera quality score is a composite of our photo and video scores. It provides a comprehensive view of the quality of the camera system as a whole.",
        }}
        showSecondOnly={true}
      />
    </DonePanel>
  );
};

export default PhoneCameraScore;
