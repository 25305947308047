import { IFaq } from "@lib/model/contentful";
import CollapsibleSection from "./collapsible-section";
import { renderRichText } from "./contentful-rich-text";
import { useContext } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { Product } from "@lib/model/product";
import {
  centsToDollars,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { Brand } from "@lib/model/product-category-config";
import {
  useAllPurchaseData,
  usePurchaseData,
} from "@lib/hooks/use-purchase-data";
import PurchaseOption from "@lib/model/purchase-option";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import Link from "next/link";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";

/** *
 * Gets dynamic Product based FAQs
 */

const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getProductFaqs = (
  product: Product,
  category: string,
  getBrand: (string) => Brand,
  purchaseData: PurchaseOption[]
) => {
  const productFaqs = [];
  if (!product) {
    return productFaqs;
  }
  const shortLabel = getProductLabels(product).shortLabel;
  switch (category) {
    case "tvs": {
      const speakerFaq = {
        sys: {
          id: product.id + "speaker",
        },
        fields: {
          title: `Does ${shortLabel} have good speakers?`,
          body:
            parseFloat(product.attributes["speakerPower"].value) > 20
              ? `Yes, the ${shortLabel} has good speakers rated at ${product.attributes["speakerPower"].value} Watts.`
              : `No, the ${shortLabel} does not have particularly good speakers. You may want to use a soundbar or other external speakers with this TV.`,
        },
      };

      const refreshFaq = {
        sys: {
          id: product.id + "refreshRate",
        },
        fields: {
          title: `What is the refresh rate for ${shortLabel}?`,
          body: `The ${shortLabel} has a ${product.attributes["refreshRate"].value} Hz refresh rate.`,
        },
      };

      const resFaq = {
        sys: {
          id: product.id + "screenResolution",
        },
        fields: {
          title: `Does ${shortLabel} support 4K content?`,
          body:
            product.attributes["screenResolution"].value === "8K"
              ? `Yes, the ${shortLabel} supports 4K (3840 x 2160) and 8K (7680 x 4320) resolution content.`
              : product.attributes["screenResolution"].value === "4K"
              ? `Yes, the ${shortLabel} supports 4K (3840 x 2160) resolution content.`
              : `No, the ${shortLabel} only supports 1080p (1920 x 1080) content.`,
        },
      };

      const hdmiFaq = {
        sys: {
          id: product.id + "HDMI",
        },
        fields: {
          title: `How many HDMI ports does the ${shortLabel} have?`,
          body: `The ${shortLabel} has ${product.attributes["hdmiPorts"].value} HDMI ports.`,
        },
      };

      productFaqs.push(speakerFaq, refreshFaq, resFaq, hdmiFaq);
      break;
    }
    case "laptops": {
      const gamingFaq = {
        sys: {
          id: product.id + "gaming",
        },
        fields: {
          title: `Is ${shortLabel} a gaming laptop?`,
          body:
            parseFloat(product.attributes["gamingScore"].value) >= 7 &&
            product.attributes["suitableForIntensiveGaming"].value === "Yes"
              ? `Yes, the ${shortLabel} is a gaming laptop, ${
                  product.attributes["gamingLaptopAppearance"].value === "Yes"
                    ? "and it has a gaming laptop appearance."
                    : "but it does not necessarily look like one."
                }`
              : `No, the ${shortLabel} is not a gaming laptop.`,
        },
      };

      const ramSlots = parseFloat(product.attributes["ramSlots"].value);
      const storageSlots = parseFloat(product.attributes["storageSlots"].value);

      const getUpgradeBody = (ramSlots, storageSlots) => {
        if (ramSlots > 0 && storageSlots > 0) {
          return `Yes, the ${shortLabel} has ${ramSlots} slots for upgrading RAM and ${storageSlots} slots for upgrading storage.`;
        } else if (ramSlots > 0) {
          return `Yes, the ${shortLabel} has ${ramSlots} slots for upgrading RAM.`;
        } else if (storageSlots > 0) {
          return `Yes, the ${shortLabel} has ${storageSlots} slots for upgrading storage.`;
        } else {
          return `No, the ${shortLabel} does not have any open slots for adding more RAM or storage.`;
        }
      };

      const upgradeFaq = {
        sys: {
          id: product.id + "upgrade",
        },
        fields: {
          title: `Can ${shortLabel} be upgraded after purchase?`,
          body: getUpgradeBody(ramSlots, storageSlots),
        },
      };

      const brandFaq = {
        sys: {
          id: product.id + "brand",
        },
        fields: {
          title: `Is ${product.manufacturer} a good brand?`,
          body: getBrand(product.manufacturer)?.description,
        },
      };

      productFaqs.push(gamingFaq, upgradeFaq, brandFaq);
      break;
    }
    case "smartphones": {
      const releaseFaq = {
        sys: {
          id: product.id + "release",
        },
        fields: {
          title: `When was the ${shortLabel} released?`,
          body: `The ${shortLabel} was released in ${months[
            product.attributes["monthReleased"].value
          ].slice(0, 3)} ${product.attributes["dayReleased"].value}, ${
            product.attributes["yearReleased"].value
          } `,
        },
      };

      const priceFaq = {
        sys: {
          id: product.id + "price",
        },
        fields: {
          title: `How much does the ${shortLabel} cost?`,
          body:
            purchaseData?.length > 0 ? (
              <>
                The {shortLabel} is currently available{" "}
                {purchaseData[0].condition} for{" "}
                {centsToDollars(purchaseData[0].price)} from{" "}
                <a
                  className="text-blue-500 font-normal hover:underline"
                  href={purchaseData[0].link}
                >
                  {purchaseData[0].storeName}.
                </a>
              </>
            ) : (
              <>This product is not avaialable for purchase anywhere.</>
            ),
        },
      };

      const ipFaq = {
        sys: {
          id: product.id + "water",
        },
        fields: {
          title: `Is the ${shortLabel} waterproof?`,
          body:
            parseFloat(product.attributes["waterproofRating"].value) > 7.0
              ? `Yes, the ${shortLabel} is officially certified to be protected from immersion in water.`
              : `No, the ${shortLabel} is not officially certified to be safe from being dropped into water.`,
        },
      };

      const batteryFaq = {
        sys: {
          id: product.id + "battery",
        },
        fields: {
          title: `How long does the battery last on the ${shortLabel}?`,
          body: `The ${shortLabel} will last for ${product.attributes["batteryLife"].value} hours under typical usage conditions.`,
        },
      };

      const wordMap = {
        best: "the best",
        excellent: "an excellent",
        "very good": "a very good",
        good: "a good",
        fair: "a fair",
        poor: "a poor",
      };

      const cameraFaq = {
        sys: {
          id: product.id + "camera",
        },
        fields: {
          title: `Does the ${shortLabel} have a good camera?`,
          body: `The ${shortLabel} has ${
            wordMap[
              getQualitativeWord(
                product.attributes["cameraScore"].value
              ).toLowerCase()
            ]
          } camera.`,
        },
      };

      productFaqs.push(releaseFaq, priceFaq, ipFaq, batteryFaq, cameraFaq);
      break;
    }
    case "headphones": {
      const releaseDate = product.attributes["releaseDate"].value
        .split("-")
        .reverse();
      const releaseFaq = {
        sys: {
          id: product.id + "release",
        },
        fields: {
          title: `How old is ${shortLabel}?`,
          body: `The ${shortLabel} was first released in ${
            months[parseFloat(releaseDate[0])]
          } ${releaseDate[1]}.`,
        },
      };

      const priceFaq = {
        sys: {
          id: product.id + "price",
        },
        fields: {
          title: `How much is ${shortLabel}?`,
          body:
            purchaseData?.length > 0 ? (
              <>
                The {shortLabel} is currently available{" "}
                {purchaseData[0].condition} for{" "}
                {centsToDollars(purchaseData[0].price)} from{" "}
                <a
                  className="text-blue-500 font-normal hover:underline"
                  href={purchaseData[0].link}
                >
                  {purchaseData[0].storeName}.
                </a>
              </>
            ) : (
              <>This product is not avaialable for purchase anywhere.</>
            ),
        },
      };

      const gymFaq = {
        sys: {
          id: product.id + "gym",
        },
        fields: {
          title: `Is ${shortLabel} good for the gym?`,
          body: `The ${shortLabel} is ${getQualitativeWord(
            parseFloat(product.attributes["exerciseScoreRescaled"].value)
          ).toLowerCase()} for use during exercise.`,
        },
      };

      productFaqs.push(releaseFaq, priceFaq, gymFaq);
      break;
    }
  }
  return productFaqs;
};

const getLlmFaqs = (
  product?: Product,
  llmFaqs?: Record<PromptType, StoredLlmOutput>
) => {
  if (!product || !llmFaqs) {
    return [];
  }
  const shortLabel = getProductLabels(product).shortLabel;

  const faqs = [];

  const isGoodFaq = {
    sys: {
      id: product.id + "isGood",
    },
    fields: {
      title: `Is the ${shortLabel} good?`,
      body: llmFaqs.isGood?.result,
    },
  };

  llmFaqs.isGood?.result && faqs.push(isGoodFaq);

  const worthBuyingFaq = {
    sys: {
      id: product.id + "worthBuying",
    },
    fields: {
      title: `Is the ${shortLabel} worth buying?`,
      body: llmFaqs.worthBuying?.result,
    },
  };

  llmFaqs.worthBuying?.result && faqs.push(worthBuyingFaq);

  return faqs;
};

/**
 * Sort a set of FAQs into those belonging to a category, and general ones.
 */
export const sortFaqs = (faqs: IFaq[], categoryName: string) => ({
  forCategoryFaqs: faqs
    ? faqs.filter((faq) => faq.fields.category === categoryName)
    : [],
  generalFaqs: faqs
    ? faqs.filter((faq) => faq.fields.category === "general")
    : [],
});

/**
 * A single set of FAQs.
 */
export const FaqSet = ({
  faqs,
  title,
  nonContentful,
}: {
  faqs: IFaq[];
  title: string;
  nonContentful?: boolean;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h3 className="small-caps text-xl font-sans font-semibold">{title}</h3>
      <div>
        {faqs.map((faq, index) => (
          <CollapsibleSection
            heading={faq.fields.title}
            key={faq.sys.id}
            isLast={index === faqs.length - 1}
          >
            <div className="my-2 content-faq-text px-2 text-sm">
              {nonContentful
                ? faq.fields.body
                : renderRichText(faq.fields.body, false, "tight")}
            </div>
          </CollapsibleSection>
        ))}
      </div>
    </div>
  );
};

export const Faqs = ({
  product,
  llmFaqs,
}: {
  product?: Product;
  llmFaqs?: Record<PromptType, StoredLlmOutput>;
}) => {
  const { purchaseData } = usePurchaseData(product);

  const { faqs, productCategoryConfig, getBrand } = useContext(
    ProductCategoryContext
  );

  const { forCategoryFaqs, generalFaqs } = sortFaqs(
    faqs,
    productCategoryConfig.name
  );

  const productFaqs = getProductFaqs(
    product,
    productCategoryConfig.name,
    getBrand,
    purchaseData
  );

  const processedLlmFaqs = getLlmFaqs(product, llmFaqs);

  return (
    <>
      {productFaqs.length > 0 && (
        <FaqSet
          title={`FAQs about ${getProductLabels(product).shortLabel}`}
          faqs={[...processedLlmFaqs, ...productFaqs]}
          nonContentful={true}
        />
      )}
      {forCategoryFaqs.length > 0 && (
        <FaqSet
          title={`FAQs about ${productCategoryConfig.pluralNoun}`}
          faqs={forCategoryFaqs}
        />
      )}
      {generalFaqs && (
        <Link href="/faq" className="text-blue-600">
          Questions about PerfectRec &gt;
        </Link>
      )}
    </>
  );
};

/**
 * The FAQs panel, with category-specific and general FAQ sets.
 */
export const FaqsCard = ({
  className,
  product,
  llmFaqs,
}: {
  className?: string;
  product?: Product;
  llmFaqs?: Record<PromptType, StoredLlmOutput>;
}) => {
  return (
    <StandardCard innerClassName="flex flex-col gap-6" className={className}>
      <Faqs product={product} llmFaqs={llmFaqs} />
    </StandardCard>
  );
};
