import { isClient } from "@lib/utilities/client-utilities";

interface Cache {
  [key: string]: any;
}

let cache: Cache = {};

export const set = (key: string, value: any, ttl?: number): void => {
  throwIfClient();

  if (ttl) {
    setTimeout(() => {
      delete cache[key];
    }, ttl);
  }
  cache[key] = value;
};

export const get = (key: string): any => {
  throwIfClient();

  return cache[key];
};

export const clear = (): void => {
  throwIfClient();

  cache = {};
};

// Add a throw if client to prevent accidental use of server cache on client.
const throwIfClient = () => {
  if (isClient())
    throw new Error("Server cache should not be used directly on the client");
};
