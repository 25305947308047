import { useAttributes } from "@lib/hooks/use-attributes";
import { Product } from "@lib/model/product";
import ProductCompareTable from "@components/compare/product-compare-table";
import CompareButton from "@components/compare/compare-button";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import {
  CompareHeaderSmall,
  ComparisonHeading,
} from "@components/compare/compare-components";

/**
 * Shows the compare view on the done page.
 */
const ComparePanel = ({
  compareProducts,
  categoryName,
}: {
  compareProducts: Product[];
  categoryName: string;
}) => {
  const { attributes } = useAttributes();

  return (
    <StandardCard className="max-h-[458px] overflow-hidden relative">
      <ComparisonHeading />
      <ProductCompareTable
        products={compareProducts}
        category={categoryName}
        attributes={attributes}
        headerRows={
          <CompareHeaderSmall
            category={categoryName}
            products={compareProducts}
          />
        }
      />
      <div className="absolute bottom-0 left-0 w-full see-more-overlay">
        <div className="bg-white p-5">
          <CompareButton
            products={compareProducts}
            btnText="Show full comparison table"
          />
        </div>
      </div>
    </StandardCard>
  );
};

export default ComparePanel;
