import DonePanel from "@components/recommendation/done-panel";
import { ScoreDisplay, SummaryTitles } from "../../product/summary/common";
import { Product } from "@lib/model/product";
import { laptopContentPhraseMap } from "@components/product/summary/laptop-usage-meter";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import { CompareCardScoreDisplay } from "../compare-components";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";

const otherScoreMap = {
  buildQuality: {
    textPhrase:
      "PerfectRec’s Build Quality Score incorporates case materials, display and keyboard flex, hinge quality, and overall reliability.",
  },
};

const usageAttributeToPromptTypeMap = {
  laptops: {
    generalUseScore: "compareGeneralUseLaptop",
    creativeUseScore: "compareEngineeringLaptop",
    gamingScore: "compareGamingLaptop",
    softwareScore: "compareSoftwareLaptop",
    contentScore: "compareContentLaptop",
  },
};

const dims = {
  laptops: {
    generalUseScore: [
      "resolution",
      "displayTechnology",
      "brightness",
      "batteryLifeHours",
      "keyboardQuality",
      "mobilityScore",
    ],
    creativeUseScore: ["cpuScore", "gpuScore", "ram"],
    gamingScore: [
      "gpuScore",
      "resolution",
      "refreshRate",
      "dlss",
      "muxSwitchOrAdvancedOptimus",
      "fanNoiseScore",
    ],
    softwareScore: ["cpuScore", "ram", "resolution", "keyboardQuality"],
    contentScore: ["cpuScore", "resolution", "ram", "displayTechnology"],
  },
  monitors: {
    casualGamingScore: [
      "resolution",
      "refreshRate",
      "contrast",
      "sdrBrightness",
      "hdrBrightness",
      "p3Gamut",
      "coating",
    ],
    competitiveGamingScore: [
      "refreshRate",
      "totalResponseTime",
      "vrr",
      "strobing",
      "sdrBrightness",
    ],
    productivityScore: ["resolution", "ppi", "adjustableStand", "coating"],
    mediaConsumptionScore: [
      "resolution",
      "contrast",
      "sdrBrightness",
      "hdrBrightness",
      "coating",
    ],
  },
};

const ProductScoreComparison = (props: {
  productA: Product;
  productB: Product;
  usageAttribute: string;
  keySpecs?: boolean;
  compareResults: any;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const { productA, productB, usageAttribute, comparisons } = props;

  const { attributes } = useAttributes();

  const attributeConfiguration = useAttribute(usageAttribute);

  if (!productA || !productB) {
    return null;
  }

  const { winner, pctA, pctB, first } = props.compareResults[usageAttribute];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  const title = attributeConfiguration.displayName.replace("Score", "");

  const llmComparison =
    comparisons &&
    comparisons[
      usageAttributeToPromptTypeMap[productA.metadata.categoryName][
        usageAttribute
      ]
    ]?.result;

  const second =
    productA.metadata.categoryName !== "laptops"
      ? undefined
      : laptopContentPhraseMap[usageAttribute]
      ? laptopContentPhraseMap[usageAttribute].textPhrase
      : otherScoreMap[usageAttribute]?.textPhrase;

  return (
    <DonePanel
      title={title}
      className={"!bg-white"}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={attributes[usageAttribute]}
        categoryName={productA.metadata.categoryName}
      />
      <div className="flex flex-col w-full mt-2 mb-6 text-gray-700">
        {dims[productA.metadata.categoryName] &&
          dims[productA.metadata.categoryName][usageAttribute]?.map(
            (dim, index) => {
              return (
                <div
                  key={index}
                  className={`flex justify-between items-end border-b-[1px] ${
                    index === 0 && "border-t-[1px]"
                  } border-gray-300 py-3`}
                >
                  <ScoreDisplay
                    attribute={attributes[dim]}
                    product={productA}
                    scoreClass="!text-base"
                    unitClass="!text-sm"
                  />
                  <div className="tracking-wider font-bold text-xs">
                    {attributes[dim].displayName.toUpperCase()}
                  </div>
                  <ScoreDisplay
                    attribute={attributes[dim]}
                    product={productB}
                    scoreClass="!text-base"
                    unitClass="!text-sm"
                  />
                </div>
              );
            }
          )}
      </div>
      <SummaryTitles
        {...{
          first,
          second,
          llm: llmComparison,
        }}
      />
    </DonePanel>
  );
};

export default ProductScoreComparison;
