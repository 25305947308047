import { ShowMoreBtn } from "@components/common/button";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { ChevronDownIcon, StarIcon } from "@heroicons/react/20/solid";
import { useAttributes } from "@lib/hooks/use-attributes";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import { Product } from "@lib/model/product";
import { roundDecimal } from "@lib/utilities/shared-utilities";
import React, { useState } from "react";

const starAttributes = {
  tvs: [
    { title: "Movies & TV shows", slug: "movieScore" },
    { title: "Gaming", slug: "gamingScore" },
    { title: "Sports", slug: "sportsScore" },
    { title: "News, Talk & Reality TV", slug: "newsScore" },
    { title: "Cartoons & Animaton", slug: "animationScore" },
    { title: "Bright Room", slug: "brightRoomScore" },
    {
      title: "Overall Picture",
      slug: "screenScore",
      explainer:
        "PerfectRec developed this proprietary score designed to provide an overall assessment of a display's visual performance.  It is a comprehensive metric that takes into account many quantitative measures of picture quality, including: contrast ratio, color gamut, color volume, color accuracy, local dimming, gray uniformity, black uniformity, viewing angle, reflections (glare) and color calibration. By combining multiple factors, this score offers a more nuanced and comprehensive evaluation of a display's capabilities than a single factor.",
    },
  ],
  laptops: [
    { title: "General Use", slug: "generalUseScore" },
    { title: "Gaming & AI", slug: "gamingScore" },
    { title: "Engineering & Design", slug: "creativeUseScore" },
    { title: "Software Development", slug: "softwareScore" },
    { title: "Content Creation", slug: "contentScore" },
    {
      title: "Battery Life",
      slug: "batteryRating",
      explainer:
        "PerfectRec’s Battery Life rating is based on hours of battery life in typical usage scenarios.",
    },
    {
      title: "Portability",
      slug: "mobilityScore",
      explainer:
        "PerfectRec’s portability rating takes into account the volume and thickness of the laptop as well as its weight.",
    },
    {
      title: "General Use Screen Quality",
      slug: "generalUseScreenScore",
      explainer:
        "The general use screen score is based on the screen technology, contrast ratio, resolution, brightness, color gamut and color accuracy. This score does not take into account specialized display features for specific use cases, such as high refresh rate for gaming.",
    },
    { title: "Build Quality", slug: "buildQuality" },
    {
      title: "Operating Noise",
      slug: "fanNoiseScore",
      explainer:
        "PerfectRec’s Operating Noise Score is based on fan noise measurements. Laptops with a fanless design, such as Macbooks, receive the maximum score.",
    },
    {
      title: "Keyboard Quality",
      slug: "keyboardQuality",
      explainer:
        "The Keyboard Quality score is based on the PerfectRec experts’ assessment of tactile feedback, typing comfort, keyboard reliability and typing noise.",
    },
  ],
  headphones: [
    { title: "Music", slug: "musicScoreRescaled" },
    { title: "Calls", slug: "callScoreRescaled" },
    { title: "Gaming", slug: "gamingScoreRescaled" },
    { title: "TV & Video", slug: "tvScoreRescaled" },
    { title: "Exercise", slug: "exerciseScoreRescaled" },
    { title: "Air Travel", slug: "travelScoreRescaled" },
    { title: "Podcasts & Audiobooks", slug: "podcastScoreRescaled" },
    {
      title: "Portability",
      slug: "portabilityScoreRescaled",
      explainer:
        "The Portability score is based on an overall assessment that includes volume and maximum dimension of the product (and case, if relevant), and its weight. Special features like foldability for headphones are also taken into account.",
    },
    {
      title: "Battery Life",
      slug: "batteryRating",
      explainer:
        "The Battery Life rating is based on hours of typical, actual usage.",
    },
  ],
  smartphones: [
    {
      title: "Overall Camera",
      slug: "cameraScore",
      explainer:
        "The Overall Camera rating is based on a combination of the comprehensive Photos and Videos ratings, with photo quality weighted 80% and video quality weighted 20%.",
    },
    {
      title: "Battery Life",
      slug: "batteryRating",
      explainer:
        "The Battery Life rating is based on overall actual measured battery life during typical usage.",
    },
    {
      title: "Photos",
      slug: "pictureScore",
      explainer:
        "The Photos rating is based on PerfectRec’s assessment of a variety of factors including resolution, dynamic range, low-light performance, versatility of shooting modes, and selfie quality, as well as software features, photo processing quality, and ease of use.",
    },
    {
      title: "Videos",
      slug: "videoScore",
      explainer:
        "The Videos rating is based on PerfectRec’s assessment of a variety of factors including framerate at different resolutions, video stabilization, video dynamic range, video low-light performance, and mic quality, as well as software features, video processing quality, and ease of use.",
    },
    {
      title: "Performance",
      slug: "performance",
      explainer:
        "The Performance rating is based on GeekBench performance benchmark scores, combined with PerfectRec’s assessment of real-life typical use cases.",
    },
  ],
  tablets: [],
  monitors: [
    {
      title: "Casual Gaming",
      slug: "casualGamingScore",
      explainer:
        "The casual gaming score is based on a variety of factors including refresh rate, resolution, contrast, brightness, color gamut and screen coating.",
    },
    {
      title: "Competitive Gaming",
      slug: "competitiveGamingScore",
      explainer:
        "The competitive gaming score is based on a variety of factors including refresh rate, total response time, variable refresh rate, strobing and brightness.",
    },
    {
      title: "Productivity",
      slug: "productivityScore",
      explainer:
        "The productivity score is based on a variety of factors like resolution, screen size, pixels per inch, ergonomics and screen coating.",
    },
    {
      title: "Media Consumption",
      slug: "mediaConsumptionScore",
      explainer:
        "The media comparison score is based on a variety of factors like resolution, contrast, brightness and screen coating",
    },
  ],
};

const roundToPointFive = (score: number) => {
  if (score < 3.0) {
    return score;
  }
  if (score < 3.3) {
    return 3.0;
  } else if (score < 3.9) {
    return 3.5;
  } else if (score < 4.3) {
    return 4.0;
  } else if (score < 4.8) {
    return 4.5;
  } else {
    return 5.0;
  }
};

const convertToStars = (rating: string) => {
  if (rating === "N/A") {
    return 1;
  }

  const ratingFloat = parseFloat(rating);

  let starRating;
  if (ratingFloat < 6) {
    starRating = 1 + (ratingFloat - 1) * 0.4;
  } else if (ratingFloat < 7) {
    starRating = 3 + (ratingFloat - 6) * 1;
  } else if (ratingFloat < 8) {
    starRating = 4 + (ratingFloat - 7) * 0.4;
  } else if (ratingFloat < 9) {
    starRating = 4.4 + (ratingFloat - 8) * 0.3;
  } else if (ratingFloat < 9.5) {
    starRating = 4.7 + (ratingFloat - 9) * 0.4;
  } else if (ratingFloat <= 10) {
    starRating = 4.9 + (ratingFloat - 9.5) * 0.2;
  }

  return roundDecimal(starRating, 1);
};

export const getStarRatings = (
  product: Product,
  allAttributes: any,
  categoryName: string
) => {
  const starRatings = starAttributes[categoryName].map((attribute) => {
    const outAttr = {} as {
      slug: string;
      title: string;
      stars: number;
      explainer: string;
    };
    outAttr.title = attribute.title;
    outAttr.slug = attribute.slug;
    outAttr.stars = convertToStars(product.attributes[attribute.slug].value);
    if (!attribute.explainer) {
      outAttr.explainer = allAttributes[attribute.slug]?.explanation;
    } else {
      outAttr.explainer = attribute.explainer;
    }
    return outAttr;
  });
  return starRatings.sort((a, b) => b.stars - a.stars);
};

//Found this component here: https://github.com/tailwindlabs/heroicons/discussions/424
const Star = ({
  variant,
  width,
  height,
}: {
  variant: "filled" | "empty" | "half";
  width: string;
  height: string;
}) => {
  let c1, c2;
  if (variant === "filled") {
    c1 = "#2563EB";
    c2 = "#2563EB";
  } else if (variant === "empty") {
    c1 = "#FFFFFF";
    c2 = "#FFFFFF";
  } else if (variant === "half") {
    c1 = "#2563EB";
    c2 = "#FFFFFF";
  }

  const id = Math.random().toString(16).slice(2);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className=""
    >
      <defs>
        <linearGradient id={id}>
          <stop offset="55%" stopColor={c1} />
          <stop offset="55%" stopColor={c2} />
        </linearGradient>
      </defs>
      <path
        d="M10 0.148438L12.935 6.14144L19.5 7.10844L14.75 11.7704L15.871 18.3564L10 15.2454L4.129 18.3564L5.25 11.7704L0.5 7.10844L7.064 6.14144L10 0.148438Z"
        fill={`url(#${id})`}
        stroke="black"
        strokeWidth="1"
      />
    </svg>
  );
};

export const StarsDisplay = ({
  starRating,
  dims,
  className,
}: {
  starRating: number;
  dims: { width: string; height: string };
  className?: string;
}) => {
  const starFills = [];
  const roundedStarRating = roundToPointFive(starRating);
  const fullStars = Math.floor(roundedStarRating);
  for (let i = 1; i <= fullStars; i++) {
    starFills.push("filled");
  }
  const partialStarFill = roundedStarRating - fullStars;
  if (partialStarFill > 0) {
    starFills.push("half");
  }
  const emptyStars = 5 - starFills.length;
  for (let i = 1; i <= emptyStars; i++) {
    starFills.push("empty");
  }

  return (
    <div className={`inline-flex flex-row gap-1 ${className}`}>
      {starFills.map((fill, index) => {
        return (
          <Star
            key={index}
            variant={fill}
            height={dims.height}
            width={dims.width}
          />
        );
      })}
    </div>
  );
};

export const StarSummary = ({
  product,
  showViewAllRatings = true,
  index,
}: {
  product: Product;
  showViewAllRatings?: boolean;
  index?: number;
}) => {
  const { attributes: allAttributes } = useAttributes();
  const starRatings = getStarRatings(
    product,
    allAttributes,
    product.metadata.categoryName
  );

  if (starRatings.length < 1) {
    return null;
  }

  return (
    <>
      <div className="font-bold justify-start tracking-widest">BEST RATING</div>
      <div className="flex justify-start">
        <span className={`mr-1`}>{starRatings[0].title}:</span>
        <span className={`mx-1`}>
          <StarsDisplay
            starRating={starRatings[0].stars}
            dims={{ width: "12.63", height: "12" }}
            className="mt-[1px]"
          />
        </span>
        <span className="mx-1">{`${starRatings[0].stars.toFixed(1)}`}</span>
      </div>
      {starRatings.length > 1 && showViewAllRatings && (
        <div className="inline-flex justify-start gap-x-2 text-blue-500 font-semibold">
          <a
            href={index ? `#star-ratings-${index}` : "#star-ratings"}
            className="cursor-pointer flex items-left"
          >
            View all ratings
            <ChevronDownIcon className={`h-4 w-5 transition-all shrink-0`} />
          </a>
        </div>
      )}
    </>
  );
};

export const StarRatingsSection = ({
  product,
  outputs,
  index,
}: {
  product: Product;
  outputs: Record<PromptType, StoredLlmOutput>;
  index?: number;
}) => {
  const { attributes } = useAttributes();

  const starRatings = getStarRatings(
    product,
    attributes,
    product.metadata.categoryName
  );

  if (starRatings.length < 1) {
    return null;
  }

  return (
    <div className="mt-0">
      <StandardCard
        title={"Ratings by Category"}
        className="overflow-hidden"
        innerClassName="!p-0"
        headerClassName="bg-blue-50"
      >
        <div
          className="grid grid-cols-1 md:grid-cols-10 py-4 md:py-0 font-sans font-normal text-sm"
          id={index ? `star-ratings-${index}` : ""}
        >
          {starRatings
            .filter((attribute) => attribute.stars >= 3.0)
            .map((attribute, index) => (
              <React.Fragment key={index}>
                <div className="flex md:justify-between font-bold col-span-3 py-0 px-3 md:p-3 md:border-b mb-[-1px] md:border-gray-500">
                  <span className="align-top">{attribute.title}:</span>
                  <span className="inline-flex mx-2">
                    <StarsDisplay
                      starRating={attribute.stars}
                      dims={{ width: "12.63", height: "12" }}
                      className="mt-[3px] mx-2"
                    />
                    <span className="relative bottom-[1px]">{`${attribute.stars.toFixed(
                      1
                    )}`}</span>
                  </span>
                </div>
                <div className="text-gray-500 col-span-7 p-3 mb-[-1px] md:border-l md:border-gray-500 md:border-b">
                  {outputs[attributes[attribute.slug]?.promptType?.explain]
                    ?.result || attribute.explainer}
                </div>
              </React.Fragment>
            ))}
        </div>
      </StandardCard>
    </div>
  );
};
