import { Product } from "@lib/model/product";
import {
  centsToDollars,
  getImageUrl,
  AssetType,
  getProductAttributeAsFloat,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import ImageWithFallback from "@components/common/image-with-fallback";
import React, { useContext } from "react";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";

export const MiniProductCard = (props: {
  children?: any;
  className?: string;
  id?: string;
  product?: Product;
  productCategoryName: string;
  displayAttributes: { value: number; label: string; slug: string }[];
  onTitleClick?: () => void;
}) => {
  const { product, displayAttributes, className } = props;

  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { productVariants } = useGlobalAllProducts();

  const getPrice = () => centsToDollars(product.bestPrice);

  const getImageSrc = () =>
    getImageUrl(
      product.image,
      AssetType.ProductImage,
      {
        width: 28,
      },
      props.productCategoryName
    );

  return (
    <div
      className={`${
        className || "bg-white"
      } flex justify-between gap-2 rounded-md p-2 shadow-md`}
    >
      <div
        className={`tooltip-image flex-shrink-0 ${
          props.onTitleClick ? "cursor-pointer" : ""
        }`}
        onClick={props.onTitleClick}
      >
        <ImageWithFallback
          alt="an image of the selected product"
          src={product && getImageSrc()}
        />
      </div>
      <div className="flex-grow flex flex-col gap-2">
        <div
          className={`font-bold text-xs tooltip-heading ${
            props.onTitleClick ? "cursor-pointer" : ""
          }`}
          onClick={props.onTitleClick}
        >
          {product && getProductLabels(product).shortLabel}{" "}
          {productCategoryConfig.hasVariants &&
            productCategoryConfig.displayVariantsInTables && (
              <span className="text-gray-500">
                (
                {productVariants
                  ?.find((variant) => variant.id === product.id)
                  .variantLabel.replace(";", ",")}
                )
              </span>
            )}
        </div>
        {product && (
          <ul className="text-xs">
            {displayAttributes.map((attribute, i) => (
              <li
                key={attribute.slug}
                className={`${
                  displayAttributes.length > 1 && " list-disc list-inside"
                }`}
              >
                {attribute.label}:{" "}
                <span className={`font-bold`}>
                  {!isNaN(attribute.value) ? attribute.value : ""}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="tooltip-arrow" data-popper-arrow></div>

      <div className="flex flex-col gap-1 justify-between items-end">
        <div className="text-xs tooltip-price">
          {product.bestPrice && getPrice()}
        </div>
      </div>
    </div>
  );
};
