import FiftyFiftySection from "@components/frame/fifty-fifty-container";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { PlusIcon } from "@heroicons/react/24/outline";
import { MinusIcon } from "@heroicons/react/24/outline";
import { Product } from "@lib/model/product";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import {
  getAndroidAndAppleOptimized,
  getQualitativeWord,
  thicknessNames,
  thicknessThresholds,
  weightNames,
  weightThresholds,
} from "@lib/utilities/product-classifier";

const getContentExplanation = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  const pros = [];
  const cons = [];

  const specialWordMap = {
    tv: "TV",
    general: "general use",
    "gaming/ai": "gaming and AI work",
    "eng/design": "engineering work",
  };

  productCategory.explanationConfiguration[
    "contentExplanation"
  ].attributes.forEach((attribute) => {
    const useCaseScore = product.attributes[attribute.slug].value;
    const specialWord = Object.keys(specialWordMap).find((word) =>
      attribute.displayName.toLowerCase().includes(word)
    );
    const explanation = `${capitalizeFirstLetter(
      getQualitativeWord(useCaseScore)
        .toLowerCase()
        .replace("fair", "only fair")
    )} for ${
      specialWord
        ? attribute.displayName
            .toLowerCase()
            .replace(specialWord, specialWordMap[specialWord])
        : attribute.displayName.toLowerCase()
    }`;
    if (useCaseScore < 7.0 && attribute.slug !== "callScoreRescaled") {
      cons.push(explanation);
    } else if (useCaseScore >= 8.0) {
      pros.push(explanation);
    }
  });

  return {
    pros: pros,
    cons: cons,
  };
};

const getStandardExplanation = (
  product: Product,
  productCategory: ProductCategoryConfig,
  explanationType: string,
  displaySuffix: string
) => {
  const pros = [];
  const cons = [];

  const score =
    product.attributes[
      productCategory.explanationConfiguration[explanationType].attributes[0]
        .slug
    ].value;
  const explanation = `${capitalizeFirstLetter(
    getQualitativeWord(score).toLowerCase().replace("fair", "only fair")
  )} ${displaySuffix}`;

  if (
    parseFloat(score) === parseFloat("2.5") &&
    explanationType === "noiseCancellingExplanation"
  ) {
    cons.push(`No noise reduction`);
  } else if (score < 7.0) {
    cons.push(explanation);
  } else if (score >= 8.0) {
    pros.push(explanation);
  }

  return {
    pros: pros,
    cons: cons,
  };
};

const getComfortExplanation = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  const pros = [];
  const cons = [];

  if (product.attributes["formFactor"].value.toLowerCase() === "headphones") {
    return {
      pros: [],
      cons: [],
    };
  }

  const score =
    product.attributes[
      productCategory.explanationConfiguration["comfortExplanation"]
        .attributes[0].slug
    ].value;
  if (score < 7.0) {
    const explanation = "Ear tips may not be the most comfortable";
    cons.push(explanation);
  } else if (score >= 8.0) {
    const explanation = "Comfortable ear tips";
    pros.push(explanation);
  }
  return {
    pros: pros,
    cons: cons,
  };
};

const getPriceExplanation = (
  product: Product,
  lowerLimit: number,
  upperLimit: number
) => {
  const pros = [];
  const cons = [];

  if (product.bestPrice < lowerLimit) {
    pros.push("Attractive price");
  } else if (product.bestPrice > upperLimit) {
    cons.push("Pricier than other options");
  }
  return {
    pros: pros,
    cons: cons,
  };
};

const getFreshExplanation = (product: Product, cutoff: number) => {
  const pros = [];
  const cons = [];

  if (Number(product.attributes["yearReleased"].value) < cutoff) {
    const difference = 2023 - cutoff;
    cons.push(
      `Released more than ${difference} year${difference === 1 ? "" : "s"} ago`
    );
  }

  return {
    pros: pros,
    cons: cons,
  };
};

const getHeadphonesOsExplanation = (product: Product) => {
  const pros = [];
  const cons = [];

  if (product.attributes["wired"].value.toLowerCase() === "yes") {
    return {
      pros: [],
      cons: [],
    };
  }

  const { androidOptimized, appleOptimized } =
    getAndroidAndAppleOptimized(product);

  if (androidOptimized) {
    pros.push(`Optimized for Android`);
  } else {
    cons.push(`Not optimized for Android`);
  }

  if (appleOptimized) {
    pros.push(`Optimized for Apple`);
  } else {
    cons.push(`Not optimized for Apple`);
  }

  return {
    pros: pros,
    cons: cons,
  };
};

const getLaptopsPerformanceExplanation = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  const pros = [];
  const cons = [];

  const performanceScores = productCategory.explanationConfiguration[
    "performanceExplanation"
  ].attributes.map((attribute) =>
    parseFloat(product.attributes[attribute.slug].value)
  );

  const mean =
    performanceScores.reduce((acc, val) => acc + val, 0) /
    performanceScores.length;

  const explanation = `${capitalizeFirstLetter(
    getQualitativeWord(mean).toLowerCase().replace("fair", "only fair")
  )} overall performance`;

  if (mean < 7.0) {
    cons.push(explanation);
  } else if (mean >= 8.0) {
    pros.push(explanation);
  }

  return {
    pros: pros,
    cons: cons,
  };
};

const getLaptopsPortabilityExplanation = (product: Product) => {
  const pros = [];
  const cons = [];

  const thickness = parseFloat(product?.attributes["thickness"]?.value);
  const weight = parseFloat(product?.attributes["weight"]?.value);

  if (thickness < thicknessThresholds[0] && weight < weightThresholds[0]) {
    pros.push("Ultra portable");
  } else if (
    thickness > thicknessThresholds[2] ||
    weight > weightThresholds[2]
  ) {
    cons.push("Not the most portable");
  }

  return {
    pros: pros,
    cons: cons,
  };
};

export const getProsAndCons = (
  product: Product,
  productCategory: ProductCategoryConfig
) => {
  const pros = [];
  const cons = [];

  switch (productCategory.name) {
    case "tvs": {
      const pqExplanation = getStandardExplanation(
        product,
        productCategory,
        "pictureQualityExplanation",
        "picture quality"
      );
      const tvContentExplanation = getContentExplanation(
        product,
        productCategory
      );
      const brightRoomExplanation = getStandardExplanation(
        product,
        productCategory,
        "brightRoomExplanation",
        "for bright rooms"
      );

      const tvPriceExplanation = getPriceExplanation(product, 50000, 150000);

      const tvFreshExplanation = getFreshExplanation(product, 2022);

      pros.push(
        ...pqExplanation.pros,
        ...tvContentExplanation.pros,
        ...brightRoomExplanation.pros,
        ...tvPriceExplanation.pros
      );
      cons.push(
        ...pqExplanation.cons,
        ...tvContentExplanation.cons,
        ...brightRoomExplanation.cons,
        ...tvPriceExplanation.cons,
        ...tvFreshExplanation.cons
      );
      break;
    }
    case "laptops": {
      const laptopsContentExplanation = getContentExplanation(
        product,
        productCategory
      );

      const performanceExplanation = getLaptopsPerformanceExplanation(
        product,
        productCategory
      );

      const batteryExplanation = getStandardExplanation(
        product,
        productCategory,
        "batteryExplanation",
        "battery life"
      );

      const laptopPriceExplanation = getPriceExplanation(
        product,
        50000,
        150000
      );

      const laptopFreshExplanation = getFreshExplanation(product, 2022);

      const laptopPortabilityExplanation =
        getLaptopsPortabilityExplanation(product);

      pros.push(
        ...laptopsContentExplanation.pros,
        ...performanceExplanation.pros,
        ...batteryExplanation.pros,
        ...laptopPortabilityExplanation.pros,
        ...laptopPriceExplanation.pros
      );
      cons.push(
        ...laptopsContentExplanation.cons,
        ...performanceExplanation.cons,
        ...batteryExplanation.cons,
        ...laptopPortabilityExplanation.cons,
        ...laptopPriceExplanation.cons,
        ...laptopFreshExplanation.cons
      );

      break;
    }
    case "smartphones": {
      const cameraExplanation = getStandardExplanation(
        product,
        productCategory,
        "cameraQualityExplanation",
        "camera"
      );

      const batteryExplanation = getStandardExplanation(
        product,
        productCategory,
        "batteryRatingExplanation",
        "battery life"
      );

      const phonePriceExplanation = getPriceExplanation(product, 60000, 80000);
      const phoneFreshExplanation = getFreshExplanation(product, 2022);

      pros.push(
        ...cameraExplanation.pros,
        ...batteryExplanation.pros,
        ...phonePriceExplanation.pros
      );
      cons.push(
        ...cameraExplanation.cons,
        ...batteryExplanation.cons,
        ...phonePriceExplanation.cons,
        ...phoneFreshExplanation.cons
      );
      break;
    }
    case "headphones": {
      const headphoneContentExplanation = getContentExplanation(
        product,
        productCategory
      );

      const ncExplanation = getStandardExplanation(
        product,
        productCategory,
        "noiseCancellingExplanation",
        "noise reduction"
      );

      const comfortExplanation = getComfortExplanation(
        product,
        productCategory
      );

      const headphonesOsExplanation = getHeadphonesOsExplanation(product);

      pros.push(
        ...headphoneContentExplanation.pros,
        ...ncExplanation.pros,
        ...comfortExplanation.pros,
        ...headphonesOsExplanation.pros
      );
      cons.push(
        ...headphoneContentExplanation.cons,
        ...ncExplanation.cons,
        ...comfortExplanation.cons,
        ...headphonesOsExplanation.cons
      );

      break;
    }
    case "tablets": {
      break;
    }
    case "monitors": {
      break;
    }
  }

  if (cons.length === 0) {
    cons.push("May not fit your budget");
  }

  return {
    pros: pros,
    cons: cons,
  };
};

const ProsAndCons = ({
  product,
  productCategory,
  prosAndCons,
  className = "",
}: {
  product: Product;
  productCategory: ProductCategoryConfig;
  prosAndCons: { pros: string[]; cons: string[] };
  className?: string;
}) => {
  return (
    <FiftyFiftySection className="!gap-x-6">
      <StandardCard
        className="mb-4 mt-2 font-sans font-normal rounded-lg overflow-hidden border-green-500"
        innerClassName="!p-0"
        headerClassName="border-green-500"
      >
        <div className="py-3 pl-3 font-sans font-semibold text-base border-b border-green-500 bg-green-100">
          Pros
        </div>
        <div className="px-4 sm:pr-2 py-3">
          <ul>
            {prosAndCons.pros.slice(0, 5).map((pro, index) => (
              <li key={index} className="py-1">
                <span className="inline-flex items-start">
                  <PlusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-green-500 stroke-[3px]" />
                  <span>{capitalizeFirstLetter(pro)}</span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </StandardCard>
      <StandardCard
        className="mb-4 mt-2 font-sans font-normal rounded-lg overflow-hidden border-red-500"
        innerClassName="!p-0"
      >
        <div className="py-3 pl-3 font-sans font-semibold text-base border-b border-red-500 bg-red-100">
          Cons
        </div>
        <div className="px-4 mt-0 py-3 sm:pr-2">
          <ul>
            {prosAndCons.cons.slice(0, 4).map((con, index) => (
              <li key={index} className="py-1">
                <span className="inline-flex items-start">
                  <MinusIcon className="relative top-[6px] h-3 w-3 ml-1 mr-2 text-red-500 stroke-[3px]" />
                  <span>{capitalizeFirstLetter(con)}</span>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </StandardCard>
    </FiftyFiftySection>
  );
};

export default ProsAndCons;
