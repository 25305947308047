import { getIconColorClass } from "@components/recommendation/done-panel";
import { useEffect, useLayoutEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

const ArcScoreMeter = (props: {
  percent: number;
  color: string;
  className?: string;
  fill?: string;
  opacityClass?: string;
  index?: number;
  width?: string;
}) => {
  const {
    percent,
    fill = "fill-none",
    opacityClass = "opacity-90",
    color,
    width = "w-[90px]",
  } = props;

  const dotRef = useRef(null);
  const drawPathRef = useRef(null);

  const adjustedPercent = isNaN(percent) ? 0 : percent - 0.06;

  const { arcStrokeColor, circleFillColor, circleStrokeColor } =
    getIconColorClass(color);

  useEffect(() => {
    if (dotRef.current && drawPathRef.current) {
      const totalL = drawPathRef.current.getTotalLength();
      const endL = adjustedPercent * totalL;

      const p = drawPathRef.current.getPointAtLength(endL);

      dotRef.current.setAttribute("cx", `${p.x}`);
      dotRef.current.setAttribute("cy", `${p.y}`);

      drawPathRef.current.setAttribute("stroke-dashoffset", totalL - endL);
      drawPathRef.current.setAttribute("stroke-dasharray", totalL);

      drawPathRef.current?.classList.remove("hidden");
      !isNaN(percent) && dotRef.current?.classList.remove("hidden");
    }
  }, [adjustedPercent, dotRef, drawPathRef]);

  const arcStartCoords = [7, 46];
  const d = `M ${arcStartCoords.join(" ")} A28 28 0 1 1 58 46`;

  // Since there are usually three of these on a screen, we need unique ids.
  const clipPathId = `confidence-number-clip-${uuidv4()}`;

  return (
    <div className={`${width} ${props.className || ""}`}>
      <svg viewBox="0 0 65 45">
        {/* The background semicircle. */}
        <path
          d={d}
          className={`stroke-none fill-none opacity-0 origin-center`}
        />

        {/* The white stroke path. */}
        <path
          d={d}
          className={`stroke-[3.5px] fill-none stroke-gray-300 ${fill} ${opacityClass} origin-center`}
          fill="none"
        />

        {/* The blue stroke path. */}
        <path
          ref={drawPathRef}
          d={d}
          strokeDashoffset={0}
          className={`${arcStrokeColor} stroke-[3.5px] draw-path hidden`}
          fill="none"
        />

        {/* The dot at the end. */}
        <circle
          ref={dotRef}
          r="5.5"
          cx="0"
          cy="0"
          className={`${circleFillColor} stroke-[2px] ${circleStrokeColor} confidence-cap hidden`}
          //transform={`translate(${arcStartCoords[0]}, ${arcStartCoords[1]})`}
        />

        {/* Here, we display a scroll of five numbers before the target, and
         then the target, and then we apply animation styles to create a "scroll
         past" effect. */}
        <defs>
          <clipPath id={clipPathId}>
            <rect y="16" x="27" width="40" height="20" />
          </clipPath>
        </defs>
        <g clipPath={`url(#${clipPathId})`}></g>
      </svg>
    </div>
  );
};

export default ArcScoreMeter;
