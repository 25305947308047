import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import { useQuestions } from "./use-questions";

export interface Filter {
  questionid: string;
  answerId: string;
  name: string;
}

const useFilters = (
  productCategoryConfig: ProductCategoryConfig,
  responseSet: ResponseSetWithResponses
): Filter[] => {
  const { questions } = useQuestions();
  if (!productCategoryConfig.filterQuestions || !questions || !responseSet) {
    return [];
  }

  const chosenFilters = [];

  productCategoryConfig.filterQuestions.forEach((filterQuestionid) => {
    const filterResponse = responseSet.responses.find(
      (response) => response.questionId === filterQuestionid
    );

    const filterQuestion = questions.find(
      (question) => question.id === filterQuestionid
    );

    if (filterResponse && filterQuestion && filterResponse.answerIds) {
      filterResponse.answerIds.forEach((filterAnswerId) => {
        chosenFilters.push({
          questionId: filterQuestionid,
          answerId: filterAnswerId,
          name: filterQuestion.answers.find(
            (answer) => answer.id === filterAnswerId
          )?.mainText,
        });
      });
    }
  });

  return chosenFilters;
};

export default useFilters;
