import React, { useContext } from "react";
import {
  ChartPropsStandard,
  PanelLayout,
  PanelSetProps,
  useLimitedChartProducts,
} from "@components/product/summary/common";
import {
  getUsage,
  laptopContentPhraseMap,
} from "@components/product/summary/laptop-usage-meter";
import LaptopPortability from "@components/product/summary/laptop-portability";
import useMultiResponse from "@lib/hooks/use-multi-response";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import LaptopUsageMeter from "@components/product/summary/laptop-usage-meter";
import LaptopBatteryMeter from "@components/product/summary/laptop-battery-meter";
import MultiOrdChart, {
  SampledMultiOrdChart,
} from "@components/product/summary/multi-ord-chart";
import ComparePanel from "@components/product/summary/compare-panel";
import { StandardCard } from "../done-page-fixtures";
import BudgetMeter from "@components/product/summary/budget-meter";
import { ClassifiedAttributes } from "@server/storage/config/attribute";
import FiltersSummary from "../filters-summary";
import LaptopPerformanceMeter from "@components/product/summary/laptop-performance-meter";
import LaptopDisplayMeter from "@components/product/summary/laptop-display-meter";
import LaptopBuildQuality from "@components/product/summary/laptop-build-quality";

/**
 * Meets-needs panels for laptops.
 */
const LaptopAttributeDisplay = (
  props: PanelSetProps & { feedbackPanel: JSX.Element }
) => {
  const { activeResponseSet } = useContext(RecUIContext);

  const multiResponse = useMultiResponse(
    "762cfcaf-5d78-426c-bd82-8d568b1f531e",
    activeResponseSet
  );

  const usagesChosen = Object.keys(laptopContentPhraseMap).filter(
    (attribute) => {
      return getUsage(attribute, multiResponse);
    }
  );

  return (
    // The repeated conditionals here are because we can't use a fragment due to
    // the way PanelLayout uses the children array.
    <PanelLayout>
      {props.selectedProduct &&
        usagesChosen.map((usageAnswerName) => (
          <LaptopUsageMeter
            key={usageAnswerName}
            {...props}
            scoreType={usageAnswerName}
          />
        ))}
      {props.selectedProduct && (
        <LaptopPerformanceMeter {...props} usagesChosen={usagesChosen} />
      )}
      {props.selectedProduct && (
        <LaptopDisplayMeter {...props} usagesChosen={usagesChosen} />
      )}
      {props.selectedProduct && <LaptopBatteryMeter {...props} />}
      {props.selectedProduct && <LaptopPortability {...props} />}
      {props.selectedProduct && <LaptopBuildQuality {...props} />}
      {props.selectedProduct && (
        <BudgetMeter
          product={props.selectedProduct}
          budgetQuestionId="374b21c8-c17b-412e-aa6b-9b4bbf9ee5dd"
        />
      )}
      {props.selectedProduct && (
        <FiltersSummary meetsNeeds={props.meetsNeeds} />
      )}
      {props.feedbackPanel}
    </PanelLayout>
  );
};

/**
 * Comparison panels for laptops.
 */
export const LaptopComparisonPanels = (props: PanelSetProps) => {
  const {
    selectedProduct,
    consideredProducts,
    recommendedProducts,
    productCategoryConfig,
    pageType,
  } = props;

  const plottedProducts = useLimitedChartProducts(
    consideredProducts,
    selectedProduct
  );

  const { activeResponseSet } = useContext(RecUIContext);

  let bestAttributeOptions = [
    "gamingScore",
    "creativeUseScore",
    "generalUseScore",
    "softwareScore",
    "contentScore",
    "batteryLifeHours",
    "generalUseBenchmarkScore",
    "gamingBenchmarkScore",
    "creativeUseBenchmarkScore",
    "softwareBenchmarkScore",
    "contentBenchmarkScore",
    "generalUseScreenScore",
    "gamingScreenScore",
    "creativeUseScreenScore",
    "contentScreenScore",
    "softwareScreenScore",
    "buildQuality",
  ];

  const useCaseAnswers = useMultiResponse(
    "762cfcaf-5d78-426c-bd82-8d568b1f531e",
    activeResponseSet
  ).map(
    (response) =>
      ClassifiedAttributes.answerToAttributeHack.laptops[response.answerId]
  );

  bestAttributeOptions = bestAttributeOptions.sort((attA, attB) => {
    const indexA = useCaseAnswers.indexOf(attA);
    const indexB = useCaseAnswers.indexOf(attB);

    if (indexA === -1 && indexB === -1) {
      // If both elements are not in "multiResponse", keep their original order
      return 0;
    } else if (indexA === -1) {
      // If only "b" is in "multiResponse", move "a" after "b"
      return 1;
    } else if (indexB === -1) {
      // If only "a" is in "multiResponse", move "a" before "b"
      return -1;
    } else {
      // If both elements are in "multiResponse", sort based on their index in "multiResponse"
      return indexA - indexB;
    }
  });

  return (
    <>
      <StandardCard className="bg-panel-blue-3">
        <MultiOrdChart
          id="laptops-multichart"
          defaultAttribute={bestAttributeOptions[0]}
          attributeOptions={bestAttributeOptions}
          defaultProduct={selectedProduct}
          plottedProducts={plottedProducts}
          rankedProducts={recommendedProducts}
          pageType={pageType}
        />
      </StandardCard>
      <ComparePanel
        compareProducts={recommendedProducts}
        categoryName={productCategoryConfig.name}
      />
    </>
  );
};

/**
 * VS/landing-page chart.
 */
export const LaptopChartsStandard = (props: ChartPropsStandard) => {
  const {
    selectedProductIndex,
    compareProducts,
    allProducts,
    getRank,
    pageType,
  } = props;

  const selectedProduct = selectedProductIndex
    ? compareProducts[props.selectedProductIndex]
    : compareProducts[0];

  let bestDefaultAttribute;
  let bestAttributeOptions;

  const commonAttributes = [
    "generalUseBenchmarkScore",
    "gamingBenchmarkScore",
    "creativeUseBenchmarkScore",
    "generalUseScreenScore",
    "gamingScreenScore",
    "creativeUseScreenScore",
    "buildQuality",
  ];

  if (selectedProduct.attributes["gamingScore"].value > 7) {
    bestDefaultAttribute = "gamingScore";
    bestAttributeOptions = [
      "gamingScore",
      "batteryLifeHours",
      "generalUseScore",
      "creativeUseScore",
      ...commonAttributes,
    ];
  } else {
    bestDefaultAttribute = "generalUseScore";
    bestAttributeOptions = [
      "generalUseScore",
      "gamingScore",
      "batteryLifeHours",
      "creativeUseScore",
      ...commonAttributes,
    ];
  }

  return (
    <SampledMultiOrdChart
      id="laptops-multiord-plot"
      defaultAttribute={bestDefaultAttribute}
      attributeOptions={bestAttributeOptions}
      selectedProduct={selectedProduct}
      rankResolver={getRank}
      plottableProductsPool={allProducts}
      rankedProducts={compareProducts}
      pageType={pageType}
    />
  );
};

export default LaptopAttributeDisplay;
