/**
 * This map provides the words that we use to correspond to choices on the
 * scale-choice likert scale.
 */
export const ScaleChoiceClassification = {
  MattersScale: {
    1: "Does not matter",
    2: "Matters a little",
    3: "Matters",
    4: "Matters very much",
    descriptionPrefix: "It ",
  },
  OftenScale: {
    1: "Not often",
    2: "Once in a while",
    3: "Often",
    4: "Very often",
    descriptionPrefix: "",
  },
  PriceToPerformanceScale: {
    1: "Lower price",
    2: "Low price",
    3: "High performance",
    4: "Higher performance",
    descriptionPrefix: "",
  },
  LaptopPriceToPerformanceScale: {
    1: "Lower price",
    2: "Low price",
    3: "High performance",
    4: "Max speed",
    descriptionPrefix: "",
  },
  TvPictureScale: {
    1: "I'm a casual viewer",
    2: "I care about picture quality",
    3: "I care about picture quality a lot",
    4: "I want the best picture",
    descriptionPrefix: "",
  },
  PhoneCameraScale: {
    1: "Decent everyday use",
    2: "Decent everyday use",
    3: "Camera quality matters",
    4: "Best possible camera",
    descriptionPrefix: "",
  },
  BatteryScale: {
    1: "Prioritize other things",
    2: "Not the most important thing",
    3: "battery life matters",
    4: "Longest possible",
    descriptionPrefix: "",
  },
  LaptopPortabilityScale: {
    1: "Does not matter",
    2: "Not the most important thing",
    3: "Matters",
    4: "Ultra-portable",
    descriptionPrefix: "",
  },
};

export const DonePageScaleChoiceClassification = {
  PhoneCameraScale: {
    1: "You said you wanted a decent everyday camera.",
    2: "You said you wanted a decent everyday camera.",
    3: "You said camera quality matters.",
    4: "You said you wanted the best possible camera.",
    descriptionPrefix: "",
  },
  BatteryScale: {
    1: "You said other things are more important than battery life.",
    2: "You said battery life isn’t the most important thing.",
    3: "You said battery life matters.",
    4: "You said you wanted the longest battery life.",
    descriptionPrefix: "",
  },
  LaptopPortabilityScale: {
    1: "You said portability does not matter.",
    2: "You said portability isn’t the most important.",
    3: "You said portability is important.",
    4: "You said you want an ultra-portable machine.",
    descriptionPrefix: "",
  },
  LaptopPerformanceScale: {
    1: "You said speed wasn’t most important.",
    2: "You said you only wanted decent speed.",
    3: "You said performance matters.",
    4: "You said you wanted excellent performance with heavy use.",
    descriptionPrefix: "",
  },
};
