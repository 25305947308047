import { useContext } from "react";
import { nanoid } from "nanoid";

import { RecUIContext } from "contexts/rec-ui-context-provider";
import { ProductCategoryContext } from "contexts/product-category-context";
import { ShareClient } from "@lib/fetching/share";
import { mlRequestBuilder } from "@lib/fetching/recommendations";
import { useQuestions } from "@lib/hooks/use-questions";
import { SessionContext } from "../../contexts/session-context-provider";
import useSWRImmutable from "swr/immutable";
import { getServerUrl } from "@lib/utilities/client-utilities";

const useShare = () => {
  const {
    topProducts,
    activeResponseSet,
    rankedProductIds,
    recNotes,
    explanations: allExplanations,
  } = useContext(RecUIContext);
  const { session } = useContext(SessionContext);
  const { productCategoryConfig, apiDomain } = useContext(
    ProductCategoryContext
  );
  const shareClient = ShareClient({ apiDomain });
  const { questions } = useQuestions();

  const generateSharePage = async () => {
    const shareId = nanoid(9);
    const modelRequest = mlRequestBuilder(
      productCategoryConfig.name,
      productCategoryConfig.mlEndpoint,
      questions
    ).build(activeResponseSet.responses);

    const shareResult = await shareClient.put(shareId, {
      id: shareId,
      metadata: {
        createdAt: new Date().toISOString(),
        sessionId: session?.id,
        //@ts-ignore
        mlEndpoint: productCategoryConfig.mlEndpoint,
        categoryName: productCategoryConfig.name,
      },
      modelRequest,
      modelResponse: {
        // This is one of the few places we keep the old recommendation structure,
        // to avoid breaking old shares.
        recommendations: topProducts.map((product, i) => ({
          product,
          explanations: allExplanations[i],
        })),
        overall_explanation: "",
        rankedProductIds,
        recNotes,
      },
    });

    return { shareResult, shareId };
  };

  const {
    data,
    mutate: createShare,
    isLoading,
    error,
  } = useSWRImmutable(
    {
      baseKey: "create-share",
      topProducts,
      activeResponseSet,
      responses: activeResponseSet?.responses,
    },
    generateSharePage
  );

  const shareURL = data?.shareId
    ? `${getServerUrl()}/s/${data.shareId}`
    : undefined;

  return {
    shareData: data?.shareResult,
    shareId: data?.shareId,
    shareURL,
    isLoading,
    error,
    createShare,
  };
};

export default useShare;
