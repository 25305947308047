import { Product } from "@lib/model/product";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { AttributeConfiguration } from "../../../data/packages/model/src/productAttribute";

//This needs to be in a TSX file because I need to use <strong/> however this cannot go into the compare-fns.ts since that does not support jsx
export const getFirst = (
  attribute: AttributeConfiguration,
  productA: Product,
  productB: Product,
  pctA: number,
  pctB: number,
  tie?: boolean,
  useCase?: boolean
) => {
  let winner;
  let pctWinner;
  let loser;
  let pctLoser;

  if (pctA >= pctB) {
    winner = productA;
    pctWinner = pctA;
    loser = productB;
    pctLoser = pctB;
  } else {
    winner = productB;
    pctWinner = pctB;
    loser = productA;
    pctLoser = pctA;
  }

  const attrVerb =
    attribute.slug === "videoScore" || attribute.slug === "pictureScore"
      ? "takes"
      : "has";

  const adjWinner = getQualitativeWord(pctWinner * 10)
    .toLowerCase()
    .replace("fair", "only fair");
  const adjLoser = getQualitativeWord(pctLoser * 10)
    .toLowerCase()
    .replace("fair", "only fair");

  const attributeDisplayName = attribute.displayName
    .toLowerCase()
    .replace(" score", "")
    .replace("tv", "TV")
    .replace("ai", "AI");

  let winnerArticle = "";
  let loserArticle = "";

  const articleMap = {
    poor: "a",
    "only fair": "an",
    good: "a",
    "very good": "a",
    excellent: "an",
    "the best": "",
  };

  if (attributeDisplayName.toLowerCase() === "camera") {
    winnerArticle = articleMap[adjWinner];
    loserArticle = articleMap[adjLoser];
  }

  let first;

  if (adjWinner !== adjLoser && !tie) {
    first = useCase ? (
      <>
        The {getProductLabels(winner).shortLabel} is {winnerArticle}{" "}
        <strong>{adjWinner}</strong> for {attributeDisplayName}, while the{" "}
        {getProductLabels(loser).shortLabel} is {loserArticle}{" "}
        <strong>{adjLoser}</strong>.
      </>
    ) : (
      <>
        The {getProductLabels(winner).shortLabel} {attrVerb} {winnerArticle}{" "}
        <strong>{adjWinner}</strong> {attributeDisplayName}, while the{" "}
        {getProductLabels(loser).shortLabel} {attrVerb} {loserArticle}{" "}
        <strong>{adjLoser}</strong> {attributeDisplayName}.
      </>
    );
  } else if (adjWinner !== adjLoser && tie) {
    first = useCase ? (
      <>
        Although they have very similar scores, PerfectRec considers{" "}
        {getProductLabels(winner).shortLabel} to be {winnerArticle}
        <strong>{adjWinner}</strong> for {attributeDisplayName}, while the{" "}
        {getProductLabels(loser).shortLabel} is {loserArticle}{" "}
        <strong>{adjLoser}</strong>.
      </>
    ) : (
      <>
        Although they have very similar scores, PerfectRec considers{" "}
        {getProductLabels(winner).shortLabel} to have {winnerArticle}{" "}
        <strong>{adjWinner}</strong> {attributeDisplayName}, while the{" "}
        {getProductLabels(loser).shortLabel} {attrVerb} {loserArticle}{" "}
        <strong>{adjLoser}</strong> {attributeDisplayName}.
      </>
    );
  } else if (adjWinner === adjLoser && !tie) {
    first = useCase ? (
      <>
        The {getProductLabels(winner).shortLabel} and{" "}
        {getProductLabels(loser).shortLabel} are both{" "}
        <strong>{adjWinner}</strong> for {attributeDisplayName}, though the{" "}
        {getProductLabels(winner).shortLabel} is somewhat better.
      </>
    ) : (
      <>
        The {getProductLabels(winner).shortLabel} and{" "}
        {getProductLabels(loser).shortLabel} both have{" "}
        <strong>{adjWinner}</strong> {attributeDisplayName}, though the{" "}
        {getProductLabels(winner).shortLabel} {attrVerb} somewhat better{" "}
        {attributeDisplayName}.
      </>
    );
  } else if (adjWinner === adjLoser && tie) {
    first = useCase ? (
      <>
        The {getProductLabels(winner).shortLabel} and{" "}
        {getProductLabels(loser).shortLabel} are both{" "}
        <strong>{adjWinner}</strong> for {attributeDisplayName}.
      </>
    ) : (
      <>
        The {getProductLabels(winner).shortLabel} and{" "}
        {getProductLabels(loser).shortLabel} both have{" "}
        <strong>{adjWinner}</strong>{" "}
        {attributeDisplayName.replace("camera", "cameras")}.
      </>
    );
  }

  return first;
};
