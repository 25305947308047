import SingleRecNewExplanations, {
  RecExplanationStyle,
} from "@components/recommendation/single-rec-explanations-new";
import { Product } from "@lib/model/product";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { toFixedWithoutRounding } from "@lib/utilities/shared-utilities";

const keyStatConfig = {
  tvs: [
    {
      displayName: "picture quality",
      icon: "pictureQuality",
      slugs: ["screenScore"],
      type: "rating",
    },
    {
      displayName: "Movies & TV",
      icon: "content",
      slugs: ["movieScore"],
      type: "rating",
    },
    {
      displayName: "gaming",
      icon: "content",
      slugs: ["gamingScore"],
      type: "rating",
    },
    {
      displayName: "sports",
      icon: "content",
      slugs: ["sportsScore"],
      type: "rating",
    },
    {
      displayName: "cartoons & animation",
      icon: "content",
      slugs: ["animationScore"],
      type: "rating",
    },
    {
      displayName: "news & talk",
      icon: "content",
      slugs: ["newsScore"],
      type: "rating",
    },
    {
      displayName: "bright room",
      icon: "brightRoom",
      slugs: ["brightRoomScore"],
      type: "rating",
    },
  ],
  laptops: [
    {
      displayName: "general use",
      icon: "usage",
      slugs: ["generalUseScore"],
      type: "rating",
    },
    {
      displayName: "gaming/ai",
      icon: "usage",
      slugs: ["gamingScore"],
      type: "rating",
    },
    {
      displayName: "Eng/Design",
      icon: "usage",
      slugs: ["creativeUseScore"],
      type: "rating",
    },
    {
      displayName: "software dev",
      icon: "usage",
      slugs: ["softwareScore"],
      type: "rating",
    },
    {
      displayName: "content creation",
      icon: "usage",
      slugs: ["contentScore"],
      type: "rating",
    },
    {
      displayName: "Portability",
      icon: "portability",
      slugs: ["mobilityScore"],
      type: "rating",
    },
    {
      displayName: "battery",
      icon: "batteryRating",
      slugs: ["batteryRating"],
      type: "rating",
    },
    {
      displayName: "Overall Screen Quality",
      icon: "pictureQuality",
      slugs: [
        "generalUseScreenScore",
        "gamingScreenScore",
        "creativeUseScreenScore",
        "contentScreenScore",
        "softwareScreenScore",
      ],
      type: "rating",
    },
    {
      displayName: "Overall Performance",
      icon: "performance",
      slugs: [
        "generalUseBenchmarkScore",
        "gamingBenchmarkScore",
        "creativeUseBenchmarkScore",
        "contentBenchmarkScore",
        "softwareBenchmarkScore",
      ],
      type: "rating",
    },
    {
      displayName: "Size",
      icon: "laptopSize",
      slugs: ["screenSize"],
      type: "laptopSize",
    },
  ],
  smartphones: [
    {
      displayName: "camera",
      icon: "cameraQuality",
      slugs: ["cameraScore"],
      type: "rating",
    },
    {
      displayName: "size",
      icon: "size",
      slugs: ["screenSize"],
      type: "phoneSize",
    },
    {
      displayName: "battery",
      icon: "batteryRating",
      slugs: ["batteryRating"],
      type: "rating",
    },
  ],
  headphones: [
    {
      displayName: "music",
      icon: "content",
      slugs: ["musicScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "calls",
      icon: "content",
      slugs: ["callScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "gaming",
      icon: "content",
      slugs: ["gamingScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "tv",
      icon: "content",
      slugs: ["tvScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "podcasts & audiobooks",
      icon: "content",
      slugs: ["podcastScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "exercise",
      icon: "content",
      slugs: ["exerciseScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "air travel",
      icon: "content",
      slugs: ["travelScoreRescaled"],
      type: "rating",
    },
    {
      displayName: "Noise Reduction",
      icon: "speakerXMark",
      slugs: ["noiseIsolationQualityRescaled"],
      type: "rating",
    },
    {
      displayName: "Build Quality",
      icon: "cog8Tooth",
      slugs: ["buildQualityRescaled"],
      type: "rating",
    },
    {
      displayName: "battery",
      icon: "batteryRating",
      slugs: ["batteryRating"],
      type: "headphonesBattery",
    },
  ],
  tablets: [],
  monitors: [
    {
      displayName: "Casual Gaming",
      icon: "cube",
      slugs: ["casualGamingScore"],
      type: "rating",
    },
    {
      displayName: "Competitive Gaming",
      icon: "cubeTransparent",
      slugs: ["competitiveGamingScore"],
      type: "rating",
    },
    {
      displayName: "Productivity",
      icon: "document",
      slugs: ["productivityScore"],
      type: "rating",
    },
    {
      displayName: "Media Consumption",
      icon: "photo",
      slugs: ["mediaConsumptionScore"],
      type: "rating",
    },
    {
      displayName: "Resolution",
      icon: "resolution",
      slugs: ["resolutionScore"],
      type: "rating",
    },
    {
      displayName: "Brightness",
      icon: "brightness",
      slugs: ["brightnessScore"],
      type: "rating",
    },
    {
      displayName: "Contrast",
      icon: "contrast",
      slugs: ["contrastScore"],
      type: "rating",
    },
    {
      displayName: "Color Volume",
      icon: "colorVolume",
      slugs: ["colorVolumeScore"],
      type: "rating",
    },
    {
      displayName: "Refresh Rate",
      icon: "refreshRate",
      slugs: ["refreshRateScore"],
      type: "rating",
    },
    {
      displayName: "Response Time",
      icon: "responseTime",
      slugs: ["responseTimeScore"],
      type: "rating",
    },
    {
      displayName: "Text Quality",
      icon: "textQuality",
      slugs: ["textQualityScore"],
      type: "rating",
    },
  ],
};

const getKeyStats = (product: Product) => {
  const explanations = keyStatConfig[product.metadata.categoryName].map(
    (keyStat) => {
      if (keyStat.type === "rating") {
        const scores: number[] = keyStat.slugs.map((slug) =>
          parseFloat(product.attributes[slug].value)
        );
        const mean =
          Object.values(scores).reduce((acc, val) => acc + val, 0) /
          Object.values(scores).length;

        return {
          icon: keyStat.icon,
          isUnmet: mean < 7.0 ? true : false,
          name: keyStat.displayName + "Explanation",
          display: true,
          content: (
            <>
              <strong className="tracking-widest">
                {keyStat.displayName.toUpperCase()}
              </strong>
              : {getQualitativeWord(mean)}&#8212;
              {toFixedWithoutRounding(mean, 1)}/10
            </>
          ),
        };
      } else if (keyStat.type === "phoneSize") {
        const phoneSize = parseFloat(
          product.attributes[keyStat.slugs[0]].value
        );
        const phoneOs = product.attributes["operatingSystem"].value;
        let text;

        if (phoneOs === "iOS") {
          if (phoneSize < 5.0) {
            text = "Smallest iPhone";
          } else if (phoneSize >= 5.0 && phoneSize < 6.0) {
            text = "Small iPhone";
          } else if (phoneSize >= 6.0 && phoneSize < 6.5) {
            text = "Medium iPhone";
          } else {
            text = "Largest iPhone";
          }
        } else if (phoneOs === "Android") {
          if (phoneSize < 6.0) {
            text = "Smallest Android";
          } else if (phoneSize >= 6.0 && phoneSize < 6.5) {
            text = "Medium Android";
          } else if (phoneSize >= 6.5 && phoneSize < 6.75) {
            text = "Large Android";
          } else {
            text = "Largest Android";
          }
        }

        return {
          icon: keyStat.icon,
          isUnmet: false,
          name: keyStat.displayName + "Explanation",
          display: true,
          content: (
            <>
              <strong className="tracking-widest">
                {keyStat.displayName.toUpperCase()}
              </strong>
              : {text}
            </>
          ),
        };
      } else if (keyStat.type === "laptopSize") {
        const text = product.attributes[keyStat.slugs[0]].value + '"';

        return {
          icon: keyStat.icon,
          isUnmet: false,
          name: keyStat.displayName + "Explanation",
          display: true,
          content: (
            <>
              <strong className="tracking-widest">
                {keyStat.displayName.toUpperCase()}
              </strong>
              : {text}
            </>
          ),
        };
      } else if (keyStat.type === "headphonesBattery") {
        const text = product.attributes[keyStat.slugs[0]].value;

        return {
          icon: keyStat.icon,
          isUnmet: text !== "N/A" && parseFloat(text) < 7.0 ? true : false,
          name: keyStat.displayName + "Explanation",
          display: text !== "N/A" ? true : false,
          content: (
            <>
              <strong className="tracking-widest">
                {keyStat.displayName.toUpperCase()}
              </strong>
              : {getQualitativeWord(parseFloat(text))}&#8212;
              {toFixedWithoutRounding(parseFloat(text), 1)}/10
            </>
          ),
        };
      }
    }
  );

  return explanations;
};

const KeyStatsDisplay = ({
  product,
  className,
}: {
  product: Product;
  className: string;
}) => {
  const explanations = getKeyStats(product);

  return (
    <SingleRecNewExplanations
      explanations={explanations}
      variant={RecExplanationStyle.pdpChips}
      className={`my-4 ${className}`}
    />
  );
};

export default KeyStatsDisplay;
