import React, { useContext } from "react";
import Size from "@components/product/summary/size";
import {
  ChartPropsStandard,
  PanelLayout,
  PanelSetProps,
  useLimitedChartProducts,
} from "@components/product/summary/common";
import PhoneCameraMeter from "@components/product/summary/phone-camera-score";
import PhoneBatteryMeter from "@components/product/summary/phone-battery-meter";
import MultiOrdChart, {
  SampledMultiOrdChart,
} from "@components/product/summary/multi-ord-chart";
import ComparePanel from "@components/product/summary/compare-panel";
import { StandardCard } from "../done-page-fixtures";
import BudgetMeter from "@components/product/summary/budget-meter";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import { ClassifiedAttributes } from "@server/storage/config/attribute";
import FiltersSummary from "../filters-summary";
import PhonePhotoScore from "@components/product/summary/phone-photo-score";
import PhoneVideoScore from "@components/product/summary/phone-video-score";

const PhoneAttributeDisplay = (
  props: PanelSetProps & { feedbackPanel: JSX.Element }
) => {
  return (
    // The repeated conditionals here are because we can't use a fragment due to
    // the way PanelLayout uses the children array.
    <PanelLayout>
      {props.selectedProduct && <PhoneCameraMeter {...props} />}
      {props.selectedProduct && <PhonePhotoScore {...props} />}
      {props.selectedProduct && <PhoneVideoScore {...props} />}
      {props.selectedProduct && <PhoneBatteryMeter {...props} />}
      {props.selectedProduct && <Size {...props} />}
      {props.selectedProduct && (
        <BudgetMeter
          product={props.selectedProduct}
          budgetQuestionId="ebaf4b02-d071-4e80-bd67-4441c993153a"
        />
      )}
      {props.selectedProduct && (
        <FiltersSummary meetsNeeds={props.meetsNeeds} />
      )}
      {props.feedbackPanel}
    </PanelLayout>
  );
};

/**
 * Comparison panels for phones.
 */
export const PhoneComparisonPanels = ({
  selectedProduct,
  consideredProducts,
  recommendedProducts,
  productCategoryConfig,
  pageType,
}: PanelSetProps) => {
  const plottedProducts = useLimitedChartProducts(
    consideredProducts,
    selectedProduct
  );

  const { activeResponseSet } = useContext(RecUIContext);

  let bestDefaultAttribute = "cameraScore";

  const scaleChoiceResponses = activeResponseSet.responses.filter(
    (response) =>
      response.question &&
      response.question.inputType === "ScaleChoice" &&
      response.answers.length === 1
  );

  const attributeOptions = ["cameraScore", "batteryLife", "screenSize"];

  if (scaleChoiceResponses.length > 0) {
    const responseWithHighestWeight = scaleChoiceResponses.reduce(
      (prev, current) =>
        current.answers[0].weight > prev.answers[0].weight ? current : prev
    );
    bestDefaultAttribute =
      ClassifiedAttributes.questionToAttributeHack.smartphones[
        responseWithHighestWeight.questionId
      ];

    if (attributeOptions.includes(bestDefaultAttribute)) {
      attributeOptions.sort((attributeA, attributeB) => {
        if (attributeA === bestDefaultAttribute) {
          return -1;
        } else if (attributeB === bestDefaultAttribute) {
          return 1;
        } else {
          return 0;
        }
      });
    } else {
      bestDefaultAttribute = "cameraScore";
    }
  }

  return (
    <>
      <StandardCard className="bg-panel-blue-3">
        <MultiOrdChart
          id="phones-multichart"
          defaultAttribute={bestDefaultAttribute}
          attributeOptions={attributeOptions}
          defaultProduct={selectedProduct}
          plottedProducts={plottedProducts}
          rankedProducts={recommendedProducts}
          pageType={pageType}
        />
      </StandardCard>
      <ComparePanel
        compareProducts={recommendedProducts}
        categoryName={productCategoryConfig.name}
      />
    </>
  );
};

/**
 * VS/landing-page chart.
 */
export const PhoneChartsStandard = (props: ChartPropsStandard) => {
  const {
    selectedProductIndex,
    compareProducts,
    getRank,
    allProducts,
    pageType,
  } = props;

  const selectedProduct = selectedProductIndex
    ? compareProducts[selectedProductIndex]
    : compareProducts[0];

  return (
    <SampledMultiOrdChart
      id="smartphones-multiord-plot"
      defaultAttribute="batteryLife"
      attributeOptions={["batteryLife", "cameraScore", "screenSize"]}
      selectedProduct={selectedProduct}
      rankResolver={getRank}
      plottableProductsPool={allProducts}
      rankedProducts={compareProducts}
      pageType={pageType}
    />
  );
};

export default PhoneAttributeDisplay;
