import {
  ArcScoreDisplay,
  ChartPropsStandard,
  PanelLayout,
  PanelSetProps,
  SummaryTitles,
  useLimitedChartProducts,
} from "@components/product/summary/common";
import ComparePanel from "@components/product/summary/compare-panel";
import { StandardCard } from "../done-page-fixtures";
import MultiOrdChart, {
  SampledMultiOrdChart,
} from "@components/product/summary/multi-ord-chart";
import DonePanel, { getMeetsNeedsColor } from "../done-panel";
import { Product } from "@lib/model/product";
import {
  AssetType,
  getImageUrl,
  getProductLabels,
  listToCommaString,
  roundDecimal,
} from "@lib/utilities/shared-utilities";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid";
import {
  CloudIcon,
  Cog8ToothIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import useMultiResponse, {
  useResponseAnswers,
} from "@lib/hooks/use-multi-response";
import { useAttribute, useAttributes } from "@lib/hooks/use-attributes";
import Answer from "@lib/model/answer";
import { useQuestion } from "@lib/hooks/use-questions";
import BudgetMeter from "@components/product/summary/budget-meter";
import { useContext } from "react";
import { RecUIContext } from "../../../contexts/rec-ui-context-provider";
import EarIconOutline from "@components/svgs/ear-icon-outline";
import {
  getAspectIcon,
  getDisplayValue,
} from "@components/compare/compare-fns";
import {
  getAndroidAndAppleOptimized,
  getQualitativeWord,
} from "@lib/utilities/product-classifier";
import { useExplanation } from "@lib/hooks/use-explanation";
import DropletIcon from "@components/svgs/droplet";
import React from "react";
import FiltersSummary from "../filters-summary";
import ImageWithFallback from "@components/common/image-with-fallback";

const HeadphonesAttributeDisplay = (
  props: PanelSetProps & { feedbackPanel: JSX.Element }
) => {
  const { answers } = useResponseAnswers(
    "1b61cf9f-0046-4dfa-b6b2-916bfc364290"
  );

  const { selectedProduct, feedbackPanel, meetsNeeds } = props;

  return (
    // The repeated conditionals here are because we can't use a fragment due to
    // the way PanelLayout uses the children array.
    <PanelLayout>
      {selectedProduct &&
        answers &&
        answers.map((answer) => (
          <UsageMeter
            answer={answer}
            product={selectedProduct}
            key={answer.id}
          />
        ))}
      {selectedProduct && <AncCard product={selectedProduct} />}
      {selectedProduct && (
        <BudgetMeter
          product={selectedProduct}
          budgetQuestionId="0afc3803-2839-4fed-8c8e-d3e2db08d218"
        />
      )}
      {selectedProduct && <CompatibilityCard product={selectedProduct} />}
      {selectedProduct && <EarbudComfortCard product={selectedProduct} />}
      {selectedProduct && <SignatureCard product={selectedProduct} />}
      {selectedProduct && <DurabilityCard product={selectedProduct} />}
      {selectedProduct && <ConnectivityCard product={selectedProduct} />}
      {selectedProduct && <BuildQualityCard product={selectedProduct} />}
      {selectedProduct && <FiltersSummary meetsNeeds={meetsNeeds} />}
      {feedbackPanel}
    </PanelLayout>
  );
};

const defaultUsageOptions = {
  "77120855-0e90-4836-a19d-d90fed8efbb2": "musicScoreRescaled",
  "fe4108c8-eef6-4633-959b-2e7ab4ae681e": "callScoreRescaled",
  "118fe58c-d91a-42c6-b62f-62054806d850": "gamingScoreRescaled",
  "5d0db25c-17f7-417a-98b3-b162318ae6c6": "tvScoreRescaled",
  "5d3e5408-d317-4003-ad64-f94f74e3330f": "podcastScoreRescaled",
  "e4b172ef-3081-427b-bb2d-69652b359a5f": "exerciseScoreRescaled",
  "759288d6-dc1c-4382-ab38-178ae0432ab6": "travelScoreRescaled",
};

const otherScores = [
  "soundQualityRescaled",
  "buildQualityRescaled",
  "batteryLife",
  "noiseIsolationQualityRescaled",
];

/**
 * Comparison panels
 */
export const HeadphonesComparisonPanels = ({
  selectedProduct,
  consideredProducts,
  recommendedProducts,
  productCategoryConfig,
  pageType,
}: PanelSetProps) => {
  const { activeResponseSet } = useContext(RecUIContext);

  const multiResponse = useMultiResponse(
    "1b61cf9f-0046-4dfa-b6b2-916bfc364290",
    activeResponseSet
  ).map((response) => defaultUsageOptions[response.answerId]);

  let attributeOptions = [
    ...otherScores,
    ...Object.values(defaultUsageOptions),
  ];

  attributeOptions = attributeOptions.sort((a, b) => {
    const indexA = multiResponse.indexOf(a);
    const indexB = multiResponse.indexOf(b);

    if (indexA === -1 && indexB === -1) {
      // If both elements are not in "multiResponse", keep their original order
      return 0;
    } else if (indexA === -1) {
      // If only "b" is in "multiResponse", move "a" after "b"
      return 1;
    } else if (indexB === -1) {
      // If only "a" is in "multiResponse", move "a" before "b"
      return -1;
    } else {
      // If both elements are in "multiResponse", sort based on their index in "multiResponse"
      return indexA - indexB;
    }
  });

  const plottedProducts = useLimitedChartProducts(
    consideredProducts,
    selectedProduct
  );

  return (
    <>
      <StandardCard className="bg-panel-blue-3">
        <MultiOrdChart
          id="headphones-multichart"
          defaultAttribute={attributeOptions[0]}
          attributeOptions={attributeOptions}
          defaultProduct={selectedProduct}
          plottedProducts={plottedProducts}
          rankedProducts={recommendedProducts}
          pageType={pageType}
        />
      </StandardCard>
      <ComparePanel
        compareProducts={recommendedProducts}
        categoryName={productCategoryConfig.name}
      />
    </>
  );
};

/**
 * VS/landing-page chart.
 */
export const HeadphonesChartsStandard = ({
  allProducts,
  compareProducts,
  selectedProductIndex,
  getRank,
  pageType,
}: ChartPropsStandard) => {
  const selectedProduct = selectedProductIndex
    ? compareProducts[selectedProductIndex]
    : compareProducts[0];

  return (
    <SampledMultiOrdChart
      id="headphones-multiord-plot"
      defaultAttribute="soundQualityRescaled"
      attributeOptions={[...otherScores, ...Object.values(defaultUsageOptions)]}
      selectedProduct={selectedProduct}
      rankResolver={getRank}
      plottableProductsPool={allProducts}
      rankedProducts={compareProducts}
      pageType={pageType}
    />
  );
};

/**
 * ANC card.
 */
const AncCard = ({ product }: { product: Product }) => {
  const ancScore = parseFloat(
    product.attributes["noiseIsolationQualityRescaled"].value
  );
  const { answer, isSkip } = useResponseAnswers(
    "52fd2a9b-bd71-4195-bac4-3db73266d085"
  );

  const { attributes } = useAttributes();
  const answerId = answer?.id;

  const { explanation } = useExplanation("noiseCancellingExplanation", product);

  // Don't display if the user said "no" to noise canceling.
  if (answerId === "e76c8504-1699-4d50-89b3-c1e0db21500e" || !explanation) {
    return null;
  }

  const noAnc = ancScore <= 2.5;

  const title = "Noise Reduction";

  const color = getMeetsNeedsColor(!explanation.isUnmet);

  return (
    <DonePanel
      title={title}
      icon={getAspectIcon("headphones", "activeNoiseCancelling")}
      color={color}
      modal={{
        title: attributes["noiseIsolationQualityRescaled"].displayName,
        text: attributes["noiseIsolationQualityRescaled"].explanation,
      }}
    >
      {!noAnc && (
        <ArcScoreDisplay
          product={product}
          attribute={attributes["noiseIsolationQualityRescaled"]}
          score={ancScore}
          icon={getAspectIcon("headphones", "noiseIsolationQualityRescaled")}
          color={color}
        />
      )}
      <SummaryTitles
        first={
          <>
            The {getProductLabels(product).shortLabel} has{" "}
            <strong>
              {noAnc
                ? "no"
                : getQualitativeWord(ancScore)
                    .toLowerCase()
                    .replace("best", "the best")
                    .replace("fair", "only fair")}
            </strong>{" "}
            noise reduction.
          </>
        }
        second={!isSkip && "You said you wanted noise reduction"}
      />
    </DonePanel>
  );
};

/**
 * Build quality card.
 */
const BuildQualityCard = ({ product }: { product: Product }) => {
  const buildQualityScore = parseFloat(
    product.attributes["buildQualityRescaled"].value
  );
  const { attributes } = useAttributes();
  const title = `Build Quality`;
  const first = (
    <>
      The {getProductLabels(product).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(buildQualityScore)
          .toLowerCase()
          .replace("fair", "only fair")
          .replace("best", "the best")}
      </strong>{" "}
      build quality.
    </>
  );
  const color = getMeetsNeedsColor(buildQualityScore >= 7);

  return (
    <DonePanel title={title} color={color}>
      <ArcScoreDisplay
        product={product}
        attribute={attributes["buildQualityRescaled"]}
        icon={getAspectIcon("headphones", "buildQualityRescaled")}
        color={color}
        score={buildQualityScore}
      />
      <SummaryTitles
        first={first}
        second="Better build quality means nicer materials and more resistance to normal wear and tear."
        showSecondOnly={true}
      />
    </DonePanel>
  );
};

export const ticksByForm = {
  Headphones: ["0", "20", "40", "60", "80"],
  Earbuds: ["0", "3", "6", "9", "12"],
};

/**
 * Visual indication of compatibility with either Apple or Android.
 */
const CompatibilityIndicator = ({
  brand,
  supported,
}: {
  brand: "apple" | "android";
  supported: boolean;
}) => {
  return (
    <div className="flex items-center gap-2">
      <ImageWithFallback
        src={getImageUrl(`${brand}-icon.svg`, AssetType.Icon)}
        alt={`${brand} is ${supported ? "supported" : "not supported"}`}
        className="h-16"
      />
      {supported ? (
        <CheckCircleIcon className="h-8 fill-panel-green-line" />
      ) : (
        <ExclamationTriangleIcon className="h-8 fill-warning-red" />
      )}
    </div>
  );
};

/**
 * Card showing what type of device it is compatible with.
 */
const CompatibilityCard = ({ product }: { product: Product }) => {
  const { answers } = useResponseAnswers(
    "25312452-8dad-45bd-ad50-d891f22dd17e"
  );

  const question = useQuestion("25312452-8dad-45bd-ad50-d891f22dd17e");
  const { explanation } = useExplanation("osExplanation", product);

  // Do not render if skipped.
  if (!answers.length || !explanation) {
    return null;
  }

  const { shortLabel } = getProductLabels(product);
  const { androidOptimized, appleOptimized } =
    getAndroidAndAppleOptimized(product);

  let supports, needs;

  if (appleOptimized && androidOptimized) {
    supports = "both";
  } else if (appleOptimized) {
    supports = "apple";
  } else if (androidOptimized) {
    supports = "android";
  }

  const answerIds = answers.map((answer) => answer.id);
  const needsApple =
    answerIds.includes("a57a0141-2d14-4f69-b1c9-4cc129187cfc") ||
    answerIds.includes("77e832ef-537e-4919-baa1-4bd124a08ab4");
  const needsAndroid =
    answerIds.includes("64823170-0f75-421f-88cc-acedd00cd714") ||
    answerIds.includes("77e832ef-537e-4919-baa1-4bd124a08ab4");

  let needsPhrase;
  if (needsApple && needsAndroid) {
    needs = "both";
    needsPhrase = "both Apple and Android devices";
  } else if (needsApple) {
    needs = "apple";
    needsPhrase = "Apple devices";
  } else if (needsAndroid) {
    needs = "android";
    needsPhrase = "Android devices";
  }

  // Don't show this card if it isn't relevant.
  if (!supports) {
    return null;
  }

  // Needs > Supports. @TODO now that we don't have a phrase here, there's
  // probably a way to make this simpler.
  const phraseMatrix = {
    both: {
      both: {
        visual: {
          apple: true,
          android: true,
        },
      },
      apple: {
        visual: {
          apple: true,
          android: false,
        },
      },
      android: {
        visual: {
          apple: false,
          android: true,
        },
      },
    },
    apple: {
      both: {
        visual: {
          apple: true,
          android: true,
        },
      },
      apple: {
        visual: {
          apple: true,
        },
      },
      android: {
        visual: {
          apple: false,
        },
      },
    },
    android: {
      both: {
        visual: {
          apple: true,
          android: true,
        },
      },
      apple: {
        visual: {
          android: false,
        },
      },
      android: {
        visual: {
          android: true,
        },
      },
    },
  };

  if (typeof explanation.content !== "string") {
    return null;
  }

  return (
    <DonePanel
      title={`Compatibility`}
      icon={Cog8ToothIcon}
      color={getMeetsNeedsColor(!explanation.isUnmet)}
      modal={{
        title: question.shortName || question.mainText,
        jsxText: (
          <>
            <p>
              Optimized for <strong>Apple</strong> means that the product
              includes an iOS app with an equalizer and control customization,
              and that it supports Apple`&apos;s AAC codec which might provide a
              benefit in sound quality when paired with Apple devices.
            </p>
            <p>
              Optimized for <strong>Android</strong> means that the product
              includes an Android app that provides an equalizer and control
              customization.
            </p>
          </>
        ),
      }}
    >
      <SummaryTitles
        first={
          <>
            The <strong>{shortLabel}</strong> are{" "}
            {explanation.content.charAt(0).toLowerCase() +
              explanation.content.slice(1)}
            .
          </>
        }
        second={`You said you will use ${needsPhrase}`}
      />
      <div className="flex justify-center my-4 gap-8">
        {phraseMatrix[needs][supports].visual.apple !== undefined && (
          <CompatibilityIndicator
            brand="apple"
            supported={phraseMatrix[needs][supports].visual.apple}
          />
        )}
        {phraseMatrix[needs][supports].visual.android !== undefined && (
          <CompatibilityIndicator
            brand="android"
            supported={phraseMatrix[needs][supports].visual.android}
          />
        )}
      </div>
    </DonePanel>
  );
};

/**
 * Durability/waterproof summary
 */
const DurabilityCard = ({ product }: { product: Product }) => {
  const waterproofNumber = parseInt(
    product.attributes["waterproofRating"].value
  );
  const { shortLabel } = getProductLabels(product);
  const { attributes } = useAttributes();

  const modal = {
    title: `Waterproofing`,
    text: `${attributes["waterproofRating"].explanation}`,
  };

  let waterproofPhrase;
  switch (waterproofNumber) {
    case 4:
      waterproofPhrase =
        "IP4 certified waterproofing, which should protect it against sweat and splashed water, but not full submersion.";
      break;
    case 5:
      waterproofPhrase =
        "IP5 certified waterproofing, which should protect it against sweat and gentle running water, but not full submersion.";
      break;
    case 7:
      waterproofPhrase =
        "IP7 certified waterproofing, which should protect it against sweat and submersion in shallow water for up to 30 minutes.";
      break;
    case 8:
      waterproofPhrase =
        "IP8 certified waterproofing, which should protect it against sweat and submersion in water for up to 30 minutes.";
      break;
  }

  if (!waterproofPhrase) {
    return null;
  }

  return (
    <DonePanel
      title={`Waterproofing`}
      modal={modal}
      color={getMeetsNeedsColor(true)}
      icon={DropletIcon}
    >
      <SummaryTitles
        first={
          <>
            The <strong>{shortLabel}</strong> has {waterproofPhrase}
          </>
        }
      />
    </DonePanel>
  );
};

/**
 * The set of cards for usage.
 */
export const UsageCards = ({ product }: { product: Product }) => {
  const { answers } = useResponseAnswers(
    "1b61cf9f-0046-4dfa-b6b2-916bfc364290"
  );

  return (
    <>
      {answers.map((answer) => (
        <UsageMeter answer={answer} product={product} key={answer.id} />
      ))}
    </>
  );
};

/**
 * A card for a single usage meter.
 */
const UsageMeter = ({
  answer,
  product,
}: {
  answer: Answer;
  product: Product;
}) => {
  const { attributes } = useAttributes();
  const {
    config: { threshold },
  } = useExplanation("contentExplanation", product);

  // A map of usage answer ID to the relevant attribute score.
  const attributeMap = {
    "77120855-0e90-4836-a19d-d90fed8efbb2": {
      attribute: "musicScoreRescaled",
    },
    "fe4108c8-eef6-4633-959b-2e7ab4ae681e": {
      attribute: "callScoreRescaled",
    },
    "118fe58c-d91a-42c6-b62f-62054806d850": {
      attribute: "gamingScoreRescaled",
    },
    "5d0db25c-17f7-417a-98b3-b162318ae6c6": {
      attribute: "tvScoreRescaled",
    },
    "5d3e5408-d317-4003-ad64-f94f74e3330f": {
      attribute: "podcastScoreRescaled",
    },
    "e4b172ef-3081-427b-bb2d-69652b359a5f": {
      attribute: "exerciseScoreRescaled",
    },
    "759288d6-dc1c-4382-ab38-178ae0432ab6": {
      attribute: "travelScoreRescaled",
    },
  };
  const attribute = attributeMap[answer.id].attribute;
  const score = product.attributes[attribute]?.value;
  if (!score || score === "N/A") {
    return null;
  }

  const title = answer.mainText.toLowerCase().replace("tv", "TV");

  const first = (
    <>
      The {getProductLabels(product).shortLabel} is{" "}
      <strong>
        {getQualitativeWord(score)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      for {title}.
    </>
  );

  const second = `You said you wanted a product for ${answer.mainText
    .toLowerCase()
    .replace("tv", "TV")}`;

  const color = getMeetsNeedsColor(parseFloat(score) > threshold);

  return (
    <DonePanel
      icon={getAspectIcon("headphones", attribute)}
      title={title}
      color={color}
      modal={{
        title: attributes[attribute].displayName,
        text: attributes[attribute].explanation,
      }}
    >
      <ArcScoreDisplay
        product={product}
        attribute={attributes[attribute]}
        icon={getAspectIcon("headphones", attribute)}
        score={parseFloat(score)}
        color={color}
      />
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

/**
 * Card showing info about comfort fit.
 */
const EarbudComfortCard = ({ product }: { product: Product }) => {
  const { answer } = useResponseAnswers("2226afa5-58b3-4b76-bb46-1f131e862f5c");

  const { activeResponseSet } = useContext(RecUIContext);
  const comfortAttribute = useAttribute("comfortScoreRescaled");
  const comfortWord = getQualitativeWord(
    product.attributes["comfortScoreRescaled"]?.value
  );

  const smallEarWord = getQualitativeWord(
    product.attributes["smallEarFitRescaled"]?.value
  );

  const { explanation } = useExplanation("comfortExplanation", product);
  const question = useQuestion("2226afa5-58b3-4b76-bb46-1f131e862f5c");

  const score = parseFloat(
    product.attributes["noiseIsolationQualityRescaled"].value
  );

  const color = getMeetsNeedsColor(!explanation.isUnmet);

  if (
    answer?.id !== "2b397f3c-84b3-403b-9ffe-bff3689daa29" ||
    !activeResponseSet ||
    !explanation ||
    !comfortWord ||
    !smallEarWord
  ) {
    return null;
  }

  const productLabel = getProductLabels(product).shortLabel;

  let firstHeading = `The ${productLabel} offers ${comfortWord.toLowerCase()} comfort`;
  if (smallEarWord !== "N/A") {
    firstHeading += `, and we consider them ${smallEarWord.toLowerCase()} when earbuds sometimes feel too big.`;
  } else {
    firstHeading += ".";
  }

  return (
    <DonePanel
      title="Comfort fit"
      icon={EarIconOutline}
      color={getMeetsNeedsColor(!explanation.isUnmet)}
      modal={{
        title: "Ear size",
        text: question.explanation,
      }}
    >
      <ArcScoreDisplay
        product={product}
        attribute={comfortAttribute}
        icon={CloudIcon}
        score={score}
        color={color}
      />
      {/* If this is showing, we know they said yes. */}
      <SummaryTitles first={firstHeading} />
    </DonePanel>
  );
};

/**
 * Sounds signature card.
 */
const SignatureCard = ({ product }: { product: Product }) => {
  const { answers } = useResponseAnswers(
    "a8e05cf5-91b7-41cf-a7c3-6486e35682e1"
  );

  const question = useQuestion("a8e05cf5-91b7-41cf-a7c3-6486e35682e1");
  const { explanation } = useExplanation("signatureExplanation", product);

  // Do not show if skipped.
  if (answers.length === 0 || !explanation) {
    return null;
  }

  const soundProfile = product.attributes["soundProfile"].value;
  const soundProfileNeeds = answers.map((answer) =>
    answer.mainText.toLocaleLowerCase()
  );

  return (
    <DonePanel
      title="Sound Profile"
      icon={getAspectIcon("headphones", "soundProfile")}
      color={getMeetsNeedsColor(!explanation.isUnmet)}
      modal={{
        title: question.shortName || question.mainText,
        text: question.explanation,
      }}
    >
      <SummaryTitles
        first={
          <>
            The {getProductLabels(product).shortLabel} has a{" "}
            {soundProfile.toLowerCase()} sound profile
          </>
        }
        second={`You said you wanted a ${listToCommaString(
          soundProfileNeeds
        )} sound profile.`}
      />
    </DonePanel>
  );
};

const ConnectivityCard = ({ product }: { product: Product }) => {
  const attribute = useAttribute("connectivity");

  const { answer, isSkip } = useResponseAnswers(
    "a94e1840-e4fc-47f4-a839-89f3cc5aef74"
  );
  const question = useQuestion("a94e1840-e4fc-47f4-a839-89f3cc5aef74");

  if (!attribute || !question || isSkip) {
    return null;
  }

  const title = getDisplayValue(attribute, product);
  const needsWired = answer.id === "61b31fe3-c3a5-4126-ae45-9ad91aa14ae2";
  const needsWireless = answer.id === "c16562ba-c20e-43ff-b132-a6b33b75f5d7";

  const notWired = product.attributes?.["wired"]?.value !== "Yes";
  const notWireless = product.attributes?.["wireless"]?.value !== "Yes";

  const cableLength = product.attributes?.["cableLength"]?.value;
  const replaceable =
    product.attributes?.["replaceableWire"]?.value === "TRUE"
      ? "replaceable"
      : "non-replaceable";
  const productName = getProductLabels(product).shortLabel;

  return (
    <DonePanel
      title={title}
      icon={LinkIcon}
      color={getMeetsNeedsColor(
        !(needsWired && notWired) && !(needsWireless && notWireless)
      )}
      modal={{
        title: question.shortName || question.mainText,
        text: question.explanation,
      }}
    >
      <ul>
        {cableLength !== "N/A" && (
          <li>
            The {productName} has a {roundDecimal(parseFloat(cableLength))}m{" "}
            {replaceable} wire.
          </li>
        )}
        {!notWireless && (
          <li>The {productName} uses Bluetooth for wireless audio.</li>
        )}
      </ul>
    </DonePanel>
  );
};

export default HeadphonesAttributeDisplay;
