import {
  SingleRecExplanationIcons,
  SingleRecExplanationIconColors,
} from "@lib/constants/rec-explanation-icons";
import {
  ArrowsPointingOutIcon,
  Battery100Icon,
  BoltIcon,
  Cog8ToothIcon,
  PaintBrushIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/solid";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import { BrightRoomIcon } from "@components/svgs/bright-room-icon";
import { CameraIcon } from "@heroicons/react/24/solid";
import { ContentIcon } from "@components/svgs/content-icon";
import { ScreenSizeIcon } from "@components/svgs/screen-size-icon";
import { ArrowsPointingInIcon } from "@heroicons/react/24/solid";
import { UsageIcon } from "@components/svgs/usage-icon";
import { WeightIcon } from "@components/svgs/weight-icon";
import { PictureQualityIcon } from "@components/svgs/picture-quality-icon";
import { RecommendationExplanation } from "@lib/model/recommender-model";
import EarIcon from "@components/svgs/ear-icon";
import { getExplanations } from "@lib/utilities/product-classifier";

export enum RecExplanationStyle {
  List = "List",
  Chips = "Chips",
}

/**
 * Gets all display metadata for an explanation, based on type and color.
 */
const getExplanationDisplay = (
  explanation: RecommendationExplanation
): {
  colors: string[];
  icon: React.FC<{ className: string }>;
  applyStrokeColor: boolean;
  label: string;
} => {
  // Order is Fill > Stroke > Text. We have to write out every class name fully
  // because of Tailwind.
  const colorManifest = {
    [SingleRecExplanationIconColors.White]: {
      colors: ["fill-white", "stroke-white", "text-white"],
    },
    [SingleRecExplanationIconColors.Blue]: {
      colors: ["fill-blue-600", "stroke-blue-600", "text-blue-600"],
    },
    [SingleRecExplanationIconColors.Green]: {
      colors: [
        "fill-rec-dark-green",
        "stroke-rec-dark-green",
        "text-rec-dark-green",
      ],
    },
    [SingleRecExplanationIconColors.LightGreen]: {
      colors: [
        "fill-rec-light-green",
        "stroke-rec-light-green",
        "text-rec-light-green",
      ],
    },
    [SingleRecExplanationIconColors.Orange]: {
      colors: ["fill-rec-orange", "stroke-rec-orange", "text-rec-orange"],
    },
    [SingleRecExplanationIconColors.Red]: {
      colors: ["fill-rec-red", "stroke-rec-red", "text-rec-red"],
    },
    [SingleRecExplanationIconColors.Yellow]: {
      colors: ["fill-yellow-500", "stroke-yellow-500", "text-yellow-500"],
    },
  };

  const typeManifest = {
    [SingleRecExplanationIcons.Battery]: {
      icon: Battery100Icon,
    },
    [SingleRecExplanationIcons.Budget]: {
      icon: CurrencyDollarIcon,
    },
    [SingleRecExplanationIcons.BrightRoom]: {
      icon: BrightRoomIcon,
    },
    [SingleRecExplanationIcons.Camera]: {
      icon: CameraIcon,
    },
    [SingleRecExplanationIcons.Content]: {
      icon: ContentIcon,
    },
    [SingleRecExplanationIcons.ScreenSize]: {
      icon: ScreenSizeIcon,
    },
    [SingleRecExplanationIcons.PhoneSize]: {
      icon: ArrowsPointingInIcon,
    },
    [SingleRecExplanationIcons.TvSize]: {
      icon: ArrowsPointingInIcon,
    },
    [SingleRecExplanationIcons.PictureQuality]: {
      icon: PictureQualityIcon,
    },
    [SingleRecExplanationIcons.Usage]: {
      icon: UsageIcon,
    },
    [SingleRecExplanationIcons.Weight]: {
      icon: WeightIcon,
    },
    [SingleRecExplanationIcons.Performance]: {
      icon: BoltIcon,
    },
    [SingleRecExplanationIcons.Portability]: {
      icon: ArrowsPointingOutIcon,
    },
    [SingleRecExplanationIcons.NoiseCanceling]: {
      icon: SpeakerXMarkIcon,
      label: "Noise reduction",
    },
    [SingleRecExplanationIcons.OS]: {
      icon: Cog8ToothIcon,
      label: "OS",
    },
    [SingleRecExplanationIcons.Signature]: {
      icon: PaintBrushIcon,
    },
    [SingleRecExplanationIcons.Small]: {
      icon: EarIcon,
      applyStrokeColor: true,
      label: "Comfort fit",
    },
  };

  const { explanationType, explanationIndicator } = explanation;

  return {
    ...typeManifest[explanationType],
    ...colorManifest[explanationIndicator],
  };
};

/**
 * Gets the fill and/or stroke classes
 */
const getSvgColorClasses = (explanation: RecommendationExplanation) => {
  const display = getExplanationDisplay(explanation);

  if (display.applyStrokeColor) {
    // Fill / Stroke
    return `${display.colors[0]} ${display.colors[1]}`;
  }

  // Just fill.
  return display.colors[0];
};

/**
 * Gets the label for a per-rec explanation.
 */
const getExplanationLabel = (explanation: RecommendationExplanation) => {
  // Some have a text override.
  const display = getExplanationDisplay(explanation);

  // If there is no map, default to un-camelcasing it.
  return display.label || unCamelCase(explanation.explanationType);
};

/**
 * Gets the color class for text of an explanation
 */
export const getTextColorClasses = (explanation: RecommendationExplanation) => {
  const display = getExplanationDisplay(explanation);

  return display.colors[2];
};

/**
 * This is a stopgap to make the rec slugs readable.
 */
const unCamelCase = (s) => s.replace(/([A-Z])/g, " $1").trim();

/**
 * Renders the icon for the explanation.
 */
const Icon = ({ explanation }: { explanation: RecommendationExplanation }) => {
  const display = getExplanationDisplay(explanation);
  const Icon = display.icon;

  return (
    <Icon
      className={`h-4 flex shrink-0 mr-1 ${getSvgColorClasses(explanation)}`}
    />
  );
};

const SingleRecExplanations = (props: {
  explanations: RecommendationExplanation[];
  variant?: RecExplanationStyle;
  className?: string;
}) => {
  if (!props.explanations) {
    return null;
  }
  const explanations = props.explanations.filter(
    (exp) => exp.explanationType.toLowerCase() !== "budget"
  );
  const variant = props.variant || RecExplanationStyle.List;
  const wrapperClasses = {
    [RecExplanationStyle.List]: "flex flex-col text-left gap-1",
    [RecExplanationStyle.Chips]: "flex gap-2 justify-center flex-wrap",
  };

  const itemClasses = {
    [RecExplanationStyle.List]: "flex",
    [RecExplanationStyle.Chips]: "flex rounded-xl border border-gray-500 p-1",
  };

  return (
    <div className={`${wrapperClasses[variant]} ${props.className || ""}`}>
      {explanations.map((explanation, index) => {
        // Checks the values of the enumeration to work around TvSize being different from PhoneSize.
        if (
          !Object.values(SingleRecExplanationIcons).includes(
            explanation.explanationType as SingleRecExplanationIcons
          ) ||
          explanation?.display === false
        ) {
          return null;
        }
        return (
          <div key={index} className={itemClasses[variant]}>
            <Icon explanation={explanation} />
            <div className="text-xs font-bold leading-3">
              {getExplanationLabel(explanation)}:{" "}
              <span className="font-normal font-variant-normal text-xs">
                {explanation.explanationText}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SingleRecExplanations;
