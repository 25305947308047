import { Product } from "@lib/model/product";
import FeedbackForm from "./feedback-form";
import { usePurchaseDataMultiple } from "@lib/hooks/use-purchase-data";
import {
  centsToDollars,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { pickBestPurchaseOption } from "@lib/utilities/pick-best-purchase-option";
import { getBadRecs } from "@lib/utilities/global-utilities";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import { useRouter } from "next/router";

/**
 * Feedback form for VS and product landing pages.
 */
export const LandingPageFeedbackForm = ({
  categoryName,
  products,
  pageType,
}: {
  categoryName: string;
  products: Product[];
  pageType: "vs" | "product-landing";
}) => {
  const { purchaseData: purchaseDataList } = usePurchaseDataMultiple(products);
  const productsSummary =
    purchaseDataList && products
      ? getProductsSummary(
          products,
          categoryName,
          purchaseDataList.map((item) => item.purchaseData)
        )
      : null;

  return (
    <FeedbackForm
      formId="mZOZvB"
      ready={purchaseDataList && products}
      hiddenFields={{ categoryName, products: productsSummary, pageType }}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Feedback form for VS and product landing pages.
 */
export const LandingPageFeedbackMidWayForm = ({
  categoryName,
  products,
  pageType,
}: {
  categoryName: string;
  products: Product[];
  pageType: "vs" | "product-landing";
}) => {
  const { purchaseData: purchaseDataList } = usePurchaseDataMultiple(products);
  const productsSummary =
    purchaseDataList && products
      ? getProductsSummary(
          products,
          categoryName,
          purchaseDataList.map((item) => item.purchaseData)
        )
      : null;

  return (
    <FeedbackForm
      formId="w7x6N2"
      ready={purchaseDataList && products}
      hiddenFields={{ categoryName, products: productsSummary, pageType }}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Feedback form for the done/share page.
 */
export const DonePageFeedbackForm = ({
  products,
  modelInput,
  activeResponseSet,
  categoryName,
  hiddenFields,
}: {
  products: Product[];
  modelInput: {
    query: string[];
    filters: string[];
  };
  activeResponseSet: ResponseSetWithResponses;
  categoryName: string;
  hiddenFields: { [key: string]: string };
}) => {
  const router = useRouter();
  const { mlEndpoint } = router.query;
  const { purchaseData: purchaseDataList } = usePurchaseDataMultiple(products);

  const results =
    purchaseDataList && products
      ? getProductsSummary(
          products,
          categoryName,
          purchaseDataList.map((item) => item.purchaseData)
        )
      : null;

  const badRecState = products
    ? getBadRecs(
        modelInput,
        products,
        activeResponseSet,
        categoryName,
        typeof mlEndpoint === "string" ? mlEndpoint : undefined
      )
    : "";

  return (
    <FeedbackForm
      formId="n0Q7eQ"
      hiddenFields={{ ...hiddenFields, categoryName, results, badRecState }}
      ready={products?.length > 0}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Feedback form for the home page to capture new category suggestions
 */
export const HomePageFeedbackForm = ({
  hiddenFields,
}: {
  hiddenFields: { [key: string]: string };
}) => {
  return (
    <FeedbackForm
      formId="wkGqzr"
      hiddenFields={{ ...hiddenFields }}
      ready={true}
      height={"100%"}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Feedback form for the home page to capture new category suggestions
 */
export const LlmFeedbackForm = ({
  hiddenFields,
}: {
  hiddenFields: { [key: string]: string };
}) => {
  return (
    <FeedbackForm
      formId="wa2pAy"
      hiddenFields={{ ...hiddenFields }}
      ready={true}
      height={"100%"}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Feedback form for the home page to capture new category suggestions
 */
export const ShoppingAssistantFeedbackForm = ({
  hiddenFields,
}: {
  hiddenFields: { [key: string]: string };
}) => {
  return (
    <FeedbackForm
      formId="wbOqlo"
      hiddenFields={{ ...hiddenFields }}
      ready={true}
      height={"100%"}
      dynamicHeight={"1"}
    />
  );
};

/**
 * Gets a text summary of products and their prices.
 */
const getProductsSummary = (
  products: Product[],
  categoryName: string,
  purchaseData
) => {
  if (categoryName === "laptops")
    return products
      .map((product, index) => {
        const attributes = product.attributes;
        return `${getProductLabels(product).shortLabel} (Processor: ${
          attributes["processor"].value
        }, GPU: ${attributes["gpu"].value}, RAM: ${
          attributes["ram"].value
        } GB, Storage: ${attributes["storage"].value} GB, Screen: ${
          attributes["resolution"].value
        } ${attributes["screenSize"].value}" ${
          attributes["displayTechnology"].value
        } ${attributes["refreshRate"].value} Hz, Touch: ${
          attributes["touchscreen"].value
        }, Battery: ${attributes["batteryCapacity"].value} Wh, Price: ${
          purchaseData[index] &&
          centsToDollars(pickBestPurchaseOption(purchaseData[index])?.price)
        })`;
      })
      .join("\n\n");

  return products
    .map(
      (product, index) =>
        `${getProductLabels(product).shortLabel} (${
          purchaseData[index] &&
          centsToDollars(pickBestPurchaseOption(purchaseData[index])?.price)
        })`
    )
    .join(" | ");
};
