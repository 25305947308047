import {
  AnswerInputProps,
  getAnswerExplanation,
  trackConfirmationShown,
} from "@components/tray/answer-input";
import { CheckboxGrid, Checkbox } from "@components/form-element/checkbox";
import { addNonBreakSpace } from "@lib/utilities/client-utilities";
import { useContext, useState } from "react";
import Answer from "../../../shared/src/model/answer";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { getAnswers } from "@lib/utilities/rec-ui";
import useAnswerConfirmation from "@lib/hooks/use-answer-confirmation";
import Modal from "@components/modal/modal";
import { AnswerConfirmation } from "@lib/model/answer-confirmation";
import { QuestionIteratorContext } from "contexts/question-iterator-context-provider";

/**
 * A multiple choice checkbox input that supports a modal brand filter.
 */
const MultipleChoiceAnswerInput = (props: AnswerInputProps) => {
  const {
    currentQuestion,
    setStagedResponse,
    stagedResponse,
    animateQuestionTray,
  } = props;

  const { toggleAnswerInResponse } = useContext(QuestionIteratorContext);

  const { questionOverrides, productCategoryConfig, activeResponseSet } =
    useContext(RecUIContext);
  const answers = getAnswers(
    questionOverrides,
    currentQuestion,
    productCategoryConfig,
    activeResponseSet
  );
  const {
    getConfirmation,
    confirmActive,
    setConfirmActive,
    confirmingAnswer,
    setConfirmingAnswer,
  } = useAnswerConfirmation();

  const [currentAnswerConfirmation, setCurrentAnswerConfirmation] =
    useState<AnswerConfirmation>();

  /**
   * Toggle an answer in the staged response.
   */
  const toggleAnswer = (answer: Answer) => {
    const newStagedResponse = toggleAnswerInResponse(answer, stagedResponse);

    setStagedResponse(newStagedResponse);
  };

  /**
   * Determine whether an answer is checked.
   */
  const answerIsChecked = (answer: Answer) => {
    return stagedResponse?.answerIds
      ? !!stagedResponse?.answerIds.find(
          (stagedAnswerId) => stagedAnswerId === answer.id
        )
      : false;
  };

  // If answers have inline explations, we assume that a) they are long and b)
  // there are not a ton of answers. Thus, we tell the checkbox grid to stack in
  // a single column.
  const stacked =
    answers.filter((answer) => answer.inlineExplanation).length > 0;

  return (
    <div>
      {confirmActive && (
        <Modal
          onClose={() => {
            setConfirmActive(false);
          }}
          onSubmit={() => {
            setConfirmActive(false);
            toggleAnswer(confirmingAnswer);
            eventTracker.track(Events.AnswerConfirmationAccepted, {
              question_id: currentQuestion.id,
              question_text: currentQuestion.mainText,
              answer_id: confirmingAnswer.id,
              answer_text: confirmingAnswer.mainText,
              confirmation_string: currentAnswerConfirmation.trayContent,
              confirmation_variant: currentAnswerConfirmation.trayVariant,
            });
          }}
          closeButtonText={
            currentAnswerConfirmation.textConfig?.leftButton ?? "Cancel"
          }
          linkButtonText={
            currentAnswerConfirmation.textConfig?.rightButton ?? "Okay, got it"
          }
          isConfirmation={true}
          title={
            currentAnswerConfirmation.textConfig?.header ?? "Important Note"
          }
          modalOpen={confirmActive}
          dataTestId="multi-select-answer-confirmation"
        >
          <div className="whitespace-pre-wrap">
            {currentAnswerConfirmation.trayContent}
          </div>
        </Modal>
      )}

      <CheckboxGrid stacked={stacked}>
        {answers.map((answer, i) => {
          return (
            <Checkbox
              label={addNonBreakSpace(answer.mainText, 2, 7)}
              value={answer.id}
              explanation={getAnswerExplanation(
                productCategoryConfig.name,
                answer,
                currentQuestion.id,
                activeResponseSet
              )}
              inlineExplanation={answer.inlineExplanation}
              checked={answerIsChecked(answer)}
              disabled={props.animatingResponse}
              onChange={() => {
                eventTracker.track(Events.MultipleChoiceToggleAnswer, {
                  questionId: currentQuestion.id,
                  category: productCategoryConfig.name,
                  answerId: answer.id,
                  answerText: answer.mainText,
                  checked: !answerIsChecked(answer),
                  dynamicFilterType: "none",
                });

                const confirmAndToggleAnswer = (answer: Answer) => {
                  const confirmAnswer = getConfirmation(answer);
                  if (confirmAnswer && !answerIsChecked(answer)) {
                    setConfirmActive(true);
                    trackConfirmationShown(
                      currentQuestion,
                      answer,
                      confirmAnswer
                    );
                    setConfirmingAnswer(answer);
                    setCurrentAnswerConfirmation(confirmAnswer);
                  } else toggleAnswer(answer);
                };

                if (questionOverrides.realtimeUpdate) {
                  animateQuestionTray(
                    () => {},
                    () => {
                      confirmAndToggleAnswer(answer);
                    }
                  );
                } else {
                  confirmAndToggleAnswer(answer);
                }
              }}
              key={answer.id}
              testId={`answer-${answer.id}`}
            />
          );
        })}
      </CheckboxGrid>
    </div>
  );
};

export default MultipleChoiceAnswerInput;
