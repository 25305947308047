import { ProductCategoryContext } from "contexts/product-category-context";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import {
  debounce,
  matchAllWordsFuzzy,
  getProductLabels,
  centsToDollars,
} from "@lib/utilities/shared-utilities";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { Product } from "@lib/model/product";
import Autocomplete from "react-autocomplete";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import {
  scrollToRef,
  useWindowDimensions,
} from "@lib/utilities/client-utilities";
import {
  getProductOptionsWithVariants,
  getUnitsSuffix,
  getVariantDisplayDims,
} from "@lib/utilities/rec-ui";
import { useAttributes } from "@lib/hooks/use-attributes";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import BlankTextPlaceholder from "@components/common/blank-text-placeholder";

const getDropdownPosition = (numberOfSelectors, position) => {
  if (numberOfSelectors === 2) {
    if (position === 0) {
      return "left-0 w-[92vw]";
    } else if (position === 1) {
      return "right-0 w-[92vw]";
    } else {
      return "";
    }
  } else if (numberOfSelectors === 3) {
    if (position === 0) {
      return "left-0 w-[91vw]";
    } else if (position === 1) {
      return "-right-28 w-[91vw]";
    } else if (position === 2) {
      return "right-0 w-[91vw]";
    } else {
      return "";
    }
  } else if (numberOfSelectors === 1) {
    return "w-full";
  }
};

const getSearchDropdownPosition = (numberOfSelectors, position) => {
  if (numberOfSelectors === 2) {
    if (position === 0) {
      return "left-0 w-[70vw]";
    } else if (position === 1) {
      return "right-0 w-[70vw]";
    } else {
      return "";
    }
  } else if (numberOfSelectors === 3) {
    if (position === 0) {
      return "left-0 w-[70vw]";
    } else if (position === 1) {
      return "-right-28 w-[70vw]";
    } else if (position === 2) {
      return "right-0 w-[70vw]";
    } else {
      return "";
    }
  } else if (numberOfSelectors === 1) {
    return "w-full";
  }
};

const VariantDisplay = ({
  products,
  variantId,
  attributes,
}: {
  products: Product[];
  variantId: string;
  attributes: AttributeConfigurationMap;
}) => {
  const product = products.find((product) => product.id === variantId);
  if (!product) {
    return null;
  }
  const displayDims = getVariantDisplayDims(
    product.supportedVariants,
    product.metadata.categoryName
  ).filter((dim) => dim != "sku");

  return (
    <div className="grid grid-rows-[20px] grid-flow-col gap-x-4">
      {displayDims.map((dim, index) => {
        return (
          <React.Fragment key={index}>
            <div className="text-xs text-left text-gray-500">
              {attributes[dim].shortName?.replace(
                "Display Technology",
                "Panel"
              ) ||
                attributes[dim].displayName?.replace(
                  "Display Technology",
                  "Panel"
                )}
            </div>
            <div className="text-xs text-left">
              {product.attributes[dim].value}{" "}
              {getUnitsSuffix(
                product[attributes[dim].unit],
                product.attributes[dim]
              )}
            </div>
          </React.Fragment>
        );
      })}
      <div className="text-xs text-left row-start-2">
        From {centsToDollars(product.bestPrice)}
      </div>
    </div>
  );
};

export const VariantSelectDropdown = ({
  position,
  product,
  setSelectedProduct,
  attributes,
  numberOfSelectors,
}: {
  position: number;
  product: Product;
  setSelectedProduct: (product: Product, position: number) => void;
  attributes: AttributeConfigurationMap;
  numberOfSelectors: number;
}) => {
  const { products, productVariants: allProductVariants } =
    useGlobalAllProducts();

  if (!products || !allProductVariants) {
    return (
      <BlankTextPlaceholder
        textForSizing={
          <>
            <br />
            <br />
            <br />
          </>
        }
      />
    );
  }

  const productVariants = allProductVariants.filter(
    (variant) => variant.label === getProductLabels(product).shortLabel
  );

  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <div>
        <Menu.Button
          className={`inline-flex gap-1 w-full text-left justify-between items-center rounded-md bg-white px-2 sm:px-3 py-2 font-semibold text-xs sm:text-sm text-gray-500 ${
            productVariants.length > 1
              ? "!bg-gray-100 hover:!bg-gray-300 cursor-pointer"
              : "cursor-default"
          }`}
        >
          <div>
            {productVariants
              .find((variant) => variant.id === product.id)
              ?.variantLabel.split(", ")
              .map((dim, index) => (
                <p key={index} className="mb-0 text-xs sm:text-sm">
                  {dim.replace(";", ",")}
                </p>
              ))}
          </div>
          <ChevronDownIcon
            className={`-mr-1 min-h-[20px] min-w-[20px] h-[20px] w-[20px] text-gray-400 ${
              productVariants.length <= 1 && "invisible"
            }`}
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      {productVariants.length > 1 && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-0"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-0"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute ${getDropdownPosition(
              numberOfSelectors,
              position
            )} z-10 mt-2 sm:left-0 sm:w-full origin-top-right rounded-md bg-white shadow-lg shadow-gray-400 ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className="py-1">
              {productVariants.map((variant, index) => (
                <Menu.Item key={variant.id}>
                  <div
                    className={`px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer ${
                      index > 0 ? "border-t border-t-gray-200" : ""
                    }`}
                    onClick={() => {
                      const product = products.find(
                        (product) => product.id === variant.id
                      );
                      if (product) {
                        setSelectedProduct(product, position);
                      } else {
                        setSelectedProduct(null, position);
                      }
                    }}
                  >
                    <VariantDisplay
                      products={products}
                      variantId={variant.id}
                      attributes={attributes}
                    />
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
};

/**
 * A text/autocomplete element for selecting which products to compare.
 *
 * We rely on react-autocomplete package to handle details like a11y and key-nav.
 */
const VariantSelectInput = ({
  position,
  setSelectedProduct,
  searchEvent,
  selectEvent,
  placeholder,
  testId,
  product,
  hasVariants,
  numberOfSelectors,
}: {
  position?: number;
  setSelectedProduct: (product: Product, position: number) => void;
  selectEvent: Events;
  searchEvent: Events;
  placeholder: string;
  variantPlaceholder?: string;
  testId?: string;
  product: Product;
  hasVariants?: boolean;
  numberOfSelectors: number;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { products, productVariants } = useGlobalAllProducts();

  const trackChange = useCallback(
    debounce((productSearchText) => {
      eventTracker.track(searchEvent, {
        productCategory: productCategoryConfig.name,
        productSearchText,
      });
    }),
    []
  );

  const [renderAsInput, setRenderAsInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const ref = useRef();

  if (!products || !productVariants) {
    return <BlankTextPlaceholder textForSizing="Company ProductName Specs" />;
  }

  return (
    // The weird selector here is because react-autocomplete makes an inline-styled
    // div that we can't put classes on.
    <div
      className={`relative flex flex-col w-full gap-2
      }`}
      ref={ref}
    >
      {products && productVariants && (
        <>
          <Autocomplete
            menuStyle={{
              borderRadius: "10px",
              border: "black",
              boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
              background: "white",
              padding: "2px 0",
              position: "absolute",
              left: "0",
              top: "10",
              marginTop: "10px",
              width: "100%",
              overflow: "auto",
              maxHeight: "400px",
              zIndex: "20",
            }}
            inputProps={{
              className:
                "w-full p-2 px-3 font-semibold text-base sm:text-base text-black placeholder-black break-words",
              placeholder,
              "data-testid": testId,
            }}
            renderMenu={(props) => {
              return (
                <div
                  onBlur={() => setRenderAsInput(false)}
                  className={`absolute ${getSearchDropdownPosition(
                    numberOfSelectors,
                    position
                  )} rounded-md sm:left-0 shadow-lg text-base sm:text-base shadow-gray-300 border-[1px] border-gray-100 bg-white px-[2px] overflow-auto sm:w-full z-20 max-h-[200px] sm:max-h-[400px] mt-[10px]`}
                >
                  {props.slice(0, 50)}
                </div>
              );
            }}
            renderInput={(props) => {
              return (
                <div
                  className={`inline-flex gap-2 px-3 rounded-md items-center text-left justify-between w-full bg-gray-100 hover:bg-gray-300 ${
                    renderAsInput && "bg-gray-300"
                  }
                  }`}
                >
                  {!renderAsInput && (
                    <div
                      {...props}
                      onClick={() => {
                        setRenderAsInput(true);
                      }}
                      className={`overflow-hidden py-2 resize-none justify-center border-0 font-semibold text-xs  w-full sm:text-base cursor-pointer text-black placeholder-black rounded-md focus:outline-none sm:pr-10 bg-gray-100 hover:bg-gray-300`}
                    >
                      {props.placeholder}
                    </div>
                  )}
                  {renderAsInput && (
                    <input
                      autoFocus
                      {...props}
                      placeholder=""
                      autoComplete="true"
                      className={`overflow-hidden py-2 resize-none justify-center border-0 hover:bg-gray-300 ${
                        renderAsInput && "bg-gray-300"
                      }
                      } font-semibold text-base w-full sm:text-base text-black placeholder-black rounded-md focus:outline-none sm:pr-10`}
                    />
                  )}
                  <ChevronDownIcon
                    className={`-mr-1 min-h-[20px] min-w-[20px] h-[20px] w-[20px] text-gray-400 cursor-pointer`}
                    aria-hidden="true"
                    onClick={() => setRenderAsInput(true)}
                  />
                </div>
              );
            }}
            items={productVariants.filter(
              (product) =>
                product.defaultVariant &&
                matchAllWordsFuzzy(
                  inputValue.toLocaleLowerCase(),
                  product.label
                )
            )}
            getItemValue={(product) => product.id}
            onChange={(e) => {
              setInputValue(e.target.value);
              trackChange(e.target.value);
            }}
            value={inputValue}
            onSelect={(id) => {
              const product = products.find((product) => product.id === id);
              if (product) {
                eventTracker.track(selectEvent, {
                  productCategory: productCategoryConfig.name,
                  product: getProductLabels(product).shortLabel,
                  productId: product.id,
                });
                setSelectedProduct(product, position);
              } else {
                setSelectedProduct(null, position);
              }
            }}
            renderItem={(productVariant, isHighlighted) => (
              <div
                className={`text-left w-full font-semibold text-sm sm:text-base cursor-pointer mt-2 py-1 px-3 ${
                  isHighlighted ? "bg-blue-600 text-white" : ""
                }`}
                key={productVariant.id}
              >
                {productVariant.label}
              </div>
            )}
            onMenuVisibilityChange={(open) => {
              if (!open) {
                setRenderAsInput(false);
              }
              setInputValue("");
            }}
          />
        </>
      )}
    </div>
  );
};

export default VariantSelectInput;
