import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { ArcScoreDisplay, PanelSetProps, SummaryTitles } from "./common";
import HeatMeter, { Legend, MeterHeading } from "./heat-meter";
import { laptopContentPhraseMap } from "./laptop-usage-meter";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { useAttributes } from "@lib/hooks/use-attributes";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { getAspectIcon } from "@components/compare/compare-fns";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";

interface Props extends PanelSetProps {
  usagesChosen: string[];
}

const displayAttributeMap = {
  generalUseScore: "generalUseScreenScore",
  gamingScore: "gamingScreenScore",
  creativeUseScore: "creativeUseScreenScore",
  softwareScore: "softwareScreenScore",
  contentScore: "contentScreenScore",
};

const LaptopDisplayMeter = (props: Props) => {
  const { selectedProduct, usagesChosen } = props;

  const { attributes } = useAttributes();

  const displayScores: Record<string, number> = {};

  if (usagesChosen.length > 0) {
    usagesChosen.map((attribute) => {
      displayScores[laptopContentPhraseMap[attribute]?.titlePhrase] =
        parseFloat(
          selectedProduct.attributes[displayAttributeMap[attribute]].value
        );
    });
  } else {
    Object.keys(laptopContentPhraseMap).map((attribute) => {
      displayScores[laptopContentPhraseMap[attribute]?.titlePhrase] =
        parseFloat(
          selectedProduct.attributes[displayAttributeMap[attribute]].value
        );
    });
  }

  const mean =
    Object.values(displayScores).reduce((acc, val) => acc + val, 0) /
    Object.values(displayScores).length;

  const numberOfScores = Object.keys(displayScores).length;

  const title =
    numberOfScores !== 1
      ? `Screen Quality`
      : `${Object.keys(displayScores)[0]} Screen Quality`;
  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(mean)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      {title.toLowerCase().replace("ai", "AI")}.
    </>
  );
  const second =
    "PerfectRec’s screen quality scores take into account various important factors that are relevant for different use cases.";

  const color = getMeetsNeedsColor(mean > 7.0);
  return (
    <DonePanel
      title={title}
      color={color}
      modal={{
        title: "Screen Quality",
        text: "PerfectRec’s screen quality scores are determined differently for different use cases. The overall screen quality score is determined by taking the average of the screen quality scores for the use cases you selected. If you skipped the use case question, the overall screen quality score is based on the average of all the use case specific screen quality scores.",
      }}
    >
      <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 mb-2">
        <ArcScoreDisplay
          product={props.selectedProduct}
          attribute={attributes["overallScreenScore"]}
          icon={getAspectIcon("laptops", "overallScreenScore")}
          score={mean}
          color={color}
        />
        {numberOfScores > 1 && (
          <div className="flex flex-col justify-center ml-6 lg:ml-0">
            {Object.keys(displayScores).map((attribute, index) => (
              <div key={index} className="flex flex-row gap-8 items-center">
                <div className="w-4">
                  <ArcScoreDisplay
                    product={props.selectedProduct}
                    attribute={attributes[displayAttributeMap[attribute]]}
                    score={displayScores[attribute]}
                    color={getMeetsNeedsColor(displayScores[attribute] >= 7)}
                    type="scoreInside"
                    index={index}
                  />
                </div>
                <div className="text-sm">
                  {capitalizeFirstLetter(
                    `${attribute}`
                      .toLowerCase()
                      .replace("ai", "AI")
                      .replace("score", "")
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopDisplayMeter;
