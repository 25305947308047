import globalHook from "use-global-hook";
import * as actions from "../actions";

const initialState = {
  cache: new Map(),
};

const useGlobalAllProductsCache = globalHook<
  typeof initialState,
  { set: (key: string, value: any) => void }
>(initialState, actions);

export default useGlobalAllProductsCache;
