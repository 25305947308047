/**
 * A custom icon that shows an ear.
 */
const EarIcon = ({ className = "" }: { className?: string }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.44124 4.51867L4.39205 4.65981C4.13606 5.3944 4.09604 6.18698 4.27673 6.94362L5.56614 12.343L5.83502 13.0365C6.05563 13.6054 6.53469 14.0348 7.12429 14.192C7.47065 14.2844 7.83749 14.277 8.1801 14.1716C8.65767 14.0246 9.06145 13.697 9.30224 13.2592L9.41012 13.063C9.56498 12.7815 9.66356 12.4725 9.7004 12.1533L9.74646 11.7541C9.80857 11.2158 9.96888 10.6892 10.2159 10.2069C10.4202 9.8081 10.6836 9.43839 10.9946 9.11586L11.108 8.99828C11.3063 8.79263 11.4795 8.56417 11.624 8.31771C11.8469 7.93745 11.9984 7.51961 12.0708 7.08483L12.0927 6.95361C12.1994 6.31313 12.1609 5.65685 11.9798 5.03329L11.9329 4.8718C11.7588 4.27211 11.436 3.72615 10.9945 3.2846C10.5791 2.86919 10.0709 2.5585 9.51176 2.37815L9.33788 2.32206C8.68247 2.11063 7.98496 2.06446 7.30739 2.18766L7.12792 2.22029C6.51128 2.3324 5.9359 2.60758 5.46156 3.01724C4.99568 3.41959 4.64381 3.93739 4.44124 4.51867Z"
        strokeLinecap="round"
      />
      <path
        d="M6.33268 9.99935L6.63726 9.72706C6.89823 9.49376 7.10528 9.20645 7.24406 8.88508L7.41089 8.49872C7.56573 8.14016 7.56703 7.73385 7.41449 7.3743L7.38381 7.30199C7.2634 7.01815 7.04058 6.79001 6.75966 6.66293L6.69477 6.63357C6.19178 6.40603 5.9235 5.85133 6.05739 5.31575V5.31575C6.119 5.06931 6.261 4.85044 6.46093 4.69374L6.82283 4.41009C7.17397 4.13487 7.59398 3.96142 8.037 3.90868L8.05738 3.90625C8.49697 3.85392 8.94243 3.92612 9.343 4.11462L9.40101 4.14192C9.68943 4.27765 9.94294 4.47764 10.1421 4.72656V4.72656C10.3713 5.01306 10.5209 5.35492 10.5759 5.71769L10.6551 6.24063"
        stroke="white"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
};

export default EarIcon;
