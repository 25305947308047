import LatestReportDisplay from "@components/common/latest-report-display";
import { Product } from "@lib/model/product";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { StarSummary } from "./summary/star-ratings";
import { useEffect } from "react";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { useRouter } from "next/router";

const ProductLandingTitle = ({
  product,
  ctaRef,
  numberOfStarRatings,
  setProduct,
  className,
  testId,
}: {
  product: Product;
  ctaRef: any;
  numberOfStarRatings: number;
  setProduct: (product: Product) => void;
  className?: string;
  testId?: string;
}) => {
  const { products: allProducts } = useGlobalAllProducts();

  const router = useRouter();

  useEffect(() => {
    if (allProducts) {
      const urlProductSlug = router.asPath
        .split("#")[0]
        .replace("?internal=true", "")
        .split("/")
        .pop();

      if (urlProductSlug) {
        const urlProduct = allProducts.find(
          (product) => product.slug === urlProductSlug
        );
        setProduct(urlProduct);
      }
    }
  }, [allProducts, product, setProduct, router.asPath]);

  return (
    <div className={`${className}`}>
      <h1
        data-testid={testId}
        ref={ctaRef}
        className="hidden md:block text-left m-0 mt-4 mb-2 text-3xl md:text-4xl leading-tight"
      >
        {getProductLabels(product).shortLabel}
      </h1>
      <div className="font-sans font-normal">
        <LatestReportDisplay
          products={[product]}
          page="product-detail"
          className="justify-start"
        />
      </div>
      {numberOfStarRatings > 0 && (
        <div className="text-left font-sans font-normal text-xs mt-2">
          <StarSummary product={product} />
        </div>
      )}
    </div>
  );
};

export default ProductLandingTitle;
