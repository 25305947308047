import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { useContext } from "react";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import {
  AnswerInputProps,
  getAnswerExplanation,
  trackConfirmationShown,
} from "@components/tray/answer-input";
import { getAnswers } from "@lib/utilities/rec-ui";
import { PrimaryButton } from "@components/common/button";
import { addNonBreakSpace } from "@lib/utilities/client-utilities";
import useAnswerConfirmation from "@lib/hooks/use-answer-confirmation";
import Answer from "@lib/model/answer";

export const SingleChoiceAnswerInput = (props: AnswerInputProps) => {
  const { questionOverrides, productCategoryConfig, activeResponseSet } =
    useContext(RecUIContext);
  const { getConfirmation } = useAnswerConfirmation();
  const currentQuestion = props.currentQuestion;
  const answers = getAnswers(
    questionOverrides,
    currentQuestion,
    productCategoryConfig,
    activeResponseSet
  );

  const explanations = answers
    .map((answer) =>
      getAnswerExplanation(
        productCategoryConfig.name,
        answer,
        currentQuestion.id,
        activeResponseSet
      )
    )
    .filter((explanation) => !!explanation);
  const hasExplanations = explanations.length > 0;
  const wrapperClasses = hasExplanations
    ? "flex flex-col gap-2"
    : "grid grid-cols-2 gap-2";

  return (
    <div className={wrapperClasses}>
      {answers.map(function (answer, i) {
        return (
          <AnswerButton
            key={answer.id}
            isSelected={props.confirmAnswer?.id === answer.id}
            hasExplanation={hasExplanations}
            onClick={() => {
              // if the user clicks the same answer twice, we should clear the confirmation
              const alreadyHasConfirmation =
                props.confirmAnswer?.id === answer.id;
              if (alreadyHasConfirmation) {
                props.setConfirmAnswer(null);
                return;
              }

              const confirmation = getConfirmation(answer);

              if (confirmation) {
                props.setConfirmAnswer(confirmation);
                trackConfirmationShown(currentQuestion, answer, confirmation);
                return;
              }
              props.animateQuestionTray(
                () => props.setConfirmAnswer(null),
                () => props.submitNewResponse(answer)
              );
            }}
            data-testid={`answer-${answer.id}`}
          >
            {props.animatingResponse ? (
              <EllipsisHorizontalIcon className="h-5 animate-pulse" />
            ) : (
              <AnswerContent
                // There will be an explanation if the question has an explanation
                // resolver, or if it has a static explanation from its config.
                explanation={getAnswerExplanation(
                  productCategoryConfig.name,
                  answer,
                  currentQuestion.id,
                  activeResponseSet
                )}
                answer={answer}
              />
            )}
          </AnswerButton>
        );
      })}
      {currentQuestion.skipOption && (
        <AnswerButton
          onClick={props.skipQuestion}
          isSelected={false}
          hasExplanation={false}
        >
          {currentQuestion.skipOption}
        </AnswerButton>
      )}
    </div>
  );
};

/**
 * The content inside the button. Possibly includes an explanation.
 */
const AnswerContent = ({
  explanation,
  answer,
}: {
  explanation: string;
  answer: Answer;
}) => {
  if (explanation) {
    return (
      <>
        <strong>{addNonBreakSpace(answer.mainText, 2, 10)}</strong>:{" "}
        <span>{addNonBreakSpace(explanation, 2, 10)}</span>
      </>
    );
  }

  return <>{addNonBreakSpace(answer.mainText, 2, 10)}</>;
};

/**
 * The answer button element/component.
 */
const AnswerButton = ({
  hasExplanation,
  children,
  isSelected,
  ...props
}: {
  hasExplanation: boolean;
  isSelected: boolean;
  children: React.ReactNode;
} & React.ComponentPropsWithoutRef<"button">) => {
  if (hasExplanation) {
    return (
      <button
        className={`transition-all text-left py-2 px-4 border border-gray-300 hover:bg-gray-50 rounded-lg ${
          isSelected ? "bg-gray-100" : "bg-white"
        }`}
        {...props}
      >
        {children}
      </button>
    );
  }

  return (
    <PrimaryButton
      className="transition-all"
      variant={isSelected ? "solid" : "outline"}
      {...props}
    >
      {children}
    </PrimaryButton>
  );
};

export default SingleChoiceAnswerInput;
