import React from "react";
import {
  ArcScoreDisplay,
  PanelSetProps,
  ScoreDisplay,
  SummaryTitles,
} from "@components/product/summary/common";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { getAspectIcon } from "@components/compare/compare-fns";
import { useResponseAnswers } from "@lib/hooks/use-multi-response";
import { useAttribute } from "@lib/hooks/use-attributes";
import { useExplanation } from "@lib/hooks/use-explanation";
import { getQualitativeWord } from "@lib/utilities/product-classifier";
import { getProductLabels } from "@lib/utilities/shared-utilities";

const topCount = 10;

const LaptopPortability = (props: PanelSetProps) => {
  const { explanation } = useExplanation(
    "portabilityExplanation",
    props.selectedProduct
  );

  const { answer: screenAnswer } = useResponseAnswers(
    "01585282-7858-449b-9eea-ad7f9a67a70b"
  );

  const { answer: portabilityAnswer } = useResponseAnswers(
    "6580cdb5-4914-4358-9b75-5e5e8609f798"
  );

  const portabilityAnswerValue = parseFloat(portabilityAnswer?.mainText);

  const mobilityAttribute = useAttribute("mobilityScore");

  const mobilityScore = props.selectedProduct.attributes["mobilityScore"].value;

  const screenSizeAttribute = useAttribute("screenSize");
  const weightAttribute = useAttribute("weight");
  const thicknessAttribute = useAttribute("thickness");

  const first = (
    <>
      The {getProductLabels(props.selectedProduct).shortLabel} has{" "}
      <strong>
        {getQualitativeWord(mobilityScore)
          .toLowerCase()
          .replace("best", "the best")
          .replace("fair", "only fair")}
      </strong>{" "}
      portability.
    </>
  );
  const second = `The most portable laptops are small, thin, and light.`;

  if (portabilityAnswerValue !== 3 && portabilityAnswerValue !== 4) {
    return null;
  }

  const color = getMeetsNeedsColor(!explanation.isUnmet);

  return (
    <DonePanel
      title={`Portability`}
      color={color}
      modal={{
        title: mobilityAttribute.displayName || mobilityAttribute.name,
        text: mobilityAttribute.explanation,
      }}
    >
      <div className="flex flex-row gap-12 lg:gap-24 mb-4">
        <ArcScoreDisplay
          product={props.selectedProduct}
          attribute={mobilityAttribute}
          icon={getAspectIcon("laptops", "mobilityScore")}
          score={mobilityScore}
          color={color}
          showScore={false}
        />
        <div className="flex flex-col w-full">
          <div className="flex justify-between items-end border-b-[1px] border-t-[1px] border-gray-500 py-1">
            <div className="tracking-wider font-bold text-sm">SIZE</div>
            <ScoreDisplay
              attribute={screenSizeAttribute}
              product={props.selectedProduct}
              scoreClass="!text-2xl"
              unitClass="!text-xl"
            />
          </div>
          <div className="flex justify-between items-end border-b-[1px] border-gray-500 py-1">
            <div className="tracking-wider font-bold text-sm">WEIGHT</div>
            <ScoreDisplay
              attribute={weightAttribute}
              product={props.selectedProduct}
              scoreClass="!text-2xl"
              unitClass="!text-xl"
            />
          </div>
          <div className="flex justify-between items-end border-b-[1px] border-gray-500 py-1">
            <div className="tracking-wider font-bold text-sm">THICKNESS</div>
            <ScoreDisplay
              attribute={thicknessAttribute}
              product={props.selectedProduct}
              scoreClass="!text-2xl"
              unitClass="!text-xl"
            />
          </div>
        </div>
      </div>
      <SummaryTitles {...{ first, second, showSecondOnly: true }} />
    </DonePanel>
  );
};

export default LaptopPortability;
