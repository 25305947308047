import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

export interface DropdownItem {
  label: string | number;
  value: string | number;
}

/**
 * Converts a simple array to an array of options values.
 */
export const arrayToOptions = (array: (string | number)[]) =>
  array.map((value) => ({
    value,
    label: value,
  }));

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Dropdown = (props: {
  items: DropdownItem[];
  onChange: (string) => void;
  default: string | number;
  getDisplayLabel?: (selectedLabel) => string | React.ReactNode;
  className?: string;
  buttonClassName?: string;
}) => {
  const getItemByValue = (value) =>
    props.items.find((item) => item.value === value);

  const [selected, setSelected] = useState(
    getItemByValue(props.default)?.value
  );

  // This allows other components to set the local state when URL changes.
  useEffect(() => {
    setSelected(getItemByValue(props.default)?.value);
  }, [props.default]);

  const onChangeHandler = (value: string) => {
    setSelected(value);
    props.onChange(value);
  };

  return (
    <Listbox value={selected} onChange={onChangeHandler}>
      {({ open }) => (
        <>
          <div className={classNames("relative", props.className)}>
            <Listbox.Button
              className={classNames(
                "relative py-2 pl-2 pr-9 text-left focus:outline-none border border-gray-500 hover:bg-gray-50 rounded-md",
                props.buttonClassName
              )}
            >
              <span className="block truncate text-gray-900">
                {props.getDisplayLabel
                  ? props.getDisplayLabel(getItemByValue(selected)?.label)
                  : getItemByValue(selected)?.label}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 fill-gray-900"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer">
                {props.items.map((item) => (
                  <Listbox.Option
                    key={item.value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "relative select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Dropdown;
