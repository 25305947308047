import Session from "@lib/model/session";

export const SessionApiClient = {
  withContext: (args: { apiDomain: string }) => {
    return {
      put: async (session: Session) => {
        await fetch(`${args.apiDomain}/user/session/${session.id}`, {
          method: "put",
          headers: { "content-type": "application/json" },
          body: JSON.stringify({
            ...session,
            ...{ metadata: { createdAt: new Date().toISOString() } },
          }),
        });
      },
    };
  },
};
