import EventTracker, { Events } from "@lib/tracking/event-tracker";
import { Product } from "@lib/model/product";
import EmailForm from "./email-form";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import { SubscriptionType } from "@lib/model/subscription";
import { usePurchaseData } from "@lib/hooks/use-purchase-data";

export const PriceAlertForm = ({
  products,
  categoryName,
  type,
}: {
  products: Product[];
  categoryName: string;
  type: SubscriptionType;
}) => {
  return (
    <EmailForm
      submit={async (email, captchaCode) => {
        const response = await fetch("/api/price-alerts/subscribe", {
          body: JSON.stringify({
            email,
            captchaCode,
            categoryName,
            productIds: products.map((product) => product.id),
            productNames: products.map(
              (product) => getProductLabels(product).shortLabel
            ),
            prices: products.map((product) => product.bestPrice),
            type,
          }),
          method: "POST",
        });
        if (response.status === 200) {
          const body = await response.json();

          if (body["subscriptionId"]) {
            EventTracker.track(Events.PriceAlertsSubscribe, {
              email,
              product0label: getProductLabels(products[0]).shortLabel,
              product0id: products[0].id,
              product1id: products[1].id,
              product1label: getProductLabels(products[1]).shortLabel,
              subscriptionId: body["subscriptionId"],
            });
            return true;
          }
        }
        return false;
      }}
      msgSuccess="Thank you for subscribing to price updates!"
      msgError="We were unable to subscribe you to the price alert. Please try again later."
      inlineBtn
      btnText="Submit"
    />
  );
};

export const VsPriceAlertsFormBlock = ({
  products,
  categoryName,
}: {
  products: Product[];
  categoryName: string;
}) => {
  const { retailers: retailers0 } = usePurchaseData(products[0]);
  const { retailers: retailers1 } = usePurchaseData(products[1]);
  let retailerCount;

  if (retailers0 && retailers1) {
    retailerCount = [...new Set([...retailers0, ...retailers1])].length;
  }

  const shortLabel0 = getProductLabels(products[0]).shortLabel;
  const shortLabel1 = getProductLabels(products[1]).shortLabel;

  return (
    <div className="bg-gray-100 p-4 sm:p-6 rounded-xl my-12 max-w-screen-md mx-auto">
      <h2 className="font-sans mb-3 text-2xl no-toc">
        Get a great deal on the {shortLabel0} or the {shortLabel1}
      </h2>
      <div className="my-2 min-h-[76px]">
        {retailerCount && (
          <>
            <p className="!mb-1">
              Perfectrec constantly monitors the prices from {retailerCount}{" "}
              retailers and sends you a weekly update.
            </p>
            <p> Only the products you care about. No spam.</p>
          </>
        )}
      </div>
      <PriceAlertForm
        {...{ products, categoryName, type: "products_price_alert_comparison" }}
      />
    </div>
  );
};
