import {
  useContext,
  useState,
  useEffect,
  createContext,
  SetStateAction,
  Dispatch,
} from "react";
import Response, { ResponseInput } from "@lib/model/response";
import { SessionContext } from "contexts/session-context-provider";
import { RecUIContext } from "contexts/rec-ui-context-provider";
import Answer from "@lib/model/answer";
import EventTracker, { Events } from "@lib/tracking/event-tracker";
import {
  getForwardOpType,
  getHistoryTracking,
  questionHasBeenAnswered,
} from "@lib/utilities/rec-ui";
import { ProductCategoryContext } from "contexts/product-category-context";
import {
  ApiRecommendedProductsFetcher,
  mlRequestBuilder,
  handleRecsError,
} from "@lib/fetching/recommendations";
import { isNil } from "ramda";
import { v4 as uuidv4 } from "uuid";
import { SessionApiClient } from "@lib/session-api-client";
import { useRouter } from "next/router";
import { ModelResponse } from "@lib/model/recommender-model";
import { getProductLabels } from "@lib/utilities/shared-utilities";
import eventTracker from "@lib/tracking/event-tracker";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { RecUiForwardOp } from "@lib/model/enum";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";

type QuestionIteratorContextValues = {
  submitStagedResponse: () => void;
  submitNewResponse: (answer: Answer) => void;
  goBack: any;
  startOver: () => void;
  skipQuestion: () => void;
  setStagedResponse: Dispatch<SetStateAction<ResponseInput>>;
  setBackHandler: Dispatch<any>;
  resetBackHandler: () => void;
  forwardOpType: RecUiForwardOp;
  showUserGuide: boolean;
  stagedResponse: ResponseInput;
  backHandler: any;
  submitResponse: (response: any) => void;
  toggleAnswerInResponse: (
    answer: Answer,
    stagedResponse: ResponseInput
  ) => ResponseInput;
  clearSubGroupInResponse: (
    subGroup: string,
    stagedResponse: ResponseInput
  ) => ResponseInput;
  recsClient: any;
  recsRequestBuilder: any;
};

export const QuestionIteratorContext =
  createContext<QuestionIteratorContextValues>(null);

/**
 * This context contains operations for things that "move" the question pointer and
 * interact with the backend and recommender model.
 *
 */
const QuestionIteratorContextProvider = ({ children }) => {
  const {
    currentQuestion,
    questions,
    activeResponseSet,
    topProducts,
    setHasDisambiguation,
    setQuestionAllowedAnswers,
    nextQuestion,
    setBack,
    resetRecommendations,
    setAnimatingResponse,
    setFirstQuestion,
    buildUpdatedResponseSet,
    storeActiveResponseSetId,
    updateRecDisplay,
    storeResponseSet,
    resetResponseSet,
    handleLoadingDone,
    restoreFromHistory,
    storeRecState,
    writeResponseHistory,
    resetResponseHistory,
    saveRankedProductIds,
    storeScreen,
    getLastAnsweredQuestionId,
  } = useContext(RecUIContext);
  const { session, initSession } = useContext(SessionContext);

  const { apiDomain, productCategoryConfig } = useContext(
    ProductCategoryContext
  );

  const { rawProducts: products } = useGlobalAllProducts();

  // We use this state for input types that require clicking an outside button
  // such as "next" in order to submit them. Examples are checkbox questions,
  // and freeform text. This state gets reset whenever the question pointer
  // changes, although if the user proceeds, its contents get stamped into a
  // response.
  const [stagedResponse, setStagedResponse] = useState<ResponseInput>(null);

  // It is possible for other components to supply their own "go back" handler.
  const [backHandler, setBackHandler] = useState(null);

  const resetBackHandler = () => setBackHandler(backHandler);

  // Handle resetting the staged response whenever question changes. Mainly, this
  // helps when someone uses the browser-back button after having done multiple
  // questions with staged responses (like checkboxes).
  useEffect(() => {
    if (!currentQuestion || !stagedResponse) {
      return;
    }
    if (stagedResponse.questionId !== currentQuestion.id) {
      setStagedResponse(null);
    }
  }, [currentQuestion?.id, stagedResponse]);

  const [showUserGuide, setShowUserGuide] = useState(false);

  const forwardOpType = getForwardOpType(currentQuestion, stagedResponse);

  const recsClient = ApiRecommendedProductsFetcher(
    productCategoryConfig,
    apiDomain
  );

  const sessionClient = SessionApiClient.withContext({
    apiDomain: apiDomain,
  });

  const recsRequestBuilder = mlRequestBuilder(
    productCategoryConfig.name,
    productCategoryConfig.mlEndpoint,
    questions
  );

  /**
   * Writes a new session record if nessecary.
   */
  const saveSession = () => {
    if (!session) {
      const sessionId = uuidv4();
      const session = {
        id: sessionId,
        trackingId: EventTracker.getDistinctId(),
      };
      initSession(session);
      sessionClient.put(session).catch((e) => {
        console.warn("user session not saved", e);
      });
      return sessionId;
    } else {
      return session.id;
    }
  };

  const router = useRouter();

  const prepUserResponse = (sessionId, response) => {
    if (isNil(response.responseSetId)) {
      return {
        ...response,
        ...{ responseSetId: uuidv4(), sessionId: sessionId },
      };
    }

    return response;
  };

  /**
   * A common set of operations for moving the question pointer forward. This
   * is typically done after the model comes back with a response.
   */
  const handleForward = (userResponse, data) => {
    if (!data) {
      return;
    }

    resetBackHandler();
    const sessionId = saveSession();
    const response = prepUserResponse(sessionId, userResponse);
    nextQuestionHandler(data.modelResult, response);
  };

  /**
   * Click handler for going back one question.
   */
  const goBack = () => {
    setBack(true);

    // Find the last answered question and route to it.
    const questionId = getLastAnsweredQuestionId();

    // Restore iterator state based on last answered question.
    const hasHistory = restoreFromHistory(questionId);
    const query = hasHistory ? "?q=" + questionId : "";

    EventTracker.track(Events.BackQuestion, {
      category: productCategoryConfig.name,
      from_question_id: questionId,
      from_question_text: currentQuestion.mainText,
      ...getHistoryTracking(activeResponseSet, questions),
    });

    router.push(window.location.pathname + query, "", {
      shallow: true,
    });
  };

  /**
   * Enrich a response with question and answer data.
   *
   * It is helpful for us to handle responses with question and answer data
   * nested.
   *
   * @param response
   * @returns
   */
  const addQuestionData = (response: Response) => {
    const question = questions.find(
      (question) => question.id === response.questionId
    );

    const answers = response.answerIds
      ? question.answers.filter((answer) =>
          response.answerIds.includes(answer.id)
        )
      : [];

    return {
      ...response,
      question,
      answers,
    };
  };

  /**
   * Common operations for moving to the next question (e.g. skip, submit)
   */
  const nextQuestionHandler = (
    mlRecData: ModelResponse,
    response: Response
  ) => {
    const advance = mlRecData.recommendations.length > 0;
    const responseWithQuestionData = addQuestionData(response);
    let topProductIds = [];

    // Reset staged question.
    setStagedResponse(null);

    // Hide the user guide
    setShowUserGuide(false);

    if (advance) {
      topProductIds = updateRecDisplay(mlRecData);
    }

    // Add allowed answers
    if (mlRecData.nextQuestion) {
      // Add allowed answers to correct question in questions state.
      setQuestionAllowedAnswers(
        mlRecData.nextQuestion.questionId,
        mlRecData.nextQuestion.responseIds
      );
    }

    const modelNextQuestion = mlRecData.nextQuestion
      ? questions.find(
          //@ts-ignore
          (question) => question.id === mlRecData.nextQuestion.questionId
        )
      : null;

    // Store the full list of product rankings. We use this on the done page
    // and on the share page to order and group the not-recommended products
    // correctly.
    saveRankedProductIds(mlRecData);

    if (advance) {
      const updatedResponseSet = buildUpdatedResponseSet(
        responseWithQuestionData
      );
      storeActiveResponseSetId(updatedResponseSet.id);
      storeResponseSet(updatedResponseSet);
      const { questionId, questionIndex } = nextQuestion(
        updatedResponseSet,
        modelNextQuestion
      );

      // @TODO - we can eliminate the question-has-been-answered check once the
      // model is updated to no longer return next-question inaccurately.
      const isPreviewDone =
        typeof questionId === "undefined" ||
        questionHasBeenAnswered(questionId, updatedResponseSet);

      if (isPreviewDone) {
        storeScreen("previewDone");
        eventTracker.track(Events.FinishedQuestion, {
          recommendation_labels: topProductIds.map(
            (productId) =>
              getProductLabels(
                products.find((product) => product.id === productId)
              ).shortLabel
          ),
          recommendation_ids: topProductIds,
          ...getHistoryTracking(activeResponseSet, questions),
        });
      }

      const query = isPreviewDone ? "?previewDone=1" : "?q=" + questionId;

      router.push(window.location.pathname + query, "", {
        shallow: true,
      });

      // Record the response set and recommendations snapshot for the question
      // we are about to show.
      writeResponseHistory(
        isPreviewDone ? "final" : questionId,
        { ...updatedResponseSet },
        questionIndex,
        mlRecData
      );
    } else {
      // Clear displayed recommendations.
      storeRecState({
        topProductIds: [],
        recNotes: [],
        losers: [],
        filtered: [],
        modelInput: {
          query: [],
          filters: [],
        },
      });
      handleLoadingDone();
    }
  };

  /**
   * Send a response to the model, but do not proceed to next question.
   *
   * Used for question checkbox toggles if realtime updates are turned on.
   */
  const submitResponse = (response) => {
    setBack(false);
    setAnimatingResponse(true);

    const answers = response.answerIds
      ? currentQuestion.answers.filter((answer) =>
          response.answerIds.find((answerId) => answerId === answer.id)
        )
      : [];

    const t0 = performance.now();
    recsClient
      .fetch(
        recsRequestBuilder.addResponse(activeResponseSet?.responses || [], {
          questionId: currentQuestion.id,
          answerIds: answers.map((a) => a.id),
        })
      )
      .catch(handleRecsError)
      .then((recsResponse) => {
        const t1 = performance.now();
        trackModelResponse(t0, t1, recsResponse, productCategoryConfig);
        updateRecDisplay(recsResponse.modelResult);
      });
  };

  const initStagedResponse = (stagedResponse) =>
    stagedResponse?.answerIds
      ? { ...stagedResponse }
      : {
          questionId: currentQuestion.id,
          answerIds: [],
        };

  /**
   * Factor a single answer input into the staged response. Useful for filters
   * and checkboxes
   */
  const toggleAnswerInResponse = (
    answer: Answer,
    stagedResponse: ResponseInput
  ) => {
    const newStagedResponse = initStagedResponse(stagedResponse);

    // Depending on whether the response exists, set or remove it.
    const stagedAnswerIndex = newStagedResponse.answerIds.findIndex(
      (stagedAnswerId) => stagedAnswerId === answer.id
    );

    if (stagedAnswerIndex !== -1) {
      newStagedResponse.answerIds.splice(stagedAnswerIndex, 1);
    } else {
      if (!answer.subGroup && !answer.exclusiveSet) {
        newStagedResponse.answerIds.push(answer.id);
      } else {
        // Subgroup and exclusive set function exactly the same here. They display
        // different though.
        const setProp = answer.subGroup ? "subGroup" : "exclusiveSet";

        // Answers with subgroup siblings are currently single choice.
        const currentAnswerIds = newStagedResponse.answerIds;
        const siblingAnswerIds = currentQuestion.answers
          .filter((siblingAnswer) => siblingAnswer[setProp] === answer[setProp])
          .map((answer) => answer.id);
        newStagedResponse.answerIds = [
          ...currentAnswerIds.filter(
            (answerId) => !siblingAnswerIds.includes(answerId)
          ),
          answer.id,
        ];
      }
    }

    return newStagedResponse;
  };

  /**
   * Clear all answers from an answer subgroup.
   */
  const clearSubGroupInResponse = (
    subGroup: string,
    stagedResponse: ResponseInput
  ) => {
    const newStagedResponse = initStagedResponse(stagedResponse);

    const subGroupAnswerIds = currentQuestion.answers
      .filter((siblingAnswer) => siblingAnswer.subGroup === subGroup)
      .map((answer) => answer.id);
    newStagedResponse.answerIds = newStagedResponse.answerIds.filter(
      (answerId) => !subGroupAnswerIds.includes(answerId)
    );

    return newStagedResponse;
  };

  /**
   * Submits a response that the user has been preparing, and gets recommendations
   * from the model. This is for question input types with the showNextButton flag.
   */
  const submitStagedResponse = () => {
    setBack(false);
    setAnimatingResponse(true);
    const answers = stagedResponse.answerIds
      ? currentQuestion.answers.filter((answer) =>
          stagedResponse.answerIds.find(
            (stagedAnswerId) => stagedAnswerId === answer.id
          )
        )
      : null;

    trackQuestionAnswer(answers);

    // Add session and response set ID if they are available.
    if (session) {
      stagedResponse.sessionId = session.id;
    }

    if (activeResponseSet) {
      stagedResponse.responseSetId = activeResponseSet.id;
    }

    if (productCategoryConfig.name !== "laptops") {
      setShowUserGuide(true);
    }
    const t0 = performance.now();

    recsClient
      .fetch(
        recsRequestBuilder.addResponse(activeResponseSet?.responses || [], {
          questionId: currentQuestion.id,
          answerIds: answers.map((a) => a.id),
        })
      )
      .catch(handleRecsError)
      .then((recsResponse) => {
        const t1 = performance.now();
        trackModelResponse(
          t0,
          t1,
          productCategoryConfig,
          recsResponse,
          answers
        );
        handleForward(stagedResponse, recsResponse);
      });
  };

  /**
   * Send a request to the server to:
   *
   * 1) Store response in the database
   * 2) Get top recommendations from ML model based on all responses in this
   *    set.
   */
  const submitNewResponse = (answer: Answer) => {
    setBack(false);
    trackQuestionAnswer([answer]);
    setAnimatingResponse(true);

    // Check that we shouldn't set to true in case we need to wait for the ml response (disambiguation)
    if (currentQuestion.id !== "fc9c7fe5-5544-49dc-bcf9-4bca2eaa90d2") {
      setShowUserGuide(true);
    }

    const userResponse = {
      questionId: currentQuestion.id,
      sessionId: session?.id,
      responseSetId: activeResponseSet?.id,
      answerIds: [answer.id],
    };

    const answers = [answer];
    const t0 = performance.now();

    recsClient
      .fetch(
        recsRequestBuilder.addResponse(activeResponseSet?.responses || [], {
          questionId: currentQuestion.id,
          answerIds: [answer.id],
        })
      )
      .catch(handleRecsError)
      .then((recsResponse) => {
        const t1 = performance.now();
        trackModelResponse(
          t0,
          t1,
          productCategoryConfig,
          recsResponse,
          answers
        );
        setHasDisambiguation(recsResponse.modelResult.nextQuestion !== null);
        setShowUserGuide(true);
        handleForward(userResponse, recsResponse);
      });
  };

  /**
   * Start the recommender over.
   */
  const startOver = () => {
    EventTracker.track(Events.StartedOver, {
      recommendation_labels: topProducts.map(
        (product) => getProductLabels(product).shortLabel
      ),
      recommendation_ids: topProducts.map((product) => product.id),
    });

    // Reset the response history.
    resetResponseHistory();

    setFirstQuestion();
    storeScreen("questions");

    // Reset response set so that it generates a new one.
    resetResponseSet();

    // Reset list of products shown.
    resetRecommendations();

    // Reset staged question.
    setStagedResponse(null);

    setShowUserGuide(false);

    router.push(window.location.pathname, "", {
      shallow: true,
    });
  };

  /**
   * Click handler for skipping a question.
   */
  const skipQuestion = () => {
    setBack(false);
    setAnimatingResponse(true); // Starts the animation.

    EventTracker.track(Events.SkippedQuestion, {
      question_id: currentQuestion.id,
      question_text: currentQuestion.mainText,
      ...getHistoryTracking(activeResponseSet, questions),
    });

    const userResponse: ResponseInput = {
      questionId: currentQuestion.id,
      sessionId: session?.id,
      responseSetId: activeResponseSet?.id,
    };

    if (productCategoryConfig.name !== "laptops") {
      setShowUserGuide(true);
    }

    const t0 = performance.now();

    recsClient
      .fetch(recsRequestBuilder.build(activeResponseSet?.responses || []))
      .catch(handleRecsError)
      .then((recsResponse) => {
        const t1 = performance.now();
        trackModelResponse(t0, t1, recsResponse, productCategoryConfig);
        handleForward(userResponse, recsResponse);
      });
  };

  /**
   * Stores analytics for tracking model response time.
   */
  const trackModelResponse = (
    t0,
    t1,
    modelResponse,
    category: ProductCategoryConfig,
    answers: Answer[] = []
  ) => {
    const historyTracking = getHistoryTracking(activeResponseSet, questions);
    const answersTexts = answers.map((answer) => answer.mainText);

    eventTracker.track(Events.RecommenderModelResponse, {
      clientResponseTimeMilliseconds: t1 - t0,
      modelResponseTimeSeconds: modelResponse?.modelResult?.responseTime,
      cacheHit: modelResponse?.modelResult?.cacheHit,
      question_id: currentQuestion.id,
      question_text: currentQuestion.mainText,
      answer_ids: answers.map((answer) => answer.id),
      answers: answersTexts,
      all_answers: [...historyTracking.all_responses, answersTexts.join(" | ")],
      // Normally we don't have to supply product category, but here we want
      // to make sure that it has the value from before the promise. This
      // will override the default category derivation that occurs in the
      // tracker.
      product_category: category.slug,
      ...historyTracking,
    });
  };

  /**
   * Stores analytics for answering one question.
   */
  const trackQuestionAnswer = (answers: Answer[]) => {
    const historyTracking = getHistoryTracking(activeResponseSet, questions);
    const answersTexts = answers.map((answer) => answer.mainText);

    EventTracker.track(Events.AnsweredQuestion, {
      question_id: currentQuestion.id,
      question_text: currentQuestion.mainText,
      answer_ids: answers.map((answer) => answer.id),
      answers: answersTexts,
      all_answers: [...historyTracking.all_responses, answersTexts.join(" | ")],
      ...historyTracking,
    });
  };

  return (
    <QuestionIteratorContext.Provider
      value={{
        submitStagedResponse,
        submitNewResponse,
        goBack: backHandler || goBack,
        startOver,
        skipQuestion,
        setStagedResponse,
        setBackHandler,
        resetBackHandler,
        forwardOpType,
        showUserGuide,
        stagedResponse,
        backHandler,
        submitResponse,
        toggleAnswerInResponse,
        clearSubGroupInResponse,
        recsClient,
        recsRequestBuilder,
      }}
    >
      {children}
    </QuestionIteratorContext.Provider>
  );
};

export default QuestionIteratorContextProvider;
