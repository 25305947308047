export const ShareClient = ({ apiDomain }) => {
  const fetcher = (uri, opts, shareId) => {
    return fetch(`${apiDomain}/${uri}`, {
      ...opts,
    })
      .then((res) => res.text())
      .then((text) => {
        // This is a short term solution until we change our endpoints to ensure
        // that they always return JSON.
        try {
          const response = JSON.parse(text);
          return { ...response, shareId };
        } catch {
          return { shareId };
        }
      });
  };

  return {
    put: (id, body) => {
      return fetcher(
        `shared/recommendations/${id}`,
        {
          body: JSON.stringify(body),
          method: "put",
          headers: { "content-type": "application/json" },
        },
        id
      );
    },
    get: (id) => {
      return fetcher(`shared/recommendations/${id}`, {}, id);
    },
  };
};
