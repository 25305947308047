/**
 * General type for stored llm output
 */
export interface StoredLlmOutput {
  result: string;
  id: string;
  created_at?: string;
  updated_at?: string;
  metadata: {
    prices: { productId: string; price: number }[];
    priceComments: { productId: string; priceComment: string }[];
    priceComparisons: { productId: string; priceComparison: string }[];
  };
}

// Setting it to the prompts in shared-llm-utilities does not work since we cannot set those to const, that is causing errors everywhere
const allPrompts = [
  "comparison",
  "comparePhotosPhone",
  "compareVideosPhone",
  "compareCameraPhone",
  "comparePictureQualityTV",
  "compareMoviesTV",
  "compareSportsTV",
  "compareGamingTV",
  "compareNewsTV",
  "compareAnimationTV",
  "compareBrightRoomTV",
  "compareGeneralUseLaptop",
  "compareEngineeringLaptop",
  "compareGamingLaptop",
  "compareMusicHeadphones",
  "compareCallsHeadphones",
  "compareGamingHeadphones",
  "compareTvHeadphones",
  "comparePodcastsHeadphones",
  "compareExerciseHeadphones",
  "compareNoiseReductionHeadphones",
  "compareTravelHeadphones",
  "comparePerformanceLaptop",
  "compareScreenLaptop",
  "productDetail",
  "worthBuying",
  "isGood",
  "prosAndCons",
  "explainPhotosPhone",
  "explainVideosPhone",
  "explainCameraPhone",
  "explainPictureQualityTV",
  "explainMoviesTV",
  "explainSportsTV",
  "explainGamingTV",
  "explainNewsTV",
  "explainAnimationTV",
  "explainBrightRoomTV",
  "explainGeneralUseLaptop",
  "explainEngineeringLaptop",
  "explainGamingLaptop",
  "explainMusicHeadphones",
  "explainCallsHeadphones",
  "explainGamingHeadphones",
  "explainTvHeadphones",
  "explainPodcastsHeadphones",
  "explainExerciseHeadphones",
  "explainNoiseReductionHeadphones",
  "explainTravelHeadphones",
  "explainPerformanceLaptop",
  "explainScreenLaptop",
] as const;

//prettier-ignore
export type PromptType = (typeof allPrompts)[number];

export const prosAndConsSchema = {
  type: "object",
  properties: {
    pros: { type: "array", items: { type: "string" } },
    cons: { type: "array", items: { type: "string" } },
  },
  required: ["pros", "cons"],
};
