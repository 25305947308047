import { AssetType, getImageUrl } from "@lib/utilities/shared-utilities";
import Modal from "@components/modal/modal";
import ImageWithFallback from "@components/common/image-with-fallback";

export interface ProductFeatureModalProps {
  open: boolean;
  onClose: () => void;
  name: string;

  // You can supply raw text. This is useful for copy coming directly from json files,
  // which sometimes contain HTML or newline spacing.
  text?: string;

  // You can also supply JSX for richer content.
  jsxText?: React.ReactNode;
  image?: string;
  imageAlt?: string;
}

const ProductFeatureModal = (props: ProductFeatureModalProps) => {
  return (
    <Modal
      onClose={props.onClose}
      modalOpen={props.open}
      closeButtonText={"Close"}
      title={props.name}
    >
      {props.text && (
        <div
          className="whitespace-pre-line content-wrapper"
          dangerouslySetInnerHTML={{ __html: props.text }}
        ></div>
      )}
      {props.jsxText}
      {props.image && (
        <ImageWithFallback
          src={getImageUrl(`${props.image}`, AssetType.Image, { width: 500 })}
          className="my-4 mx-auto"
          alt={props.imageAlt || ""}
        />
      )}
    </Modal>
  );
};

export default ProductFeatureModal;
