import { useEffect, useState } from "react";

/**
 * Given a ref, this hook tracks whether it is on screen.
 */
const useElementOnScreen = (
  observeRef: React.RefObject<HTMLElement>,
  deps: any[] = [],
  threshold = 0.5
) => {
  const [refVisible, setRefVisible] = useState(true);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setRefVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, {
      threshold: threshold,
    });
    const currentContainerRef = observeRef?.current;

    if (currentContainerRef) {
      observer.observe(currentContainerRef);
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef);
      }
    };
  }, [observeRef?.current, ...deps]);

  return { refVisible };
};

export default useElementOnScreen;
