import { useEffect } from "react";
import { isProduction } from "@lib/utilities/client-utilities";

const HorizontalAdUnit = ({
  adSlot,
  ezoicId,
}: {
  adSlot: string;
  ezoicId?: string;
}) => {
  useEffect(() => {
    if (!isProduction()) {
      return;
    }
    try {
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(
        {}
      );
    } catch {
      console.log("ignored google ad error");
    }
  }, []);

  return (
    <div className="w-full h-[90px]">
      <div id={ezoicId}>
        <ins
          className="adsbygoogle"
          style={{
            display: "block",
            height: "90px",
            width: "100%",
            margin: "auto",
          }}
          data-ad-client="ca-pub-5902669265606677"
          data-ad-slot={adSlot}
        ></ins>
      </div>
    </div>
  );
};

export default HorizontalAdUnit;
