import DonePanel from "@components/recommendation/done-panel";
import {
  getProductLabels,
  roundDecimal,
} from "@lib/utilities/shared-utilities";
import { Battery100Icon } from "@heroicons/react/24/solid";
import { SummaryTitles } from "../../product/summary/common";
import { Product } from "@lib/model/product";
import { useAttributes } from "@lib/hooks/use-attributes";
import React from "react";
import { CompareCardScoreDisplay } from "../compare-components";

/**
 * A summary of this phone's battery life, plotted on a line graph.
 */
const ProductBatteryComparison = (props: {
  productA: Product;
  productB: Product;
  attributeName: string;
  ticks: string[];
  keySpecs?: boolean;
  compareResults: any;
}) => {
  const { productA, productB, attributeName, ticks } = props;
  const { attributes } = useAttributes();
  const attribute = attributes[attributeName];

  if (!productA || !productB) {
    return null;
  }

  const values = [productA, productB].map((product, i) =>
    product.attributes[attributeName].value === "N/A"
      ? [false, product, i]
      : [roundDecimal(product.attributes[attributeName].value), product, i]
  );

  const valuesWithBattery = values.filter(
    ([batteryValue, product]) => batteryValue
  );

  if (valuesWithBattery.length === 0) {
    return null;
  }

  const first = (
    <>
      {values.map(([value, product], i) => (
        <React.Fragment key={(product as Product).id}>
          {value ? (
            <>
              The {getProductLabels(product as Product).seoLabel} has{" "}
              <strong>{value}</strong> hours of battery life.{" "}
            </>
          ) : (
            <>
              The {getProductLabels(product as Product).seoLabel} does not have
              a battery.{" "}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );

  const second =
    productA.metadata.categoryName === "laptops" &&
    `Battery life estimate is based on a mix of common use patterns. More portable and higher performing laptops tend to have less battery life.`;

  const maxBatteryLife = parseFloat(ticks[ticks.length - 1]);

  const { pctA, pctB, winner } = props.compareResults[attributeName];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={"Battery"}
      icon={({ className }) => <Battery100Icon className={className} />}
      className={"!bg-white"}
      modal={{
        title: attribute.displayName,
        text: attribute.explanation,
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={attribute}
        categoryName={productA.metadata.categoryName}
        meetsNeedsA={
          parseFloat(productA.attributes["batteryRating"].value) >= 7.0
        }
        meetsNeedsB={
          parseFloat(productB.attributes["batteryRating"].value) >= 7.0
        }
        attributeMax={maxBatteryLife}
        showUnits={true}
      />
      <SummaryTitles
        {...{
          first,
          second,
        }}
      />
    </DonePanel>
  );
};

export default ProductBatteryComparison;
