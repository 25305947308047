import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import { getProductLabels, joinWithAnd } from "@lib/utilities/shared-utilities";
import {
  ArcScoreDisplay,
  ScoreDisplay,
  SummaryTitles,
} from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import {
  laptopDisplayAttributes,
  laptopPerformanceAttributes,
} from "@components/compare/compare-fns";
import { useAttributes } from "@lib/hooks/use-attributes";
import { getQualitativeCompareWeight } from "@lib/utilities/product-classifier";
import { CompareCardScoreDisplay } from "@components/compare/compare-components";
import { capitalizeFirstLetter } from "@lib/utilities/client-utilities";
import { getLaptopPerformanceAttributePhrase } from "./laptop-performance-comparison";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
/**
 * Laptop Performance Comparison
 */
const LaptopDisplayComparison = (props: {
  productA: Product;
  productB: Product;
  icon?: React.FC<{ className?: string }>;
  keySpecs?: boolean;
  compareResults: any;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  const { productA, productB, icon } = props;

  const { attributes: allAttributes } = useAttributes();

  if (!productA || !productB) {
    return null;
  }
  const productAName = getProductLabels(productA).seoLabel;
  const productBName = getProductLabels(productB).seoLabel;

  const scoresA = laptopDisplayAttributes.map((attribute) =>
    parseFloat(productA.attributes[attribute].value)
  );
  const scoresB = laptopDisplayAttributes.map((attribute) =>
    parseFloat(productB.attributes[attribute].value)
  );

  const meanA =
    scoresA.reduce((acc, val) => acc + val, 0) / Object.values(scoresA).length;

  const meanB =
    scoresB.reduce((acc, val) => acc + val, 0) / Object.values(scoresB).length;

  const { winner } = props.compareResults["laptopDisplay"];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  const IconComponent = icon;

  const winners = scoresA.map((score, index) => {
    if (
      score - scoresB[index] > 0.2 ||
      getQualitativeCompareWeight(score) >
        getQualitativeCompareWeight(scoresB[index])
    ) {
      return productA;
    } else if (
      scoresB[index] - score > 0.2 ||
      getQualitativeCompareWeight(scoresB[index]) >
        getQualitativeCompareWeight(score)
    ) {
      return productB;
    } else {
      return "tie";
    }
  });

  const productAWins = [];
  const productBWins = [];

  laptopDisplayAttributes.forEach((attribute, index) => {
    if (winners[index] === productA) {
      productAWins.push(
        getLaptopPerformanceAttributePhrase(productA, attribute)
      );
    }
  });
  laptopPerformanceAttributes.forEach((attribute, index) => {
    if (winners[index] === productB) {
      productBWins.push(
        getLaptopPerformanceAttributePhrase(productB, attribute)
      );
    }
  });

  let first;

  if (productAWins.length === 0 && productBWins.length === 0) {
    first = (
      <>
        The {productAName} and the {productBName} have <strong>similar</strong>{" "}
        screen quality.
      </>
    );
  } else if (productAWins.length > 0 && productBWins.length > 0) {
    first = (
      <>
        The {productAName} has a better screen than the {productBName} for{" "}
        {joinWithAnd(productAWins)}, while the {productBName} has a better
        screen for {joinWithAnd(productBWins)}.
      </>
    );
  } else if (productAWins.length > 0) {
    first = (
      <>
        The {productAName} has a <strong>better</strong> screen than the{" "}
        {productBName} for {joinWithAnd(productAWins)}.
      </>
    );
  } else if (productBWins.length > 0) {
    first = (
      <>
        The {productBName} has a <strong>better</strong> screen than the{" "}
        {productAName} for {joinWithAnd(productBWins)}.
      </>
    );
  }

  const title = "Screen Quality";
  const second =
    "PerfectRec’s screen quality scores take into account various important factors that are relevant for different use cases.";

  const llmComparison = props.comparisons?.compareScreenLaptop?.result;

  const dims = [
    "screenSize",
    "resolution",
    "displayTechnology",
    "refreshRate",
    "brightness",
  ];
  const dimName = [
    "SIZE",
    "RESOLUTION",
    "DISPLAY TECHNOLOGY",
    "REFRESH RATE",
    "BRIGHTNESS",
  ];

  return (
    <DonePanel
      title={title}
      className={"!bg-white"}
      modal={{
        title: "Screen Quality",
        text: "PerfectRec’s screen quality scores are determined differently for different use cases. The overall screen quality score is determined by taking the average of the screen quality scores for the use cases you selected. If you skipped the use case question, the overall screen quality score is based on the average of the use case screen quality scores.",
      }}
    >
      <CompareCardScoreDisplay
        productA={productA}
        productB={productB}
        attribute={allAttributes["overallScreenScore"]}
        categoryName={productA.metadata.categoryName}
      />
      <div className="flex flex-col w-full mt-2 mb-6 text-gray-700">
        {dims.map((dim, index) => {
          return (
            <div
              key={index}
              className={`flex justify-between items-end border-b-[1px] ${
                index === 0 && "border-t-[1px]"
              } border-gray-300 py-3`}
            >
              <ScoreDisplay
                attribute={allAttributes[dim]}
                product={productA}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
              <div className="tracking-wider font-bold text-xs">
                {dimName[index]}
              </div>
              <ScoreDisplay
                attribute={allAttributes[dim]}
                product={productB}
                scoreClass="!text-base"
                unitClass="!text-sm"
              />
            </div>
          );
        })}
      </div>
      <SummaryTitles {...{ first, second, llm: llmComparison }} />
    </DonePanel>
  );
};

export default LaptopDisplayComparison;
