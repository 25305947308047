import { InputType } from "@lib/model/enum";
import SingleChoiceAnswerInput from "@components/answer-input/single-choice";
import MultipleChoiceAnswerInput from "@components/answer-input/multiple-choice";
import { QuestionWithAnswers } from "@lib/model/question";
import { ResponseInput } from "../../../shared/src/model/response";
import Answer from "../../../shared/src/model/answer";
import ScaleChoiceAnswerInput from "@components/answer-input/scale-choice";
import GroupedFilters from "@components/answer-input/grouped-filters";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import { AnswerConfirmation } from "@lib/model/answer-confirmation";
import { ResponseSetWithResponses } from "@lib/model/response-set";

const inputComponentMap = {
  [InputType.SingleChoice]: {
    component: SingleChoiceAnswerInput,
    showNextButton: false,
    multiAnswer: false,
  },
  [InputType.MultipleChoice]: {
    component: MultipleChoiceAnswerInput,
    showNextButton: true,
    multiAnswer: true,
  },
  [InputType.ScaleChoice]: {
    component: ScaleChoiceAnswerInput,
    showNextButton: false,
    multiAnswer: false,
  },
  [InputType.GroupedFilters]: {
    component: GroupedFilters,
    multiAnswer: true,
    showNextButton: true,
  },
};

/**
 * A set of resolvers for answer input. Allows answer-specific logic for things
 * like answer explanations.
 */
const inputDisplayResolvers = {
  smartphones: {
    "339252bb-c599-4995-b3a9-6f8e09317dd8": {
      getAnswerExplanation: (
        answer: Answer,
        responseSet: ResponseSetWithResponses
      ) => {
        const osAnswerId = responseSet.responses?.find(
          (response) =>
            response.questionId === "62fa6a24-7b63-4f20-9413-bb56861811ad"
        )?.answerIds?.[0];

        const osIdMap = {
          "dcf960aa-1d36-45e1-8cae-492ccdd0cfa2": "iPhone",
          "3d0a2d14-a332-44ab-a689-dd693fe2146d": "android",
        };

        const sizeIdMap = {
          "f458b76f-dd0f-49a0-9137-6535590659ae": "small",
          "611565d7-a4dc-4cbf-afb4-73b875f225ea": "medium",
          "9f8834c7-5247-4217-aaeb-d344cb2df7c5": "large",
        };

        const osChoice = osIdMap[osAnswerId] || "skip";
        const size = sizeIdMap[answer.id];

        const phraseMap = {
          iPhone: {
            small: "Only older or used small iPhones are available.",
            medium: "Includes the regular and Pro iPhones.",
            large: "Includes the Plus and Pro Max iPhones.",
          },
          android: {
            small:
              "Androids tend to be larger. The smallest we recommend is 5.9”.",
            medium: "There are many good medium Android options.",
            large: "There are many good large Android options.",
          },
          skip: {
            small:
              "Only older, used iPhones are significantly smaller than 6”.",
            medium: "There are many good medium phone options.",
            large: "There are many good large phone options.",
          },
        };

        return phraseMap[osChoice]?.[size];
      },
    },
  },
};

/**
 * Gets an answer explanation, possibly using the resolvers above.
 */
export const getAnswerExplanation = (
  categoryName: string,
  answer: Answer,
  questionId: string,
  responseSet: ResponseSetWithResponses
) => {
  // Default to static explanation
  if (answer.explanation) {
    return answer.explanation;
  }

  if (
    inputDisplayResolvers[categoryName]?.[questionId]?.getAnswerExplanation &&
    responseSet?.responses?.length > 0
  ) {
    return inputDisplayResolvers[categoryName]?.[
      questionId
    ]?.getAnswerExplanation(answer, responseSet);
  }

  return null;
};

/**
 * Determine whether a question uses the "next" button functionality, as opposed
 * to only allowing skip.
 */
export const questionUsesNextButton = (question: QuestionWithAnswers) =>
  inputComponentMap[question.inputType]?.showNextButton;

/**
 *
 * @param question
 * @returns
 */
export const questionIsMultiAnswer = (question: QuestionWithAnswers) =>
  inputComponentMap[question.inputType]?.multiAnswer;

export type AnswerInput = {
  answerIds: string[];
};

export interface AnswerInputProps {
  currentQuestion: QuestionWithAnswers;
  submitNewResponse: (answer: Answer) => unknown;
  animatingResponse: boolean;
  animateQuestionTray: (
    onStart: () => unknown,
    onMiddle: () => unknown
  ) => unknown;
  setStagedResponse?: React.Dispatch<React.SetStateAction<ResponseInput>>;
  confirmAnswer?: {
    id: string;
    trayContent: string;
    trayVariant: "status" | "warn" | "caution";
    prerequisite: string;
  };
  setConfirmAnswer: (confirmAnswer: unknown) => unknown;

  /**
   * A local response object to manipulate while the user is working on their
   * answer (e.g. checking checkboxes).
   */
  stagedResponse?: ResponseInput;
  setBackHandler: (backHandler: () => unknown) => unknown;
  resetBackHandler: () => unknown;
  submitResponse: (response: ResponseInput) => void;
  skipQuestion: () => void;
}

/**
 * Track showing the confirmation dialog.
 */
export const trackConfirmationShown = (
  question: QuestionWithAnswers,
  answer: Answer,
  confirmation: AnswerConfirmation
) => {
  eventTracker.track(Events.AnswerConfirmationShown, {
    questionId: question.id,
    question_id: question.id,
    question_text: question.mainText,
    answer_id: answer.id,
    answer_text: answer.mainText,
    confirmation_string: confirmation.trayContent,
    confirmation_variant: confirmation.trayVariant,
  });
};

export const AnswerInput = (props: AnswerInputProps) => {
  const InputComponent =
    inputComponentMap[props.currentQuestion.inputType].component;
  return <InputComponent {...props} />;
};
