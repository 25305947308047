import { QuestionWithAnswers } from "@lib/model/question";
import { ResponseSetWithResponses } from "@lib/model/response-set";
import { RecUiForwardOp } from "@lib/model/enum";
import { ResponseInput } from "@lib/model/response";
import { questionUsesNextButton } from "@components/tray/answer-input";
import {
  centsToDollars,
  getProductLabels,
  groupByKey,
  productInEnvCatalog,
  productIsDisabled,
} from "../../../shared/src/utilities/shared-utilities";
import { Product } from "@lib/model/product";
import { QuestionOverrides } from "../../contexts/rec-ui-context-provider";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import Response from "@lib/model/response";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { FeatureUnits } from "@lib/model/enum";
import { AttributeConfiguration } from "../../../data/packages/model/src/productAttribute";
import PurchaseOption from "@lib/model/purchase-option";
import { getDisplayValue } from "@components/compare/compare-fns";
import { pickBestPurchaseOption } from "@lib/utilities/pick-best-purchase-option";
/**
 * This file is for pure functions that are needed for the rec-ui context to
 * operate.
 */

type Option = {
  value: string;
  label: string;
};

const getQuestion = (id, questions) =>
  questions.find((question) => question.id === id);

const checkConditionalQuestions = (
  productCategoryConfig: ProductCategoryConfig,
  question: QuestionWithAnswers,
  responseSet: ResponseSetWithResponses
) => {
  const display = () => {
    if (
      productCategoryConfig.conditionalQuestions &&
      question.id in productCategoryConfig.conditionalQuestions
    ) {
      const displayWhenIDs =
        productCategoryConfig.conditionalQuestions[question.id]
          .onlyDisplayWhenAnyAnswerIds;
      if (displayWhenIDs) {
        for (const response of responseSet.responses) {
          if (response.answerIds) {
            for (const answerId of response.answerIds) {
              if (displayWhenIDs.includes(answerId)) {
                return true;
              }
            }
          }
        }
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const doNotDisplay = () => {
    if (
      productCategoryConfig.conditionalQuestions &&
      question.id in productCategoryConfig.conditionalQuestions
    ) {
      const doNotDisplayWhenIDs =
        productCategoryConfig.conditionalQuestions[question.id]
          .doNotDisplayWhenAnyAnswerIds;
      if (doNotDisplayWhenIDs) {
        for (const response of responseSet.responses) {
          if (response.answerIds) {
            for (const answerId of response.answerIds) {
              if (doNotDisplayWhenIDs.includes(answerId)) {
                return false;
              }
            }
          }
        }
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  return display() && doNotDisplay();
};

/**
 * Gets the next question that should be displayed, that has a known weight.
 *
 * (As opposed to times when the model supplies the next question.) Questions
 * that only come into play when the model calls for them don't have a weight.
 *
 * @returns
 */
export const getNextWeightedQuestionIndex = (
  questions: QuestionWithAnswers[],
  productCategoryConfig: ProductCategoryConfig,
  responseSet?: ResponseSetWithResponses
) => {
  // Find the highest weight among answered, weighted questions referenced by
  // responses that are supposed to be sent to the model (i.e. not ones that
  // the user has "backed" out from).
  let greatestAnsweredQuestionWeight = null;
  const responseCandidates = responseSet?.responses
    ? responseSet.responses.filter((response) => {
        const question = questions.find((q) => q.id === response.questionId);
        return question.weight !== null;
      })
    : null;

  if (responseCandidates) {
    greatestAnsweredQuestionWeight = responseCandidates.reduce(
      (highestWeight, response) => {
        const question = getQuestion(response.questionId, questions);
        return question.weight > highestWeight
          ? question.weight
          : highestWeight;
      },
      0
    );
  }

  // Find the first question with a greater weight that does not have a
  // default answer.
  const questionIndex = questions.findIndex((question) => {
    if (greatestAnsweredQuestionWeight === null) {
      return question.weight !== null;
    }
    return (
      question.weight !== null &&
      question.weight > greatestAnsweredQuestionWeight &&
      checkConditionalQuestions(productCategoryConfig, question, responseSet)
    );
  });

  // Returning -1 here broke the UI in some cases, so we return 0 to indicate the start of the flow
  return questionIndex !== -1 ? questionIndex : 0;
};

/**
 * Gets a response from a response set by its question
 *
 * @param question
 * @param activeResponseSet
 * @returns
 */
export const getSavedResponse = (
  question: QuestionWithAnswers,
  activeResponseSet: ResponseSetWithResponses
) => {
  return activeResponseSet?.responses.find(
    (response) => response.questionId === question.id
  );
};

/**
 * A helper function to determine whether there is a forward action, and if it is
 * "next" or "skip".
 *
 * @param currentQuestion
 * @param stagedResponses
 * @returns
 */
export const getForwardOpType = (
  currentQuestion: QuestionWithAnswers,
  stagedResponse: ResponseInput
) => {
  if (!currentQuestion) {
    return RecUiForwardOp.None;
  }
  const usesNext = questionUsesNextButton(currentQuestion);

  if (!usesNext) {
    return RecUiForwardOp.Skip;
  } else {
    return stagedResponse?.answerIds?.length
      ? RecUiForwardOp.Next
      : RecUiForwardOp.Skip;
  }
};

/**
 * Gets responses from a response set that count as being factored into model.
 *
 * @param responseSet - a response set.
 */
export const getActiveResponses = (responseSet: ResponseSetWithResponses) => {
  return responseSet?.responses ? responseSet.responses : [];
};

/**
 *
 * @param considerationSet
 * @returns
 */
export const getProductOptions = (products: Product[], category: string) => {
  const productOptions = products.map((product) => ({
    label: getProductLabels(product).shortLabel,
    value: product.id,
    manufacturer: product.manufacturer,
  }));

  const optionGroupsObject = groupByKey<Option>(productOptions, "manufacturer");

  return Object.entries(optionGroupsObject)
    .map(([groupLabel, options]) => ({
      label: groupLabel,
      options,
    }))
    .sort((optionGroupA, optionGroupB) =>
      optionGroupA.label.localeCompare(optionGroupB.label)
    );
};

export const variantGroupingAttribute = {
  laptops: "model",
  tvs: "shortName",
  smartphones: "model",
  headphones: "model",
  tablets: "model",
  monitors: "model",
};
/**
 * Gets a three-tier list of options in this format:
 *
 * Group: Manufacturer
 * - Single variant product
 * - Multivariant product (disabled)
 * -- Name of variant 1
 * -- Name of variant 2
 */
export const getProductOptionsWithVariants = (
  products: Product[],
  categoryName: string,
  attributes: AttributeConfigurationMap,
  maxDisplayDims = 4,
  window = null
) => {
  const buildProductOption = (product: Product) => ({
    label: getProductLabels(product).shortLabel,
    model: product.model,
    value: product.id,
  });

  const modelAdded = (product: Product, options) =>
    options.find(
      (option) => option.label === getProductLabels(product).shortLabel
    );

  const optionsList = products.reduce((acc, currentProduct) => {
    // Create a manufacturer top level key if there isn't one.
    if (
      !acc.find(
        (optionGroup) => optionGroup.label === currentProduct.manufacturer
      )
    ) {
      acc.push({ label: currentProduct.manufacturer, options: [] });
    }

    const manufacturerIndex = acc.findIndex(
      (optionGroup) => optionGroup.label === currentProduct.manufacturer
    );

    if (!modelAdded(currentProduct, acc[manufacturerIndex].options)) {
      // Add a variant for the current product.
      const currentProductAttrs = {};
      for (const key of Object.keys(currentProduct.attributes)) {
        currentProductAttrs[key] = currentProduct.attributes[key].value;
      }

      const currentVariant = {
        label: getVariantName(
          currentProductAttrs,
          currentProduct.supportedVariants,
          categoryName,
          attributes,
          maxDisplayDims
        ),
        value: currentProduct.id,
        isCanonical: currentProduct.isCanonical,
      };

      // If we have meaningful variants, create a disabled product group, if it
      // doesn't already exist.
      acc[manufacturerIndex].options.push({
        label: getProductLabels(currentProduct).shortLabel,
        model: currentProduct.model,
        disabled: true,
        options: [
          currentVariant,
          ...currentProduct.variants
            .filter((variant) => productInEnvCatalog(variant, window))
            .map((variant) => ({
              label: getVariantName(
                variant.attributes,
                currentProduct.supportedVariants,
                categoryName,
                attributes,
                maxDisplayDims
              ),
              value: variant.variantId,
              isCanonical: variant.isCanonical,
            })),
        ],
      });
    }

    return acc;
  }, []);

  return optionsList;
};

/**
 * Gets a suitable name for a variant as a string.
 */
const getVariantName = (
  attributeValues: { [key: string]: string },
  supported: string[],
  categoryName: string,
  attributes: AttributeConfigurationMap,
  maxDisplayDims = 4
) => {
  let name = getInlineVariantDisplayDims(
    supported,
    categoryName,
    maxDisplayDims
  )
    .filter((attribute) => attributes[attribute])
    .map((attribute) => {
      const unit = attributes[attribute].unit;
      return (
        attributeValues[attribute] +
        getUnitsSuffix(FeatureUnits[unit], attributeValues[attribute])
      );
    })
    .join(", ");

  name = name
    .replace("GB,", "GB |")
    .replace("&quot;", '"')
    .replace(/\s?\(\d+ W\)/g, "");

  return name;
};

/**
 * Get the key specs for a product as a string.
 */
export const getKeySpecsAsString = (
  product: Product,
  keySpecs: string[],
  attributeConfig: AttributeConfigurationMap
) => {
  return keySpecs
    .filter((keyAttribute) => product.attributes[keyAttribute])
    .map((keyAttribute) => {
      const unit = attributeConfig[keyAttribute]?.unit;
      return (
        product.attributes[keyAttribute].value +
        (unit
          ? getUnitsSuffix(
              FeatureUnits[unit],
              product.attributes[keyAttribute].value
            )
          : "")
      );
    })
    .join(" | ");
};

export const getAttributeShortname = (
  attributeConfig: AttributeConfiguration
) => {
  return attributeConfig.shortName || attributeConfig.displayName;
};

/**
 * Gets variant-defining dimensions in their correct priority order.
 */
export const getVariantDisplayDims = (
  supported: string[],
  category: string,
  max = 4
) => {
  const dimPriority = {
    laptops: [
      "processor",
      "graphics",
      "ram",
      "storage",
      "displayTechnology",
      "resolution",
      "refreshRate",
      "batteryCapacity",
      "touchscreen",
      "screenAspectRatio",
    ],
    tvs: ["screenSize", "sku"],
  };
  const displayDims = supported
    .sort((a, b) => {
      const indexA = dimPriority[category].indexOf(a);
      const indexB = dimPriority[category].indexOf(b);
      return indexA - indexB;
    })
    .slice(0, max);
  return displayDims;
};

/**
 * Gets variant-defining dimensions in their correct priority order.
 */
export const getInlineVariantDisplayDims = (
  supported: string[],
  category: string,
  max = 4
) => {
  const dimPriority = {
    laptops: [
      "processor",
      "graphics",
      "ram",
      "storage",
      "displayTechnology",
      "resolution",
      "refreshRate",
      "batteryCapacity",
      "touchscreen",
      "screenAspectRatio",
    ],
    tvs: ["screenSize"],
  };

  let displayDims;

  //this logic is exclusively for laptops we have to write completely seperate logic for future categories
  if (category === "laptops") {
    displayDims =
      !(
        supported.includes("processor") &&
        supported.includes("graphics") &&
        (supported.includes("ram") || supported.includes("storage"))
      ) &&
      supported.some((dim) =>
        [
          "displayTechnology",
          "resolution",
          "refreshRate",
          "batteryCapacity",
          "touchscreen",
          "screenAspectRatio",
        ].includes(dim)
      )
        ? supported
            .sort((a, b) => {
              const indexA = dimPriority[category].indexOf(a);
              const indexB = dimPriority[category].indexOf(b);
              return indexA - indexB;
            })
            .slice(0, max)
        : ["processor", "graphics", "ram", "storage"]
            .sort((a, b) => {
              const indexA = dimPriority[category].indexOf(a);
              const indexB = dimPriority[category].indexOf(b);
              return indexA - indexB;
            })
            .slice(0, max);

    const hasRam = displayDims.includes("ram");
    const hasStorage = displayDims.includes("storage");

    if (hasRam && !hasStorage) {
      const ramIndex = displayDims.indexOf("ram");
      displayDims.splice(ramIndex + 1, 0, "storage");
      displayDims = displayDims.slice(0, max);
    } else if (hasStorage && !hasRam) {
      const storageIndex = displayDims.indexOf("storage");
      displayDims.splice(storageIndex, 0, "ram");
      displayDims = displayDims.slice(0, max);
    }
  } else {
    displayDims = supported
      .filter((dim) => dim != "sku")
      .sort((a, b) => {
        const indexA = dimPriority[category].indexOf(a);
        const indexB = dimPriority[category].indexOf(b);
        return indexA - indexB;
      })
      .slice(0, max);
  }

  return displayDims;
};

/**
 * Gets the units suffix for an attribute value as a string.
 */
export const getUnitsSuffix = (
  units: FeatureUnits,
  value: string,
  inWords?: boolean
) => {
  switch (units) {
    case FeatureUnits.Hz:
      return "Hz";
    case FeatureUnits.Inch:
      return inWords ? "in" : '"';
    case FeatureUnits.Score10:
      return "/10";
    case FeatureUnits.Score100:
      return "/100";
    case FeatureUnits.Lbs:
      return " lbs";
    case FeatureUnits.Grams:
      return " Grams";
    case FeatureUnits.Oz:
      return " oz";
    case FeatureUnits.GB:
      return " GB";
    case FeatureUnits.Hours:
      return " Hours";
    case FeatureUnits.Watts:
      return " W";
    case FeatureUnits.Wh:
      return " Wh";
    case FeatureUnits.mAh:
      return " mAh";
    case FeatureUnits.Times:
      return value === "No" ? "" : "x";
    case FeatureUnits.Months:
      return ` ${parseFloat(value) > 1 ? "months" : "month"}`;
    case FeatureUnits.Years:
      return ` ${parseFloat(value) > 1 ? "years" : "year"}`;
    case FeatureUnits.Nits:
      return " nits";
    case FeatureUnits.MP:
      return " megapixels";
    case FeatureUnits.Degrees:
      return String.fromCharCode(176);
    case FeatureUnits.Celsius:
      return String.fromCharCode(176) + "C";
    case FeatureUnits.Feet:
      return " feet";
    case FeatureUnits.PPI:
      return " PPI";
    case FeatureUnits.Ms:
      return " ms";
    case FeatureUnits.Percentage:
      return " %";
    case FeatureUnits.Bit:
      return " bit";
    case FeatureUnits.ContrastRatio:
      return ": 1";
    case FeatureUnits.Count:
    case FeatureUnits.None:
    default:
      return "";
  }
};

/**
 * Get allowed answers for a question, given the complete set of answer overrides
 * for all questions.
 *
 * @TODO - this function uses the order of the objects in the "answers" array of
 * the question, not the "weight" property for ordering. Other places use the weight
 * property. Ideally, let's consolidate how question order is determined.
 */
export const getAnswers = (
  questionOverrides: QuestionOverrides,
  question: QuestionWithAnswers,
  productCategoryConfig: ProductCategoryConfig,
  responseSet?: ResponseSetWithResponses
) => {
  const overridenResponses = questionOverrides[question.id]?.allowedAnswers;
  const conditionalAnswerConfig = productCategoryConfig.conditionalAnswers;
  const conditionalAnswerGroupConfig =
    productCategoryConfig.conditionalAnswerGroups;
  let displayAnswers = question.answers;

  if (responseSet) {
    const responseAnswerIds = responseSet.responses
      .map((response) => response.answerIds)
      .flat(1);

    const removeAnswerIds = [];

    const anyAnswer = (ruleAnswerIds) =>
      ruleAnswerIds.some((ruleAnswerId) =>
        responseAnswerIds.includes(ruleAnswerId)
      );

    const allAnswers = (ruleAnswerIds) =>
      ruleAnswerIds.every((ruleAnswerId) =>
        responseAnswerIds.includes(ruleAnswerId)
      );

    const rulesContainerDoesNegate = (container) => {
      const negAndRuleIds = container.doNotDisplayWhenAllAnswerIds;
      const negOrRuleIds = container.doNotDisplayWhenAnyAnswerIds;
      const posAndRuleIds = container.onlyDisplayWhenAllAnswerIds;

      // Assess exclusionary OR rules (doNotDisplayWhenAnyAnswerIds).
      if (negOrRuleIds && anyAnswer(negOrRuleIds)) {
        return true;
      }

      // Assess exclusionary AND rules (doNotDisplayWhenAllAnswerIds).
      if (negAndRuleIds && allAnswers(negAndRuleIds)) {
        return true;
      }

      // Assess inclusive AND rules (displayWhenAllAnswerIds).
      if (posAndRuleIds && !allAnswers(posAndRuleIds)) {
        return true;
      }

      return false;
    };

    // Answers may have their own conditional config.
    if (conditionalAnswerConfig) {
      const condAnswers = question.answers.filter(
        (answer) => answer.id in conditionalAnswerConfig
      );
      for (const answer of condAnswers) {
        if (rulesContainerDoesNegate(conditionalAnswerConfig[answer.id])) {
          removeAnswerIds.push(answer.id);
        }
      }
    }

    // Also, answer groups may have conditional config. This affects display of
    // all their child answers.
    if (conditionalAnswerGroupConfig) {
      const condGroupAnswers = question.answers.filter(
        (answer) => productCategoryConfig.conditionalAnswerGroups[answer.group]
      );

      for (const answer of condGroupAnswers) {
        if (
          rulesContainerDoesNegate(conditionalAnswerGroupConfig[answer.group])
        ) {
          removeAnswerIds.push(answer.id);
        }
      }
    }

    if (removeAnswerIds.length > 0) {
      displayAnswers = question.answers.filter(
        (answer) => !removeAnswerIds.includes(answer.id)
      );
    }
  }

  // Factor in overrides provided by the model.
  return overridenResponses
    ? question.answers.filter((answer) =>
        overridenResponses.find(
          (allowedAnswerId) => allowedAnswerId === answer.id
        )
      )
    : displayAnswers;
};

/**
 * Given a new response, factor it into the existing active response set and
 * prepare it for storage in the client.
 *
 * Mainly, this takes care of:
 * - Possibly creating a new response set, or picking the right existing one
 * - Either splicing the input response in at the right index, or pushing it to
 *   the end.
 * - Adding the "send to model" flag, which indicates it is not a response that
 *   has been backed-out from.
 *
 * @param newResponse  - A new response object.
 * @param responseSets - The list of all response sets known to the client.
 *
 * @returns - The response set, updated with the new response.
 */
export const deriveUpdatedResponseSet = (
  newResponse: Response,
  responseSets: ResponseSetWithResponses[]
) => {
  const responseSetId = newResponse.responseSetId;

  const targetResponseSetIndex = responseSets.findIndex(
    (responseSet) => responseSet.id === responseSetId
  );
  const needsNewResponseSet = targetResponseSetIndex === -1;

  // Find an existing response set, or instantiate a new one.
  let targetResponseSet;
  if (needsNewResponseSet) {
    targetResponseSet = {
      id: responseSetId,
      createdAt: newResponse.createdAt,
      responses: [],
    };
  } else {
    targetResponseSet = responseSets[targetResponseSetIndex];
  }

  // If the new response is for a question that has already been answered,
  // replace that response with the new one. Otherwise, add it to the end.
  let existingResponseIndex = -1;
  if (targetResponseSet.responses) {
    existingResponseIndex = targetResponseSet.responses.findIndex(
      (existingResponse) =>
        existingResponse.questionId === newResponse.questionId
    );
  }

  const response = {
    ...newResponse,
    sendToModel: true,
  };

  if (existingResponseIndex === -1) {
    targetResponseSet.responses = [...targetResponseSet.responses, response];
  } else {
    targetResponseSet.responses[existingResponseIndex] = response;
  }

  return targetResponseSet;
};

/**
 * Determines whether a question has been answered in the supplied response set.
 */
export const questionHasBeenAnswered = (
  questionId: string,
  responseSet: ResponseSetWithResponses
) => {
  return responseSet.responses.find(
    (response) => response.questionId === questionId
  );
};

/**
 * Gets a human-readable list of the label of the answers to questions in a
 * response set, as a string.
 */
export const responsesAsString = (
  responseSet: ResponseSetWithResponses,
  questions: QuestionWithAnswers[]
) => {
  if (!responseSet || !questions) {
    return "";
  }
  return responsesAsArray(responseSet, questions).join(" || ");
};

/**
 * Gets a human-readable list of the label of the answers to questions in a
 * stagedResponse, as a string.
 */
export const stagedResponseAsString = (
  stagedResponse: ResponseInput,
  questions: QuestionWithAnswers[]
) => {
  if (!stagedResponse || !questions) {
    return "";
  }
  const question = questions.find(
    (question) => question.id === stagedResponse.questionId
  );

  const answers = question.answers.filter((answer) => {
    return stagedResponse.answerIds.includes(answer.id);
  });

  return answers.length && question.realtimeUpdate
    ? ` || ${answers.map((answer) => answer.mainText).join(" | ")}`
    : "";
};

/**
 * Gets a human-readable list of recommendation product labels, as a string.
 */
export const recommendationsAsString = (
  products: Product[],
  productCategoryConfig: ProductCategoryConfig,
  allAttributes: AttributeConfigurationMap,
  glue = "\n"
) => {
  return products
    .map((product) => {
      let label = `${getProductLabels(product).shortLabel} ${centsToDollars(
        product.bestPrice
      )}`;

      if (productCategoryConfig) {
        const questionRelatedAttributes = [] as string[];
        for (const k in product.attributes) {
          if (allAttributes[k]?.relatedToQuestionResponses === "Yes") {
            questionRelatedAttributes.push(k);
          }
        }

        const result = questionRelatedAttributes
          .map((attr) => {
            const attribute = allAttributes[attr];
            const unit = attribute?.unit;
            return `${attribute.displayName}: ${getDisplayValue(
              attribute,
              product,
              1
            )}${getUnitsSuffix(
              FeatureUnits[unit],
              product.attributes[attr]?.value,
              true
            )}`;
          })
          .join(", ");
        label += ` (${result})`;
      }
      return label;
    })
    .join(glue);
};

/**
 * Gets a human-readable list of the label of the answers to questions in a
 * response set, as an array.
 */
export const responsesAsArray = (
  responseSet: ResponseSetWithResponses,
  questions: QuestionWithAnswers[]
) => {
  return responseSet.responses.map((response) => {
    const question = questions.find(
      (question) => question.id === response.questionId
    );

    const answers = question.answers.filter((answer) => {
      return response.answerIds?.includes(answer.id);
    });

    const includeShortName =
      question.inputType === "ScaleChoice" ? `${question.shortName} = ` : "";

    return answers.length
      ? `${includeShortName}${answers
          .map((answer) => answer.mainText)
          .join(" | ")}`
      : "Skip";
  });
};

/**
 * Gets tracking information about question history, either as a list of IDs or
 * as a list of question text.
 */
export const questionHistoryTracking = (
  responseSet: ResponseSetWithResponses,
  questions: QuestionWithAnswers[],
  type: "ids" | "text"
) => {
  return responseSet.responses.map((response) => {
    if (type === "ids") {
      return response.questionId;
    } else if (type === "text") {
      const question = questions.find(
        (question) => question.id === response.questionId
      );

      return question.mainText;
    }
  });
};

/**
 * Gets history about the response set to send to Mixpanel.
 */
export const getHistoryTracking = (
  responseSet: ResponseSetWithResponses,
  questions: QuestionWithAnswers[]
) => {
  if (!responseSet || !questions) {
    return {
      all_responses: [],
      question_history_ids: [],
      question_history_text: [],
    };
  }
  const track = {
    all_responses: responsesAsArray(responseSet, questions),
    question_history_ids: questionHistoryTracking(
      responseSet,
      questions,
      "ids"
    ),
    question_history_text: questionHistoryTracking(
      responseSet,
      questions,
      "text"
    ),
  };

  return track;
};
