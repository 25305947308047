import React from "react";
import { PanelLayout } from "@components/product/summary/common";
import ProductBatteryComparison from "./product-battery-comparison";
import ProductCostComparison from "./product-cost-comparison";
import ProductScoreComparison from "./product-score-comparison";
import {
  MicrophoneIcon,
  MusicalNoteIcon,
  PhoneIcon,
  SpeakerXMarkIcon,
  StarIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { ComparisonSummary } from "../compare-components";
import { ticksByForm } from "@components/recommendation/classified-attribute-display/headphones-attribute-display";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import VsPageCtaCard from "../vs-page-cta-card";
import { Product } from "@lib/model/product";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import SquareAdUnit from "@components/ads/square-ad-unit";

const HeadphonesComparisonDisplay = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
  keySpecs?: boolean;
  compareResults: any;
  displayDiffs: boolean;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  // There is a different scale for headphone and earbuds batteries. If we're
  // comparing one to the other, use the larger (headphones) scale.
  const formFactor = props.products
    .map((product) => product.attributes?.["formFactor"]?.value)
    .includes("Headphones")
    ? "Headphones"
    : "Earbuds";

  const batteryScaleMax = parseFloat(
    ticksByForm[formFactor][ticksByForm[formFactor].length - 1]
  );

  return (
    <PanelLayout>
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="musicScoreRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="gamingScoreRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="callScoreRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="podcastScoreRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="tvScoreRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="noiseIsolationQualityRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductBatteryComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        attributeName="batteryLife"
        ticks={ticksByForm[formFactor]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductCostComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        maxBudgetCents={40000}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute="buildQualityRescaled"
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      {props.keySpecs && props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {!props.keySpecs && !props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {/* {props.keySpecs && props.displayDiffs && <SquareAdUnit />}
      {!props.keySpecs && !props.displayDiffs && <SquareAdUnit />} */}
    </PanelLayout>
  );
};

export const HeadphonesComparisonSummary = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
}) => {
  return (
    <ComparisonSummary
      attributes={{
        exact: [
          "soundQualityRescaled",
          "noiseIsolationQualityRescaled",
          "callScoreRescaled",
        ],
        fuzzy: ["musicScoreRescaled", "tvScoreRescaled", "gamingScoreRescaled"],
      }}
      products={props.products}
    />
  );
};

export default HeadphonesComparisonDisplay;
