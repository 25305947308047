import DonePanel from "@components/recommendation/done-panel";
import {
  getProductImage,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { ArrowsPointingInIcon } from "@heroicons/react/24/solid";
import { SummaryTitles } from "../../../product/summary/common";
import { Product } from "@lib/model/product";
import { PhoneSizeComparisonGraphic } from "@components/svgs/phone-size-comparison-graphic";
import { ClassificationName } from "@lib/model/attribute";
import { useAttribute } from "@lib/hooks/use-attributes";

function getClassificationSize(size: number) {
  if (size < 6) {
    return ClassificationName.Small;
  }
  if (size < 6.5) {
    return ClassificationName.Medium;
  }
  return ClassificationName.Large;
}

/**
 * A summary of this phone's battery life, plotted on a line graph.
 */
const PhoneSizeComparison = (props: {
  productA: Product;
  productB: Product;
  keySpecs?: boolean;
  compareResults: any;
}) => {
  const { productA, productB, compareResults } = props;
  const attributeConfiguration = useAttribute("screenSize");

  if (!productA || !productB) {
    return null;
  }
  const productAName = getProductLabels(productA).shortLabel;
  const productBName = getProductLabels(productB).shortLabel;
  const valueA = productA.attributes["screenSize"].value;
  const valueB = productB.attributes["screenSize"].value;
  const first = (
    <>
      The {productAName} has a screen size of <strong>{valueA}</strong> inches
      and the {productBName} is <strong>{valueB}</strong> inches.
    </>
  );
  const title = "Screen Size";
  const classSizeA = getClassificationSize(
    productA.attributes["screenSize"].value
  );
  const classSizeB = getClassificationSize(
    productB.attributes["screenSize"].value
  );

  const { winner } = compareResults["screenSize"];

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={title}
      icon={({ className }) => <ArrowsPointingInIcon className={className} />}
      className={"!bg-white"}
      modal={{
        title: attributeConfiguration.displayName,
        text: attributeConfiguration.explanation,
      }}
    >
      <SummaryTitles {...{ first }} />
      <PhoneSizeComparisonGraphic
        className="h-48 mx-auto mt-2"
        highlightSizes={[
          {
            size: classSizeA,
            image: getProductImage(productA, { width: 24 }),
          },
          {
            size: classSizeB,
            image: getProductImage(productB, { width: 24 }),
          },
        ]}
      />
    </DonePanel>
  );
};

export default PhoneSizeComparison;
