interface FortySixtySectionProps {
  children?: React.ReactNode;
  dark?: boolean;
  className?: string;
}

const FortySixtySection = (props: FortySixtySectionProps) => {
  const darkClasses = props.dark ? "bg-gray-900 text-gray-400" : "";
  return (
    <div
      className={`sm:grid md:grid-cols-[37%_58.75%] gap-x-12 ${
        darkClasses ? darkClasses : ""
      } ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
};

export default FortySixtySection;
