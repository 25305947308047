import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import Review from "@lib/model/review";
import { displayDate } from "@lib/utilities/shared-utilities";

/**
 * Renders a single review snippet and metadata with a link to the review.
 */
const ReviewCard = ({
  review,
  className = "",
}: {
  review: Review;
  className?;
}) => {
  if (!review) {
    return null;
  }

  const date = getReviewDate(review);

  return (
    <div
      className={`bg-white border hover:bg-gray-50 border-keyline-1 p-4 rounded-lg ${className}`}
      data-testid="product-review"
    >
      <a
        className="flex justify-between"
        href={review.url}
        target="_blank"
        rel="noreferrer"
      >
        <h3 className="font-sans text-lg my-0">{review.siteName}</h3>
        <ArrowSmallRightIcon className="h-5 w-4" />
      </a>
      <div className="text-xs text-gray-500 font-semibold mb-3">
        {review.reviewerName}{" "}
        {date && (
          <>
            | <span className="italic">{date}</span>
          </>
        )}
      </div>
      <div className="text-xs text-gray-500">
        &quot;{review.snippet.trim()}&quot;
      </div>
    </div>
  );
};

/**
 * Gets the date for a review. Reviews sometimes only have a year.
 */
const getReviewDate = (review) => {
  if (review.date) {
    const dateBuilder = displayDate(review.date);
    if (review.showMonth) {
      return dateBuilder.toMonth();
    } else {
      return dateBuilder.toYear();
    }
  }
};

export default ReviewCard;
