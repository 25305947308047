import React, { useContext, useState } from "react";
import Session from "@lib/model/session";
import {
  getSessionStorage,
  setSessionStorage,
} from "@lib/utilities/client-utilities";
import { withErrorBoundary } from "react-error-boundary";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import errorTracker from "@lib/tracking/error-tracker";

export type SessionContextType = {
  session: Session;
  initSession: (session: Session) => void;
};

export const SessionContext = React.createContext<SessionContextType>(null);

function fallbackRender({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  /**
   * We only want to handle errors accessing session storage
   * errors here and just rethrow everything else.
   **/
  if (!["SecurityError", "QuotaExceededError"].includes(error.name)) {
    throw error;
  }
  errorTracker.track(error);
  eventTracker.track(Events.SessionStorageError, {
    error_message: error.toString(),
  });

  return (
    <div role="alert" className="flex flex-col items-center justify-center">
      <p className="text-2xl">
        <strong>Please adjust your browser settings</strong>
      </p>
      <p className="text-lg max-w-lg">
        PerfectRec needs session storage to work. Please enable this feature to
        use PerfectRec. (You might need to turn off &ldquo;Block All
        Cookies&rdquo; in settings.)
      </p>
    </div>
  );
}

const SessionContextProvider = withErrorBoundary(
  ({ children }) => {
    const [session, setSession] = useState<Session>(
      getSessionStorage("session")
    );
    const initSession = (session: Session) => {
      setSession(session);
      setSessionStorage("session", session);
    };

    return (
      <SessionContext.Provider value={{ session, initSession }}>
        {children}
      </SessionContext.Provider>
    );
  },
  { fallbackRender: fallbackRender }
);

export default SessionContextProvider;
