import { OptionGroup, Option } from "../../../shared/src/model/form";
import React from "react";

const SelectSingle = ({
  optionGroups,
  options,
  defaultValue,
  className = "",
  style,
  // @TODO get the right type for this event.
  onChange,
  emptySelect = "Select",
}: {
  optionGroups?: OptionGroup[];
  options?: Option[];
  defaultValue?: string;
  className?: string;
  style?: "gray" | "blue";
  // @TODO get the right type for this event.
  onChange?: (e: any) => void;
  emptySelect?: string;
}) => {
  const styles = {
    gray: "border-gray-300",
    blue: "bg-blue-shade-1 border-none font-bold",
  };

  return (
    <select
      value={defaultValue}
      onChange={onChange}
      className={`block rounded-md ${styles[style]} py-2 pl-1.5 md:pl-3 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 font-sans truncate ${className}`}
    >
      {optionGroups ? (
        <OptionGroups groups={optionGroups} emptySelect={emptySelect} />
      ) : (
        <Options options={options} />
      )}
    </select>
  );
};

const OptionGroups = (props: {
  groups: OptionGroup[];
  emptySelect: string;
}) => {
  return (
    <>
      {props.emptySelect && (
        <option disabled value="_empty">
          {props.emptySelect}
        </option>
      )}
      {props.groups.map((group) => (
        <optgroup label={group.label} key={group.label}>
          {group.options.map((option) => (
            <React.Fragment key={option.value}>
              <option
                value={option.value}
                key={option.value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
              {option?.options?.length &&
                option.options.map((childOption) => (
                  <option value={childOption.value} key={childOption.value}>
                    -- {childOption.label}
                  </option>
                ))}
            </React.Fragment>
          ))}
        </optgroup>
      ))}
    </>
  );
};

const Options = (props: { options: Option[] }) => {
  return (
    <>
      {props.options.map((option) => (
        <option
          value={option.value}
          key={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </option>
      ))}
    </>
  );
};

export default SelectSingle;
