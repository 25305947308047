import React from "react";
import { PanelLayout } from "@components/product/summary/common";
import ProductBatteryComparison from "./product-battery-comparison";
import ProductCostComparison from "./product-cost-comparison";
import ProductScoreComparison from "./product-score-comparison";
import { laptopContentPhraseMap } from "@components/product/summary/laptop-usage-meter";
import LaptopPortabilityComparison from "./laptops/laptop-portability-comparison";
import { ComparisonSummary } from "../compare-components";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import VsPageCtaCard from "../vs-page-cta-card";
import LaptopPerformanceComparison from "./laptops/laptop-performance-comparison";
import LaptopDisplayComparison from "./laptops/laptop-display-comparison";
import { PromptType, StoredLlmOutput } from "@lib/model/llm";
import SquareAdUnit from "@components/ads/square-ad-unit";

const LaptopComparisonDisplay = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
  keySpecs?: boolean;
  compareResults: any;
  displayDiffs: boolean;
  comparisons?: Record<PromptType, StoredLlmOutput>;
}) => {
  return (
    <PanelLayout>
      {Object.keys(laptopContentPhraseMap).map((usageAnswerName) => (
        <ProductScoreComparison
          key={usageAnswerName}
          productA={props?.products?.[0]}
          productB={props?.products?.[1]}
          usageAttribute={usageAnswerName}
          keySpecs={props.keySpecs}
          compareResults={props.compareResults}
          comparisons={props.comparisons}
        />
      ))}
      <LaptopDisplayComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
        comparisons={props.comparisons}
      />
      <ProductBatteryComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        attributeName="batteryLifeHours"
        ticks={["0", "5", "10", "15", "20"]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <LaptopPortabilityComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductScoreComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        usageAttribute={"buildQuality"}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      <ProductCostComparison
        productA={props?.products?.[0]}
        productB={props?.products?.[1]}
        maxBudgetCents={400000}
        keySpecs={props.keySpecs}
        compareResults={props.compareResults}
      />
      {props.keySpecs && props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {!props.keySpecs && !props.displayDiffs && (
        <VsPageCtaCard productCategory={props.categoryConfig} />
      )}
      {/* {props.keySpecs && props.displayDiffs && <SquareAdUnit />}
      {!props.keySpecs && !props.displayDiffs && <SquareAdUnit />} */}
    </PanelLayout>
  );
};

export const LaptopComparisonSummary = (props: {
  products;
  categoryConfig: ProductCategoryConfig;
}) => {
  return (
    <ComparisonSummary
      attributes={{
        exact: ["batteryLifeHours", "mobilityScore", "gamingScore"],
        fuzzy: [
          "keyboardQuality",
          "creativeUseScore",
          "generalUseScore",
          "softwareScore",
          "contentScore",
        ],
      }}
      products={props.products}
    />
  );
};

export default LaptopComparisonDisplay;
