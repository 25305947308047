import { useContext, useEffect, useMemo } from "react";
import { Product } from "../../../../shared/src/model/product";
import { ProductCategoryContext } from "contexts/product-category-context";
import { CategoryApiClient } from "@lib/category-api-client";
import { useAttributes } from "../use-attributes";
import {
  addAttributeMap,
  getCatalogProducts,
} from "@lib/utilities/shared-utilities";
import useSWRImmutable from "swr/immutable";
import { errorNotificationSubject } from "@lib/events";
import {
  getProductOptions,
  getProductOptionsWithVariants,
} from "@lib/utilities/rec-ui";
import useGlobalAllProductsCache from "./store";

const getVariantDisplayLabel = (label, variantLabel, categoryName): string => {
  if (categoryName === "laptops") {
    if (label.includes("Apple") && !variantLabel.includes("Intel")) {
      const specs = variantLabel.split(",");
      const socSubStrings = specs[0].split(" (");
      const socName = socSubStrings[0];
      const cpuCores =
        socSubStrings.length > 1 ? socSubStrings[1].replace(")", " CPU; ") : "";
      const gpuSubStrings = specs[1].split(" (");
      const gpuCores =
        gpuSubStrings.length > 1 ? gpuSubStrings[1].replace(") ", " GPU") : "";
      return `${socName} (${cpuCores}${gpuCores}), ${specs.slice(2).join(",")}`;
    } else if (
      variantLabel.split(",").length > 1 &&
      variantLabel.split(",")[1].includes(variantLabel.split(",")[0])
    ) {
      const specs = variantLabel.split(",");
      specs[1] = specs[1].replace(specs[0], "");
      return specs.join(",");
    } else {
      return variantLabel;
    }
  } else {
    return variantLabel;
  }
};

export const useGlobalAllProducts = () => {
  const { attributes } = useAttributes();

  const { apiDomain, productCategoryConfig } = useContext(
    ProductCategoryContext
  );

  const [productsCache, productsCacheActions] = useGlobalAllProductsCache(
    (state) => state.cache,
    (actions) => actions.set
  );

  const key = `${productCategoryConfig.name}/product/all`;
  const path = "product/all";

  const { data, error } = useSWRImmutable<Product[]>(
    key,
    async () =>
      await CategoryApiClient.withContext({
        productCategoryConfig,
        apiDomain,
        table: "product",
      }).fetch(path)
  );

  useEffect(() => {
    if (productsCache.has(key)) {
      return;
    }
    if (error) {
      errorNotificationSubject.next({
        ts: Date.now(),
        text: "There was a problem loading the information needed.",
        error,
      });
    }

    const rawProducts = (() => {
      return data && attributes
        ? data.map((apiProduct) => addAttributeMap(apiProduct))
        : null;
    })();

    const catalogProducts = (() => {
      return rawProducts
        ? getCatalogProducts(rawProducts, productCategoryConfig, window)
        : null;
    })();

    const productVariants = (() => {
      const ProductOptionsWithVariants =
        catalogProducts &&
        (productCategoryConfig.hasVariants
          ? getProductOptionsWithVariants(
              catalogProducts,
              productCategoryConfig.name,
              attributes,
              4,
              window
            )
          : getProductOptions(catalogProducts, productCategoryConfig.name));

      //restructuring for cleaner implementation.
      return (
        ProductOptionsWithVariants &&
        ProductOptionsWithVariants.reduce((acc, brand) => {
          brand.options.forEach((option) => {
            const subOptions = [];
            if (option.options) {
              //logic to pick default variant
              for (const subOption of option.options) {
                subOptions.push({
                  label: option.label,
                  variantLabel: getVariantDisplayLabel(
                    option.label,
                    subOption.label,
                    productCategoryConfig.name
                  ),
                  id: subOption.value,
                  defaultVariant: subOption.isCanonical,
                });
              }
              acc.push(...subOptions);
            } else {
              acc.push({
                label: option.label,
                id: option.value,
                defaultVariant: true,
              });
            }
          });
          return acc;
        }, []).sort((productA, productB) => {
          const shortNameA = productA.label;
          const shortNameB = productB.label;

          if (shortNameA < shortNameB) {
            return -1;
          } else if (shortNameA > shortNameB) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    })();

    const response = {
      // See getCatalogProducts() for an explanation.
      products: catalogProducts,

      // rawProducts contains all products found in the spreadsheet. It should only
      // be used for times when we have to look up product IDs that the model has
      // provided. It should not be used to display lists of products to the user.
      rawProducts,

      //Used in variant select input only
      productVariants,
    };
    if (response.products && response.products?.length > 0) {
      productsCacheActions(key, response);
    }
  }, [data, error, productsCache]);

  if (productsCache.has(key)) {
    return productsCache.get(key);
  }

  return {
    products: null,
    rawProducts: null,
    productVariants: null,
  };
};
