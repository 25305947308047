import ArticleList from "@components/common/article-list";
import { IScenario } from "@lib/model/contentful";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { getScenarioUrl } from "@lib/utilities/shared-utilities";
import { getScenarioTitle } from "./scenario-page";

const ScenariosList = ({
  scenarios,
  category,
}: {
  scenarios: IScenario[];
  category: ProductCategoryConfig;
}) => {
  return (
    <ArticleList
      className="my-8"
      items={scenarios.map((scenario) => ({
        key: scenario.sys.id,
        title: getScenarioTitle(scenario.fields, category),
        url: getScenarioUrl(category, scenario.fields.slug),
        author: scenario.fields.author?.fields?.name,
      }))}
    />
  );
};

export default ScenariosList;
