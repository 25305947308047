import HeatMeter from "../../product/summary/heat-meter";
import DonePanel from "@components/recommendation/done-panel";
import {
  AssetType,
  centsToDollars,
  getImageUrl,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { SummaryTitles } from "../../product/summary/common";
import { Product } from "@lib/model/product";
import { usePurchaseData } from "@lib/hooks/use-purchase-data";
import * as d3 from "d3";
import BlankTextPlaceholder from "@components/common/blank-text-placeholder";
import src from "@tailwindcss/forms";
import ImageWithFallback from "@components/common/image-with-fallback";
import { ProductCategoryContext } from "contexts/product-category-context";
import { useContext } from "react";

/**
 * A summary of this phone's camera quality, plotted on a line graph.
 */
const ProductCostComparison = (props: {
  productA: Product;
  productB: Product;
  maxBudgetCents: number;
  keySpecs?: boolean;
  compareResults: any;
}) => {
  const { productA, productB, maxBudgetCents } = props;

  const { productCategoryConfig } = useContext(ProductCategoryContext);

  const priceA = productA.bestPrice;
  const priceB = productB.bestPrice;

  // If max budget not provided, derive a range based on the products.
  const { priceMin, priceMax } = getCostRange(priceA, priceB, maxBudgetCents);

  if (!productA || !productB) {
    return (
      <BlankTextPlaceholder
        textForSizing={
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        }
      />
    );
  }
  const productAName = getProductLabels(productA).shortLabel;
  const productBName = getProductLabels(productB).shortLabel;
  const first = (
    <>
      The {productAName} has a price of{" "}
      <strong>{centsToDollars(priceA)}</strong> and the {productBName} costs{" "}
      <strong>{centsToDollars(priceB)}</strong>.
    </>
  );

  const tickInterval = d3.nice(priceMin, priceMax, 5);
  const scaleMin = tickInterval[0];
  const scaleMax = tickInterval[1];

  const { winner, pctA, pctB, ticks } = props.compareResults["productPrice"];

  if (!winner) {
    return null;
  }

  const formattedTicks = ticks.map((value) =>
    (value / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    })
  );

  //logic to determine in which section it will be displayed
  if (props.keySpecs && winner === "tie") {
    return null;
  }

  if (!props.keySpecs && winner !== "tie") {
    return null;
  }

  return (
    <DonePanel
      title={"Cost"}
      icon={({ className }) => <CurrencyDollarIcon className={className} />}
      className={"!bg-white"}
    >
      <div className="flex flex-row justify-between mt-2">
        <div className="flex flex-row justify-center items-center gap-6">
          <div className="font-serif text-2xl font-bold">
            {centsToDollars(priceA)}
          </div>
          <div className="flex flex-col justify-center items-center ">
            <div className="relative flex justify-center w-12 h-12">
              <ImageWithFallback
                src={getImageUrl(
                  productA.image,
                  AssetType.ProductImage,
                  { width: 150 },
                  productCategoryConfig.name
                )}
                alt={productA.model}
                className={`object-contain`}
              />
            </div>
            <div className="h-[2px w-[50px] border-b-[2px] mt-2 border-accent-purple" />
          </div>
        </div>
        <div className="flex flex-row justify-center items-center gap-6">
          <div className="flex flex-col justify-center items-center">
            <div className="relative flex justify-center w-12 h-12">
              <ImageWithFallback
                src={getImageUrl(
                  productB.image,
                  AssetType.ProductImage,
                  { width: 150 },
                  productCategoryConfig.name
                )}
                alt={productB.model}
                className={`object-contain`}
              />
            </div>
            <div className="h-[2px] w-[50px] border-b-[2px] mt-2 border-accent-orange" />
          </div>
          <div className="font-serif text-2xl font-bold">
            {centsToDollars(priceB)}
          </div>
        </div>
      </div>
      <HeatMeter
        ticks={formattedTicks}
        pctA={pctA}
        pctB={pctB}
        direction="greenToRed"
        winner={winner}
        products={[productA, productB]}
      />

      <div className="mt-4">
        <SummaryTitles {...{ first }} />
      </div>
    </DonePanel>
  );
};

const pctBetween = (lowerExtreme, upperExtreme, givenValue) =>
  (givenValue - lowerExtreme) / (upperExtreme - lowerExtreme);

/**
 * Gets min/max cost range for a graph, based on two prices. Rounds to a sensible
 * interval
 */
const getCostRange = (
  priceA: number,
  priceB: number,
  maxCategoryPrice: number,
  roundInterval = 5000
) => {
  const minPrice = Math.min(priceA, priceB);
  const maxPrice = Math.max(priceA, priceB);

  // Min extreme: 50% of the lower priced item
  const priceExtremeMin = minPrice / 2;

  // Max extreme: Whichever is lowest: the most expensive product in the category,
  // or 150% of the most expensive product being compared.
  const priceExtremeMax = Math.min(maxCategoryPrice, maxPrice * 1.5);

  return {
    priceMin: roundToNearest(priceExtremeMin, roundInterval),
    priceMax: roundToNearest(priceExtremeMax, roundInterval),
  };
};

const roundToNearest = (num, nearest) => Math.round(num / nearest) * nearest;

export default ProductCostComparison;
