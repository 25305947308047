import { MultiResponse } from "@lib/hooks/use-multi-response";

// @TODO let's move short attribute name into the spreadsheet.
export const TvContentPhraseMap = {
  movieScore: {
    verb: "watch movies & cinematic TV",
    subjectPlural: "Movies & Cinematic TV",
    shortAttributeName: "Movies score",
  },
  sportsScore: {
    verb: "watch sports",
    subjectPlural: "Sports",
    shortAttributeName: "Sports score",
  },
  gamingScore: {
    verb: "play video games",
    subjectPlural: "Video Games",
    shortAttributeName: "Gaming score",
  },
  animationScore: {
    verb: "watch cartoons & animation",
    subjectPlural: "Cartoons & Animation",
    shortAttributeName: "Animation score",
  },
  newsScore: {
    verb: "watch news, talk, & other TV",
    subjectPlural: "News, Talk, & Other TV",
    shortAttributeName: "News/talk score",
  },
};

export const getContentWatched = (
  attributeName: string,
  multiResponse: MultiResponse[]
) => {
  const contentAnswerIdMap = {
    movieScore: "e957a026-e312-4dc1-ab19-e360ef3227c7",
    sportsScore: "fd1355bf-66ba-44e1-9997-4a277f6efb1a",
    gamingScore: "7efbcb67-dac0-4761-95b7-6dd0bf70e660",
    animationScore: "31a3215f-6199-4001-9e74-270d2b6a2e9d",
    newsScore: "43eccfb2-e90a-4623-be19-ccfc7ee208be",
  };
  return multiResponse.find((filter) => {
    return filter.answerId === contentAnswerIdMap[attributeName];
  });
};
