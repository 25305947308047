import { Product } from "@lib/model/product";
import { SummaryTitles } from "./common";
import DonePanel, {
  getMeetsNeedsColor,
} from "@components/recommendation/done-panel";
import {
  centsToDollars,
  getProductLabels,
} from "@lib/utilities/shared-utilities";
import { getTicksForRange } from "./heat-meter";
import { useQuestion } from "@lib/hooks/use-questions";
import { useResponseAnswers } from "@lib/hooks/use-multi-response";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { SingleRecExplanationIconColors } from "@lib/constants/rec-explanation-icons";
import { useBudgetExplanation } from "@lib/hooks/use-budget-explanation";
import useProductsMaxPrice from "@lib/hooks/use-max-price-product";
import LineScoreMeter from "./line-score-meter";

const BudgetMeter = ({
  product,
  budgetQuestionId,
}: {
  product: Product;
  budgetQuestionId: string;
}) => {
  const productName = getProductLabels(product).shortLabel;
  const displayPriceNumeric = product.bestPrice;
  const displayPrice = centsToDollars(product.bestPrice);
  const { answer, isSkip, responded } = useResponseAnswers(budgetQuestionId);
  const budgetQuestion = useQuestion(budgetQuestionId);
  const { maxPriceRounded: scaleMax } = useProductsMaxPrice();

  const budgetMax =
    !isSkip && answer.displayMax
      ? (answer.metadata["range"][1] as number)
      : null;

  const { phrase: budgetPhrase, didUserChooseMaxBudget } = useBudgetExplanation(
    {
      product,
      maxBudgetOverride: budgetMax,
    }
  );

  if (!displayPriceNumeric) {
    return null;
  }

  const ticks = getTicksForRange(0, scaleMax / 100, 5).map(
    (tick) => `$${tick}`
  );

  const color = getMeetsNeedsColor(
    budgetMax ? displayPriceNumeric < budgetMax : true
  );

  return (
    <DonePanel
      color={isSkip ? SingleRecExplanationIconColors.Green : color}
      title={"Cost"}
      icon={CurrencyDollarIcon}
      modal={{
        title: budgetQuestion.shortName || budgetQuestion.mainText,
        text: budgetQuestion.explanation,
      }}
    >
      {!isSkip && budgetMax && budgetPhrase != null && (
        <div className="font-serif mt-4">
          <span className="font-semibold text-3xl mr-1">
            {budgetPhrase.split(" ")[0]}
          </span>
          <span className="text-2xl ml-1">
            {budgetPhrase.split(" ").slice(1).join(" ")}
          </span>
        </div>
      )}
      <div className="my-2">
        <LineScoreMeter
          percent={displayPriceNumeric / scaleMax}
          color={color}
          maxPercent={budgetMax / scaleMax}
          maxPhrase={"Max budget"}
          ticks={ticks}
        />
      </div>
      <SummaryTitles
        first={
          !isSkip && budgetPhrase != null ? (
            <>
              At {displayPrice}, the <strong>{productName}</strong> is{" "}
              {budgetPhrase.toLowerCase()}.
            </>
          ) : (
            <>
              The <strong>{productName}</strong> costs {displayPrice}
            </>
          )
        }
        second={
          !isSkip && responded && !didUserChooseMaxBudget
            ? `You said your budget was ${answer.mainText.toLowerCase()}`
            : null
        }
        showSecondOnly={true}
      />
    </DonePanel>
  );
};

export default BudgetMeter;
