import md5 from "md5";
import { mergeAll } from "ramda";
import { errorNotificationSubject } from "@lib/events";
import { addAttributeMap } from "@lib/utilities/shared-utilities";
import Response from "@lib/model/response";
import { QuestionWithAnswers } from "@lib/model/question";
import { ModelRequest, ModelResponse } from "@lib/model/recommender-model";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import { Product } from "@lib/model/product";
import { isInternalUser } from "@lib/utilities/global-utilities";
import { isProduction } from "@lib/utilities/client-utilities";

import * as Cache from "@server/cache";

const CategoryApiFetcher = ({ categoryName, apiDomain, version }) => {
  return {
    fetch: (uri, opts) => {
      const headers = {
        "x-category-name": categoryName,
        "x-version": version,
      };

      const allHeaders = { ...headers, ...opts.headers };

      return fetch(`${apiDomain}/${uri}`, {
        ...opts,
        ...{ headers: allHeaders },
      })
        .then(async (res) => {
          return res.json();
        })
        .catch((e) => console.error(e));
    },
  };
};

const getAllProductsCacheKey = ({ categoryName, apiDomain, version }) =>
  `all-products-${categoryName}-${version}-${apiDomain}`;

const ProductFetcher = ({ categoryName, apiDomain, version }) => {
  const categoryApiFetcher = CategoryApiFetcher({
    categoryName,
    apiDomain,
    version,
  });

  const allProductsCacheKey = getAllProductsCacheKey({
    categoryName,
    apiDomain,
    version,
  });
  return {
    fetch: (productId) => {
      // retrieve from cache if possible
      const cachedProducts = Cache.get(allProductsCacheKey);
      if (cachedProducts) {
        const cachedProduct = cachedProducts.find((p) => p.id === productId);
        if (cachedProduct) return cachedProduct;
      }

      return categoryApiFetcher.fetch(`product?id=${productId}&idName=id`, {});
    },
    fetchAll: async () => {
      const allProductTTL = 5 * 60 * 1000; // 5 minutes

      const cachedProducts = Cache.get(allProductsCacheKey);
      if (cachedProducts) return cachedProducts;

      const products = await categoryApiFetcher.fetch(`product/all`, {});
      await Cache.set(allProductsCacheKey, products, allProductTTL);

      return products;
    },
  };
};

const PurchaseDataFetcher = ({ categoryName, apiDomain, version }) => {
  const categoryApiFetcher = CategoryApiFetcher({
    categoryName,
    apiDomain,
    version,
  });
  return {
    fetch: (productId) => {
      return categoryApiFetcher.fetch(
        `product/purchaseData?id=${productId}&idName=productId`,
        {}
      );
    },
  };
};

/**
 * Fetches product information from the api, and gives you an interface to the old
 * product model, that allows us to configure and classify the product and
 * its attributes.
 *
 *
 * @param ctx
 * @param attributeConfiguration
 * @param dataVersions
 * @constructor
 */
export const ApiProductFetcher = (
  productCategoryConfig: ProductCategoryConfig,
  apiDomain: string
) => {
  const { dataVersions } = productCategoryConfig;

  const apiConfig = {
    categoryName: productCategoryConfig.name,
    apiDomain,
  };

  const productFetcher = ProductFetcher({
    ...apiConfig,
    ...{ version: dataVersions.product },
  });

  const purchaseDataFetcher = PurchaseDataFetcher({
    ...apiConfig,
    ...{ version: dataVersions.purchaseData },
  });

  console.log(apiConfig);

  return {
    fetch: async (productId): Promise<Product> => {
      const [product, purchaseData] = await Promise.all([
        productFetcher.fetch(productId),
        purchaseDataFetcher.fetch(productId),
      ]);

      return {
        ...addAttributeMap(product),
        ...purchaseData,
      };
    },
    fetchAll: async (): Promise<Product[]> => {
      const products = await productFetcher.fetchAll();

      return products.map((product) => addAttributeMap(product));
    },
  };
};

/**
 * Fetches recommendations.
 *
 * Eventually add strong types out of here.
 *
 * @param ctx
 * @param attributeConfiguration
 * @constructor
 */
export const ApiRecommendationsFetcher = (
  apiDomain = process.env.NEXT_PUBLIC_API_URL
) => {
  return {
    fetch: async (modelRequest: ModelRequest): Promise<ModelResponse> => {
      const body = JSON.stringify(modelRequest);
      const inferenceId = md5(body);
      return fetch(
        `${apiDomain}/recommendations/${modelRequest.metadata.mlEndpoint}`,
        {
          headers: {
            "x-category-name": modelRequest.metadata.categoryName,
            "x-inference-id": inferenceId,
            "content-type": "application/json",
          },
          method: "POST",
          body: body,
        }
      ).then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        const error = await res.text();
        console.error("Error response from recommender", error);

        return { error };
      });
    },
  };
};

/**
 * Fetches recommendations and the resulting products from the api, this will eventually be pushed up into api
 * gateway for shared business logic / caching, etc.
 *
 * Eventually add strong types out of here.
 *
 * @param productCategoryCtx
 * @param attributeConfiguration
 * @constructor
 */
export const ApiRecommendedProductsFetcher = (
  productCategoryConfig: ProductCategoryConfig,
  apiDomain: string
) => {
  return {
    fetch: async (
      modelRequest: ModelRequest
    ): Promise<{
      modelResult: ModelResponse;
    }> => {
      const debug = localStorage?.getItem("debugModelExchange") === "true";
      if (debug) {
        console.log(`Model payload`, modelRequest);
      }
      const modelResult = await ApiRecommendationsFetcher(apiDomain).fetch(
        modelRequest
      );
      if (debug) {
        console.log(`Model response`, modelResult);
      }

      return { modelResult: modelResult };
    },
  };
};

export const handleRecsError = (e) => {
  errorNotificationSubject.next({
    ts: Date.now(),
    text: "There was an error submitting your response.",
    error: e,
  });

  return e;
};

export const mlRequestBuilder = (
  categoryName,
  mlEndpoint,
  allQuestions: QuestionWithAnswers[]
) => {
  const build = (responses: Response[]) => {
    const userResponses = mergeAll(
      responses.map((r) => {
        return { [r.questionId]: r.answerIds };
      })
    );

    return {
      metadata: {
        categoryName: categoryName,
        mlEndpoint: mlEndpoint,
        createdAt: new Date().toISOString(),
        internal: isInternalUser() || !isProduction(),
      },
      responses: allQuestions.map((q) => {
        return { questionId: q.id, answerIds: userResponses[q.id] || [] };
      }),
      filters: [],
      // @TODO - Get rid of this requirement on the model side.
      rejectedProductIds: [],
    };
  };

  const addResponse = (responses: Response[], { questionId, answerIds }) => {
    const currentMlRequest = build(responses);

    const currentResponses = currentMlRequest.responses.filter(
      (r) => r.questionId !== questionId
    );

    return {
      ...currentMlRequest,
      ...{
        responses: [
          ...currentResponses,
          { questionId: questionId, answerIds: answerIds },
        ],
      },
    };
  };

  const removeResponse = (responses, { questionId }) => {
    return addResponse(responses, { questionId, answerIds: [] });
  };

  return {
    build,
    addResponse,
    removeResponse,
  };
};
