import { getClientSafeWindow } from "@lib/utilities/client-utilities";
import { isProduction } from "@lib/utilities/client-utilities";
import Script from "next/script";
import { useEffect, useRef, useState } from "react";

declare global {
  interface Window {
    Tally: any;
  }
}

const FeedbackForm = ({
  formId,
  hiddenFields,
  ready = true,
  height = "182",
  dynamicHeight = "1",
}: {
  formId;
  hiddenFields?: { [key: string]: string };
  ready;
  height?;
  dynamicHeight?;
}) => {
  const windowClone = getClientSafeWindow();

  const [ip, setIp] = useState(null);
  const [country, setCountry] = useState(null);
  const [ipCheckDone, setIpCheckDone] = useState(false);
  const [entryMetadata, setEntryMetadata] = useState<{ [key: string]: string }>(
    null
  );

  // Try to detect IP once.
  useEffect(() => {
    const detectIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json", {
          // @TODO update typescript to get this new type.
          signal: AbortSignal.timeout(5000),
        });
        const payload = (await response.json()) as { ip?: string };

        if (payload.ip) {
          setIp(payload.ip);
          const countryResponse = await fetch(
            `https://api.iplocation.net/?ip=${payload.ip}`,
            {
              signal: AbortSignal.timeout(1000),
            }
          );

          const countryPayload = (await countryResponse.json()) as {
            country_code2?: string;
          };

          if (countryPayload.country_code2) {
            setCountry(countryPayload.country_code2);
          }
        }
      } catch (error) {
        console.log("Unable to detect IP");
      }
      setIpCheckDone(true);
    };

    detectIp();
  }, []);

  // Retrieve entry metadata from session.
  useEffect(() => {
    const entryMetadataStored =
      typeof sessionStorage !== "undefined"
        ? sessionStorage.getItem("entryMetadata")
        : "";

    if (entryMetadataStored) {
      setEntryMetadata(JSON.parse(entryMetadataStored));
    }
  }, [setEntryMetadata]);

  // Load the Tally form when all dependencies are ready.
  useEffect(() => {
    if (windowClone?.Tally && ready && ipCheckDone && entryMetadata) {
      windowClone.Tally.loadEmbeds();
    }
  }, [windowClone?.Tally, ready, ipCheckDone, entryMetadata]);

  hiddenFields = {
    ...hiddenFields,
    ...entryMetadata,
    ip,
    country,
    isProduction: isProduction() ? "Yes" : "No",
    url:
      typeof window !== "undefined" &&
      "https://www.perfectrec.com" + window?.location?.pathname,
  };

  const queryString = hiddenFields
    ? "&" +
      Object.keys(hiddenFields)
        .map((field) => {
          return `${field}=${encodeURIComponent(hiddenFields[field])}`;
        })
        .join("&")
    : "";

  // The point of this is to prevent Tallyform's JS from scrolling us to the iframe.
  // It does this using element.scrollIntoView(), so we override that method of the
  // ref to do nothing.
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView = () => null;
    }
  }, [ref.current]);

  return (
    <>
      <iframe
        data-tally-src={`https://tally.so/embed/${formId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=${dynamicHeight}${queryString}`}
        loading="lazy"
        width="100%"
        height={height}
        title="PerfectRec Feedback"
        ref={ref}
      ></iframe>
      <Script id="tally-js" src="https://tally.so/widgets/embed.js" />
    </>
  );
};

export default FeedbackForm;
