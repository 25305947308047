import { ILandingPageFields } from "@lib/model/contentful";
import Head from "next/head";
import Header from "@components/frame/header";
import { Footer } from "@components/page/footer";
import { renderRichText } from "@components/common/contentful-rich-text";
import TableOfContents from "@components/common/table-of-contents";
import ContentHero from "@components/common/content-hero";
import ContentLayoutWithSidebar from "@components/frame/content-layout-with-sidebar";
import ShareLinks from "@components/common/share-links";
import { ProductCategoryConfigMap } from "@lib/model/product-category-config";
import Meta from "./meta";
import { usePageScrollTracking } from "@lib/hooks/scrollTracking/use-page-scroll-tracking";

interface LandingPageProps {
  page: ILandingPageFields;
  configMap: ProductCategoryConfigMap;
}

const Page = (props: LandingPageProps) => {
  const page = props.page;

  usePageScrollTracking({ page: "Generic Landing Page" });

  return (
    <>
      <Meta
        title={page.title}
        description={page.metaDescription}
        url={`/${page.path}`}
      />
      <Header configMap={props.configMap} />
      <div className="container my-12 max-w-screen-xl">
        <ContentHero imageAsset={page.heroImage} title={page.title}>
          <ShareLinks className="md:hidden" />
        </ContentHero>
        <ContentLayoutWithSidebar>
          <TableOfContents
            selector="#post-body h2, #post-body h3"
            title="In this article"
          >
            <ShareLinks />
          </TableOfContents>
          <div id="post-body">
            {renderRichText(
              page.content,
              true,
              "normal",
              false,
              props.configMap
            )}
          </div>
        </ContentLayoutWithSidebar>
      </div>
      <Footer configMap={props.configMap} />
    </>
  );
};

export default Page;
