import { CSSProperties, useEffect, useRef, useState } from "react";
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
} from "@heroicons/react/24/solid";
import { Product } from "@lib/model/product";
import { getProductImage } from "@lib/utilities/shared-utilities";
import ImageWithFallback from "@components/common/image-with-fallback";

export interface HeatMeterData {
  percent: number;
  product?: Product;
}

export const MeterHeading = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-sm font-normal font-sans text-gray-900 text-center">
    {children}
  </h3>
);

export const getTicksForRange = (start: number, end: number, n: number) => {
  const range = end - start;

  // Size of each interval.
  const l = range / n;

  const ticks = [];
  for (let t = 0; t < n; t++) {
    ticks.push(Math.round(start + t * l));
  }
  ticks.push(end);
  return ticks;
};

/**
 * A single round product marker.
 *
 * Essentially a React version of the markers that we use for the scatterplots
 * in D3/raw-JS.
 */
export const ProductMarker = ({
  pct,
  product,
  barWidth,
  i,
}: {
  pct: number;
  product: Product;
  barWidth: number;
  i: number;
}) => {
  const underlines = {
    0: "border-accent-purple",
    1: "border-accent-orange",
  };

  const position = {
    left: `${barWidth - barWidth * (1 - pct) - 14}px`,
    top: `${-40}px`,
  };

  return (
    <div
      className={`absolute rounded-full bg-white marker-drop-shadow px-[5px] pb-[5px] pt-[3px] w-[26px] h-[26px] border-2 ${underlines[i]}`}
      style={position}
    >
      <ProductIcon product={product} className="h-4 w-4" />
    </div>
  );
};

/**
 * An oblong marker that contains two products.
 */
export const CombinedProductMarker = ({
  pctA,
  pctB,
  barWidth,
  products,
}: {
  pctA: number;
  pctB: number;
  barWidth: number;
  products: Product[];
}) => {
  // Determine which product should go first.
  const [productA, productB] = products;

  const sortedProducts =
    pctA <= pctB ? [productA, productB] : [productA, productB];

  const pctMarker = (pctA + pctB) / 2;

  const position = {
    left: `${barWidth - barWidth * (1 - pctMarker) - 26}px`,
    top: `${-42}px`,
  };

  return (
    <div
      style={position}
      className="absolute rounded-sm flex gap-1 marker-drop-shadow w-[52px] h-[26px] p-1"
    >
      {sortedProducts.map((product, i) => (
        <ProductIcon
          product={product}
          className="w-5 h-[18px]"
          key={product.id + i}
        />
      ))}
    </div>
  );
};

/**
 * A tiny product image.
 */
const ProductIcon = ({
  product,
  className = "",
}: {
  product: Product;
  className?: string;
}) => {
  return (
    <ImageWithFallback
      src={getProductImage(product, { width: 24 })}
      alt="Product image"
      width={24}
      height={24}
      className={`object-contain ${className}`}
    />
  );
};

const HeatMeter = ({
  pctA,
  pctB,
  blissInterval,
  ticks = ["0", "2", "4", "6", "8", "10"],
  poles,
  direction = "redToGreen",
  colorStops = [1.07, 50.58, 102.13],
  winner,
  products,
  singleProductPos,
}: {
  pctA: number;
  pctB?: number;

  // An interval that is considered optimal (green), outside of which the gradient
  // starts to transition to "bad." Overrides colorStops argument, and similarly,
  // units here are percentages.
  blissInterval?: number[];
  ticks?: string[];

  // What to call the extreme ends of the line (e.g. "largest / smallest")
  poles?: string[];
  direction?: "greenToRed" | "redToGreen";
  greenUntilPct?: number;

  // The percentage points where color transitions start. Order is Green > Yellow >
  // Red.
  colorStops?: number[];
  winner?: Product | "tie";
  products?: Product[];

  // Use this property if you need to determine which border a single product
  // marker should get.
  singleProductPos?: number;
}) => {
  const barRef = useRef<HTMLDivElement>();
  const [barWidth, setBarWidth] = useState(null);

  useEffect(() => {
    if (barRef.current) {
      const w = barRef.current.getBoundingClientRect().width - 8;
      if (!barWidth) {
        setBarWidth(w);
      }
    }
  });

  const [productA, productB] = products ? products : [null, null];

  const showMarkers = (pctA || pctB) && (productA || productB);
  const topMarginClass = showMarkers ? "mt-14" : "mt-4";

  return (
    <div ref={barRef} className={topMarginClass}>
      {showMarkers && (
        <div className="relative">
          {winner !== "tie" ? (
            <>
              {productA && (
                <ProductMarker
                  product={productA}
                  pct={pctA}
                  barWidth={barWidth}
                  i={singleProductPos !== undefined ? singleProductPos : 0}
                />
              )}
              {productB && (
                <ProductMarker
                  product={productB}
                  pct={pctB}
                  barWidth={barWidth}
                  i={singleProductPos !== undefined ? singleProductPos : 1}
                />
              )}
            </>
          ) : (
            <CombinedProductMarker {...{ pctA, pctB, barWidth, products }} />
          )}
        </div>
      )}

      <Triangles
        rawPercents={pctB ? [pctA, pctB] : [pctA]}
        barWidth={barWidth}
        winner={winner}
      />
      <AttributeMeter
        barWidth={barWidth}
        direction={direction}
        colorStops={colorStops}
        blissInterval={blissInterval}
      />
      <TickMarks ticks={ticks} barWidth={barWidth} />
      {poles && <Legend poles={poles} />}
    </div>
  );
};

const Triangles = ({
  rawPercents,
  barWidth,
  winner,
}: {
  rawPercents: number[];
  barWidth: number;
  winner: Product | "tie";
}) => {
  // If it is a tie, only show one triangle at the average position.
  const positions =
    winner === "tie" ? [(rawPercents[0] + rawPercents[1]) / 2] : rawPercents;

  return (
    <div style={{ width: barWidth, position: "absolute" }}>
      {positions.map((percentage, index) => (
        <TriangleSVG
          key={index}
          styles={{
            position: "absolute",
            left: `${barWidth - barWidth * (1 - percentage) - 7}px`,
            top: "-10px",
          }}
        />
      ))}
    </div>
  );
};

const TickMarks = (props: { ticks: string[]; barWidth: number }) => {
  const { ticks, barWidth } = props;

  return (
    <div className="pt-2">
      <div
        className={`flex flex-grow-0 justify-between mt-[-8px]`}
        style={{
          width: barWidth,
        }}
      >
        {ticks.map((tick, index) => (
          <div key={tick}>
            <div className={`flex flex-col items-center`}>
              <div className="w-px h-[8px] bg-gray-900" />
            </div>
          </div>
        ))}
      </div>
      <div
        className={`flex flex-grow-0 justify-between`}
        style={{
          width: barWidth + 16,
        }}
      >
        {ticks.map((tick, index) => (
          <div key={tick}>
            <div className={`flex flex-col items-center`}>
              <p className={`text-xs text-gray-900 mr-3`}>{tick}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const Legend = ({
  poles,
  className = "",
}: {
  poles: string[];
  className?: string;
}) => {
  return (
    <div
      className={`flex justify-between text-base font-sans text-gray-900 font-semibold ${className}`}
    >
      <span>
        <ArrowSmallLeftIcon className="w-[9px] inline-block mr-1" />
        {poles[0]}
      </span>

      <span>
        {poles[1]}
        <ArrowSmallRightIcon className="w-[9px] inline-block ml-1" />
      </span>
    </div>
  );
};

/**
 * Gets the color stops that are sufficiently spaced to work visually, and assign
 * them the correct color.
 */
const deriveStops = (
  testStopPct,
  stops: number[],
  colors: string[],
  minGap = 20
) => {
  // Assign yellow first, then red, in case we filter out one of the stops.
  const filteredStops = stops.filter(
    (stop) => Math.abs(testStopPct - stop) >= minGap
  );
  return filteredStops.map((stop, i) => ({
    pct: stop,
    color: filteredStops.length > 1 ? colors[i] : "yellow",
  }));
};

const AttributeMeter = ({
  barWidth,
  direction = "redToGreen",
  blissInterval: b,
  colorStops,
}: {
  barWidth: number;
  direction?: "greenToRed" | "redToGreen";
  blissInterval?: number[];
  colorStops;
}) => {
  const colors = {
    green: "#00A958",
    yellow: "#FFE600",
    red: "#FF0000",
  };

  // If there isn't a bliss point, we use either green-to-red or red-to-green
  // gradients, governed by a 3 member color stop set.
  const bgClasses = {
    greenToRed: `linear-gradient(90deg, ${colors.green} ${colorStops[0]}%, ${colors.yellow} ${colorStops[1]}%, ${colors.red} ${colorStops[2]}%)`,
    redToGreen: `linear-gradient(90deg, ${colors.red} ${colorStops[0]}%, ${colors.yellow} ${colorStops[1]}%,  ${colors.green} ${colorStops[2]}%)`,
  };

  /**
   * Gets the gradient background-css property for a bliss interval.
   *
   * The interval itself should be green, and then the edges grade yellow to red,
   * or red to yellow depending on whether it is above or below the bliss interval.
   *
   * If any color stop is too close to the interval, it will get removed.
   */
  const getBlissGradient = () => {
    const lGap = b[0] / 2;
    const rGap = (100 - b[1]) / 2;
    const lStops = deriveStops(b[0], [0, b[0] - lGap], ["red", "yellow"]);
    const rStops = deriveStops(b[0], [b[1] + rGap, 100], ["yellow", "red"]);

    const bStops = [
      ...lStops,
      { color: "green", pct: b[0] },
      { color: "green", pct: b[1] },
      ...rStops,
    ];

    return (
      "linear-gradient(90deg, " +
      bStops
        .map((stop) => {
          return `${colors[stop.color]} ${stop.pct}%`;
        })
        .join(", ") +
      ")"
    );
  };

  const gradientBarStyles = {
    width: barWidth,
    height: 8,
    background: b ? getBlissGradient() : bgClasses[direction],
    transition: "background-size 0.3s ease",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div>
      <div style={gradientBarStyles}></div>
    </div>
  );
};

export default HeatMeter;

function TriangleSVG(props: { styles: CSSProperties }) {
  return (
    <svg
      style={{ ...props.styles }}
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.38207 7.07851C6.91799 7.57657 6.12889 7.57657 5.6648 7.07851L0.980975 2.0518C0.281727 1.30136 0.813891 0.0781201 1.83961 0.0781202L11.2073 0.0781209C12.233 0.078121 12.7651 1.30136 12.0659 2.0518L7.38207 7.07851Z"
        fill="#111827"
      />
    </svg>
  );
}
