import React, { Fragment, useContext, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { ProductVariant } from "../../../data/packages/model/src/product";
import {
  getInlineVariantDisplayDims,
  getUnitsSuffix,
  getVariantDisplayDims,
} from "@lib/utilities/rec-ui";
import { AttributeConfigurationMap } from "@lib/model/attribute";
import { useAttributes } from "@lib/hooks/use-attributes";
import { Product } from "@lib/model/product";
import PurchaseOption from "@lib/model/purchase-option";
import {
  centsToDollars,
  getCategoryVariantsPhrase,
} from "@lib/utilities/shared-utilities";
import { FeatureUnits } from "@lib/model/enum";
import { useGlobalAllProducts } from "@lib/hooks/global/use-global-all-products";
import { useAllPurchaseData } from "@lib/hooks/use-purchase-data";
import { ProductCategoryContext } from "contexts/product-category-context";
import { StandardCard } from "@components/recommendation/done-page-fixtures";
import { pickBestPurchaseOption } from "@lib/utilities/pick-best-purchase-option";
import BlankTextPlaceholder from "@components/common/blank-text-placeholder";
import PurchaseData from "./purchase-data";

// @TODO convert all of the JSX returning functions in this file to components.

export const getVariant = (variantId: string, variants: ProductVariant[]) => {
  return variants.find((variant) => variant.variantId === variantId);
};

const getSmartphonesDisplayValue = (
  variant: ProductVariant,
  prices: { [variantId: string]: number },
  isButton: boolean
) => {
  const fromPriceDisplay =
    prices && prices[variant.variantId] ? (
      <>From {centsToDollars(prices[variant.variantId])}</>
    ) : (
      <></>
    );

  return (
    <div className="flex items-center justify-between flex-wrap ">
      <div className="text-gray-900">
        {variant.attributes["storage"]}{" "}
        {getUnitsSuffix(FeatureUnits.GB, variant.attributes["storage"], true)}
      </div>
      {isButton ? (
        <ChevronDownIcon
          className="flex justify-self-center self-end -mr-1 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      ) : (
        <div className="text-xs">{fromPriceDisplay}</div>
      )}
    </div>
  );
};

const getTVsDisplayValue = (
  variant: ProductVariant,
  attributes: AttributeConfigurationMap,
  supported: string[],
  purchaseData?: PurchaseOption[],
  isButton?: boolean
) => {
  const displayDims = ["screenSize", "sku"];

  const variantPurchaseData = purchaseData?.filter(
    (datum) => datum?.productId === variant?.variantId && datum.inStock
  );

  const fromPriceDisplay =
    variantPurchaseData && variantPurchaseData[0]?.price ? (
      <>From {centsToDollars(variantPurchaseData[0].price)}</>
    ) : (
      <></>
    );

  return (
    <div className={`grid grid-cols-5 grid-rows-[20px] grid-flow-col gap-x-4`}>
      {displayDims.map((dim, index) => (
        <React.Fragment key={index}>
          <div className="text-xs text-left text-gray-500 col-span-2">
            {attributes[dim].shortName || attributes[dim].displayName}
          </div>
          <div className="text-xs text-left col-span-2">
            {variant.attributes[dim]}
            {getUnitsSuffix(
              FeatureUnits[attributes[dim].unit],
              variant.attributes[dim],
              true
            )}
          </div>
        </React.Fragment>
      ))}
      {isButton ? (
        <ChevronDownIcon
          className="flex self-center justify-self-end -mr-1 h-5 w-5 text-gray-400 col-span-1 row-span-2 row-start-1 col-start-5"
          aria-hidden="true"
        />
      ) : (
        variantPurchaseData &&
        purchaseData && (
          <div className="flex flex-col col-span-1 row-span-2 row-start-1 col-start-5">
            <div className="text-xs text-right flex justify-self-center self-end ">
              {fromPriceDisplay}
            </div>
          </div>
        )
      )}
    </div>
  );
};

const getLaptopsDisplayValue = (
  variant: ProductVariant,
  attributes: AttributeConfigurationMap,
  supported: string[],
  purchaseData?: PurchaseOption[],
  isButton?: boolean
) => {
  let displayDims = getVariantDisplayDims(supported, "laptops");

  if (displayDims.length < 2) {
    displayDims = getInlineVariantDisplayDims(supported, "laptops");
  }

  const variantPurchaseData = purchaseData?.filter(
    (datum) => datum?.productId === variant?.variantId && datum.inStock
  );

  const fromPriceDisplay =
    variantPurchaseData && variantPurchaseData[0]?.price ? (
      <>From {centsToDollars(variantPurchaseData[0].price)}</>
    ) : (
      <></>
    );

  return (
    <div
      className={`grid grid-cols-9 sm:grid-rows-[20px] grid-flow-col gap-x-4 gap-y-4 sm:gap-y-0`}
    >
      {displayDims.map((dim, index) => (
        <React.Fragment key={index}>
          <div className="text-xs text-left text-gray-500 col-span-2">
            {attributes[dim].shortName?.replace(
              "Display Technology",
              "Panel"
            ) ||
              attributes[dim].displayName?.replace(
                "Display Technology",
                "Panel"
              )}
          </div>
          <div className="text-xs text-left col-span-2">
            {variant.attributes[dim].replace(/\s?\(\d+ W\)/g, "")}{" "}
            {getUnitsSuffix(
              FeatureUnits[attributes[dim].unit],
              variant.attributes[dim],
              true
            )}
          </div>
        </React.Fragment>
      ))}
      {isButton ? (
        <ChevronDownIcon
          className="flex self-center justify-self-end -mr-1 h-5 w-5 text-gray-400 col-span-1 row-span-2 row-start-1 col-start-9"
          aria-hidden="true"
        />
      ) : (
        variantPurchaseData &&
        purchaseData && (
          <div className="flex flex-col col-span-1 row-span-2 row-start-1 col-start-9">
            <div className="text-xs text-right flex justify-self-center self-end ">
              {fromPriceDisplay}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export const displayValue = (
  variantId: string,
  variants: ProductVariant[],
  category: string,
  attributes: AttributeConfigurationMap,
  supported: string[],
  isButton?: boolean,
  purchaseData?: PurchaseOption[],
  prices?: { [variantId: string]: number }
) => {
  const variant = getVariant(variantId, variants);
  if (!variant) {
    return "";
  }
  switch (category) {
    case "smartphones":
      return getSmartphonesDisplayValue(variant, prices, isButton);
    case "laptops":
      return getLaptopsDisplayValue(
        variant,
        attributes,
        supported,
        purchaseData,
        isButton
      );
    case "tvs":
      return getTVsDisplayValue(
        variant,
        attributes,
        supported,
        purchaseData,
        isButton
      );
  }
};

/**
 *
 * Alternate variant dropdown with purchaseData that allows setting the product on PDPs
 */
export const VariantSelectTable = ({
  product,
  setProduct,
  showSeeAllOptionsCta = true,
  showSelector = true,
  className,
  index,
}: {
  product: Product;
  setProduct?: (product: Product, index?: number) => void;
  showSeeAllOptionsCta?: boolean;
  showSelector?: boolean;
  className?: string;
  index?: number;
}) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { attributes } = useAttributes();
  const { products } = useGlobalAllProducts();
  const { allPurchaseData } = useAllPurchaseData();

  const productPurchaseData = allPurchaseData?.filter(
    (datum) => datum?.productId === product?.id && datum.inStock
  );

  if (!productPurchaseData) {
    return (
      <BlankTextPlaceholder
        textForSizing={
          <>
            {productCategoryConfig.hasVariants && (
              <>
                <br />
                <br />
                <br />
                <br />
              </>
            )}
            <br />
            <br />
            <br />
            <br />
          </>
        }
      />
    );
  }

  if (
    !pickBestPurchaseOption(productPurchaseData) &&
    !productCategoryConfig.hasVariants
  ) {
    return null;
  }

  const productAttributes = {};
  Object.keys(product?.attributes)?.forEach(
    (attribute) =>
      (productAttributes[attribute] = product.attributes[attribute].value)
  );

  const productAsVariant: ProductVariant = {
    variantId: product.id,
    minPrice: centsToDollars(product.bestPrice),
    attributes: productAttributes,
    status: product.status,
  };

  const allVariants = [productAsVariant, ...product.variants].filter(
    (variant) =>
      variant.status.catalogString === "live" ||
      variant.status.catalogString === "staged"
  );

  return (
    <StandardCard
      className={`${className}`}
      innerClassName="!p-2 sm:!py-2 sm:!px-3"
    >
      {productCategoryConfig.hasVariants && showSelector && setProduct && (
        <>
          <h3 className="my-2 font-sans text-2xl small-caps tracking-wider">
            {getCategoryVariantsPhrase(productCategoryConfig)}
          </h3>
          <Menu as="div" className="relative inline-block w-full text-left">
            <div>
              <Menu.Button
                className={`w-full items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
              >
                {displayValue(
                  product.id,
                  allVariants,
                  productCategoryConfig.name,
                  attributes,
                  product.supportedVariants,
                  true
                )}
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-0"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-0"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                className={`absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
              >
                <div className="py-1">
                  {allVariants.map((variant, index) => (
                    <Menu.Item key={variant.variantId}>
                      <div
                        className={`px-3 py-2 text-sm hover:bg-gray-100 cursor-pointer ${
                          index > 0 ? "border-t border-t-gray-200" : ""
                        } ${
                          product.id === variant.variantId &&
                          "border-l-2 border-blue-500"
                        }`}
                        onClick={() =>
                          setProduct(
                            products.find(
                              (product) => product.id === variant.variantId
                            )
                          )
                        }
                      >
                        {displayValue(
                          variant.variantId,
                          allVariants,
                          productCategoryConfig.name,
                          attributes,
                          product.supportedVariants,
                          false,
                          allPurchaseData
                        )}
                      </div>
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </>
      )}
      {pickBestPurchaseOption(productPurchaseData) ? (
        <div className="">
          <div className="text-xs sm:text-sm my-2">
            {productCategoryConfig.hasVariants
              ? `Lowest price for this ${getCategoryVariantsPhrase(
                  productCategoryConfig
                ).toLowerCase()}:`
              : `Lowest price for this product:`}
          </div>
          <div className="mb-2 inline-flex items-end flex-wrap">
            <span className="text-xl sm:text-2xl font-bold mr-1">
              {centsToDollars(product.bestPrice)}{" "}
            </span>
            {productPurchaseData.length > 0 && (
              <span className="text-base mr-2 relative bottom-[3px]">
                {" "}
                at{" "}
                <a
                  className="text-blue-600 hover:underline cursor-pointer"
                  href={pickBestPurchaseOption(productPurchaseData).link}
                >
                  {pickBestPurchaseOption(productPurchaseData).storeName}
                </a>
              </span>
            )}
            {showSeeAllOptionsCta && (
              <StandardCard
                className="border-blue-500 inline-flex my-2 md:my-0 max-w-[60%] md:max-w-[100%]"
                innerClassName="!p-1 !px-2"
              >
                <a
                  href="#shopping"
                  className="text-sm hover:cursor-pointer font-semibold inline-flex items-center text-blue-500"
                >
                  <span>See all buying options</span>
                  <ChevronDoubleDownIcon className="h-4 w-4" />
                </a>
              </StandardCard>
            )}
          </div>
          {/* {!showSeeAllOptionsCta && productPurchaseData.length > 0 && (
            <div>
              <a
                href={index ? `#shopping${index}` : "#shopping"}
                className="inline-flex text-blue-600 text-sm hover:underline cursor-pointer items-center flex-wrap"
              >
                <span className="mr-1 flex-wrap">Browse shopping options</span>
                <ArrowDownIcon className="h-3 w-3 flex-wrap flex-shrink-0 font-bold" />
              </a>
            </div>
          )} */}
        </div>
      ) : (
        <div className="text-sm my-2">
          {productCategoryConfig.hasVariants
            ? `This configuration is out of stock.`
            : `This product is out of stock.`}
        </div>
      )}
    </StandardCard>
  );
};

export const VariantPriceSummary = ({ product }: { product: Product }) => {
  const { productCategoryConfig } = useContext(ProductCategoryContext);
  const { productVariants } = useGlobalAllProducts();
  const { allPurchaseData } = useAllPurchaseData();

  const [expanded, setExpanded] = useState(false);

  const productPurchaseData = allPurchaseData?.filter(
    (datum) => datum?.productId === product?.id && datum.inStock
  );

  if (!productPurchaseData) {
    return (
      <BlankTextPlaceholder
        textForSizing={
          <>
            <br />
            <br />
            <br />
            <br />
          </>
        }
      />
    );
  }

  const productAttributes = {};
  Object.keys(product?.attributes)?.forEach(
    (attribute) =>
      (productAttributes[attribute] = product.attributes[attribute].value)
  );

  const variantString =
    productVariants && productCategoryConfig.hasVariants
      ? productVariants.find((variant) => variant.id === product.id)
          ?.variantLabel
      : "";

  return (
    <StandardCard className="mt-4" innerClassName="!px-4">
      {pickBestPurchaseOption(productPurchaseData) ? (
        <div>
          <div className="text-xs sm:text-sm text-gray-400 my-2">
            {variantString}
          </div>
          <div className="text-sm my-2">
            {productCategoryConfig.hasVariants
              ? `Lowest price for this ${getCategoryVariantsPhrase(
                  productCategoryConfig
                ).toLowerCase()}:`
              : `Lowest price:`}
          </div>
          <div className="mb-2 md:inline-flex md:items-end">
            <span className="text-4xl font-bold">
              {centsToDollars(product.bestPrice)}
            </span>
            {productPurchaseData.length > 0 && (
              <a
                className="mx-2 font-bold text-base relative bottom-[4px] text-blue-600 hover:underline cursor-pointer"
                href={pickBestPurchaseOption(productPurchaseData).link}
              >
                ({pickBestPurchaseOption(productPurchaseData).storeName})
              </a>
            )}
            <StandardCard
              className="border-blue-500 inline-flex my-2 md:my-0 max-w-[60%] md:max-w-[100%]"
              innerClassName="!p-1 !px-2"
            >
              <div
                className="text-sm hover:cursor-pointer font-semibold inline-flex items-center text-blue-500"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <span>
                  {expanded ? "Hide buying options" : "See all buying options"}
                </span>
                {expanded ? (
                  <ChevronDoubleUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDoubleDownIcon className="h-4 w-4" />
                )}
              </div>
            </StandardCard>
          </div>
          {expanded && (
            <div className="mt-2">
              <PurchaseData product={product} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="text-xs sm:text-sm text-gray-400 my-2">
            {variantString}
          </div>
          <div className="md:inline-flex gap-2 md:items-end">
            <div className="text-sm my-2">
              {productCategoryConfig.hasVariants
                ? `This configuration is out of stock.`
                : `This product is out of stock.`}
            </div>
            <StandardCard
              className="border-blue-500 inline-flex my-2 md:my-0 max-w-[60%] md:max-w-[100%]"
              innerClassName="!p-1 !px-2"
            >
              <div
                className="text-sm hover:cursor-pointer font-semibold inline-flex items-center text-blue-500"
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <span>
                  {expanded ? "Hide buying options" : "See all buying options"}
                </span>
                {expanded ? (
                  <ChevronDoubleUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDoubleDownIcon className="h-4 w-4" />
                )}
              </div>
            </StandardCard>
          </div>
          {expanded && (
            <div className="mt-2">
              <PurchaseData product={product} />
            </div>
          )}
        </>
      )}
    </StandardCard>
  );
};

const VariantDropdown = (props: {
  product: Product;
  activeVariantId: string;
  setActiveVariantId: React.Dispatch<React.SetStateAction<string>>;
  category: string;
  purchaseData: PurchaseOption[];
  variants: ProductVariant[];
  bestPrices?: { [variantId: string]: number };
}) => {
  const { attributes } = useAttributes();

  if (!props.variants) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <div>
        <Menu.Button className="w-full items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {displayValue(
            props.activeVariantId,
            props.variants,
            props.category,
            attributes,
            props.product.supportedVariants,
            true
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-0"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-0"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-[100] mt-2 w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.variants.map((variant, index) => (
              <Menu.Item key={variant.variantId}>
                <div
                  className={`px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer ${
                    index > 0 ? "border-t border-t-gray-200" : ""
                  } ${
                    props.product.id === variant.variantId &&
                    "border-l-2 border-blue-500"
                  }`}
                  onClick={() => props.setActiveVariantId(variant.variantId)}
                >
                  {displayValue(
                    variant.variantId,
                    props.variants,
                    props.category,
                    attributes,
                    props.product.supportedVariants,
                    false,
                    props.purchaseData,
                    props.bestPrices
                  )}
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default VariantDropdown;
