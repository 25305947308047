import { ProductImage } from "@components/product/product-card/product-card-composed";
import { Product } from "@lib/model/product";
import { ProductCategoryConfig } from "@lib/model/product-category-config";
import eventTracker, { Events } from "@lib/tracking/event-tracker";
import {
  getProductLabels,
  getVsPageUrl,
} from "@lib/utilities/shared-utilities";
import Link from "next/link";

const SimilarProducts = ({
  comparisons,
  category,
  onCardClick,
}: {
  comparisons: Product[][];
  category: ProductCategoryConfig;
  onCardClick?: (products: Product[]) => void;
}) => {
  return (
    <div className="grid sm:grid-cols-2 gap-2 md:gap-4">
      {comparisons.map(([product1, product2]) => {
        const vsPageUrl = getVsPageUrl([product1, product2], category);

        return (
          <a
            key={`${product1.id}--${product2.id}--comp`}
            href={vsPageUrl}
            onClick={() => {
              eventTracker.track(Events.DetailsPageSimilarProductClick, {
                pageProduct: product1.seoName,
                comparingProduct: product2.seoName,
                category: category.name,
                trackingContext: vsPageUrl,
              });

              if (onCardClick) {
                onCardClick([product1, product2]);
              }
            }}
            className="text-center text-sm font-sans border border-gray-500 rounded-lg py-2"
          >
            <div className="flex flex-col space-y-2">
              <SimilarProductText vsProducts={[product1, product2]} />

              <SimilarProductImages
                vsProducts={[product1, product2]}
                categoryName={category.name}
              />
            </div>
          </a>
        );
      })}
    </div>
  );
};

const SimilarProductText = ({
  vsProducts,
}: {
  vsProducts: [Product, Product];
}) => {
  const [pageProduct, product] = vsProducts;
  const pageProductShortName = getProductLabels(pageProduct).shortLabel;
  const productShortName = getProductLabels(product).shortLabel;
  if (!pageProductShortName || !productShortName) {
    return null;
  }

  return (
    <span>
      Compare {pageProductShortName} vs. {productShortName}
    </span>
  );
};

const SimilarProductImages = ({
  vsProducts,
  categoryName,
}: {
  vsProducts: [Product, Product];
  categoryName: string;
}) => {
  return (
    <div className="flex flex-nowrap items-center justify-center gap-2 md:gap-8">
      <div className="flex items-center justify-center">
        <ProductImage
          product={vsProducts[0]}
          categoryName={categoryName}
          sizeClassName="max-h-20"
        />
      </div>

      <VsLogo />

      <div className="flex items-center justify-center">
        <ProductImage
          product={vsProducts[1]}
          categoryName={categoryName}
          sizeClassName="max-h-20"
        />
      </div>
    </div>
  );
};

const VsLogo = ({ sizeClassName = "text-2xl" }: { sizeClassName?: string }) => {
  return (
    <span className={`text-gray-500 font-bold text-center ${sizeClassName}`}>
      VS
    </span>
  );
};

export default SimilarProducts;
